<template>
<div class="flow-container">
    <div
        class="flow dir1 bg-gray"
        v-bind:class="{
            'last-flow': isLast,
            skipped: isOpen,
        }"
        v-bind:style="{ backgroundColor }"
        @click="showItem"
    >
        <label>
            <input
                type="checkbox"
                checked="checked"
                v-model="isNotIncomplete"
                class="checkbox01-input"
                disabled
            />
            <span
                class="checkbox01-parts"
                v-bind:style="{
                    color: textColor,
                    fontSize,
                }"
                v-text="name"
            />
            <img v-if="isUpdated" src="/images/ico-task-attention.png" alt="" class="ico-task-attention" />
        </label>
    </div>
    <div v-if="showCtrl" class="buttons2">
        <a class="button basic is-gray action-skip" @click="onSkip(item)"
            >スキップ</a
        ><span>&nbsp;&nbsp;</span>
        <a class="button basic is-login action-complete" @click="onComplete(item)"
            >完了</a
        >
    </div>
</div>
</template>

<script>
export default {
    props: {
        actionName: {
            type: String,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        isLast: {
            type: Boolean,
            default: false,
        },
        showCtrl: {
            type: Boolean,
            default: false,
        },
        onSkip: {
            type: Function,
            default: () => null,
        },
        onComplete: {
            type: Function,
            default: () => null,
        },
    },
    computed: {
        name() {
            return `${this.item.index}. ${this.item.name}`;
        },
        isPartial() {
            return this.item.status === 'partial';
        },
        isNotIncomplete() {
            return this.item.status !== 'incomplete';
        },
        isCompleted() {
            return this.item.status === 'completed';
        },
        isOpen() {
            return this.isPartial;
        },
        backgroundColor() {
            if (this.item.selected) {
                return '#E8F8E2';
            }

            if (this.isCompleted || this.isPartial) {
                return '';
            }

            return 'white';
        },
        fontSize() {
            if (this.item.selected) {
                return '1.1em';
            }

            return '1em';
        },
        textColor() {
            if (this.isCompleted) {
                return '#5CC33B';
            }

            return '';
        },

        isUpdated() {
            console.log( "WorkProcedureChild.isUpdated() : ", this.item.is_updated );

            return this.item.is_updated;
        }
    },
    methods: {
        showItem() {
            this.$store.commit(this.actionName, this.item);
            Vue.Download.onPageTransition( this.$route );
        },
    },
};
</script>

<style scoped lang="scss">
.action-skip,
.action-complete {
    color: #fff !important;
}
#sidebar {
    .flow {
        label {
            position: relative;
            width: 100%;
        }
    }
}
.ico-task-attention {
    position: absolute;
    top: 0.1em;
    right: -1em;
    z-index: 1;
    border-radius: 2px;
}
</style>
