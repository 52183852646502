var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: {
      "on-nav-back": function($event) {
        return _vm.$router.push({ name: "log-index" })
      }
    },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("閲覧ログ - ユーザー")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "columns is-gapless logs" },
              [
                _c("log-side-bar"),
                _vm._v(" "),
                _c("div", { staticClass: "column main-column" }, [
                  _c(
                    "div",
                    { staticClass: "main-column-inner" },
                    [
                      _c("log-period-input", {
                        attrs: {
                          period_begin: _vm.period_begin,
                          period_end: _vm.period_end
                        },
                        on: {
                          "update:period_begin": function($event) {
                            _vm.period_begin = $event
                          },
                          "update:period_end": function($event) {
                            _vm.period_end = $event
                          },
                          "period-updated": _vm.on_period_updated
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container01"
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "board" }, [
                              _c(
                                "div",
                                { staticClass: "board-header has-side-button" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "board-title icon-user title-lg"
                                    },
                                    [_vm._v("ログイン回数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "download-button" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.download_top_authorized_users_csv.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("CSVダウンロード")]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "board-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "show-more-table" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "table basic3 color02 initial-rows3",
                                            class: {
                                              "partial-display": !_vm.is_top_authorized_users_more
                                            }
                                          },
                                          [
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.top_authorized_users,
                                                function(user, n) {
                                                  return _c(
                                                    "tr",
                                                    { key: user.user_id },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("zero_pad")(
                                                              n + 1,
                                                              2
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "log-user",
                                                                  params: {
                                                                    user_id:
                                                                      user.user_id
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  user.user_name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(user.count) +
                                                            "回"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("more-button", {
                                      attrs: {
                                        list: _vm.top_authorized_users,
                                        limit: 3,
                                        color: "color02"
                                      },
                                      model: {
                                        value: _vm.is_top_authorized_users_more,
                                        callback: function($$v) {
                                          _vm.is_top_authorized_users_more = $$v
                                        },
                                        expression:
                                          "is_top_authorized_users_more"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container02"
                        },
                        [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "board" }, [
                              _c(
                                "div",
                                { staticClass: "board-header has-side-button" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "board-title icon-video title-lg"
                                    },
                                    [_vm._v("動画再生回数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "download-button" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.download_top_watched_users_csv.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("CSVダウンロード")]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "board-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "show-more-table" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "table basic3 color03 initial-rows3",
                                            class: {
                                              "partial-display": !_vm.is_top_watched_users_more
                                            }
                                          },
                                          [
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.top_watched_users,
                                                function(user, n) {
                                                  return _c(
                                                    "tr",
                                                    { key: user.user_id },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("zero_pad")(
                                                              n + 1,
                                                              2
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "log-user",
                                                                  params: {
                                                                    user_id:
                                                                      user.user_id
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  user.user_name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(user.count) +
                                                            "回"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("more-button", {
                                      attrs: {
                                        list: _vm.top_watched_users,
                                        limit: 3,
                                        color: "color03"
                                      },
                                      model: {
                                        value: _vm.is_top_watched_users_more,
                                        callback: function($$v) {
                                          _vm.is_top_watched_users_more = $$v
                                        },
                                        expression: "is_top_watched_users_more"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "board" }, [
                              _c(
                                "div",
                                { staticClass: "board-header has-side-button" },
                                [
                                  _c(
                                    "h2",
                                    {
                                      staticClass:
                                        "board-title icon-task title-lg"
                                    },
                                    [_vm._v("完了タスク数")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "download-button" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.download_top_completed_users_csv.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("CSVダウンロード")]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "board-body" }, [
                                _c(
                                  "div",
                                  { staticClass: "show-more-table" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "table-responsive" },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "table basic3 color01 initial-rows3",
                                            class: {
                                              "partial-display": !_vm.is_top_completed_users_more
                                            }
                                          },
                                          [
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.top_completed_users,
                                                function(user, n) {
                                                  return _c(
                                                    "tr",
                                                    { key: user.user_id },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("zero_pad")(
                                                              n + 1,
                                                              2
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              attrs: {
                                                                to: {
                                                                  name:
                                                                    "log-user",
                                                                  params: {
                                                                    user_id:
                                                                      user.user_id
                                                                  }
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  user.user_name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(user.count) +
                                                            "回"
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("more-button", {
                                      attrs: {
                                        list: _vm.top_completed_users,
                                        limit: 3,
                                        color: "color01"
                                      },
                                      model: {
                                        value: _vm.is_top_completed_users_more,
                                        callback: function($$v) {
                                          _vm.is_top_completed_users_more = $$v
                                        },
                                        expression:
                                          "is_top_completed_users_more"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }