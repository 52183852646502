var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": _vm.is_show_back, "show-admin": "true" },
    on: {
      "on-nav-back": function($event) {
        return _vm.$router.push(_vm.top_page_path)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("閲覧ログ - ユーザー")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "columns is-gapless logs" },
              [
                _c("log-side-bar"),
                _vm._v(" "),
                _c("div", { staticClass: "column main-column" }, [
                  _c("div", { staticClass: "main-column-inner" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _vm.user.user_id
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "log-user-button procedure-button",
                                  attrs: {
                                    to: {
                                      name: "log-work-procedures-user",
                                      params: { user_id: _vm.user.user_id }
                                    }
                                  }
                                },
                                [_vm._v("ユーザー作業履歴")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c("log-period-input", {
                            attrs: {
                              period_begin: _vm.period_begin,
                              period_end: _vm.period_end
                            },
                            on: {
                              "update:period_begin": function($event) {
                                _vm.period_begin = $event
                              },
                              "update:period_end": function($event) {
                                _vm.period_end = $event
                              },
                              "period-updated": _vm.on_period_updated
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row board-container board-container01" },
                      [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "board border-none" }, [
                            _c("div", { staticClass: "user-basic-data" }, [
                              _c("div", { staticClass: "user-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/icon_user.png",
                                    alt: ""
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "data1" }, [
                                _c("p", { staticClass: "user-name" }, [
                                  _vm._v(_vm._s(_vm.user.user_name))
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "user-kana" }, [
                                  _vm._v(_vm._s(_vm.user.user_name_kana))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "data2" }, [
                                _c("p", { staticClass: "user-department" }, [
                                  _vm._v(_vm._s(_vm.user.org_name))
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "user-email" }, [
                                  _vm._v(_vm._s(_vm.user.mail_address))
                                ])
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "board board-content-sm" }, [
                            _c("dl", [
                              _c(
                                "dt",
                                {
                                  staticClass: "board-title icon-user title-lg"
                                },
                                [_vm._v("ログイン回数")]
                              ),
                              _vm._v(" "),
                              _c("dd", { staticClass: "board-body" }, [
                                _c(
                                  "span",
                                  { staticClass: "count log-in-count" },
                                  [_vm._v(_vm._s(_vm.total_login_count))]
                                ),
                                _c("span", { staticClass: "unit" }, [
                                  _vm._v("回")
                                ])
                              ])
                            ])
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row board-container board-container02" },
                      [
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "board tab-panel" }, [
                            _c("ul", { staticClass: "tab" }, [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tabcolor02 selected",
                                    attrs: { href: "#tab1-1" }
                                  },
                                  [_vm._v("ログイン回数")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tabcolor02",
                                    attrs: { href: "#tab1-2" }
                                  },
                                  [_vm._v("動画再生回数")]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "panel" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "panel-content",
                                  attrs: { id: "tab1-1" }
                                },
                                [
                                  _c("canvas", {
                                    attrs: {
                                      id: "daily_authorized_logins_chart"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "panel-content",
                                  attrs: { id: "tab1-2" }
                                },
                                [
                                  _c("canvas", {
                                    attrs: {
                                      id: "daily_video_play_counts_chart"
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-6" }, [
                          _c("div", { staticClass: "board" }, [
                            _c("dl", [
                              _c(
                                "dt",
                                { staticClass: "board-title align-right" },
                                [_vm._v("完了タスク数")]
                              ),
                              _vm._v(" "),
                              _c("dd", [
                                _c("canvas", {
                                  attrs: { id: "daily_completed_tasks_chart" }
                                })
                              ])
                            ])
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row board-container board-container03" },
                      [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "board tab-panel" }, [
                            _c("ul", { staticClass: "tab" }, [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tabcolor01 selected",
                                    attrs: { href: "#tab2-1" }
                                  },
                                  [
                                    _c("span", { staticClass: "icon-task" }, [
                                      _vm._v("完了したタスク")
                                    ])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tabcolor03",
                                    attrs: { href: "#tab2-2" }
                                  },
                                  [
                                    _c("span", { staticClass: "icon-video" }, [
                                      _vm._v("よく動画再生した作業")
                                    ])
                                  ]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "panel" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "panel-content",
                                  attrs: { id: "tab2-1" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "download-button" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.download_top_completed_tasks_csv.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("CSVダウンロード")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "show-more-table" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "table-responsive" },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table basic2 color03 initial-rows3",
                                              class: {
                                                "partial-display": !_vm.is_top_completed_tasks_more
                                              }
                                            },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", [_vm._v(" ")]),
                                                  _vm._v(" "),
                                                  _c("th", [_vm._v("タスク")]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v("カテゴリ")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [_vm._v(" ")])
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.top_completed_tasks,
                                                  function(task, n) {
                                                    return _c(
                                                      "tr",
                                                      { key: task.id },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "zero_pad"
                                                              )(n + 1, 2)
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "router-link",
                                                              {
                                                                attrs: {
                                                                  to: {
                                                                    name:
                                                                      "log-work-procedures-task",
                                                                    params: {
                                                                      task__id:
                                                                        task.id
                                                                    }
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    task.task_name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              task.category_name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(task.count) +
                                                              "回"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("more-button", {
                                        attrs: {
                                          list: _vm.top_completed_tasks,
                                          limit: 3,
                                          color: "color03"
                                        },
                                        model: {
                                          value:
                                            _vm.is_top_completed_tasks_more,
                                          callback: function($$v) {
                                            _vm.is_top_completed_tasks_more = $$v
                                          },
                                          expression:
                                            "is_top_completed_tasks_more"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "panel-content",
                                  attrs: { id: "tab2-2" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "download-button" },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.download_top_played_work_procedures_csv.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("CSVダウンロード")]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "show-more-table" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "table-responsive" },
                                        [
                                          _c(
                                            "table",
                                            {
                                              staticClass:
                                                "table basic2 color03 initial-rows3",
                                              class: {
                                                "partial-display": !_vm.is_top_played_work_procedures_more
                                              }
                                            },
                                            [
                                              _c("thead", [
                                                _c("tr", [
                                                  _c("th", [_vm._v(" ")]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v("作業手順")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [_vm._v("タスク")]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v("カテゴリ")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [_vm._v(" ")])
                                                ])
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "tbody",
                                                _vm._l(
                                                  _vm.top_played_work_procedures,
                                                  function(work_procedure, n) {
                                                    return _c(
                                                      "tr",
                                                      {
                                                        key: work_procedure.id
                                                      },
                                                      [
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm._f(
                                                                "zero_pad"
                                                              )(n + 1, 2)
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              work_procedure.work_procedure_name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              work_procedure.task_name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              work_procedure.category_name
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              work_procedure.count
                                                            ) + "回"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("more-button", {
                                        attrs: {
                                          list: _vm.top_played_work_procedures,
                                          limit: 3,
                                          color: "color03"
                                        },
                                        model: {
                                          value:
                                            _vm.is_top_played_work_procedures_more,
                                          callback: function($$v) {
                                            _vm.is_top_played_work_procedures_more = $$v
                                          },
                                          expression:
                                            "is_top_played_work_procedures_more"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }