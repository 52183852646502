var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: {
      "show-back": "true",
      "show-user": "true",
      "show-fill": _vm.allTagOpen,
      "fill-height": _vm.fillHeight
    },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v(_vm._s(_vm.categoryName))])]
        },
        proxy: true
      },
      {
        key: "nav-ctrl",
        fn: function() {
          return [
            _c("p", [
              _c(
                "button",
                {
                  staticClass: "button basic is-lightgray",
                  attrs: { href: "" },
                  on: { click: _vm.onUpdateDatetimeSort }
                },
                [
                  _vm._v("更新日順 "),
                  _vm.axis == "update_datetime"
                    ? _c("arrow-up-down", { attrs: { order: _vm.order } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button basic is-lightgray",
                  attrs: { href: "" },
                  on: { click: _vm.onDictionarySort }
                },
                [
                  _vm._v("名前順 "),
                  _vm.axis == "dictionary"
                    ? _c("arrow-up-down", { attrs: { order: _vm.order } })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-login rs tagsearch",
                  attrs: { href: "#" },
                  on: { click: _vm.onAllTagOpen }
                },
                [_vm._v("タグ検索")]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row all-tag-container" }, [
              _c("div", { staticClass: "__col-md-10 all-tag-container_area" }, [
                _vm.allTagOpen
                  ? _c("div", { staticClass: "all-tag-indicator" }, [
                      _vm._v("▲")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.allTagOpen
                  ? _c(
                      "div",
                      {
                        staticClass: "all-tag-area",
                        staticStyle: {
                          "padding-top": "5px",
                          "padding-bottom": "5px"
                        }
                      },
                      [
                        _c(
                          "ul",
                          [
                            _vm._l(_vm.allTags, function(tag) {
                              return _c(
                                "li",
                                {
                                  key: tag.tagId,
                                  staticClass: "button basic is-tags",
                                  class: { "tag-selected": tag.selected },
                                  staticStyle: {
                                    "margin-left": "5px",
                                    "margin-right": "5px"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onTagSelected(tag)
                                    }
                                  }
                                },
                                [_c("span", [_vm._v(_vm._s(tag.tagName))])]
                              )
                            }),
                            _vm._v(" "),
                            _c("li", {
                              staticStyle: { display: "inline-block" }
                            })
                          ],
                          2
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "container tasks-container" }, [
              _c(
                "div",
                {
                  staticClass:
                    "wrapper columns is-multiline videoBlock sp-hidden"
                },
                [
                  _vm._l(_vm.tasks, function(task) {
                    return [
                      task.is_visible
                        ? _c("task-item", {
                            key: task.id,
                            attrs: { task: task },
                            on: {
                              "select-checklist": function($event) {
                                return _vm.gotoChecklist(task, {
                                  path: "/user/checklist/" + task.id
                                })
                              },
                              "select-task": function($event) {
                                return _vm.selectTask(task)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "wrapper columns is-multiline videoBlock pc-hidden"
                },
                [
                  _vm._l(_vm.tasks, function(task) {
                    return [
                      task.is_visible
                        ? _c("task-item", {
                            key: task.id,
                            attrs: { task: task, "is-small-view": true },
                            on: {
                              "select-checklist": function($event) {
                                return _vm.gotoChecklist(task, {
                                  path: "/user/checklist/" + task.id
                                })
                              },
                              "select-task": function($event) {
                                return _vm.selectTask(task)
                              }
                            }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.showTaskModal
                ? _c("div", { staticClass: "modal js-modal" }, [
                    _c(
                      "div",
                      {
                        staticClass: "modal_bg js-modal-close",
                        on: {
                          click: function($event) {
                            _vm.showTaskModal = false
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "modal-close js-modal-close"
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal_content" },
                          [
                            _c("task-item", {
                              attrs: {
                                task: _vm.selectedForModalTask,
                                "is-sp-modal-view": true
                              },
                              on: {
                                "select-checklist": function($event) {
                                  return _vm.gotoChecklist(
                                    _vm.selectedForModalTask,
                                    {
                                      path:
                                        "/user/checklist/" +
                                        _vm.selectedForModalTask.id
                                    }
                                  )
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }