var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-user": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("ダウンロードしたタスク一覧")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c(
                      "h2",
                      { staticClass: "column", attrs: { id: "title" } },
                      [_vm._v("ダウンロードしたタスク一覧")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "table-area" } },
                  [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { attrs: { id: "subtitle-area" } }, [
                        _vm._v("タスク一覧")
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-red",
                          attrs: { disabled: !_vm.deleteButtonEnabled },
                          on: {
                            click: function($event) {
                              _vm.showModalDelete = true
                            }
                          }
                        },
                        [
                          _vm._v("\n                        削除 "),
                          _c("img", {
                            attrs: { src: "/images/ico-trash.png", alt: "" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("p", { staticClass: "input-search tr" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button basic is-gray2",
                            on: { click: _vm.sortDatetime }
                          },
                          [
                            _vm._v(
                              "\n                            ダウンロード日順 "
                            ),
                            _c("arrow-up-down", {
                              attrs: { order: _vm.sortOrder }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-wrapper" }, [
                  _c(
                    "table",
                    {
                      staticClass: "basic scrollTable",
                      attrs: { id: "table001" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c("th", { staticStyle: { "min-width": "10em" } }, [
                            _vm._v("ダウンロード日")
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v("タスク名")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("カテゴリ名")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("タスク容量")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(_vm.tasks, function(task) {
                          return _c("tr", { key: task.id }, [
                            _c("td", { staticClass: "check" }, [
                              _c(
                                "label",
                                [
                                  _c("base-check-component", {
                                    on: {
                                      "check-state-change": function($event) {
                                        return _vm.onCheckStateChange(task)
                                      }
                                    },
                                    model: {
                                      value: task.selected,
                                      callback: function($$v) {
                                        _vm.$set(task, "selected", $$v)
                                      },
                                      expression: "task.selected"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "dates" }, [
                              _vm._v(_vm._s(task.download_datetime_for_show))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "tasks" }, [
                              _vm._v(_vm._s(task.task_name))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "cats" }, [
                              _vm._v(_vm._s(task.category_name))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  (task.file_size / 1024 / 1024).toFixed(2)
                                ) + " MB"
                              )
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "table",
                    {
                      staticClass:
                        "basic scrollTable sp-download-table pc-hidden"
                    },
                    [
                      _c(
                        "tbody",
                        _vm._l(_vm.tasks, function(task) {
                          return _c("tr", { key: task.id }, [
                            _c("td", { staticClass: "check" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: task.selected,
                                    expression: "task.selected"
                                  }
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(task.selected)
                                    ? _vm._i(task.selected, null) > -1
                                    : task.selected
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = task.selected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              task,
                                              "selected",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              task,
                                              "selected",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(task, "selected", $$c)
                                      }
                                    },
                                    function($event) {
                                      return _vm.onSpCheckStateChange()
                                    }
                                  ]
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "name" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    task.download_datetime_for_show +
                                      " " +
                                      task.category_name
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(task.task_name))])
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "dates" }, [
                              _vm._v(
                                _vm._s(
                                  (task.file_size / 1024 / 1024).toFixed(2)
                                )
                              ),
                              _c("span", [_vm._v("MB")])
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.showModalDelete
                  ? _c(
                      "modal-component",
                      {
                        attrs: {
                          "is-delete": true,
                          "is-quest": "true",
                          width: "600"
                        },
                        on: {
                          modalYes: _vm.onClickDelete,
                          modalNo: function($event) {
                            _vm.showModalDelete = false
                          }
                        }
                      },
                      [
                        _c("h4", { attrs: { slot: "header" }, slot: "header" }),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { slot: "body" },
                            slot: "body"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "tc pb2em",
                                staticStyle: { "margin-top": "0.5em" }
                              },
                              [
                                _c("strong", [
                                  _vm._v("選択されたタスクを削除しますか？")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }