<template>
    <span>
    <span  v-if="order=='desc'">
        <arrow-up></arrow-up>
    </span>
    <span  v-if="order=='asc'">
        <arrow-down></arrow-down>
    </span>
    </span>
</template>
<script>
    export default {
        props: {
            order: String,
        }
    }
</script>