var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 32 32" },
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          viewBox: "0 0 32 32",
          "xml:space": "preserve"
        }
      },
      [
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M6,10.5c-0.2,1.7-0.4,5.2-0.7,6.6c-0.8,2.7-1.7,5.3-2.7,7.8c3.5-2,8.9-7.5,13.3-11.3c1.2-1,2.5-2.1,3.9-2.8\n                c3-1.4,6.2-2.6,9.7-0.8"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }