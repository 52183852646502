var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showHome
    ? _c("div", { staticClass: "sp-footer pc-hidden" }, [
        _c(
          "dl",
          [
            _c(
              "dt",
              { staticClass: "home" },
              [
                _c("router-link", { attrs: { to: "/user/categories" } }, [
                  _vm._v("ホーム")
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("dt", { staticClass: "mypage" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isShowUser = true
                    }
                  }
                },
                [_vm._v("マイページ")]
              )
            ]),
            _vm._v(" "),
            _c("dt", { staticClass: "info" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isShowNotice = true
                    }
                  }
                },
                [_vm._v("お知らせ")]
              ),
              _vm.noticeUnread > 0
                ? _c("span", { staticClass: "badge" }, [
                    _vm._v(_vm._s(_vm.noticeUnread))
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("dt", { staticClass: "other" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.isShowOther = true
                    }
                  }
                },
                [_vm._v("その他")]
              )
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "sp-footer" } }, [
              _vm.isShowUser
                ? _c("dd", { staticClass: "mypage" }, [
                    _c(
                      "div",
                      { staticClass: "box" },
                      [
                        _c("span", {
                          staticClass: "close",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.isShowUser = false
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("p", [_vm._v(_vm._s(_vm.loginUserName))]),
                        _vm._v(" "),
                        _c("router-link", { attrs: { to: "/user/download" } }, [
                          _vm._v("ダウンロードしたタスク一覧")
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isOnline && _vm.showLogout,
                                expression: "isOnline && showLogout"
                              }
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.$emit("logout")
                              }
                            }
                          },
                          [_vm._v("ログアウト")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isOnline && _vm.showLogout,
                                expression: "isOnline && showLogout"
                              }
                            ],
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.$emit("change-password")
                              }
                            }
                          },
                          [_vm._v("パスワード変更")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "sp-footer" } }, [
              _c(
                "dd",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowNotice,
                      expression: "isShowNotice"
                    }
                  ],
                  staticClass: "info"
                },
                [
                  _c(
                    "div",
                    { staticClass: "box" },
                    [
                      _c("p", { staticClass: "info_head" }, [
                        _vm._v("お知らせ")
                      ]),
                      _c("span", {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.isShowNotice = false
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("Notification", {
                        attrs: { "is-sp-type-open": _vm.isShowNotice },
                        on: {
                          "get-unread": function($event) {
                            return _vm.getNoticeUnread($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("transition", { attrs: { name: "sp-footer" } }, [
              _vm.isShowOther
                ? _c("dd", { staticClass: "other" }, [
                    _c("div", { staticClass: "box" }, [
                      _c("span", {
                        staticClass: "close",
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.isShowOther = false
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "other_head" }, [
                        _c("p", [_vm._v("ダウンロード容量の目安")]),
                        _c("p", { staticClass: "other_count" }, [
                          _vm._v(
                            _vm._s(_vm.localDiskUsage) +
                              " / " +
                              _vm._s(_vm.localDiskTotal)
                          ),
                          _c("span", [_vm._v("GB")])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }