var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: {
      "on-nav-back": function($event) {
        return _vm.$router.push({ name: "log-index" })
      }
    },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("閲覧ログ - タスク更新履歴")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "columns is-gapless logs" },
              [
                _c("log-side-bar"),
                _vm._v(" "),
                _c("div", { staticClass: "column main-column" }, [
                  _c(
                    "div",
                    { staticClass: "main-column-inner" },
                    [
                      _c(
                        "log-period-input",
                        {
                          attrs: {
                            period_begin: _vm.period_begin,
                            period_end: _vm.period_end
                          },
                          on: {
                            "update:period_begin": function($event) {
                              _vm.period_begin = $event
                            },
                            "update:period_end": function($event) {
                              _vm.period_end = $event
                            },
                            "period-updated": _vm.on_period_updated
                          }
                        },
                        [
                          _c("div", { staticClass: "download-button" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.download_task_updates_csv.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("CSVダウンロード")]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container01"
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "board" }, [
                              _c(
                                "div",
                                { staticClass: "show-more-table" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c("sortable-table", {
                                        ref: "table",
                                        attrs: {
                                          headers: _vm.headers,
                                          list: _vm.task_updates,
                                          limit: 6,
                                          is_more: _vm.is_task_updates_more,
                                          sort_by: _vm.sort_by,
                                          is_sort_asc: _vm.is_sort_asc
                                        },
                                        on: {
                                          "update:list": function($event) {
                                            _vm.task_updates = $event
                                          },
                                          "update:sort_by": function($event) {
                                            _vm.sort_by = $event
                                          },
                                          "update:is_sort_asc": function(
                                            $event
                                          ) {
                                            _vm.is_sort_asc = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("more-button", {
                                    attrs: {
                                      list: _vm.task_updates,
                                      limit: 6,
                                      color: "color01"
                                    },
                                    model: {
                                      value: _vm.is_task_updates_more,
                                      callback: function($$v) {
                                        _vm.is_task_updates_more = $$v
                                      },
                                      expression: "is_task_updates_more"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }