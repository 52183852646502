var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "embed-responsive embed-responsive-16by9" }, [
    _vm.isNew
      ? _c("div", [
          _c("div", { staticClass: "status-new" }, [_vm._v("NEW")]),
          _vm._v(" "),
          _vm.isUpdate
            ? _c(
                "div",
                { staticClass: "status", staticStyle: { left: "4em" } },
                [_vm._v("UPDATE")]
              )
            : _vm._e()
        ])
      : _vm.isUpdate
      ? _c("div", { staticClass: "status" }, [_vm._v("UPDATE")])
      : _vm._e(),
    _vm._v(" "),
    _c("img", {
      staticClass: "embed-responsive-item image-thumbnail-source",
      attrs: { src: _vm.path }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }