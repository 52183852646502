var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson" }, [
    _c("a", { attrs: { href: _vm.link } }, [
      _c("div", { staticClass: "user" }, [
        _c("div", { staticClass: "icon" }, [
          _c("p", { style: _vm.iconImage(_vm.lesson.user.image) })
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "name" }, [
          _vm._v(_vm._s(_vm.lesson.user.display_name))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "contents" }, [
        _c(
          "div",
          { staticClass: "flex_nw" },
          [
            _c("lesson-video-thumbnail-component", {
              attrs: { video: _vm.lesson.video }
            }),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "categories flex" },
                [
                  _c("div", { staticClass: "cat" }, [
                    _vm._v(_vm._s(_vm.partName))
                  ]),
                  _vm._v(" "),
                  _c("level-component", { attrs: { level: _vm.lesson.level } })
                ],
                1
              ),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.lesson.title))]),
              _vm._v(" "),
              _c("p", { staticClass: "description" }, [
                _vm._v(_vm._s(_vm.lesson.description))
              ])
            ])
          ],
          1
        ),
        _vm._v(" "),
        _vm.showPrice
          ? _c("p", { staticClass: "price" }, [
              _vm._v("レッスン料"),
              _c("span", [_vm._v(_vm._s(_vm.lesson.price.toLocaleString()))]),
              _vm._v(" 円（税込）")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _vm.$store.state.userInfo.grant == 1 && _vm.progress > 0
            ? _c("p", { staticClass: "status process" }, [
                _vm._v(_vm._s(_vm.progress) + "％習得")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$store.state.userInfo.grant != 1
            ? [
                _vm.lesson.purchases_count > 0
                  ? _c("p", { staticClass: "students" }, [
                      _vm._v(_vm._s(_vm.lesson.purchases_count))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.lesson.display_flag == 0 && _vm.lesson.draft == 0
                  ? _c("p", { staticClass: "status private" }, [
                      _vm._v("非公開")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.lesson.display_flag == 0 && _vm.lesson.draft == 1
                  ? _c("p", { staticClass: "status private" }, [
                      _vm._v("下書き")
                    ])
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }