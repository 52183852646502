<template>
<footer class="l-footer p-footer">
                <div class="p-footer__sitemap c-flex">
                    <div class="c-list c-flex__col-33 c-flex__col-100-sm">
                        <ul>
                            <li class="c-list__item c-list__item--plain"><a href="/register">新規会員登録</a></li>
                            <li class="c-list__item c-list__item--plain"><a href="/search">レッスンを探す</a></li>
                            <li class="c-list__item c-list__item--plain"><a href="/notice">お知らせ</a></li>
                        </ul>
                    </div>
                    <div class="c-list c-flex__col-33 c-flex__col-100-sm">
                        <ul>
                            <li class="c-list__item c-list__item--plain"><a href="/guide">ご利用ガイド</a></li>
                            <li class="c-list__item c-list__item--plain"><a href="/faq">よくある質問</a></li>
                            <li class="c-list__item c-list__item--plain"><a href="mailto:contact@misoshiru.net?subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body=%E3%81%8A%E5%90%8D%E5%89%8D%3A%0D%0A%0D%0A%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%3A%0D%0A%0D%0A%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%3A%0D%0A">お問い合わせ</a></li>
                        </ul>
                    </div>
                    <div class="c-list c-flex__col-33 c-flex__col-100-sm">
                        <ul>
                            <li class="c-list__item c-list__item--plain"><a href="/terms">利用規約</a></li>
                            <li class="c-list__item c-list__item--plain"><a href="/privacy">プライバシーポリシー</a></li>
                            <li class="c-list__item c-list__item--plain"><a href="#">運営会社</a></li>
                        </ul>
                    </div>
                </div>
                <p class="p-footer__copyright">©misoshiru team</p>
            </footer>
</template>

<script>
export default {
    components: {
    },
    
    props: {
    },

    data: function() {
        return {
        };
    },

    mounted: function() {
    },
    methods: {
        isCurrent: function(_path){

            const path = location.pathname
            if(_path == "/"){
                if(_path == path){
                    return true;
                }else{
                    return false;
                }
            }
            if (path.startsWith(_path)){
                return true;
            }

            return false;
        }
    },

    computed: {
    },

    watch: {
    },

};
</script>

<style scoped lang="scss">
</style>
