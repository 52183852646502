<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" :require-user-info="true"  title="お知らせ" main-class="notice_page">
        <template v-slot:body>
            <section class="bg_white">
                <div class="list_box">
                    <ul>
                        <template v-if="notices && notices.length > 0">
                        <template v-for="(item,index) in notices">
                        <li :key="index">
                            <a :href="link(item)" class="flex_nw">
                                <div class="icon">
                                    <p :style="iconImage(item)"></p>
                                </div>
                                <div class="content">
                                    <p>{{item.name}}</p>
                                    <p class="date">{{ date(item.create_datetime) }}</p>
                                </div>
                                <i class="fas fa-angle-right"></i>
                            </a>
                        </li>
                        </template>
                        </template>
                        <template v-else>
                            <div class="container pt50">
                                <p>お知らせはまだありません</p>
                            </div>
                        </template>
                    </ul>
                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';
import moment from 'moment';

export default {
    components: {
    CommonLayoutComponent,
    ModalComponent
    },
  data: function () {
    return {
        notices:[],
    };
  },
  created: function () {},

    computed: {
    },
  mounted: function () {
      this.getData()
  },
  computed: {
  },
  methods: {
      getData: function(){
            Vue.ELearning.Notice.getAll()
            .then(
                function(res) {
                    if (res.data.status_number == 200) {
                        console.log("notice", res.data.content);
                        this.notices = res.data.content.data;
                    }
                }.bind(this),
            )
            .catch(function(res) {
                throw new Error("お知らせの取得に失敗しました", response);
            }.bind(this));
      },
    　iconImage: function(notice) {
          if(notice && notice.user && notice.user.image){
              return 'background: url(' + notice.user.image + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      },
    link: function(item){
        if(item.user_id > 0){
          //レッスンに関するお知らせ
          if((item.type >= 20 && item.type < 30) ||
             (item.type >= 50 && item.type < 60)){
               return this.$libs.url(['lesson',item.task_id, 'intro'])
          }
          //アドバイスに関するお知らせ
          if(item.type >= 30 && item.type < 40){
               return this.$libs.url(['lesson',item.task_id, 'intro'],{query:{view:'advice'}})
          }
          //質問に関するお知らせ
          if(item.type >= 40 && item.type < 50){
               return this.$libs.url(['lesson',item.task_id, 'intro'],{query:{view:'qa'}})              
          }
          return this.$libs.url(['lesson',item.task_id, 'intro'])
          
        }else{
            this.$libs.url(['lesson',item.task_id, 'intro'],{query:{view:'advice'}})            
        }
    },
    date: function(stringDate){
          var date = moment(stringDate);
          return date.format("MM月DD日"); 
     },

  },
}

</script>
<style lang="scss" scoped>
</style>
