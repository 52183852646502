var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": true,
      title: "新規登録",
      "main-class": "register_page"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", [
              !_vm.registerd
                ? _c("section", { staticClass: "bg_yellow" }, [
                    _c("div", { staticClass: "container pt50" }, [
                      _c("div", { staticClass: "caption" }, [
                        !_vm.teacher
                          ? _c("h1", [_vm._v("新規登録")])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.teacher
                          ? _c("h1", [_vm._v("新規登録（講師）")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n            情報をご入力後、利用規約をお読みになり、"
                          ),
                          _c("br"),
                          _vm._v(
                            "\n            「登録」ボタンを押してください。\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "box bg_white" }, [
                        _c("div", { staticClass: "item" }, [
                          _c("p", [_vm._v("お名前（ニックネーム）")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userName,
                                expression: "userName"
                              }
                            ],
                            class: { error: _vm.errUserName },
                            attrs: {
                              type: "text",
                              name: "name",
                              placeholder: ""
                            },
                            domProps: { value: _vm.userName },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.userName = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errUserName
                            ? _c("p", { staticClass: "error_description" }, [
                                _vm._v(_vm._s(_vm.userNameErrors.join(" ")))
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("p", [_vm._v("メールアドレス")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email"
                              }
                            ],
                            class: { error: _vm.errMailAddress },
                            attrs: {
                              type: "text",
                              name: "email",
                              placeholder: ""
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.email = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errMailAddress
                            ? _c("p", { staticClass: "error_description" }, [
                                _vm._v(_vm._s(_vm.mailAddressErrors.join(" ")))
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("p", [_vm._v("パスワード")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password"
                              }
                            ],
                            class: { error: _vm.errPassword },
                            attrs: {
                              type: "password",
                              name: "password",
                              placeholder: ""
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.passwordErrors
                            ? _c("p", { staticClass: "error_description" }, [
                                _vm._v(_vm._s(_vm.passwordErrors.join(" ")))
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "item" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.terms,
                                  expression: "terms"
                                }
                              ],
                              attrs: { id: "terms", type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.terms)
                                  ? _vm._i(_vm.terms, null) > -1
                                  : _vm.terms
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.terms,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 && (_vm.terms = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.terms = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.terms = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "terms" } }, [
                              _vm._v("利用規約と個人情報保護方針に同意する")
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.errTerms
                            ? _c("p", { staticClass: "error_description" }, [
                                _vm._v("未チェックです")
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "mt20 button md",
                            on: { click: _vm.register }
                          },
                          [_vm._v("登録")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "other_buttons" }, [
                        !_vm.teacher
                          ? _c("p", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.$libs.url(["teacher", "register"])
                                  }
                                },
                                [
                                  _vm._v("講師になりたい方はこちら"),
                                  _c("i", { staticClass: "fas fa-angle-right" })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.teacher
                          ? _c("p", [
                              _c(
                                "a",
                                { attrs: { href: _vm.$libs.url("register") } },
                                [
                                  _vm._v("受講したい方はこちら"),
                                  _c("i", { staticClass: "fas fa-angle-right" })
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "inner" }, [
                          _c(
                            "button",
                            {
                              staticClass: "login button md",
                              on: {
                                click: function($event) {
                                  return _vm.$libs.pushUrl("login")
                                }
                              }
                            },
                            [_vm._v("登録済みの方はログイン")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "icon",
                              attrs: { href: _vm.$libs.url("") }
                            },
                            [
                              _vm._v("みそしる教室 トップへ"),
                              _c("i", { staticClass: "fas fa-angle-right" })
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.registerd
                ? _c("section", { staticClass: "bg_yellow" }, [
                    _c("div", { staticClass: "container pt50" }, [
                      _c("div", { staticClass: "caption" }, [
                        _c("h1", [_vm._v("新規登録完了")]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("\n            ありがとうございます。"),
                          _c("br"),
                          _vm._v(
                            "\n            登録が完了しました。\n          "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "box bg_white" }, [
                        _c("div", { staticClass: "item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button md",
                              on: {
                                click: function($event) {
                                  return _vm.$libs.pushUrl("mypage", "edit")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n            ユーザー設定をする\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("p", { staticClass: "skip" }, [
                            _c("a", { attrs: { href: _vm.$libs.url("") } }, [
                              _vm._v("ユーザー設定をスキップして始める"),
                              _c("i", { staticClass: "fas fa-angle-right" })
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }