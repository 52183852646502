<template>
    <div class="flow-container">
        <div class="sp-hidden">
        <div
            class="flow"
            v-bind:style="{ backgroundColor }"
            @click="showItem"
            v-bind:class="{
                'last-flow': isLast && !openChild,
                'bg-gray': isNotIncomplete,
                opened: isOpened,
                skipped: isOpened && !hasChild,
            }"
        >
            <label style="display: block;">
                <input
                    type="checkbox"
                    v-model="isNotIncomplete"
                    class="checkbox01-input"
                    disabled
                />
                <span class="checkbox01-parts">
                    <span
                        v-bind:style="{
                            color: textColor,
                            fontSize,
                        }"
                        v-text="name"
                    />
                    <template v-if="hasChild">
                        <img
                            v-show="!openChild"
                            src="/images/ico-plus-gray.png"
                            @click="expand"
                            alt=""
                            class="expand-icon"
                        />
                        <img
                            v-show="openChild"
                            src="/images/ico-minus-gray.png"
                            @click="expand"
                            alt=""
                            class="expand-icon"
                        />
                    </template>
                </span>
                <img v-if="isUpdated" src="/images/ico-task-attention.png" alt="" class="ico-task-attention" />
            </label>
        </div>
        <div v-show="showCtrl && !hideContent" class="buttons2">
            <a class="button basic is-gray action-skip" @click="onSkip(item)">スキップ</a>
                <span>&nbsp;&nbsp;</span>
            <a class="button basic is-login action-complete" @click="onComplete(item)">完了</a>
        </div>

        <template v-for="child in item.child">
            <transition name="slide-fade" :key="child.branch_id">
                <work-procedure-child
                    v-if="openChild"
                    :action-name="actionName"
                    :isLast="isLast"
                    :item="child"
                    :show-ctrl="showCtrl"
                    :on-skip="onSkip"
                    :on-complete="onComplete"
                />
            </transition>
        </template>
        </div>

        <!-- >>SP -->
        <div class="pc-hidden">
            <div class="blockToggle_head" 
                :class="{
                    child: item.branch_id > 0,
                    'is-check': isNotIncomplete,
                    'is-skip' : isOpened && !hasChild,
                    'is-active' : isActive || isOpenDetail,
                }" 
                @click="$emit('active')">

                <div class="blockToggle_title">
                    <input type="checkbox"
                        v-model="isNotIncomplete"
                        disabled
                        :class="{'is-skip': isOpened && !hasChild,
                        'is-check':isNotIncomplete}"
                    >
                    <p class="blockToggle_text">{{ name }}</p>
                    <p class="iconWrap">
                        <span v-if="isUpdated"  class="icon_ex"></span>
                        <span v-if="!hasChild" class="blockToggle_btn" :class="{'is-active':isOpenDetail}" @click.prevent.stop="$emit('open-detail-toggle')"></span>
                    </p>
                </div>
                <transition name="btnarea">
                <div class="btnArea-top" v-if="isActive && !hasChild && !isOpenDetail">
                    <button href="#" @click.prevent="onSkip(item)" class="button rs is-skip">スキップ</button>
                    <button href="#" @click.prevent="onComplete(item)" class="button rs is-check">完了</button>
                </div>
                </transition>
            </div>
        </div>
        <!-- >> SP -->
    </div>
</template>
<script>
import WorkProcedureChild from './WorkProcedureChild.vue';

export default {
    components: {
        WorkProcedureChild,
    },
    props: {
        actionName: {
            type: String,
            required: true,
        },
        item: {
            type: Object,
            default: () => ({}),
        },
        isLast: {
            type: Boolean,
            default: false,
        },
        showCtrl: {
            type: Boolean,
            default: false,
        },
        onSkip: {
            type: Function,
            default: () => null,
        },
        onComplete: {
            type: Function,
            default: () => null,
        },

        isActive: {
            type: Boolean,
            default: false,
        },
        isOpenDetail: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isExpand: false,
            openChild: true,
        };
    },
    computed: {
        name() {
            return `${this.item.index}. ${this.item.name}`;
        },
        isPartial() {
            return this.status === 'partial';
        },
        isNotIncomplete() {
            return this.status !== 'incomplete';
        },
        isCompleted() {
            return this.status === 'completed';
        },
        backgroundColor() {
            if (this.item.selected) {
                return '#E8F8E2';
            }
            return '';
        },
        textColor() {
            if (this.isCompleted) {
                return '#5CC33B';
            }
            return '';
        },
        fontSize() {
            if (this.item.selected) {
                return '1.1em';
            }

            return '1em';
        },
        isOpened() {
            return this.isPartial;
        },
        status() {
            if (this.item.child.length == 0) {
                return this.item.status;
            }

            let incompleteCount = 0;
            let partialCount = 0;
            let completeCount = 0;
            for (let item in this.item.child) {
                const elem = this.item.child[item];
                if (elem.status == 'incomplete') {
                    incompleteCount++;
                } else if (elem.status == 'partial') {
                    partialCount++;
                } else if (elem.status == 'completed') {
                    completeCount++;
                }
            }

            if (this.item.child.length == incompleteCount) {
                return 'incomplete';
            } else if (
                this.item.child.length == completeCount ||
                this.item.child.length == completeCount + partialCount
            ) {
                return 'completed';
            }
            return 'partial';
        },
        hasChild() {
            return this.item.child !== undefined && this.item.child.length > 0;
        },

        isUpdated() {
            console.log( "WorkProcedure.isUpdated() : ", this.item.is_updated );

            return this.item.is_updated;
        },
        hideContent() {
          return (this.item.branch_id == 0 && this.item.child.length > 0);
        },

    },
    methods: {
        showItem() {
            const targetItem = this.hasChild? this.item.child[0] : this.item; //F2 No.105 親手順を選択した場合は、子の手順を選択状態とする

            this.$store.commit(this.actionName, targetItem);
            Vue.Download.onPageTransition( this.$route );
        },
        expand() {
            this.openChild = !this.openChild;
        },
    },
};
</script>

<style scoped lang="scss">
.action-skip,
.action-complete {
    color: #fff !important;
}
.expand-icon {
    position: relative;
    top: 3px;
    cursor: pointer;
}
.slide-fade {
    &-enter-active {
        transition: all 0.1s ease;
    }
    &-leave-active {
        transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
    }
    &-enter,
    &-leave-to {
        transform: translateY(10px);
        opacity: 0;
    }
}
#sidebar {
    .flow {
        label {
            position: relative;
        }
    }
}
.ico-task-attention {
    position: absolute;
    top: 0.3em;
    right: -1em;
    z-index: 1;
    border-radius: 2px;
}

@media screen and (max-width: 767px) {
main.page-checklist{
.btnarea-enter-active,
.btnarea-leave-active{
    transition: all 0.2s ease-out;
}

.btnarea-enter-to{
    padding-bottom: 15px;
    max-height: 40px;
}

.btnarea-enter,
.btnarea-leave-to {
    padding-bottom: 0;
    max-height: 0;
    overflow: hidden;
}
}
}
</style>
