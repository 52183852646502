<template>
    <div class="work-procedure-container" style=";">

        <li class="list-group-item work-procedure" v-bind:style="{background:getRootBgColor}">
            <div class="row" >
                <div class="col-md-1">
                    <check-component v-bind:check-box-mode="getRootStatus"></check-component>
                </div>

                <div v-if="!showButton" class="col-md-9">
                    <span class="work-procedure-name" style="word-wrap:break-spaces;" v-bind:style="{color:getRootTextColor, 'font-size':getFontSize(procedureItem)}" @click="showItem(procedureItem)">{{ getItem.index }}.&nbsp;{{ getItem.name }}</span>
                </div>
                <div v-if="!showButton" class="col-md-1">
                    <span v-if="procedureItem.child.length > 0" class="child-open-indicator work-procedure-name" @click="openChild = !openChild">{{ childOpenIndicatorLabel }}</span>
                </div>

                <div v-if="showButton" class="col-md-7">
                    <span class="work-procedure-name" style="word-wrap:break-spaces;" v-bind:style="{color:getRootTextColor, 'font-size':getFontSize(procedureItem)}" @click="showItem(procedureItem)">{{ getItem.index }}.&nbsp;{{ getItem.name }}</span>
                </div>
                <div v-if="showButton" class="col-md-1">
                    <span v-if="procedureItem.child.length > 0" class="child-open-indicator work-procedure-name" @click="openChild = !openChild">{{ childOpenIndicatorLabel }}</span>
                </div>

                <div v-if="showButton && !isPreview" class="col-md-3">
                    <button v-if="procedureItem.child.length === 0" type="button" class="btn btn-info" @click="skipByCurrentProc(procedureItem)" style="color:white;">スキップ</button>
                    <button v-if="procedureItem.child.length === 0" type="button" class="btn btn-success" @click="completeByCurrentProc(procedureItem)">完了</button>
                </div>
            </div>
        </li>

        <!--<transition-group name="list" tag="">-->
        <li v-if="openChild" v-for="item0 in procedureItem.child" class="list-group-item work-procedure-sub" v-bind:key="item0.uuid" v-bind:style="{background:getBgColor(item0)}">
            <!--<transition name="fade">-->
            <div v-if="openChild" class="row">
                <div class="col-md-1">&nbsp;</div>
                <div class="col-md-1">
                    <check-component v-bind:check-box-mode="item0.status"></check-component>
                </div>

                <div v-if="!showButton" class="col-md-9">
                    <span class="work-procedure-name" style="word-wrap:break-spaces;" v-bind:style="{color:getTextColor(item0), 'font-size':getFontSize(item0)}" @click="showItem(item0)">{{ item0.index }}.&nbsp;{{ item0.name }}</span>
                </div>

                <div v-if="showButton" class="col-md-7">
                    <span class="work-procedure-name" style="word-wrap:break-spaces;" v-bind:style="{color:getTextColor(item0), 'font-size':getFontSize(item0)}" @click="showItem(item0)">{{ item0.index }}.&nbsp;{{ item0.name }}</span>
                </div>
                <div v-if="showButton && !isPreview" class="col-md-3">
                    <div class="pull-right">
                        <button type="button" class="btn btn-info " @click="skipByCurrentProc(item0)" style="color:white;">スキップ</button>
                        <button type="button" class="btn btn-success pull-right" @click="completeByCurrentProc(item0)">完了</button>
                    </div>

                </div>
            </div>
            <!--</transition>-->
        </li>
        <!--</transition-group>-->
    </div>
</template>
<script>

    import CheckComponent from './CheckComponent.vue';

    export default {
        data() {
            return {
                styleObject: {
                    /*color: 'red',
                    fontSize: '13px'*/
                    backgroundColor:'white'
                },

                openChild:true,
            }
        },

        mounted: function() {
            console.log(this.isPreview);
        },

        computed: {
            getItem() {
                return this.procedureItem;
            },
            childOpenIndicatorLabel() {
                if(!this.openChild) {
                    return "＋";
                } else {
                    return "－";
                }
            },
            getRootBgColor: function() {
                var status = this.getRootStatus;

                if(this.procedureItem.selected) {
                    return "#E8F8E2";
                }

                if(status == 'incomplete') {
                    return 'white';
                } else {
                    return '';
                }
            },



            getRootTextColor: function() {
                var status = this.getRootStatus;

                if(status == 'completed') {
                    return '#5CC33B';
                } else {
                    return '';
                }
            },

            getRootStatus: function() {
                if(this.procedureItem.child.length == 0) {
                    return this.procedureItem.status;
                } else {
                    var incompleteCount = 0;
                    var partialCount = 0;
                    var completeCount = 0;
                    for(var item in this.procedureItem.child) {
                        var elem = this.procedureItem.child[item];
                        if(elem.status == 'incomplete') {
                            incompleteCount++;
                        } else if(elem.status == 'partial') {
                            partialCount++;
                        } else if(elem.status == 'completed') {
                            completeCount++;
                        }
                    }


                    if(this.procedureItem.child.length == incompleteCount) {
                        return 'incomplete';
                    } else if(this.procedureItem.child.length == completeCount || this.procedureItem.child.length == (completeCount + partialCount)) {
                        return 'completed';
                    } else {
                        return 'partial';
                    }

                    //return 'completed';
                }
            },

            getStyle(v) {
               // console.log("getStyle");
              //  console.log(v);
            },
            hasChild() {
                if(this.procedureItem.child !== undefined && this.procedureItem.child.length > 0) {
                    return true;
                } else {
                    return false;
                }
            },

            isSingle() {
                if(this.procedureItem.child !== undefined && this.procedureItem.child.length > 0) {
                    return true;
                } else {
                    return false;
                }
            },
            isMulti() {
                if(this.procedureItem.child !== undefined && this.procedureItem.child.length > 0) {
                    return true;
                } else {
                    return false;
                }
            },
        },

        props: {
            procedureItem: {
                name: '作業手順１',
                status: 'completed',

                child: [
                    {
                        name: '作業手順１－１',
                        status: 'incomplete',
                        child: []
                    },

                    {
                        name: '作業手順１－２',
                        status: 'partial',
                        child: []
                    },

                    {
                        name: '作業手順１－３',
                        status: 'completed',
                        child: []
                    },
                ]
            },
            showButton:false,
            isPreview: {
                type:Boolean,
                default: function() {
                    return false;
                }
            }
        },

        methods: {
            getBgColor: function (item) {
                //console.log(item.name, item.status);
                if(item.selected) {
                    return "#E8F8E2";
                }

                if(item.status === 'completed' || item.status === 'partial') {

                } else {
                    return "white";
                }
            },

            getFontSize: function(item) {
                if(item.selected) {
                    return "1.2em";
                } else {
                    return "1em";
                }
            },

            getTextColor: function(item) {
                if(item.status === 'completed') {
                    return "#5CC33B";
                }
            },

            showItem: function(item){
                //alert(item.name);

                //console.log(this.$store);
                //console.log(item);

                //if(item.child.length == 0) {
                    this.$store.commit('updateCurrentProcedure', item);
                //}
            },

            skipByCurrentProc: function(item){
                item.status = 'partial';
                //console.log(this.procedureItem);
                //this.$store.commit('nextProc', item);
            },

            completeByCurrentProc: function(item){
                item.status = 'completed';
                //console.log(this.procedureItem);
                //this.$store.commit('nextProc', item);
            },
        },

        components: {
          //  WorkProgressItem,
            CheckComponent
        }
    }
</script>
<style scoped>
    .description {
        font-family: -apple-system, blinkMacSystemFont, /* Macの欧文はSan Francisco (OS X 10.11以降) */
        'Helvetica Neue', /* Macの欧文 (OS X 10.10以前) */
        'Segoe UI',       /* Windowsの欧文 */
        YuGothicM,        /* Windows8.1以上は游ゴシック */
        YuGothic,         /* Macの游ゴシック */
        MeiryoUI,           /* 游ゴシックが入っていないWindows */
        sans-serif;
        line-height:1.5em;
        letter-spacing: 1.5px;
    }

    .description-big {
        font-family: -apple-system, blinkMacSystemFont, /* Macの欧文はSan Francisco (OS X 10.11以降) */
        'Helvetica Neue', /* Macの欧文 (OS X 10.10以前) */
        'Segoe UI',       /* Windowsの欧文 */
        YuGothicM,        /* Windows8.1以上は游ゴシック */
        YuGothic,         /* Macの游ゴシック */
        MeiryoUI,           /* 游ゴシックが入っていないWindows */
        sans-serif;
        line-height:1.5em;
        letter-spacing: 1.5px;

        font-size:2em;

    }

    .work-procedure-title {
        background-color:#EEEEEE;
    }
    .work-procedure {
        background-color:#E5E5E5;
    }
    .work-procedure-sub {
        background-color:#E5E5E5;
        /*padding-left:3.5em;*/
    }

    .completed {
        color:#5CC33B;
    }
    .work-procedure-name {
        /*font-size:2em;*/
        line-height:32px;
        font-size:1.1em;
        font-weight: bold;
        color:#696969;

        cursor:pointer;
    }

    .work-procedure-container {
        display:inline-block;
        vertical-align:middle
    }


    .child-open-indicator {
        background-color:#696969;
        color:white;

        border-radius:1.1em;
        -webkit-border-radius: 1.1em;
        -moz-border-radius: 1.1em;
    }

    .view-enter-active, view-leave-active {
        transition: opacity 1s
    }
    .view-leave-active {
        position:absolute;
    }
    .view-enter, .view-leave-to {
        opacity:0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }


    .list-item {
        display: inline-block;
        margin-right: 10px;
    }
    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to /* .list-leave-active for below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }
</style>