var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-header" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("nav", { staticClass: "period-nav" }, [
        _c("div", { staticClass: "period-start" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.period_begin,
                expression: "period_begin"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "date" },
            domProps: { value: _vm.period_begin },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.period_begin = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "separator" }, [_vm._v("-")]),
        _vm._v(" "),
        _c("div", { staticClass: "period-end" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.period_end,
                expression: "period_end"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "date" },
            domProps: { value: _vm.period_end },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.period_end = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        0 ? _c("div", { staticClass: "period-button" }) : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }