<template>
    <common-layout-component2 show-back="true" show-admin="true" v-on:on-nav-back="back">

        <template v-slot:nav-message>
            <span class="nav-msg-sub sp-hidden">{{ categoryName }}</span>
            {{ taskName }}
        </template>

        <template v-slot:nav-ctrl>
            <div class="sp-hidden">
                <div class="task-volume">
                    <p>タスク容量：<span>{{ taskSize }} MB</span></p>
                    <button v-if="isOnline" @click.stop="downloadTask" class="button basic">タスクをダウンロード</button>
                </div>
            </div>
            <div class="pc-hidden">
                <button v-if="!isSpDetailAllOpened" class="btn-allOpen" @click="spDetailAllOpen">すべて開く</button>
                <button v-if="isSpDetailAllOpened" class="btn-allOpen btn-allClose" @click="spDetailAllOpen">すべて閉じる</button>                
                <p>
                    <span>{{ taskSize }} MB</span>
                    <button v-if="isOnline" @click.stop="downloadTask" class="button basic download">ダウンロード</button>
                </p>
            </div>            
        </template>

        <template v-slot:body>
            <keep-alive>
                <component v-bind:is="currentComponent"

                           v-bind:props="procs"
                           v-bind:prop="proc"
                           v-bind:content-height="realContentHeight"
                           v-bind:sidebar-width="sidebarWidth"
                           v-bind:show-modal-download="showModalDownload"
                           v-bind:task-downloaded="taskDownloaded"

                           v-on:toggle="toggleComponent"
                           v-on:output-report="toggleComponent"
                           v-on:skip="toggleComponent"
                           v-on:complete="toggleComponent"
                           v-on:video-play="on_video_play"
                           v-on:close-modal-download="closeModalDownload"
                           class="sp-hidden"
                ></component>
            </keep-alive>
                             <keep-alive>
                <SpChecklist
                           v-bind:props="procs"
                           v-bind:prop="proc"
                           v-bind:show-modal-download="showModalDownload"
                           v-bind:task-downloaded="taskDownloaded"
                           v-on:skip="toggleComponent"
                           v-on:complete="toggleComponent"
                           v-on:video-play="on_video_play"
                           v-on:close-modal-download="closeModalDownload"
                           v-on:sp-detail-all-showed="isSpDetailAllOpened = true"
                           v-on:sp-detail-has-close="isSpDetailAllOpened = false"
                           class="pc-hidden"
                           ref="SpCheckList"
                ></SpChecklist>                
                             </keep-alive>
        </template>
    </common-layout-component2>
</template>
<script>
    import logic from './js/Checklist.js';

    import VideoComponent from '../../commons/VideoComponent.vue';
    import CircleGraphComponent from '../../commons/CircleGraphComponent.vue';
    import WorkProgressStateComponent from '../../commons/WorkProgressStateComponent.vue';

    import CheckComponent from '../../commons/CheckComponent.vue';
    import ExclamationComponent from '../../commons/ExclamationComponent.vue';

    import WorkProcedureItem from '../../commons/WorkProgressItem.vue';

    import TableComponent from '../../commons/TableComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';

    import OffCanvasComponent from '../../commons/OffCanvasComponent.vue';


    import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';


    import NormalChecklist from './components/NormalChecklist.vue';
    import LeftFulChecklist from './components/LeftFulChecklist.vue';
    import SpChecklist from './components/SpChecklist.vue';

    logic.components = {
        VideoComponent,
        CircleGraphComponent,
        WorkProgressStateComponent,
        CheckComponent,
        ExclamationComponent,
        WorkProcedureItem,
        TableComponent,
        ModalComponent,
        OffCanvasComponent,
        CommonLayoutComponent2,

        NormalChecklist,
        LeftFulChecklist,
        SpChecklist
}

    export default logic;
</script>
<style scoped lang="scss">
    .mainCol {
        padding-top:0 !important;
    }

.task-volume {
    padding: 0px 10px 0 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
 p {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  font-size: 18px;
  margin-right: 20px;
	 span {
   padding: 0;
  }
 }
 button {
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  color: #4fbe2b;
  padding: 0 25px;
 }
}

</style>