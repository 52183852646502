var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "レッスン",
      "main-class": "lesson_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.lesson
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "lesson_header bg_yellow" }, [
                    _c(
                      "div",
                      { staticClass: "container pt0 pb0" },
                      [
                        _c("lesson-video-component", {
                          attrs: {
                            video: _vm.videoPath,
                            thumbnail: _vm.videoThumbnail
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "bg_yellow" }, [
                    _c("div", { staticClass: "container pt20" }, [
                      _c("div", { staticClass: "description" }, [
                        _c("div", { staticClass: "title" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$libs.url([
                                  "lesson",
                                  _vm.lessonId,
                                  "intro"
                                ])
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.lesson.title) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("h1", [
                          _vm._v(
                            "Step" +
                              _vm._s(_vm.stepIndex + 1) +
                              ". " +
                              _vm._s(_vm.step.title)
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {},
                          [
                            _vm.step.description
                              ? _c("p", [_vm._v(_vm._s(_vm.step.description))])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.step.notice
                              ? _c("div", { staticClass: "box_wh notice" }, [
                                  _c("p", [_vm._v(_vm._s(_vm.step.notice))])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.stepIndex < _vm.lesson.steps.length - 1
                              ? [
                                  _c("div", { staticClass: "next" }, [
                                    _c("p", [_vm._v("次のステップへ")]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            _vm.showModal = true
                                          }
                                        }
                                      },
                                      [
                                        _c("p", [
                                          _c("i", {
                                            staticClass: "fas fa-arrow-right"
                                          })
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                              : [
                                  _c("div", { staticClass: "next" }, [
                                    _c("p", [_vm._v("レッスン修了")]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            _vm.showModal = true
                                          }
                                        }
                                      },
                                      [
                                        _c("p", [
                                          _c("i", {
                                            staticClass: "fas fa-arrow-right"
                                          })
                                        ])
                                      ]
                                    )
                                  ])
                                ]
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("h3", {}, [_vm._v("レッスン")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "box_wh steps mb0" }, [
                          _c(
                            "ul",
                            [
                              _vm._l(_vm.lesson.steps, function(item, index) {
                                return [
                                  _c("li", { key: index }, [
                                    _c(
                                      "a",
                                      {
                                        class: {
                                          complete: item.progress.length > 0
                                        },
                                        attrs: {
                                          href: _vm.$libs.url([
                                            "lesson",
                                            _vm.lesson.id,
                                            index
                                          ])
                                        }
                                      },
                                      [
                                        _c("p", { staticClass: "step" }, [
                                          item.progress.length > 0 &&
                                          item.progress[0].status == 1
                                            ? _c("i", {
                                                staticClass: "fas fa-check"
                                              })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                                        Step" +
                                              _vm._s(index + 1) +
                                              "\n                                    "
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [_vm._v(_vm._s(item.title))]),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fas fa-angle-right"
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "bold mb60" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$libs.url([
                                  "lesson",
                                  _vm.lesson.id,
                                  "intro"
                                ])
                              }
                            },
                            [
                              _vm._v(
                                "\n                            トップに戻る\n                        "
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.showModal
                    ? _c(
                        "div",
                        {
                          staticClass: "modal step_doit",
                          on: {
                            click: function($event) {
                              _vm.showModal = false
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "container" }, [
                            _c("div", { staticClass: "box_wh" }, [
                              _c("h3", [_vm._v("できましたか？")]),
                              _vm._v(" "),
                              _c("div", { staticClass: "flex_nw" }, [
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.onStepComplete(1)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/images/miso/lesson_step_ok.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [_vm._v("できた")])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "item" }, [
                                  _c(
                                    "button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.onStepComplete(2)
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/images/miso/lesson_step_no.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", [_vm._v("うまくできなかった")])
                                ])
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }