var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column is-half cat-item top" }, [
    _c(
      "a",
      {
        staticClass: "menu-item",
        class: _vm.itemClass,
        attrs: { href: "javascript:void(0)" },
        on: { click: _vm.navTo }
      },
      [
        _c("h3", [
          _c("img", { attrs: { src: _vm.iconSrc, alt: "" } }),
          _vm._v(" "),
          _c("span", { domProps: { textContent: _vm._s(_vm.linkText) } })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }