var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": true,
      title: "探す",
      "main-class": "search_page"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "section",
              [
                _c("div", { staticClass: "container pt20 pb0" }, [
                  _c("div", { staticClass: "flex_nw tab_2" }, [
                    _c(
                      "button",
                      {
                        class: { current: _vm.isSearchTypeLesson },
                        on: {
                          click: function($event) {
                            _vm.isSearchTypeLesson = true
                          }
                        }
                      },
                      [_vm._v("レッスンを探す")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        class: { current: !_vm.isSearchTypeLesson },
                        on: {
                          click: function($event) {
                            _vm.isSearchTypeLesson = false
                          }
                        }
                      },
                      [_vm._v("講師を探す")]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("search-form-component", {
                  attrs: { "is-search-type-lesson": _vm.isSearchTypeLesson }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }