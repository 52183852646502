var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: _vm.title,
      "main-class": "my_page sales"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "sales_container bg_yellow" }, [
              _c(
                "div",
                { staticClass: "container pt30 pb30" },
                [
                  _c("h2", [_vm._v("売上金")]),
                  _vm._v(" "),
                  _c("p", { staticClass: "sales" }, [
                    _c("span", [
                      _vm._v(_vm._s(Number(_vm.total).toLocaleString()))
                    ]),
                    _vm._v("円")
                  ]),
                  _vm._v(" "),
                  _vm.sales && !_vm.paymentId
                    ? [
                        _vm.sales && _vm.sales.length > 0
                          ? _c(
                              "button",
                              {
                                staticClass: "mb10 button round brown inline",
                                on: {
                                  click: function($event) {
                                    _vm.showModalPayment = true
                                  }
                                }
                              },
                              [_vm._v("振り込み申請をする")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [_vm._v("振り込み申請期限 2021/xx/xx")])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sales && _vm.paymentId
                    ? [
                        _c("p", { staticClass: "status" }, [
                          _vm._v(_vm._s(_vm.status))
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "dates flex_nw" }, [
                          _c("p", [
                            _vm._v(
                              "申請日 " +
                                _vm._s(
                                  _vm.formattedDate(_vm.payment.created_at)
                                )
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(_vm.payedDate))])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              !_vm.paymentId
                ? _c("div", { staticClass: "history" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.$libs.url(["mypage", "sales", "history"])
                        }
                      },
                      [
                        _vm._v("\n                    振り込み履歴"),
                        _c("i", { staticClass: "fas fa-angle-right" })
                      ]
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.sales && _vm.sales.length > 0
              ? [
                  _c("section", { staticClass: "bg_yellow" }, [
                    _c(
                      "div",
                      { staticClass: "sales bg_white" },
                      [
                        _vm._l(_vm.sales, function(item, index) {
                          return [
                            _c(
                              "div",
                              { key: index, staticClass: "sales_item" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.$libs.url([
                                        "lesson",
                                        item.task_id
                                      ])
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "contents" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex_nw" },
                                        [
                                          _c(
                                            "lesson-video-thumbnail-component",
                                            {
                                              attrs: { video: item.task.video }
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "right" }, [
                                            _c(
                                              "div",
                                              { staticClass: "user flex_nw" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "icon" },
                                                  [
                                                    _c("p", {
                                                      style: _vm.iconImage(
                                                        item.user.image
                                                      )
                                                    })
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "name" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.user.display_name
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "date" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formattedDate(
                                                          item.created_at
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("h3", [
                                              _vm._v(_vm._s(item.task.title))
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "price" }, [
                                              _c(
                                                "span",
                                                { staticClass: "small" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.strType(item.type)
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.price.toLocaleString()
                                                  ) + "円"
                                                )
                                              ]),
                                              _vm._v("（税込）")
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.showModalPayment
              ? _c("modal-component", {
                  attrs: {
                    title: "振り込み申請をしますか？",
                    "view-selectable": true
                  },
                  on: {
                    yes: _vm.applyPayment,
                    no: function($event) {
                      _vm.showModalPayment = false
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function() {
                          return [
                            _c("p", { staticClass: "description" }, [
                              _vm._v(
                                "売上金のお振り込み手続きを行います。通常、翌月末のお振り込みとなります。また、この操作は取り消しできません。\n                "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    424673421
                  )
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showModalPaymentApplied
              ? _c("modal-component", {
                  attrs: {
                    title: "振り込み申請をしました",
                    "view-plain": true
                  },
                  on: {
                    ok: function($event) {
                      _vm.showModalPaymentApplied = false
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }