<template>
    <label style="cursor:pointer;" v-bind:style="currentStyle">

        <input v-if="currentSelected" type="checkbox" class="checkbox01-input" @click="checkStateChange" @change.stop.prevent="" checked readonly>
        <input v-else type="checkbox" class="checkbox01-input" @click="checkStateChange"  @change.stop.prevent="" readonly>

        <span class="checkbox01-parts">{{ msgLabel }}</span>
    </label>
</template>
<script>
    export default {
        computed: {
            currentSelected() {
                return this.selected;
            },

            currentLevel() {
                if(this.level == undefined) {
                    return 1;
                } else {
                    if(this.level == 1) {
                        return 1;
                    } else {
                        return 2;
                    }
                }
            },

            currentStyle() {
                var style = {};
                if(this.currentLevel == 2) {
                    style['padding-left'] = "1.5em";
                } else {
                    style['padding-left'] = "0.1em";
                }

                return style;
            },
        },

        methods: {
            checkStateChange: function(){
                this.$emit("check-state-change", {
                    'selected': this.currentSelected
                });
            },
        },

        props: {
            selected: Boolean,
            msgLabel: String,
            level: Number,
        }
    }
</script>