var logic = {
    data() {
        return {
            isDrag: null,

            // 手順メタ情報
            categoryName: '',
            taskName: '',

            mode: '', // single/multi/pdf

            // 現在選択中の手順情報
            currentWorkProcName: '',
            currentWorkProcText: '',
            currentWorkProcNotice: '',
            currentKind: 'single',
            currentUuid: '',
            currentVideoId: '',
            isChild: false,

            /**  */
            hasChild: false,

            currentFilePath: '',
            currentFileErr: '',
            currentFileSize: 0,

            // 手順全体
            checklist: [],

            showConfirmModal: false,
            showConfirmModalDeleteFile: false,
            showConfirmModalDeleteProc: false,
            showConfirmModalDeleteInner: false,
            showConfirmDeleteStepChild: false,
            showConfirmModalLeave: false,

            allowLeave: false,
            nextPath: "",
        };
    }
};

logic.created = function() {};

logic.mounted = function() {
    this.layoutManager();

    // プレビューから戻ってきた場合は情報を復元する
    let origin = this.$store.state.previewOrigin;
    if (origin != undefined && origin.length > 0) {
        origin = Vue.ELearning.Util.cloneObject(origin);

        this.categoryName = this.$store.state.previewCategoryName;
        this.taskName = this.$store.state.previewTaskName;

        this.checklist = [];
        this.checklist = origin;
        this.currentFlowOnChange(this.checklist[0].uuid);

        // プレビューデータをクリア
        this.$store.commit('clearPreviewData');
    } else {
        this.refleshData(function() { this.currentFlowOnChange(this.checklist[0].uuid); }.bind(this));
    }

    //ページ離脱アラート
    window.onbeforeunload = function(e) {
        e.returnValue = "このページを離れますか？"; // returnValueのメッセージは使用できなくなった（Chromeデフォルトメッセージが使用される）
    }
};

logic.beforeRouteLeave = function(to, from, next) {

    console.log("to.path:", to.path);
    //preview/loginページに遷移 / 保存済み / 遷移確認済ならOK
    if (to.path == "/admin/preview" || to.path == "/login" || this.allowLeave) {
        window.onbeforeunload = null;
        next();
    }

    //アラートを表示
    this.nextPath = to.path;
    this.showConfirmModalLeave = true;
    next(false);
};

logic.computed = {
    contentHeight: function() {
        // メインヘッダ・サブヘッダ・ウィンドウの高さ取得
        var windowHeight = this.$store.state.winHeight - 42;
        var offset = jQuery('#sidebar').offset();
        var bodyOffsetTop = offset ? offset.top : 0;
        return windowHeight - bodyOffsetTop;
    },

    isUploadDisabled: function() {
        var path = this.currentFilePath;
        if (path === '') {
            return false;
        } else {
            return true;
        }
    },

    currentFileName: function() {
        var filePath = this.currentFilePath;

        if (filePath != undefined) {
            var ary = filePath.split('/');

            return ary[ary.length - 1];
        } else {
            return '...';
        }
    },

    isVideo: function() {
        if (this.currentFilePath == undefined || this.currentFilePath == null) {
            return false;
        }

        var isVideo = false;
        isVideo = isVideo || this.currentFilePath.endsWith('.mp4');
        isVideo = isVideo || this.currentFilePath.endsWith('.MP4');
        return isVideo;
    },
    isPdf: function() {
        if (this.currentFilePath == undefined || this.currentFilePath == null) {
            return false;
        }

        var isPdf = false;
        isPdf = isPdf || this.currentFilePath.endsWith('.pdf');
        isPdf = isPdf || this.currentFilePath.endsWith('.PDF');
        return isPdf;
    },
    isImage: function() {
        if (this.currentFilePath == undefined || this.currentFilePath == null) {
            return false;
        }

        // !name.endsWith(".jpg") || !name.endsWith(".jpeg") || !name.endsWith(".png") || !name.endsWith(".svg") || !name.endsWith(".gif")
        var isImage = false;
        isImage = isImage || this.currentFilePath.endsWith('.jpg');
        isImage = isImage || this.currentFilePath.endsWith('.jpeg');
        isImage = isImage || this.currentFilePath.endsWith('.png');
        isImage = isImage || this.currentFilePath.endsWith('.svg');
        isImage = isImage || this.currentFilePath.endsWith('.gif');
        isImage = isImage || this.currentFilePath.endsWith('.JPG');
        isImage = isImage || this.currentFilePath.endsWith('.JPEG');
        isImage = isImage || this.currentFilePath.endsWith('.PNG');
        isImage = isImage || this.currentFilePath.endsWith('.SVG');
        isImage = isImage || this.currentFilePath.endsWith('.GIF');

        return isImage;
    },
    /**
     * 作業手順詳細を非表示にするか
     *
     * @returns {boolean} 非表示にする場合は true
     */
    hideContent() {
        return this.currentKind === 'multi';
    },
    /**
     * 削除ボタンを表示するか
     *
     * @returns {boolean} 表示する場合は true
     */
    showDeleteProc() {
        return this.checklist.length > 1;
    },

    itemIndex() {
        const itemIndex = this.checklist.findIndex(item => {
            return item.selected || item.child.find(childItem => childItem.selected);
        });
        return itemIndex;
    },

    isOverFileSizeSum() {
        var max_disk_size = this.$store.state.appInfo.maxDiskSize; //process.env.MIX_MAX_DISK_SIZE;
        var current_file_size_sum = this.$store.state.fileSizeSum
        if (current_file_size_sum >= max_disk_size) {
            return true;
        } else {
            return false;
        }
    },

    /**
     * 現在編集中の作業手順の一覧
     */
    work_procedures() {
        let clone = Vue.ELearning.Util.cloneObject(this.checklist);

        let task_id = this.$route.params.task_id;
        let work_procedure_index = 1;
        let wps = [];

        console.log("clone : ", clone);

        for (let parent_item of clone) {
            let branch_id = 0;

            let common_item = { task_id: task_id, work_procedure_index: work_procedure_index, branch_id: branch_id };
            let wp = _.merge(common_item, this.work_procedure_from_checklist_item(parent_item));

            // F2 No.105 2020/02/17 子供がある場合、親の無効な情報を削除（作成時に入力があった場合に登録されてしまう）
            //   → 一旦コメントアウト 閲覧側非表示で対応
            /*
            if (parent_item.kind == 'multi') {
                if (parent_item.child.length > 0) {
                    delete wp.work_procedure_text;
                    delete wp.work_procedure_notice;
                    delete wp.video_id;
                    delete wp.file_size;
                    delete wp.video_base_path;
                    delete wp.video_path;
                }
            }
            */
            wps.push(wp);

            console.log('kind : ', parent_item.kind);

            if (parent_item.kind == 'multi') {
                for (let child_item of parent_item.child) {
                    branch_id++;

                    let common_item = { task_id: task_id, work_procedure_index: work_procedure_index, branch_id: branch_id };
                    let wp = _.merge(common_item, this.work_procedure_from_checklist_item(child_item));

                    wps.push(wp);
                }
            }

            work_procedure_index++;
        }

        console.log("wps : ", wps);

        return wps;
    }
};

logic.methods = {
    layoutManager: function() {
        this.$nextTick(() => {

            var windowWidth = jQuery(window).width();
            var sideBarWidth = jQuery('#sidebar').width();
            this.sidebarWidth = sideBarWidth;
            //console.log("W:", windowWidth, sideBarWidth, windowWidth - sideBarWidth);
        });
    },

    /**
     * 表示用のチェックリスト項目から保存用の作業手順を取得する
     * 
     * @param {object} 表示用のチェックリスト項目
     * @return {object} 保存用の作業手順
     */
    work_procedure_from_checklist_item(item) {
        return {
            id: item.id,
            work_procedure_name: item.workProcedureName,
            work_procedure_text: item.workProcedureText,
            work_procedure_notice: item.workProcedureNotice,
            video_id: item.videoId,
            file_size: item.fileSize,

            video_base_path: item.videoBasePath,
            video_path: item.videoPath
        }
    },

    /**
     * 選択手順を変更する
     *
     * @param {string} uuid
     * @param {string} value
     */
    currentFlowOnChange(uuid, value) {
        for (var key in this.checklist) {
            this.checklist[key].selected = false;
            //item0.selected = false;

            for (var keyChild in this.checklist[key].child) {
                this.checklist[key].child[keyChild].selected = false;
            }
        }

        for (var key in this.checklist) {
            var item0 = this.checklist[key];

            if (item0.uuid == uuid) {
                this.checklist[key].selected = true;

                this.currentWorkProcName = item0.workProcedureName;
                this.currentWorkProcText = item0.workProcedureText;
                this.currentWorkProcNotice = item0.workProcedureNotice;
                this.currentKind = item0.kind;
                this.currentUuid = item0.uuid;
                this.currentVideoId = item0.videoId;
                this.currentFileSize = item0.fileSize;

                this.currentFilePath = item0.videoBasePath + item0.videoPath + '';
                this.currentFileErr = '';
                this.hasChild = item0.kind == 'multi';
                this.isChild = false;
            }
            if (item0.child.length > 0) {
                for (var keyChild in item0.child) {
                    var child = item0.child[keyChild];

                    if (child.uuid == uuid) {
                        this.checklist[key].child[keyChild].selected = true;

                        this.currentWorkProcName = child.workProcedureName;
                        this.currentWorkProcText = child.workProcedureText;
                        this.currentWorkProcNotice = child.workProcedureNotice;
                        this.currentKind = child.kind;
                        this.currentUuid = child.uuid;
                        this.currentVideoId = child.videoId;
                        this.currentFileSize = child.fileSize;

                        this.currentFilePath = child.videoBasePath + child.videoPath;
                        this.currentFileErr = '';

                        //this.isChild = true;
                        this.hasChild = false;
                        this.isChild = true;
                    }
                }
            }
        }
    },

    /**
     * 選択中手順の種類(一つの作業手順/複数の作業手順)を変更する
     */
    currentKindChange: function(kind) {
        this.currentKind = kind;

        for (var key in this.checklist) {
            var item0 = this.checklist[key];
            if (item0.selected) {
                item0.kind = kind;
            }
            for (var keyChild in item0.child) {
                var itemChild = item0.child[keyChild];
                if (itemChild.selected) {
                    itemChild.kind = kind;
                }
            }
        }

        if (this.currentKind == 'multi') {
            this.hasChild = true;
        } else {
            this.hasChild = false;
        }
    },

    /**
     * 選択中手順の手順名を変更する
     */
    currentNameChange: function(name) {
        this.currentWorkProcName = name;

        for (var key in this.checklist) {
            var item0 = this.checklist[key];
            if (item0.selected) {
                item0.workProcedureName = name;
            }

            for (var keyChild in item0.child) {
                var itemChild = item0.child[keyChild];
                if (itemChild.selected) {
                    itemChild.workProcedureName = name;
                }
            }
        }
    },

    /**
     * 選択中手順の手順テキストを変更する
     */
    currentTextChange: function(text) {
        this.currentWorkProcText = text;
        for (var key in this.checklist) {
            var item0 = this.checklist[key];
            if (item0.selected) {
                item0.workProcedureText = text;
            }
            for (var keyChild in item0.child) {
                var itemChild = item0.child[keyChild];
                if (itemChild.selected) {
                    itemChild.workProcedureText = text;
                }
            }
        }
    },

    /**
     * 選択中手順の手順注意事項を変更する
     */
    currentNoticeChange: function(notice) {
        this.currentWorkProcNotice = notice;

        for (var key in this.checklist) {
            var item0 = this.checklist[key];
            if (item0.selected) {
                item0.workProcedureNotice = notice;
            }
            for (var keyChild in item0.child) {
                var itemChild = item0.child[keyChild];
                if (itemChild.selected) {
                    itemChild.workProcedureNotice = notice;
                }
            }
        }
    },

    /**
     * ドラッグ状態判定(ファイルアップロードコンポーネント)
     * @param event
     * @param key
     * @param status
     * @returns {boolean}
     */
    checkDrag: function(event, key, status) {
        if (status && event.dataTransfer.types == 'text/plain') {
            // ファイルではなくhtml要素をドラッグしてきたときは処理を中止
            return false;
        }
        this.isDrag = status ? key : null;
    },

    /**
     * ファイル選択時処理(アップロード)
     * @param event
     * @param key
     * @param image
     * @returns {boolean}
     */
    onDrop(event, key = '', image = {}) {
        this.isDrag = null; //★ドラッグ中のクラスを外しておく。

        if (this.isUploadDisabled) {
            this.$dialog.message('すでにファイルが選択されております。');
            //alert('すでにファイルが選択されております。');
            return;
        }

        this.currentFileErr = '';

        let fileList = event.target.files ? event.target.files : event.dataTransfer.files; //★①ファイル取得
        // ファイルが無い時は処理を中止
        if (fileList.length == 0) {
            return false;
        }
        let files = [];

        Vue.ELearning.Util.showOverlay(this.$store);

        for (let i = 0; i < fileList.length; i++) {
            var name = fileList[i].name;
            console.log('アップロード対象', fileList[i]);
            // 拡張子によるフィルタ

            console.log(name);

            /*if (!name.endsWith(".mp4") || !name.endsWith(".jpg") || !name.endsWith(".jpeg") || !name.endsWith(".png") || !name.endsWith(".svg") || !name.endsWith(".gif")) {
                      alert("選択されたファイルは動画またはPDFまたは画像ファイルではありません");
                      Vue.ELearning.Util.hideOverlay(this.$store);
                      return;
                  }*/

            if (this.currentKind == 'pdf') {
                if (!name.endsWith('.pdf') && !name.endsWith('.PDF')) {
                    this.$dialog.message('選択されたファイルはPDFファイルではありません');
                    //alert('選択されたファイルはPDFファイルではありません');
                    Vue.ELearning.Util.hideOverlay(this.$store);
                    event.target.value = "";
                    return;
                }
            } else {
                if (!name.endsWith('.mp4') &&
                    !name.endsWith('.jpg') &&
                    !name.endsWith('.jpeg') &&
                    !name.endsWith('.png') &&
                    !name.endsWith('.svg') &&
                    !name.endsWith('.gif') &&
                    !name.endsWith('.MP4') &&
                    !name.endsWith('.JPG') &&
                    !name.endsWith('.JPEG') &&
                    !name.endsWith('.PNG') &&
                    !name.endsWith('.SVG') &&
                    !name.endsWith('.GIF')
                ) {
                    this.$dialog.message('選択されたファイルは動画または画像ファイルではありません');
                    //alert('選択されたファイルは動画または画像ファイルではありません');
                    Vue.ELearning.Util.hideOverlay(this.$store);
                    event.target.value = "";
                    return;
                }
            }

            files.push(fileList[i]);
        }
        // 今回は1ファイルのみ送ることにする。
        let file = files.length > 0 ? files[0] : [];
        let fd = new FormData(); //★②
        fd.append('fileData', file);

        var config = {
            headers: { 'Content-Type': 'multipart/formdata' }
        };

        var handler = Vue.ELearning.Assets.upload(fd, config);

        handler
            .then(res => {
                if (res.data.status_number == 200) {
                    var content = res.data.content;
                    console.log('upload completed:', content);
                    if (content.hasFile == 'OK') {
                        var path = content.video.video_path;
                        var base_path = content.video.video_base_path;
                        var videoId = content.video.video_id;
                        var file_size = content.param[5].fileData.size;

                        for (var key in this.checklist) {
                            var i = this.checklist[key];
                            if (i.selected) {
                                i.videoBasePath = base_path;
                                i.videoPath = path;
                                i.videoId = videoId;
                                i.fileSize = file_size;
                            }
                            for (var childKey in this.checklist[key].child) {
                                if (this.checklist[key].child[childKey].selected) {
                                    this.checklist[key].child[childKey].videoBasePath = base_path;
                                    this.checklist[key].child[childKey].videoPath = path;
                                    this.checklist[key].child[childKey].videoId = videoId;
                                    this.checklist[key].child[childKey].fileSize = file_size;
                                }
                            }
                        }

                        this.currentFilePath = base_path + path;

                        Vue.ELearning.Util.hideOverlay(this.$store);
                    } else {
                        // アップロード失敗
                        this.currentFileErr = 'ファイルのアップロードに失敗しました。';

                        Vue.ELearning.Util.hideOverlay(this.$store);
                    }
                } else if (res.data.status_number == 401) {

                    this.allowLeave = true;
                    this.$router.push({ path: "/login" });
                    throw new Error('タイムアウトしました');

                } else {
                    throw new Error('ファイルのアップロードに失敗しました');
                }
                event.target.value = "";
            })
            .catch(error => {
                console.error(error);
                this.$dialog.message(error.message);

                Vue.ELearning.Util.hideOverlay(this.$store);
                event.target.value = "";
            });
    },

    /**
     * 戻るボタン押下時処理
     */
    back: function() {
        this.$router.push('/admin/task/' + this.$route.params.task_id);
    },

    /**
     * チェックリスト情報取得
     */
    refleshData: function(callback) {
        Vue.ELearning.Util.showOverlay(this.$store);

        var task_id = Number(this.$route.params.task_id);

        if (task_id) {
            var handler = Vue.ELearning.Checklist.getForEdit(task_id); //F2 No.94 2020/2/14 カテゴリ非公開のタスクも取得する用に変更
            handler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            var content = res.data.content;

                            var categoryName = content.category_name;
                            var taskName = content.task_name;

                            this.categoryName = categoryName;
                            this.taskName = taskName;

                            // console.log(this.categoryName, this.taskName);

                            var converted = this.convertProcData(content.procedure);

                            console.log('converted : ', Object.entries(converted));

                            if (converted.length > 0) {
                                this.checklist = converted;
                            } else {
                                this.checklist = [];
                                this.checklist.push({
                                    taskId: '',
                                    branchId: 0,
                                    workProcedureName: '',
                                    workProcedureText: '',
                                    workProcedureNotice: '',

                                    videoId: '',
                                    videoBasePath: '',
                                    videoPath: '',
                                    // fileSize: 0,

                                    kind: 'single',
                                    child: [],
                                    selected: false,
                                    uuid: Vue.ELearning.Util.generateUuid()
                                });
                            }
                        }

                        if (callback) {
                            callback();
                        };
                        Vue.ELearning.Util.hideOverlay(this.$store);
                    }.bind(this)
                )
                .catch(
                    function(res) {
                        console.error('読み込み失敗', res);
                        //callback();
                        Vue.ELearning.Util.hideOverlay(this.$store);
                    }.bind(this)
                );
        } else {
            // 新規追加
            callback();
        }
    },

    convertProcData: function(procs) {
        console.log("procs : ", procs);

        var parentAry = [];

        // 親
        for (var parentKey in procs) {
            var parentItem = procs[parentKey];

            if (parentItem.branch_id == 0) {

                var isPdf = false;
                if (parentItem.video_path != undefined) {
                    if (parentItem.video_path.endsWith('.pdf') || parentItem.video_path.endsWith('.PDF')) {
                        isPdf = true;
                    }
                }

                // なぜ名前を変える必要がある？ 2019-10-10 ando
                parentAry.push({
                    id: parentItem.id,
                    work_procedure_index: parentItem.work_procedure_index,
                    branchId: parentItem.branch_id,
                    workProcedureName: parentItem.work_procedure_name,
                    workProcedureText: parentItem.work_procedure_text,
                    workProcedureNotice: parentItem.work_procedure_notice,

                    videoId: parentItem.video_id,
                    videoBasePath: parentItem.video_base_path == null ? '' : parentItem.video_base_path,
                    videoPath: parentItem.video_path == null ? '' : parentItem.video_path,
                    fileSize: parentItem.file_size == null ? 0 : parentItem.file_size,

                    kind: isPdf ? 'pdf' : 'single',
                    child: [],
                    selected: false,
                    uuid: Vue.ELearning.Util.generateUuid() // なぜこんなものが必要？ 2019-10-10 ando
                });
            }
        }

        // 子
        for (var parentKey in parentAry) {
            var parentItem = parentAry[parentKey];

            for (var childKey in procs) {
                var childItem = procs[childKey];

                if (childItem.branch_id != 0 && childItem.work_procedure_index == parentItem.work_procedure_index) {
                    var isPdfChild = false;
                    if (childItem.video_path != undefined) {
                        if (childItem.video_path.endsWith('.pdf') || childItem.video_path.endsWith('.PDF')) {
                            isPdfChild = true;
                        }
                    }

                    parentItem.child.push({
                        id: childItem.id,
                        work_procedure_index: parentItem.work_procedure_index,
                        branchId: childItem.branch_id,
                        workProcedureName: childItem.work_procedure_name,
                        workProcedureText: childItem.work_procedure_text,
                        workProcedureNotice: childItem.work_procedure_notice,

                        videoId: childItem.video_id,
                        videoBasePath: childItem.video_base_path == null ? '' : childItem.video_base_path,
                        videoPath: childItem.video_path == null ? '' : childItem.video_path,
                        fileSize: childItem.file_size == null ? 0 : childItem.file_size,

                        kind: isPdfChild ? 'pdf' : 'single',
                        child: [],
                        selected: false,
                        uuid: Vue.ELearning.Util.generateUuid()
                    });

                    parentItem.kind = 'multi';
                }
            }
        }

        return parentAry;
    },

    /**
     * プレビュー
     */
    preview: function() {
        // 選択されたカテゴリ名・タスク名を保存
        this.$store.commit('savePreviewCategoryName', this.categoryName);
        this.$store.commit('savePreviewTaskName', this.taskName);

        // 編集中データ一時保存
        var clone = Vue.ELearning.Util.cloneObject(this.checklist);
        this.$store.commit('savePreviewOrigin', clone);

        // VuexStoreにプレビューデータを保存
        this.$store.commit('storeChecklistForPreview', this.work_procedures);

        // VuexStoreにプレビューバックURLをセット
        this.$store.commit('savePreviewOriginUrl', '/admin/checklist/' + this.$route.params.task_id);

        this.$router.push('/admin/preview');
    },

    /**
     * 保存
     */
    save: function() {
        console.log("wps : ", this.work_procedures);

        var handler = Vue.ELearning.Checklist.save(this.work_procedures);

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(res => {
                if (res.data.status_number == 200) {
                    var content = res.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        //console.error(content);
                        if (content.errors.work_procedure_name != undefined) {
                            this.$dialog.message(content.errors.work_procedure_name[0]);
                        }

                    } else {
                        this.allowLeave = true;
                        this.back();
                    }

                    Vue.ELearning.Util.hideOverlay(this.$store);

                } else if (res.data.status_number == 401) {

                    this.allowLeave = true;
                    this.$router.push({ path: "/login" });
                    throw new Error('タイムアウトしました');

                } else {

                    throw new Error('チェックリストの保存に失敗しました');
                }
            })
            .catch(error => {
                console.error(error);
                this.$dialog.message(error.message);
                Vue.ELearning.Util.hideOverlay(this.$store);
            });
    },

    clearCurrentValues() {
        this.currentWorkProcName = '';
        this.currentFilePath = '';
        this.currentWorkProcText = '';
        this.currentWorkProcNotice = '';
    },

    /**
     * 子項目を追加
     */
    addChildProcedure() {
        // 次の作業手順を追加
        const procedure = {
            id: null,
            branchId: '',
            workProcedureName: '',
            workProcedureText: '',
            workProcedureNotice: '',
            videoId: '',
            videoBasePath: '',
            videoPath: '',
            fileSize: 0,
            kind: 'single',
            uuid: null,
            selected: true,
            child: []
        };

        let procId = 1;
        let added = false;
        const oldProcedures = this.checklist.slice();
        this.checklist = oldProcedures.map(item => {
            item.workProcedureId = procId;

            if (this.hasChild) {
                // 親手順にて選択された場合
                if (!added && item.kind == 'multi' && item.selected) {
                    // 自身の選択状態は解除
                    item.selected = false;
                    // 子要素を追加
                    procId++;
                    procedure.workProcedureId = procId;
                    procedure.uuid = Vue.ELearning.Util.generateUuid();
                    item.child.push(procedure);
                    added = true;
                    this.currentUuid = procedure.uuid;
                }
            } else {
                // 子手順にて選択された場合
                item.child.forEach((childItem, idx) => {
                    procId++;
                    childItem.workProcedureId = procId;

                    if (!added && childItem.selected) {
                        // 自身の選択状態は解除
                        childItem.selected = false;
                        // 要素を追加
                        procId++;
                        const newChild = item.child.slice();
                        procedure.workProcedureId = procId;
                        procedure.uuid = Vue.ELearning.Util.generateUuid();
                        newChild.splice(idx + 1, 0, procedure);
                        item.child = newChild;
                        added = true;
                        this.currentUuid = procedure.uuid;
                    }
                });
            }
            procId++;

            return item;
        });

        // 入力欄をクリア
        this.clearCurrentValues();

        // 選択状態を変更
        this.currentFlowOnChange(this.currentUuid);
    },

    /**
     * 子項目を削除
     */
    deleteInner: function() {
        this.showConfirmModalDeleteInner = false;
        // 次の作業手順を追加
        var uuid = this.currentUuid;

        var newProc = [];

        var isTarget = false;

        var procId = 1;
        var nearUp = null;
        var nearDown = null;
        var itemNear = null;
        for (var key in this.checklist) {
            var item = this.checklist[key];

            if (item.child.length > 0) {
                // 子手順の場合
                var newChild = [];
                for (var childKey in item.child) {
                    var child = item.child[childKey];
                    if (uuid != child.uuid) {
                        child.workProcedureId = procId;
                        newChild.push(child);
                        procId++;
                    } else {
                        nearUp = parseInt(childKey) - 1;
                        nearDown = parseInt(childKey) + 1;
                        if (typeof item.child[nearUp] !== 'undefined' && item.child[nearUp]) {
                            itemNear = item.child[nearUp].uuid;
                        } else if (typeof item.child[nearDown] !== 'undefined' && item.child[nearDown]) {
                            itemNear = item.child[nearDown].uuid;
                        } else {
                            itemNear = item.uuid;
                        }
                    }
                }

                item.child = Vue.ELearning.Util.cloneObject(newChild);
            }

            if (uuid != item.uuid) {
                item.workProcedureId = procId;
                newProc.push(item);
                procId++;
            } else {
                nearUp = key - 1;
                nearDown = key + 1;
                if (typeof this.checklist[nearUp] !== 'undefined' && this.checklist[nearUp]) {
                    itemNear = this.checklist[nearUp].uuid;
                } else if (typeof this.checklist[nearDown] !== 'undefined' && this.checklist[nearDown]) {
                    itemNear = this.checklist[nearDown].uuid;
                }
            }
        }

        var clone = Vue.ELearning.Util.cloneObject(newProc);
        this.checklist = clone;
        if (itemNear != null) {
            this.currentFlowOnChange(itemNear);
        }
    },

    /**
     * 作業手順を追加
     *
     * @param {number} 追加先のインデックス
     */
    addItem(index) {
        // 手順の一覧を複製
        const oldProcedures = this.checklist.slice();

        // 選択状態を解除
        oldProcedures.forEach(item => {
            if (item.selected) {
                item.selected = false;
            } else {
                item.child.forEach(childItem => {
                    if (childItem.selected) {
                        childItem.selected = false;
                    }
                });
            }
        });

        // 要素を追加
        const uuid = Vue.ELearning.Util.generateUuid();
        oldProcedures.splice(index, 0, {
            id: null,
            branchId: '',
            workProcedureName: '',
            workProcedureText: '',
            workProcedureNotice: '',
            videoId: '',
            videoBasePath: '',
            videoPath: '',
            fileSize: 0,
            kind: 'single',
            uuid,
            selected: true,
            child: []
        });

        // 一覧の更新
        this.checklist = oldProcedures.slice();

        // 入力欄をクリア
        this.clearCurrentValues();

        // 選択状態を変更
        this.currentFlowOnChange(uuid);
    },

    /**
     * 前の作業手順を追加
     */
    addPrev() {
        // 追加箇所を特定
        const insertIndex = this.checklist.findIndex(item => {
            return item.selected || item.child.find(childItem => childItem.selected);
        });

        // 手順を追加
        this.addItem(insertIndex);
    },

    /**
     * 次の作業手順を追加
     */
    addNext() {
        // 追加箇所を特定
        const insertIndex = this.checklist.findIndex(item => {
            return item.selected || item.child.find(childItem => childItem.selected);
        });

        // 手順を追加
        this.addItem(insertIndex + 1);
    },

    /**
     * 作業手順を削除
     */
    deleteProc() {
        this.showConfirmModalDeleteProc = false;
        this.showConfirmDeleteStepChild = false;
        // 手順の一覧を複製
        const oldProcedures = this.checklist.slice();

        // 削除対象の親作業手順を特定
        const target = oldProcedures.find(
            item =>
            item.uuid === this.currentUuid ||
            (item.child.length && item.child.find(child => child.uuid === this.currentUuid))
        );

        if (!target) {
            console.warn(`Does not match uuid [${this.currentUuid}]`);
        }

        // 対象手順を除外
        const newProcedures = oldProcedures.filter(_ => _.uuid !== target.uuid);

        // 一覧の更新
        this.checklist = newProcedures.slice();

        // モーダルを開いている場合は閉じる
        if (this.showConfirmModal) {
            this.closeConfirmModal();
        }

        // 選択状態を変更
        this.currentFlowOnChange(this.checklist[0].uuid);
    },

    /**
     * ファイルを削除
     */
    onFileDelete: function() {
        this.currentFilePath = '';

        for (var key in this.checklist) {
            var i = this.checklist[key];
            if (i.selected) {
                i.videoBasePath = '';
                i.videoPath = '';
                i.videoId = 0;
                i.fileSize = 0;
            }

            for (var childKey in this.checklist[key].child) {
                if (this.checklist[key].child[childKey].selected) {
                    this.checklist[key].child[childKey].videoBasePath = '';
                    this.checklist[key].child[childKey].videoPath = '';

                    this.checklist[key].child[childKey].videoId = 0;
                    this.checklist[key].child[childKey].fileSize = 0;
                }
            }
        }
        this.showConfirmModalDeleteFile = false;
    },

    importTaskCheck: function() {
        this.$el.querySelector('#input-file-csv').click();
    },

    selectedUploadCsv(e) {
        const taskId = this.$route.params.task_id;

        const files = e.target.files;
        const csv = files[0];

        let formData = new FormData();
        formData.append('csv', csv);

        const handler = Vue.ELearning.Checklist.postCsv(taskId, formData);

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then((res) => {
                const content = res.data.content;
                if (content.result == "NG") {
                    //エラー
                    const key = Object.keys(content.errors)[0];
                    var message = "インポートに失敗しました";
                    message = message + "。" + content.errors[key].join(" ");
                    this.$dialog.message(message);
                }

                Vue.ELearning.Util.hideOverlay(this.$store);
                this.refleshData();

                e.target.value = "";
            })
            .catch(err => {
                console.log("error", err);
                this.$dialog.message('インポートに失敗しました');
                //alert('インポートに失敗しました');
                Vue.ELearning.Util.hideOverlay(this.$store);

                e.target.value = "";
            });
    },

    exportTaskCheck: function() {
        var taskId = this.$route.params.task_id;
        console.log('********** export TaskCheck taskId:' + taskId);
        var handler = Vue.ELearning.Checklist.getTaskCsv(taskId);
        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(response => {
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'checklist.csv');
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                Vue.ELearning.Util.hideOverlay(this.$store);
            })
            .catch(response => {
                this.$dialog.message('エクスポートに失敗しました');
                //alert('エクスポートに失敗しました');
                Vue.ELearning.Util.hideOverlay(this.$store);
            });
    },

    /**
     * 削除確認モーダルを開く
     */
    openConfirmModal() {
        this.showConfirmModal = true;
    },

    /**
     * 削除確認モーダルを閉じる
     */
    closeConfirmModal() {
        this.showConfirmModal = false;
    },
    closeConfirmModalDeleteFile() {
        this.showConfirmModalDeleteFile = false;
    },
    closeConfirmModalDeleteProc() {
        this.showConfirmModalDeleteProc = false;
    },
    closeConfirmModalDeleteInner() {
        this.showConfirmModalDeleteInner = false;
    },
    showConfirmDelete() {
        if (this.hasChild) {
            this.showConfirmDeleteStepChild = true;
        } else {
            this.showConfirmModalDeleteProc = true;
        }
    },
    closeConfirmModalDeleteStepChild() {
        this.showConfirmDeleteStepChild = false;
    },

    leave() {
        this.allowLeave = true;
        this.$router.push({ path: this.nextPath });
    },
    closeConfirmModalLeave() {
        this.showConfirmModalLeave = false;
    },
};

export default logic;