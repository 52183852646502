var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": _vm.is_show_back, "show-admin": "true" },
    on: {
      "on-nav-back": function($event) {
        return _vm.$router.push(_vm.top_page_path)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("閲覧ログ - 作業履歴")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "columns is-gapless logs" },
              [
                _c("log-side-bar", {
                  attrs: {
                    "is-show-userwork-userlist": _vm.is_show_userlist,
                    "userwork-users": _vm.all_users,
                    "userwork-users-sort-asc": _vm.users_sort_asc
                  },
                  on: { sortUsers: _vm.onSortUserList }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "column main-column" }, [
                  _c(
                    "div",
                    { staticClass: "main-column-inner" },
                    [
                      _vm.user.user_id
                        ? _c("div", { staticClass: "board border-none" }, [
                            _c("div", { staticClass: "user-basic-data" }, [
                              _c("div", { staticClass: "user-icon" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/icon_user.png",
                                    alt: ""
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "data1" }, [
                                _c("p", { staticClass: "user-name" }, [
                                  _vm._v(_vm._s(_vm.user.user_name))
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "user-kana" }, [
                                  _vm._v(_vm._s(_vm.user.user_name_kana))
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "data2" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "log-user-button dash-button",
                                      attrs: {
                                        to: {
                                          name: "log-user",
                                          params: { user_id: _vm.user.user_id }
                                        }
                                      }
                                    },
                                    [_vm._v("ダッシュボード")]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "log-period-input",
                        {
                          attrs: {
                            period_begin: _vm.period_begin,
                            period_end: _vm.period_end
                          },
                          on: {
                            "update:period_begin": function($event) {
                              _vm.period_begin = $event
                            },
                            "update:period_end": function($event) {
                              _vm.period_end = $event
                            },
                            "period-updated": _vm.on_period_updated
                          }
                        },
                        [
                          _c("div", { staticClass: "download-button" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.download_work_procedure_logs_csv.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v("CSVダウンロード")]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container01"
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "board" }, [
                              _c(
                                "div",
                                { staticClass: "show-more-table" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c("sortable-table", {
                                        ref: "table",
                                        attrs: {
                                          headers: _vm.headers,
                                          list: _vm.work_procedure_logs,
                                          limit: 6,
                                          is_more:
                                            _vm.is_work_procedure_logs_more,
                                          sort_by: _vm.sort_by,
                                          is_sort_asc: _vm.is_sort_asc
                                        },
                                        on: {
                                          "update:list": function($event) {
                                            _vm.work_procedure_logs = $event
                                          },
                                          "update:sort_by": function($event) {
                                            _vm.sort_by = $event
                                          },
                                          "update:is_sort_asc": function(
                                            $event
                                          ) {
                                            _vm.is_sort_asc = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("more-button", {
                                    attrs: {
                                      list: _vm.work_procedure_logs,
                                      limit: 6,
                                      color: "color01"
                                    },
                                    model: {
                                      value: _vm.is_work_procedure_logs_more,
                                      callback: function($$v) {
                                        _vm.is_work_procedure_logs_more = $$v
                                      },
                                      expression: "is_work_procedure_logs_more"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.is_show_userlist
                    ? _c("div", { staticClass: "white_bg" })
                    : _vm._e()
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }