<template>
  <div class="columns is-gapless">
    <!-- left menu -->
    <div class="column" style="overflow-y:auto;" v-bind:style="{ 'max-height': contentHeight + 'px' }">
      <div id="sidebar">
        <a id="cont-close" @click="toggle"><img src="/images/ico-right-arrow.png" alt=""/></a>
        <p class="side-title"><b>作業手順</b></p>

        <template v-for="(p, idx) in props">
          <work-procedure
            :key="p.work_procedure_id"
            :item="p"
            :is-last="isLastProcedure(idx)"
            :show-ctrl="true"
            :on-skip="skipByCurrentProc"
            :on-complete="completeByCurrentProc"
            action-name="updateCurrentProcedure"
          />
        </template>

        <p class="tc pt10">
          <a class="button basic is-login" style="color:white;" @click="openSaveDialog"
            ><img src="/images/ico-report.png" alt="" /> &nbsp;{{
              isCompleted ? 'タスク完了' : '保存して中断'
            }}
          </a>
        </p>
      </div>
    </div>

    <confirm-modal
      v-if="showModalCompleted"
      :continue-func="completeByCurrentProc"
      :close-func="closeModalCompleted"
      title="タスク完了の確認"
      message="タスクを完了しますか？"
    />

    <confirm-modal
      v-if="showModalSkip"
      :continue-func="skipByCurrentProc"
      :close-func="closeModalSkip"
      title="タスクスキップの確認"
      message="タスクをスキップしますか？"
    />

    <confirm-modal
      v-if="showModalTaskCompleted"
      :continue-func="completeByTask"
      :close-func="closeModalTaskCompleted"
      title="タスク完了の確認"
      message="タスクを完了しますか？"
    />

    <modal-component
      v-if="showModalSaveHistory"
      is-quest="true"
      @modalYes="saveHistory"
      @modalNo="showModalSaveHistory = false"
    >
      <h4 slot="header">確認</h4>
      <h3 slot="body" style="text-align: center;">
        <p class="tc pb2em" style="padding-bottom:0;">
          <strong>タスクを保存して中断しますか？</strong>
        </p>
      </h3>
    </modal-component>

    <task-download-modal
      v-if="showModalDownload"
      :downloaded="taskDownloaded"
      @close-modal-download="closeModalDownload" 
    />

  </div>
</template>

<script>
import WorkProcedure from '../../common/components/WorkProcedure.vue';
import ConfirmModal from '../../common/components/ConfirmModal.vue';
import ModalComponent from '../../../commons/ModalComponent.vue';
import TaskDownloadModal from '../../common/components/TaskDownloadModal.vue';

export default {
  components: {
    WorkProcedure,
    ConfirmModal,
    ModalComponent,
    TaskDownloadModal
  },
  props: {
    sideBarWidth: {
      type: Number,
      default: function() {
        return 0;
      }
    },

    prop: {
      type: Object,
      default: function() {
        return {};
      }
    },
    props: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentHeight: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    showModalDownload:false,
    taskDownloaded:false,

  },
  data() {
    return {
      showModalCompleted: false,
      showModalSkip: false,
      showModalTaskCompleted: false,
      showModalSaveHistory: false
    };
  },
  computed: {
    isCompleted() {
      const recursive = procs => {
        return procs.filter(proc => proc.status === 'incomplete');
      };

      return !Boolean(
        this.props.filter(proc => {
          if (proc.child && proc.child.length) {
            if (recursive(proc.child).length !== 0) {
              return true;
            }
          }

          return proc.status === 'incomplete';
        }).length
      );
    },
    noticeEmpty: function() {
      var notice = this.prop.notice;
      // proc.notice != undefined && proc.notice != '' && proc.notice.replace(' ', '') != ''
      if (notice == null) {
        return true;
      }
      if (notice == undefined) {
        return true;
      }
      if (notice == '') {
        return true;
      }
      if (notice.replace(' ', '') == '') {
        return true;
      }

      //console.log("notice", notice, typeof notice);
      return false;
    },

    isVideo: function() {
      var path = this.prop.basepath + this.prop.videopath + '';
      if (typeof path !== 'string') {
        return false;
      } else {
        var isVideo = false;
        isVideo = isVideo || path.endsWith('.mp4');
        isVideo = isVideo || path.endsWith('.MP4');
        return isVideo;
      }
    },
    isPdf: function() {
      var path = this.prop.basepath + this.prop.videopath + '';
      if (typeof path !== 'string') {
        return false;
      } else {
        var isPdf = false;
        isPdf = isPdf || path.endsWith('.pdf');
        isPdf = isPdf || path.endsWith('.PDF');
        return isPdf;
      }
    },
    isImage: function() {
      var path = this.prop.basepath + this.prop.videopath + '';
      if (path == undefined || path == null) {
        return false;
      }

      // !name.endsWith(".jpg") || !name.endsWith(".jpeg") || !name.endsWith(".png") || !name.endsWith(".svg") || !name.endsWith(".gif")
      var isImage = false;
      isImage = isImage || path.endsWith('.jpg');
      isImage = isImage || path.endsWith('.jpeg');
      isImage = isImage || path.endsWith('.png');
      isImage = isImage || path.endsWith('.svg');
      isImage = isImage || path.endsWith('.gif');
      isImage = isImage || path.endsWith('.JPG');
      isImage = isImage || path.endsWith('.JPEG');
      isImage = isImage || path.endsWith('.PNG');
      isImage = isImage || path.endsWith('.SVG');
      isImage = isImage || path.endsWith('.GIF');

      return isImage;
    }
  },
  methods: {
    modalSkip() {
      this.showModalSkip = true;
    },
    modalCompleted() {
      this.showModalCompleted = true;
    },
    closeModalSkip() {
      this.showModalSkip = false;
    },
    closeModalCompleted() {
      this.showModalCompleted = false;
    },
    closeModalTaskCompleted() {
      this.showModalTaskCompleted = false;
    },
    isLastProcedure(index) {
      return index === this.props.length - 1;
    },
    toggle: function() {
      this.$emit('toggle', 'normal');
    },
    skip: function() {
      this.$emit('skip', 'skip');
    },
    completed: function() {
      this.$emit('complete', 'complete');
    },
    outputReport: function() {
      this.$emit('output-report', 'report');
    },

    skipByCurrentProc: function(targetProc) {

      //選択中のチェックリスト以外のボタンを押すことが可能なため、ボタンを押したチェックリスト（targetProc）を対象にする。
      targetProc.status = 'partial';
      this.$store.commit('nextProc', targetProc);

      // 作業手順をスキップした事をログに記録する →　F2 No.111 記録するタイミングをタスク完了/中断時に変更
      //Vue.ELearning.Logs.logWorkProcedureSkip( targetProc.id, targetProc.name );
      this.$store.commit('storeWorkProcedureForLog', targetProc);
    },

    completeByCurrentProc: function(targetProc) {

      //選択中のチェックリスト以外のボタンを押すことが可能なため、ボタンを押したチェックリスト（targetProc）を対象にする。
      targetProc.status = 'completed';
      this.$store.commit('nextProc', targetProc);

      // 作業手順を完了した事をログに記録する →　F2 No.111 記録するタイミングをタスク完了/中断時に変更
      //Vue.ELearning.Logs.logWorkProcedureComplete( targetProc.id, targetProc.name );
      this.$store.commit('storeWorkProcedureForLog', targetProc);
    },

    completeByTask() {
      // 作業手順を完了した事をログに記録する
      Vue.ELearning.Logs.logWorkProcedure();

      this.$store.dispatch('saveProgress', {
        complete: true,
        callback: () => {
          this.showModalTaskCompleted = false;
          this.$router.push( { path: `/user/tasks/${this.$store.state.current_category_id}` } );
        }
      });
    },
    saveHistory() {
      // 作業手順を完了した事をログに記録する
      Vue.ELearning.Logs.logWorkProcedure();

      this.$store.dispatch('saveProgress', {
        callback: () => {
          this.showModalSaveHistory = false;
          this.$router.push( { path: `/user/tasks/${this.$store.state.current_category_id}` } );
        }
      });
    },
    openSaveDialog() {
      const type = this.isCompleted ? 'showModalTaskCompleted' : 'showModalSaveHistory';
      this[type] = true;
    },

    closeModalDownload() {
      this.$emit('close-modal-download');
    }

  }
};
</script>
