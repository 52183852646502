<template>
    <div class="column has-background-white checklist-item" :style="itemStyle">
        <div class="contents-inner">
            <div class="contents-body">
                <!-- open　icon -->
                <a id="cont-open" @click="toggle">
                    <img src="/images/ico-right-arrow.png" alt="" />
                </a>
                <h2 class="title-list" v-text="itemName" />
                <template v-if="!hideContent">
                    <div class="video">
                        <div v-if="isVideo" style="width:90%">
                            <video-component :videoSrc="basePath + videoPath"></video-component>
                        </div>

                        <div v-else-if="isPdf" style="width:80%">
                            <a
                                :href="videoSrc"
                                target="_blank"
                                v-text="fileName"
                            /><br />
                            <object
                                :data="videoSrc"
                                type="application/pdf"
                                width="80%"
                                style="height:450px;"
                            />
                        </div>
                        <div v-else-if="isImage" style="width:90%">
                            <img :src="videoSrc" class="img-responsive" />
                        </div>
                        <div v-else>
                            <label></label>
                        </div>
                    </div>
                    <p class="category pb20" style="white-space:pre-wrap;" v-text="description" />
                    <div v-show="hasNotice" class="attention" v-text="notice" />
                </template>
            </div>
            <!-- contents-body-->
        </div>
    </div>
    <!-- column -->
</template>

<script>
import VideoComponent from '../../../commons/VideoComponent.vue';

export default {
    components: {
        VideoComponent,
    },
    props: {
        contentHeight: {
            type: Number,
            required: true,
        },
        index: {
            type: String | Number,
            default: null,
        },
        name: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        notice: {
            type: String,
            default: '',
        },
        basePath: {
            type: String,
            default: '',
        },
        videoPath: {
            type: String,
            default: '',
        },
        hideContent: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        itemName() {
            return `${this.index == undefined ? '' : this.index + '.'} ${
                this.name
            }`;
        },
        itemStyle() {
            return {
                maxHeight: `${this.contentHeight}px`,
            };
        },
        fileName() {
            return this.videoSrc.split('/').slice(-1)[0];
        },
        hasNotice() {
            return this.notice && this.notice.replace(' ', '').length > 0;
        },
        validSrc() {
            return this.videoSrc && this.videoSrc.length > 0;
        },
        videoSrc() {
            return `${this.basePath}${this.videoPath}`;
        },
        isVideo() {
            return (
                this.validSrc &&
                (this.videoSrc.endsWith('mp4') || this.videoSrc.endsWith('MP4'))
            );
        },
        isPdf() {
            return (
                this.validSrc &&
                (this.videoSrc.endsWith('pdf') || this.videoSrc.endsWith('PDF'))
            );
        },
        isImage() {
            return (
                this.validSrc &&
                (this.videoSrc.endsWith('.jpg') ||
                    this.videoSrc.endsWith('.jpeg') ||
                    this.videoSrc.endsWith('.png') ||
                    this.videoSrc.endsWith('.svg') ||
                    this.videoSrc.endsWith('.gif') ||
                    this.videoSrc.endsWith('.JPG') ||
                    this.videoSrc.endsWith('.JPEG') ||
                    this.videoSrc.endsWith('.PNG') ||
                    this.videoSrc.endsWith('.SVG') ||
                    this.videoSrc.endsWith('.GIF'))
            );
        },
    },
    methods: {
        toggle() {
            this.$emit('toggle');
        },
        skip() {
            this.$emit('skip');
        },
        completed() {
            this.$emit('completed');
        },
    },
};
</script>

<style scoped lang="scss">
.checklist-item {
    overflow-y: auto;

    .contents-inner {
        @media screen and (max-height: 800px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .button {
            color: #fff;
        }
    }
}
</style>
