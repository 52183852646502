var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "embed-responsive embed-responsive-16by9 video-thumbnail-container",
      staticStyle: { "margin-top": "0" }
    },
    [
      _vm.isNew
        ? _c("div", [
            _c("div", { staticClass: "status-new" }, [_vm._v("NEW")]),
            _vm._v(" "),
            _vm.isUpdate
              ? _c(
                  "div",
                  { staticClass: "status", staticStyle: { left: "4em" } },
                  [_vm._v("UPDATE")]
                )
              : _vm._e()
          ])
        : _vm.isUpdate
        ? _c("div", { staticClass: "status" }, [_vm._v("UPDATE")])
        : _vm._e(),
      _vm._v(" "),
      _c("video", {
        staticClass: "video-thumbnail embed-responsive-item",
        attrs: {
          src: _vm.video_src,
          muted: "",
          playsinline: "",
          preload: "metadata"
        },
        domProps: { muted: true },
        on: {
          contextmenu: function($event) {
            $event.preventDefault()
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }