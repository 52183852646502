<template>
  <div class="container">
    <div class="wrapper columns is-multiline">
      <template v-for="(item, index) in props">
        <div :key="item.id">
          <div class="blockToggle">
            <work-procedure
              :key="item.work_procedure_id"
              :item="item"
              :is-last="isLastProcedure(index)"
              :is-active="isActiveProc(item)"
              :is-open-detail="detailShowed(item)"
              :on-skip="skipProc"
              :on-complete="completeProc"

              @active="activeProc([item])"
              @open-detail-toggle="detailShowToggle(item)"
              action-name="updateCurrentProcedure"
            ></work-procedure>

            <transition name="checklist-detail">
              <template v-if="detailShowed(item)">
                <check-list-detail
                  :key="item.id"
                  :content-height="100"
                  :index="0"
                  :name="item.name"
                  :notice="item.notice"
                  :description="item.description"
                  :video-id="item.video_id"
                  :base-path="item.basepath"
                  :video-path="item.videopath"
                  :hide-content="hideContent"
                  @skip="modalSkip(item)"
                  @completed="modalCompleted(item)"
                  @video-play="$emit('video-play')"
                />
              </template>
            </transition>
          </div>
          
          <!-- サブ手順 -->
          <template v-for="(child, index) in item.child">
            <div class="blockToggle" :key="child.id">
              <work-procedure
                :item="child"
                :is-last="isLastProcedure(index)"
                :is-active="isActiveProc(child)"
                :is-open-detail="detailShowed(child)"
                :on-skip="skipProc"
                :on-complete="completeProc"
                @open-detail-toggle="detailShowToggle(child)"
                @active="activeProc([child, item])"
                action-name="updateCurrentProcedure"
              />
              <transition name="checklist-detail">
                <template v-if="detailShowed(child)">
                  <check-list-detail
                    :content-height="100"
                    :index="0"
                    :name="child.name"
                    :notice="child.notice"
                    :description="child.description"
                    :base-path="child.basepath"
                    :video-id="child.video_id"
                    :video-path="child.videopath"
                    :hide-content="hideContent"
                    @skip="modalSkip(item)"
                    @completed="modalCompleted(item)"
                    @video-play="$emit('video-play')"
                  />
                </template>
              </transition>
            </div>
          </template>
        </div>
      </template>
    </div>

    <a
      href="#"
      class="floatArea floatArea-save"
      v-if="!isCompleted"
      @click.prevent="openSaveDialog"
    >
      <p>保存して中断</p>
    </a>

    <a href="#" class="floatArea floatArea-comp" v-if="isCompleted" @click.prevent="openSaveDialog">
      <p>完了</p>
    </a>

    <confirm-modal
      v-if="showModalCompleted"
      :continue-func="completeByCurrentProc"
      :close-func="closeModalCompleted"
      title="タスク完了の確認"
      message="タスクを完了しますか？"
    />

    <confirm-modal
      v-if="showModalSkip"
      :continue-func="skipByCurrentProc"
      :close-func="closeModalSkip"
      title="タスクスキップの確認"
      message="タスクをスキップしますか？"
    />

    <confirm-modal
      v-if="showModalTaskCompleted"
      :continue-func="completeByTask"
      :close-func="closeModalTaskCompleted"
      title="タスク完了の確認"
      message="タスクを完了しますか？"
    />

    <modal-component
      v-if="showModalSaveHistory"
      is-quest="true"
      @modalYes="saveHistory"
      @modalNo="showModalSaveHistory = false"
    >
      <h4 slot="header">確認</h4>
      <h3 slot="body" style="text-align: center;">
        <p class="tc pb2em" style="padding-bottom:0;">
          <strong>タスクを保存して中断しますか？</strong>
        </p>
      </h3>
    </modal-component>

    <task-download-modal
      v-if="showModalDownload"
      :downloaded="taskDownloaded"
      @close-modal-download="closeModalDownload"
    />
  </div>
</template>
<script>
import WorkProcedure from "../../common/components/WorkProcedure.vue";
import ModalComponent from "../../../commons/ModalComponent.vue";
import VideoComponent from "../../../commons/VideoComponent.vue";
import CheckListDetail from "./CheckListDetail.vue";
import ConfirmModal from "../../common/components/ConfirmModal.vue";
import TaskDownloadModal from "../../common/components/TaskDownloadModal.vue";

export default {
  components: {
    WorkProcedure,
    ModalComponent,
    VideoComponent,
    CheckListDetail,
    ConfirmModal,
    TaskDownloadModal
  },

  props: {
    prop: {},
    props: {
      type: Array,
      default: function() {
        return [];
      }
    },
    showModalDownload: false,
    taskDownloaded: false
  },
  data() {
    return {
      showModalCompleted: false,
      showModalSkip: false,
      showModalTaskCompleted: false,
      showModalSaveHistory: false,

      activeProcIds: [],
      showDetailList: [],

      currentProc: null
    };
  },
  computed: {
    isCompleted() {
      const recursive = procs => {
        return procs.filter(proc => proc.status === "incomplete");
      };

      return !Boolean(
        this.props.filter(proc => {
          if (proc.child && proc.child.length) {
            if (recursive(proc.child).length !== 0) {
              return true;
            }
          }

          return proc.status === "incomplete";
        }).length
      );
    },

    hideContent() {
      return false;
      //return this.prop.branch_id == 0 && this.prop.child.length > 0;
    }
  },
  mounted: function() {},

  methods: {
    isLastProcedure(index) {
      return index === this.props.length - 1;
    },
    modalSkip(proc) {
      this.currentProc = proc;
      this.showModalSkip = true;
    },
    modalCompleted(proc) {
      this.currentProc = proc;
      this.showModalCompleted = true;
    },
    closeModalSkip() {
      this.currentProc = null;
      this.showModalSkip = false;
    },
    closeModalCompleted() {
      this.currentProc = null;
      this.showModalCompleted = false;
    },
    closeModalTaskCompleted() {
      this.currentProc = null;
      this.showModalTaskCompleted = false;
    },

    //リストのスキップボタンイベント
    skipProc: function(proc) {
      this.currentProc = proc;
      this.skipByCurrentProc();
    },
    //リストの完了ボタンイベント
    completeProc: function(proc) {
      this.currentProc = proc;
      this.completeByCurrentProc();
    },

    skipByCurrentProc: function() {
      this.showModalSkip = false;

      this.currentProc.status = "partial";
      this.$store.commit("nextProc", this.currentProc);

      // 作業手順をスキップした事をログに記録する
      this.$store.commit("storeWorkProcedureForLog", this.currentProc);
    },

    completeByCurrentProc: function() {
      this.showModalCompleted = false;

      this.currentProc.status = "completed";
      this.$store.commit("nextProc", this.currentProc);

      // 作業手順を完了した事をログに記録する
      this.$store.commit("storeWorkProcedureForLog", this.currentProc);
    },

    completeByTask() {
      // 作業手順を完了した事をログに記録する
      Vue.ELearning.Logs.logWorkProcedure();

      this.$store.dispatch("saveProgress", {
        complete: true,
        callback: () => {
          this.showModalTaskCompleted = false;
          this.$router.push({
            path: `/user/tasks/${this.$store.state.current_category_id}`
          });
        }
      });
    },
    saveHistory() {
      // 作業手順を完了した事をログに記録する
      Vue.ELearning.Logs.logWorkProcedure();

      this.$store.dispatch("saveProgress", {
        callback: () => {
          this.showModalSaveHistory = false;
          this.$router.push({
            path: `/user/tasks/${this.$store.state.current_category_id}`
          });
        }
      });
    },

    openSaveDialog() {
      const type = this.isCompleted
        ? "showModalTaskCompleted"
        : "showModalSaveHistory";
      this[type] = true;
    },

    closeModalDownload() {
      this.$emit("close-modal-download");
    },

    activeProc(procs) {
      this.activeProcIds = [];
      procs.forEach(proc => {
        this.activeProcIds.push(proc.id);
      });

      if (procs.length == 1 && procs[0].child.length > 0) {
        this.activeProcIds.push(procs[0].child[0].id);
      }
    },
    isActiveProc(proc) {
      return this.activeProcIds.some(id => id == proc.id);
    },

    detailShowed(proc) {
      return this.showDetailList.some(id => id == proc.id);
    },

    detailAllShowed() {
      var count = 0;
      this.props.forEach(proc => {
        if (proc.child.length > 0) {
          proc.child.forEach(child => count++);
        } else {
          count++;
        }
      });

      if (this.showDetailList.length < count) {
        return false;
      } else {
        return true;
      }
    },

    detailShowToggle(proc) {
      if (this.detailShowed(proc)) {
        //削除
        this.showDetailList = this.showDetailList.filter(id => id != proc.id);
      } else {
        //追加
        this.showDetailList.push(proc.id);
      }

      if (this.detailAllShowed()) {
        this.$emit("sp-detail-all-showed");
      } else {
        this.$emit("sp-detail-has-close");
      }
    },

    detailShowAllToggle() {
      if (this.detailAllShowed()) {
        //すべて閉じる
        this.showDetailList = [];
        this.$emit("sp-detail-has-close");
      } else {
        //全て開く
        this.showDetailList = [];
        this.props.forEach(proc => {
          if (proc.child.length > 0) {
            proc.child.forEach(child => {
              this.showDetailList.push(child.id);
            });
          } else {
            this.showDetailList.push(proc.id);
          }
        });
        this.$emit("sp-detail-all-showed");
      }
    }
  }
};
</script>
<style scoped>
.checklist-detail-enter-active,
.checklist-detail-leave-active {
  transition: all 0.4s ease-out;
}

.checklist-detail-enter-to,
.checklist-detail-leave {
  max-height: 100vh;
}

.checklist-detail-enter,
.checklist-detail-leave-to {
  max-height: 0px;
}
</style>