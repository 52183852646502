var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "modal" }, on: { afterLeave: _vm.onPasswordLeave } },
        [
          _vm.showModalChangePassword
            ? _c(
                "modal-component",
                {
                  attrs: {
                    "is-save": !_vm.forceChange,
                    "is-change": _vm.forceChange,
                    width: "600"
                  },
                  on: {
                    modalYes: _vm.doChangePassword,
                    modalNo: _vm.cancelChangePassword
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "password",
                      attrs: { slot: "body" },
                      slot: "body"
                    },
                    [
                      _vm.forceChange
                        ? _c("h3", [
                            _c("strong", [
                              _vm._v("パスワードを変更してください。")
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "field columns" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label column",
                            class: {
                              error: _vm.hasErrPassword([
                                "old_password",
                                "auth"
                              ])
                            }
                          },
                          [_vm._v("旧パスワード")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "control column" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentPassword,
                                expression: "currentPassword"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "error-ctrl": _vm.hasErrPassword([
                                "old_password",
                                "auth"
                              ])
                            },
                            attrs: { type: "password", autocomplete: "off" },
                            domProps: { value: _vm.currentPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.currentPassword = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "error" }, [
                            _vm._v(
                              _vm._s(_vm.errPasswordMessage("old_password"))
                            )
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "error" }, [
                            _vm._v(_vm._s(_vm.errPasswordMessage("auth")))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "field columns" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label column",
                            class: {
                              error: _vm.hasErrPassword(["new_password"])
                            }
                          },
                          [_vm._v("新パスワード")]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "control column" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newPassword,
                                expression: "newPassword"
                              }
                            ],
                            staticClass: "input",
                            class: {
                              "error-ctrl": _vm.hasErrPassword(["new_password"])
                            },
                            attrs: { type: "password", autocomplete: "off" },
                            domProps: { value: _vm.newPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.newPassword = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "error" }, [
                            _vm._v(
                              _vm._s(_vm.errPasswordMessage("new_password"))
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "field columns" }, [
                        _c(
                          "label",
                          {
                            staticClass: "label column",
                            attrs: { autocomplete: "off" }
                          },
                          [
                            _vm._v("\n            新パスワード\n            "),
                            _c("span", [_vm._v("(確認)")])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "control column" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.confirmPassword,
                                expression: "confirmPassword"
                              }
                            ],
                            staticClass: "input",
                            attrs: { type: "password" },
                            domProps: { value: _vm.confirmPassword },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.confirmPassword = $event.target.value
                              }
                            }
                          })
                        ])
                      ])
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal" }, on: { afterLeave: _vm.onSavedLeave } },
        [
          _vm.showModalChangeComplete
            ? _c(
                "modal-component",
                {
                  attrs: { width: "600" },
                  on: {
                    modalOk: function($event) {
                      _vm.showModalChangeComplete = false
                    }
                  }
                },
                [
                  _c(
                    "h3",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { slot: "body" },
                      slot: "body"
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "tc pb2em",
                          staticStyle: { "margin-top": "0.5em" }
                        },
                        [_c("strong", [_vm._v("パスワードを変更しました")])]
                      )
                    ]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }