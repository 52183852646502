<template>
    <div class="embed-responsive embed-responsive-16by9 video-thumbnail-container" style="margin-top:0;">
        <div v-if="isNew">
            <div class="status-new">NEW</div>
            <div v-if="isUpdate" class="status" style="left:4em;">UPDATE</div>
        </div>
        <div v-else-if="isUpdate" class="status">UPDATE</div>
        <video class="video-thumbnail embed-responsive-item" v-bind:src="video_src" @contextmenu.prevent muted playsinline preload="metadata"></video>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },

        props: {
            path: String,

            isUpdate:Boolean,
            isNew:Boolean,
        },

        mounted: function(){
            this.$forceUpdate()
        },

        computed: {
            video_src: function() {

                const ua = navigator.userAgent;
                const isIOS = ua.indexOf("iPhone") >= 0 || ua.indexOf("iPad") >= 0  || navigator.userAgent.indexOf("iPod") >= 0
                if(isIOS){
                    return this.path + "#t=0.1";
                }
                return this.path;
            }
        },
    }
</script>
<style>
    .video-thumbnail {
        border-radius:1.1em;
        -webkit-border-radius: 1.1em;
        -moz-border-radius: 1.1em;
    }

    /*
    .video-thumbnail:hover {
        
    }
    */

    .video-thumbnail-container:hover {
        background-color:#E8F8E2
    }


    .status-new {
        z-index:10;
        position:absolute;
        background-color:red;
        color:white;
        font-weight:bold;
        top:1em;
        left:1em;

        opacity: 0.9;

        padding:2.5px;

        border-radius:0.5em;
        -webkit-border-radius: 0.5em;
        -moz-border-radius: 0.5em;
    }
</style>
