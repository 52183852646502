<template>
    <div>
        <template v-for="categoryTask in currentGroupTasks">
            <div class="row category" style="border:1px solid #d3d3d3;background-color:#EEEEEE;" v-bind:style="{color:categoryColor(categoryTask)}">
                <div class="col-md-8" style="line-height:1.5em;"><span style="font-size:1.5em;padding-top:0.5em;vertical-align:middle;">{{ categoryTask.categoryName }}</span></div>
                <div class="col-md-4" style="text-align:right;">
                    <plus-minus v-bind:indicator="groupTaskIndicator(categoryTask)" @indicator-click="categoryTask.isOpen = !categoryTask.isOpen" style="font-size:1.5em;cursor:pointer;"></plus-minus>
                </div>
            </div>

            <div v-if="categoryTask.isOpen" class="row" style="border-collapse: collapse;">
                <template v-for="(task, idx) in categoryTask.tasks">
                    <div class="col-md-6" style="line-height:2.5em;border:1px solid #d3d3d3;border-top:0;" v-bind:class="{'bottom-task-left': isBottomTaskLeft(categoryTask.tasks, idx), 'bottom-task-right': isBottomTaskRight(categoryTask.tasks, idx)}" v-bind:style="{'border-right':categoryTaskBorderRight(idx, categoryTask.tasks.length), color:taskColor(task), 'font-weight':taskWeight}" :key="categoryTask.categoryId + '-' + task.taskId">
                        <base-check-component v-if="isSelectionMode" v-on:check-state-change="onCheckStateChange(task)" v-bind:value="task.selected"></base-check-component>
                        <span v-if="isSelectionMode">&nbsp;&nbsp;</span>

                        <span v-if="isSelectionMode" style="cursor:pointer;" @click="onCheckStateChange(task)">{{ task.taskName }}</span>
                        <span v-else>{{ task.taskName }}</span>
                    </div>

                    <div v-if="categoryTask.tasks.length - 1 == idx && (categoryTask.tasks.length % 2) == 1" class="col-md-6 bottom-task-right" style="line-height:2.5em;border:1px solid #d3d3d3;border-top:0;"></div>
                </template>
            </div>
            <div class="row" style="margin-bottom:1em;height:1px;"></div>

        </template>
    </div>
</template>
<script>
    import BaseCheckComponent from './BaseCheckComponent.vue';
    import PlusMinus from './PlusMinus.vue';

    export default {
        methods: {
            isBottomTaskLeft: function(tasks, index) {
                var taskSize = tasks.length;

                if(taskSize % 2 == 0) {
                    if(taskSize - 2 == index) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if(taskSize - 1 == index) {
                        return true;
                    } else {
                        return false;
                    }
                    /*return false;*/
                }
            },
            isBottomTaskRight: function(tasks, index) {
                var taskSize = tasks.length;

                if(taskSize % 2 == 0) {
                    if(taskSize - 1 == index) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    /*if(taskSize - 1 == index) {
                        return true;
                    } else {
                        return false;
                    }*/
                    return false;
                }
            },

            groupTaskIndicator: function(groupTask) {
                return groupTask.isOpen ? 'close' : 'open';
            },

            onGroupTaskIndicatorClick: function(groupTask) {
                groupTask.isOpen = !groupTask.isOpen;
            },

            categoryTaskBorderRight: function(index, size){
                //console.log(index, size);

                if((index + 1) % 2 == 0) {
                    return "1px solid #d3d3d3";
                } else {
                    return 0;
                }
            },

            taskSelected: function(task){
                task.selected = !task.selected;
            },

            categoryColor: function(categoryItem){
                var taskSize = categoryItem.tasks.length;

                var selectedItem = 0;

                for(var key in categoryItem.tasks) {
                    var item = categoryItem.tasks[key];
                    if(item.selected) {
                        selectedItem++;
                    }
                }

                if(selectedItem == 0) {
                    return "#d3d3d3";
                } else {
                    return "black";
                }
            },

            taskColor: function(task){
                if(task.selected) {
                    return "#4FBE2B";
                } else {
                    return "#d3d3d3";
                }
            },

            taskWeight: function(task){
                if(task.selected) {
                    return "bold";
                } else {
                    return "normal";
                }
            },

            onCheckStateChange(task) {
                task.selected = !task.selected;
            },
        },
        props: {
            currentGroupTasks:Array,
            isSelectionMode:Boolean,
        },

        components: {
            BaseCheckComponent,
            PlusMinus
        }
    }
</script>
<style scoped>
    .category {
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }

    .bottom-task-left {


        border-bottom-left-radius: 1em;
    }
    .bottom-task-right {


        border-bottom-right-radius: 1em;
    }
</style>
