<template>
    <div class="alert-container">
        <div id="alert" class="ico-alert2 sp-hidden" @click="toggle">
            <img src="/images/ico-alert2.svg" width="22px"/>
            <span v-show="count > 0">{{ count }}</span>
        </div>

        <div class="notifications sp-hidden" v-if="isOpen">
            <div class="notifications-wrap container" @click="toggle"></div>
            <div class="notifications-inner">
                <div class="hd">
                    お知らせ
                    <span class="close-cursor" @click="toggle">
                        <img src="/images/icon_time_circle.png">
                    </span>
                </div>
                <div class="notifications-table-wrapper overflow-auto">
                    <table class="table mb-0">
                        <template v-if="notifications.length > 0">
                            <tr v-for="(row, key) in notifications" :key="key">
                                <td class="notifications-date">
                                    <span class="unread" v-if="row.unread"></span>
                                    <span class="readed" v-if="!row.unread"></span>
                                    {{ row.create_datetime | moment }}
                                </td>
                                <td>{{ row.name }}</td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td>お知らせはありません。</td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>

        <!-- SP >>-->
        <div class="pc-hidden">
            <template v-if="notifications.length > 0">
                <div class="notice" v-for="(row, key) in notifications" :key="key + '_02'">
                        <span class="unread" v-if="row.unread"></span>
                        <span class="readed" v-if="!row.unread"></span>
                    <p>
                        {{ row.create_datetime | moment }}</p>
                    <p>{{ row.name }}</p>
                </div>
            </template>
            <template v-else>
                <div class="notice"><p>お知らせはありません。</p></div>
            </template>
        </div>
        <!-- << SP -->
    </div>
</template>

<script>
export default {
    props:{
        isSpTypeOpen: Boolean,
    },

    data() {
        return {
            isOpen: false,
            count: 0,
            notifications: [],
        }
    },

    methods: {
        fetch() {
            return new Promise((resolve, reject) => {
                Vue.ELearning.Notice.getAll()
                    .then(res => res.data.content)
                    .then(data => {
                        this.count = data.totalUnread;
                        this.notifications = data.data;
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        },

        /**
         * お知らせ既読情報を、ローカルストレージに保存されたお知らせ既読情報とマージする
         */
        async merge() {
            const read_notice_id = await Vue.Download.getStoredReadNoticeId();

            if ( ! read_notice_id )
            {
                return;
            }

            for ( let notice of this.notifications )
            {
                notice.unread = notice.id <= read_notice_id;
            }

            this.count = _.sumBy( this.notifications, n => n.unread ? 0 : 1 );
        },

        async pull() {
            await this.fetch();
            await this.merge();
        },

        markReaded() {
            return new Promise((resolve, reject) => {
                if(this.count) {
                    const notifications = _.clone(this.notifications).shift()

                    if (!notifications) {
                        resolve()
                    }

                    return Vue.ELearning.Notice.readed(notifications.id)
                            .then(res => resolve(res))
                            .then(data => {
                            })
                            .catch(err => reject(err))
                }
            })
        },

        toggle() {
            this.isOpen = !this.isOpen;
            if(!this.isOpen) {
                this.notifications.forEach((notification) => {
                    notification.unread = false;
                });
            }
        },
    },

    mounted() {
        this.pull();
    },

    watch: {
        isOpen(boolean) {
            if (boolean && this.count !== 0) {
                this.markReaded()
            }

            this.count = 0
        },

        //SP用オープンWatch　既読にする
        isSpTypeOpen(boolean){
            this.isOpen = boolean;

            if(!boolean) {
                this.notifications.forEach((notification) => {
                    notification.unread = false;
                });
            }

        },

        //SP用　unreadカウント取得イベント
        count(num) {
            this.$emit("get-unread", num);
        }
    },
}

</script>

<style scoped lang="scss">
    .alert-container{
        position: relative;
    }
    #alert {
        margin-right: 20px;
        &:hover {
            cursor: pointer;
        }
    }
    .notifications {
        &-inner {
            position :absolute;
            top: 49px;
            right: -5px;
            width: 450px;
            height: calc(100vh - 130px);
            margin: 0 10px 0 auto;
            max-height: 600px;
            background: #fff;
            z-index: 1040;
            filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.2));
            &:before {
                content: "";
                position: absolute;
                top: -36px;
                right: 8px;
                border: 18px solid transparent;
                border-bottom: 18px solid #fff;
            }
        }

        &-table-wrapper {
            height:calc(100% - 40px);
            table td{
                padding: 0.5em 1.25em;
                &:nth-of-type(2){
                    padding-left: 0.25em;
                }
            }
        }

        &-wrap {
            background: transparent;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1030;
            @media screen and (min-width: 1088px){
                max-width: 100%;
            }
            @media (min-width: 992px){
                max-width: 100%;
            }

        }

        .hd {
            background: #fff;
            border-bottom: solid 1px #ccc;
            padding: 0.75em 1.25em;
            font-size: 13px;
            color: #333;
        }
    }
    .close-cursor {
        display: none;
        float: right;
        &:hover {
            cursor: pointer;
        }
    }

</style>
