<template>
    <common-layout-component :show-back="false" :show-navi-bar="false" title="ログイン" main-class="login_page">

        <template v-slot:body>
            <section class="bg_yellow">
                <div class="container pt50">
                    <div class="caption">
                        <h1>ログイン</h1>
                        <p>このサービスをご利用になるには<br> ログインしてください。
                        </p>
                    </div>
                    <div class="box bg_white">
                        <div class="item">
                            <p>メールアドレス</p>
                            <input type="text" name="email" placeholder="" v-model="email">
                        </div>
                        <div class="item">
                            <p>パスワード</p>
                            <input type="password" name="password" placeholder="" v-model="password">
                        </div>
                        <div class="item">
                            <input id="remember" type="checkbox" name="remember">
                            <label for="remember">ログイン状態を保存する</label>
                        </div>
                        <button class="mt20 button md" @click="doLogin">ログイン</button>
                    </div>
                    <div class="other_buttons">
                        <p class="icon"><a :href="$libs.url('')">パスワードをお忘れですか？<i class="fas fa-angle-right"></i></a></p>
                        <div class="inner">
                            <button class="bg_accent button md" @click="$libs.pushUrl('register')">無料新規登録はこちら</button>
                            <a :href="$libs.url('')" class="icon">みそしる教室 トップへ<i class="fas fa-angle-right"></i></a>
                        </div>
                    </div>
                </div>
            </section>
            <modal-component v-if="showModal" is-quest="false" @modalOk="showModal = false" >
                <h4 slot="header">パスワードの確認</h4>
                <h2 slot="body" style="text-align: center">管理者にお問い合わせください。</h2>
            </modal-component>

            <modal-component v-if="showResendVerificationModal" is-quest="false" @modalOk="showResendVerificationModal = false" >
                <h4 slot="header">確認コードの再送信</h4>
                <h2 slot="body" style="text-align: center">確認コードをメールアドレスに送信しました</h2>
            </modal-component>

            <modal-component v-if="showSendVerificationErrorModal" is-quest="false" @modalOk="showSendVerificationErrorModal = false" >
                <h4 slot="header">エラー</h4>
                <h2 slot="body" style="text-align: center">しばらく経ってから再度お試しください</h2>
            </modal-component>

        </template>

    </common-layout-component>
</template>

<script>

import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

export default {
    components:{
        CommonLayoutComponent,
        ModalComponent
    },

    data() {
        return {
            showModal: false,
            isError: false,

            email: '',
            password: '',
            remember: '',
            message: '',
            verificationCode: '',

            errMailAddress: false,
            errPassword: false,
            errAuth: false,
            mailAddressErrors: [],
            passwordErrors: [],
            authErrors: [],

            isProcessing: false,
            showVerification: false,
            showResendVerificationModal: false,
            showSendVerificationErrorModal: false,
        }
    },

    methods: {
        errorHandler: function(content) {
            if (content.errors == undefined) {
                return true;
            } else {
                console.error(content.errors);
            }

            if (content.errors.mail_address != undefined) {

                this.errMailAddress = true;
                this.mailAddressErrors = [];
                for (var key in content.errors.mail_address) {
                    var item = content.errors.mail_address[key];
                    this.mailAddressErrors.push(item);
                }
            }
            if (content.errors.password != undefined) {

                this.errPassword = true;
                this.passwordErrors = [];
                for (var key in content.errors.password) {
                    var item = content.errors.password[key];
                    this.passwordErrors.push(item);
                }
            }
            if (content.errors.auth != undefined) {

                this.errAuth = true;
                this.authErrors = [];
                for (var key in content.errors.auth) {
                    var item = content.errors.auth[key];
                    this.authErrors.push(item);
                }
            }

            if (content.errors.verification != undefined) {
                this.errVerification = true;
                this.verificationErrors = [content.errors.verification];
            }
            return false;
        },


        login(authType) {

            console.log(this.email);
            this.errMailAddress = false;
            this.mailAddressErrors = [];
            this.errPassword = false;
            this.passwordErrors = [];
            this.errAuth = false;
            this.authErrors = [];
            this.errVerification = false;
            this.verificationErrors = [];

            this.message = "";
            this.isError = false;
            var isInvalid = false;

            if (this.username === '') {
                isInvalid = true;
            }
            if (this.password === '') {
                isInvalid = true;
            }

            this.isProcessing = true;

            this.$store.commit('overlay', true);

            // 認証処理
            var res = this.$store.dispatch(authType, {
                'userId': this.email,
                'password': this.password,
                'remember': (this.remember ? 'on' : ''),
                'code': this.verificationCode,
            });

            res.then(function(res) {
                //console.log(res);

                var content = res.data;

                console.log("response", content);

                if (content.status_number == "200") {

                    console.log("user logged in : ", content.content);

                    try {
                        this.$store.commit("login", {
                            'id': content.content.id,
                            'userName': content.content.display_name,
                            'grant': content.content.user_grant,
                            'initialPassword': content.content.initial_password,
                            'authToken': content.content["auth-token"],
                            'remember': this.remember
                        });
                    
                        const urlObj = this.$libs.urlObj()
                        if (urlObj.query.redirect) {
                            this.$libs.pushUrl(urlObj.query.redirect);
                        } else {
                            this.$libs.pushUrl("")
                        }
                    } catch (e) {
                        console.error(e);
                    }

                } else if (content.status_number == "401") {

                    if (content.content["verification-requrired"]) {

                        if (content.content["error"] == true) {
                            this.showSendVerificationErrorModal = true;

                        } else {
                            //2要素認証
                            this.showVerification = true;
                        }

                    } else {
                        //認証失敗 401で返却される
                        var result = this.errorHandler(content.content);
                        if (!result) {
                            this.isProcessing = false;
                            this.$store.commit('overlay', false);
                            return;
                        }
                    }

                } else {
                    console.error("バリデーションエラー");
                    var msg = content.content.message;
                    this.message = msg;
                    console.log(msg);
                }

                this.isProcessing = false;
                //this.$store.commit('overlay', false);

            }.bind(this)).catch(function(err) {
                console.error(err);
                this.isProcessing = false;
                this.$store.commit('overlay', false);

            }.bind(this));

            return;
        },

        doLogin() {
            this.login("authenticate");
        },
        doVerification() {
            this.login("verification");
        },
        resendVarification() {
            var handler = Vue.ELearning.Authenticatior.resendVerification({
                'userId': this.username,
            });

            handler.then(function(res) {

                var content = res.data;
                if (content.content["error"] == true) {
                    this.showSendVerificationErrorModal = true;
                } else {
                    this.showResendVerificationModal = true;
                }

            }.bind(this)).catch(function(res) {
                this.showSendVerificationErrorModal = true;

            }.bind(this));
        }
    },

    mounted() {
        if (this.$refs.email) {
            this.$refs.email.value = '';
        }
        if (this.$refs.password) {
            this.$refs.password.value = '';
        }

        if (this.$store.state.isLoggedInDestroyed) {
            this.$dialog.message("同じIDでログインされたため、ログアウトしました。");
            this.$store.commit('loginDestroyed', false);
            return;
        }

        // ユーザー情報取得
        var handler = Vue.ELearning.UserInfo.current();
        handler.then((response) => {
            //ログイン中ならリダイレクト
            if (response.data.status_number == 200) {
                this.$libs.pushUrl("mypage");
            }else{
                console.log("未ログイン");
            }
        }).catch((response) => {
            console.error(response);
        });
    }
}
</script>
<style scoped lang="scss">
</style>
