<template>
    <div
        class="a-task each-task column is-one-third videoBlock_item"
        @click.prevent.stop="gotoChecklist"
    >
        <div class="each-task-inner">
            <div class="task-images">
                <video-thumbnail v-if="useVideoThumbnail" :is-new="computedIsNew" :is-update="computedIsUpdate" :path="thumbnailPath" />
                <image-thumbnail v-else-if="useImageThumbnail" :is-new="computedIsNew" :is-update="computedIsUpdate" :path="thumbnailPath" />
                <pdf-thumbnail v-else-if="usePdfThumbnail" :is-new="computedIsNew" :is-update="computedIsUpdate" :path="thumbnailPath" :pdf-name="task.task_name" />

                <div v-else-if="task.task_size > 0 && task.thumbnail_url.video_path != undefined" class="row">
                    <div class="col-md-12">
                        <div class="jumbotron">
                            <div class="status-box">
                                <div v-if="computedIsNew" class="status-new">NEW</div>
                                <div v-if="computedIsUpdate" class="status">UPDATE</div>
                            </div>
                            <h3>サムネイルを表示できません</h3>
                        </div>
                    </div>
                </div>

                <div v-if="task.task_size == 0" class="row">
                    <div class="col-md-12">
                        <div class="jumbotron">
                            <div class="status-box">
                                <div v-if="computedIsNew" class="status-new">NEW</div>
                                <div v-if="computedIsUpdate" class="status">UPDATE</div>
                            </div>
                            <h3>作業手順が登録されておりません</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="task-info">
            <div v-if="isTaskStored" class="task-badge">ダウンロード済</div>
            <div class="task-title videoBlock_text" :class="{'icon-download':isTaskStored && isSmallView}">{{ task.task_name }}</div>
            <div :class="{'sp-hidden':isSmallView}" class="task-table-height">
                <table class="basic task-table">
                    <tr>
                        <td class="dates">
                            <span class="green"><i class="far fa-edit"></i>作成日</span>&nbsp;{{ task.create_datetime_for_show }}<br />
                            <span class="green"><i class="far fa-clock"></i>更新日</span>&nbsp;{{ task.update_datetime_for_show }}
                        </td>
                        <td class="dates">
                            <span class="green"><i class="fas fa-user"></i>作成者</span>&nbsp;{{ task.creator }}<br />
                            <span class="green"><i class="fas fa-user"></i>更新者</span>&nbsp;{{ task.updator }}
                        </td>
                    </tr>
                </table>
            </div>
            <p :class="{'sp-hidden':isSmallView}" class="task-text">{{ task.task_text }}</p>

            <div class="columns">
                <div :class="{'sp-hidden':isSmallView}" class="column p-relative has-tags">
                    <template v-for="(tag, index) in task.tags">
                        <span v-show="isShowTag(index)" :key="tag.tag_id" class="button basic is-tags" v-text="tag.tag_name" />
                    </template>
                    <img v-if="task.tags.length > 5 && !tagOpen" class="tag-open" v-bind:src="$store.state.documentRoot + '/images/ico-plus2.png'" alt="" @click.prevent.stop="tagOpen = !tagOpen" />
                    <img v-if="task.tags.length > 0 && tagOpen" class="tag-close" v-bind:src="$store.state.documentRoot + '/images/ico-minus.png'" alt="" @click.prevent.stop="tagOpen = !tagOpen" />
                </div>
                <div class="column is-one-third">
                    <!-- 完了アイコン -->
                    <!-- <img src="/images/ico-complete.png" alt=""> -->

                    <!-- 進捗アイコン -->
                    <div v-if="task.task_size > 0" class="col-md-3">
                        <circle-graph-component v-bind:value="progress" :size="progressSize"></circle-graph-component>
                    </div>
                    <div v-else>
                        <circle-graph-component v-bind:value="-1" :size="progressSize"></circle-graph-component>
                    </div>
                </div>
            </div>
            <!-- columns -->
            </div>

        </div>
        <!-- each-task-inner -->

        <!-- sp smallView用進捗アイコン -->
        <div v-if="task.task_size > 0 && progress > 0" class="sp-progress pc-hidden">
            <circle-graph-component v-bind:value="progress" :size="'small'"></circle-graph-component>
        </div>

    </div>
    <!--each-task-->
</template>
<script>
import CircleGraphComponent from '../../commons/CircleGraphComponent.vue';
import PlusMinus from '../../commons/PlusMinus.vue';
import VideoThumbnail from './components/VideoThumbnail.vue';
import ImageThumbnail from './components/ImageThumbnail.vue';
import PdfThumbnail from './components/PdfThumbnail.vue';

export default {
    components: {
        CircleGraphComponent,
        PlusMinus,
        VideoThumbnail,
        ImageThumbnail,
        PdfThumbnail,
    },

    mounted: function()
    {
        this.checkTaskStored();
        this.updateThumbnailPath();
    },

    computed: {
        useVideoThumbnail() {
            return (
                this.task.task_size > 0 &&
                this.task.thumbnail_url.video_path != undefined &&
                Vue.ELearning.Util.isVideoFileName( this.task.thumbnail_url.video_path )
            );
        },

        useImageThumbnail() {
            return (
                this.task.task_size > 0 &&
                this.task.thumbnail_url.video_path != undefined &&
                Vue.ELearning.Util.isImageFileName( this.task.thumbnail_url.video_path )
            );
        },
        usePdfThumbnail() {
            return (
                this.task.task_size > 0 &&
                this.task.thumbnail_url.video_path != undefined &&
                Vue.ELearning.Util.isPdfFileName( this.task.thumbnail_url.video_path )
            );
        },
        tagIndicator: function() {
            if (this.tagOpen) {
                return '－';
            } else {
                return '＋';
            }
        },
        openClose: function() {
            if (this.tagOpen) {
                return 'close';
            } else {
                return 'open';
            }
        },

        tagsForShow() {
            if (this.tagOpen) {
                return this.task.tags;
            } else {
                var result = [];
                var max = 3;
                var min = 1;

                for (var tag in this.task.tags) {
                    if (min <= max) {
                        result.push(this.task.tags[tag]);
                    } else {
                        break;
                    }
                    min++;
                }

                return /*this.taskInfo.tags*/ result;
            }
        },

        progress() {
            var base = this.task.task_size;
            var progress = this.task.progress;

            var ratio = 0;

            if (base != undefined && progress != undefined && base > 0) {
                ratio = (progress / base) * 100;
            }

            //console.log(Math.floor(ratio));
            return Math.floor(ratio);
        },

        computedIsNew() {
            
            return false; //F2 No.34 2020/02/13 Newは表記しないことになったため、常にFalse

            var create_datetime = this.task.create_datetime_for_show;
            /*
            var update_datetime = this.task.update_datetime_for_show;
            if(this.task.create_datetime != this.task.update_datetime) {
                return false;
            }
            */
            var dayTarget = new Date(create_datetime);
            var now = new Date(/*"2019/04/18"*/);

            var termDay = (now - dayTarget) / 86400000;

            //console.log("new-diff", termDay);

            if (termDay <= 7) {
                return true;
            }

            return false;
        },
        computedIsUpdate() {
            // is-newの場合でも表示させたいとのこと。
            //if (!this.computedIsNew) {
                // is-newでない場合に表示可能となる
                
                //var create_datetime = this.task.create_datetime_for_show;
                var update_datetime = this.task.update_datetime_for_show;
                var dayTarget = new Date(update_datetime);
                var now = new Date();

                var termDay = (now - dayTarget) / 86400000;

                console.log("update-diff:", termDay, "task:", this.task.task_name);

                //F2 No.34 2020/02/13 NewはUpdate扱いになったため、変更
                //if (termDay <= 7 && create_datetime != update_datetime) {
                if (termDay <= 7) {                    
                    return true;
                }
            //}
            return false;
        },

        isOnline() {
            return Vue.Download.isOnline();
        },

        progressSize() {
            if(this.isSmallView){
                return "large";
            }
            if(this.isSpModalView){
                return "medium"
            }
            return "large"
        }
    },

    data() {
        return {
            tagOpen: false,

            /** タスクがローカルにダウンロードされて保存さているか？ */
            isTaskStored: false,

            /** サムネイルのパス */
            thumbnailPath: "",
        };
    },
    methods: {
        isShowTag(index) {
            return index < 5 || (index >= 5 && this.tagOpen);
        },
        gotoChecklist() {

            if (this.isSmallView){
                this.$emit('select-task');
                return;
            }
            
            if (this.task.task_size == 0) {
                //console.log("select size 0");
                return;
            } else {
                //console.log("select");
                this.$emit('select-checklist');
            }
        },
        tagAreaChange() {
            this.tagOpen = !this.tagOpen;
        },

        async checkTaskStored() {
            let stored = await Vue.Download.isTaskStored( this.task.id );
            this.isTaskStored = stored;

            console.log( "checkTaskStored", this.task.id, stored, this.task );
        },

        async updateThumbnailPath()
        {
            if ( ! Vue.Download.isOnline() )
            {
                this.thumbnailPath = await Vue.Download.getFileURLByFileId( this.task.thumbnail_url.video_id );

                if ( ! this.thumbnailPath )
                {
                    this.thumbnailPath = "";
                }
            }
            else
            {
                this.thumbnailPath = this.task.thumbnail_url.video_base_path + this.task.thumbnail_url.video_path;
            }
        }
    },
    props: {
        task: Object,
        isSmallView: Boolean,
        isSpModalView: Boolean,
    },
};
</script>

<style lang="scss" scoped>

.has-tags {
    .button {
        margin-left: 0.3rem;
        cursor: inherit;
        text-decoration: none;
        &:hover {
            opacity: 1;
        }
    }
}
@media (min-width: 768px){
  .col-md-3 {
    padding: 0!important;
  }
}

.status-box{
    position: absolute;
    top: 1em;
    left: 1em;

    .status,.status-new{
        position: static;
        display: inline-block;
        margin-right: 2px;
    }
}
</style>
