<template>
        <div class="sp-footer pc-hidden" v-if="showHome">
            <dl>
                <dt class="home"><router-link to="/user/categories">ホーム</router-link></dt>
                <dt class="mypage"><a href="#" @click.prevent="isShowUser = true">マイページ</a></dt>
                <dt class="info"><a href="#" @click.prevent="isShowNotice = true">お知らせ</a><span class="badge" v-if="noticeUnread > 0">{{ noticeUnread }}</span></dt>
                <dt class="other"><a href="#" @click.prevent="isShowOther = true">その他</a></dt>

                <transition name="sp-footer">
                <dd class="mypage" v-if="isShowUser">
                    <div class="box">
                        <span class="close" @click.prevent="isShowUser = false"></span>
                        <p>{{ loginUserName }}</p>
                        <router-link to="/user/download">ダウンロードしたタスク一覧</router-link>
                        <a href="#" @click.prevent="$emit('logout')" v-show="isOnline && showLogout">ログアウト</a>
    　                  <a href="#" @click.prevent="$emit('change-password')" v-show="isOnline && showLogout">パスワード変更</a>
                    </div>
                </dd>
                </transition>
                <transition name="sp-footer">
                <dd class="info" v-show="isShowNotice">
                    <div class="box">
                    <p class="info_head">お知らせ</p><span class="close" @click.prevent="isShowNotice = false"></span>
                    <Notification :is-sp-type-open="isShowNotice" v-on:get-unread="getNoticeUnread($event)" />
                    </div>
                </dd>
                </transition>
                <transition name="sp-footer">
                <dd class="other" v-if="isShowOther">
                    <div class="box">
                        <span class="close" @click.prevent="isShowOther = false"></span>
                        <div class="other_head"><p>ダウンロード容量の目安</p><p class="other_count">{{ localDiskUsage }} / {{ localDiskTotal }}<span>GB</span></p></div>
                        <!--<a href="/">ヘルプ</a>-->
                    </div>
                </dd>
                </transition>
            </dl>
        </div>
</template>

<script>
import Notification from 'Components/commons/NotificationComponent.vue';

export default {
    components: {
        Notification,
    },

    props:{
        showHome: Boolean,
        showLogout: Boolean,
        showNotice: Boolean,
        loginUserName: String,
        localDiskUsage: String,
        localDiskTotal: String,
    },

    data: function() {
        return{
            isShowUser: false,
            isShowNotice: false,
            isShowOther: false,
            
            noticeUnread: 0
        }
    },
    computed: {
        isOnline() {
            return Vue.Download.isOnline();
        },
    },

    methods: {
        getNoticeUnread(count){
            this.noticeUnread = count;
        },
    }
}
</script>
<style scoped lang="scss">
.sp-footer-enter-active,
.sp-footer-leave-active {
    transition: opacity .3s;
    .box{
       transition: all .3s;
    }
}

.sp-footer-enter,
.sp-footer-leave-to {
    opacity: 0;
    .box{
        transform: translateY(100%);
    }
}
</style>