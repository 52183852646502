var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": true,
      title: "みそシル教室",
      "main-class": "home"
    },
    scopedSlots: _vm._u(
      [
        _vm.lessons
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "popular bg_yellow" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "caption" }, [
                        _c("h1", [_vm._v("人気のレッスン")])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "h_scroll_container" }, [
                        _c(
                          "div",
                          { staticClass: "flex_nw" },
                          [
                            _vm._l(_vm.$config.parts, function(item, index) {
                              return [
                                _c(
                                  "button",
                                  {
                                    key: index,
                                    staticClass: "cat_tab",
                                    on: {
                                      click: function($event) {
                                        return _vm.$libs.pushUrl(
                                          ["search", "result"],
                                          { query: { categories: item.value } }
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "lessons" },
                        [
                          _vm._l(_vm.lessons, function(lesson, index) {
                            return [
                              _c("lesson-cassette-component", {
                                key: index,
                                attrs: { lesson: lesson }
                              })
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.$libs.pushUrl(["search"])
                            }
                          }
                        },
                        [_vm._v("レッスンを探す")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "bg_white" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "caption" }, [
                        _c("h1", { staticClass: "key_color" }, [
                          _vm._v("人気の講師")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "teachers" },
                        [
                          _vm._l(_vm.teachers, function(user, index) {
                            return [
                              _c("user-cassette-component", {
                                key: index,
                                attrs: { user: user }
                              })
                            ]
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.$libs.pushUrl(["search"], {
                                query: { view: "teacher" }
                              })
                            }
                          }
                        },
                        [_vm._v("講師を探す")]
                      )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }