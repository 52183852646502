<template>
    <span class="plus-minus">
        <span v-if="indicator == 'open'">
            <i class="fas fa-plus-circle" @click="onClick(false)"></i>
        </span>
        <span v-if="indicator == 'close'">
            <i class="fas fa-minus-circle" @click="onClick(true)"></i>
        </span>
    </span>
</template>
<script>
    export default {
        methods: {
            onClick: function (val) {
                this.$emit("indicator-click", val);
            }
        },
        props: {
            indicator:String
        }
    }
</script>