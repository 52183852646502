<template>
  <common-layout-component2 show-back="true" show-admin="true" v-on:on-nav-back="back">
    <template v-slot:nav-message>
      <span class="nav-msg-sub">{{ categoryName }}</span>
      {{ taskName }}
    </template>
    <template v-slot:body>
      <div class="columns is-gapless">
        <!-- left menu -->
        <div
          class="column is-one-quarter"
          style="overflow-y:auto;"
          v-bind:style="{ 'max-height': contentHeight + 'px' }"
        >
          <div id="sidebar" style="margin:0;padding:0;">
            <p class="side-title"><b>作業手順</b></p>
            <template v-for="(item, index) in checklist">
              <work-procedure :key="item.uuid" :index="index" :item="item" @check="currentFlowOnChange" />
            </template>
          </div>
          <p class="tc pt10">
            <a class="button basic is-gray" style="color:white;" @click="preview">プレビュー</a>
            <a class="button basic is-login" style="color:white;" @click="save">保存</a>
          </p>
          <br />
        </div>

        <!-- right contents -->
        <div
          id="main-body-content-of-checklist"
          class="column has-background-white"
          style="overflow-y:auto;"
          v-bind:style="{ 'max-height': contentHeight + 'px' }"
        >
          <div class="contents-inner">
            <div class="field">
              <input
                type="radio"
                v-bind:checked="currentKind == 'single'"
                id="radio03-01"
                class="radio02-input"
                @change="currentKindChange('single')"
              />
              <label for="radio03-01"><b>一つの作業手順</b></label>

              <template v-if="!isChild">
                <input
                  type="radio"
                  v-bind:checked="currentKind == 'multi'"
                  id="radio03-02"
                  class="radio02-input"
                  @change="currentKindChange('multi')"
                />
                <label for="radio03-02"><b>複数の作業手順</b></label>
              </template>

              <input
                type="radio"
                v-bind:checked="currentKind == 'pdf'"
                id="radio03-03"
                class="radio02-input"
                @change="currentKindChange('pdf')"
              />
              <label for="radio03-03"><b>PDFアップロード</b></label>

                <template v-if="itemIndex === 0">
                    <button class="button basic is-gray" @click="importTaskCheck">
                    インポート&nbsp;<img src="/images/ico-import.png" alt="" />
                    </button>
                    <input id="input-file-csv" @change="selectedUploadCsv" type="file" name="file" class="d-none" />
                    <button class="button basic is-gray" @click="exportTaskCheck">
                    エクスポート&nbsp;<img src="/images/ico-export.png" alt="" />
                    </button>
                </template>

            </div>



            <div class="field">
              <label class="label">作業手順</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  v-model:balue="currentWorkProcName"
                  @change="currentNameChange($event.target.value)"
                />
              </div>
            </div>

            <template v-if="!hideContent">
              <div class="field">
                <label class="label">動画/画像</label>

                <div class="control columns">

                    <template v-if="isOverFileSizeSum">

                        <div class="alert alert-warning" role="alert">
                            <strong class="text">使用可能容量を超過しているので、これ以上アップロードできません。</strong>
                        </div>

                    </template>

                    <template v-else>

                        <div v-show="!isUploadDisabled" class="column">
                          <div id="droparea" @dragover.prevent="checkDrag($event, 'new', true)" @drop.prevent="onDrop">
                            <p class="leads">
                              ここにファイルをドラッグ
                            </p>
                            <p>または</p>
                            <p>
                              <label class="button basic is-gray" v-bind:disabled="isUploadDisabled">
                                ファイルを選択
                                <input
                                  type="file"
                                  class="drop__input"
                                  style="display:none;"
                                  id="corporation_file"
                                  @change="onDrop"
                                  v-bind:disabled="isUploadDisabled"
                                />
                              </label>
                            </p>
                            <p class="caution">100MB以上の動画はアップロードできません。<br>アップロードできるファイル形式は、動画：MP4、画像：PNG・JPEG、PDFです。</p>
                          </div>
                        </div>

                    </template>

                  <div v-show="currentFileErr != ''" class="column">
                    <label class="label">{{ currentFileErr }}</label>
                  </div>
                  <div v-show="isUploadDisabled" class="column">
                    <div v-if="isVideo">
                      <video-component :videoSrc="currentFilePath"></video-component>
                    </div>
                    <div v-else-if="isPdf">
                      <label class="label">PDF</label>

                      <object v-bind:data="currentFilePath" type="application/pdf" width="80%" style="height:1170px;">
                        <a v-bind:href="currentFilePath" target="_blank">{{ currentFileName }}</a>
                      </object>
                    </div>
                    <div v-else-if="isImage">
                      <img v-bind:src="currentFilePath" class="img-responsive" />
                    </div>
                    <div v-else>
                      <label class="label">ファイル</label>
                    </div>
                  </div>

                  <div class="column is-one-quarter rel">
                    <button
                      id="delete-file"
                      class="button basic is-red"
                      v-show="isUploadDisabled"
                      v-bind:disabled="!isUploadDisabled"
                      @click="showConfirmModalDeleteFile = true"
                    >
                      ファイルを削除
                    </button>
                  </div>

                </div>
              </div>

              <div class="field">
                <label class="label">操作説明文</label>
                <div class="control">
                  <textarea
                    class="textarea"
                    style=""
                    v-model:balue="currentWorkProcText"
                    @change="currentTextChange($event.target.value)"
                  >
                  </textarea>
                </div>
              </div>

              <div class="field  pb2em">
                <label class="label">注意文</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder=""
                    v-model:balue="currentWorkProcNotice"
                    @change="currentNoticeChange($event.target.value)"
                  />
                </div>
              </div>
            </template>
          </div>

          <div class="contents-inner2 top-border">
            <!-- Control -->
            <div v-show="hasChild || isChild" class="columns">
              <div class="column" style="text-align:center;">
                <a href="#" class="button is-login" @click.prevent="addChildProcedure"
                  >次のサブ手順を追加&nbsp;<img src="/images/ico-plus-w.png" alt=""/></a
                ><br /><br />
                <a v-show="isChild" href="#" class="button basic is-red" @click.prevent="showConfirmModalDeleteInner = true"
                  >このサブ手順を削除&nbsp;<img src="/images/ico-minus-w.png" alt=""
                /></a>
              </div>
            </div>
            <div style="padding-left:2em;padding-right:2em;">
              <div class="columns">
                <p class="column">
                  <a href="#" class="button is-login" @click.prevent="addPrev"
                    ><img src="/images/ico-prev.png" alt="" />&nbsp;前に作業手順を追加</a
                  >
                </p>
                <div class="column tr">
                  <p class="pb2em">
                    <a href="#" class="button is-login" @click.prevent="addNext"
                      >次の作業手順を追加&nbsp;<img src="/images/ico-next.png" alt=""
                    /></a>
                  </p>
                  <p v-if="showDeleteProc">
                    <a v-if="!isChild" href="#" class="button basic is-red" @click.prevent="showConfirmDelete"
                      >この作業手順を削除&nbsp;<img src="/images/ico-trash.png" alt=""
                    /></a>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- column -->
      </div>

      <confirm-modal
        v-if="showConfirmModal"
        :continue-func="deleteProc"
        :close-func="closeConfirmModal"
        title="サブ手順削除の確認"
        message="サブ手順もすべて削除されますが、よろしいですか？"
      />
      <confirm-modal
        v-if="showConfirmModalDeleteFile"
        :continue-func="onFileDelete"
        :close-func="closeConfirmModalDeleteFile"
        title="確認"
        message="ファイルを削除してよろしいですか？"
      />
      <confirm-modal
        v-if="showConfirmModalDeleteProc"
        :continue-func="deleteProc"
        :close-func="closeConfirmModalDeleteProc"
        title="確認"
        message="この作業手順を削除してよろしいですか？"
      />
      <confirm-modal
        v-if="showConfirmModalDeleteInner"
        :continue-func="deleteInner"
        :close-func="closeConfirmModalDeleteInner"
        title="確認"
        message="このサブ手順を削除してよろしいですか？"
      />
      <confirm-modal
        v-if="showConfirmDeleteStepChild"
        :continue-func="deleteProc"
        :close-func="closeConfirmModalDeleteStepChild"
        title="確認"
        message="サブ手順も全て削除されますがよろしいですか？"
      />
      <confirm-modal
        v-if="showConfirmModalLeave"
        :continue-func="leave"
        :close-func="closeConfirmModalLeave"
        yes-text="このページを離れる"
        no-text="キャンセル"
        title="確認"
        message="このページを離れますか？行った変更が保存されない可能性があります。"
      />

    </template>
  </common-layout-component2>
</template>
<script>
import logic from './js/ChecklistEdit.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import VideoComponent from '../../commons/VideoComponent.vue';
import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import SimpleCheckboxComponent from '../../commons/SimpleCheckboxComponent.vue';
import ConfirmModal from '../common/components/ConfirmModal.vue';
import WorkProcedure from './components/WorkProcedure.vue';

logic.components = {
  CommonLayoutComponent2,
  VideoComponent,
  BaseCheckComponent,
  SimpleCheckboxComponent,
  ConfirmModal,
  WorkProcedure
};

export default logic;
</script>

<style scoped lang="scss">
.control.columns {
    position: relative;
    .alert.alert-warning {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
    }
}
#droparea p.caution {
  font-size: 12.5px;
  line-height: 1.6;
  color: #e48365;
  text-align: left;
  display: inline-block;
  padding: 0;
}
</style>
