/**
 * IndexedDB を使ったなるほどーがデータベース
 * 
 * Vue.DB でデータベースを操作できる。
 * 内部では「現在ログイン中のユーザー」のデータだけを抽出し、他のユーザーのデータは見えない状態にしている。
 */

import store from '../store';

export default {
    install(Vue, options) {
        const DB_NAME = 'naruhodoga';
        const DB_VERSION = 3;

        const DB_STORE_FILE = 'file';
        const DB_STORE_TASK = 'task';
        const DB_STORE_LOG = 'log';

        let db;
        let db_open_request = window.indexedDB.open(DB_NAME, DB_VERSION);

        db_open_request.onerror = function(event) {
            console.error('db_open_request.onerror :', event);
        }

        db_open_request.onsuccess = function(event) {
            console.log('db_open_request.onsuccess');

            db = event.target.result;

            db.onerror = function(error) {
                console.error('db.onerror :', error);
            }

            _.each(Vue.DB.on_ready_resolves, r => r());

            Vue.DB.is_ready = true;
        }

        db_open_request.onupgradeneeded = function(event) {
            console.log('db_open_request.onupgradeneeded');

            let db = event.target.result;

            console.log("db.version :", db.version);
            console.log("event.oldVersion :", event.oldVersion);
            try {
                /*
                if (event.oldVersion < 1) {
                    let store_file = db.createObjectStore(DB_STORE_FILE, { keyPath: 'id' });
                    let store_task = db.createObjectStore(DB_STORE_TASK, { keyPath: 'id' });
                }
                */
                if (event.oldVersion < 2) {

                    //db.deleteObjectStore(DB_STORE_FILE);
                    //db.deleteObjectStore(DB_STORE_TASK);

                    let store_file = db.createObjectStore(DB_STORE_FILE, { keyPath: ['user_id', 'id'] });
                    let store_task = db.createObjectStore(DB_STORE_TASK, { keyPath: ['user_id', 'id'] });

                    store_file.createIndex("user_id", "user_id", { unique: false });
                    store_task.createIndex("user_id", "user_id", { unique: false });

                }

                if (event.oldVersion < 3) {
                    let store_log = db.createObjectStore(DB_STORE_LOG, { keyPath: 'id', autoIncrement: true });
                    store_log.createIndex("user_id", "user_id", { unique: false });
                }

            } catch (e) {
                console.log(">>> DB create error", e);
            }
        }

        Vue.DB = {
            /** DB の準備が出来たときに実行する関数の一覧 */
            on_ready_resolves: [],

            /** 現在ログイン中のユーザーのユーザー ID を返す ( ログイン中のユーザーが存在しない場合は 0 を返す ) */
            get_user_id: function() {
                return store.state.offlineUserId ? store.state.offlineUserId : 0;
            },

            isReady: function() {
                return this.is_ready;
            },

            /**
             * IndexedDB を Open し終わるまでまつ Promise を返す
             */
            waitReady: function() {

                if (this.isReady()) {
                    return Promise.resolve();
                }

                return new Promise(function(resolve, reject) {
                    this.on_ready_resolves.push(resolve);
                }.bind(this));
            },

            /**
             * ストアに保存する
             * 
             * @param {string} store_name 名前
             * @param {*} value 保存する値
             */
            putToStore: function(store_name, value) {

                return new Promise(async(resolve, reject) => {
                    await this.waitReady();

                    let transaction = db.transaction([store_name], 'readwrite');

                    transaction.oncomplete = function() {
                        console.log('transaction.oncomplete');
                        resolve();
                    }

                    transaction.onerror = function() {
                        console.error('transaction.onerror');
                        reject(new Error("putToStore() failed."));
                    }

                    let object_store = transaction.objectStore(store_name);

                    try {
                        let request = object_store.put(value);

                        request.onsuccess = function(event) {
                            console.log('request.onsuccess');
                        }
                    } catch (e) {
                        console.log('e :', e);
                    }
                });
            },

            getFromStore: function(store_name, id) {
                id = Number(id);

                console.log("getFromStore(", store_name, id, ")");
                // console.log( "type of id : ", typeof id );

                return new Promise(async(resolve) => {

                    // console.log( "db :", db );

                    await this.waitReady();

                    let request = db.transaction(store_name).objectStore(store_name).get([this.get_user_id(), id]);

                    request.onsuccess = function(event) {
                        console.log('result of getFromStore() : ', event);
                        resolve(event.target.result);
                    };

                    request.onerror = function(event) {
                        console.error('error on getFromStore() : ', event);
                    }
                });
            },
            getAllFromStore: function(store_name) {

                console.log("getAllFromStore(", store_name, ")");
                console.log("get_user_id() :", this.get_user_id());

                return new Promise(async(resolve, reject) => {

                    await this.waitReady();

                    let values = [];

                    let index = db.transaction(store_name).objectStore(store_name).index("user_id");
                    let cursor_open_request = index.openCursor(IDBKeyRange.only(this.get_user_id()));

                    cursor_open_request.onsuccess = function(event) {
                        const cursor = event.target.result;

                        console.log("cursor :", cursor);

                        if (cursor) {
                            values.push(cursor.value);
                            cursor.continue();
                        } else {
                            console.log('result of getAllFromStore() : ', values);
                            resolve(values);
                        }
                    }

                    cursor_open_request.onerror = function(event) {
                        console.error('error on getAllFromStore() : ', event);
                        reject(new Error("getAllFromStore() failed."));
                    }
                });
            },
            getAllKeysFromStore: function(store_name) {

                console.log("getAllKeysFromStore(", store_name, ")");
                console.log("get_user_id() :", this.get_user_id());

                return new Promise(async(resolve, reject) => {

                    await this.waitReady();

                    let keys = [];

                    let index = db.transaction(store_name).objectStore(store_name).index("user_id");
                    let cursor_open_request = index.openKeyCursor(IDBKeyRange.only(this.get_user_id()));

                    cursor_open_request.onsuccess = function(event) {
                        const cursor = event.target.result;

                        console.log("cursor :", cursor);

                        if (cursor) {
                            keys.push(cursor.primaryKey[1]);
                            cursor.continue();
                        } else {
                            console.log('result of getAllKeysFromStore() : ', keys);
                            resolve(keys);
                        }
                    }

                    cursor_open_request.onerror = function(event) {
                        console.error('error on getAllKeysFromStore() : ', event);
                        reject(new Error("getAllKeysFromStore() failed."));
                    }
                });
            },

            isStored: function(store_name, id) {

                console.log("isStored(", store_name, id, ")");
                // console.log( "type of id : ", typeof id );

                return new Promise(async(resolve) => {
                    // console.log( "db :", db );

                    await this.waitReady();

                    db.transaction(store_name).objectStore(store_name).getKey([this.get_user_id(), id]).onsuccess = function(event) {
                        console.log("is stored :", event.target.result !== undefined);

                        resolve(event.target.result !== undefined);
                    };
                });
            },

            deleteFromStore: function(store_name, id) {
                console.log("deleteFromStore(", store_name, id, ")");
                // console.log( "type of id : ", typeof id );

                return new Promise(async(resolve) => {

                    await this.waitReady();

                    console.log("db :", db);
                    console.log("this.get_user_id :", this.get_user_id());

                    let request = db.transaction(store_name, 'readwrite').objectStore(store_name).delete(id);

                    request.onsuccess = function(event) {
                        console.log('result of deleteFromStore() : ', event);
                        resolve(event.target.result);
                    };

                    request.onerror = function(event) {
                        console.error('error on deleteFromStore() : ', event);
                    }
                });
            },

            /**
             * ファイルを保存する
             * 
             * @param {number} id ファイル ID
             * @param {blob} blob ファイルの内容
             * @param {object} props 追加で保存するプロパティ
             */
            putFile: function(id, blob, props) {

                let file = { id: id, blob: blob };
                Object.assign(file, props);

                this.putToStore(DB_STORE_FILE, file);
            },

            getFile: function(id) {
                return this.getFromStore(DB_STORE_FILE, id);
            },
            getFilesAll: function() {
                return this.getAllFromStore(DB_STORE_FILE);
            },
            getFileKeysAll: function() {
                return this.getAllKeysFromStore(DB_STORE_FILE);
            },
            deleteFile: function(id) {
                return this.deleteFromStore(DB_STORE_FILE, [this.get_user_id(), Number(id)]);
            },

            putTask: function(task) {
                this.putToStore(DB_STORE_TASK, task);
            },

            getTask: function(id) {
                return this.getFromStore(DB_STORE_TASK, id);
            },
            getTasksAll: function() {
                return this.getAllFromStore(DB_STORE_TASK);
            },
            getTaskKeysAll: function() {
                return this.getAllKeysFromStore(DB_STORE_TASK);
            },
            isTaskStored: function(id) {
                return this.isStored(DB_STORE_TASK, id);
            },
            deleteTask: function(id) {
                return this.deleteFromStore(DB_STORE_TASK, [this.get_user_id(), Number(id)]);
            },

            putLog: function(log) {
                return this.putToStore(DB_STORE_LOG, log);
            },
            getLogsAll: function() {
                return this.getAllFromStore(DB_STORE_LOG);
            },
            deleteLog: function(id) {
                return this.deleteFromStore(DB_STORE_LOG, Number(id));
            }
        }
    }
}