var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb40" },
    [
      _vm.myQa && _vm.myQa.length > 0
        ? [
            _vm._l(_vm.myQa, function(item, index) {
              return [
                _c(
                  "div",
                  { key: index, staticClass: "feedback box_wh" },
                  [
                    _c("div", { staticClass: "box" }, [
                      _c("div", { staticClass: "user flex_nw" }, [
                        _c("div", { staticClass: "icon" }, [
                          _c("p", { style: _vm.iconImage(item.user.image) })
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.user.display_name))
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "date" }, [
                          _vm._v(_vm._s(_vm.date(item.created_at)))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "contents" },
                        [
                          _c("h3", [_vm._v(_vm._s(item.title))]),
                          _vm._v(" "),
                          _c("p", [_vm._v(_vm._s(item.message))]),
                          _vm._v(" "),
                          item.video
                            ? _c("lesson-video-component", {
                                attrs: { video: item.video.video_path }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(item.replies, function(re_item, index) {
                      return [
                        _c("div", { key: index, staticClass: "box reply" }, [
                          _vm._m(0, true),
                          _vm._v(" "),
                          _c("div", { staticClass: "user flex_nw" }, [
                            _c("div", { staticClass: "icon" }, [
                              _c("p", {
                                style: _vm.iconImage(re_item.user.image)
                              })
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(re_item.user.display_name))
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "date" }, [
                              _vm._v(_vm._s(_vm.date(re_item.created_at)))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "contents" },
                            [
                              _c("p", [_vm._v(_vm._s(re_item.message))]),
                              _vm._v(" "),
                              re_item.video
                                ? _c("lesson-video-component", {
                                    attrs: { video: re_item.video.video_path }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ])
                      ]
                    }),
                    _vm._v(" "),
                    _vm.$store.state.userInfo.id == _vm.lesson.user_id
                      ? [
                          _c("div", { staticClass: "box reply reply_post" }, [
                            _c("p", { staticClass: "notice" }, [
                              _c("i", {
                                staticClass: "fas fa-exclamation-circle"
                              }),
                              _vm._v("回答してください"),
                              _c("br"),
                              _vm._v(
                                "期限:" +
                                  _vm._s(_vm.replayLimitDate(item.created_at))
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "button round",
                                on: {
                                  click: function($event) {
                                    return _vm.$libs.pushUrl([
                                      "lesson",
                                      _vm.lesson.id,
                                      "question",
                                      item.id,
                                      "reply"
                                    ])
                                  }
                                }
                              },
                              [_vm._v("\n              回答する\n            ")]
                            )
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            })
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("h2", { staticClass: "mt30" }, [_vm._v("みんなの質問")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "feedback box_wh" },
        [
          _vm.qa && _vm.qa.length > 0
            ? [
                _vm._l(_vm.qa, function(item, index) {
                  return [
                    _c("div", { key: index, staticClass: "qa_item" }, [
                      _c("div", { staticClass: "box head flex_nw" }, [
                        _c("div", { staticClass: "left" }, [
                          _c("h3", [
                            _vm.isNew(item.created_at)
                              ? _c("span", { staticClass: "new" }, [
                                  _vm._v("NEW")
                                ])
                              : _vm._e(),
                            _vm._v(_vm._s(item.title))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "flex_nw" }, [
                            _c("p", { staticClass: "name" }, [
                              _vm._v(_vm._s(item.user.display_name))
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "date" }, [
                              _vm._v(_vm._s(_vm.date(item.created_at)))
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(1, true)
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "inner" },
                        [
                          _c("div", { staticClass: "box" }, [
                            _c("div", { staticClass: "user flex_nw" }, [
                              _c("div", { staticClass: "icon" }, [
                                _c("p", {
                                  style: _vm.iconImage(item.user.image)
                                })
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.user.display_name))
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "date" }, [
                                _vm._v(_vm._s(_vm.date(item.created_at)))
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "contents" },
                              [
                                _c("p", [_vm._v(_vm._s(item.message))]),
                                _vm._v(" "),
                                item.video
                                  ? _c("lesson-video-component", {
                                      attrs: { video: item.video.video_path }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _vm._l(item.reply, function(re_item, index) {
                            return [
                              _c(
                                "div",
                                { key: index, staticClass: "box reply" },
                                [
                                  _vm._m(2, true),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "user flex_nw" }, [
                                    _c("div", { staticClass: "icon" }, [
                                      _c("p", {
                                        style: _vm.iconImage(re_item.user.image)
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "name" }, [
                                      _vm._v(_vm._s(re_item.user.display_name))
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "date" }, [
                                      _vm._v(
                                        _vm._s(_vm.date(re_item.created_at))
                                      )
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "contents" },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(re_item.message))
                                      ]),
                                      _vm._v(" "),
                                      re_item.video
                                        ? _c("lesson-video-component", {
                                            attrs: {
                                              video: re_item.video.video_path
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ])
                  ]
                })
              ]
            : [
                _c("p", { staticClass: "pt50 pb50 text_center" }, [
                  _vm._v("質問はまだありません")
                ])
              ]
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "relpay_icon" }, [
      _c("i", { staticClass: "fas fa-reply" }),
      _vm._v("回答")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("i", { staticClass: "fas fa-chevron-down" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "relpay_icon" }, [
      _c("i", { staticClass: "fas fa-reply" }),
      _vm._v("回答")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }