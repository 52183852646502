<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" :title="title" main-class="my_page sales">
        <template v-slot:body>
            <section class="sales_container bg_yellow">
                <div class="container pt30 pb30">
                    <h2>売上金</h2>
                    <p class="sales"><span>{{ Number(total).toLocaleString() }}</span>円</p>
                    <template v-if="sales && !paymentId">
                        <button v-if="sales && sales.length > 0" class="mb10 button round brown inline" @click="showModalPayment = true">振り込み申請をする</button>
                        <p>振り込み申請期限 2021/xx/xx</p>
                    </template>
                    <template v-if="sales && paymentId">
                        <p class="status">{{ status }}</p>
                        <div class="dates flex_nw">
                            <p>申請日 {{ formattedDate(payment.created_at) }}</p>
                            <p>{{ payedDate }}</p>
                        </div>
                    </template>
                </div>
                <div v-if="!paymentId" class="history">
                    <a :href="$libs.url(['mypage','sales','history'])">
                        振り込み履歴<i class="fas fa-angle-right"></i>
                    </a>
                </div>
            </section>
            <template v-if="sales && sales.length > 0">
            <section class="bg_yellow">
                <div class="sales bg_white">
                    <template v-for="item,index in sales">
                    <div class="sales_item" :key="index">
                        <a :href="$libs.url(['lesson',item.task_id])">
                            <div class="contents">
                                <div class="flex_nw">
                                    <lesson-video-thumbnail-component :video="item.task.video" />
                                    <div class="right">
                                        <div class="user flex_nw">
                                            <div class="icon">
                                                <p :style="iconImage(item.user.image)"></p>
                                            </div>
                                            <p class="name">{{item.user.display_name}}</p>
                                            <p class="date">{{ formattedDate(item.created_at) }}</p>
                                        </div>
                                        <h3>{{ item.task.title }}</h3>
                                        <p class="price"><span class="small">{{ strType(item.type) }}</span> <span>{{ item.price.toLocaleString() }}円</span>（税込）</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    </template>
                </div>
            </section>
            </template>

            <modal-component v-if="showModalPayment" title="振り込み申請をしますか？" :view-selectable="true" @yes="applyPayment" @no="showModalPayment = false">
                <template v-slot:body>
                    <p class="description">売上金のお振り込み手続きを行います。通常、翌月末のお振り込みとなります。また、この操作は取り消しできません。
                    </p>
                </template>
            </modal-component>
            <modal-component v-if="showModalPaymentApplied" title="振り込み申請をしました" :view-plain="true" @ok="showModalPaymentApplied = false"/>          
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../miso/commons/ModalComponent.vue';
import LessonVideoThumbnailComponent from '../../miso/user/LessonVideoThumbnailComponent.vue';
import moment from 'moment';
export default {
    components: {
    CommonLayoutComponent,
    ModalComponent,
    LessonVideoThumbnailComponent,
    },
    props: {
            paymentId: {
                type: Number,
            },
        },
  data: function () {
    return {
      total:0,
      sales: null,
      payment: null,
      showModalPayment:false,
      showModalPaymentApplied:false,
    };
  },
  created: function () {},

  mounted: function () {
      this.refleshData();
  },
  computed: {
      status: function(){
          if(this.payment){
              return (this.payment.payed_datatime)? "振り込み済み" : "申請中"
          }
          return "" 
        },
        payedDate: function(){
            if(this.payment){
                const strDate = (this.payment.payed_datetime)? this.payment.payed_datetime : this.payment.expiration_datetime
                const date = moment(strDate)
                const formattedDate =  date.format("YYYY/MM/DD")

                return (this.payment.payed_datetime)? "振り込み予定日: " + formattedDate : "振り込み日: " + formattedDate
            }
              return "" 
        }, 
        title: function(){
            if(this.paymentId && this.payment){

               var date = moment(this.payment.created_at)
               return "申請日 " + date.format("MM/DD")

            }else{
                return "売上金管理"
            }
        }   
  },
  methods: {
    refleshData: function() {

            var handler
            if(this.paymentId){
                // ユーザー情報取得（this.paymentIdある場合は支払い申請指定）
                handler = Vue.ELearning.Payment.myPaymentById(this.paymentId);
            }else{
                // ユーザー情報取得 未申請分
                handler = Vue.ELearning.Purchase.mySales();
            }

            handler.then((response) => {
                if (response.data.status_number == 200) {

                     if(this.paymentId){
                        this.payment = response.data.content.result
                        this.total = this.payment.price_sum;
                        this.sales = this.payment.purchases;

                     }else{
                        this.total = response.data.content.total;
                        this.sales = response.data.content.sales;
                     }

                } else {
                    throw new Error("売上情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },
    strType: function(type){
        if(type == 1){
            return "レッスン料"
        }
        if(type == 2){
            return "採点チケット"
        }
        if(type == 3){
            return "質問チケット"
        }
        return "レッスン料"
    },

    formattedDate: function(strDate){
        var date = moment(strDate)
        return date.format("YYYY/MM/DD HH:mm")
    },
    
    onNaviBack: function() {
        if(this.paymentId){
            this.$libs.pushUrl(["mypage","sales","history"])
        }else{
            this.$libs.pushUrl("mypage")
        }
    },
    iconImage: function(image) {
          if(image){
              return 'background: url(' + image + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      },
      applyPayment: function(){
        // 支払い申請
            var handler = Vue.ELearning.Payment.apply();
            this.showModalPayment = false

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    this.showModalPaymentApplied = true
                    //this.refleshData()
                    this.$libs.pushUrl(["mypage","sales","history"])

                } else {
                    throw new Error("支払い申請に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },    
  },
}

</script>
<style lang="scss" scoped>
</style>
