var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "video" }, [
    !_vm.isVideo ? _c("img", { attrs: { src: _vm.video, alt: "" } }) : _vm._e(),
    _vm._v(" "),
    _vm.isVideo
      ? _c("video", {
          attrs: {
            src: _vm.srcVideo,
            controls: "",
            poster: _vm.thumbnail,
            "webkit-playsinline": "",
            playsinline: ""
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }