<template>
    <div>
        <div
            class="flow"
            v-bind:class="{
                'bg-green': item.selected,
            }"
        >
            <label class="procedure-check">
                <input
                    type="checkbox"
                    class="checkbox01-input"
                    v-model="item.selected"
                    @change.stop.prevent="change"
                />
                <span class="checkbox01-parts" v-text="name" />
            </label>
        </div>

        <template v-for="(child, cIndex) in item.child">
            <work-procedure-child
                v-if="hasChild"
                :key="child.uuid"
                :parent-index="index"
                :index="cIndex"
                :item="child"
                @check="currentFlowOnChange"
            />
        </template>
    </div>
</template>

<script>
import WorkProcedureChild from './WorkProcedureChild.vue';

export default {
    components: {
        WorkProcedureChild,
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        isLast: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        hasChild() {
            return this.item.kind === 'multi';
        },
        number() {
            return this.index + 1;
        },
        name() {
            return `${this.number}. ${this.item.workProcedureName}`;
        },
    },
    methods: {
        change($event) {
            this.currentFlowOnChange(this.item.uuid, $event.target.value);
        },
        currentFlowOnChange(uuid, value) {
            this.$emit('check', uuid, value);
        }
    }
};
</script>

<style lang="scss" scoped>
.procedure-check {
    cursor: pointer;
}
</style>
