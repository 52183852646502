<template>
    <tr>
        <td class="check">
            <label>
                <input
                    type="checkbox"
                    v-model="user.selected"
                    class="checkbox01-input"
                    disabled
                />
                <span class="checkbox01-parts" @click="onUserByGroupChange" />
            </label>
        </td>
        <td class="user" @click="onUserByGroupChange" v-text="user.userName" />
        <td
            class="area"
            v-on:click="onUserByGroupChange"
            v-text="user.orgName"
        />
    </tr>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    methods: {
        onUserByGroupChange() {
            // 管理者のチェックは操作させない
            if (this.user.admin) {
                this.$dialog.message("管理者権限のユーザーです。");
                return;
            }
            this.user.selected = !this.user.selected;
        },
    },
};
</script>
