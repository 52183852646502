<template>
  <common-layout-component2 show-back="true" show-admin="true" @on-nav-back="$router.push( { name: 'log-index' } )">
    <template v-slot:nav-message>
      <p>閲覧ログ - コンテンツ</p>
    </template>

    <template v-slot:body>
      <div class="columns is-gapless logs">
        <log-side-bar />

        <div class="column main-column">
          <div class="main-column-inner">
            <log-period-input :period_begin.sync="period_begin" :period_end.sync="period_end" v-on:period-updated="on_period_updated" />

            <div class="row board-container board-container01">
              <div class="col-12">
              <div class="board tab-panel">
                <ul class="tab">
                  <li><a href="#tab1-1" class="tabcolor01 selected"><span class="icon-task">よく閲覧されたタスク</span></a></li>
                  <li><a href="#tab1-2" class="tabcolor02"><span class="icon-user">閲覧したユーザーが多いタスク</span></a></li>
                </ul>
                <div class="panel">
                  <div id="tab1-1" class="panel-content">
                    <div class="download-button"><a href="#" @click.prevent.stop.prevent="download_top_viewed_tasks_csv">CSVダウンロード</a></div>
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color01 initial-rows4" :class="{ 'partial-display': ! is_top_viewed_tasks_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>タスク</th>
                              <th>カテゴリ　</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( task, n ) in top_viewed_tasks" :key="task.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ task.task_name }}</td>
                              <td>{{ task.category_name }}</td>
                              <td>{{ task.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_viewed_tasks_more" :list="top_viewed_tasks" :limit="4" color="color01"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->


                  <div id="tab1-2" class="panel-content">
                    <div class="download-button"><a href="#" @click.prevent.stop.prevent="download_top_users_viewed_tasks_csv">CSVダウンロード</a></div>
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color02 initial-rows4" :class="{ 'partial-display': ! is_top_users_viewed_tasks_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>タスク</th>
                              <th>カテゴリ　</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( task, n ) in top_users_viewed_tasks" :key="task.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ task.task_name }}</td>
                              <td>{{ task.category_name }}</td>
                              <td>{{ task.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_users_viewed_tasks_more" :list="top_users_viewed_tasks" :limit="4" color="color02"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->
                </div><!-- /.panel -->
              </div><!-- /.tab-panel -->
              </div>
            </div><!-- /.board-container01 -->
            
            
            <div class="row board-container board-container02">
              <div class="col-12">
              <div class="board tab-panel">
                <ul class="tab">
                  <li><a href="#tab2-1" class="tabcolor03 selected"><span class="icon-video">よく動画再生された作業</span></a></li>
                  <li><a href="#tab2-2" class="tabcolor03"><span class="icon-contents">よくスキップされた作業</span></a></li>
                </ul>
                <div class="panel">
                  <div id="tab2-1" class="panel-content">
                    <div class="download-button"><a href="#" @click.prevent.stop.prevent="download_top_played_work_procedures_csv">CSVダウンロード</a></div>
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color03 initial-rows3" :class="{ 'partial-display': ! is_top_played_work_procedures_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>作業手順</th>
                              <th>タスク</th>
                              <th>カテゴリ　</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( work_procedure, n ) in top_played_work_procedures" :key="work_procedure.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ work_procedure.work_procedure_name }}</td>
                              <td>{{ work_procedure.task_name }}</td>
                              <td>{{ work_procedure.category_name }}</td>
                              <td>{{ work_procedure.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_played_work_procedures_more" :list="top_played_work_procedures" :limit="3" color="color03"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->


                  <div id="tab2-2" class="panel-content">
                    <div class="download-button"><a href="#" @click.prevent.stop.prevent="download_top_skipped_work_procedures_csv">CSVダウンロード</a></div>
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color03 initial-rows3" :class="{ 'partial-display': ! is_top_skipped_work_procedures_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>作業手順</th>
                              <th>タスク</th>
                              <th>カテゴリ　</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( work_procedure, n ) in top_skipped_work_procedures" :key="work_procedure.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ work_procedure.work_procedure_name }}</td>
                              <td>{{ work_procedure.task_name }}</td>
                              <td>{{ work_procedure.category_name }}</td>
                              <td>{{ work_procedure.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_skipped_work_procedures_more" :list="top_skipped_work_procedures" :limit="3" color="color03"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->
                </div><!-- /.panel -->
              </div><!-- /.tab-panel -->
              </div>
            </div><!-- /.board-container02 -->
          </div><!-- /.main-column -->
        </div><!-- /.column -->
      </div>
    </template>
  </common-layout-component2>
</template>
<script>

import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import LogSideBar from './components/LogSideBar';
import LogPeriodInput from './components/LogPeriodInput';
import MoreButton from './components/MoreButton';

import Chart from 'chart.js';
import moment from 'moment';

export default {
  components: {
      CommonLayoutComponent2,
      LogSideBar,
      LogPeriodInput,
      MoreButton,
  },
  
  data: () => { return {
    period_begin: "",                   // 期間 ( 開始 )
    period_end: "",                     // 期間 ( 終了 )

    top_viewed_tasks: [],               // よく閲覧されたタスクの一覧
    top_users_viewed_tasks: [],         // 閲覧したユーザーが多いタスクの一覧

    top_completed_tasks: [],            // 完了数の多いタスクの一覧
    top_played_work_procedures: [],     // 動画再生回数の多い作業手順の一覧

    top_played_work_procedures: [],     // よく動画再生された作業の一覧
    top_skipped_work_procedures: [],    // よくスキップされた作業の一覧

    // More
    is_top_viewed_tasks_more: false,
    is_top_users_viewed_tasks_more: false,

    is_top_played_work_procedures_more: false,
    is_top_skipped_work_procedures_more: false,
  } },

  computed: {
    // API の共通パラメータ
    api_params: function () { return { begin: this.period_begin, end: this.period_end }; }
  },

  mounted () {
    Vue.ELearning.Logs.init();
  },

  methods: {
    /**
     * 期間が変更された時の処理
     */
    on_period_updated: function ( begin, end ) {
      // 
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopViewedTasks( this.api_params ), ( data ) => { this.top_viewed_tasks = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopUsersViewedTasks( this.api_params ), ( data ) => { this.top_users_viewed_tasks = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopPlayedWorkProcedures( this.api_params ), ( data ) => { this.top_played_work_procedures = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopSkippedWorkProcedures( this.api_params ), ( data ) => { this.top_skipped_work_procedures = data; } );
    },

    /** よく閲覧されたタスク CSV ダウンロード */
    download_top_viewed_tasks_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopViewedTasksCsv( this.api_params ), "top_viewed_tasks.csv" );
    },

    /** 閲覧したユーザーが多いタスク CSV ダウンロード */
    download_top_users_viewed_tasks_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopUsersViewedTasksCsv( this.api_params ), "top_users_viewed_tasks.csv" );
    },

    /** よく動画再生された作業 CSV ダウンロード */
    download_top_played_work_procedures_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopPlayedWorkProceduresCsv( this.api_params ), "top_played_work_procedures.csv" );
    },

    /** よくスキップされた作業 CSV ダウンロード */
    download_top_skipped_work_procedures_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopSkippedWorkProceduresCsv( this.api_params ), "top_skipped_work_procedures.csv" );
    },
  },
};

</script>