<template>
    <div class="tags-resist-body">
        <div class="columns is-multiline">
            <template v-for="tag in tags">
                <tag-select-item :key="tag.tagId" :tag="tag" />
            </template>
        </div>
        <p class="tr">
            <button class="button basic is-gray" @click="openModal">
                タグ新規作成
            </button>
        </p>
    </div>
</template>

<script>
import TagSelectItem from './TagSelectItem.vue';

export default {
    components: {
        TagSelectItem,
    },
    props: {
        tags: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        openModal() {
            this.$emit('open-tag-modal');
        },
    },
};
</script>
