<template>
    <common-layout-component :show-back="false" :show-navi-bar="true"  title="よくある質問" main-class="faq_page">
        <template v-slot:body>
            <section class="u-bg-yellow">
                <div class="list c-list u-pt20">
                    <h3 class="c-list__title">まなぶ</h3>
                    <ul>
                        <li class="c-list__item">
                            <a href="#">採点結果がこない<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <p>返信は講師が行います。7日間返信がない場合は返金されます。</p>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">購入したレッスンがなくなった<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <p>購入したレッスンの受講可能期間は180日間です。受講期間が終了すると、自動的にレッスンが消去されます。</p>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">質問・採点依頼できない<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <p>講師の都合により受付できません。講師のレッスン運営については当サイトでは対応いたしません。</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="list c-list">
                    <h3 class="c-list__title">おしえる</h3>
                    <ul>
                        <li class="c-list__item"><a href="#">振込スケジュール<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <p>
                                    振込は毎月〇〇日に行います。振込申請から〇営業日後以降、最も近い振込日に設定されます。営業日は土・日・祝、年末年始(12/28~1/5)を除く平日を指します。振込申請履歴は、アプリ、Webサイトの「振込申請履歴」からご確認いただけます。
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    export default {
        components :{
            CommonLayoutComponent
        },
        mounted:function(){
            $(".list li a").click(function() {
                $(this).toggleClass("is-active").next(".item_contents").slideToggle(300);
                return false;
            });
        },
    }
</script>
