var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.value
      ? _c("div", { staticClass: "show-more-button", class: _vm.color }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.on_more_click.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "icon-more" }, [_vm._v("More")])]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.is_more_executed
      ? _c("div", { staticClass: "show-more-button back", class: _vm.color }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.on_back_click.apply(null, arguments)
                }
              }
            },
            [_c("span", { staticClass: "icon-more" }, [_vm._v("Back")])]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }