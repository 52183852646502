var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "生徒",
      "main-class": "my_page purchased_users"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "bg_yellow" }, [
              _c("div", { staticClass: "container pt20 pb10" }, [
                _c("div", { staticClass: "page_header" }, [
                  _c("div", [
                    _c("p", { staticClass: "result_num" }, [
                      _c("span", [_vm._v(_vm._s(_vm.count))]),
                      _vm._v("名")
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "users bg_white" },
                [
                  _vm.users && _vm.users.length > 0
                    ? [
                        _vm._l(_vm.users, function(item, index) {
                          return [
                            _c("div", { key: index, staticClass: "user" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.$libs.url(["user", item.id])
                                  }
                                },
                                [
                                  _c("div", { staticClass: "flex_nw" }, [
                                    _c("div", { staticClass: "icon" }, [
                                      _c("p", {
                                        style: _vm.iconImage(item.image)
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "right" }, [
                                      _c("p", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.display_name))
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("i", {
                                        staticClass: "fas fa-angle-right"
                                      })
                                    ])
                                  ])
                                ]
                              )
                            ])
                          ]
                        })
                      ]
                    : _vm.users
                    ? [
                        _c("p", { staticClass: "mt50" }, [
                          _vm._v("受講者はまだいません。")
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }