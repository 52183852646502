<template>
    <div class="justify-content-center">
    <svg class="" xmlns="http://www.w3.org/2000/svg" version="1.1" v-bind:viewBox="viewBox"  v-bind:width="rad2" v-bind:height="rad2">

        <circle v-bind:cx="rad" v-bind:cy="rad" v-bind:r="rad" fill="#D3D5D7" />

        <path v-bind:d="pathParam" v-bind:fill="circleFill" stroke="#fff"></path>

        <circle v-bind:cx="rad" v-bind:cy="rad" v-bind:r="radInner" fill="#FFFFFF" />


        <!--
        <text v-show="showCompletedLabel" style="font-weight:bold;font-size:1.2em;" v-bind:x="percentLabelX" y="70" v-bind:fill="textFill">✓</text>
        <text v-show="showUnCompletedLabel" style="font-weight:bold;font-size:1.2em;" v-bind:x="percentLabelX" y="70" v-bind:fill="textFill">✓</text>
        -->

        <text v-if="size != 'small'" style="font-weight:bold;font-size:1.2em;" v-bind:x="percentLabelX" v-bind:y="rad *1.44" v-bind:fill="textFill">{{ percentLabel }}</text>

        <text v-if="showCompletedLabel && size != 'small'" style="font-weight:bold;font-size:2.5em;" v-bind:x="percentX + 10" v-bind:y="rad" v-bind:fill="textFill">✔</text>
        <text v-if="showUnCompletedLabel && size != 'small'" style="font-weight:bold;font-size:2.5em;" v-bind:x="percentX" v-bind:y="rad" v-bind:fill="textFill">{{unCompTextContent}}</text>

        <text v-if="showPercentLabel && size != 'small'" style="font-weight:bold;font-size:1.5em;" v-bind:x="percentX" v-bind:y="rad * 0.88" v-bind:fill="textFill">{{ percentText }}</text>

        <!-- sp >> -->
        <text v-if="size == 'small' && percent > 0" style="font-weight:bold;font-size:0.8em;" v-bind:x="percentLabelX" v-bind:y="rad+9" v-bind:fill="textFill">{{ percentLabel }}</text>
        <text v-if="showPercentLabel && size == 'small' && percent > 0" style="font-weight:bold;font-size:1.1em;" v-bind:x="percentX" v-bind:y="rad - 1" v-bind:fill="textFill">{{ percentText }}</text>
        <!-- << sp -->
    </svg>
    </div>
</template>
<script>
    export default {
        computed: {
            unCompTextContent: function() {
                if(this.percent == -1) {
                    return "！";
                } else {
                    return "！";
                }

            },


            showCompletedLabel: function() {
                if(this.percent == -1) {
                    return false;
                }

                if(this.percent == 100) {
                    return true;
                } else {
                    return false;
                }
            },
            showUnCompletedLabel: function() {

                if(this.percent == -1) {
                    return true;
                }

                if(this.percent == 0) {
                    return true;
                } else {
                    return false;
                }
            },
            showPercentLabel: function() {
                if(this.percent > 0 && this.percent < 100) {
                    return true;
                } else {
                    return false;
                }
            },

            viewBox: function(){
                return "0 0 " + this.rad2 + " " + this.rad2; 
            },

            /*
            radius1: function(){
                switch (this.size){
                    case "small":
                        return 15;
                    case "medium":
                        return 29;
                    default:
                        return 40;
                    }

            },

            radius2: function(){
                switch (this.size){
                    case "small":
                        return this.radius1 - 2;
                    case "medium":
                        return this.radius1 - 3;
                    default:
                        return this.radius1 - 5;
                    }
            },
            */
            percent: function(){
                return this.value;
            },

            percentText: function(){
                if(this.percent == -1) {
                    return "";
                }

                if(this.percent == 0) {
                    return '';
                } else if(this.percent == 100) {
                    return '100%';
                } else if(this.percent > 0) {
                    return this.percent + '%';
                }
            },

            percentLabel: function(){
                var green = "#49BC23";
                var brue = "#3B91C9";
                var gray = "#D3D5D7";
                var red = "#D82E2E";

                if(this.percent == -1) {
                    return "準備中";
                }

                if(this.percent == 0) {
                    return "未着手";
                } else if(this.percent == 100) {
                    return "完了";
                } else if(this.percent > 0) {
                    return "作業中";
                }
            },

            percentLabelX: function() {
                /*
                if(this.percent < 100) {
                    return this.rad * 0.55 - 1;
                } else {
                    return this.rad * 0.55 + 4;
                }
                */
                var correct1;
                var correct2;
                switch (this.size){
                    case "small":
                        correct1 = -6;
                        correct2 = 0;
                        break;
                    case "medium":
                        correct1 = -6;
                        correct2 = 0;
                        break;
                    default:
                        correct1 = -11;
                        correct2 = -5;
                }

                if(this.percent < 10) {
                    return this.rad * 0.65 + correct1;
                } else if(this.percent >= 10 && this.percent < 100) {
                    return this.rad * 0.65 + correct1;
                } else {
                    return this.rad * 0.65 + correct2;
                }
            },

            percentX: function() {
                //return 25;
                var correct1;
                var correct2;
                switch (this.size){
                    case "small":
                        correct1 = -1;
                        correct2 = -3;
                        break;
                    case "medium":
                        correct1 = 0;
                        correct2 = -2;
                        break;
                    default:
                        correct1 = -4;
                        correct2 = -7;
                }

                if(this.percent < 10) {
                    return this.rad * 0.65 + correct1;
                } else if(this.percent >= 10 && this.percent < 100) {
                    return this.rad * 0.65 + correct2;
                } else {
                    return this.rad * 0.65 + correct2;
                }
            },

            rad: function() {
                switch (this.size){
                    case "small":
                        return 17;
                    case "medium":
                        return 32;
                    default:
                        return 45;
                }
                //return this.size == "small" ? 17 : 45;
            },

            currentHeight: function() {
                return this.red - 5;
            },

            radInner: function() {
                switch (this.size){
                    case "small":
                        return this.rad - 2;
                    case "medium":
                        return this.rad - 3;
                    default:
                        return this.rad - 4;
                    }
            },

            rad2: function() {
                return this.rad * 2;
            },

            circleFill: function() {
                //#0153d8
                if(this.percent == -1) {
                    return "#D3D5D7";
                }

                if(this.percent == 0) {
                    return "#D3D5D7";
                } else if(this.percent > 0 && this.percent < 100) {
                    return "#3B91C9";
                } else {
                    return "#49BC23";
                }
            },
            textFill: function() {
                if(this.percent == -1) {
                    return "#D3D5D7";
                }

                if(this.percent == 0) {
                    return "#D82E2E";
                } else if(this.percent > 0 && this.percent < 100) {
                    return "#3B91C9";
                } else {
                    return "#49BC23";
                }
            },

            pathParam: function(){
                var cx = this.rad;
                var cy = this.rad;

                var r = this.rad;

                var pct = this.percent / 100;

                //console.log(360 * pct);

                //var a = 170;
                var a = 360 * pct;

                if(this.percent > 99) {
                    a = 359.999;
                }

                if(this.percent < 1) {
                    a = 359.999;
                }

                var s = 0;
                var rad1 = (s - 90) / 180 * Math.PI;
                var rad2 = (a + s - 90) / 180 * Math.PI;

                var params = [
                    cx + r * Math.cos(rad1), cy + r * Math.sin(rad1),
                    r, r,
                    a > 180 && 1 || 0,
                    cx + r * Math.cos(rad2), cy + r * Math.sin(rad2),
                    cx, cy
                ];

                var res = params.reduce(function(str, param){
                    return str.replace('%d', param);
                }, 'M %d,%d A %d,%d 0 %d,1 %d,%d L %d,%d Z');
                //console.log(res);

                //return "M 150,0 A 150,150 0 1,1 141.8986347236027,299.7810664959306 L 150,150 Z";
                return res;
            }
        },
        data() {
            return {}
        },
        props: {
            'value': 0,
            'size': String,
        }
    }
</script>
