import { render, staticRenderFns } from "./SortableTable.vue?vue&type=template&id=5ce93994&scoped=true&"
import script from "./SortableTable.vue?vue&type=script&lang=js&"
export * from "./SortableTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ce93994",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m1292/Documents/develop/laravel/misoshiru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ce93994')) {
      api.createRecord('5ce93994', component.options)
    } else {
      api.reload('5ce93994', component.options)
    }
    module.hot.accept("./SortableTable.vue?vue&type=template&id=5ce93994&scoped=true&", function () {
      api.rerender('5ce93994', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/designed/admin/components/SortableTable.vue"
export default component.exports