import { data } from "jquery";

export default {
    install(Vue, options) {

        Vue.prototype.$config = {

            parts: [
                { name: "ピアノ", value: 1 },
                { name: "ギター", value: 2 },
                { name: "ベース", value: 3 },
                { name: "ドラム", value: 4 },
                { name: "サックス", value: 5 },
                { name: "トランペット", value: 6 },
                { name: "バイオリン", value: 7 },
                { name: "ウクレレ", value: 8 },
                { name: "鍵盤楽器", value: 9 },
                { name: "弦楽器", value: 10 },
                { name: "和楽器", value: 11 },
                { name: "打楽器", value: 12 },
                { name: "DTM/DAW", value: 13 },
                { name: "DJ", value: 14 },
                { name: "その他", value: 99 },
            ],
            genres: [
                { name: "ポップス", value: 1 },
                { name: "ロック", value: 2 },
                { name: "クラシック", value: 3 },
                { name: "ジャズ", value: 4 },
                { name: "R&B/ソウル", value: 5 },
                { name: "エレクトロニック", value: 6 },
                { name: "アニメ", value: 7 },
                { name: "ダンス", value: 8 },
                { name: "ヒップホップ/ラップ", value: 9 },
                { name: "メタル", value: 10 },
                { name: "レゲエ", value: 11 },
                { name: "その他", value: 99 },
            ],
            sex: [
                { name: "指定なし", value: 0 },
                { name: "男性", value: 1 },
                { name: "女性", value: 2 },
            ],
            ages: [
                { name: "指定なし", value: 0 },
                { name: "20代以下", value: 1 },
                { name: "30代", value: 2 },
                { name: "40代", value: 3 },
                { name: "50代", value: 4 },
                { name: "60代以上", value: 5 },
            ],
            career: [
                { name: "指定なし", value: 0 },
                { name: "1年未満", value: 1 },
                { name: "1年以上", value: 2 },
                { name: "3年以上", value: 3 },
                { name: "5年以上", value: 4 },
                { name: "10年以上", value: 5 },
            ],
            levels: [
                { name: "★☆☆☆☆", value: 1, forSelectLabel: "☆☆☆☆★" },
                { name: "★★☆☆☆", value: 2, forSelectLabel: "☆☆☆★★" },
                { name: "★★★☆☆", value: 3, forSelectLabel: "☆☆★★★" },
                { name: "★★★★☆", value: 4, forSelectLabel: "☆★★★★" },
                { name: "★★★★★", value: 5, forSelectLabel: "★★★★★" },
            ],
            names: function(property, values) {
                var datas = this[property];
                var name = []

                values.forEach(function(value) {
                    var _names = datas.filter(function(item) {
                        return item["value"] == value
                    });
                    if (_names.length > 0) {
                        name.push(_names[0].name)
                    }
                });
                return name;
            },
            name: function(property, value) {
                var datas = this[property];

                var _names = datas.filter(function(item) {
                    return item["value"] == value
                });

                if (_names.length > 0) {
                    return _names[0].name
                }
                return "";
            }
        };

        // Vue.$dialog でも使えるようにする
        Vue.$config = Vue.prototype.$config;
    }
}