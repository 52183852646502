var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("グループ登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c(
                      "h2",
                      { staticClass: "column", attrs: { id: "title" } },
                      [_vm._v("グループ編集")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column tr" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-gray",
                          on: { click: _vm.importGrp }
                        },
                        [
                          _vm._v("\n                        インポート "),
                          _c("img", {
                            attrs: { src: "/images/ico-import.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "d-none",
                        attrs: {
                          id: "input-file-csv",
                          type: "file",
                          name: "file"
                        },
                        on: { change: _vm.selectedUploadCsv }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-gray",
                          on: { click: _vm.exportGrp }
                        },
                        [
                          _vm._v("\n                        エクスポート "),
                          _c("img", {
                            attrs: { src: "/images/ico-export.png", alt: "" }
                          })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { attrs: { id: "subtitle-area" } }, [
                  _vm._v("グループ一覧")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "table-area" } },
                  [
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          on: {
                            click: function($event) {
                              return _vm.gotoEdit("new")
                            }
                          }
                        },
                        [
                          _vm._v("\n                        新規登録 "),
                          _c("img", {
                            attrs: { src: "/images/ico-plus.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          attrs: { disabled: _vm.editButtonEnabled },
                          on: {
                            click: function($event) {
                              return _vm.gotoEdit("edit")
                            }
                          }
                        },
                        [
                          _vm._v("\n                        編集 "),
                          _c("img", {
                            attrs: { src: "/images/ico-pen.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-red",
                          attrs: { disabled: _vm.deleteButtonEnabled },
                          on: {
                            click: function($event) {
                              _vm.showModalDelete = true
                            }
                          }
                        },
                        [
                          _vm._v("\n                        削除 "),
                          _c("img", {
                            attrs: { src: "/images/ico-trash.png", alt: "" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column column-search" }, [
                      _c("p", { staticClass: "input-search tr" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.keyword,
                              expression: "keyword"
                            }
                          ],
                          staticStyle: { "padding-right": "2.2em" },
                          attrs: { type: "text", placeholder: "検索" },
                          domProps: { value: _vm.keyword },
                          on: {
                            change: function($event) {
                              return _vm.onSearch($event.target.value)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.keyword = $event.target.value
                            }
                          }
                        }),
                        _c("img", {
                          attrs: { src: "/images/ico-search.png", alt: "" }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-clear btn-round",
                          attrs: { disabled: !_vm.keyword.length },
                          on: { click: _vm.clearConditions }
                        },
                        [
                          _vm._v(
                            "\n                        クリア\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-wrapper" }, [
                  _c(
                    "table",
                    { staticClass: "basic scrollTable" },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.onSort("group_name")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                グループ名 "
                              ),
                              _vm.axis == "group_name"
                                ? _c("arrow-up-down-alt", {
                                    attrs: { order: _vm.order }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("th", [_vm._v("グループ説明文")]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n                                閲覧可能なタスク"
                            )
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.groups.length == 0,
                              expression: "groups.length == 0"
                            }
                          ]
                        },
                        [
                          _c("td", { attrs: { colspan: "4" } }, [
                            _vm._v("グループが存在しません。")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.groups, function(group) {
                        return [
                          _c("tr", { key: group.groupId }, [
                            _c("td", { staticClass: "check" }, [
                              _c(
                                "label",
                                [
                                  _c("base-check-component", {
                                    attrs: { value: group.selected },
                                    on: {
                                      "check-state-change": function($event) {
                                        return _vm.onCheckStateChange(group)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "group-name" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(group.groupName) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "group-body" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(group.groupText) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "task" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button basic is-gray2 rs",
                                  on: {
                                    click: function($event) {
                                      return _vm.onOpenGroupByTask(group)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                    タスクリスト"
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/images/ico-add-gray.png",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _vm.showModalDelete
                  ? _c(
                      "modal-component",
                      {
                        attrs: {
                          "is-delete": true,
                          "is-quest": "true",
                          width: "600"
                        },
                        on: {
                          modalYes: _vm.execDelete,
                          modalNo: function($event) {
                            _vm.showModalDelete = false
                          }
                        }
                      },
                      [
                        _c("h4", { attrs: { slot: "header" }, slot: "header" }),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { slot: "body" },
                            slot: "body"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "tc pb2em",
                                staticStyle: { "margin-top": "1em" }
                              },
                              [
                                _c("strong", [
                                  _vm._v("選択されたグループを削除しますか？")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isOpenGroupByTask
                  ? _c(
                      "modal-component",
                      {
                        attrs: { "is-quest": "true", width: "1200" },
                        on: {
                          modalYes: function($event) {
                            _vm.isOpenGroupByTask = !_vm.isOpenGroupByTask
                          },
                          modalNo: function($event) {
                            _vm.isOpenGroupByTask = !_vm.isOpenGroupByTask
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("h3", [_vm._v("閲覧可能なタスク")])]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "body" }, slot: "body" },
                          [
                            _c("multi-selection-component", {
                              attrs: {
                                "current-parents": _vm.currentGroupTasks,
                                "parent-item-name": "categoryName",
                                "child-item-name": "taskName",
                                "child-column-name": "tasks",
                                "is-selection-mode": false
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12",
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-login",
                                      on: {
                                        click: function($event) {
                                          _vm.isOpenGroupByTask = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                閉じる\n                            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }