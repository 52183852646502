var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "false", "show-admin": "true" },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("設定/登録")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "container" }, [
              _c(
                "div",
                { staticClass: "columns is-multiline cat-wrapper" },
                [
                  _c("menu-item", {
                    attrs: {
                      "allow-grant": 3,
                      "icon-file-name": "ico-usr.png",
                      "link-path": "/admin/users",
                      "link-text": "ユーザー登録/編集"
                    }
                  }),
                  _vm._v(" "),
                  _c("menu-item", {
                    attrs: {
                      "allow-grant": 3,
                      "icon-file-name": "ico-group.png",
                      "link-path": "/admin/groups",
                      "link-text": "グループ登録/編集"
                    }
                  }),
                  _vm._v(" "),
                  _c("menu-item", {
                    attrs: {
                      "allow-grant": 3,
                      "icon-file-name": "ico-cat.png",
                      "link-path": "/admin/categories",
                      "link-text": "カテゴリ登録/編集"
                    }
                  }),
                  _vm._v(" "),
                  _c("menu-item", {
                    attrs: {
                      "allow-grant": 2,
                      "icon-file-name": "ico-task.png",
                      "link-path": "/admin/tasks",
                      "link-text": "タスク登録/編集"
                    }
                  }),
                  _vm._v(" "),
                  _c("menu-item", {
                    attrs: {
                      "allow-grant": 2,
                      "icon-file-name": "ico-tag.png",
                      "link-path": "/admin/tags",
                      "link-text": "タグ登録/編集"
                    }
                  })
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }