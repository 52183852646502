<template>
  <common-layout-component2 show-back="false" show-admin="true" @on-nav-back="$router.push( { name: 'log-index' } )">
    <template v-slot:nav-message>
      <p>閲覧ログ - ダッシュボード</p>
    </template>

    <template v-slot:body>
      <div class="columns is-gapless logs">
        <log-side-bar />

        <div class="column main-column">
          <div class="main-column-inner">
            <log-period-input v-on:period-updated="on_period_updated" />

            <div class="row board-container board-container01">
              <div class="col-md-4">
                <div class="board board-content-sm">
                  <dl>
                    <dt class="board-title icon-server">サーバー容量</dt>
                    <dd class="board-body has-footnote"><span class="usable-capacity">{{ currentFileSizeSum }}</span>/<span class="total-capacity">{{ max_disk_space }}</span><span class="unit">GB</span><br>
                    <span class="footnote">使用量/契約容量</span>
                    </dd>
                  </dl>
                </div>
              </div>
              <div class="col-md-4">
                <div class="board board-content-sm">
                  <dl>
                    <dt class="board-title icon-user">1ログインでの<br>平均動画閲覧数</dt>
                    <dd class="board-body">
                      <span class="count log-in-count">{{ average_video_play_count_per_login }}</span><span class="unit">回</span>
                    </dd>
                  </dl>				
                </div>
              </div>
              <div class="col-md-4">
                <div class="board board-content-sm">
                  <dl>
                    <dt class="board-title icon-video">動画再生回数</dt>
                    <dd class="board-body">
                      <span class="count view-count">{{ total_video_play_count }}</span><span class="unit">回</span>
                    </dd>
                  </dl>				
                </div>
              </div>
            </div><!-- /.board-container01 -->

            <div class="row board-container board-container02">
              <div class="col-md-6">
                <div class="board tab-panel">
                  <ul class="tab">
                    <li><a href="#tab1-1" class="tabcolor02 selected">ログイン回数</a></li>
                    <li><a href="#tab1-2" class="tabcolor02">ログインユーザー数</a></li>
                  </ul> 
                  <div class="panel">
                    <div id="tab1-1" class="panel-content">
                      <canvas id="authorized_logins_chart"></canvas>
                    </div>

                    <div id="tab1-2" class="panel-content">
                      <canvas id="authorized_users_chart"></canvas>
                    </div>

                  </div><!-- /.panel -->		
                </div><!-- /.board -->
              </div>

              <div class="col-md-6">
                <div class="board board-content-md">
                  <dl>
                    <dt class="board-title icon-task">完了タスク</dt>
                    <dd>
                      <canvas id="completed_tasks_chart"></canvas>
                    </dd>
                  </dl>				
                </div>
              </div>

            </div><!-- /.board-container02 -->

            <div class="row board-container board-container03">
              <div class="col-12">
              <div class="board tab-panel">
                <ul class="tab">
                  <li><a href="#tab2-1" class="tabcolor01 selected"><span class="icon-device">閲覧デバイス</span></a></li>
                  <li><a href="#tab2-2" class="tabcolor01"><span class="icon-user">再生回数が多いユーザー</span></a></li>
                  <li><a href="#tab2-3" class="tabcolor01"><span class="icon-task">よく閲覧されたタスク</span></a></li>
                </ul>
                <div class="panel">
                  <div id="tab2-1" class="panel-content">
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color01 partial-display">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>デバイス</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( device, n ) in top_authorized_devices" :key="device.device">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ device.device_name }}</td>
                              <td>{{ device.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->

                  <div id="tab2-2" class="panel-content">
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color01 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_watched_users_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>ユーザー</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( user, n ) in top_watched_users" :key="user.user_id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ user.user_name }}</td>
                              <td>{{ user.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_watched_users_more" :list="top_watched_users" :limit="3" color="color01"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->

                  <div id="tab2-3" class="panel-content">
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color01 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_viewed_tasks_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>タスク</th>
                              <th>カテゴリ　</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( task, n ) in top_viewed_tasks" :key="task.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ task.task_name }}</td>
                              <td>{{ task.category_name }}</td>
                              <td>{{ task.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_viewed_tasks_more" :list="top_viewed_tasks" :limit="3" color="color01"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->
                </div><!-- /.panel -->
              </div><!-- /.tab-panel -->
              </div>
            </div><!-- /.board-container03 -->
          </div><!-- /.main-column -->
        </div><!-- /.column -->
      </div>
    </template>
  </common-layout-component2>
</template>
<script>

import "Sass/logs.scss"

import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import LogSideBar from './components/LogSideBar';
import LogPeriodInput from './components/LogPeriodInput';
import MoreButton from './components/MoreButton';

import Chart from 'chart.js';
import moment from 'moment';

export default {
  components: {
      CommonLayoutComponent2,
      LogSideBar,
      LogPeriodInput,
      MoreButton,
  },
  
  data: () => { return {
    total_login_count: 0,                   // ログイン回数
    total_video_play_count: 0,              // 動画再生回数

    authorized_logins: [],                  // ログイン回数
    authorized_users: [],                   // ログインユーザー数
    completed_tasks: [],                    // 完了タスク

    authorized_logins_chart: null,    
    authorized_users_chart: null,
    completed_tasks_chart: null,

    top_authorized_devices: [],             // 閲覧デバイス ( ログイン毎に記録 )
    top_watched_users: [],                  // 再生回数が多いユーザー
    top_viewed_tasks: [],                   // よく閲覧されたタスクの一覧

    is_top_watched_users_more: false,
    is_top_viewed_tasks_more: false,
  } },

  computed: {

    /** 使用可能サーバー容量 ( GB ) */
    available_disk_space: function () {
      return this.max_disk_space - this.$store.state.fileSizeSum;
    },

    /** サーバー使用容量 ( GB ) */
    currentFileSizeSum() {
        return this.$store.state.fileSizeSum;
    },

    /** 契約サーバー容量 ( GB ) */
    max_disk_space: function () {
      return this.$store.state.appInfo.maxDiskSize; //process.env.MIX_MAX_DISK_SIZE;
    },

    /** 1 ログインでの平均動画閲覧数 */
    average_video_play_count_per_login: function () {
      if ( ! this.total_login_count || ! this.total_video_play_count )
      {
        return 0;
      }

      return Math.round( this.total_video_play_count / this.total_login_count );
    },
  },

  mounted () {
    Vue.ELearning.Logs.init();
  },

  methods: {
    /**
     * 期間が変更された時の処理
     */
    on_period_updated: function ( begin, end ) {

      let api_params = {
        /* user_id: this.user_id, */ 
        begin: begin,
        end: end,
      };

      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getUsersLoginCount( api_params ), ( data ) => { this.total_login_count = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getVideoPlayCount( begin, end ),  ( data ) => { this.total_video_play_count = data; } );

      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getAuthorizedLogins( begin, end ), ( data ) => { this.authorized_logins = data; this.setup_authorized_logins_chart(); } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getAuthorizedUsers( begin, end ),  ( data ) => { this.authorized_users = data; this.setup_authorized_users_chart(); } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getCompletedTasks( begin, end ),   ( data ) => { this.completed_tasks = data; this.setup_completed_tasks_chart(); } );

      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopAuthorizedDevices( begin, end ), ( data ) => { this.top_authorized_devices = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopWatchedUsers( begin, end ),      ( data ) => { this.top_watched_users = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopViewedTasks( api_params ),       ( data ) => { this.top_viewed_tasks = data; } );

      /*
      Vue.ELearning.Logs.getTopAuthorizedDevices( this.period_begin, this.period_end ).then(
        response => {
          if ( response.status == 200 )
          {
            this.top_authorized_devices = response.data.content;
            // console.log( response.data );

            this.setup_completed_tasks_chart();
          }
        }
      );
      */
    },

    setup_authorized_logins_chart: function () {

      // console.log( this.authorized_logins );
      // console.log( this.authorized_logins.map( ( row ) => moment( row.date ).format( "M/D" ) ) );
      // console.log( this.authorized_logins.map( ( row ) => row.total ) );

      if ( this.authorized_logins_chart )
      {
        this.authorized_logins_chart.destroy();
      }

      this.authorized_logins_chart = new Chart( "authorized_logins_chart", {
        type: 'line',
        data: {
          labels: this.authorized_logins.map( ( row ) => moment( row.date ).format( "M/D" ) ),
          datasets: [{
            data: this.authorized_logins.map( ( row ) => row.count ),
            borderColor: "#00BDDD",
            lineTension: 0.0,
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: "#FFFFFF",
            pointHoverRadius: 5,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function ( value ) { if ( value % 1 === 0 ) { return value; } }
              }
            }]
          },
        }
      });
    },
    
    setup_authorized_users_chart: function () {
      
      if ( this.authorized_users_chart )
      {
        this.authorized_users_chart.destroy();
      }

      this.authorized_users_chart = new Chart( "authorized_users_chart", {
        type: 'line',
        data: {
          labels: this.authorized_users.map( ( row ) => moment( row.date ).format( "M/D" ) ),
          datasets: [{
            data: this.authorized_users.map( ( row ) => row.count ),
            borderColor: "#00BDDD",
            lineTension: 0.0,
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: "#FFFFFF",
            pointHoverRadius: 5,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function ( value ) { if ( value % 1 === 0 ) { return value; } }
              }
            }]
          },
        }
      });
    },

    setup_completed_tasks_chart: function () {
      
      if ( this.completed_tasks_chart )
      {
        this.completed_tasks_chart.destroy();
      }

      this.completed_tasks_chart = new Chart( "completed_tasks_chart", {
        type: 'bar',
        data: {
          labels: this.completed_tasks.map( ( row ) => moment( row.date ).format( "M/D" ) ),
          datasets: [{
            data: this.completed_tasks.map( ( row ) => row.count ),
            backgroundColor: "rgba(79, 190, 43, 0.5)",
          }]
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                categoryPercentage: 0.5,
                barPercentage: 0.5,
              }
            ],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function ( value ) { if ( value % 1 === 0 ) { return value; } }
              }
            }]
          }
        }
      });
    },
  },
};

</script>