var logic = {};

logic.created = function() {
    console.log("Initialize Admin/Categories");

    // 最新情報を取得
    this.refleshData();
};

logic.data = function() {
    return {
        showModalDelete:false,

        radius:32,
        tagOpen: false,
        checkBoxState:false,

        sortOrder:'asc',
        sortAxis:'update_datetime'/* dictionary */,

        categoryInfo: [
            /*
            {
                categoryId: 1,
                categoryName: 'タスク名タスク名タスク名タスク名タスク名タスク名タスク名',
                categoryDesc: 'タスク説明XxxxxxxxxX タスク説明XxxxxxxxxXタスク説明XxxxxxxxxXタスク説明XxxxxxxxxXタスク説明XxxxxxxxxX',
                selected:false,
                enabled:true,
                publicity: '公開',
            },
            */
        ],
        taskInfo: {
            taskName: 'タスク名タスク名タスク名タスク名タスク名タスク名タスク名',
            taskDescription: 'タスク説明XxxxxxxxxX タスク説明XxxxxxxxxXタスク説明XxxxxxxxxXタスク説明XxxxxxxxxXタスク説明XxxxxxxxxX',
            postDate: '2019/01/01',
            postUser: '投稿者００１',
            updateDate: '2019/02/01',
            updateUser: '更新者００１',
            progress:72,
            tags: [
                {
                    tagId:"",
                    tagName:"タグ００１"
                },
            ]
        }
    }
};

logic.methods = {
    sortUpdateDatetime: function(){
        Vue.ELearning.Util.showOverlay(this.$store);

        var axisChanged = false;

        if(this.sortAxis != "update_datetime") {
            axisChanged = true;
        }

        this.sortAxis = "update_datetime";

        if(axisChanged) {
            this.sortOrder = "asc";
        } else {
            if(this.sortOrder == "asc") {
                this.sortOrder = "desc";
            } else {
                this.sortOrder = "asc";
            }
        }

        // APIコール
        var res = Vue.ELearning.Category.getAllWithSort(this.sortAxis, this.sortOrder);
        res.then(function(res){
            if(res.data.status_number == 200) {
                var categories = res.data.content;

                this.categoryInfo = [];
                for(var k in categories) {
                    var item = categories[k];

                    this.categoryInfo.push({
                        'categoryId': item.category_id,
                        'categoryName': item.category_name,
                        'categoryDesc': item.category_text,
                        'selected': false,
                        'enabled':true,
                        'publicity': (item.display_flag == 1) ? '公開' : '非公開',
                    });
                }
            }
            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this)).catch(function(err){
            console.error(err);
            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this));
    },
    sortDictionary: function(){
        Vue.ELearning.Util.showOverlay(this.$store);
        var axisChanged = false;

        if(this.sortAxis != "dictionary") {
            axisChanged = true;
        }

        this.sortAxis = "dictionary";

        if(axisChanged) {
            this.sortOrder = "asc";
        } else {
            if(this.sortOrder == "asc") {
                this.sortOrder = "desc";
            } else {
                this.sortOrder = "asc";
            }
        }


        // APIコール

        var res = Vue.ELearning.Category.getAllWithSort(this.sortAxis, this.sortOrder);
        res.then(function(res){
            if(res.data.status_number == 200) {
                var categories = res.data.content;

                this.categoryInfo = [];
                for(var k in categories) {
                    var item = categories[k];

                    this.categoryInfo.push({
                        'categoryId': item.category_id,
                        'categoryName': item.category_name,
                        'categoryDesc': item.category_text,
                        'selected': false,
                        'enabled':true,
                        'publicity': (item.display_flag == 1) ? '公開' : '非公開',
                    });
                }
            }
            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this)).catch(function(err){
            console.error(err);
            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this));
    },
    navigateEdit: function(categoryId) {
        this.$router.push({
            path: '/admin/category/' + categoryId
        });
    },

    onCheckStateChange: function(category){
        category.selected = !category.selected;
    },

    back: function() {

        this.$router.push({
            path: '/admin/menu/'
        });

    },
    onClickNew: function() {
        this.navigateEdit(0);
    },
    onClickEdit: function() {
        var len = this.categoryInfo.length;
        for(var item in this.categoryInfo) {
            if (this.categoryInfo[item].selected) {
                this.navigateEdit(this.categoryInfo[item].categoryId);
                return;
            }
        }
    },
    onClickReplicate: function() {
        var len = this.categoryInfo.length;
        for(var item in this.categoryInfo) {
            if(this.categoryInfo[item].selected) {
                var categoryId = this.categoryInfo[item].categoryId;

                var res = Vue.ELearning.Category.replicate(categoryId);


                res.then(function(res){
                    console.log(res);

                    // 最新情報を取得
                    this.refleshData();

                }.bind(this)).catch(function(res){
                    console.error(res);
                }.bind(this));

                return;
            }
        }
    },
    onClickDelete: function() {
        var deleteTarget = [];
        for(var item in this.categoryInfo) {

            if(this.categoryInfo[item].selected) {
                // ToDo: 指定IDからカテゴリを削除するAPIをKick

                //this.categoryInfo[item].enabled = false;
                deleteTarget.push(this.categoryInfo[item].categoryId);
            }
        }

        var res = Vue.ELearning.Category.delete(deleteTarget);
        res.then(function(res){
            //console.log(res);
            this.showModalDelete = false;
            // 最新情報を取得
            this.refleshData();

        }.bind(this)).catch(function(res){
            this.showModalDelete = false;
            console.error(res);
        }.bind(this));
    },

    refleshData: function(){
        Vue.ELearning.Util.showOverlay(this.$store);

        var res = Vue.ELearning.Category.getAll();

        res.then(function(res){
            var content = res.data;

            this.categoryInfo = [];
            for(var k in content.content) {
                var item = content.content[k];

                this.categoryInfo.push({
                    'categoryId': item.category_id,
                    'categoryName': item.category_name,
                    'categoryDesc': item.category_text,
                    'selected': false,
                    'enabled':true,
                    'publicity': (item.display_flag == 1) ? '公開' : '非公開',
                });
            }

            this.sortAxis = "update_datetime";
            this.sortOrder = "asc";
            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this)).catch(function(err){
            //console.log(err);
            Vue.ELearning.Util.hideOverlay(this.$store);
        });
    },


    /**
     * CSVダウンロード
     */
    downloadCsv: function() {

        alert("仕様確認中");
        return;


        var res = Vue.ELearning.Category.getAll();

        res.then(function(res){
            var content = res.data;

            var csv = 'categories\n';
            csv += 'カテゴリID,カテゴリ名,カテゴリ説明,公開/非公開\n';
            var line = '';

            //this.categoryInfo = [];
            for(var k in content.content) {
                var item = content.content[k];
                console.log(item);
                line = [item.category_id, item.category_name, item.category_text, item.display_flag].join(",");
                csv += line + '\n';

            }

            var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

            let blob = new Blob([bom, csv], {type: 'text/csv'});

            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, "test.csv");

                // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
                window.navigator.msSaveOrOpenBlob(blob, "test.csv");
            } else {
                document.getElementById("download").href = window.URL.createObjectURL(blob);
                document.getElementById("download").download = "categories.csv";
                document.getElementById("download").click();
                document.getElementById("download").href = "#";
            }

        }.bind(this)).catch(function(err){
            //console.log(err);
        });
    },


    importCsv: function () {
        alert("仕様確認中");
    }
};

logic.computed = {
    sortOrderIndicatorUpdateDatetime: function() {
        if(this.sortAxis !== "update_datetime") {
            return "";
        } else {
            if(this.sortOrder === "asc") {
                return "↓";
            } else {
                return "↑";
            }
        }
    },
    sortOrderIndicatorDictionary: function() {
        if(this.sortAxis !== "dictionary") {
            return "";
        } else {
            if(this.sortOrder === "asc") {
                return "↓";
            } else {
                return "↑";
            }
        }
    },

    categoryList: function() {
        return this.categoryInfo;
    },
    tagIndicator: function() {
        if(this.tagOpen) {
            return "－";
        } else {
            return "＋";
        }
    },
    tagsForShow() {
        if(this.tagOpen) {
            return this.taskInfo.tags;
        } else {
            var result = [];
            var max = 0;
            var min = 1;

            for(var tag in this.taskInfo.tags) {
                if(min <= max) {
                    result.push(this.taskInfo.tags[tag]);
                } else {
                    break;
                }
                min++;
            }

            return /*this.taskInfo.tags*/result;
        }
    },

    editButtonEnabled() {
        var selectCount = 0;

        for(var item in this.categoryInfo) {

            if(this.categoryInfo[item].selected && this.categoryInfo[item].enabled) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if(selectCount == 0) {
            return true;
        }
        if(selectCount == 1) {
            return false;
        }
        return true;
    },
    replicateButtonEnabled() {
        var selectCount = 0;

        for(var item in this.categoryInfo) {

            if(this.categoryInfo[item].selected && this.categoryInfo[item].enabled) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if(selectCount == 0) {
            return true;
        }
        if(selectCount == 1) {
            return false;
        }
        return true;
    },
    deleteButtonEnabled() {
        var selectCount = 0;

        for(var item in this.categoryInfo) {

            if(this.categoryInfo[item].selected && this.categoryInfo[item].enabled) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if(selectCount == 0) {
            return true;
        }
        if(selectCount >= 1) {
            return false;
        }

    },
};

export default logic;