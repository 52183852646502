<template>
    <div class="row">
        <!-- 左側 -->
        <div v-bind:class="rightAnimatedClassObj">
            <div v-show="offCanvasState" @click="changeOffCanvas" class="close-off-canvas"><i class="fas fa-chevron-left"></i></div>

            <slot name="left">

            </slot>
        </div>

        <!-- 右側 -->
        <div v-if="!offCanvasState" v-bind:class="leftAnimatedClassObj">
            <div v-show="!offCanvasState" @click="changeOffCanvas" class="open-off-canvas"><i class="fas fa-chevron-right"></i></div>
            <slot name="right">

            </slot>
        </div>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.leftSize = Number(this.initialRight);
            this.leftTweenedSize = Number(this.initialRight);

            this.rightSize = Number(this.initialLeft);
            this.rightTweenedSize = Number(this.initialLeft);


        },
        data() {

            return {
                'leftSize': 8,
                'leftTweenedSize': 8,

                'rightSize': 4,
                'rightTweenedSize': 4,

                'offCanvasState':false,
            }
        },

        methods: {
            changeOffCanvas: function (event) {
                if (!this.offCanvasState) {
                    this.leftSize = 0;
                    this.rightSize = 12;
                } else {
                    this.leftSize = Number(this.initialRight);
                    this.rightSize = Number(this.initialLeft);
                }

                this.offCanvasState = !this.offCanvasState;

                this.$emit("off-canvas-on-change", this.offCanvasState);
                //console.log(this.initialLeft);
            },
        },

        props: {
            initialLeft: String,
            initialRight: String,
         //   offCanvasState:Boolean
        },

        computed: {
            leftAnimatedSize: function () {
                return this.leftTweenedSize.toFixed(0);
            },
            rightAnimatedSize: function () {
                return this.rightTweenedSize.toFixed(0);
            },
            leftAnimatedClass: function () {
                return "col-md-" + this.leftTweenedSize.toFixed(0);
            },
            rightAnimatedClass: function () {
                return "col-md-" + this.rightTweenedSize.toFixed(0);
            },

            leftAnimatedClassObj: function () {
                var key = "col-md-" + this.leftTweenedSize.toFixed(0);
                var cls = {};
                cls[key] = true;

                return cls;
            },

            rightAnimatedClassObj: function () {
                var key = "col-md-" + this.rightTweenedSize.toFixed(0);

                var cls = {};
                cls[key] = true;
                //console.log("right", key);
                return cls;
            },
        },
        watch: {
            leftSize: function (newValue) {
                TweenLite.to(this.$data, 0.25, {leftTweenedSize: newValue});
            },
            rightSize: function (newValue) {
                TweenLite.to(this.$data, 0.25, {rightTweenedSize: newValue});
            }
        }
    }
</script>
<style scoped>
    .open-off-canvas {
        position:absolute;
        top:13px;
        left:-16px;
        z-index:100000;
        font-size:1.1em;
        border:1px solid gray;

        border-top-right-radius:64px;
        border-bottom-right-radius:64px;

        -webkit-border-top-right-radius: 64px;
        -webkit-border-bottom-right-radius: 64px;

        -moz-border-radius-topright: 64px;
        -moz-border-radius-bottomright: 64px;

        background-color:gray;

        color:white;
        font-weight:bold;

        display:table-cell;
        vertical-align:middle;

        cursor:pointer;
    }

    .close-off-canvas {
        position:absolute;
        top:14px;
        right:32px;
        z-index:100000;

        font-size:1.1em;
        border:1px solid gray;

        border-top-left-radius:64px;
        border-bottom-left-radius:64px;

        -webkit-border-top-left-radius: 64px;
        -webkit-border-bottom-left-radius: 64px;

        -moz-border-radius-topleft: 64px;
        -moz-border-radius-bottomleft: 64px;

        background-color:gray;

        color:white;
        font-weight:bold;

        display:table-cell;
        vertical-align:middle;

        cursor:pointer;

        /*
        -webkit-border-top-left-radius: 10px;
        -webkit-border-top-right-radius: 20px;
        -webkit-border-bottom-right-radius: 30px;
        -webkit-border-bottom-left-radius: 40px;
        -moz-border-radius-topleft: 10px;
        -moz-border-radius-topright: 20px;
        -moz-border-radius-bottomright: 30px;
        -moz-border-radius-bottomleft: 40px;
        */
    }
</style>