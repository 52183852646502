var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "w-content" }, [
        _c(
          "header",
          { staticStyle: { "padding-top": "0", "padding-bottom": "0" } },
          [
            _c(
              "nav",
              {
                staticClass: "navbar",
                staticStyle: { "margin-right": "0", "padding-right": "0" },
                attrs: { "aria-label": "main navigation", role: "navigation" }
              },
              [
                _c(
                  "div",
                  { staticClass: "navbar-brand" },
                  [
                    !_vm.noImageNav
                      ? _c(
                          "router-link",
                          {
                            staticClass: "navbar-item",
                            staticStyle: { "text-decoration": "none" },
                            attrs: { to: "/user/categories" }
                          },
                          [
                            _c("img", {
                              staticClass: "navbar-logo",
                              attrs: {
                                alt: "",
                                src:
                                  _vm.$store.state.documentRoot +
                                  "/images/logo-new.png"
                              }
                            }),
                            _vm._v(" \n                        "),
                            _c("span", { staticClass: "logo" })
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.noImageNav
                      ? _c("span", { staticClass: "navbar-item" }, [
                          _c("img", {
                            staticClass: "navbar-logo",
                            attrs: {
                              alt: "",
                              src:
                                _vm.$store.state.documentRoot +
                                "/images/logo-new.png"
                            }
                          }),
                          _vm._v(" \n                        "),
                          _vm.noImageNav
                            ? _c("span", {
                                staticClass: "logo",
                                staticStyle: { color: "white" }
                              })
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "navbar-menu", attrs: { id: "navbarBasic" } },
                  [
                    _c("div", { staticClass: "navbar-end" }, [
                      _c("div", { staticClass: "navbar-item" }, [
                        _vm.isOnline
                          ? _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isShowHome,
                                        expression: "isShowHome"
                                      }
                                    ],
                                    staticClass: "link-header link-home",
                                    attrs: { id: "", to: "/user/categories" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    ホーム\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.isShowAdmin &&
                                          _vm.currentGrant > 1,
                                        expression:
                                          "isShowAdmin && currentGrant>1"
                                      }
                                    ],
                                    staticClass: "link-header link-log",
                                    attrs: { id: "", to: "/admin/menu" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    設定/登録\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isShowLog,
                                        expression: "isShowLog"
                                      }
                                    ],
                                    staticClass: "link-header link-log-history",
                                    attrs: {
                                      id: "",
                                      to: { path: _vm.log_top_page_path }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    履歴\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isShowLogout,
                                        expression: "isShowLogout"
                                      }
                                    ],
                                    staticClass: "link-header login-username"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.toggleMenu.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.loginUserName))]
                                    ),
                                    _vm._v(" "),
                                    _vm.isOpenMenu
                                      ? _c("div", { staticClass: "usermenu" }, [
                                          _c("div", {
                                            staticClass:
                                              "usermenu-wrap container",
                                            on: { click: _vm.toggleMenu }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "usermenu-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "usermenu-list-wrapper overflow-auto"
                                                },
                                                [
                                                  _c("ul", [
                                                    _c(
                                                      "li",
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to:
                                                                "/user/download"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "ダウンロードしたタスク一覧"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("li", [
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.isShowLogout,
                                                              expression:
                                                                "isShowLogout"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "link-logout",
                                                          attrs: {
                                                            href: "#",
                                                            id: "logout"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.showModalConfirmLogout = true
                                                              _vm.isOpenMenu = false
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("ログアウト")]
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("li", [
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.isShowLogout,
                                                              expression:
                                                                "isShowLogout"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "link-logout",
                                                          attrs: {
                                                            href: "#",
                                                            id: "password"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              _vm.showModalChangePassword = true
                                                              _vm.isOpenMenu = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "パスワード変更"
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isShowHome,
                                        expression: "isShowHome"
                                      }
                                    ],
                                    staticClass: "network_status"
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/images/ico_online.svg",
                                        width: "34px",
                                        height: "auto"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showNotice ? _c("Notification") : _vm._e()
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "buttons" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "link-header link-home",
                                    attrs: { id: "", to: "/user/categories" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                                   ホーム\n                                "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isShowLogout,
                                        expression: "isShowLogout"
                                      }
                                    ],
                                    staticClass: "link-header login-username"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            if (
                                              $event.target !==
                                              $event.currentTarget
                                            ) {
                                              return null
                                            }
                                            return _vm.toggleMenu.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.loginUserName))]
                                    ),
                                    _vm._v(" "),
                                    _vm.isOpenMenu
                                      ? _c("div", { staticClass: "usermenu" }, [
                                          _c("div", {
                                            staticClass:
                                              "usermenu-wrap container",
                                            on: { click: _vm.toggleMenu }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "usermenu-inner" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "usermenu-list-wrapper overflow-auto"
                                                },
                                                [
                                                  _c("ul", [
                                                    _c(
                                                      "li",
                                                      [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to:
                                                                "/user/download"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "ダウンロードしたタスク一覧"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "network_status" }, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.reload.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/images/ico_offline.svg",
                                          width: "34px",
                                          height: "auto"
                                        }
                                      })
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _vm.showNotice ? _c("Notification") : _vm._e()
                              ],
                              1
                            )
                      ])
                    ])
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "bread" }, [
          _c(
            "nav",
            {
              staticClass: "navbar",
              attrs: { "aria-label": "main navigation", role: "navigation" }
            },
            [
              _c("div", { staticClass: "navbar-brand" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isShowBack,
                        expression: "isShowBack"
                      }
                    ],
                    class: {
                      "nav-back": !_vm.isChecklistInCurrent,
                      "nav-back-checklist": _vm.isChecklistInCurrent
                    },
                    style: { navBackStyle: _vm.navBackStyle }
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: { "text-decoration": "none" },
                        attrs: { href: "" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onBack.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c("img", {
                          attrs: {
                            alt: "",
                            src:
                              _vm.$store.state.documentRoot +
                              "/images/ico-lw.png",
                            width: "10"
                          }
                        }),
                        _vm._v(" Back\n                        ")
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("h1", { staticClass: "nav-msg" }, [_vm._t("nav-message")], 2)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "navbar-menu" }),
              _vm._v(" "),
              _c("div", { staticClass: "navbar-end" }, [_vm._t("nav-ctrl")], 2),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOnline,
                      expression: "isOnline"
                    }
                  ],
                  staticClass: "network_status pc-hidden"
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/images/sp/ico_online.svg",
                      width: "34px",
                      height: "auto"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isOnline,
                      expression: "!isOnline"
                    }
                  ],
                  staticClass: "network_status pc-hidden"
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.reload.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/images/sp/ico_offline.svg",
                          width: "34px",
                          height: "auto"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFill,
                expression: "showFill"
              }
            ],
            style: { height: _vm.fillHeight + "px" },
            attrs: { id: "nav-var-fill" }
          })
        ]),
        _vm._v(" "),
        !_vm.simpleLayout
          ? _c(
              "section",
              { class: _vm.wrapperClasses, style: _vm.mainColStyle },
              [_vm._t("body")],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.simpleLayout
          ? _c("section", { staticClass: "mainWindow" }, [_vm._t("body")], 2)
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "footer",
        [
          _c("div", { staticClass: "container" }, [
            _c("div", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowUser,
                      expression: "isShowUser"
                    }
                  ],
                  staticClass: "cache-volume"
                },
                [
                  _c("p", { staticClass: "cache-volume-text" }, [
                    _vm._v("ダウンロード容量の目安")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "cache-volume-volume" }, [
                    _vm._v(
                      _vm._s(_vm.localDiskUsage) +
                        " / " +
                        _vm._s(_vm.localDiskTotal)
                    ),
                    _c("span", [_vm._v("GB")])
                  ])
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "copyright" }, [_vm._v("©ITP")])
            ])
          ]),
          _vm._v(" "),
          _c("SpFooterComponent", {
            attrs: {
              "show-home": _vm.isShowHome,
              "show-logout": _vm.isShowLogout,
              "show-notice": _vm.showNotice,
              "login-user-name": _vm.loginUserName,
              "local-disk-usage": _vm.localDiskUsage,
              "local-disk-total": _vm.localDiskTotal
            },
            on: {
              logout: function($event) {
                _vm.showModalConfirmLogout = true
              },
              "change-password": function($event) {
                _vm.showModalChangePassword = true
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showModalConfirmLogout
        ? _c(
            "modal-component",
            {
              attrs: { "is-quest": "true", width: "600" },
              on: {
                modalYes: _vm.doLogout,
                modalNo: function($event) {
                  _vm.showModalConfirmLogout = false
                }
              }
            },
            [
              _c(
                "h3",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "body" },
                  slot: "body"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "tc pb2em",
                      staticStyle: { "margin-top": "0.5em" }
                    },
                    [_c("strong", [_vm._v("ログアウトしますか？")])]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalChangePassword || _vm.passwordChangeRequired
        ? _c("ChangePasswordComponent", {
            attrs: { "force-change": _vm.passwordChangeRequired },
            on: {
              saved: function($event) {
                _vm.showModalChangePassword = false
              },
              cancel: function($event) {
                _vm.showModalChangePassword = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }