var logic = {};

logic.created = function() {
    console.log('Initialize Admin/CategoryEdit');

    if (this.$route.params.categoryId > 0) {
        this.regist = false;
        // 実際はサーバからデータを取得する。

        var categoryId = this.$route.params.categoryId;

        var api = Vue.ELearning.Category.getOne(categoryId);
        api.then(
            function(res) {
                console.log(res);
                if (res.data.status_number == 200) {
                    var content = res.data.content;

                    this.categoryId = content.category_id;
                    this.categoryName = content.category_name;
                    this.categoryDesc = content.category_text;

                    if (content.display_flag == 1) {
                        this.publicity = 'public';
                    } else {
                        this.publicity = 'negative';
                    }
                } else {
                    this.$dialog.message('カテゴリデータを取得できませんでした。');
                }
            }.bind(this),
        ).catch(function(res) {
            console.error(res);
        });

        /*
        //this.taskId = 2;
        this.categoryName = 'タグ名０００００１';
        this.categoryDesc = 'タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明';
        this.publicity = 'negative';
        */
    } else {
        this.categoryName = '';
        this.categoryDesc = '';
        this.publicity = 'public';
    }
};

logic.data = function() {
    return {
        categoryId: '',
        categoryName: '',
        categoryDesc: '',
        publicity: 'public',
        regist: true,

        errCategoryName: false,
        categoryNameErrors: [],
    };
};

logic.computed = {
    title() {
        return `カテゴリ${this.regist ? '登録' : '編集'}`;
    },
    submitLabel() {
        return `${this.regist ? '' : '上書き'}保存`;
    },
    saveBtnTitle: function() {
        var categoryId = this.$route.params.categoryId;
        if (categoryId > 0) {
            return '上書き保存';
        } else {
            return '保存';
        }
    },
};

logic.methods = {
    errorHandler: function(content) {
        if (content.errors.category_name != undefined) {
            console.log('err-category_name');
            this.errCategoryName = true;
            this.categoryNameErrors = [];
            for (var key in content.errors.category_name) {
                var item = content.errors.category_name[key];
                this.categoryNameErrors.push(item);
            }
        }
    },

    back: function() {
        this.$router.push('/admin/categories');
    },

    onCancel: function() {
        this.$router.push('/admin/categories');
    },
    onUpdate: function() {
        // 更新処理(API呼び出し)

        this.errCategoryName = false;
        this.categoryNameErrors = [];

        if (this.$route.params.categoryId > 0) {
            // Update

            var api = Vue.ELearning.Category.update({
                categoryId: this.$route.params.categoryId,
                categoryName: this.categoryName,
                categoryDesc: this.categoryDesc,
                publicity: this.publicity,
            });

            api.then(
                function(res) {
                    console.log(res);

                    var content = res.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        console.error(content);

                        this.errorHandler(content);

                        Vue.ELearning.Util.hideOverlay(this.$store);
                        return;
                    }

                    this.$router.push('/admin/categories');
                }.bind(this),
            ).catch(
                function(res) {
                    console.log(res);
                    this.$router.push('/admin/categories');
                }.bind(this),
            );
        } else {
            // Insert
            var api = Vue.ELearning.Category.insert({
                categoryName: this.categoryName,
                categoryDesc: this.categoryDesc,
                publicity: this.publicity,
            });

            api.then(
                function(res) {
                    console.log(res);

                    var content = res.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        console.error(content);

                        this.errorHandler(content);

                        Vue.ELearning.Util.hideOverlay(this.$store);
                        return;
                    }

                    this.$router.push('/admin/categories');
                }.bind(this),
            ).catch(
                function(res) {
                    console.log(res);
                    this.$router.push('/admin/categories');
                }.bind(this),
            );
        }

        //this.$router.push("/admin/categories");
    },
};

export default logic;