<template>
                    <div class="description">
                        <div v-if="showToggleButton && !showDesc" class="description">
                            <p class="short">{{ shortDescription }}</p>
                        </div>

                        <p v-if="showToggleButton && !showDesc" class="align_center"><a href="#" @click.stop.prevent="showDesc=true" class="toggle open">レッスン情報をもっと見る<i class="fas fa-angle-down"></i></a></p>
                        <div v-if="!showToggleButton || showDesc">
                        <div class="description">
                            <p>{{ lesson.description }}</p>
                        </div>
                        <div class="box_wh contents">
                            <div v-if="lesson.goal">
                                <h3>ゴール</h3>
                                <p>{{ lesson.goal }}
                                </p>
                            </div>
                            <div>
                                <h3>レッスン内容</h3>
                                <ul>
                                    <template v-for="(item ,index) in lesson.steps">
                                    <li :key="index">{{ item.title }}</li>
                                    </template>
                                </ul>
                            </div>
                            <div>
                                <h3>この講師について</h3>
                                <div class="about_teacher flex">
                                    <a :href="$libs.url(['teacher',lesson.user_id])">
                                        <div class="icon">
                                            <p :style="iconImage(lesson.user.image)"></p>
                                        </div>
                                    </a>
                                    <div class="name">
                                        <a :href="$libs.url(['teacher',lesson.user_id])">
                                            {{ lesson.user.display_name }}
                                        </a>
                                    </div>
                                </div>
                                <p>{{ lesson.user.meta.profile }}</p>
                            </div>
                        </div>
                        <div class="box_wh lesson_info">
                            <dl class="flex_nw">
                                <dt>レッスンステップ数</dt>
                                <dd>{{ lesson.steps.length }}ステップ</dd>
                            </dl>
                            <dl class="flex_nw">
                                <dt>カテゴリー</dt>
                                <dd>{{ partName }}</dd>
                            </dl>
                            <dl class="flex_nw">
                                <dt>難易度</dt>
                                <dd>
                                    <level-component :level="lesson.level"></level-component>
                                </dd>
                            </dl>

                            <dl class="flex_nw">
                                <dt>販売価格</dt>
                                <dd>{{ lesson.price.toLocaleString() }}円</dd>
                            </dl>
                            <dl class="flex_nw">
                                <dt>採点・質問（/回）</dt>
                                <dd>{{ lesson.advice_price.toLocaleString() }}円</dd>
                            </dl>
                            <dl class="flex_nw">
                                <dt>公開日</dt>
                                <dd>{{ publishedDate }}</dd>
                            </dl>
                            <dl class="flex_nw">
                                <dt>視聴期限</dt>
                                <dd v-if="isPurchased">{{ expirationDate }}</dd>
                                <dd v-if="!isPurchased">購入日から{{ lesson.valid_days }}日</dd>                                
                            </dl>
                        </div>
                        </div>
                        <p v-if="showToggleButton" class="align_center"><a href="#" @click.stop.prevent="showDesc=false" v-if="showDesc" class="toggle close">レッスン情報をとじる<i class="fas fa-angle-up"></i></a></p>
                    </div>
</template>

<script>
import LevelComponent from '../../miso/commons/LevelComponent.vue';
import moment from 'moment';

export default {

  components: {
      LevelComponent,
  },
  props: {
        lesson: Object,
        showToggleButton: Boolean,
  },
  data: function () {
    return {
      showDesc:false,
    };
  },
  mounted: function () {
  },
  computed: {
    progress: function () {
      return 33;
    },
    link: function(){
        return "/lesson/" + this.lesson.id
    },
    partName: function(){
        return this.$config.name("parts",this.lesson.part)
    },
    genreName: function(){
        return this.$config.name("genres",this.lesson.genre)
    },
    shortDescription: function(){
        if(this.lesson && this.lesson.description.length > 80){
            return this.lesson.description.substr( 0, 80 ) + "..."
        }
       return this.lesson.description
    },
    publishedDate: function(){

        if(this.lesson){
            const date = moment(this.lesson.published_datetime)
            return date.format("YYYY/MM/DD")
        }
        return null
    },

    isPurchased: function(){
        if(this.lesson){
            return this.$store.state.userInfo.grant == 1 && this.lesson.purchases.length > 0
        }else{
            return false
        }
    },
    expirationDate: function(){
        if(this.lesson){
            var date 

            //購入情報のうち、有効期限が一番遅いものを使用する
            const purchase = this.lesson.purchases.forEach(purchase => {
                var expirationDate = moment(purchase.expiration_datetime)
                if(!date || expirationDate.isAfter(date)){
                    date = expirationDate
                }
            })
            return date.format("YYYY/MM/DD HH:mm")
        }
        return null
    }    
  },
  methods: {
    iconImage: function (path) {
      if (path) {
        return "background: url(" + path + ") center center / cover";
      } else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
