<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="レッスン" main-class="lesson_page">
        <template v-slot:body v-if="lesson">
            <section class="lesson_header bg_yellow">
                <div class="container pt0 pb0">
                    <lesson-video-component :video="videoPath" :thumbnail="videoThumbnail" />
                </div>
            </section>
            <section class="bg_yellow">
                <div class="container pt20 pb10">
                    <div class="user_teacher flex">
                        <a :href="$libs.url(['teacher',lesson.user.id])">
                            <div class="icon">
                                <p :style="iconImage(lesson.user.image)"></p>
                            </div>
                        </a>
                        <div class="name">
                            <p>講師</p>
                            <a :href="$libs.url(['teacher',lesson.user.id])">{{ lesson.user.display_name }}</a>
                        </div>
                    </div>
                    <div class="description">
                        <div class="categories flex">
                            <div class="cat no_border">{{ partName }}</div>
                            <level-component :level="lesson.level"></level-component>
                        </div>
                        <h1>{{ lesson.title }}</h1>
                    </div>
                </div>
                <!-- 現在の受講者 講師のみ　-->
                <div v-if="lesson.user_id == $store.state.userInfo.id && $store.state.userInfo.grant != 1" class="current_students">
                    <a :href="$libs.url(['lesson',lesson.id, 'purchased'])">
                        <span>現在の受講者</span>
                        <span class="num">{{ lesson.purchases_count }}</span>
                    </a>
                </div>
                <div class="container pt0">
                    <!--
                    <div class="description">
                        <p class="short">{{ shortDescription }}</p>
                    </div>
                    -->
                    <div>
                        <div class="types flex_nw mt20">
                            <a @click.stop.prevent="viewType = 1" :class="{current: viewType == 1}">レッスン</a>
                            <a @click.stop.prevent="viewType = 2" :class="{current: viewType == 2}">採点</a>
                            <a @click.stop.prevent="viewType = 3" :class="{current: viewType == 3}">Q &amp; A</a>
                        </div>
                        <!-- ステップ　-->
                        <div v-if="viewType == 1" class="box_wh steps">
                            <ul>
                                <template v-for="(item,index) in lesson.steps">
                                <li :key="index">
                                    <a :href="$libs.url(['lesson',lesson.id, index])" :class="{complete: item.progress.length > 0}">
                                        <p class="step">
                                            <i v-if="item.progress.length > 0 && item.progress[0].status == 1" class="fas fa-check"></i>
                                            Step{{ index + 1 }}
                                        </p>
                                        <p>{{ item.title }}</p>
                                        <i class="fas fa-angle-right"></i>
                                    </a>
                                </li>
                                </template>
                            </ul>
                        </div>
                        <!-- 採点　-->
                        <template v-if="viewType == 2">
                            <lesson-scores-component :lesson="lesson" :my-scores="myScores" :scores="scores">
                            </lesson-scores-component>
                        </template>

                        <!-- Q & A　-->
                        <template v-if="viewType == 3">
                            <lesson-qa-component  :lesson="lesson" :my-qa="myQa" :qa="qa">
                            </lesson-qa-component>
                        </template>

                        <h2>レッスン情報</h2>
                        <lesson-description-component :lesson="lesson" :show-toggle-button="true">
                        </lesson-description-component>
                    </div>

                </div>
            </section>
            <modal-component v-if="showModalComment" :title="commentModalTitle" :view-selectable="true" @yes="commentPurchase" @no="showModalComment = false"/>
            <modal-component v-if="showModalCommentPurchased" title="ご購入ありがとうございます！" :view-plain="true" :ok="commentModalOkTitle" @ok="onCommentPurchased" />
            
        </template>
        <template v-slot:action>
            <div>
            <template v-if="viewType == 2 && $store.state.userInfo.grant == 1">
                <div class="container pt10 pb10">
                    <div class="action">
                        <template v-if="lesson.del_flag == 0 && lesson.draft == 0 && lesson.display_flag == 1">
                            <button v-if="lesson.advice_price > 0" class="round" @click="showModalComment = true">採点してもらう</button>
                            <button v-if="lesson.advice_price == 0" class="round" @click="commentPurchase()">採点してもらう</button>
                        </template>
                        <template v-else>
                            <button class="round" disabled>採点依頼はできません</button>
                        </template>
                    </div>
                </div>
            </template>
            <template v-if="viewType == 3 && $store.state.userInfo.grant == 1">
                <div class="container pt10 pb10">
                    <div class="action">
                        <template v-if="lesson.del_flag == 0 && lesson.draft == 0 && lesson.display_flag == 1">
                            <!--<button class="round" @click="showModalComment = true">質問する</button>-->
                            <button class="round" @click="commentPurchase()">質問する</button>
                        </template>
                        <template v-else>
                            <button class="round" disabled>質問できません</button>
                        </template>
                    </div>
                </div>
            </template>
            <template v-if="lesson && lesson.user_id == $store.state.userInfo.id && $store.state.userInfo.grant==2">
                    <div class="create">
                        <div class="flex_nw">
                            <button class="round" @click="$libs.pushUrl(['lesson', lessonId, 'edit'])">編集</button>
                        </div>
                    </div>
            </template>
            </div>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import LessonVideoComponent from '../../miso/user/LessonVideoComponent.vue';
    import LessonDescriptionComponent from '../../miso/user/LessonDescriptionComponent.vue';
    import LessonScoresComponent from '../../miso/user/LessonScoresComponent.vue';
    import LessonQaComponent from '../../miso/user/LessonQaComponent.vue';
    import LevelComponent from '../../miso/commons/LevelComponent.vue';
    import moment from 'moment';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonDescriptionComponent,
            LessonScoresComponent,
            LessonQaComponent,
            LessonVideoComponent, 
            LevelComponent,
        },
        props: {
            lessonId: {
                type: Number,
            },
        },

        data: function () {
            return {
                lesson:null,
                viewType:1,//1:レッスン 2:評価 3:QA
                scores:null,
                myScores:null,
                qa:null,
                myQa:null,
                showModalComment: false,
                showModalCommentPurchased: false,
            }
        },
        watch: {
            viewType :function(val){
                console.log('watch showAdvices:', val)
                if(val >= 2 && this.lesson){
                    this.viewedComment(val)
                }
            }
        },
        mounted:function(){
            console.log("lessonID:",this.lessonId)
            this.getData()

            const urlObj = this.$libs.urlObj()
            if(urlObj.query && urlObj.query.view == "advice"){
                this.viewType = 2
            }
            if(urlObj.query && urlObj.query.view == "qa"){
                this.viewType = 3
            }            
        },
        computed: {
            partName: function(){
                return this.$config.name("parts",this.lesson.part)
            },
            genreName: function(){
                return this.$config.name("genres",this.lesson.genre)
            },
            videoPath: function(){
                if(this.lesson.video && this.lesson.video.video_path){
                    return this.lesson.video.video_path
                }
                return null
            },
            videoThumbnail: function(){
                if(this.lesson.video && this.lesson.video.thumbnail_path){
                    return this.lesson.video.thumbnail_path
                }
                return null
            },
            commentModalTitle:function(){

                const purchasedComment = this.lesson.purchases.filter(purchase =>{
                    return purchase.type == this.viewType
                })
                if(purchasedComment.length > 0){
                    //チケット購入済み
                    if(this.viewType == 2){
                        return '購入したチケットを使用して採点を依頼しますか？'
                    }else{
                        return '購入したチケットを使用して質問しますか？'
                    }
                }else{
                    if(this.viewType == 2){
                        return this.lesson.advice_price.toLocaleString() + '円で採点を依頼しますか？'
                    }else{
                        return this.lesson.advice_price.toLocaleString() + '円で質問しますか？'
                    }
                }
            },
            commentModalOkTitle:function(){

                if(this.viewType == 2){
                    return '採点ページ'
                }else{
                    return '質問ページ'
                }
            },

            shortDescription: function(){
                if(this.lesson && this.lesson.description.length > 80){
                    return this.lesson.description.substr( 0, 80 ) + "..."
                }
                return this.lesson.description
            }
        },

        methods:{
            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTask(this.lessonId);
                taskHandler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            this.lesson = res.data.content;

                            //生徒で未購入の場合は紹介TOPへリダイレクト
                            if(this.$store.state.userInfo.grant == 1 && this.lesson.purchases.length == 0){
                                this.$libs.pushUrl(["lesson",this.lessonId])
                                return
                            }
                            //講師で自分のレッスンでない場合は紹介TOPへリダイレクト
                            if(this.$store.state.userInfo.grant == 2 && this.lesson.user_id != this.$store.state.userInfo.id){
                                this.$libs.pushUrl(["lesson",this.lessonId])
                                return
                            }

                            this.makeScores()
                            this.makeQandA()

                            if(this.viewType >= 2){
                                this.viewedComment(this.viewType)
                            }
                        }
                    }.bind(this),
                )
                .catch(function(res) {
                    throw new Error("タスクの取得に失敗しました", res);
                }.bind(this));
            },

            /**
            * コメントの閲覧ログ type: 2:採点 3:Q&A 
            */
            viewedComment:function(type){
                    var commentIds = []

                    var comments = (type == 2)? this.scores : this.qa
                    //講師からの自分へのコメントをすべて抽出
                    comments.forEach( comment => {

                        if(comment.user_id == this.$store.state.userInfo.id && comment.reply){

                            //講師からの返信に絞る
                            const replyComments = comment.reply.filter( reply => {
                                return reply.user_id == this.lesson.user_id
                            })
                            replyComments.forEach( comment => {
                                commentIds.push(comment.id)
                            })
                        }
                    })

                    if(commentIds.length > 0){
                        const params = {
                            task_id: this.lessonId,
                            comment_ids: commentIds
                        }
                        console.log("viewedComment:", params)
                        Vue.ELearning.Task.viewedComment(params)
                        .then(
                            function(res) {
                                if (res.data.status_number == 200) {
                                    console.log("viewedComment", res.data.content);
                                }
                            }.bind(this),
                        ).catch(function(res) {
                               throw new Error("コメント閲覧ログに失敗しました", response);
                        }.bind(this));
                    }
            },

            iconImage: function (path) {
                if (path) {
                    return "background: url(" + path + ") center center / cover";
                } else {
                    return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
                }
            },
            makeScores: function(){
                this.scores = this.lesson.scores.filter(function(score){
                    return !score.reply_id 
                })
                this.scores.forEach( parent => {
                    parent.replies = this.lesson.scores.filter(function(score){
                        return score.reply_id == parent.id
                    })
                })
                
                //返信があるものだけに絞る
                this.scores = this.scores.filter(function(score){
                    return score.replies.length > 0
                })
                
                //ベストスコア
                this.scores.forEach( score => {
                    var scores = score.replies.map(function(reply){ return reply.score })
                    const aryMax = function (a, b) {return Math.max(a, b);}
                    let max = scores.reduce(aryMax);
                    score.bestScore = max
                })
                
                //スコアで並べ替え
                this.scores.sort(function(a, b) {
                    if (a.bestScore > b.bestScore) {
                        return -1;
                    } else {
                        return 1;
                    }
                })
                

                this.myScores = this.lesson.my_scores
                /*
                this.myScores = this.lesson.my_scores.filter(function(score){
                    return !scores.reply_id
                })
                this.myScores.forEach( parent => {
                    parent.reply = this.lesson.my_scores.filter(function(score){
                        return scores.reply_id == parent.id
                    })
                })
                */                
            },
            makeQandA: function(){
                
                this.qa = this.lesson.qa.filter(function(qa){
                    return !qa.reply_id 
                })
                this.qa.forEach( parent => {
                    parent.reply = this.lesson.qa.filter(function(qa){
                        return qa.reply_id == parent.id
                    })
                })

                this.myQa = this.lesson.my_qa;
                /*
                this.myQa = this.lesson.my_qa.filter(function(qa){
                    return !qa.reply_id
                })
                this.myQa.forEach( parent => {
                    parent.reply = this.lesson.my_qa.filter(function(qa){
                        return qa.reply_id == parent.id
                    })
                })
                */                
            },
            
            commentPurchase: function(){
                this.showModalComment = false

                const purchasedComment = this.lesson.purchases.filter(purchase =>{
                    return purchase.type == this.viewType //採点  or 質問
                })
                //購入済みなら遷移
                if(purchasedComment.length > 0){
                    this.onCommentPurchased()
                    return
                }

                Vue.ELearning.Purchase.purchase({task_id: this.lesson.id, type: this.viewType})
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("purchase", res.data.content);

                            if(res.data.content.result == "NG"){
                                this.$dialog.message("エラーが発生しました");
                            }else{
                                this.showModalCommentPurchased = true
                            }
                        }else if(res.data.status_number == 401){
                            this.$libs.pushUrl("login")
                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },

            onCommentPurchased: function(){
                var pathName = (this.viewType == 2) ? 'advice' : 'question'
                this.$libs.pushUrl(["lesson",this.lessonId, pathName])
            },

            date: function(stringDate){
                var now = moment()
                var date = moment(stringDate);

                if(now.diff(date, 'days') > 90){
                    return date.format("YYYY年MM月DD日"); 
                }else{
                    return date.format("MM月DD日"); 
                }
            },
            
            onNaviBack: function(){
                if(this.lesson){
                    this.$libs.pushUrl("mylesson")

                }else{
                    history.back()
                }
            },

        }
    }
</script>
<style lang="scss" scoped>
</style>
