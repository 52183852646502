var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { class: _vm.rightAnimatedClassObj },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.offCanvasState,
                expression: "offCanvasState"
              }
            ],
            staticClass: "close-off-canvas",
            on: { click: _vm.changeOffCanvas }
          },
          [_c("i", { staticClass: "fas fa-chevron-left" })]
        ),
        _vm._v(" "),
        _vm._t("left")
      ],
      2
    ),
    _vm._v(" "),
    !_vm.offCanvasState
      ? _c(
          "div",
          { class: _vm.leftAnimatedClassObj },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.offCanvasState,
                    expression: "!offCanvasState"
                  }
                ],
                staticClass: "open-off-canvas",
                on: { click: _vm.changeOffCanvas }
              },
              [_c("i", { staticClass: "fas fa-chevron-right" })]
            ),
            _vm._v(" "),
            _vm._t("right")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }