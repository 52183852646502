var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "受講者",
      "main-class": "lesson_page lesson_users"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.lesson
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "popular bg_yellow" }, [
                    _c("div", { staticClass: "lessons" }, [
                      _c("div", { staticClass: "lesson" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$libs.url(["lesson", _vm.lessonId])
                            }
                          },
                          [
                            _c("div", { staticClass: "contents" }, [
                              _c(
                                "div",
                                { staticClass: "flex_nw" },
                                [
                                  _c("lesson-video-thumbnail-component", {
                                    attrs: { video: _vm.lesson.video }
                                  }),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "categories flex" },
                                      [
                                        _c("div", { staticClass: "cat" }, [
                                          _vm._v(_vm._s(_vm.partName))
                                        ]),
                                        _vm._v(" "),
                                        _c("level-component", {
                                          attrs: { level: _vm.lesson.level }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("h3", [
                                      _vm._v(_vm._s(_vm.lesson.title))
                                    ]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "description" }, [
                                      _vm._v(_vm._s(_vm.lesson.description))
                                    ])
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "users bg_white" },
                      [
                        _vm._l(_vm.lesson.purchases, function(
                          purchased,
                          index
                        ) {
                          return [
                            _c("div", { key: index, staticClass: "user" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.$libs.url([
                                      "user",
                                      purchased.user.id
                                    ])
                                  }
                                },
                                [
                                  _c("div", { staticClass: "flex_nw" }, [
                                    _c("div", { staticClass: "icon" }, [
                                      _c("p", {
                                        style: _vm.iconImage(
                                          purchased.user.image
                                        )
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "right" }, [
                                      _c("p", { staticClass: "name" }, [
                                        _vm._v(
                                          _vm._s(purchased.user.display_name)
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _c("i", {
                                        staticClass: "fas fa-angle-right"
                                      })
                                    ])
                                  ])
                                ]
                              )
                            ])
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }