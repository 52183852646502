var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flow-container" }, [
    _c(
      "div",
      {
        staticClass: "flow dir1 bg-gray",
        class: {
          "last-flow": _vm.isLast,
          skipped: _vm.isOpen
        },
        style: { backgroundColor: _vm.backgroundColor },
        on: { click: _vm.showItem }
      },
      [
        _c("label", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.isNotIncomplete,
                expression: "isNotIncomplete"
              }
            ],
            staticClass: "checkbox01-input",
            attrs: { type: "checkbox", checked: "checked", disabled: "" },
            domProps: {
              checked: Array.isArray(_vm.isNotIncomplete)
                ? _vm._i(_vm.isNotIncomplete, null) > -1
                : _vm.isNotIncomplete
            },
            on: {
              change: function($event) {
                var $$a = _vm.isNotIncomplete,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.isNotIncomplete = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.isNotIncomplete = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.isNotIncomplete = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "checkbox01-parts",
            style: {
              color: _vm.textColor,
              fontSize: _vm.fontSize
            },
            domProps: { textContent: _vm._s(_vm.name) }
          }),
          _vm._v(" "),
          _vm.isUpdated
            ? _c("img", {
                staticClass: "ico-task-attention",
                attrs: { src: "/images/ico-task-attention.png", alt: "" }
              })
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _vm.showCtrl
      ? _c("div", { staticClass: "buttons2" }, [
          _c(
            "a",
            {
              staticClass: "button basic is-gray action-skip",
              on: {
                click: function($event) {
                  return _vm.onSkip(_vm.item)
                }
              }
            },
            [_vm._v("スキップ")]
          ),
          _c("span", [_vm._v("  ")]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button basic is-login action-complete",
              on: {
                click: function($event) {
                  return _vm.onComplete(_vm.item)
                }
              }
            },
            [_vm._v("完了")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }