var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-component",
    {
      attrs: { "is-quest": "true", width: "600" },
      on: { modalYes: _vm.toggle, modalNo: _vm.toggle }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("h3", [_vm._v("タグ作成")])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _c("div", { staticClass: "row form-group" }, [
          _c("div", { staticClass: "col-md-2" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }, [
            _c("label", { staticClass: "form-check-label" }, [_vm._v("タグ名")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.name,
                  expression: "name"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.name = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 button-wrap" }, [
            _c(
              "button",
              {
                staticClass: "button basic is-cancel",
                on: { click: _vm.cancel }
              },
              [_vm._v("\n                    キャンセル")]
            ),
            _vm._v("  \n                "),
            _c(
              "button",
              {
                staticClass: "button basic is-login",
                on: { click: _vm.create }
              },
              [_vm._v("\n                    作成\n                ")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }