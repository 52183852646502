<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="生徒" main-class="my_page purchased_users">
        <template v-slot:body>

            <section class="bg_yellow">
                <div class="container pt20 pb10">
                    <div class="page_header">
                        <div>
                            <p class="result_num"><span>{{ count }}</span>名</p>
                        </div>
                    </div>
                </div>
                <div class="users bg_white">
                    <template v-if="users && users.length > 0">
                        <template v-for="item,index in users">
                        <div class="user" :key="index">
                        <a :href="$libs.url(['user',item.id])">
                            <div class="flex_nw">
                                <div class="icon">
                                    <p :style="iconImage(item.image)"></p>
                                </div>
                                <div class="right">
                                    <p class="name">{{item.display_name}}</p>
                                </div>
                                <p><i class="fas fa-angle-right"></i></p>
                            </div>
                        </a>
                        </div>
                        </template>
                    </template>
                    <template v-else-if="users">
                        <p class="mt50">受講者はまだいません。</p>
                    </template>
                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../miso/commons/ModalComponent.vue';
export default {
    components: {
    CommonLayoutComponent,
    ModalComponent,
    },
  data: function () {
    return {
        userIcon:null,
        displayName: "",
        showModalLogout: false,
        users: null,
        count: 0,
    };
  },
  created: function () {},

  mounted: function () {
      this.refleshData();
  },
  computed: {
  },
  methods: {
    refleshData: function() {

            // ユーザー情報取得
            var handler = Vue.ELearning.UserInfo.myStudents();

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    this.users = response.data.content.result;
                    this.count = response.data.content.count;

                } else {
                    throw new Error("購入情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },
    onNaviBack: function() {
        this.$libs.pushUrl("mypage")
    },
    iconImage: function(image) {
          if(image){
              return 'background: url(' + image + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      }

  },
}

</script>
<style lang="scss" scoped>
</style>
