var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "l-footer p-footer" }, [
      _c("div", { staticClass: "p-footer__sitemap c-flex" }, [
        _c("div", { staticClass: "c-list c-flex__col-33 c-flex__col-100-sm" }, [
          _c("ul", [
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/register" } }, [
                _vm._v("新規会員登録")
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/search" } }, [
                _vm._v("レッスンを探す")
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/notice" } }, [_vm._v("お知らせ")])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-list c-flex__col-33 c-flex__col-100-sm" }, [
          _c("ul", [
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/guide" } }, [_vm._v("ご利用ガイド")])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/faq" } }, [_vm._v("よくある質問")])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "mailto:contact@misoshiru.net?subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&body=%E3%81%8A%E5%90%8D%E5%89%8D%3A%0D%0A%0D%0A%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%3A%0D%0A%0D%0A%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%3A%0D%0A"
                  }
                },
                [_vm._v("お問い合わせ")]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "c-list c-flex__col-33 c-flex__col-100-sm" }, [
          _c("ul", [
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/terms" } }, [_vm._v("利用規約")])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "/privacy" } }, [
                _vm._v("プライバシーポリシー")
              ])
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "c-list__item c-list__item--plain" }, [
              _c("a", { attrs: { href: "#" } }, [_vm._v("運営会社")])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "p-footer__copyright" }, [
        _vm._v("©misoshiru team")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }