var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns is-gapless" },
    [
      _c(
        "div",
        {
          staticClass: "column is-one-quarter",
          staticStyle: { "overflow-y": "auto" },
          style: { "max-height": _vm.contentHeight + "px" }
        },
        [
          _c(
            "div",
            { attrs: { id: "sidebar" } },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.props, function(p, idx) {
                return [
                  _c("work-procedure", {
                    key: p.work_procedure_id,
                    attrs: {
                      item: p,
                      "is-last": _vm.isLastProcedure(idx),
                      "action-name": "updateCurrentProcedure"
                    }
                  })
                ]
              }),
              _vm._v(" "),
              _c("p", { staticClass: "tc pt10" }, [
                _c(
                  "a",
                  {
                    staticClass: "button basic is-login",
                    staticStyle: { color: "white" },
                    on: { click: _vm.openSaveDialog }
                  },
                  [
                    _c("img", {
                      attrs: { src: "/images/ico-report.png", alt: "" }
                    }),
                    _vm._v(
                      "  " +
                        _vm._s(
                          _vm.isCompleted ? "タスク完了" : "保存して中断"
                        ) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("check-list-detail", {
        attrs: {
          "content-height": _vm.contentHeight,
          index: _vm.prop.index,
          name: _vm.prop.name,
          notice: _vm.prop.notice,
          description: _vm.prop.description,
          "base-path": _vm.prop.basepath,
          "video-path": _vm.prop.videopath,
          "hide-content": _vm.hideContent
        },
        on: {
          skip: _vm.modalSkip,
          completed: _vm.modalCompleted,
          toggle: _vm.toggle,
          "video-play": function($event) {
            return _vm.$emit("video-play")
          }
        }
      }),
      _vm._v(" "),
      _vm.showModalCompleted
        ? _c("confirm-modal", {
            attrs: {
              "continue-func": _vm.completeByCurrentProc,
              "close-func": _vm.closeModalCompleted,
              title: "タスク完了の確認",
              message: "タスクを完了しますか？"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalSkip
        ? _c("confirm-modal", {
            attrs: {
              "continue-func": _vm.skipByCurrentProc,
              "close-func": _vm.closeModalSkip,
              title: "タスクスキップの確認",
              message: "タスクをスキップしますか？"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalTaskCompleted
        ? _c("confirm-modal", {
            attrs: {
              "continue-func": _vm.completeByTask,
              "close-func": _vm.closeModalTaskCompleted,
              title: "タスク完了の確認",
              message: "タスクを完了しますか？"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalSaveHistory
        ? _c(
            "modal-component",
            {
              attrs: { "is-quest": "true" },
              on: {
                modalYes: _vm.saveHistory,
                modalNo: function($event) {
                  _vm.showModalSaveHistory = false
                }
              }
            },
            [
              _c("h4", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("確認")
              ]),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "body" },
                  slot: "body"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "tc pb2em",
                      staticStyle: { "padding-bottom": "0" }
                    },
                    [_c("strong", [_vm._v("タスクを保存して中断しますか？")])]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalDownload
        ? _c("task-download-modal", {
            attrs: { downloaded: _vm.taskDownloaded },
            on: { "close-modal-download": _vm.closeModalDownload }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "side-title" }, [
      _c("b", [_vm._v("作業手順")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }