var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box file_box" }, [
    _c("div", { staticClass: "inner" }, [
      !_vm.isVideo && _vm.videoSrc
        ? _c("img", { attrs: { src: _vm.videoSrc, alt: "" } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isVideo && _vm.videoSrc
        ? _c("video", {
            attrs: {
              src: _vm.videoSrc,
              controls: "",
              "webkit-playsinline": "",
              playsinline: "",
              mute: "",
              autoplay: ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.videoSrc
        ? _c("div", { staticClass: "upload_icon" }, [
            _c("i", { staticClass: "fas fa-photo-video" })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "file_label",
          attrs: { for: "file_upload" + _vm.index }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          _c("input", {
            attrs: {
              type: "file",
              id: "file_upload" + _vm.index,
              accept: ".mp4, .png, .jpg, .jpeg, .gif"
            },
            on: {
              change: function($event) {
                return _vm.onChangeVideo($event)
              }
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }