<template>
    <div class="lesson">
        <a :href="link">
            <div class="user">
                <div class="icon">
                    <p :style="iconImage(lesson.user.image)"></p>
                </div>
                <p class="name">{{ lesson.user.display_name }}</p>
            </div>
            <div class="contents">
                <div class="flex_nw">
                  <lesson-video-thumbnail-component :video="lesson.video" />
                    <div>
                        <div class="categories flex">
                            <div class="cat">{{ partName }}</div>
                            <level-component :level="lesson.level"></level-component>
                        </div>
                        <h3>{{ lesson.title }}</h3>
                        <p class="description">{{ lesson.description }}</p>
                    </div>
                </div>
                <p v-if="showPrice" class="price">レッスン料<span>{{ lesson.price.toLocaleString() }}</span> 円（税込）</p>
            </div>
            <div class="footer">
                <p v-if="$store.state.userInfo.grant == 1 && progress > 0" class="status process">{{ progress }}％習得</p>
                <template v-if="$store.state.userInfo.grant != 1">
                  <p v-if="lesson.purchases_count > 0" class="students">{{ lesson.purchases_count }}</p>
                  <p v-if="lesson.display_flag == 0 && lesson.draft == 0" class="status private">非公開</p>
                  <p v-if="lesson.display_flag == 0 && lesson.draft == 1" class="status private">下書き</p>                  
                </template>
            </div>
        </a>
    </div>    
</template>

<script>
import LevelComponent from '../../miso/commons/LevelComponent.vue';
import LessonVideoThumbnailComponent from '../../miso/user/LessonVideoThumbnailComponent.vue';

export default {

  components: {
      LevelComponent,
      LessonVideoThumbnailComponent,
  },
  props: {
        lesson: Object,
        showPrice: Boolean,
  },
  data: function () {
    return {
    };
  },
  mounted: function () {
  },
  computed: {
    progress: function () {
      if(this.lesson.steps && this.lesson.steps.length > 0){
        var progress = this.lesson.steps.filter(function(step){
            
            return step.progress && step.progress.length > 0
        })
        console.log(progress.length)
        return  Math.round(progress.length / this.lesson.steps.length * 100)
      }
      return 0
    },
    link: function(){
        return this.$libs.url(['lesson',this.lesson.id])
    },
    partName: function(){
        return this.$config.name("parts",this.lesson.part)
    },
    genreName: function(){
        return this.$config.name("genres",this.lesson.genre)
    }
  },
  methods: {
    iconImage: function (path) {
      if (path) {
        return "background: url(" + path + ") center center / cover";
      } else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },
    thumbnailImage: function () {
      if(this.lesson.video && this.lesson.video.video_path){
        return "background: url(" + this.lesson.video.video_path + ") center center / cover";
      } else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
