var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "l-tabbar c-tabbar" },
    [
      _vm._t("tabaction"),
      _vm._v(" "),
      _c("div", { staticClass: "c-container" }, [
        _c("div", { staticClass: "c-tabbar__inner" }, [
          _c(
            "div",
            {
              staticClass: "c-tabbar__item",
              class: { "is-current": _vm.isCurrent("/") }
            },
            [
              _c("a", { attrs: { href: _vm.$libs.url("") } }, [
                _c("i", { staticClass: "fas fa-home" }),
                _vm._v(" "),
                _c("p", [_vm._v("ホーム")])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-tabbar__item",
              class: { "is-current": _vm.isCurrent("/search") }
            },
            [
              _c("a", { attrs: { href: _vm.$libs.url("search") } }, [
                _c("i", { staticClass: "fas fa-search" }),
                _vm._v(" "),
                _c("p", [_vm._v("探す")])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-tabbar__item",
              class: { "is-current": _vm.isCurrent("/mylesson") }
            },
            [
              _c("a", { attrs: { href: _vm.$libs.url("mylesson") } }, [
                _c("i", { staticClass: "far fa-check-circle" }),
                _vm._v(" "),
                _c("p", [_vm._v("マイレッスン")])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-tabbar__item",
              class: { "is-current": _vm.isCurrent("/notice") }
            },
            [
              _c("a", { attrs: { href: _vm.$libs.url("notice") } }, [
                _c("i", { staticClass: "fas fa-bell" }),
                _vm._v(" "),
                _c("p", [_vm._v("お知らせ")])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "c-tabbar__item",
              class: { "is-current": _vm.isCurrent("/mypage") }
            },
            [
              _c("a", { attrs: { href: _vm.$libs.url("mypage") } }, [
                _c("img", { attrs: { src: "/images/footer_icon_user.png" } }),
                _vm._v(" "),
                _c("p", [_vm._v("マイページ")])
              ])
            ]
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }