var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "ステップの登録",
      "main-class": "lesson_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "post create_steps bg_yellow" }, [
              _c(
                "div",
                { staticClass: "container pt20" },
                [
                  _vm._l(_vm.steps, function(step, index) {
                    return [
                      _c(
                        "div",
                        { key: index, staticClass: "box step" },
                        [
                          _c("h2", {}, [
                            _vm._v("Step " + _vm._s(step.work_procedure_index))
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "box" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: step.title,
                                  expression: "step.title"
                                }
                              ],
                              attrs: {
                                type: "text",
                                placeholder: "ステップを入力"
                              },
                              domProps: { value: step.title },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(step, "title", $event.target.value)
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("lesson-video-input-component", {
                            attrs: { video: step.video, index: index },
                            on: {
                              "change-video": function($event) {
                                return _vm.onChangeVideo($event, index)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "box" }, [
                            _c("h3", [_vm._v("説明（任意）")]),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: step.description,
                                  expression: "step.description"
                                }
                              ],
                              attrs: { placeholder: "タップして入力" },
                              domProps: { value: step.description },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    step,
                                    "description",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "box" }, [
                            _c("h3", [_vm._v("ポイント（任意）")]),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: step.notice,
                                  expression: "step.notice"
                                }
                              ],
                              staticClass: "notice",
                              attrs: { placeholder: "タップして入力" },
                              domProps: { value: step.notice },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(step, "notice", $event.target.value)
                                }
                              }
                            })
                          ])
                        ],
                        1
                      )
                    ]
                  }),
                  _vm._v(" "),
                  _vm.isEnabledAddStep
                    ? _c(
                        "div",
                        {
                          staticClass: "add_step",
                          on: { click: _vm.onAddStep }
                        },
                        [
                          _c("p", [
                            _c("i", { staticClass: "fas fa-plus-circle" }),
                            _vm._v("ステップを追加する")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _c(
                      "button",
                      { staticClass: "button md", on: { click: _vm.post } },
                      [_vm._v("保存する")]
                    )
                  ])
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.showModalSaved
              ? _c("modal-component", {
                  attrs: { title: "保存しました", "view-plain": true },
                  on: { ok: _vm.saved }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }