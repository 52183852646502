<template>
    <div class="columns is-gapless" style="">
        <!-- left menu -->
        <div
            class="column column-checklist"
            :style="{ 'max-height': contentHeight + 'px' }"
            :class="{ 'is-one-quarter': showDetail }"
        >
            <div id="sidebar">
                <a v-if="!showDetail" id="cont-close" @click="toggle">
                    <img src="/images/ico-right-arrow.png" alt="" />
                </a>
                <p class="side-title"><b>作業手順</b></p>

                <template v-for="(p, idx) in procedures">
                    <work-procedure
                        :key="p.work_procedure_id"
                        :item="p"
                        :is-last="isLastProcedure(idx)"
                        action-name="updateCurrentProcedureForPreview"
                    />
                </template>
                <p class="tc pt10">
                    <a
                        class="button basic is-login"
                        style="color:white;"
                        @click="back"
                        >編集画面へ戻る</a
                    >
                </p>
            </div>
        </div>

        <!-- right contents -->
        <check-list-detail
            v-show="showDetail"
            :content-height="contentHeight"
            :index="target.index"
            :name="target.name"
            :notice="target.notice"
            :description="target.description"
            :base-path="target.basepath"
            :video-path="target.videopath"
            :hide-content="hideContent"
            @toggle="toggle"
        />
    </div>
</template>
<script>
import CheckListDetail from './CheckListDetail.vue';
import WorkProcedure from '../../common/components/WorkProcedure.vue';

export default {
    components: {
        CheckListDetail,
        WorkProcedure,
    },
    props: {
        procedures: {
            type: Array,
            default: () => [],
        },
        target: {
            type: Object,
            default: () => ({}),
        },
        contentHeight: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            showDetail: true,
        };
    },
    computed: {
        hideContent() {
            // 作業内項目をもつ手順
            const hasChilds = this.procedures.filter(_ => _.child.length > 0);

            // 最初の作業内項目の場合は非表示対象とする
            const firstChild = hasChilds.find(
                _ =>
                    _.child.findIndex(__ => __.uuid === this.target.uuid) === 0,
            );
            return firstChild !== undefined;
        },
    },
    methods: {
        isLastProcedure(index) {
            return index === this.procedures.length - 1;
        },
        back() {
            const path = this.$store.state.previewOriginUrl;
            this.$router.push({ path });
        },
        toggle() {
            this.showDetail = !this.showDetail;
        },
    },
};
</script>

<style lang="scss" scoped>
.column-checklist {
    overflow-y: auto;
}
</style>
