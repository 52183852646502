<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :title="title" main-class="user_page user_teacher">
        <template v-slot:body v-if="user">
            <section class="user_header bg_keycolor">
                <div class="container pt30 pb30">
                    <div class="icon">
                        <p :style="iconImage(user.image)"></p>
                    </div>
                    <div class="name">
                        <p v-if="user.grant_id > 1">講師</p>
                        <h1>{{ user.display_name }}</h1>
                    </div>
                </div>
            </section>
            <section class="profile bg_white" v-if="user.meta">
                <div class="container pt20 pb20">
                    <p>{{ user.meta.profile }}
                    </p>
                    <template v-if="user.grant_id > 1">
                    <div class="categories flex">
                        <template v-for="(item, index) in partNames">
                            <div class="cat" :key="index">{{ item }}</div>
                        </template>
                    </div>
                    <p class="career">講師歴: {{ careerNames }}</p>
                    </template>
                </div>
            </section>
            <section class="popular bg_yellow" >
                <template v-if="lessons && lessons.length > 0">
                <div class="container pt20">
                    <template v-if="user.grant_id == 1">
                         <h2>受講したレッスン</h2>
                    </template>
                    <template v-else>
                    <div>
                        <p class="count"><span>{{ lessons.length }}</span>レッスン</p>
                    </div>
                    </template>
                    <div class="lessons">
                        <template v-for="(item, index) in lessons">
                            <lesson-cassette-component :key="index" :lesson="item" :showPrice="user.grant_id > 1">
                            </lesson-cassette-component>
                        </template>
                    </div>
                </div>
                </template>
                <template v-else>
                    <div class="container">
                        <p>レッスンはまだありません。</p>
                    </div>
                </template>
            </section>

        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';
import LessonCassetteComponent from '../../miso/user/LessonCassetteComponent.vue';

export default {
    components: {
    CommonLayoutComponent,
    ModalComponent,
    LessonCassetteComponent,
    },
    props: {
            userId: {
                type: Number,
            },
            title:{
                type: String,
            },
        },
  data: function () {
    return {
        user:null,
        lessons:null,
    };
  },
  created: function () {},
    computed: {
    },
  mounted: function () {

      // ユーザー情報取得
      var handler = Vue.ELearning.UserInfo.getUser(this.userId);

      handler.then((response) => {
        if (response.data.status_number == 200) {
            this.user = response.data.content;

            if(this.user.grant_id == 1){
                if(this.user.purchases.length > 0)
                this.lessons = this.user.purchases.map(function(item){
                    return item.task
                })
            }else{
                this.lessons = this.user.lessons
            }

        } else {
            throw new Error("ユーザー情報の取得に失敗しました", response);
        }

      }).catch((response) => {
          this.$dialog.message( "ユーザ情報の取得に失敗しました。" )
        console.error(response);
      });
  },
  computed: {
      partNames:function(){
          if(this.user && this.user.meta && this.user.meta.part){
              return this.$config.names("parts", this.user.meta.part.split(","))
          }
          return [];
      },
      careerNames:function(){
          if(this.user && this.user.meta && this.user.meta.career){
              return this.$config.names("career", [this.user.meta.career]).join(",")
          }
          return "-";
      }      
  },
  methods: {
     iconImage: function(path) {
          if(path){
              return 'background: url(' + path + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      },
    onNaviBack: function(){
        history.back()
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
