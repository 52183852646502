<template>
<div class="modal">
    <div class="container">
        <div class="box_wh">
            <h3 v-if="title">{{ title }}</h3>
            <slot name="body"></slot>
            <div class="flex_nw" v-if="viewSelectable">
                <div class="item">
                    <button class="round ok keycolor" @click="$emit('yes')">{{ yes }}</button>
                </div>
                <div class="item">
                    <button class="round cancel white" @click="$emit('no')">{{ no }}</button>
                </div>
            </div>
            <div class="flex_nw" v-if="viewPlain">
                <div class="item">
                    <button class="round ok keycolor" @click="$emit('ok')">{{ ok }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    components: {
    },
    
    props: {
        title: String,
        yes:{
            type:String,
            default: "はい"
        },
        no:{
            type:String,
            default: "キャンセル"
        },
        ok:{
            type:String,
            default: "OK"
        },
        viewSelectable:{
            type:Boolean,
            default: false,
        },
        viewPlain:{
            type:Boolean,
            default: false,
        },        
    },

    data: function() {
        return {
        };
    },

    mounted: function() {
    },
    methods: {
    },

    computed: {
    },
};
</script>

<style scoped lang="scss">
</style>
