<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" :require-user-info="true"  title="マイレッスン" main-class="mylesson_page">
        <template v-slot:body>
            <section class="popular bg_yellow">
                <div class="container pt20 pb20">
                    <div class="flex_nw tab_2">
                        <button :class="{current:!showCompleteLessons}" @click="showCompleteLessons = false"><span v-if="$store.state.userInfo.grant == 1">受講中レッスン</span><span v-if="$store.state.userInfo.grant >= 2">公開中のレッスン</span></button>
                        <button :class="{current:showCompleteLessons}" @click="showCompleteLessons = true"><span v-if="$store.state.userInfo.grant == 1">修了したレッスン</span><span v-if="$store.state.userInfo.grant >= 2">非公開のレッスン</span></button>
                    </div>
                    <div class="lessons" v-if="!showCompleteLessons">
                        <template v-for="(item, index) in publishedLessons">
                            <lesson-cassette-component :key="index" :lesson="item" :showPrice="false">
                            </lesson-cassette-component>
                        </template>
                    </div>
                    <div class="lessons" v-if="showCompleteLessons">
                        <template v-for="(item, index) in privateLessons">
                            <lesson-cassette-component :key="index" :lesson="item">
                            </lesson-cassette-component>
                        </template>
                    </div>

                </div>
            </section>
        </template>
        <template v-slot:action v-if="$store.state.userInfo.grant >= 2">
                <div class="create">
                    <div class="flex_nw">
                        <button class="round" @click="$libs.pushUrl(['lesson','create'])"><i class="fas fa-plus"></i>レッスンをつくる</button>
                    </div>
                </div>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';
    import LessonCassetteComponent from '../../miso/user/LessonCassetteComponent.vue';
    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonCassetteComponent,
        },
        data: function () {
            return {
                lessons:[],
                showCompleteLessons: false,
            }
        },
        computed: {
            publishedLessons:function(){

                if(this.$store.state.userInfo.grant == 1){
                    return this.lessons.filter(function(lesson){
                        return lesson.expired == 0
                    })
                }else{
                    return this.lessons.filter(function(lesson){
                        return lesson.display_flag == 1
                    })
                }
            },
            privateLessons:function(){

                if(this.$store.state.userInfo.grant == 1){
                    return this.lessons.filter(function(lesson){
                        return lesson.expired == 1
                    })
                }else{
                    return this.lessons.filter(function(lesson){
                        return lesson.display_flag == 0
                    })
                }
            }            
        },
        mounted:function(){
            console.log("userInfo.grant",this.$store.state.userInfo.grant);
           this.getData()
        },
        methods:{
            getData: function(){
                // タスク情報取得
                var handler
                if(this.$store.state.userInfo.grant == 1){
                    handler = Vue.ELearning.Task.getPurchased();
                }else{
                    handler = Vue.ELearning.Task.getAllForAdmin();
                }
                handler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            this.lessons = res.data.content;
                        }
                    }.bind(this),
                )
                .catch(function(res) {
　　                  throw new Error("タスクの取得に失敗しました", response);

                }.bind(this));
            },
            search: function(){
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
