var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": true,
      title: "ご利用ガイド",
      "class-name": "guide_page"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "u-bg-yellow" }, [
              _c("div", { staticClass: "list c-list u-pt20" }, [
                _c("h3", { staticClass: "c-list__title" }, [_vm._v("まなぶ")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("レッスンをさがす"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "ホーム画面の「レッスンを探す」をタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "カテゴリやジャンル、講師情報などで検索してお好みのレッスンを探してください"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "検索結果をタップすると、レッスンの詳細を確認できます"
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("講師をさがす"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v("ホーム画面の「講師を探す」をタップします")
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "年齢層や楽器、得意ジャンルなどで検索してお好みの講師を探してください"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "検索結果をタップすると、講師の情報と登録されているレッスンを確認できます"
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("レッスンを購入する"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [_vm._v("購入するレッスンを選択します")]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "レッスン料を確認し、「購入する」をタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "購入したレッスンの試聴期限は購入日から180日間です"
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("レッスンを受ける"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "マイレッスン画面で購入したレッスンをタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("レッスン情報の説明や概要動画をみます")
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("Step１をタップして、練習します"),
                            _c("br"),
                            _vm._v(
                              " 講師にききたいことがあったら「トップに戻る」をタップして「Q&A」タブから質問します（レッスンによって有償の場合があります）\n                                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "できるようになったら「次のステップへ」をタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "すべてのステップをクリアしたら、講師に採点をリクエストしましょう"
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("採点してもらう"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "マイレッスン画面で修了したレッスンをタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "「採点」タブを選択し、「採点してもらう」をタップします（レッスンによって有償の場合があります）"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "レッスン内容を実演した動画を選択して、講師に伝えたいコメントを入力します"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "採点結果を公開するか選択して投稿します 採点結果を公開すると、ユーザー名、実演動画、点数が公開され、ランキング対象になります"
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("講師に質問する"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "マイレッスン画面で購入したレッスンをタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "「Q&A」タブを選択し、「質問する」をタップします（レッスンによって有償の場合があります） "
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "講師への質問を入力します 動画を添付することも可能です"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "質問内容を公開するか選択して投稿します 質問内容を公開すると、ユーザー名、実演動画、質問が公開されます"
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("プロフィール編集・アカウント設定"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "マイページ画面の「個人情報設定」をタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [_vm._v("必要な情報を編集します")])
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list c-list" }, [
                _c("h3", { staticClass: "c-list__title" }, [
                  _vm._v("おしえる")
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("レッスンを作る"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "マイレッスン画面の「レッスンをつくる」をタップします"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "レッスンをイメージできる動画や画像、タイトル、レッスン内容の説明、目指すゴールを入力します"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "カテゴリ（楽器の種類）やジャンル、難易度を選びます"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [_vm._v("レッスンの販売価格を設定します")]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "採点や質問の回答を有料にする場合は、1回あたりの価格を「採点・質問（円/回）」に入力します（期限内に回答しなかった場合は入金されません）"
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v(
                              "「ステップの登録」をタップして、レッスンをステップ毎にわけて動画や説明を登録します（1ステップをなるべく短い動画にして、クリアしやすくしましょう） レッスンの入力が終わったら、「公開する」をタップして販売を開始しましょう\n                                "
                            )
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("質問や採点リクエストを受け取ったら"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "画面右上の「やること」をタップします\n                                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [_vm._v("内容を確認し、返信します")])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("売上の管理"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v("マイページ画面で「XXXXXX」をタップします")
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("売上確認、振込申請、入金履歴、返金？")
                          ])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("講師をやめる"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v("マイページ画面で「XXXXXX」をタップします")
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _vm._v("売上確認、振込申請、入金履歴、返金？")
                          ]),
                          _vm._v(" "),
                          _c("li")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("プロフィール編集・アカウント設定"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "マイページ画面の「個人情報設定」をタップします\n                                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", [_vm._v("必要な情報を編集します")])
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("売上の管理"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("振込先"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("入金"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("返金"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("講師をやめる"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("ul", [
                          _c("li"),
                          _vm._v(" "),
                          _c("li"),
                          _vm._v(" "),
                          _c("li")
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }