var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column is-one-quarter" }, [
    _c("label", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.tag.selected,
            expression: "tag.selected"
          }
        ],
        staticClass: "checkbox01-input",
        attrs: { type: "checkbox" },
        domProps: {
          value: _vm.tag.tagId,
          checked: Array.isArray(_vm.tag.selected)
            ? _vm._i(_vm.tag.selected, _vm.tag.tagId) > -1
            : _vm.tag.selected
        },
        on: {
          change: function($event) {
            var $$a = _vm.tag.selected,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.tag.tagId,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.tag, "selected", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.tag,
                    "selected",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.tag, "selected", $$c)
            }
          }
        }
      }),
      _vm._v(" "),
      _c("span", {
        staticClass: "checkbox01-parts",
        domProps: { textContent: _vm._s(_vm.tag.tagName) }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }