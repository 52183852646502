var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multi-modal" },
    [
      _vm._l(_vm.currentParents, function(currentParent, idx) {
        return [
          _vm.getChildren(currentParent).length > 0
            ? _c(
                "div",
                {
                  key: idx,
                  staticClass: "modal-cat",
                  style: { color: _vm.parentColor(currentParent) }
                },
                [
                  _vm.useParentCheck
                    ? _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: currentParent.selected,
                              expression: "currentParent.selected"
                            }
                          ],
                          staticClass: "checkbox01-input",
                          attrs: { type: "checkbox", disabled: "" },
                          domProps: {
                            checked: Array.isArray(currentParent.selected)
                              ? _vm._i(currentParent.selected, null) > -1
                              : currentParent.selected
                          },
                          on: {
                            change: function($event) {
                              var $$a = currentParent.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      currentParent,
                                      "selected",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      currentParent,
                                      "selected",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(currentParent, "selected", $$c)
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "checkbox01-parts",
                          class: {
                            "has-selected": _vm.hasSelectedChilds(currentParent)
                          },
                          domProps: {
                            textContent: _vm._s(
                              _vm.getParentValue(currentParent)
                            )
                          },
                          on: {
                            click: function($event) {
                              return _vm.onCheckParentStateChange(currentParent)
                            }
                          }
                        })
                      ])
                    : _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.getParentValue(currentParent))
                        }
                      }),
                  _vm._v(" "),
                  !currentParent.isOpen
                    ? _c("img", {
                        staticClass: "icon-toggle",
                        attrs: { src: "/images/ico-plus-gray.png", alt: "" },
                        on: {
                          click: function($event) {
                            currentParent.isOpen = !currentParent.isOpen
                          }
                        }
                      })
                    : _c("img", {
                        staticClass: "icon-toggle",
                        attrs: { src: "/images/ico-minus-gray.png", alt: "" },
                        on: {
                          click: function($event) {
                            currentParent.isOpen = !currentParent.isOpen
                          }
                        }
                      })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getChildren(currentParent).length > 0
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: currentParent.isOpen,
                      expression: "currentParent.isOpen"
                    }
                  ],
                  key: _vm.getChildrenIndex(idx),
                  staticClass: "tasks"
                },
                [
                  _vm._l(_vm.getChildren(currentParent), function(child, cIdx) {
                    return [
                      _vm.isSelectionMode
                        ? [
                            _c(
                              "div",
                              {
                                key: cIdx,
                                staticClass: "task-body",
                                class: {
                                  "last-l": _vm.isBottomChildLeft(
                                    currentParent,
                                    cIdx
                                  ),
                                  "last-r": _vm.isBottomChildRight(
                                    currentParent,
                                    cIdx
                                  )
                                },
                                style: {
                                  color: _vm.childColor(child),
                                  "font-weight": _vm.childWeight(child)
                                }
                              },
                              [
                                _c("label", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: child.selected,
                                        expression: "child.selected"
                                      }
                                    ],
                                    staticClass: "checkbox01-input",
                                    attrs: { type: "checkbox", disabled: "" },
                                    domProps: {
                                      checked: Array.isArray(child.selected)
                                        ? _vm._i(child.selected, null) > -1
                                        : child.selected
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a = child.selected,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                child,
                                                "selected",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                child,
                                                "selected",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(child, "selected", $$c)
                                        }
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "checkbox01-parts",
                                      on: {
                                        click: function($event) {
                                          return _vm.onCheckStateChange(
                                            child,
                                            currentParent
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.getChildValue(child)))]
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isOddLast(currentParent, cIdx)
                              ? _c(
                                  "div",
                                  {
                                    key: _vm.getLastIndex(cIdx),
                                    staticClass: "task-body last-r"
                                  },
                                  [
                                    _vm._v(
                                      "\n                         \n                    "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        : [
                            _c(
                              "div",
                              {
                                key: cIdx,
                                staticClass: "task-body",
                                class: {
                                  "last-l": _vm.isBottomChildLeft(
                                    currentParent,
                                    cIdx
                                  ),
                                  "last-r": _vm.isBottomChildRight(
                                    currentParent,
                                    cIdx
                                  )
                                },
                                style: {
                                  color: _vm.childColor(child),
                                  "font-weight": _vm.childWeight(child)
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.getChildValue(child)) +
                                    "\n                    "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isOddLast(currentParent, cIdx)
                              ? _c(
                                  "div",
                                  {
                                    key: _vm.getLastIndex(cIdx),
                                    staticClass: "task-body last-r"
                                  },
                                  [
                                    _vm._v(
                                      "\n                         \n                    "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }