/**
 * Vuex
 *
 */

import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

/**
 * Vuex Store
 */
const moduleGlobal = {
    state: {
        appInfo: {},

        winHeight: 0,

        currentTitle: '',

        documentRoot: process.env.MIX_VUEROOTER_BASE,

        // 選択中タスクに紐づくタグ情報
        allTags: [],

        // 選択中作業手順
        currentProcedure: [{
            index: 0,
            name: '',
            status: 'incomplete',

            description: '',
            notice: '',

            basepath: '',
            videopath: '',

            child: [],
        }, ],

        // プレビュー用
        currentProcedureForPreview: [{
            index: 0,
            name: '',
            status: 'incomplete',

            description: '',
            notice: '',

            basepath: '',
            videopath: '',

            child: [],
        }, ],

        /** ログ用　作業完了した作業手順 */
        logWorkProcedures: [],

        /** 現在オンラインかどうか */
        isOnline: true,

        /** オフライン状態になってから最後にユーザーが操作を行った日時 */
        lastOfflineActionDateTime: null,

        /** 現在ログインしているかどうか */
        isLoggedIn: false,

        isLoggedInDestroyed: false,

        /** 現在ログイン中のユーザーの情報 */
        userInfo: {},

        /** オフライン中にログインしている事にするユーザーの ID ( 最後にログインしたユーザーの ID をログアウト後も残す ) */
        offlineUserId: null,

        /** 「現在処理中です、しばらくお待ちください。」オーバーレイを表示するかどうか */
        showOverlay: false,

        /** 現在のタスク */
        currentTask: {},

        currentCategoryName: '',
        currentTaskName: '',

        /** 現在のカテゴリの ID */
        current_category_id: null,

        /** 作業項目の一覧 */
        procedures: [],

        preview: [],

        previewCategoryName: '',
        previewTaskName: '',
        previewOrigin: [],
        previewOriginUrl: '',

        fileSizeSum: 0,

        /** StorageManager ディスク容量の見積結果 */
        storageEstimate: null,

        showedDownloadedMessage: {},
    },
    mutations: {

        appInfo(state, info) {
            state.appInfo = info;
        },
        /**
         * オンライン状態フラグを更新する
         *
         * @param {object} state State
         * @param {boolean} online オンライン状態フラグ
         */
        updateIsOnline(state, online) {
            state.isOnline = online;
        },

        /**
         * オフライン状態になってから最後にユーザーが操作を行った日時を更新する
         * 
         * @param {object} state State
         * @param {string|null} datetime オフライン状態になってから最後にユーザーが操作を行った日時
         */
        updateLastOfflineActionDateTime(state, datetime) {
            state.lastOfflineActionDateTime = datetime;
        },

        /**
         * タイトル変更ミューテーション
         * @param state
         * @param newTitle
         */
        updateTitle(state, newTitle) {
            state.currentTitle = `${newTitle}｜みそシル教室`;
        },

        overlay: function(state, newVal) {
            state.showOverlay = newVal;
        },

        /**
         * 現在の作業手順を更新する
         *
         * @param state
         * @param newProc
         */
        updateCurrentProcedure(state, newProc) {
            state.currentProcedure = newProc;
            this.commit('selectProc', newProc);

            //console.log("updateCurrentProcedure()", newProc);
        },

        /**
         * タスク情報 ( 作業項目情報を含む ) を store に保存する
         * 
         * この関数は、タスク詳細画面でネットワークまたは DB からタスク情報を取得した後に呼ばれる。
         * 
         * @param state
         * @param {Object} task タスク
         */
        storeTask(state, task) {

            //console.log("storeTask():", task);

            state.currentTask = task;

            state.currentCategoryName = task.category_name;
            state.currentTaskName = task.task_name;

            state.current_category_id = task.category_id;

            // console.log( "storeTask :", task.procedure );

            var intermediate = formatProcedures(task.procedure);

            state.procedures = [];

            for (var key in intermediate) {
                var item = intermediate[key];
                state.procedures.push(item);
            }

            if (intermediate.length > 0) {
                var selected = false;
                for (var key in intermediate) {
                    if (selected) {
                        break;
                    }
                    var item = intermediate[key];

                    //console.log(item.child.length);
                    if (item.child.length > 0) {
                        for (var childKey in item.child) {
                            var child = item.child[childKey];

                            if (child.status == 'incomplete') {
                                //this.commit("selectProc", child);
                                //console.log(item.name);
                                this.commit('updateCurrentProcedure', child);

                                selected = true;

                                break;
                            }
                        }
                    } else {
                        if (item.status == 'incomplete') {
                            //this.commit("selectProc", item);
                            //console.log(item.name);
                            this.commit('updateCurrentProcedure', item);
                            break;
                        }
                    }
                }
            }
        },

        /**
         * 作業手順選択変更
         * @param state
         * @param newProc
         */
        selectProc(state, newProc) {
            var targetUUIR = newProc.uuid;
            for (var key in state.procedures) {
                var aProc = state.procedures[key];
                aProc.selected = false;

                if (aProc.uuid == targetUUIR) {
                    aProc.selected = true;
                }

                for (var childKey in aProc.child) {
                    var child = aProc.child[childKey];
                    child.selected = false;
                    if (child.uuid == targetUUIR) {
                        child.selected = true;

                        aProc.selected = true;
                    }
                }
            }
        },

        /**
         *
         * @param state
         * @param newProc
         */
        nextProc(state, newProc) {
            var targetUUIR = newProc.uuid;

            var isTarget = false;
            var targetProc = null;

            var nextFunc = function() {};

            for (var key in state.procedures) {
                var aProc = state.procedures[key];

                if (isTarget && aProc.child.length == 0) {
                    targetProc = aProc;
                    break;
                }
                if (aProc.uuid == targetUUIR) {
                    isTarget = true;
                    continue;
                }

                var id = 1;
                for (var childKey in aProc.child) {
                    var child = aProc.child[childKey];

                    if (isTarget) {
                        targetProc = child;
                        break;
                    }

                    if (child.uuid == targetUUIR) {
                        isTarget = true;

                        if (id == aProc.child.length) {
                            break;
                        } else {
                            continue;
                        }
                    }
                    id++;
                }

                if (targetProc) {
                    break;
                }
            }

            if (targetProc) {
                //console.log(">>>>>>>>>> targetProc:", targetProc);
                this.commit('updateCurrentProcedure', targetProc);
            } else {
                //alert("タスクを完了しました。");
            }
        },

        /**
         * ログイン処理
         * @param state
         * @param user_info ログインしたユーザーの情報
         */
        login(state, user_info) {
            // alert( 'logged in !!!' );

            state.isLoggedIn = true;
            state.userInfo = user_info;
            state.offlineUserId = user_info.userId;

            // 2019-10-05  ando : セッションとは別に、API 用トークンをクッキーに保存する ( トークンは正しく機能していないが既存の処理なのでとりあえず残す )
            Cookies.set("el-auth-token", user_info.authToken, {
                'expires': 3,
                'path': '/',
                /*'HostOnly':true,
                'HttpOnly':true,
                'Domain':'lancer-portfolio.biz',
                'Secure':false*/
            });

            // console.log(document.cookie.split(";"));
        },

        /**
         * ログアウト処理
         */
        logout(state) {
            Cookies.remove(process.env.SESSION_COOKIE_NAME);
            Cookies.remove("el-auth-token");

            state.isLoggedIn = false;
            state.userInfo = {};
        },

        /**
         * ログイン破棄されたことを記録する（メッセージ出力用）
         */
        loginDestroyed(state, destroyed) {
            state.isLoggedInDestroyed = destroyed;
        },

        /**
         * ログアウト処理
         */
        updateUserInfo(state, userInfo) {
            Object.keys(userInfo).map(key => state.userInfo[key] = userInfo[key]);
        },

        // == プレビュー画面用 =========================================================================================
        /**
         * プレビューデータをクリアする
         */
        clearPreviewData(state) {
            state.preview = [];
            state.previewCategoryName = '';
            state.previewTaskName = '';
            state.previewOrigin = [];
            state.previewOriginUrl = '';
        },

        /**
         * プレビューから戻る際のurlを設定する
         */
        savePreviewOriginUrl(state, url) {
            //state.preview = [];
            state.previewOriginUrl = url;
        },

        /**
         * プレビューデータを設定する
         */
        savePreviewOrigin(state, origin) {
            //state.preview = [];
            state.previewOrigin = origin;
        },

        /**
         * プレビュー対象のカテゴリ名を設定する
         */
        savePreviewCategoryName(state, categoryName) {
            state.previewCategoryName = categoryName;
        },

        /**
         * プレビュー対象のタスク名を設定する
         */
        savePreviewTaskName(state, taskName) {
            state.previewTaskName = taskName;
        },

        /**
         * 現在の作業手順を更新する
         * @param state
         * @param newProc
         */
        updateCurrentProcedureForPreview(state, newProc) {
            state.currentProcedureForPreview = newProc;
            this.commit('selectProcForPreview', newProc);
        },

        /**
         * 作業手順情報設定
         *
         * @todo storeTask() とまとめる
         *
         * @param state
         * @param checklist
         */
        storeChecklistForPreview(state, checklist) {
            state.currentCategoryName = 'プレビュー用';
            state.currentTaskName = 'プレビュー用';

            var intermediate = formatProcedures(checklist);
            state.preview = [];

            for (var key in intermediate) {
                var item = intermediate[key];
                state.preview.push(item);
            }

            if (intermediate.length > 0) {
                var selected = false;
                for (var key in intermediate) {
                    if (selected) {
                        break;
                    }
                    var item = intermediate[key];

                    //console.log(item.child.length);
                    if (item.child.length > 0) {
                        for (var childKey in item.child) {
                            var child = item.child[childKey];

                            if (child.status == 'incomplete') {
                                this.commit('updateCurrentProcedureForPreview', child);

                                selected = true;

                                break;
                            }
                        }
                    } else {
                        if (item.status == 'incomplete') {
                            this.commit('updateCurrentProcedureForPreview', item);
                            break;
                        }
                    }
                }
            }
        },

        /**
         * 作業手順選択変更
         * @param state
         * @param newProc
         */
        selectProcForPreview(state, newProc) {
            var targetUUIR = newProc.uuid;

            //console.log("sel-proc", newProc);

            for (var key in state.preview) {
                var aProc = state.preview[key];
                aProc.selected = false;

                if (aProc.uuid == targetUUIR) {
                    aProc.selected = true;
                }

                for (var childKey in aProc.child) {
                    var child = aProc.child[childKey];

                    child.selected = false;
                    if (child.uuid == targetUUIR) {
                        child.selected = true;

                        aProc.selected = true;
                    }
                }
            }
        },

        /**
         *
         * @param state
         * @param newProc
         */
        nextProcForPreview(state, newProc) {
            var targetUUIR = newProc.uuid;

            var isTarget = false;
            var targetProc = null;

            var nextFunc = function() {};

            for (var key in state.preview) {
                var aProc = state.preview[key];

                if (isTarget && aProc.child.length == 0) {
                    targetProc = aProc;
                    break;
                }
                if (aProc.uuid == targetUUIR) {
                    isTarget = true;
                    continue;
                }

                var id = 1;
                for (var childKey in aProc.child) {
                    var child = aProc.child[childKey];

                    if (isTarget) {
                        targetProc = child;
                        break;
                    }

                    if (child.uuid == targetUUIR) {
                        isTarget = true;

                        if (id == aProc.child.length) {
                            break;
                        } else {
                            continue;
                        }
                    }
                    id++;
                }

                if (targetProc) {
                    break;
                }
            }

            if (targetProc) {
                //console.log(targetProc);
                this.commit('updateCurrentProcedureForPreview', targetProc);
            } else {
                //alert("タスクを完了しました。");
            }
        },

        // ログ用　作業完了/スキップ時 作業手順のストア
        storeWorkProcedureForLog(state, proc) {
            //store済ははじく
            const exists = state.logWorkProcedures.filter(_proc => _proc.id == proc.id);
            if (exists.length == 0) {
                state.logWorkProcedures.push(proc);
            }
        },

        clearLogWorkProcedures(state) {
            state.logWorkProcedures = [];
        },

        getPreviewSize() {
            return state.preview.length;
        },

        // レイアウト関連
        windowResize(state) {
            //console.log("vuex: window resize");

            var windowHeight = jQuery(window).height();

            state.winHeight = windowHeight;

            //console.log("win-height:", state.winHeight);
        },

        fileSizeSum(state, newFileSizeSum) {
            state.fileSizeSum = newFileSizeSum;
        },

        updateStorageEstimate(state, estimate) {
            state.storageEstimate = estimate;
        },

        showedDownloadedMessage(state, message) {
            console.log(">>> message:", message);
            state.showedDownloadedMessage[message.type] = message.date;
        },
    },

    actions: {
        authenticate(state, account) {
            var auth = Vue.ELearning.Authenticatior.authenticate(
                account.userId,
                account.password,
                // 2019-10-28 kato PWA作業スケジュール No.27（実装そのものを追加）
                account.remember,
            );
            //console.log(auth);
            return auth;
        },

        // 2019-10-29 kato PWA作業スケジュール No.27（実装そのものを追加）
        autoauthenticate(state, remember) {
            var auth = Vue.ELearning.Authenticatior.autoauthenticate(remember);
            return auth;
        },

        verification(state, account) {
            var auth = Vue.ELearning.Authenticatior.verification(
                account.userId,
                account.password,
                account.remember,
                account.code,
            );
            return auth;
        },

        showOverlay({ commit }) {
            commit('overlay', true)
        },

        hideOverlay({ commit }) {
            commit('overlay', false)
        },

        /**
         * ローカルストレージの見積を更新する
         */
        async updateStorageEstimate({ commit }) {
            if (!window.navigator.storage) {
                console.warn("no window.navigator.storage");
                return;
            }

            const estimate = await window.navigator.storage.estimate();

            console.log("estimate :", estimate)

            commit('updateStorageEstimate', estimate);
        },

        /**
         * タスクの進捗を更新する
         * 
         * サーバーにタスクの進捗情報を送信すると同時に、
         * もしタスクがローカルストレージにも保存されている場合は、ローカルストレージのタスクの進捗情報も更新する
         * 
         * @param state
         * @param {boolean} complete タスク全体を完了するか
         * @param callback 保存後の処理
         */
        async saveProgress({ state }, { complete = false, callback }) {
            const getProgressStatus = status => {
                if (status === 'incomplete') {
                    return 0;
                }
                if (status === 'partial') {
                    return 2;
                }
                if (status === 'completed') {
                    return 3;
                }
                return 99;
            };

            const target = [];

            for (const parent of state.procedures) {
                console.log("parent :", parent);

                const parentStatus = getProgressStatus(parent.status);
                target.push({ work_procedure_id: parent.id, status: parentStatus });

                for (const child of parent.child) {
                    const childSatus = getProgressStatus(child.status);
                    target.push({ work_procedure_id: child.id, status: childSatus });
                }
            }

            console.log("target :", target);


            // 進捗更新処理
            const handler = Vue.ELearning.Progress.store(target, complete);
            handler
                .then(res => {
                    callback({ status: 'OK', response: res });
                })
                .catch(res => {
                    callback({ status: 'NG', response: res });
                });

            // タスクが完了したことをログに記録する
            if (complete) {
                // console.log( "task complete" );
                Vue.ELearning.Logs.logTaskComplete(state.currentTask.id).then();
            }

            // ダウンロード済みタスクの進捗を更新する
            const stored_task = await Vue.Download.getStoredTask(state.currentTask.id);

            if (stored_task) {
                // @todo PHP 側と処理を揃える
                if (complete) {
                    // タスクを完了した場合は、全ての作業項目の進捗をクリアする
                    stored_task.procedure = _.map(stored_task.procedure, p => { p.status = null; return p; });
                } else {
                    // タスクが未完了の場合は、作業項目毎の進捗を保存する
                    for (const work_procedure of target) {
                        const stored_work_procedure = _.find(stored_task.procedure, { id: work_procedure.work_procedure_id });

                        // console.log( "stored_work_procedure :", stored_work_procedure );

                        if (stored_work_procedure) {
                            stored_work_procedure.status = work_procedure.status;
                        }
                    }
                }

                stored_task.progress = _.sumBy(stored_task.procedure, p => (p.status == 2 || p.status == 3) ? 1 : 0);

                if (!Vue.Download.isOnline()) {
                    stored_task.is_offline_progressed = true;
                }

                console.log("stored_task :", stored_task);

                await Vue.DB.putTask(stored_task);
            }
        },
    },
    getters: {
        /*
        localDiskTotal: state => {
            return state.storageEstimate ? ( state.storageEstimate.quota / 1024 / 1024 / 1024 ).toFixed( 2 ) : "-";
        },

        localDiskUsage: state => {
            return state.storageEstimate ? ( state.storageEstimate.usage / 1024 / 1024 / 1024 ).toFixed( 2 ) : "-";
        }
        */
    },

    plugins: [
        /** F5 キーなどでページがリロードされた場合でも、ログイン情報が破棄されないように、 state を localStorage に保存する */
        createPersistedState({
            reducer: (state) => {
                let s = Object.assign({}, state);
                delete s.isOnline; // オンラインフラグは保存しない
                delete s.showOverlay; // オーバーレイ表示フラグは保存しない
                return s;
            }
        }),
    ],
};

export default new Vuex.Store(moduleGlobal);

/**
 * 作業項目を表示用にフォーマットする
 * 
 * @param {Array} procedures 作業項目の一覧
 * @return {Array} フォーマットされた作業項目の一覧
 */
function formatProcedures(procedures) {
    const data_from_item = (item, index) => {

        var status = '';

        if (item.status == 0) {
            status = 'incomplete';
        } else if (item.status == 2) {
            status = 'partial';
        } else if (item.status == 3) {
            status = 'completed';
        } else if (item.status == undefined) {
            status = 'incomplete';
        }

        return {
            id: item.id,
            task_id: item.task_id,
            branch_id: item.branch_id,
            index: index,
            name: item.work_procedure_name,
            status: status,
            description: item.work_procedure_text,
            notice: item.work_procedure_notice,
            video_id: item.video_id,
            basepath: item.video_base_path,
            videopath: item.video_path,
            file_size: item.file_size,
            selected: false,
            is_updated: item.is_updated, // 前回ダウンロード後に更新されたかどうか
            uuid: Vue.ELearning.Util.generateUuid(),
            child: [],
        };
    }

    let intermediate = [];

    // 親の登録
    for (const item of procedures) {
        if (item.branch_id != 0) {
            continue;
        }

        const data = data_from_item(item, item.work_procedure_index);

        intermediate.push(data);
    }

    // 子の登録
    for (const parent_item of intermediate) {
        for (const child_item of procedures) {
            if (child_item.work_procedure_index != parent_item.index) {
                continue;
            }

            if (child_item.branch_id == 0) {
                continue;
            }

            parent_item.status = '';

            const data = data_from_item(child_item, child_item.work_procedure_index + '-' + child_item.branch_id);

            parent_item.child.push(data);
        }
    }

    return intermediate;
}