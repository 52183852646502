var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("カテゴリ登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c(
                      "h2",
                      { staticClass: "column", attrs: { id: "title" } },
                      [_vm._v("カテゴリ編集")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c("div", { attrs: { id: "subtitle-area" } }, [
                  _vm._v("カテゴリ一覧")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "table-area" } },
                  [
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          on: { click: _vm.onClickNew }
                        },
                        [
                          _vm._v("新規登録 "),
                          _c("img", {
                            attrs: { src: "/images/ico-plus.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          attrs: { disabled: _vm.editButtonEnabled },
                          on: { click: _vm.onClickEdit }
                        },
                        [
                          _vm._v("編集 "),
                          _c("img", {
                            attrs: { src: "/images/ico-pen.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-gray",
                          attrs: { disabled: _vm.replicateButtonEnabled },
                          on: { click: _vm.onClickReplicate }
                        },
                        [
                          _vm._v("複製 "),
                          _c("img", {
                            attrs: { src: "/images/ico-copy.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-red",
                          attrs: {
                            disabled: _vm.deleteButtonEnabled,
                            "data-remodal-target": "modal"
                          },
                          on: {
                            click: function($event) {
                              _vm.showModalDelete = true
                            }
                          }
                        },
                        [
                          _vm._v("削除 "),
                          _c("img", {
                            attrs: { src: "/images/ico-trash.png", alt: "" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("p", { staticClass: "input-search tr" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button basic is-gray2",
                            attrs: { href: "#" },
                            on: { click: _vm.sortUpdateDatetime }
                          },
                          [
                            _vm._v("更新日順 "),
                            _vm.sortAxis == "update_datetime"
                              ? _c("arrow-up-down-alt", {
                                  attrs: { order: _vm.sortOrder }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button basic is-gray2",
                            attrs: { href: "#" },
                            on: { click: _vm.sortDictionary }
                          },
                          [
                            _vm._v("名前順 "),
                            _vm.sortAxis == "dictionary"
                              ? _c("arrow-up-down-alt", {
                                  attrs: { order: _vm.sortOrder }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-wrapper" }, [
                  _c(
                    "table",
                    { staticClass: "basic scrollTable" },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c("th", [_vm._v("カテゴリ名")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("公開/非公開")])
                        ])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.categoryList, function(category) {
                        return [
                          _c("tr", [
                            _c("td", { staticClass: "check" }, [
                              _c(
                                "label",
                                [
                                  _c("base-check-component", {
                                    on: {
                                      "check-state-change": function($event) {
                                        return _vm.onCheckStateChange(category)
                                      }
                                    },
                                    model: {
                                      value: category.selected,
                                      callback: function($$v) {
                                        _vm.$set(category, "selected", $$v)
                                      },
                                      expression: "category.selected"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "group-name" }, [
                              _vm._v(_vm._s(category.categoryName))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "open" }, [
                              _vm._v(_vm._s(category.publicity))
                            ])
                          ])
                        ]
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _vm.showModalDelete
                  ? _c(
                      "modal-component",
                      {
                        attrs: {
                          "is-delete": true,
                          "is-quest": "true",
                          width: "600",
                          height: "331"
                        },
                        on: {
                          modalYes: _vm.onClickDelete,
                          modalNo: function($event) {
                            _vm.showModalDelete = false
                          }
                        }
                      },
                      [
                        _c("h4", { attrs: { slot: "header" }, slot: "header" }),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { slot: "body" },
                            slot: "body"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "tc pb2em",
                                staticStyle: { "margin-top": "0.5em" }
                              },
                              [
                                _c(
                                  "strong",
                                  {
                                    staticStyle: {
                                      "font-size": "1.4rem",
                                      "line-height": "2",
                                      "font-weight": "normal"
                                    }
                                  },
                                  [_vm._v("選択されたカテゴリを削除しますか？")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { display: "block" } },
                                  [_vm._v("※タスクも削除されます")]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }