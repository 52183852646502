<template>
  <common-layout-component2 :show-back="is_show_back" show-admin="true" @on-nav-back="$router.push( top_page_path )">
    <template v-slot:nav-message>
      <p>閲覧ログ - 作業履歴</p>
    </template>

    <template v-slot:body>
      <div class="columns is-gapless logs">
        <log-side-bar :is-show-userwork-userlist="is_show_userlist" :userwork-users="all_users" :userwork-users-sort-asc="users_sort_asc" @sortUsers="onSortUserList"/>

        <div class="column main-column">
          <div class="main-column-inner">
              <div class="board border-none" v-if="user.user_id">
                <div class="user-basic-data">
                  <div class="user-icon"><img src="/images/icon_user.png" alt=""></div>
                  <div class="data1">
                    <p class="user-name">{{ user.user_name }}</p>
                    <p class="user-kana">{{ user.user_name_kana }}</p>
                    </div>
                  <div class="data2">
                    <router-link :to="{ name: 'log-user', params: { user_id: user.user_id } }" class="log-user-button dash-button">ダッシュボード</router-link>
                  </div>
                </div>
              </div><!-- /.board -->

            <log-period-input :period_begin.sync="period_begin" :period_end.sync="period_end" v-on:period-updated="on_period_updated">
              <div class="download-button"><a href="#" @click.prevent.stop.prevent="download_work_procedure_logs_csv">CSVダウンロード</a></div>
            </log-period-input>

            <div class="row board-container board-container01">
              <div class="col-12">
                <div class="board">
                  <div class="show-more-table">
                    <div class="table-responsive">
                      <sortable-table ref="table" :headers="headers" :list.sync="work_procedure_logs" :limit="6" :is_more="is_work_procedure_logs_more" :sort_by.sync="sort_by" :is_sort_asc.sync="is_sort_asc"></sortable-table>
                    </div><!-- /.table-responsive -->
                    <more-button v-model="is_work_procedure_logs_more" :list="work_procedure_logs" :limit="6" color="color01"></more-button>
                  </div><!-- /.show-more-table -->
                </div><!-- /.board -->
              </div>
            </div><!-- /.board-container01 -->
          </div><!-- /.main-column -->
          <div class="white_bg" v-if="is_show_userlist"/>
        </div><!-- /.column -->
      </div>
    </template>
  </common-layout-component2>
</template>
<script>

import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import LogSideBar from './components/LogSideBar';
import LogPeriodInput from './components/LogPeriodInput';
import SortableTable from './components/SortableTable';
import MoreButton from './components/MoreButton';

import moment from 'moment';

export default {
  components: {
      CommonLayoutComponent2,
      LogSideBar,
      LogPeriodInput,
      SortableTable,
      MoreButton,
  },
  
  data: () => { return {
    period_begin: "",                   // 期間 ( 開始 )
    period_end: "",                     // 期間 ( 終了 )
    
    work_procedure_logs: [],            // ログ
    headers: [
      { name: "category_name", title: "カテゴリ", },
      { name: "task_name", title: "タスク", },
      { name: "work_procedure_name", title: "作業手順", },
      { name: "log_type_name", title: "作業内容"},      
      { name: "create_datetime", title: "作業日時", filter: ( value ) => moment(value).format("YYYY/MM/DD HH:mm") }
    ],

    sort_by: "create_datetime",                    // ソート
    is_sort_asc: true,
    
    // More
    is_work_procedure_logs_more: false,

    user:{},
    all_users: [],
    users_sort_asc: true,
  } },

  computed: {
    // API の共通パラメータ
    api_params: function () {
      return {
        begin:    this.period_begin,
        end:      this.period_end,
        user_id:  this.$route.params.user_id,
        task__id: this.$route.params.task__id
      };
    },

    is_show_back: () => ! Vue.ELearning.Logs.isTopPage(),
    top_page_path: () => Vue.ELearning.Logs.getTopPagePath(),
    
    is_show_userlist: function(){ return ( this.is_admin && this.$route.params.user_id == undefined); }, 
    is_admin: function () { return this.$store.state.userInfo.grant >= 3; },
  },

  watch: {
    '$route' (to, from) {
      this.update_list();
    }
  },

  mounted () {
    Vue.ELearning.Logs.init();
  },

  methods: {
    /**
     * 期間が変更された時の処理
     */
    on_period_updated: function ( begin, end ) {
      this.update_list();
    },

    /** リストを更新する */
    update_list: function () {

      //F2 No.42 2020/02/17 管理者かつユーザ作業履歴トップ（パラメータにuser_idなし）の場合にユーザ一覧を表示
      if(this.is_show_userlist){

        this.users_sort_asc = true;
        Vue.ELearning.Logs.getApiData( Vue.ELearning.UserInfo.allForLog(), async function ( data ) {
          this.all_users = data;
          this.sortByUser();

        }.bind( this ) );
      };

      if(this.api_params.user_id){
        // 
        Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getWorkProcedureCompletedLogs( this.api_params ), async function ( data ) {
          this.work_procedure_logs = data;
          this.sort_by = "create_datetime";
          this.is_sort_asc = true;

          await Vue.nextTick();

          this.$refs.table.sort_list();
        }.bind( this ) );

        Vue.ELearning.Logs.getApiData( Vue.ELearning.UserInfo.getUser( this.api_params.user_id ), ( data ) => {
          this.user = data;
        } );
      //Vue.ELearning.Group.getAll();
      }
    },

    sortByUser: function(){
      const asc = this.users_sort_asc? 1 : -1;

      this.all_users.sort(function(a, b) {

        const kana_a = a.user_name_kana? a.user_name_kana : "";
        const kana_b = b.user_name_kana? b.user_name_kana : "";          

        if (kana_a  > kana_b) { return asc; } 
        if (kana_a  < kana_b) { return -1 * asc; }

        /*
        if (a.user_name > b.user_name) { return asc; }
        if (a.user_name < b.user_name) { return -1 * asc; }

        if (a.user_id > b.user_id) { return asc; }
        if (a.user_id < b.user_id) { return -1 * asc; }
        */
        return 0;
      });

    },

    onSortUserList: function(){

      this.users_sort_asc = ! this.users_sort_asc;
      this.sortByUser();

    },

    /** 作業履歴の一覧 CSV ダウンロード */
    download_work_procedure_logs_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getWorkProcedureCompletedLogsCsv( this.api_params ), "work_procedure_logs.csv" );
    }
  },
};

</script>
<style scoped lang="scss">
div.logs .main-column{
  position: relative;
}

.white_bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #fff, $alpha: 0.5);
}

div.logs .board.border-none{
  margin-top: 5px;
  margin-bottom: 15px;
}

div.logs .user-basic-data .data2{
  padding-top: 5px;
  padding-top: 5px;  
}

</style>