var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("カテゴリ登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "container mainContainer" }, [
              _c(
                "div",
                { staticClass: "columns", attrs: { id: "header-area" } },
                [
                  _c("h2", {
                    staticClass: "column",
                    attrs: { id: "title" },
                    domProps: { textContent: _vm._s(_vm.title) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "column tr" })
                ]
              ),
              _vm._v(" "),
              _c("section", { staticClass: "subWindow" }, [
                _c("div", { staticClass: "field columns" }, [
                  _c(
                    "label",
                    {
                      staticClass: "label column is-one-fifth",
                      class: { error: _vm.errCategoryName }
                    },
                    [_vm._v("カテゴリ名")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "control column" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.categoryName,
                          expression: "categoryName"
                        },
                        { name: "focus", rawName: "v-focus" }
                      ],
                      staticClass: "input",
                      class: { "error-ctrl": _vm.errCategoryName },
                      attrs: {
                        type: "text",
                        placeholder: "カテゴリ名が入ります"
                      },
                      domProps: { value: _vm.categoryName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.categoryName = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errCategoryName,
                        expression: "errCategoryName"
                      }
                    ],
                    staticClass: "field columns",
                    class: { error: _vm.errCategoryName }
                  },
                  [
                    _c("label", { staticClass: "label column is-one-fifth" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c(
                        "div",
                        { class: { error: _vm.errCategoryName } },
                        _vm._l(_vm.categoryNameErrors, function(err, idx) {
                          return _c("p", {
                            key: idx,
                            domProps: { textContent: _vm._s(err) }
                          })
                        }),
                        0
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "field columns" }, [
                  _c("label", { staticClass: "label column is-one-fifth" }, [
                    _vm._v("公開/非公開")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control column" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.publicity,
                          expression: "publicity"
                        }
                      ],
                      staticClass: "radio02-input",
                      attrs: {
                        type: "radio",
                        id: "radio02-01",
                        value: "public"
                      },
                      domProps: { checked: _vm._q(_vm.publicity, "public") },
                      on: {
                        change: function($event) {
                          _vm.publicity = "public"
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "radio02-01" } }, [
                      _c("b", [_vm._v("公開")])
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.publicity,
                          expression: "publicity"
                        }
                      ],
                      staticClass: "radio02-input",
                      attrs: {
                        type: "radio",
                        id: "radio02-02",
                        value: "negative"
                      },
                      domProps: { checked: _vm._q(_vm.publicity, "negative") },
                      on: {
                        change: function($event) {
                          _vm.publicity = "negative"
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "radio02-02" } }, [
                      _c("b", [_vm._v("非公開")])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control tc" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button basic is-cancel",
                        on: { click: _vm.onCancel }
                      },
                      [_vm._v("キャンセル")]
                    ),
                    _vm._v(" "),
                    _c("a", {
                      staticClass: "button basic is-login",
                      staticStyle: { color: "white" },
                      domProps: { textContent: _vm._s(_vm.submitLabel) },
                      on: { click: _vm.onUpdate }
                    })
                  ])
                ])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }