var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "basic scrollTable" },
    [
      _c("thead", [
        _c("tr", [
          _c("th"),
          _vm._v(" "),
          _c(
            "th",
            {
              staticClass: "select-user-table-sortheader",
              on: {
                click: function($event) {
                  return _vm.sort("user_name_kana")
                }
              }
            },
            [
              _c("span", [_vm._v("ユーザー名 ")]),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.sortAxis == "user_name_kana" &&
                      _vm.sortOrder == "asc",
                    expression:
                      "sortAxis == 'user_name_kana' && sortOrder == 'asc'"
                  }
                ],
                attrs: { src: "/images/ico-down.png", alt: "" }
              }),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.sortAxis == "user_name_kana" &&
                      _vm.sortOrder == "desc",
                    expression:
                      "sortAxis == 'user_name_kana' && sortOrder == 'desc'"
                  }
                ],
                attrs: { src: "/images/ico-up.png", alt: "" }
              }),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sortAxis == "org_name",
                    expression: "sortAxis == 'org_name'"
                  }
                ],
                attrs: { src: "/images/ico-down-thin.png", alt: "" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "th",
            {
              staticClass: "select-user-table-sortheader",
              on: {
                click: function($event) {
                  return _vm.sort("org_name")
                }
              }
            },
            [
              _c("span", [_vm._v("部署名 ")]),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sortAxis == "org_name" && _vm.sortOrder == "asc",
                    expression: "sortAxis == 'org_name' && sortOrder == 'asc'"
                  }
                ],
                attrs: { src: "/images/ico-down.png", alt: "" }
              }),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.sortAxis == "org_name" && _vm.sortOrder == "desc",
                    expression: "sortAxis == 'org_name' && sortOrder == 'desc'"
                  }
                ],
                attrs: { src: "/images/ico-up.png", alt: "" }
              }),
              _vm._v(" "),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.sortAxis == "user_name_kana",
                    expression: "sortAxis == 'user_name_kana'"
                  }
                ],
                attrs: { src: "/images/ico-down-thin.png", alt: "" }
              })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.users.length == 0
        ? _c("tr", [
            _c(
              "td",
              {
                staticClass: "select-user-table-nodata",
                attrs: { colspan: "3" }
              },
              [
                _vm._v(
                  "\n            グループに紐付け可能なユーザーが存在しません。\n        "
                )
              ]
            )
          ])
        : _vm._l(_vm.users, function(user) {
            return [
              _c("select-user-table-row", {
                key: user.userId,
                attrs: { user: user }
              })
            ]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }