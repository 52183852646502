var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "columns is-gapless" },
    [
      _c(
        "div",
        {
          staticClass: "column column-checklist",
          class: { "is-one-quarter": _vm.showDetail },
          style: { "max-height": _vm.contentHeight + "px" }
        },
        [
          _c(
            "div",
            { attrs: { id: "sidebar" } },
            [
              !_vm.showDetail
                ? _c(
                    "a",
                    { attrs: { id: "cont-close" }, on: { click: _vm.toggle } },
                    [
                      _c("img", {
                        attrs: { src: "/images/ico-right-arrow.png", alt: "" }
                      })
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.procedures, function(p, idx) {
                return [
                  _c("work-procedure", {
                    key: p.work_procedure_id,
                    attrs: {
                      item: p,
                      "is-last": _vm.isLastProcedure(idx),
                      "action-name": "updateCurrentProcedureForPreview"
                    }
                  })
                ]
              }),
              _vm._v(" "),
              _c("p", { staticClass: "tc pt10" }, [
                _c(
                  "a",
                  {
                    staticClass: "button basic is-login",
                    staticStyle: { color: "white" },
                    on: { click: _vm.back }
                  },
                  [_vm._v("編集画面へ戻る")]
                )
              ])
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c("check-list-detail", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showDetail,
            expression: "showDetail"
          }
        ],
        attrs: {
          "content-height": _vm.contentHeight,
          index: _vm.target.index,
          name: _vm.target.name,
          notice: _vm.target.notice,
          description: _vm.target.description,
          "base-path": _vm.target.basepath,
          "video-path": _vm.target.videopath,
          "hide-content": _vm.hideContent
        },
        on: { toggle: _vm.toggle }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "side-title" }, [
      _c("b", [_vm._v("作業手順")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }