var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [
            _c("span", { staticClass: "nav-msg-sub" }, [
              _vm._v(_vm._s(_vm.currentCategoryName))
            ]),
            _vm._v(
              "\n            " + _vm._s(_vm.currentTaskName) + "\n        "
            )
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c("h2", {
                      staticClass: "column",
                      attrs: { id: "title" },
                      domProps: { textContent: _vm._s(_vm.title) }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "column tr" })
                  ]
                ),
                _vm._v(" "),
                _c("section", { staticClass: "subWindow" }, [
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errCategoryId }
                      },
                      [_vm._v("カテゴリ名")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedCategoryId,
                              expression: "selectedCategoryId"
                            },
                            { name: "focus", rawName: "v-focus" }
                          ],
                          staticClass: "input minimal",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedCategoryId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.onCategoryChanged
                            ]
                          }
                        },
                        _vm._l(_vm.categories, function(category) {
                          return _c(
                            "option",
                            { domProps: { value: category.value } },
                            [_vm._v(_vm._s(category.text))]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errCategoryId,
                          expression: "errCategoryId"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errCategoryId }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errCategoryId } },
                          _vm._l(_vm.categoryErrors, function(err) {
                            return _c("p", [_vm._v(_vm._s(err))])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errTaskName }
                      },
                      [_vm._v("タスク名")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskName,
                            expression: "taskName"
                          }
                        ],
                        staticClass: "input",
                        class: { "error-ctrl": _vm.errTaskName },
                        attrs: {
                          type: "text",
                          placeholder: "タスク名が入ります"
                        },
                        domProps: { value: _vm.taskName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.taskName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errTaskName,
                          expression: "errTaskName"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errTaskName }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errTaskName } },
                          _vm._l(_vm.taskNameErrors, function(err) {
                            return _c("p", [_vm._v(_vm._s(err))])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errTaskText }
                      },
                      [_vm._v("説明文")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c(
                        "textarea",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taskDesc,
                              expression: "taskDesc"
                            }
                          ],
                          staticClass: "input",
                          class: { "error-ctrl": _vm.errTaskText },
                          staticStyle: { height: "auto" },
                          attrs: { rows: "2", name: "" },
                          domProps: { value: _vm.taskDesc },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.taskDesc = $event.target.value
                            }
                          }
                        },
                        [
                          _vm._v(
                            "ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。\n                            "
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errTaskText,
                          expression: "errTaskText"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errTaskText }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errTaskText } },
                          _vm._l(_vm.taskTextErrors, function(err) {
                            return _c("p", [_vm._v(_vm._s(err))])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("閲覧権限")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.grant,
                            expression: "grant"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio02-01",
                          value: "all"
                        },
                        domProps: { checked: _vm._q(_vm.grant, "all") },
                        on: {
                          change: function($event) {
                            _vm.grant = "all"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio02-01" } }, [
                        _c("b", [_vm._v("全て")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.grant,
                            expression: "grant"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio02-02",
                          value: "perGroup"
                        },
                        domProps: { checked: _vm._q(_vm.grant, "perGroup") },
                        on: {
                          change: function($event) {
                            _vm.grant = "perGroup"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio02-02" } }, [
                        _c("b", [_vm._v("グループ指定")])
                      ]),
                      _vm._v(" "),
                      !_vm.groupSelectedCompleteEnabled ||
                      !_vm.groupSelectionEnabled
                        ? _c(
                            "button",
                            {
                              staticClass: "button basic is-tags rs selection",
                              attrs: { disabled: !_vm.groupSelectionEnabled },
                              on: {
                                click: function($event) {
                                  _vm.isOpenUserByTask = !_vm.isOpenUserByTask
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                選択して下さい\n                            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.groupSelectedCompleteEnabled &&
                      _vm.groupSelectionEnabled
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button basic is-tags rs selection selected",
                              attrs: { disabled: !_vm.groupSelectionEnabled },
                              on: {
                                click: function($event) {
                                  _vm.isOpenUserByTask = !_vm.isOpenUserByTask
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                選択中\n                            "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("公開/非公開")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.publicity,
                            expression: "publicity"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio03-01",
                          value: "public"
                        },
                        domProps: { checked: _vm._q(_vm.publicity, "public") },
                        on: {
                          change: function($event) {
                            _vm.publicity = "public"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio03-01" } }, [
                        _c("b", [_vm._v("公開")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.publicity,
                            expression: "publicity"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio03-02",
                          value: "negative"
                        },
                        domProps: {
                          checked: _vm._q(_vm.publicity, "negative")
                        },
                        on: {
                          change: function($event) {
                            _vm.publicity = "negative"
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio03-02" } }, [
                        _c("b", [_vm._v("非公開")])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns pb2em" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("タグ登録")
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "control column" },
                      [
                        !_vm.hasSelectedTags
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "button basic is-tags rs selection",
                                on: {
                                  click: function($event) {
                                    _vm.isTagSelectorOpened = !_vm.isTagSelectorOpened
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                選択して下さい\n                            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasSelectedTags
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "button basic is-tags rs selection selected",
                                on: {
                                  click: function($event) {
                                    _vm.isTagSelectorOpened = !_vm.isTagSelectorOpened
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                                選択中\n                            "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "transition",
                          { attrs: { name: "fade" } },
                          [
                            _vm.isTagSelectorOpened
                              ? _c("tag-select", {
                                  attrs: { tags: _vm.tags },
                                  on: { "open-tag-modal": _vm.openTagModal }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control tc" }, [
                      _c("div", { staticClass: "columns is-multiline" }, [
                        _c("div", { staticClass: "column is-one-third" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button basic is-cancel",
                              on: { click: _vm.onCancel }
                            },
                            [
                              _vm._v(
                                "\n                                        キャンセル\n                                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("button", {
                            staticClass: "button basic is-login",
                            staticStyle: { color: "white" },
                            domProps: { textContent: _vm._s(_vm.submitLabel) },
                            on: { click: _vm.onUpdate }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third tr" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button basic is-login",
                              staticStyle: { color: "white" },
                              on: { click: _vm.onChecklistEdit }
                            },
                            [
                              _vm._v(
                                "\n                                        保存＆チェックリスト編集 "
                              ),
                              _c("img", {
                                attrs: { src: "/images/ico-pen.png", alt: "" }
                              })
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.isOpenUserByTask
                  ? _c(
                      "modal-component",
                      {
                        attrs: { "is-quest": "true", width: "1200" },
                        on: {
                          modalYes: function($event) {
                            _vm.isOpenUserByTask = !_vm.isOpenUserByTask
                          },
                          modalNo: function($event) {
                            _vm.isOpenUserByTask = !_vm.isOpenUserByTask
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("h3", [_vm._v("グループ選択")])]
                        ),
                        _vm._v(" "),
                        _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                          _c(
                            "table",
                            { staticClass: "basic scrollTable" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th"),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: { click: _vm.assignedGroupSort }
                                    },
                                    [
                                      _c("span", [_vm._v("グループ名 ")]),
                                      _vm._v(" "),
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.sortOrderGroup == "asc",
                                            expression:
                                              "sortOrderGroup == 'asc'"
                                          }
                                        ],
                                        attrs: {
                                          src: "/images/ico-down.png",
                                          alt: ""
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.sortOrderGroup == "desc",
                                            expression:
                                              "sortOrderGroup == 'desc'"
                                          }
                                        ],
                                        attrs: {
                                          src: "/images/ico-up.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.groups.length == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          border: "1px solid gray"
                                        },
                                        attrs: { colspan: "2" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    ユーザーに紐付け可能なグループが存在しません。\n                                "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.groups, function(group, key) {
                                return _c("tr", { key: key }, [
                                  _c(
                                    "td",
                                    { staticClass: "check" },
                                    [
                                      _c("base-check-component", {
                                        on: {
                                          "check-state-change": function(
                                            $event
                                          ) {
                                            return _vm.onUserByGroupChange(
                                              group
                                            )
                                          }
                                        },
                                        model: {
                                          value: group.selected,
                                          callback: function($$v) {
                                            _vm.$set(group, "selected", $$v)
                                          },
                                          expression: "group.selected"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      on: {
                                        click: function($event) {
                                          return _vm.onUserByGroupChange(group)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(group.groupName) +
                                          "\n                                "
                                      )
                                    ]
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12",
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-cancel",
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelModalGroups()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    キャンセル"
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "  \n                                "
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-login",
                                      attrs: {
                                        disabled: !_vm.groupSelectedCompleteEnabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.userTaskDialogCompleted()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    選択完了\n                                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isOpenCreateTag
                  ? _c("tag-select-modal", {
                      on: {
                        cancel: _vm.closeTagModal,
                        created: _vm.reloadTags,
                        toggle: _vm.toggleTagModal
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }