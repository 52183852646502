<template>
    <div class="column is-one-quarter">
        <label>
            <input
                type="checkbox"
                class="checkbox01-input"
                :value="tag.tagId"
                v-model="tag.selected"
            />
            <span class="checkbox01-parts" v-text="tag.tagName" />
        </label>
    </div>
</template>

<script>
export default {
    props: {
        tag: {
            type: Object,
            required: true,
        },
    },
};
</script>
