var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "box_wh" },
        [
          _vm.title ? _c("h3", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
          _vm._v(" "),
          _vm._t("body"),
          _vm._v(" "),
          _vm.viewSelectable
            ? _c("div", { staticClass: "flex_nw" }, [
                _c("div", { staticClass: "item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "round ok keycolor",
                      on: {
                        click: function($event) {
                          return _vm.$emit("yes")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.yes))]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "round cancel white",
                      on: {
                        click: function($event) {
                          return _vm.$emit("no")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.no))]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.viewPlain
            ? _c("div", { staticClass: "flex_nw" }, [
                _c("div", { staticClass: "item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "round ok keycolor",
                      on: {
                        click: function($event) {
                          return _vm.$emit("ok")
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.ok))]
                  )
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }