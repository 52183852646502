var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: {
        cursor: "pointer",
        width: "20px",
        height: "20px",
        opacity: "1"
      },
      attrs: {
        version: "1.1",
        id: "_x32_",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 512 512",
        "xml:space": "preserve"
      },
      on: { click: _vm.onclick }
    },
    [
      _vm.currentSelected
        ? _c("g", [
            _c("polygon", {
              staticClass: "st0",
              staticStyle: { fill: "#B6B6B6" },
              attrs: {
                points:
                  "335.644,414.285 53.466,414.285 53.466,132.107 291.098,132.107 344.564,78.64 0,78.64 0,467.751\n\t\t\t\t389.106,467.751 389.106,441.018 389.106,323.746 335.644,377.213 \t"
              }
            }),
            _vm._v(" "),
            _c("polygon", {
              staticClass: "st0",
              staticStyle: { fill: "#49BC23" },
              attrs: {
                points:
                  "158.903,163.312 103.914,218.311 193.434,307.822 248.423,362.82 303.412,307.822 512,99.247\n\t\t\t\t457.002,44.249 248.431,252.823 \t"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentSelected
        ? _c("g", [
            _c("polygon", {
              staticClass: "st0",
              staticStyle: { fill: "#B6B6B6" },
              attrs: {
                points:
                  "335.644,414.285 53.466,414.285 53.466,132.107 344.564,132.107 344.564,78.64 0,78.64 0,467.751\n\t\t\t\t389.106,467.751 389.106,441.018 389.106,78.64 344.564,78.64 335.644,377.213 \t"
              }
            })
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }