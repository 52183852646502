var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "false", "show-admin": "true" },
    on: {
      "on-nav-back": function($event) {
        return _vm.$router.push({ name: "log-index" })
      }
    },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("閲覧ログ - ダッシュボード")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "columns is-gapless logs" },
              [
                _c("log-side-bar"),
                _vm._v(" "),
                _c("div", { staticClass: "column main-column" }, [
                  _c(
                    "div",
                    { staticClass: "main-column-inner" },
                    [
                      _c("log-period-input", {
                        on: { "period-updated": _vm.on_period_updated }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container01"
                        },
                        [
                          _c("div", { staticClass: "col-md-4" }, [
                            _c(
                              "div",
                              { staticClass: "board board-content-sm" },
                              [
                                _c("dl", [
                                  _c(
                                    "dt",
                                    { staticClass: "board-title icon-server" },
                                    [_vm._v("サーバー容量")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "dd",
                                    { staticClass: "board-body has-footnote" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "usable-capacity" },
                                        [_vm._v(_vm._s(_vm.currentFileSizeSum))]
                                      ),
                                      _vm._v("/"),
                                      _c(
                                        "span",
                                        { staticClass: "total-capacity" },
                                        [_vm._v(_vm._s(_vm.max_disk_space))]
                                      ),
                                      _c("span", { staticClass: "unit" }, [
                                        _vm._v("GB")
                                      ]),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "footnote" }, [
                                        _vm._v("使用量/契約容量")
                                      ])
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c(
                              "div",
                              { staticClass: "board board-content-sm" },
                              [
                                _c("dl", [
                                  _c(
                                    "dt",
                                    { staticClass: "board-title icon-user" },
                                    [
                                      _vm._v("1ログインでの"),
                                      _c("br"),
                                      _vm._v("平均動画閲覧数")
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("dd", { staticClass: "board-body" }, [
                                    _c(
                                      "span",
                                      { staticClass: "count log-in-count" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.average_video_play_count_per_login
                                          )
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("回")
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-4" }, [
                            _c(
                              "div",
                              { staticClass: "board board-content-sm" },
                              [
                                _c("dl", [
                                  _c(
                                    "dt",
                                    { staticClass: "board-title icon-video" },
                                    [_vm._v("動画再生回数")]
                                  ),
                                  _vm._v(" "),
                                  _c("dd", { staticClass: "board-body" }, [
                                    _c(
                                      "span",
                                      { staticClass: "count view-count" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.total_video_play_count)
                                        )
                                      ]
                                    ),
                                    _c("span", { staticClass: "unit" }, [
                                      _vm._v("回")
                                    ])
                                  ])
                                ])
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container02"
                        },
                        [
                          _c("div", { staticClass: "col-md-6" }, [
                            _c("div", { staticClass: "board tab-panel" }, [
                              _c("ul", { staticClass: "tab" }, [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tabcolor02 selected",
                                      attrs: { href: "#tab1-1" }
                                    },
                                    [_vm._v("ログイン回数")]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tabcolor02",
                                      attrs: { href: "#tab1-2" }
                                    },
                                    [_vm._v("ログインユーザー数")]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "panel" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "panel-content",
                                    attrs: { id: "tab1-1" }
                                  },
                                  [
                                    _c("canvas", {
                                      attrs: { id: "authorized_logins_chart" }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "panel-content",
                                    attrs: { id: "tab1-2" }
                                  },
                                  [
                                    _c("canvas", {
                                      attrs: { id: "authorized_users_chart" }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-md-6" }, [
                            _c(
                              "div",
                              { staticClass: "board board-content-md" },
                              [
                                _c("dl", [
                                  _c(
                                    "dt",
                                    { staticClass: "board-title icon-task" },
                                    [_vm._v("完了タスク")]
                                  ),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _c("canvas", {
                                      attrs: { id: "completed_tasks_chart" }
                                    })
                                  ])
                                ])
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row board-container board-container03"
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "board tab-panel" }, [
                              _c("ul", { staticClass: "tab" }, [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tabcolor01 selected",
                                      attrs: { href: "#tab2-1" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon-device" },
                                        [_vm._v("閲覧デバイス")]
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tabcolor01",
                                      attrs: { href: "#tab2-2" }
                                    },
                                    [
                                      _c("span", { staticClass: "icon-user" }, [
                                        _vm._v("再生回数が多いユーザー")
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "tabcolor01",
                                      attrs: { href: "#tab2-3" }
                                    },
                                    [
                                      _c("span", { staticClass: "icon-task" }, [
                                        _vm._v("よく閲覧されたタスク")
                                      ])
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "panel" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "panel-content",
                                    attrs: { id: "tab2-1" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "show-more-table" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "table-responsive" },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table basic2 color01 partial-display"
                                              },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", [_vm._v(" ")]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("デバイス")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [_vm._v(" ")])
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.top_authorized_devices,
                                                    function(device, n) {
                                                      return _c(
                                                        "tr",
                                                        { key: device.device },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "zero_pad"
                                                                )(n + 1, 2)
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                device.device_name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                device.count
                                                              ) + "回"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "panel-content",
                                    attrs: { id: "tab2-2" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "show-more-table" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "table-responsive" },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table basic2 color01 initial-rows3",
                                                class: {
                                                  "partial-display": !_vm.is_top_watched_users_more
                                                }
                                              },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", [_vm._v(" ")]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("ユーザー")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [_vm._v(" ")])
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.top_watched_users,
                                                    function(user, n) {
                                                      return _c(
                                                        "tr",
                                                        { key: user.user_id },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "zero_pad"
                                                                )(n + 1, 2)
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                user.user_name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                user.count
                                                              ) + "回"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("more-button", {
                                          attrs: {
                                            list: _vm.top_watched_users,
                                            limit: 3,
                                            color: "color01"
                                          },
                                          model: {
                                            value:
                                              _vm.is_top_watched_users_more,
                                            callback: function($$v) {
                                              _vm.is_top_watched_users_more = $$v
                                            },
                                            expression:
                                              "is_top_watched_users_more"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "panel-content",
                                    attrs: { id: "tab2-3" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "show-more-table" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "table-responsive" },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table basic2 color01 initial-rows3",
                                                class: {
                                                  "partial-display": !_vm.is_top_viewed_tasks_more
                                                }
                                              },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", [_vm._v(" ")]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("タスク")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("カテゴリ　")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [_vm._v(" ")])
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.top_viewed_tasks,
                                                    function(task, n) {
                                                      return _c(
                                                        "tr",
                                                        { key: task.id },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "zero_pad"
                                                                )(n + 1, 2)
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.task_name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.category_name
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.count
                                                              ) + "回"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("more-button", {
                                          attrs: {
                                            list: _vm.top_viewed_tasks,
                                            limit: 3,
                                            color: "color01"
                                          },
                                          model: {
                                            value: _vm.is_top_viewed_tasks_more,
                                            callback: function($$v) {
                                              _vm.is_top_viewed_tasks_more = $$v
                                            },
                                            expression:
                                              "is_top_viewed_tasks_more"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }