var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("タグ登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "container mainContainer" }, [
              _c(
                "div",
                { staticClass: "columns", attrs: { id: "header-area" } },
                [
                  _c("h2", {
                    staticClass: "column",
                    attrs: { id: "title" },
                    domProps: { textContent: _vm._s(_vm.title) }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "column tr" })
                ]
              ),
              _vm._v(" "),
              _c("section", { staticClass: "subWindow" }, [
                _c("div", { staticClass: "field columns" }, [
                  _c("label", { staticClass: "label column is-one-fifth" }, [
                    _vm._v("タグ名")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control column" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.tagName,
                          expression: "tagName"
                        },
                        { name: "focus", rawName: "v-focus" }
                      ],
                      staticClass: "input",
                      attrs: { type: "text", placeholder: "タグ名が入ります" },
                      domProps: { value: _vm.tagName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.tagName = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control tc" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button basic is-cancel",
                        on: { click: _vm.onCancel }
                      },
                      [_vm._v("キャンセル")]
                    ),
                    _vm._v(" "),
                    _c("a", {
                      staticClass: "button basic is-login",
                      staticStyle: { color: "white" },
                      domProps: { textContent: _vm._s(_vm.submitLabel) },
                      on: { click: _vm.onUpdate }
                    })
                  ])
                ])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }