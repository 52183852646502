//console.log("Initialize Route");

import Vue from 'vue';
import VueRouter from 'vue-router';

// VueRouter
Vue.use(VueRouter);

// パスとコンポーネントのマッピング
const routes = [
    // =================================================================================================================
    // 共通画面

    // ログイン
    {
        name: 'login',
        path: '/login',
        component: require('./components/miso/commons/Login.vue').default,
        meta: { requiresAuth: false, title: 'ログイン', class: "login_page" },
    },

    // ユーザー新規登録画面 
    {
        name: 'user-register',
        path: '/user/register',
        component: require('./components/miso/user/Register.vue').default,
        meta: { requiresAuth: false, title: '新規登録', class: "register_page" },
    },

    // 講師新規登録画面 
    {
        name: 'teacher-register',
        path: '/teacher/register',
        component: require('./components/miso/user/Register.vue').default,
        meta: { requiresAuth: false, title: '新規登録', class: "register_page", teacher: true },
    },
    // マイページ画面
    {
        name: 'mypage',
        path: '/mypage',
        component: require('./components/miso/user/Mypage.vue').default,
        meta: { requiresAuth: true, title: 'マイページ', class: "my_page" },
    },
    // ユーザー編集画面
    {
        name: 'mypage-edit',
        path: '/mypage/edit',
        component: require('./components/miso/user/MypageEdit.vue').default,
        meta: { requiresAuth: true, title: '個人情報設定', class: "my_page edit" },
    },

    // 検索画面
    {
        name: 'search',
        path: '/search',
        component: require('./components/miso/user/Search.vue').default,
        meta: { requiresAuth: false, title: '探す', class: "search_page" },
    },
    // 検索結果画面
    {
        name: 'search-result',
        path: '/search/result',
        component: require('./components/miso/user/SearchResult.vue').default,
        meta: { requiresAuth: false, title: '検索結果', class: "search_page" },
    },
    // お知らせ画面
    {
        name: 'notice',
        path: '/notice',
        component: require('./components/miso/user/Notice.vue').default,
        meta: { requiresAuth: true, title: 'お知らせ', class: "notice_page" },
    },
    // やることリスト画面
    {
        name: 'todo',
        path: '/todo',
        component: require('./components/miso/user/Todo.vue').default,
        meta: { requiresAuth: true, title: 'やることリスト', class: "notice_page" },
    },
    // マイレッスン画面
    {
        name: 'mylesson',
        path: '/mylesson',
        component: require('./components/miso/user/MyLesson.vue').default,
        meta: { requiresAuth: true, title: 'マイレッスン', class: "mylesson_page" },
    },

    // レッスン画面
    {
        name: 'lesson',
        path: '/lesson/:id(\\d+)',
        component: require('./components/miso/user/Lesson.vue').default,
        meta: { requiresAuth: false, title: 'レッスン', class: "lesson_page" },
    },

    // レッスン トップ画面
    {
        name: 'lesson-intro',
        path: '/lesson/:id(\\d+)/intro',
        component: require('./components/miso/user/LessonIntro.vue').default,
        meta: { requiresAuth: true, title: 'レッスン', class: "lesson_page" },
    },

    // レッスン ステップ画面
    {
        name: 'lesson-step',
        path: '/lesson/:id(\\d+)/:step(\\d+)/',
        component: require('./components/miso/user/LessonStep.vue').default,
        meta: { requiresAuth: true, title: 'レッスン', class: "lesson_page" },
    },

    // レッスンアドバイス依頼画面
    {
        name: 'lesson-post-advice',
        path: '/lesson/:id(\\d+)/advice',
        component: require('./components/miso/user/LessonPostAdvice.vue').default,
        meta: { requiresAuth: true, title: '採点してもらう', class: "lesson_page" },
    },

    // レッスンアドバイス返信画面
    {
        name: 'lesson-reply-advice',
        path: '/lesson/:id(\\d+)/advice/:advice(\\d+)/reply',
        component: require('./components/miso/user/LessonPostAdvice.vue').default,
        meta: { requiresAuth: true, title: '採点する', class: "lesson_page" },
    },

    // レッスン質問画面
    {
        name: 'lesson-post-question',
        path: '/lesson/:id(\\d+)/question',
        component: require('./components/miso/user/LessonPostAdvice.vue').default,
        meta: { requiresAuth: true, title: '質問する', class: "lesson_page" },
    },

    // レッスン質問回答画面
    {
        name: 'lesson-reply-question',
        path: '/lesson/:id(\\d+)/question/:question(\\d+)/reply',
        component: require('./components/miso/user/LessonPostAdvice.vue').default,
        meta: { requiresAuth: true, title: '回答する', class: "lesson_page" },
    },

    // レッスン新規作成画面
    {
        name: 'lesson-create',
        path: '/lesson/create',
        component: require('./components/miso/user/LessonEdit.vue').default,
        meta: { requiresAuth: true, title: 'レッスン情報を入力', class: "lesson_page", new: true },
    },

    // レッスン編集画面
    {
        name: 'lesson-edit',
        path: '/lesson/:id(\\d+)/edit',
        component: require('./components/miso/user/LessonEdit.vue').default,
        meta: { requiresAuth: true, title: 'レッスン情報を入力', class: "lesson_page" },
    },

    // レッスン購入者画面
    {
        name: 'lesson-purchased-users',
        path: '/lesson/:id(\\d+)/purchased',
        component: require('./components/miso/user/LessonPurchasedUsers.vue').default,
        meta: { requiresAuth: true, title: '受講者', class: "lesson_page lesson_users" },
    },

    // ステップ編集画面
    {
        name: 'step-edit',
        path: '/lesson/:id(\\d+)/step/edit',
        component: require('./components/miso/user/StepEdit.vue').default,
        meta: { requiresAuth: true, title: 'ステップの登録', class: "lesson_page" },
    },

    // 講師画面
    {
        name: 'teacher',
        path: '/teacher/:id(\\d+)',
        component: require('./components/miso/user/User.vue').default,
        meta: { requiresAuth: false, title: '講師', class: "user_page user_teacher" },
    },

    // 生徒画面
    {
        name: 'user',
        path: '/user/:id(\\d+)',
        component: require('./components/miso/user/User.vue').default,
        meta: { requiresAuth: false, title: 'ユーザー', class: "user_page user_teacher" },
    },

    // トップ画面
    {
        name: 'top',
        path: '/',
        component: require('./components/miso/commons/Top.vue').default,
        meta: { requiresAuth: false, title: 'みそしる教室', class: "home" },
    },

    // =================================================================================================================
    /*
    // ユーザー側
    {
        path: '/user/categories',
        component: require('./components/designed/user/CategorySelect.vue').default,
        meta: { requiresAuth: true, title: 'カテゴリ選択', isOfflineCompliant: true, class: "category_page" },
    },
    */
    // タスク選択画面
    {
        path: '/user/tasks/:category',
        component: require('./components/designed/user/TaskSelect.vue').default,
        meta: { requiresAuth: true, title: 'タスク選択', isOfflineCompliant: true, class: "tasks_page" },
    },

    // チェックリスト画面
    {
        path: '/user/checklist/:task_id',
        component: require('./components/designed/user/Checklist.vue').default,
        meta: { requiresAuth: true, title: 'チェックリスト', isOfflineCompliant: true, class: "page-checklist" },
    },

    // ダウンロードしたタスク一覧画面
    {
        path: '/user/download',
        component: require('./components/designed/user/DownloadList.vue').default,
        meta: { requiresAuth: true, title: 'ダウンロードしたタスク一覧', isOfflineCompliant: true, class: "page-download" },
    },
    // =================================================================================================================
    // 管理メニュー画面
    {
        path: '/admin/menu',
        component: require('./components/designed/admin/Menu.vue').default,
        meta: { requiresAuth: true, title: '設定/登録' },
    },

    // ユーザー一覧画面
    {
        path: '/admin/users',
        component: require('./components/designed/admin/UserList.vue').default,
        meta: { requiresAuth: true, title: 'ユーザー登録/編集' },
    },

    // ユーザー編集画面
    {
        path: '/admin/user/:userId(\\d+)',
        component: require('./components/designed/admin/UserEdit.vue').default,
        meta: { requiresAuth: true, title: 'ユーザー編集' },
        props: { register: false },
    },
    // グループ一覧画面
    {
        path: '/admin/groups',
        component: require('./components/designed/admin/GroupList.vue').default,
        meta: { requiresAuth: true, title: 'グループ登録/編集' },
    },
    // グループ新規登録画面  /* 2020/2/5追加　F2 No.65:ページタイトル修正対応 パラメータは/0だけ通す */
    {
        path: '/admin/group/:groupId(0)',
        component: require('./components/designed/admin/GroupEdit.vue').default,
        meta: { requiresAuth: true, title: 'グループ登録' },
        props: { register: true },
    },

    // グループ編集画面
    {
        path: '/admin/group/:groupId(\\d+)',
        component: require('./components/designed/admin/GroupEdit.vue').default,
        meta: { requiresAuth: true, title: 'グループ編集' },
        props: { register: false },
    },

    // カテゴリ一覧画面
    {
        path: '/admin/categories',
        component: require('./components/designed/admin/CategoryList.vue').default,
        meta: { requiresAuth: true, title: 'カテゴリ登録/編集' },
    },

    // カテゴリ新規登録  /* 2020/2/5追加　F2 No.65:ページタイトル修正対応 パラメータは/0だけ通す */
    {
        path: '/admin/category/:categoryId(0)',
        component: require('./components/designed/admin/CategoryEdit.vue').default,
        meta: { requiresAuth: true, title: 'カテゴリ登録' },
        props: { register: true },
    },

    // カテゴリ編集
    {
        path: '/admin/category/:categoryId(\\d+)',
        component: require('./components/designed/admin/CategoryEdit.vue').default,
        meta: { requiresAuth: true, title: 'カテゴリ編集' },
        props: { register: false },
    },

    // タスク一覧画面
    {
        path: '/admin/tasks',
        component: require('./components/designed/admin/TaskList.vue').default,
        meta: { requiresAuth: true, title: 'タスク登録/編集' },
    },
    // タスク新規登録画面  /* 2020/2/5追加　F2 No.65:ページタイトル修正対応 パラメータは/0だけ通す */
    {
        path: '/admin/task/:id(0)',
        component: require('./components/designed/admin/TaskEdit.vue').default,
        meta: { requiresAuth: true, title: 'タスク登録' },
        props: { register: true },
    },

    // タスク編集画面
    {
        path: '/admin/task/:id(\\d+)',
        component: require('./components/designed/admin/TaskEdit.vue').default,
        meta: { requiresAuth: true, title: 'タスク編集' },
        props: { register: false },
    },
    // チェックリスト編集画面
    {
        path: '/admin/checklist/:task_id',
        component: require('./components/designed/admin/ChecklistEdit.vue').default,
        meta: { requiresAuth: true, title: 'チェックリスト登録/編集' },
    },
    {
        path: '/admin/preview',
        component: require('./components/designed/admin/Preview.vue').default,
        meta: { requiresAuth: true, title: 'チェックリストプレビュー' },
    },

    // タグ一覧画面
    {
        path: '/admin/tags',
        component: require('./components/designed/admin/TagList.vue').default,
        meta: { requiresAuth: true, title: 'タグ登録/編集' },
    },
    // タグ新規登録画面   /* 2020/2/5追加　F2 No.65:ページタイトル修正対応 パラメータは/0だけ通す */
    {
        path: '/admin/tag/:tagId(0)',
        component: require('./components/designed/admin/TagEdit.vue').default,
        meta: { requiresAuth: true, title: 'タグ登録' },
        props: { register: true },
    },

    // タグ編集画面
    {
        path: '/admin/tag/:tagId(\\d+)',
        component: require('./components/designed/admin/TagEdit.vue').default,
        meta: { requiresAuth: true, title: 'タグ編集' },
        props: { register: false },
    },


    // 閲覧ログ ダッシュボード
    {
        name: 'log-index',
        path: '/admin/logs',
        component: require('./components/designed/admin/LogDashboard.vue').default,
        meta: { requiresAuth: true, title: '閲覧ログ - ダッシュボード' },
    },
    // 閲覧ログ ユーザー
    {
        name: 'log-users',
        path: '/admin/logs/users',
        component: require('./components/designed/admin/LogUsers.vue').default,
        meta: { requiresAuth: true, title: '閲覧ログ - ユーザー' },
    },
    // 閲覧ログ 個別ユーザー
    {
        name: 'log-user',
        path: '/admin/logs/users/:user_id',
        component: require('./components/designed/admin/LogUser.vue').default,
        meta: { requiresAuth: true, title: '閲覧ログ - ユーザー' },
    },
    // 閲覧ログ コンテンツ
    {
        name: 'log-contents',
        path: '/admin/logs/contents',
        component: require('./components/designed/admin/LogContents.vue').default,
        meta: { requiresAuth: true, title: '閲覧ログ - コンテンツ' },
    },
    // 閲覧ログ タスク更新履歴
    {
        name: 'log-task-updates',
        path: '/admin/logs/task_updates',
        component: require('./components/designed/admin/LogTaskUpdates.vue').default,
        meta: { requiresAuth: true, title: '閲覧ログ - タスク更新履歴' },
    },
    // 閲覧ログ 作業履歴
    {
        name: 'log-work-procedures-task',
        path: '/admin/logs/work_procedures/:user_id/:task__id',
        component: require('./components/designed/admin/LogWorkProcedures.vue').default,
        meta: { requiresAuth: true, title: '作業履歴' },
    },
    {
        name: 'log-work-procedures-user',
        path: '/admin/logs/work_procedures/:user_id',
        component: require('./components/designed/admin/LogWorkProcedures.vue').default,
        meta: { requiresAuth: true, title: '作業履歴' },
    },
    {
        name: 'log-work-procedures',
        path: '/admin/logs/work_procedures',
        component: require('./components/designed/admin/LogWorkProcedures.vue').default,
        meta: { requiresAuth: true, title: '閲覧ログ - ユーザー作業履歴' },
    },

    {
        name: 'admin-menu',
        path: '/design/admin/menu',
        component: require('./components/designed/admin/Menu.vue').default,
        meta: { requiresAuth: true, title: '管理画面トップ' },
    },

    /*
    // =================================================================================================================
    // Not Found
    {
        path: '*',
        component: require('./components/miso/commons/NotFound.vue')
            .default,
        meta: { requiresAuth: false, title: 'Not Found', class: "notfound_page" },
    },

    // =================================================================================================================
    */
];

// VueRouterインスタンスを作成する
const router = new VueRouter({
    mode: 'history',
    base: process.env.MIX_VUEROOTER_BASE,
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
});

// VueRouterインスタンスをエクスポートする
// app.jsでインポートするため
export default router;