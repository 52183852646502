var logic = {};

logic.created = function() {
    console.log('Initialize Admin/GroupEdit');
};

logic.mounted = function() {
    var groupId = this.$route.params.groupId;
    if (groupId != 0) {
        this.regist = false;
    }

    Vue.ELearning.Util.showOverlay(this.$store);
    this.refleshData();
};

logic.data = function() {
    return {
        groupId: '',
        groupName: '',
        groupText: '',

        // ダイアログ制御フラグ(ユーザー選択ダイアログ)
        userSelectorOpen: false,

        // ダイアログ制御フラグ(閲覧タスク選択ダイアログ)
        taskSelectorOpen: false,

        // グループユーザー情報(未紐付けのものも含む完全リスト)
        users: [],

        // グループタスク情報(未紐付けのものも含む完全リスト)
        categoryTasks: [],

        // ダイアログ開前に使用する一時保存領域
        // ダイアログが開かれる直前の状態を保持し
        // キャンセルボタン押下時に状態を巻き戻す
        oldUsers: [],
        oldCategoryTasks: [],

        errGroupName: false,
        groupNameErrors: [],

        regist: true,
    };
};

logic.computed = {
    title() {
        return `グループ${this.regist ? '登録' : '編集'}`;
    },
    submitLabel() {
        return `${this.regist ? '' : '上書き'}保存`;
    },
    hasSelectedTasks() {
        console.log("tasks", this.categoryTasks);
        return !this.categoryTasks.every(category =>
            category.tasks.every(task => !task.selected == 1)
        );
    },
    hasSelectedUsers() {
        return !this.users.every(user => !user.selected);
    },
};

logic.methods = {
    closeSelectTask() {
        this.taskSelectorOpen = false;
    },
    cancelSelectTask() {
        this.revertTask();
        this.taskSelectorOpen = false;
    },
    closeSelectUser() {
        this.userSelectorOpen = false;
    },
    cancelSelectUser() {
        this.revertUser();
        this.userSelectorOpen = false;
    },
    refleshData: function() {
        var groupId = this.$route.params.groupId;

        // グループ情報取得
        if (groupId > 0) {
            var handlerGroup = Vue.ELearning.Group.getGroup(groupId);

            handlerGroup
                .then(res => {
                    if (res.data.status_number == 200) {
                        var content = res.data.content;

                        //console.log("group", content);
                        this.groupName = content.group_name;
                        this.groupText = content.group_text;
                        this.groupId = content.group_id;
                    } else {
                        throw Error('グループ情報を取得できませんでした。');
                    }
                })
                .catch(res => {
                    console.error(res);
                });
        } else {
            this.groupId = 0;
        }

        this.getAllTasksByGroup();
    },

    getAllTasksByGroup: function() {
        var groupId = this.$route.params.groupId;

        var handler = Vue.ELearning.Group.getAllTasksByGroup(groupId);

        handler
            .then(response => {
                if (response.data.status_number == 200) {
                    var categories = response.data.content;

                    this.categoryTasks = [];

                    for (var key in categories) {
                        var item = categories[key];

                        this.categoryTasks.push({
                            categoryId: item['categoryId'],
                            categoryName: item['categoryName'],
                            isOpen: true,
                            tasks: item['tasks'],
                            selected: false,
                        });
                    }

                    //console.log(this.categoryTasks);

                    return Vue.ELearning.Group.getAllUsersByGroup(
                        groupId,
                        this.sortAxisUser,
                        this.sortOrderUser,
                    );

                    //this.isOpenGroupByTask = true;
                } else {
                    throw new Error('関連タスク情報を取得できませんでした');
                }
            })
            .then(response => {
                if (response.data.status_number == 200) {
                    var users = response.data.content;

                    this.users = [];

                    for (var key in users) {
                        var item = users[key];

                        this.users.push({
                            userId: item['user_id'],
                            userName: item['user_name'],
                            orgName: item['org_name'],
                            selected: item['admin'] === 1 ?
                                true : item['selected'] === 1,
                            admin: item['admin'] === 1,
                        });
                    }

                    console.log(this.users);

                    //return Vue.ELearning.Group.getAllUsersByGroup(groupId);

                    //this.isOpenGroupByTask = true;

                    Vue.ELearning.Util.hideOverlay(this.$store);
                } else {
                    throw new Error('関連ユーザー情報を取得できませんでした');
                }
            })
            .catch(response => {
                console.error(response);
                Vue.ELearning.Util.hideOverlay(this.$store);
            });
    },

    back: function() {
        this.$router.push('/admin/groups');
    },

    /**
     * グループ登録・更新
     */
    save: function() {
        console.log('グループ登録更新：');

        if (this.groupId == 0) {
            // 新規登録
            this.addGroup();
        } else {
            // 更新
            this.saveGroup();
        }
    },

    errorHandler: function(content) {
        if (content.errors == undefined) {
            return;
        }

        if (content.errors.group_name != undefined) {
            console.log('err-group-name');
            this.errGroupName = true;
            this.groupNameErrors = [];
            for (var key in content.errors.group_name) {
                var item = content.errors.group_name[key];
                this.groupNameErrors.push(item);
            }
        }
    },

    addGroup: function() {
        this.errGroupName = false;
        this.groupNameErrors = [];

        var handler = Vue.ELearning.Group.insertGroup({
                group_id: this.groupId,
                group_name: this.groupName,
                group_text: this.groupText,
            },
            this.categoryTasks,
            this.users,
        );

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(res => {
                if (res.data.status_number == 200) {
                    var content = res.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        console.error(content);

                        this.errorHandler(content);

                        Vue.ELearning.Util.hideOverlay(this.$store);
                        return;
                    }

                    Vue.ELearning.Util.hideOverlay(this.$store);
                    this.$router.push('/admin/groups');
                } else {
                    throw new Error('グループの登録に失敗しました。');
                }
            })
            .catch(res => {
                console.error(res);
                Vue.ELearning.Util.hideOverlay(this.$store);
                this.$router.push('/admin/groups');
            });
    },
    saveGroup: function() {
        this.errGroupName = false;
        this.groupNameErrors = [];

        //var handler = Vue.ELearning.Group.updateGroup(groupId);
        var handler = Vue.ELearning.Group.updateGroup({
                group_id: this.groupId,
                group_name: this.groupName,
                group_text: this.groupText,
            },
            this.categoryTasks,
            this.users,
        );

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(res => {
                if (res.data.status_number == 200) {
                    var content = res.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        console.error(content);

                        this.errorHandler(content);

                        Vue.ELearning.Util.hideOverlay(this.$store);
                        return;
                    }

                    Vue.ELearning.Util.hideOverlay(this.$store);
                    this.$router.push('/admin/groups');
                } else {
                    throw new Error('グループの登録に失敗しました。');
                }
            })
            .catch(res => {
                console.error(res);
                Vue.ELearning.Util.hideOverlay(this.$store);
                this.$router.push('/admin/groups');
            });
    },

    userSelectorOpener: function() {
        this.storeUser();

        this.userSelectorOpen = true;
    },
    taskSelectorOpener: function() {
        this.storeTask();

        this.taskSelectorOpen = true;
    },

    // =============================================================================================================
    // ダイアログキャンセル対応
    // ダイアログ開前の状態を保持し、キャンセルボタン押下時、元の状態を復元する
    // =============================================================================================================
    /**
     * グループタスク状態を保存
     */
    storeTask: function() {
        this.oldCategoryTasks = [];

        var clone = Vue.ELearning.Util.cloneObject(this.categoryTasks);

        console.log(clone);

        for (var key in clone) {
            this.oldCategoryTasks.push(clone[key]);
        }
    },

    /**
     * グループタスク状態を、ダイアログ開前(store以前)の状態に巻き戻す
     */
    revertTask: function() {
        this.categoryTasks = [];

        for (var key in this.oldCategoryTasks) {
            this.categoryTasks.push(this.oldCategoryTasks[key]);
        }
    },

    /**
     * グループユーザ状態を保存
     */
    storeUser: function() {
        this.oldUsers = [];

        var clone = Vue.ELearning.Util.cloneObject(this.users);

        for (var key in clone) {
            this.oldUsers.push(clone[key]);
        }
    },

    /**
     * グループユーザー情報を、ダイアログ開前(store以前)の状態に巻き戻す
     */
    revertUser: function() {
        this.users = [];

        for (var key in this.oldUsers) {
            this.users.push(this.oldUsers[key]);
        }
    },
};

export default logic;