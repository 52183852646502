var logic = {};

logic.created = function() {
    console.log('Initialize Admin/TagEdit');
    Vue.ELearning.Util.showOverlay(this.$store);
    if (this.$route.params.tagId > 0) {
        // 実際はサーバからデータを取得する。
        this.regist = false;

        var tagId = this.$route.params.tagId;

        var api = Vue.ELearning.Tag.getOne(tagId);

        api.then(
            function(res) {
                console.log(res);
                if (res.data.status_number == 200) {
                    var content = res.data.content;

                    this.tagId = content.tag_id;
                    this.tagName = content.tag_name;
                    Vue.ELearning.Util.hideOverlay(this.$store);
                } else {
                    this.$dialog.message("タグデータを取得できませんでした。");
                    //alert('タグデータを取得できませんでした。');
                }
            }.bind(this),
        ).catch(function(res) {
            console.error(res);
            Vue.ELearning.Util.hideOverlay(this.$store);
        });

        /*
        //this.taskId = 2;
        this.categoryName = 'タグ名０００００１';
        this.categoryDesc = 'タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明タグ説明';
        this.publicity = 'negative';
        */
    } else {
        this.tagName = '';
        /*this.categoryDesc = '';
        this.publicity = 'public';*/
        Vue.ELearning.Util.hideOverlay(this.$store);
    }
};

logic.data = function() {
    return {
        tagId: '',
        tagName: '',
        /*categoryDesc:'',
        publicity:'public',*/
        regist: true,
    };
};

logic.computed = {
    title() {
        return `タグ${this.regist ? '登録' : '編集'}`;
    },
    submitLabel() {
        return `${this.regist ? '' : '上書き'}保存`;
    },
};

logic.methods = {
    back: function() {
        this.$router.push('/admin/tags');
    },

    onCancel: function() {
        this.$router.push('/admin/tags');
    },

    onUpdate: function() {
        // 更新処理(API呼び出し)

        if (this.$route.params.tagId > 0) {
            // Update

            Vue.ELearning.Util.showOverlay(this.$store);
            var api = Vue.ELearning.Tag.updateTag({
                tag_id: this.$route.params.tagId,
                tag_name: this.tagName,
                /*categoryDesc: this.categoryDesc,
                publicity: this.publicity,*/
            });

            api.then(
                function(res) {
                    Vue.ELearning.Util.hideOverlay(this.$store);
                    if (typeof res.data.content.errors !== 'undefined' && res.data.content.errors != '') {
                        console.log("タグ更新エラー", res.data.content.errors);
                        this.$dialog.message("タグの更新に失敗しました。");
                    } else {
                        this.$router.push('/admin/tags');
                    }
                }.bind(this),
            ).catch(
                function(res) {
                    Vue.ELearning.Util.hideOverlay(this.$store);
                    if (typeof res.data.content.errors !== 'undefined' && res.data.content.errors != '') {
                        console.log("タグ更新エラー", res.data.content.errors);
                        this.$dialog.message("タグの更新に失敗しました。");
                    } else {
                        this.$router.push('/admin/tags');
                    }
                }.bind(this),
            );
        } else {
            // Insert

            Vue.ELearning.Util.showOverlay(this.$store);
            var api = Vue.ELearning.Tag.insertTag({
                tag_name: this.tagName,
                /*categoryDesc: this.categoryDesc,
                publicity: this.publicity,*/
            });

            api.then(
                function(res) {
                    Vue.ELearning.Util.hideOverlay(this.$store);
                    if (typeof res.data.content.errors !== 'undefined' && res.data.content.errors != '') {
                        console.log("タグ登録エラー", res.data.content.errors);
                        this.$dialog.message("タグの登録に失敗しました。");
                        //alert(res.data.content.errors);
                    } else {
                        this.$router.push('/admin/tags');
                    }
                }.bind(this),
            ).catch(
                function(res) {
                    Vue.ELearning.Util.hideOverlay(this.$store);
                    if (typeof res.data.content.errors !== 'undefined' && res.data.content.errors != '') {
                        console.log("タグ登録エラー", res.data.content.errors);
                        this.$dialog.message("タグの登録に失敗しました。");
                        //alert(res.data.content.errors);
                    } else {
                        this.$router.push('/admin/tags');
                    }
                }.bind(this),
            );
        }

        //this.$router.push("/admin/categories");
    },
};

export default logic;