<template>
  <div class="column sidebar">
    <nav class="side-navigation">
      <ul class="menu" v-if="is_admin">
        <li class="dashboard"><router-link to="/admin/logs" exact-active-class="active" >ダッシュボード</router-link></li>
        <li class="user"><router-link to="/admin/logs/users" active-class="active">ユーザー</router-link></li>
        <li class="contents"><router-link to="/admin/logs/contents" active-class="active">コンテンツ</router-link></li>
        <li class="task"><router-link to="/admin/logs/task_updates" active-class="active">タスク更新履歴</router-link></li>
        <li class="user-work"><router-link to="/admin/logs/work_procedures" active-class="active">ユーザー作業履歴</router-link>
          <div class="user-work-list" v-if="isShowUserworkUserlist && userworkUsers.length > 0">
            <div class="user-work-list-header">
              <p>ユーザー名</p>
              <a @click="$emit('sortUsers')" :class="{asc: userworkUsersSortAsc}"></a>               
            </div>
            <ul>
              <li v-for="(user, index) in userworkUsers" :key="index">
                <router-link :to="{ name: 'log-work-procedures-user', params: { user_id: user.user_id } }" active-class="active" :class="userNameClass( user.user_name )">{{ user.user_name }}</router-link>
              </li>
            </ul>
          </div>
        </li>

      </ul>
      <ul class="menu" v-if="! is_admin">
        <li class="user"><router-link :to="{ name: 'log-user', params: { user_id: user_id } }" active-class="active">ユーザー</router-link></li>
        <li class="user-work">
          <router-link :to="{ name: 'log-work-procedures-user', params: { user_id: user_id } }" active-class="active">ユーザー作業履歴</router-link>
        </li>
      </ul>
    </nav>
  </div><!-- /.sidebar -->
</template>

<script>

export default {
  props:{
    isShowUserworkUserlist:Boolean,
    userworkUsers:Array,
    userworkUsersSortAsc:Boolean,
  },
  data: () => {
    return {
    }
  },
  
  computed: {
    is_admin: function () { return this.$store.state.userInfo.grant >= 3; },
    user_id: function () { return this.$store.state.userInfo.userId; },
  },

  methods: {
    isLongName: function (user_name){ return (user_name.length > 20);},

    userNameClass: function ( user_name )
    {
      return {
        'small': user_name.length > 20,
      }
    },
  }
};

</script>

<style scoped lang="scss">

/* LAYOUT */
  .sidebar{
    width:200px;
    flex:none;
    border-right: 1px solid #cccccc;
    min-height:calc(100vh - 203.46666666666px);
    position: relative;
  }

  /* SIDEBAR */
  .sidebar .menu li{
    margin-bottom:1px;
    color:#333;
  }
  .sidebar .menu a{
    padding:1.035714285714286em 1em 1.035714285714286em 3.7em;
    font-size:0.875rem;
    display: block;
    background: #fff;
    color:inherit;
    background-repeat: no-repeat;
    background-position: center left 20px;
    transition: background-color 300ms;
  }
  .sidebar .menu li a.active{
    background-color: #e8f8e2;
    color:#4fbe2b;
  }
  .sidebar .menu li.dashboard a{
    background-image: url("~Image/menu-dashboard.svg");
    background-size:22px;
  }
  .sidebar .menu li.user a{
    background-image: url("~Image/menu-user.svg");
    background-size:22px;
  }
  .sidebar .menu li.contents a{
    background-image: url("~Image/menu-contents.svg");
    background-size:22px;
  }
  .sidebar .menu li.task a{
    background-image: url("~Image/menu-task.svg");
    background-size:22px;
  }
  .sidebar .menu li.user-work a{
    background-image: url("~Image/menu-work.svg");
    background-size:22px;
  }
/* selected */
  .sidebar .menu li.dashboard a.active{
    background-image: url("~Image/menu-dashboard-sel.svg");
    background-size:22px;

  }
  .sidebar .menu li.user a.active{
    background-image: url("~Image/menu-user-sel.svg");
    background-size:22px;
  }
  .sidebar .menu li.contents a.active{
    background-image: url("~Image/menu-contents-sel.svg");
    background-size:22px;
  }
  .sidebar .menu li.task a.active{
    background-image: url("~Image/menu-task-sel.svg");
    background-size:22px;
  }
  .sidebar .menu li.user-work a.active{
    background-image: url("~Image/menu-work-sel.svg");
    background-size:22px;
  }
  .sidebar .menu a:hover{
    background-color:#fafafa;
    text-decoration: none;
  }

.user-work-list {
  position: absolute;
  top: 0;
  left: calc(100% + 1px);
  z-index: 1;
  width: 202px;
  height: 100%;
  border-right: solid 1px #d9d9d9;
  background: #eeeeee;
  overflow-y: scroll;
}

.sidebar .menu .user-work-list .user-work-list-header{
  padding: 6px 13px 6px 20px;
  background: #fff;
  display: flex;
  align-items: center;
}
.sidebar .menu .user-work-list .user-work-list-header p{
  font-size: 12px;
  flex-grow: 1;
  color: #999;
}
.sidebar .menu .user-work-list .user-work-list-header a{
    flex-basis: 19px;
    width: 19px;
    height: 19px;
    background: url(~Image/sortarr-top-green.svg) no-repeat center;
    background-size: 19px;
    display: block;
    padding: 0;
}
.sidebar .menu .user-work-list .user-work-list-header a.asc{
    background: url(~Image/sortarr-bottom-green.svg) no-repeat center;
    background-size: 19px;
}

.sidebar .menu .user-work-list .user-work-list-header a:hover{
  opacity: 0.7;
}

.user-work-list ul {
  background: #e8f8e2;
}

.sidebar .menu .user-work-list li a {
  font-size: 14px;
  line-height: 1.4;
  font-weight: normal;
  padding: 16px 20px 15px 40px;
  position: relative;
  border-bottom: solid 1px white;
  color: #4fbe2b;
  display: block;
  background-color: #e8f8e2;
  background-image: url(/images/user-work-list-icon.png);
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-size: 13px 15px;
}

.sidebar .menu .user-work-list li a.small {
  font-size: 12px;
}

.sidebar .menu .user-work-list li:last-of-type a {
  border-bottom: none;
}

.sidebar .menu .user-work-list li a:hover {
  opacity: 0.6;
}
</style>
