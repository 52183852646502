var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": true,
      title: "よくある質問",
      "main-class": "faq_page"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "u-bg-yellow" }, [
              _c("div", { staticClass: "list c-list u-pt20" }, [
                _c("h3", { staticClass: "c-list__title" }, [_vm._v("まなぶ")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("採点結果がこない"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("p", [
                          _vm._v(
                            "返信は講師が行います。7日間返信がない場合は返金されます。"
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("購入したレッスンがなくなった"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("p", [
                          _vm._v(
                            "購入したレッスンの受講可能期間は180日間です。受講期間が終了すると、自動的にレッスンが消去されます。"
                          )
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("質問・採点依頼できない"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("p", [
                          _vm._v(
                            "講師の都合により受付できません。講師のレッスン運営については当サイトでは対応いたしません。"
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list c-list" }, [
                _c("h3", { staticClass: "c-list__title" }, [
                  _vm._v("おしえる")
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", { staticClass: "c-list__item" }, [
                    _c("a", { attrs: { href: "#" } }, [
                      _vm._v("振込スケジュール"),
                      _c("i", { staticClass: "fas fa-angle-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "item_contents c-list__item__contents" },
                      [
                        _c("p", [
                          _vm._v(
                            "\n                                振込は毎月〇〇日に行います。振込申請から〇営業日後以降、最も近い振込日に設定されます。営業日は土・日・祝、年末年始(12/28~1/5)を除く平日を指します。振込申請履歴は、アプリ、Webサイトの「振込申請履歴」からご確認いただけます。\n                            "
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }