import momemt from 'moment'

export default {
    install(Vue) {
        Vue.filter('moment', (date, format = 'YYYY/MM/DD') => {
            if (!date) return ''
            return momemt(date).format(format)
        });

        Vue.filter( "zero_pad", ( n, digits ) => {
            return ( new Intl.NumberFormat('ja', { minimumIntegerDigits: digits } ) ).format( n );
        })
    }
}
