<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="レッスン" main-class="lesson_page">
        <template v-slot:body v-if="lesson">
            <section class="lesson_header bg_yellow">
                <div class="container pt0 pb0">
                    <lesson-video-component :video="videoPath" :thumbnail="videoThumbnail"/>
                </div>
            </section>
            <section class="bg_yellow">
                <div class="container pt20">
                    <div class="description">
                        <div class="title">
                            <a :href="$libs.url(['lesson', lessonId, 'intro'])">
                                {{ lesson.title }}
                            </a>
                        </div>    
                        <h1>Step{{ stepIndex + 1 }}. {{ step.title }}</h1>
                        <div class="">
                            <p v-if="step.description">{{ step.description}}</p>
                            <div  v-if="step.notice" class="box_wh notice">
                                <p>{{ step.notice }}</p>
                            </div>
                            <template v-if="stepIndex < lesson.steps.length - 1">
                                <div class="next">
                                    <p>次のステップへ</p>
                                    <button class="" @click.stop.prevent="showModal=true">
                                        <p>
                                            <i class="fas fa-arrow-right"></i>
                                        </p>
                                    </button>
                                </div>
                            </template>
                            <template v-else>
                                <div class="next">
                                    <p>レッスン修了</p>
                                    <button class="" @click.stop.prevent="showModal=true">
                                        <p>
                                            <i class="fas fa-arrow-right"></i>
                                        </p>
                                    </button>
                                </div>
                            </template>                            
                        </div>
                    </div>
                    <div>
                        <h3 class="">レッスン</h3>
                        <div class="box_wh steps mb0">
                            <ul>
                                <template v-for="(item,index) in lesson.steps">
                                <li :key="index">
                                    <a :href="$libs.url(['lesson', lesson.id, index])"  :class="{complete: item.progress.length > 0}" >
                                        <p class="step">
                                            <i v-if="item.progress.length > 0 && item.progress[0].status == 1" class="fas fa-check"></i>
                                            Step{{ index + 1 }}
                                        </p>
                                        <p>{{ item.title }}</p>
                                        <i class="fas fa-angle-right"></i>
                                    </a>
                                </li>
                                </template>
                            </ul>
                        </div>
                        <p class="bold mb60">
                            <a :href="$libs.url(['lesson', lesson.id, 'intro'])">
                                トップに戻る
                            </a>
                        </p>
                    </div>
                </div>
            </section>
        <div v-if="showModal" class="modal step_doit" @click="showModal = false">
                <div class="container">
                    <div class="box_wh">
                        <h3>できましたか？</h3>
                        <div class="flex_nw">
                            <div class="item">
                                <button @click="onStepComplete(1)"><img src="/images/miso/lesson_step_ok.png" alt=""></button>
                                <p>できた</p>
                            </div>
                            <div class="item">
                                <button @click="onStepComplete(2)"><img src="/images/miso/lesson_step_no.png" alt=""></button>
                                <p>うまくできなかった</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';
    import LessonVideoComponent from '../../miso/user/LessonVideoComponent.vue';
    import LessonDescriptionComponent from '../../miso/user/LessonDescriptionComponent.vue';
    import LevelComponent from '../../miso/commons/LevelComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonDescriptionComponent,
            LessonVideoComponent, 
            LevelComponent,
        },
        props: {
            lessonId: {
                type: Number,
            },
            stepIndex: {
                type: Number,
            },            
        },
        data: function () {
            return {
                lesson:null,
                showModal:false,
            }
        },
        computed: {
            step:function(){
                return this.lesson.steps[this.stepIndex]
            },
            partName: function(){
                return this.$config.name("parts",this.lesson.part)
            },
            genreName: function(){
                return this.$config.name("genres",this.lesson.genre)
            },
            videoPath: function(){
                if(this.step.video && this.step.video.video_path){
                    return this.step.video.video_path
                }
                return null
            },
            videoThumbnail: function(){
                if(this.step.video && this.step.video.thumbnail_path){
                    return this.step.video.thumbnail_path
                }
                return null
            },

        },
        mounted:function(){

            //console.log("lesson, step", this.lessonId, this.stepId)
            this.getData()

        },
        methods:{
            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTask(this.lessonId);
                taskHandler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            this.lesson = res.data.content;

                            //生徒で未購入の場合は紹介TOPへリダイレクト
                            if(this.$store.state.userInfo.grant == 1 && this.lesson.purchases.length == 0){
                                this.$libs.pushUrl(["lesson",this.lessonId])
                                return
                            }

                            //講師で自分のレッスンでない場合は紹介TOPへリダイレクト
                            if(this.$store.state.userInfo.grant == 2 && this.lesson.user_id != this.$store.state.userInfo.id){
                                this.$libs.pushUrl(["lesson",this.lessonId])
                                return
                            }

                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },

            search: function(){
            },
            iconImage: function (path) {
                if (path) {
                    return "background: url(" + path + ") center center / cover";
                } else {
                    return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
                }
            },
            onStepComplete: function(status) {
                this.showModal = false;

                //生徒は進捗を投稿する
                if(this.$store.state.userInfo.grant == 1){        
                    this.postProgress(status,(res) =>{
                        
                        if(res.status=="NG"){
                            this.$dialog.message("エラーが発生しました");
                        }

                        if(this.stepIndex < this.lesson.steps.length - 1){
                            this.$libs.pushUrl(["lesson",this.lessonId, this.stepIndex + 1])
                        }else{
                            //
                        }
                    })
                }else{
                    if(this.stepIndex < this.lesson.steps.length - 1){
                        this.$libs.pushUrl(["lesson",this.lessonId, this.stepIndex + 1])
                    }
                }
            },

            postProgress: function(status, callback){
                const target = {
                    work_procedure_id: this.step.id,
                    status: status,
                };

                this.step.progress = [target];
                console.log("target :", target);
                // 進捗更新処理
                    const handler = Vue.ELearning.Progress.store(target);
                    handler
                    .then(res => {
                        callback({ status: 'OK', response: res });
                    })
                    .catch(res => {
                        callback({ status: 'NG', response: res });
                    });

                    /*
                    // タスクが完了したことをログに記録する
                    if (complete) {
                        // console.log( "task complete" );
                        Vue.ELearning.Logs.logTaskComplete(this.lesson.id).then();
                    }
                    */
            },

            onNaviBack: function(){

                if(this.lesson){
                    this.$libs.pushUrl(["lesson",this.lessonId, "intro"])
                }else{
                    history.back()
                }
            },
         
        }
    }
</script>
<style lang="scss" scoped>
</style>
