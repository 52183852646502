/**
 * なるほどーがで簡単にダイアログを表示するための機能を追加する Vue プラグイン
 * 
 * コンポーネントで以下のようにダイアログを表示させる事ができるようになる。
 * 
 * this.$dialog.message( "メッセージ" )
 * this.$dialog.message( { title: "タイトル", "メッセージ" } )
 */

import DialogComponent from '../components/miso/commons/DialogComponent';

export default {
    install(Vue, options) {

        Vue.prototype.$dialog = {

            /**
             * メッセージを表示するダイアログを表示する
             * 
             * @param {string|object} "メッセージ" または { title: "タイトル", message: "メッセージ" }
             */
            message: function(data) {
                if (typeof data === "string") {
                    data = {
                        title: data,
                        message: null,
                    };
                }

                let vue = Vue.extend(DialogComponent);
                let dialog = new vue({
                    data: data
                });
                dialog.show();
            }
        };

        // Vue.$dialog でも使えるようにする
        Vue.$dialog = Vue.prototype.$dialog;
    }
}