<template>
  <common-layout-component :show-back="false" :show-navi-bar="true" title="新規登録" main-class="register_page">
    <template v-slot:body>
      <div>
      <section v-if="!registerd" class="bg_yellow">
        <div class="container pt50">
          <div class="caption">
            <h1 v-if="!teacher">新規登録</h1>
            <h1 v-if="teacher">新規登録（講師）</h1>
            <p>
              情報をご入力後、利用規約をお読みになり、<br />
              「登録」ボタンを押してください。
            </p>
          </div>
          <div class="box bg_white">
            <div class="item">
              <p>お名前（ニックネーム）</p>
              <input
                type="text"
                name="name"
                placeholder=""
                v-model="userName"
                :class="{error:errUserName}"
              />
              <p class="error_description" v-if="errUserName">{{ userNameErrors.join(" ") }}</p>
            </div>
            <div class="item">
              <p>メールアドレス</p>
              <input type="text" name="email" placeholder="" v-model="email" :class="{error:errMailAddress}"/>
              <p class="error_description" v-if="errMailAddress">{{ mailAddressErrors.join(" ") }}</p>
            </div>
            <div class="item">
              <p>パスワード</p>
              <input
                type="password"
                name="password"
                placeholder=""
                v-model="password"
                :class="{error:errPassword}"
              />
              <p class="error_description" v-if="passwordErrors">{{ passwordErrors.join(" ") }}</p>
            </div>

            <div class="item">
              <label>
                <input id="terms" type="checkbox" v-model="terms">
                <label for="terms">利用規約と個人情報保護方針に同意する</label>
              </label>
              <p class="error_description" v-if="errTerms">未チェックです</p>
            </div>
            <button class="mt20 button md" @click="register">登録</button>
          </div>
          <div class="other_buttons">
            <p v-if="!teacher"><a :href="$libs.url(['teacher','register'])">講師になりたい方はこちら<i class="fas fa-angle-right"></i></a></p>
            <p v-if="teacher"><a :href="$libs.url('register')">受講したい方はこちら<i class="fas fa-angle-right"></i></a></p>

            <div class="inner">
                <button class="login button md" @click="$libs.pushUrl('login')">登録済みの方はログイン</button>
                <a :href="$libs.url('')" class="icon">みそしる教室 トップへ<i class="fas fa-angle-right"></i></a>
            </div>
          </div>
        </div>
      </section>

      <section v-if="registerd" class="bg_yellow">
        <div class="container pt50">
          <div class="caption">
            <h1>新規登録完了</h1>
            <p>
              ありがとうございます。<br />
              登録が完了しました。
            </p>
          </div>
          <div class="box bg_white">
            <div class="item">
            <button
              class="button md"
              @click="$libs.pushUrl('mypage','edit')"
            >
              ユーザー設定をする
            </button>
            <p class="skip"><a :href="$libs.url('')">ユーザー設定をスキップして始める<i class="fas fa-angle-right"></i></a></p>
            </div>
          </div>
        </div>
      </section>
      </div>
    </template>
  </common-layout-component>
</template>

<script>
import CommonLayoutComponent from "../../miso/commons/CommonLayoutComponent.vue";
import ModalComponent from "../../commons/ModalComponent.vue";

export default {
  components: {
    CommonLayoutComponent,
    ModalComponent,
  },
  props: {
      teacher: {
        type: Boolean,
      },
    },
  data: function () {
    return {
      userId: "",
      userName: "",
      email: "",
      password: "",
      terms:false,

      errUserName: false,
      errMailAddress: false,
      errPassword: false,
      errTerms: false,

      userNameErrors: [],
      mailAddressErrors: [],
      passwordErrors: [],

      registerd: false,
    };
  },
  created: function () {},

  mounted: function () {},

  methods: {
    errorHandler: function (content) {
      if (content.errors == undefined) {
          console.log("err");
        return;
      }
      if (content.errors.display_name != undefined) {
        console.log("err-user-name");
        this.errUserName = true;

        this.userNameErrors = [];
        for (var key in content.errors.display_name) {
          var item = content.errors.display_name[key];
          this.userNameErrors.push(item);
        }
      }
      if (content.errors.mail_address != undefined) {
        console.log("err-mail_address");
        this.errMailAddress = true;

        this.mailAddressErrors = [];
        for (var key in content.errors.mail_address) {
          var item = content.errors.mail_address[key];
          this.mailAddressErrors.push(item);
        }
      }
      if (content.errors.password != undefined) {
        console.log("err-password");
        this.errPassword = true;

        this.passwordErrors = [];
        for (var key in content.errors.password) {
          var item = content.errors.password[key];
          this.passwordErrors.push(item);
        }
      }
    },

    register: function () {
      var handler = null;

      this.errUserName = false;
      this.errMailAddress = false;
      this.errPassword = false;
      this.userNameErrors = [];
      this.mailAddressErrors = [];
      this.passwordErrors = [];

      this.errTerms = !this.terms;
        if(this.errTerms){
            return;
        }

      Promise.resolve()
      .then(() => {
          //新規登録
          return Vue.ELearning.UserInfo.insert(
            {
              display_name: this.userName,
              mail_address: this.email,
              password: this.password,
              grant_id: this.teacher? 2 : 1,
            },
        )
      }).then((response) => {
          console.log(response);
          if (response.status == 200) {
            var content = response.data.content;
            if (content.result == "NG") {
              // バリデーションエラー
              //console.error(content);
              this.errorHandler(content);
              return false;
            }else{
                //認証
                return Vue.ELearning.Authenticatior.authenticate(this.email,this.password,false)
            }

          } else {
            throw new Error("新規登録に失敗しました。1");
          }
        }).then((response) => {
          if (response && response.status == 200) {
            var content = response.data.content;
            if (response.data.status_number != 200) {
              // エラー
              console.error("認証に失敗しました。2");
              return;
            }
            console.log("user logged in : ", content);

            try {
                this.$store.commit("login", {
                    'id': content.id,
                    'userName': content.display_name,
                    'grant': content.user_grant,
                    'initialPassword': content.initial_password,
                    'authToken': content["auth-token"],
                    'remember': this.remember
                });

                this.registerd = true;
                
            } catch (e) {
                    console.error(e);
            }


            this.registerd = true;

          } else {
            throw new Error("新規登録に失敗しました。3");
          }
        })
        .catch((response) => {
          console.error(response);
        });
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
</style>
