<template>
  <div class="l-tabbar c-tabbar">
    <slot name="tabaction"></slot>
    <div class="c-container">
      <div class="c-tabbar__inner">
        <div class="c-tabbar__item" :class="{ 'is-current': isCurrent('/') }">
          <a :href="$libs.url('')"
            ><i class="fas fa-home"></i>
            <p>ホーム</p>
          </a>
        </div>
        <div class="c-tabbar__item" :class="{ 'is-current': isCurrent('/search') }">
          <a :href="$libs.url('search')"
            ><i class="fas fa-search"></i>
            <p>探す</p>
          </a>
        </div>
        <div class="c-tabbar__item" :class="{ 'is-current': isCurrent('/mylesson') }">
          <a :href="$libs.url('mylesson')"
            ><i class="far fa-check-circle"></i>
            <p>マイレッスン</p>
          </a>
        </div>
        <div class="c-tabbar__item" :class="{ 'is-current': isCurrent('/notice') }">
          <a :href="$libs.url('notice')"
            ><i class="fas fa-bell"></i>
            <p>お知らせ</p>
          </a>
        </div>
        <div class="c-tabbar__item" :class="{ 'is-current': isCurrent('/mypage') }">
          <a :href="$libs.url('mypage')"
            ><img src="/images/footer_icon_user.png" />
            <p>マイページ</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {},

  data: function () {
    return {};
  },

  mounted: function () {},
  methods: {
    isCurrent: function (_path) {
      const path = location.pathname;
      if (_path == "/") {
        if (_path == path) {
          return true;
        } else {
          return false;
        }
      }
      if (path.startsWith(_path)) {
        return true;
      }

      return false;
    },
  },

  computed: {},

  watch: {},
};
</script>

<style scoped lang="scss">
</style>
