var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teacher" }, [
    _c("a", { attrs: { href: _vm.$libs.url(["teacher", _vm.user.id]) } }, [
      _c("div", { staticClass: "flex_nw" }, [
        _c("div", { staticClass: "icon" }, [
          _c("p", { style: _vm.iconImage(_vm.user.image) })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _c("p", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.user.display_name))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.profile))
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "categories flex" },
            [
              _vm._l(_vm.partNames, function(item, index) {
                return [
                  _c("div", { key: index, staticClass: "cat" }, [
                    _vm._v(_vm._s(item))
                  ])
                ]
              })
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }