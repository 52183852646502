<template>
  <div class="teacher">
    <a :href="$libs.url(['teacher', user.id])">
      <div class="flex_nw">
        <div class="icon">
          <p :style="iconImage(user.image)"></p>
        </div>
        <div class="right">
          <p class="name">{{ user.display_name }}</p>
          <p class="description">{{ profile }}</p>
          <div class="categories flex">
            <template v-for="(item, index) in partNames">
              <div class="cat" :key="index">{{ item }}</div>
            </template>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    user: Object,
  },
  data: function () {
    return {};
  },
  mounted: function () {},
  computed: {
    profile: function () {
      if (this.user && this.user.meta && this.user.meta.profile) {
        return this.user.meta.profile; 
      }
      return "";
    },
    partNames: function () {
      if (this.user && this.user.meta && this.user.meta.part) {
        return this.$config.names("parts", this.user.meta.part.split(","));
      }
      return [];
    },
  },
  methods: {
    iconImage: function (path) {
      if (path) {
        return "background: url(" + path + ") center center / cover";
      } else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
