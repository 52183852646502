var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      title: "検索結果",
      "main-class": "search_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.result
          ? {
              key: "body",
              fn: function() {
                return [
                  _c(
                    "section",
                    {
                      staticClass: "result",
                      class: {
                        show_filter: _vm.showFilter,
                        bg_white: !_vm.isSearchTypeLesson
                      }
                    },
                    [
                      _c("div", { staticClass: "container pt20 pb20" }, [
                        _c(
                          "div",
                          { staticClass: "page_header result_header" },
                          [
                            _c("div", [
                              _c("p", { staticClass: "result_num" }, [
                                _c("span", [_vm._v(_vm._s(_vm.count))]),
                                _vm._v("件")
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "plane filter",
                                on: {
                                  click: function($event) {
                                    _vm.showFilter = true
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-filter" }),
                                _vm._v("絞り込み")
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isSearchTypeLesson
                          ? _c(
                              "div",
                              { staticClass: "lessons" },
                              [
                                _vm._l(_vm.result, function(item, index) {
                                  return [
                                    _c("lesson-cassette-component", {
                                      key: index,
                                      attrs: { lesson: item, showPrice: true }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isSearchTypeLesson
                          ? _c(
                              "div",
                              { staticClass: "teachers" },
                              [
                                _vm._l(_vm.result, function(item, index) {
                                  return [
                                    _c("user-cassette-component", {
                                      key: index,
                                      attrs: { user: item }
                                    })
                                  ]
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "filter_box" },
                        [
                          _c(
                            "transition",
                            { attrs: { name: "show_filter_bg" } },
                            [
                              _vm.showFilter
                                ? _c("div", {
                                    staticClass: "bg",
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        _vm.showFilter = false
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "show_filter_inner" } },
                            [
                              _vm.showFilter
                                ? _c(
                                    "div",
                                    { staticClass: "inner pt20" },
                                    [
                                      _c("search-form-component", {
                                        attrs: {
                                          "is-search-type-lesson":
                                            _vm.isSearchTypeLesson
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }