<template>
  <common-layout-component2 show-back="true" show-admin="true" @on-nav-back="$router.push( { name: 'log-index' } )">
    <template v-slot:nav-message>
      <p>閲覧ログ - ユーザー</p>
    </template>

    <template v-slot:body>
      <div class="columns is-gapless logs">
        <log-side-bar />

        <div class="column main-column">
          <div class="main-column-inner">
            <log-period-input :period_begin.sync="period_begin" :period_end.sync="period_end" v-on:period-updated="on_period_updated" />

            <div class="row board-container board-container01">
              <div class="col-12">
                <div class="board">
                  <div class="board-header has-side-button">
                    <h2 class="board-title icon-user title-lg">ログイン回数</h2>
                    <div class="download-button"><a href="#" v-on:click.prevent.stop.prevent="download_top_authorized_users_csv">CSVダウンロード</a></div>
                  </div>
                  <div class="board-body">
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic3 color02 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_authorized_users_more }">
                          <tbody>
                            <tr v-for="( user, n ) in top_authorized_users" :key="user.user_id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td><router-link :to="{ name: 'log-user', params: { user_id: user.user_id } }">{{ user.user_name }}</router-link></td>
                              <td>{{ user.count }}回</td>
                            </tr> 
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_authorized_users_more" :list="top_authorized_users" :limit="3" color="color02"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.board-body -->
                </div><!-- /.board -->
              </div><!-- /.col-12 -->
            </div><!-- /.board-container01 -->

            <div class="row board-container board-container02">
              <div class="col-md-6">
                <div class="board">
                  <div class="board-header has-side-button">
                    <h2 class="board-title icon-video title-lg">動画再生回数</h2>
                    <div class="download-button"><a href="#" v-on:click.stop.prevent="download_top_watched_users_csv">CSVダウンロード</a></div>
                  </div>
                  <div class="board-body">
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic3 color03 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_watched_users_more }">
                          <tbody>
                            <tr v-for="( user, n ) in top_watched_users" :key="user.user_id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td><router-link :to="{ name: 'log-user', params: { user_id: user.user_id } }">{{ user.user_name }}</router-link></td>
                              <td>{{ user.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_watched_users_more" :list="top_watched_users" :limit="3" color="color03"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.board-body -->
                </div><!-- /.board -->
              </div><!-- /.col-md-6 -->
              
              <div class="col-md-6">
                <div class="board">
                  <div class="board-header has-side-button">
                    <h2 class="board-title icon-task title-lg">完了タスク数</h2>
                    <div class="download-button"><a href="#" v-on:click.stop.prevent="download_top_completed_users_csv">CSVダウンロード</a></div>
                  </div>
                  <div class="board-body">
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic3 color01 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_completed_users_more }">
                          <tbody>
                            <tr v-for="( user, n ) in top_completed_users" :key="user.user_id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td><router-link :to="{ name: 'log-user', params: { user_id: user.user_id } }">{{ user.user_name }}</router-link></td>
                              <td>{{ user.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_completed_users_more" :list="top_completed_users" :limit="3" color="color01"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.board-body -->
                </div><!-- /.board -->
              </div><!-- /.col-md-6 -->
            </div><!-- /.board-container03 -->

          </div><!-- /.main-column -->
        </div><!-- /.column -->
      </div>
    </template>
  </common-layout-component2>
</template>
<script>

import "Sass/logs.scss"

import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import LogSideBar from './components/LogSideBar';
import LogPeriodInput from './components/LogPeriodInput';
import MoreButton from './components/MoreButton';

import moment from 'moment';

export default {
  components: {
      CommonLayoutComponent2,
      LogSideBar,
      LogPeriodInput,
      MoreButton,
  },
  
  data: () => { return {
    period_begin: "",                 // 期間 ( 開始 )
    period_end: "",                   // 期間 ( 終了 )

    top_authorized_users: [],         // ログイン回数が多いユーザーの一覧
    top_watched_users: [],            // 動画再生回数が多いユーザーの一覧
    top_completed_users: [],          // 完了タスク数が多いユーザーの一覧

    // more
    is_top_authorized_users_more: false,
    is_top_watched_users_more: false,
    is_top_completed_users_more: false,
  } },

  computed: {

  },

  mounted () {
    Vue.ELearning.Logs.init();
  },

  methods: {
    /**
     * 期間が変更された時の処理
     */
    on_period_updated: function ( begin, end ) {
      // alert( 'A : ' + begin + ' ... ' + end );
      // alert( 'B : ' + this.period_begin + ' ... ' + this.period_end );

      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopAuthorizedUsers( begin, end ), ( data ) => { this.top_authorized_users = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopWatchedUsers( begin, end ), ( data ) => { this.top_watched_users = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopCompletedUsers( begin, end ), ( data ) => { this.top_completed_users = data; } );
    },

    download_top_authorized_users_csv: function()
    {
      // this.$router.push( '/admin/logs/top_authorized_users/csv', { params: { begin: this.period_begin, end: this.period_end } } );
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopAuthorizedUsersCsv( this.period_begin, this.period_end ), "top_authorized_users.csv" );
    },

    download_top_watched_users_csv: function()
    {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopWatchedUsersCsv( this.period_begin, this.period_end ), "top_watched_users.csv" );
    },

    download_top_completed_users_csv: function()
    {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopCompletedUsersCsv( this.period_begin, this.period_end ), "top_completed_users.csv" );
    },
  },
};

</script>
