var logic = {};

logic.mounted = function() {
    //console.log("Initialize Admin/Users");

    var w = jQuery('#table000').width();
    //console.log(w);

    this.tableWidth = w;

    this.refleshData();
};

logic.methods = {
    onSearch: function(val) {
        //console.log("search", val.value);

        this.keyword = val.value;
        /*this.keyword = val.searchText;*/

        this.refleshData();
    },

    onSort: function(axis) {
        if (this.axis == axis) {
            if (this.order == 'asc') {
                this.order = 'desc';
            } else {
                this.order = 'asc';
            }
        } else {
            this.order = 'asc';
        }
        this.axis = axis;

        //console.log(this.axis, this.order);

        this.refleshData();
    },

    onCheckStateChange: function(user) {
        user.selected = !user.selected;
        //console.log(user);
    },

    groupsOpen: function(user) {
        if (user.isGroupsOpen) {
            return 'close';
        } else {
            return 'open';
        }
    },

    gotoRegist: function() {

        if (this.isOverUserNum()) {
            this.$dialog.message("ご契約のユーザー数を超えるため登録できません。");
            return;
        }

        this.$router.push({
            path: '/admin/user/0',
        });
    },

    gotoEdit: function() {
        for (var key in this.users) {
            var item = this.users[key];
            if (item.selected) {
                var userId = item.userId;

                //console.log(userId);

                this.$router.push({
                    path: '/admin/user/' + userId,
                });
            }
        }
    },

    refleshData: function() {
        Vue.ELearning.Util.showOverlay(this.$store);
        //var handler = Vue.ELearning.UserInfo.all();
        var handler = Vue.ELearning.UserInfo.search(
            this.keyword,
            this.axis,
            this.order,
        );

        handler
            .then(res => {
                //console.log(res);

                if (res.data.status_number == 200) {
                    var records = res.data.content;

                    var requests = [];

                    this.users = [];

                    //ユーザ数を更新する（追加/削除を行った場合用）
                    if (records) {
                        const adminSum = records.filter(record => record.grant_id >= 3).length;

                        //appInfoを更新
                        var appInfo = this.$store.state.appInfo;
                        appInfo.userSum = records.length;
                        appInfo.adminSum = adminSum;
                        this.$store.commit('appInfo', appInfo);
                    }

                    const getGrantName = id => {
                        if (id == 1) {
                            return '閲覧';
                        } else if (id == 2) {
                            return '編集';
                        } else if (id >= 3) {
                            return '管理';
                        } else {
                            return '不明';
                        }
                    };

                    for (var key in records) {
                        var item = records[key];

                        this.users.push({
                            userId: item['user_id'],
                            userName: item['user_name'],
                            mailAddress: item['mail_address'],
                            orgName: item['org_name'],
                            grantName: getGrantName(item['grant_id']),
                            groups: [],

                            selected: false,
                            enabled: true,
                            isGroupsOpen: false,
                        });

                        var aHandler = Vue.ELearning.Assignment.getGroupsByUser(
                            item['user_id'],
                        );
                        requests.push(aHandler);
                    }

                    //console.log(requests);
                    var all = Promise.all(requests);
                    //console.log("promise-all", all);
                    return all;
                } else {
                    throw new Error('APIエラー');
                }
            })
            .then(res => {
                //throw new Error("APIエラー");
                for (var key in res) {
                    var response = res[key];

                    if (response.data.status_number == 200) {
                        var content = response.data.content;

                        for (var groupKey in content) {
                            var group = content[groupKey];

                            for (var userKey in this.users) {
                                var user = this.users[userKey];
                                if (group.user_id == user.userId) {
                                    user.groups.push(group);
                                }
                            }
                        }
                    }
                }

                //console.log("users", this.users);
                Vue.ELearning.Util.hideOverlay(this.$store);
            })
            .catch(res => {
                console.error('error:', res);
                Vue.ELearning.Util.hideOverlay(this.$store);
            });
    },

    back: function() {
        this.$router.push({
            path: '/admin/menu/',
        });
    },

    onIndicatorClick(val) {
        this.selected = val;
    },

    execDelete: function() {
        //console.log("削除します:", this.selectedUsers);

        var deleteHandler = [];
        for (var key in this.selectedUsers) {
            var item = this.selectedUsers[key];

            var handler = Vue.ELearning.UserInfo.delete(item.userId);

            deleteHandler.push(handler);
        }

        Promise.all(deleteHandler)
            .then(response => {
                //console.log(response);

                this.refleshData();

                this.showModalDelete = false;
            })
            .catch(response => {
                console.error('ユーザーの削除に失敗しました:', response);

                this.showModalDelete = false;
            });
    },

    importUsr: function() {
        console.log("********** import User");
        this.$el.querySelector('#input-file-csv').click();
    },

    selectedUploadCsv(e) {
        var files = e.target.files;
        const csv = files[0];

        let formData = new FormData();
        formData.append('csv', csv);

        const handler = Vue.ELearning.UserInfo.postUserCsv(formData)

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then((res) => {
                const content = res.data.content;
                if (content.result == "NG") {
                    //エラー
                    const key = Object.keys(content.errors)[0];
                    var message = "インポートに失敗しました";
                    message = message + "。" + content.errors[key].join(" ");
                    this.$dialog.message(message);
                }

                Vue.ELearning.Util.hideOverlay(this.$store)
                this.refleshData()

                e.target.value = "";
            })
            .catch(err => {
                console.log("error", err);
                this.$dialog.message("インポートに失敗しました");
                Vue.ELearning.Util.hideOverlay(this.$store);

                e.target.value = "";
            })
    },

    exportUsr: function() {
        console.log("********** export User");

        var handler = Vue.ELearning.UserInfo.getUserCsv(
            this.keyword,
            this.axis,
            this.order
        );

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(response => {
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'userlist.csv');
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                Vue.ELearning.Util.hideOverlay(this.$store)
            })
            .catch(response => {
                console.log(response)
                    //alert('エクスポートに失敗しました');
                this.$dialog.message("エクスポートに失敗しました");
                Vue.ELearning.Util.hideOverlay(this.$store)
            });
    },

    clearConditions() {
        this.keyword = '';

        this.refleshData();
    },

    isOverUserNum() {
        var max_user = this.$store.state.appInfo.maxNumUser;
        var now_user = this.$store.state.appInfo.userSum;
        if (now_user >= max_user) {
            return true;
        } else {
            return false;
        }
    },
};


logic.computed = {
    selectedUsers: function() {
        var users = [];

        for (var key in this.users) {
            var user = this.users[key];

            if (user.selected == true) {
                users.push(user);
            }
        }

        return users;
    },

    openClose: function() {
        if (this.selected) {
            return 'open';
        } else {
            return 'close';
        }
    },

    editButtonEnabled() {
        var selectCount = 0;

        for (var item in this.users) {
            if (this.users[item].selected && this.users[item].enabled) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if (selectCount == 0) {
            return true;
        }
        if (selectCount == 1) {
            return false;
        }
        return true;
    },

    deleteButtonEnabled() {
        var selectCount = 0;
        var adminSelectCount = 0;

        for (var item in this.users) {
            if (this.users[item].selected && this.users[item].enabled) {
                selectCount++;
                if (this.users[item].grantName === '管理') {
                    adminSelectCount++;
                }
            }
        }

        //console.log(selectCount);

        if (selectCount == 0 || adminSelectCount >= this.currentAdminSum) {
            return true;
        } else if (selectCount >= 1) {
            return false;
        }

    },

    currentAdminSum() {
        return this.$store.state.appInfo.adminSum;
    }
};

logic.data = function() {
    return {
        tableWidth: 0,
        selected: true,
        showModalDelete: false,
        axis: 'user_name_kana',
        order: 'asc',
        keyword: '',

        users: [],

        uploadCsvFile: null,
    };
};

export default logic;