var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      title: "レッスン",
      "main-class": "lesson_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.lesson
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "lesson_header bg_yellow" }, [
                    _c(
                      "div",
                      { staticClass: "container pt0 pb0" },
                      [
                        _c("lesson-video-component", {
                          attrs: { video: _vm.videoPath }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "bg_yellow" }, [
                    _c(
                      "div",
                      { staticClass: "container pt20" },
                      [
                        _c("div", { staticClass: "user_teacher flex" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$libs.url([
                                  "teacher",
                                  _vm.lesson.user_id
                                ])
                              }
                            },
                            [
                              _c("div", { staticClass: "icon" }, [
                                _c("p", {
                                  style: _vm.iconImage(_vm.lesson.user.image)
                                })
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "name" }, [
                            _c("p", [_vm._v("講師")]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.$libs.url([
                                    "teacher",
                                    _vm.lesson.user_id
                                  ])
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.lesson.user.display_name) +
                                    "\n                        "
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "description" }, [
                          _c(
                            "div",
                            { staticClass: "categories flex" },
                            [
                              _c("div", { staticClass: "cat no_border" }, [
                                _vm._v(_vm._s(_vm.partName))
                              ]),
                              _vm._v(" "),
                              _c("level-component", {
                                attrs: { level: _vm.lesson.level }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("h1", [_vm._v(_vm._s(_vm.lesson.title))])
                        ]),
                        _vm._v(" "),
                        _c("lesson-description-component", {
                          attrs: {
                            lesson: _vm.lesson,
                            "show-toggle-button": false
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  !_vm.$store.state.isLoggedIn ||
                  _vm.$store.state.userInfo.grant < 2
                    ? _c("section", { staticClass: "purchase" }, [
                        _c("div", { staticClass: "container pt10 pb10" }, [
                          _c("div", { staticClass: "flex_nw" }, [
                            _c("div", { staticClass: "price" }, [
                              _c("span", [_vm._v("レッスン料")]),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.lesson.price.toLocaleString()) +
                                  "円\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "round",
                                on: {
                                  click: function($event) {
                                    _vm.showModalPurchase = true
                                  }
                                }
                              },
                              [_vm._v("購入する")]
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showModalPurchase
                    ? _c("modal-component", {
                        attrs: {
                          title: "このレッスンを購入しますか？",
                          "view-selectable": true
                        },
                        on: {
                          yes: _vm.purchase,
                          no: function($event) {
                            _vm.showModalPurchase = false
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showModalPurchased
                    ? _c("modal-component", {
                        attrs: {
                          title: "ご購入ありがとうございます！",
                          "view-plain": true,
                          ok: "レッスンへ"
                        },
                        on: {
                          ok: function($event) {
                            return _vm.$libs.pushUrl([
                              "lesson",
                              _vm.lessonId,
                              "intro"
                            ])
                          }
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }