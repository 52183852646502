var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [
            _c("span", { staticClass: "nav-msg-sub sp-hidden" }, [
              _vm._v(_vm._s(_vm.categoryName))
            ]),
            _vm._v("\n        " + _vm._s(_vm.taskName) + "\n    ")
          ]
        },
        proxy: true
      },
      {
        key: "nav-ctrl",
        fn: function() {
          return [
            _c("div", { staticClass: "sp-hidden" }, [
              _c("div", { staticClass: "task-volume" }, [
                _c("p", [
                  _vm._v("タスク容量："),
                  _c("span", [_vm._v(_vm._s(_vm.taskSize) + " MB")])
                ]),
                _vm._v(" "),
                _vm.isOnline
                  ? _c(
                      "button",
                      {
                        staticClass: "button basic",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.downloadTask.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("タスクをダウンロード")]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pc-hidden" }, [
              !_vm.isSpDetailAllOpened
                ? _c(
                    "button",
                    {
                      staticClass: "btn-allOpen",
                      on: { click: _vm.spDetailAllOpen }
                    },
                    [_vm._v("すべて開く")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isSpDetailAllOpened
                ? _c(
                    "button",
                    {
                      staticClass: "btn-allOpen btn-allClose",
                      on: { click: _vm.spDetailAllOpen }
                    },
                    [_vm._v("すべて閉じる")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _c("span", [_vm._v(_vm._s(_vm.taskSize) + " MB")]),
                _vm._v(" "),
                _vm.isOnline
                  ? _c(
                      "button",
                      {
                        staticClass: "button basic download",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.downloadTask.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("ダウンロード")]
                    )
                  : _vm._e()
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "keep-alive",
              [
                _c(_vm.currentComponent, {
                  tag: "component",
                  staticClass: "sp-hidden",
                  attrs: {
                    props: _vm.procs,
                    prop: _vm.proc,
                    "content-height": _vm.realContentHeight,
                    "sidebar-width": _vm.sidebarWidth,
                    "show-modal-download": _vm.showModalDownload,
                    "task-downloaded": _vm.taskDownloaded
                  },
                  on: {
                    toggle: _vm.toggleComponent,
                    "output-report": _vm.toggleComponent,
                    skip: _vm.toggleComponent,
                    complete: _vm.toggleComponent,
                    "video-play": _vm.on_video_play,
                    "close-modal-download": _vm.closeModalDownload
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "keep-alive",
              [
                _c("SpChecklist", {
                  ref: "SpCheckList",
                  staticClass: "pc-hidden",
                  attrs: {
                    props: _vm.procs,
                    prop: _vm.proc,
                    "show-modal-download": _vm.showModalDownload,
                    "task-downloaded": _vm.taskDownloaded
                  },
                  on: {
                    skip: _vm.toggleComponent,
                    complete: _vm.toggleComponent,
                    "video-play": _vm.on_video_play,
                    "close-modal-download": _vm.closeModalDownload,
                    "sp-detail-all-showed": function($event) {
                      _vm.isSpDetailAllOpened = true
                    },
                    "sp-detail-has-close": function($event) {
                      _vm.isSpDetailAllOpened = false
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }