<template>
    <common-layout-component2
        show-back="true"
        show-admin="true"
        v-on:on-nav-back="back"
    >
        <template v-slot:nav-message>
            <p>グループ登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column" v-text="title" />
                    <div class="column tr"></div>
                </div>
                <!--表組み -->
                <section class="subWindow">
                    <div class="field columns">
                        <label
                            class="label column is-one-fifth"
                            v-bind:class="{ error: errGroupName }"
                            >グループ名</label
                        >
                        <div class="control column">
                            <input
                                class="input"
                                v-bind:class="{ 'error-ctrl': errGroupName }"
                                type="text"
                                v-model="groupName"
                                placeholder="グループ名が入ります"
                                v-focus
                            />
                        </div>
                    </div>
                    <div
                        v-show="errGroupName"
                        class="field columns"
                        v-bind:class="{ error: errGroupName }"
                    >
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{ error: errGroupName }">
                                <p
                                    v-for="(err, idx) in groupNameErrors"
                                    :key="idx"
                                    v-text="err"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth"
                            >グループ説明文</label
                        >
                        <div class="control column">
                            <input
                                class="input"
                                type="text"
                                v-model="groupText"
                                placeholder=""
                            />
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth"
                            >閲覧タスク選択</label
                        >
                        <div class="control column">
                            <button
                                v-if="!hasSelectedTasks"
                                class="button basic is-tags rs selection"
                                @click="taskSelectorOpener"
                            >
                                選択して下さい
                            </button>
                            <button
                                v-if="hasSelectedTasks"
                                class="button basic is-tags rs selection selected"
                                @click="taskSelectorOpener"
                            >
                                選択中
                            </button>                            
                        </div>
                    </div>

                    <div class="field columns pb2em">
                        <label class="label column is-one-fifth"
                            >ユーザー選択</label
                        >
                        <div class="control column">
                            <button
                                v-if="!hasSelectedUsers"
                                class="button basic is-tags rs selection"
                                @click="userSelectorOpener"
                            >
                                選択して下さい
                            </button>
                            <button
                                v-if="hasSelectedUsers"
                                class="button basic is-tags rs selection selected"
                                @click="userSelectorOpener"
                            >
                                選択中
                            </button>                            
                        </div>
                    </div>

                    <div class="field">
                        <div class="control tc">
                            <button
                                class="button basic is-cancel"
                                @click="back"
                            >
                                キャンセル
                            </button>
                            <button
                                class="button basic is-login"
                                @click="save"
                                v-text="submitLabel"
                            />
                        </div>
                    </div>
                </section>

                <!-- モーダルコンポーネント -->
                <!-- ユーザー選択画面 -->
                <select-user-modal
                    v-if="userSelectorOpen"
                    :users="users"
                    @close-modal="closeSelectUser"
                    @cancel="cancelSelectUser"
                />

                <!-- 閲覧タスク選択画面 -->
                <select-task-modal
                    v-if="taskSelectorOpen"
                    :category-tasks="categoryTasks"
                    @close-modal="closeSelectTask"
                    @cancel="cancelSelectTask"
                />
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
import logic from './js/GroupEdit.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import SearchInputComponent from '../../commons/SearchInputComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

import PlusMinus from '../../commons/PlusMinus.vue';

import SelectTaskModal from './components/SelectTaskModal.vue';
import SelectUserModal from './components/SelectUserModal.vue';

logic.components = {
    CommonLayoutComponent2,
    BaseCheckComponent,
    SearchInputComponent,
    ModalComponent,
    PlusMinus,
    SelectTaskModal,
    SelectUserModal,
};

export default logic;
</script>
<style scoped>
.error {
    color: rgba(255, 0, 0, 0.6) !important;
}
.error-ctrl {
    background-color: rgba(255, 0, 0, 0.16) !important;
}
</style>
