<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" title="探す" main-class="search_page">
        <template v-slot:body>
            <section>
                <div class="container pt20 pb0">
                    <div class="flex_nw tab_2">
                        <button :class="{current:isSearchTypeLesson}" @click="isSearchTypeLesson=true">レッスンを探す</button>
                        <button :class="{current:!isSearchTypeLesson}" @click="isSearchTypeLesson=false">講師を探す</button>
                    </div>
                </div>
                <search-form-component :is-search-type-lesson="isSearchTypeLesson"/>
            </section>
            
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';
    import SearchFormComponent from '../../miso/user/SearchFormComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            SearchFormComponent,
        },
        data: function () {
            return {

                isSearchTypeLesson:true,
            }
        },
        mounted:function(){
            if(this.$libs.urlObj().query.view == "teacher"){
                this.isSearchTypeLesson = false
            }
        },
        methods:{
            search: function(){
            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
