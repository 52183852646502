<template>
  <div class="content-header">
    <slot></slot>

    <nav class="period-nav">
      <div class="period-start"><input type="date" class="form-control" v-model="period_begin"></div>
      <div class="separator">-</div>
      <div class="period-end"><input type="date" class="form-control" v-model="period_end"></div>
      <div v-if="0" class="period-button"></div>
    </nav>
  </div>
</template>

<script>

import moment from 'moment';

export default {

  data: () => { return {
    period_begin: "",                       // 期間 ( 開始 )
    period_end: "",                         // 期間 ( 終了 )
  } },

  mounted() {
    // 期間の初期値を設定する
    this.$data.period_begin = moment().subtract( 6, 'days' ).format( "YYYY-MM-DD" );
    this.$data.period_end = moment().format( "YYYY-MM-DD");

    // 期間の初期値を設定した後に、変更を監視する
    this.$watch( "period_begin", function ( value ) { this.$emit( "update:period_begin", value ); this.on_period_updated(); } );
    this.$watch( "period_end",   function ( value ) { this.$emit( "update:period_end",   value ); this.on_period_updated(); } );

    // 変更を親コンポーネントに反映させる
    this.$emit( "update:period_begin", this.$data.period_begin );
    this.$emit( "update:period_end",   this.$data.period_end   );

    // 最初に 1 度だけ変更を通知する
    this.on_period_updated();
  },

  methods: {
    /** 期間が変更された時の処理 */
    on_period_updated: function () {
      // 変更を親コンポーネントに通知する
      this.$emit( "period-updated", this.$data.period_begin, this.$data.period_end );
    }
  }
};

</script>

<style scoped lang="scss">
  .period-nav{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom:16px;
  }
  .period-nav input[type="date"]{
    background-color: #f0f0f0
  }
  .period-nav .separator{
    padding:0 0.5em;
    font-size:1rem;
  }
  .period-nav .period-button{
    display: block;
    width: 34px;
    height: 34px;
    border:1px solid #ccc;
    border-radius: 50%;
    text-align: center;
    background: url("~Image/ico-calendar.svg") no-repeat center;
    background-size:20px;
    overflow: hidden;
    text-indent: -9999px;
    margin-left:12px;
  }
  .period-nav .period-button a{
    display: block;
    height: 100%;
  }
</style>
