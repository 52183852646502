var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-component",
    {
      attrs: { "is-quest": "true", width: "1200" },
      on: { modalNo: _vm.close }
    },
    [
      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
        _c("h3", [_vm._v("ユーザー選択")])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [_c("select-user-table", { attrs: { "initial-users": _vm.users } })],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12 button-wrap" }, [
            _c(
              "button",
              {
                staticClass: "button basic is-cancel",
                on: { click: _vm.cancel }
              },
              [_vm._v("\n                    キャンセル")]
            ),
            _vm._v("  \n                "),
            _c(
              "button",
              {
                staticClass: "button basic is-login",
                on: { click: _vm.close }
              },
              [_vm._v("\n                    選択完了\n                ")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }