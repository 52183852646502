<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="受講者" main-class="lesson_page lesson_users">
        <template v-slot:body v-if="lesson">
            <section class="popular bg_yellow">
                <div class="lessons">
                    <div class="lesson">
                        <a :href="$libs.url(['lesson',lessonId])">
                            <div class="contents">
                                <div class="flex_nw">
                                    <lesson-video-thumbnail-component :video="lesson.video" />
                                    <div>
                                        <div class="categories flex">
                                            <div class="cat">{{ partName }}</div>
                                            <level-component :level="lesson.level"></level-component>
                                        </div>

                                        <h3>{{ lesson.title }}</h3>
                                        <p class="description">{{ lesson.description }}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="users bg_white">
                    <template v-for="(purchased, index) in lesson.purchases">
                    <div class="user" :key="index">
                        <a :href="$libs.url(['user',purchased.user.id])">
                            <div class="flex_nw">
                                <div class="icon">
                                    <p :style="iconImage(purchased.user.image)"></p>
                                </div>
                                <div class="right">
                                    <p class="name">{{purchased.user.display_name}}</p>
                                </div>
                                <p><i class="fas fa-angle-right"></i></p>
                            </div>
                        </a>
                    </div>
                    </template>
                </div>
            </section>

        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import LessonVideoComponent from '../../miso/user/LessonVideoComponent.vue';
    import LessonDescriptionComponent from '../../miso/user/LessonDescriptionComponent.vue';
    import LevelComponent from '../../miso/commons/LevelComponent.vue';
    import LessonVideoThumbnailComponent from '../../miso/user/LessonVideoThumbnailComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonDescriptionComponent,
            LessonVideoComponent, 
            LevelComponent,
            LessonVideoThumbnailComponent,
        },
        props: {
            lessonId: {
                type: Number,
            },
        },

        data: function () {
            return {
                lesson:null,
                showAdvices:false,
                comments:null,
            }
        },
        mounted:function(){
            this.getData()
        },
        computed: {
            partName: function(){
                return this.$config.name("parts",this.lesson.part)
            },
            genreName: function(){
                return this.$config.name("genres",this.lesson.genre)
            },
            videoPath: function(){
                if(this.lesson.video && this.lesson.video.video_path){
                    return this.lesson.video.video_path
                }
                return null
            }
        },

        methods:{
            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTaskWithPurchasedUsers(this.lessonId);
                taskHandler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            this.lesson = res.data.content;

                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },

            search: function(){
            },
            iconImage: function (path) {
                if (path) {
                    return "background: url(" + path + ") center center / cover";
                } else {
                    return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
                }
            },
            onNaviBack: function(){

                if(this.lesson){
                    this.$libs.pushUrl(['lesson',this.lessonId, 'intro'])
                }else{
                    history.back()
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>
