<template>
    <common-layout-component2 show-back="true" show-user="true" v-on:on-nav-back="back" v-bind:show-fill="allTagOpen" v-bind:fill-height="fillHeight">

        <template v-slot:nav-message>
            <p>{{ categoryName }}</p>
        </template>

        <template v-slot:nav-ctrl>
            <p>
                <button href="" class="button basic is-lightgray" @click="onUpdateDatetimeSort">更新日順&nbsp;<arrow-up-down v-if="axis=='update_datetime'" v-bind:order="order"></arrow-up-down></button>
                <button href="" class="button basic is-lightgray" @click="onDictionarySort">名前順&nbsp;<arrow-up-down v-if="axis=='dictionary'" v-bind:order="order"></arrow-up-down></button>
                <button href="#" class="button is-login rs tagsearch" @click="onAllTagOpen">タグ検索</button>
            </p>
            <div class="row all-tag-container" style="">
                <div class="__col-md-10 all-tag-container_area"  style="">
                    <div v-if="allTagOpen" class="all-tag-indicator" >▲</div>
                    <div v-if="allTagOpen" class="all-tag-area" style="padding-top:5px;padding-bottom:5px;">
                        <ul>
                            <li v-for="tag in allTags" :key="tag.tagId" v-bind:class="{'tag-selected': tag.selected}" class="button basic is-tags" style="margin-left:5px;margin-right:5px;"  @click="onTagSelected(tag)">
                                <span>{{ tag.tagName }}</span>
                            </li>
                            <li style="display:inline-block" ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:body>
            <div class="container tasks-container">
                <div class="wrapper columns is-multiline videoBlock sp-hidden">             
                    <!-- task -->
                    <template v-for="task in tasks">
                        <task-item v-if="task.is_visible" :key="task.id" :task="task" v-on:select-checklist="gotoChecklist(task, {path:'/user/checklist/' + task.id})" v-on:select-task="selectTask(task)"></task-item>
                    </template>
                </div>
                <div class="wrapper columns is-multiline videoBlock pc-hidden">                    
                    <!-- task -->
                    <template v-for="task in tasks">
                        <task-item v-if="task.is_visible" :key="task.id" :task="task" :is-small-view="true" v-on:select-checklist="gotoChecklist(task, {path:'/user/checklist/' + task.id})" v-on:select-task="selectTask(task)"></task-item>
                    </template>
                </div>

                <!-- >> SP用タスクモーダル -->
                <div class="modal js-modal" v-if="showTaskModal">
                    <div class="modal_bg js-modal-close" @click="showTaskModal = false">
                        <span class="modal-close js-modal-close"></span>
                        <div class="modal_content">
                        <task-item :task="selectedForModalTask" :is-sp-modal-view="true" v-on:select-checklist="gotoChecklist(selectedForModalTask, {path:'/user/checklist/' + selectedForModalTask.id})"></task-item>
                        </div>
                    </div>
                </div>
                <!-- << SP用モーダル -->                
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
    import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
    import CircleGraphComponent from '../../commons/CircleGraphComponent.vue';
    import PlusMinus from '../../commons/PlusMinus.vue';
    import TaskItem from './TaskItem.vue';

    export default {
        mounted: function() {

            var categoryId = this.$route.params.category;

            this.categoryId = categoryId;

            var catHandler = Vue.ELearning.Category.getOne(categoryId);
            catHandler.then(function(res){
                //console.log("tasks:", res.data);

                if(res.data.status_number == 200) {
                    var records = res.data.content;

                    this.categoryName = records.category_name;
                    //console.log(records);
                }
            }.bind(this)).catch(function(res){
                //console.error("tasks:", res.data);
            }.bind(this));

            // タグ一覧取得
            var resTag = Vue.ELearning.Task.getProvidedTags(categoryId);
            resTag.then(function(res){
                //console.log("tasks:", res.data);

                if(res.data.status_number == 200) {
                    var records = res.data.content;

                    //console.log(records);
                    this.providedTags = [];
                    for(var key in records) {
                        var item = records[key];

                        this.providedTags.push({
                            tagId: item.tag_id,
                            tagName: item.tag_name,
                            selected: false,
                        });
                        //this.tasks.push(item);
                    }

                    this.providedTags.push({
                        tagId: 9999,
                        tagName: '全て',
                        selected: true,
                    });
                    //console.log(this.tasks);
                }
            }.bind(this)).catch(function(res){
                console.error("tasks:", res.data);
            }.bind(this));

            // 
            var res = this.refreshData( categoryId );
            this.currentTag = 9999; // 必要？？？
        },
        data: function() {
            return {
                categoryId: '',
                allTagOpen:false,

                categoryName: '',

                /** タスクの一覧 */
                tasks: [],

                providedTags:[],

                axis: 'update_datetime',
                order: 'desc',

                currentTag: '',

                /** 選択されたタグ ID の一覧 */
                selectedTagIds: [],

                fillHeight:0,

                selectedForModalTask: null,
                showTaskModal: false,
            };
        },
        computed: {
            currentGrant: function() {

                //console.log("grant", this.$store.state.userInfo);
                return this.$store.state.userInfo.grant;
            },
            allTags: function() {
                //return this.$store.state.allTags;
                return this.providedTags;
            },


            axisUpdateDatetimeIndicatorShow: function(){
                //console.log("axis", this.axis);


                if(this.axis == "update_datetime") {
                    return true;
                } else {
                    return false;
                }
            },
            axisDictionaryIndicatorShow: function(){
                //console.log("axis", this.axis);
                if(this.axis == "dictionary") {
                    return true;
                } else {
                    return false;
                }
            },
        },

        methods: {
            layoutManager: function() {
                this.$nextTick(() => {
                    var tasks = jQuery(".each-task-inner");

                    //console.log("tasks", tasks);

                    var maxHeight = 0;
                    var minHeight = 10000;
                    tasks.each(function(index, item){
                        //console.log(item);

                        var height = jQuery(item).height();

                        //console.log(index, height);
                        if(maxHeight < height) {
                            maxHeight = height;
                        }
                        if(minHeight > height && height > 0) {
                            minHeight = height;
                        }
                    },);

                    console.log(maxHeight, minHeight);

                    var diff = maxHeight - minHeight;
                    diff = Math.floor(diff / 2);

                    tasks.each(function(index, item){
                        //console.log(item);

                        var height = jQuery(item).height();

                        if(height > 0) {
                            jQuery(item).css("min-height", (maxHeight + diff) + 'px');
                        }
                    },);

                });
            },
            progress: function(task) {
                var base = task.task_size;
                var progress = task.progress;

                var ratio = 0;

                if(base != undefined && progress != undefined && base > 0) {
                    ratio = (progress / base) * 100;
                }

                //console.log(Math.floor(ratio));
                return Math.floor(ratio);
            },

            computedIsNew(task) {
                var create_datetime = task.create_datetime_for_show;
                var dayTarget = new Date(create_datetime);
                var now = new Date(/*"2019/04/18"*/);

                var termDay = (now - dayTarget) / 86400000;

                //console.log("new-diff", termDay);

                if(termDay < 30) {
                    return true;
                }

                return false;
            },
            computedIsUpdate(task) {
                if(!this.computedIsNew) {
                    // is-newでない場合に表示可能となる
                    var update_datetime = task.update_datetime_for_show;
                    var dayTarget = new Date(update_datetime);
                    var now = new Date();

                    var termDay = (now - dayTarget) / 86400000;

                    //console.log("update-diff", termDay);

                    if(termDay < 30) {
                        return true;
                    }
                }
                return false;
            },

            back() {
                this.$router.push({
                    path: '/user/categories'
                });
            },
            gotoChecklist(item, pathObj) {
                //console.log("item", item);
                this.$router.push(pathObj);
            },
            onAllTagOpen() {
                this.allTagOpen = !this.allTagOpen;
            },
            onTagSelected( tagData )
            {
                var flag = false;
                var flagTagAll = true;
                for(var key in this.providedTags) {
                    var item = this.providedTags[key];
                    if(tagData.tagId == 9999 && item.tagId != 9999) {
                        item.selected = false;
                    } else if(tagData.tagId != 9999 && item.tagId == 9999) {
                        item.selected = false;
                    } else {
                        if(item.tagId == tagData.tagId) {
                            if(tagData.selected == true) {
                                tagData.selected = false;
                            } else {
                                tagData.selected = true;
                            }
                        }
                    }
                    if(tagData.tagId != 9999 && item.selected == true) {
                        flagTagAll = false;
                    }
                }

                this.selectedTagIds = [];

                for ( let tag of this.providedTags )
                {
                    if ( tag.selected && tag.tagId !== 9999 )
                    {
                        this.selectedTagIds.push( tag.tagId );
                    }
                }

                this.currentTag = tagData.tagId;

                // console.log( "this.selectedTags : ", this.selectedTagIds );

                this.updateTasks();
            },

            onUpdateDatetimeSort: function(){
                if(this.axis == "update_datetime") {
                    if(this.order == "asc") {
                        this.order = "desc";
                    } else {
                        this.order = "asc";
                    }
                } else {
                    this.axis = "update_datetime";
                    this.order = "asc";
                }

                this.updateTasks();
            },

            onDictionarySort: function(){
                if(this.axis == "dictionary") {
                    if(this.order == "asc") {
                        this.order = "desc";
                    } else {
                        this.order = "asc";
                    }
                } else {
                    this.axis = "dictionary";
                    this.order = "asc";
                }

                this.updateTasks();
            },

            /**
             * 一覧で表示するタスク情報を更新する
             * 
             */
            refreshData: function ( categoryId, tagId = undefined, axis = undefined, order = undefined )
            {
                // タグに紐づくタスク取得
                var res = Vue.ELearning.Task.getProvidedTask(categoryId, tagId, axis, order);
                res.then( async function ( res ) {
                    //console.log("tasks:", res.data);

                    if(res.data.status_number == 200) {
                        var records = res.data.content;
                        this.tasks = [];
                        for(var key in records) {
                            var item = records[key];

                            if ( Vue.Download.isOnline() )
                            {
                                // @todo 何のために uuid を使っているのか調査し、なるべく削除する
                                item["uuid"] = Vue.ELearning.Util.generateUuid();

                                this.tasks.push( item );
                            }
                            else
                            {
                                let stored_task = await Vue.Download.getStoredTask( item.id );

                                if ( stored_task )
                                {
                                    // @todo 何のために uuid を使っているのか調査し、なるべく削除する
                                    stored_task.uuid = Vue.ELearning.Util.generateUuid();

                                    this.tasks.push( stored_task );
                                }
                            }
                        }

                        // console.log( "tasks", this.tasks );

                        this.updateTasks();

                        // レイアウト調整
                        this.layoutManager();
                    }
                }.bind(this)).catch(function(res){
                    console.error("tasks:", res.data);
                }.bind(this));
            },

            /**
             * タスクの表示を更新する
             * 
             * この関数は現在のタグの選択によって this.tasks の各タスクの is_visible を設定し、
             * 現在の並び順の選択によって this.tasks を並び替える
             */
            updateTasks: function ()
            {
                this.tasks = _.map( this.tasks, ( task ) => {
                    const tag_ids = _.map( task.tags, tag => tag.tag_id );

                    // console.log( "tag_ids : ", tag_ids );

                    task.is_visible = _.difference( this.selectedTagIds, tag_ids ).length == 0;

                    return task;
                } );

                const axis = this.axis == "dictionary" ? "task_name" : this.axis;

                // console.log( "axis : ", axis );

                this.tasks = _.sortBy( this.tasks, axis );

                if ( this.order == "desc" )
                {
                    this.tasks = _.reverse( this.tasks );
                }
            },

            selectTask: function(task){
                this.selectedForModalTask = task;
                this.showTaskModal = true;
            }
        },

        watch: {
            allTagOpen: function(val) {
                if(val == true) {
                    this.$nextTick(function(){
                        var h = jQuery(".all-tag-container").height();
                        //console.log("open", h);

                        this.fillHeight = h;
                    }.bind(this));

                } else {
                    this.$nextTick(function(){
                        var h = jQuery(".all-tag-container").height();
                        //console.log("close", h);

                        this.fillHeight = h;
                    }.bind(this));

                }
            }
        },

        components: {
            CommonLayoutComponent2,
            CircleGraphComponent,
            PlusMinus,
            TaskItem
        },
    }
</script>
<style lang="scss" scoped>
    .cat-item a {
        text-decoration: none;
    }

.navbar-end button >span {
  margin-left: 7px;
}
.navbar-end button.tagsearch {
  background: white;
  color: #4fbe2b;
  padding: 0 15px;
  margin-left: 20px;
  border-radius: 5px;
}

    .tag-button {
        cursor:pointer;
        display:inline-block;
        margin:5px;
        border-radius:1em;
        border:2px solid #5AC23A;
        padding:5px;
        color:#5AC23A;
    }

    .tag-button:hover {
        background-color:#5AC23A;
        color:white;
    }

    .tag-selected {
        background-color:#5AC23A;

        /*border:2px solid #3f8124;*/

        color:white;
    }

    /* tag-indicator */

    .all-tag-indicator {
        color: #f5f5f5;
        position:absolute;
        right:1em;
        top:-1em;
        font-size:1.3em;
    }
    .all-tag-area {
        background-color:#f5f5f5;

        top:0;
    }

    .tag-item {
        display:inline-block;
        margin:5px;
        border-radius:1em;
        border:2px solid #5AC23A;
        padding:5px;
        color:#5AC23A;
        cursor:pointer;
    }

    .tag-item:hover {
        background-color: rgba(18, 194, 74, 0.5);
        color: rgba(0, 0, 0, 0.6);
    }

    .tag-selected {
        background-color:#5AC23A !important;

        /*border:2px solid #3f8124;*/

        color:white !important;
    }

    .all-tag-container {
        margin:0;
        padding:0;
        margin-top:1em;
        position:absolute;
        top:80px;
        right:-70px;
        width:100%;
        /*box-shadow: 2px 2px 8px #ccc;*/
        right: 0px;
        background: whitesmoke;
    }
    .all-tag-container_area {
        width: 100%;
        padding: 0 20px;
    }
</style>
