var logic = {};

logic.mounted = function() {
    //console.log("Initialize Admin/TaskEdit");
    //console.log(this.$route.params);

    this.id = Number(this.$route.params.id);

    // カテゴリ一覧取得
    this.getCategories();

    console.log("id : ", this.id);

    if (this.id) {
        // 編集モード
        this.regist = false;

        // タスク情報取得
        this.getTask();

        // タスクに紐づくタグ一覧を取得
        this.getTagsByTask();

        this.fetchGroupsByTask().then(() => {
            this.fetchGroups();
        });
    } else {
        // 新規モード
        this.regist = true;

        // タスクに紐づくタグ一覧を取得
        this.getTagsByTask();

        this.fetchGroups();
    }

    // チェックリストのプレビューデータをクリアする
    this.$store.commit('clearPreviewData');

};

logic.computed = {
    groupSelectionEnabled: function() {
        return this.grant === 'perGroup';
    },
    title() {
        return `タスク${this.regist ? '登録' : '編集'}`;
    },
    submitLabel() {
        return `${this.regist ? '' : '上書き'}保存`;
    },

    allTags: function() {
        return this.$store.state.allTags;
    },

    /** 選択中のタグのタグ ID の一覧 */
    selectedTagIds: function() {
        let selected_tag_ids = [];

        for (let key in this.tags) {
            let item = this.tags[key];

            if (item.selected == true && item.tagId != '') {
                selected_tag_ids.push(item.tagId);
            }
        }

        return selected_tag_ids;
    },

    /** 編集されたタスク */
    editedTask: function() {
        return {
            id: this.id,
            category_id: this.selectedCategoryId,
            task_name: this.taskName,
            task_text: this.taskDesc,
            references: this.grant == 'all' ? 1 : 2,
            display_flag: this.publicity == 'public' ? 1 : 0,
            tags: this.selectedTagIds,
        };
    },

    currentCategoryName: function() {
        var selectedId = this.selectedCategoryId;
        if (selectedId) {
            var category = this.categories.filter(function(item) {
                return item.categoryId == selectedId;
            });
            if (category.length > 0) return category[0].categoryName;

        }

        return "";
    },

    currentTaskName: function() {
        if (this.taskName.length > 0) {
            return this.taskName;
        }

        return "新規タスク";
    },

    groupSelectedCompleteEnabled: function() {

        // 2020/02/12 No:54 グループを指定する場合は最低１つ以上の選択を必須とする。
        let selectedGroups = this.groups.filter(group => group.selected);
        if (selectedGroups.length == 0) {

            return false;
        } else {

            return true;
        }
    },

    hasSelectedTags: function() {
        return !this.tags.every(tag => !tag.selected);
    },
};

logic.data = function() {
    return {
        isTagSelectorOpened: false,

        taskGrant: '',
        isOpenUserByTask: false,
        isOpenCreateTag: false,

        groupsByTask: [],

        /** 選択可能なカテゴリの一覧 */
        categories: [],

        /** 選択可能なタグの一覧 */
        tags: [],

        /** 2019-10-06 ando : ここにカラムを列挙するのも美しくないが、元のコードにならって編集中の id をここで保持する */
        id: null,

        selectedCategoryId: null,
        taskName: '',
        taskDesc: '',
        grant: 'all',

        publicity: 'public',
        regist: true,

        errCategoryId: false,
        categoryErrors: [],

        errTaskName: false,
        taskNameErrors: [],

        errTaskText: false,
        taskTextErrors: [],

        groups: [],
        sortOrderGroup: 'asc',
        initGroups: []
    };
};

logic.methods = {
    openTagModal() {
        this.isOpenCreateTag = true;
    },
    closeTagModal() {
        this.isOpenCreateTag = false;
    },
    toggleTagModal() {
        this.isOpenCreateTag = !this.isOpenCreateTag;
    },
    cancelModalGroups() {
        this.isOpenUserByTask = false;
        this.groups = JSON.parse(JSON.stringify(this.initGroups));;
    },

    /**
     * タスク情報取得
     *
     */
    getTask: function() {
        // タスク情報取得
        var taskHandler = Vue.ELearning.Task.getTask(this.id);

        taskHandler
            .then(
                function(res) {
                    if (res.data.status_number == 200) {
                        //console.log("task", res.data.content);
                        var item = res.data.content;

                        this.id = item.id;
                        this.selectedCategoryId = item.category_id;
                        this.taskName = item.task_name;
                        this.taskDesc = item.task_text;
                        this.grant = item.references == 1 ? 'all' : 'perGroup';
                        this.publicity = item.display_flag == 1 ? 'public' : 'negative';
                    }
                }.bind(this),
            )
            .catch(function(res) {}.bind(this));
    },

    /**
     * カテゴリ情報取得
     */
    getCategories: function() {
        // カテゴリ一覧取得
        var categoryHandler = Vue.ELearning.Category.getAll();
        categoryHandler
            .then(
                function(res) {
                    this.categories = [];

                    if (res.data.status_number == 200) {
                        var record = res.data.content;

                        //console.log("categories", res.data.content);

                        var index = 0;
                        for (var key in record) {
                            var item = record[key];

                            this.categories.push({
                                categoryId: item.category_id,
                                categoryName: item.category_name,

                                value: item.category_id,
                                text: item.category_name,
                            });

                            index++;
                        }
                    }
                }.bind(this),
            )
            .catch(
                function(res) {
                    console.error(res);
                }.bind(this),
            );
    },

    fetchGroupsByTask() {
        return new Promise((resolve, reject) => {
            const res = Vue.ELearning.Assignment.getGroupsByTask(this.id);
            res.then(res => {
                    if (res.data.status_number != 200) throw new Error();
                    return res.data.content;
                })
                .then(data => {
                    this.groupsByTask = [];
                    data.map(row => this.groupsByTask.push(row.group_id));

                    resolve();
                })
                .catch(err => {
                    console.error('グループタスクアサイン情報取得失敗');
                    reject();
                });
        });
    },

    /**
     * 全タグの一覧を取得し、同時に各タグが現在のタスクに紐づいているかの情報も取得する
     * ( 新規モードの場合も 0 を引数として上記を実行し、全タグの一覧のみを取得する )
     * 
     */
    getTagsByTask: function() {
        var tagHandler = Vue.ELearning.Assignment.getAllTagsByTask(this.id);

        tagHandler
            .then(
                function(res) {
                    this.tags = [];

                    if (res.data.status_number == 200) {
                        for (var key in res.data.content) {
                            var item = res.data.content[key];
                            this.tags.push({
                                tagId: item.tag_id,
                                tagName: item.tag_name,
                                selected: item.selected == 1 ? true : false,
                            });
                        }
                    }
                }.bind(this),
            )
            .catch(
                function(res) {
                    console.error(res.data);
                }.bind(this),
            );
    },

    getAllTags: function() {
        // タグ情報取得(全タグ)
        var tagHandler = Vue.ELearning.Tag.getAll();
        tagHandler
            .then(
                function(res) {
                    this.tags = [];

                    if (res.data.status_number == 200) {
                        for (var key in res.data.content) {
                            var item = res.data.content[key];
                            this.tags.push({
                                tagId: item.tag_id,
                                tagName: item.tag_name,
                                selected: false,
                            });
                        }
                    }
                }.bind(this),
            )
            .catch(
                function(res) {
                    console.error(res.data);
                }.bind(this),
            );
    },

    onCategoryChanged: function() {
        //console.log("category", this.categoryId);
    },

    back: function() {
        this.$router.push('/admin/tasks');
    },

    onCancel: function() {
        this.$router.push('/admin/tasks');
    },

    errorHandler: function(content) {
        if (content.errors.category_id != undefined) {
            console.log('err-category-id');
            this.errCategoryId = true;
            this.categoryErrors = [];
            for (var key in content.errors.category_id) {
                var item = content.errors.category_id[key];
                this.categoryErrors.push(item);
            }
        }
        if (content.errors.task_name != undefined) {
            console.log('err-task-name');
            this.errTaskName = true;
            this.taskNameErrors = [];
            for (var key in content.errors.task_name) {
                var item = content.errors.task_name[key];
                this.taskNameErrors.push(item);
            }
        }
        if (content.errors.task_text != undefined) {
            console.log('err-task-name');
            this.errTaskText = true;
            this.taskTextErrors = [];
            for (var key in content.errors.task_text) {
                var item = content.errors.task_text[key];
                this.taskTextErrors.push(item);
            }
        }
    },

    /**
     * 「上書き保存」 or 「保存」が押された時の処理
     */
    onUpdate: function() {

        // 2020/01/10 ID:54 グループを指定する場合は最低１つ以上の選択を必須とする。@tosa
        if (this.grant === 'perGroup') {
            let selectedGroups = this.groups.filter(group => group.selected);
            if (selectedGroups.length == 0) {
                this.$dialog.message("閲覧権限をグループ指定にした場合は、少なくとも1グループは選択してください。");
                Vue.ELearning.Util.hideOverlay(this.$store);
                return;
            }
        }

        if (this.id) {
            this.updateTask(
                function() {
                    // 関連ユーザー更新
                    this.updateGroupsByTask(
                        function() {
                            // タグ更新
                            this.updateTagsByTask(
                                function() {
                                    this.$router.push('/admin/tasks');
                                }.bind(this),
                            );
                        }.bind(this),
                    );
                }.bind(this),
            );
        } else {
            this.insertTask(
                function() {
                    // 関連ユーザー更新
                    this.updateGroupsByTask(
                        function() {
                            // タグ更新
                            this.updateTagsByTask(
                                function() {
                                    this.$router.push('/admin/tasks');
                                }.bind(this),
                            );
                        }.bind(this),
                    );
                }.bind(this),
            );
        }
    },

    /**
     * タスクに紐付けされているユーザ情報変更時処理
     * @param record
     */
    onUserByTaskChange: function(record) {
        //console.log(record);

        record.selected = !record.selected;
    },

    /**
     * タスクを更新する
     * 
     */
    updateTask: function(callback) {
        let task = this.editedTask;

        console.log('task : ', task);

        this.errCategoryId = false;
        this.categoryErrors = [];
        this.errTaskName = false;
        this.taskNameErrors = [];
        this.errTaskText = false;
        this.taskTextErrors = [];

        var res = Vue.ELearning.Task.updateTask(task);
        res.then(
            function(res) {
                console.log('タスク更新', res.data.content);

                var content = res.data.content;
                if (content.result == 'NG') {
                    // バリデーションエラー
                    console.error(content);

                    this.errorHandler(content);

                    Vue.ELearning.Util.hideOverlay(this.$store);
                    return;
                }

                callback();
            }.bind(this),
        ).catch(
            function(res) {
                console.error(res);
                this.$dialog.message("タスク情報の更新に失敗しました");
                //alert('タスク情報の更新に失敗しました');
                //callback();
            }.bind(this),
        );
    },

    /**
     * タスク新規作成
     * @param {callback} callback 
     */
    insertTask: function(callback) {
        let task = this.editedTask;

        // 2020/01/10 ID:54 グループを指定する場合は最低１つ以上の選択を必須とする。@tosa
        if (this.grant === 'perGroup') {
            let selectedGroups = this.groups.filter(group => group.selected);
            if (selectedGroups.length == 0) {
                this.$dialog.message("閲覧権限をグループ指定にした場合は、少なくとも1グループは選択してください。");
                Vue.ELearning.Util.hideOverlay(this.$store);
                return;
            }
        }

        this.errCategoryId = false;
        this.categoryErrors = [];
        this.errTaskName = false;
        this.taskNameErrors = [];
        this.errTaskText = false;
        this.taskTextErrors = [];

        var res = Vue.ELearning.Task.insertTask(task);

        res.then(
            function(res) {

                console.log("content : ", res.data.content);

                var content = res.data.content;
                if (content.result && content.result == 'NG') {
                    // バリデーションエラー
                    console.error(content);

                    this.errorHandler(content);

                    Vue.ELearning.Util.hideOverlay(this.$store);
                    return;

                }

                if (content.result) {
                    this.id = res.data.content.result;
                }

                callback();
            }.bind(this),
        ).catch(
            function(res) {
                this.$dialog.message("タスク情報の作成に失敗しました");
                //alert('タスク情報の作成に失敗しました');
                //callback();
            }.bind(this),
        );
    },

    updateGroupsByTask(callback) {
        let selectedGroups = this.groups.filter(group => group.selected);

        const res = Vue.ELearning.Assignment.assignTaskGroup(this.id, _.map(selectedGroups, 'groupId'));
        res.then(() => callback()).catch(err => {
            console.log(err);
            this.$dialog.message("タスクに対するグループの紐付けに失敗しました");
            //alert('タスクに対するグループの紐付けに失敗しました');
        });
    },

    /**
     * タスクに対するタグの紐づけを更新する
     * 
     */
    updateTagsByTask: function(callback) {
        var res = Vue.ELearning.Assignment.assignTaskTag(this.id, this.selectedTagIds);

        res.then(
            function(res) {
                //console.log(res);
                //console.log("タグアサイン更新");
                callback();
            }.bind(this),
        ).catch(
            function(res) {
                console.log(res);
                //alert('タスクに対するタグの紐付けに失敗しました');
                this.$dialog.message("タスクに対するタグの紐付けに失敗しました");
                //this.isOpenUserByTask = false
            }.bind(this),
        );
    },

    userTaskDialogCompleted: function() {

        this.isOpenUserByTask = false;
    },

    reloadTags() {
        this.closeTagModal();
        this.getTagsByTask();
    },

    /**
     * 「保存＆チェックリスト編集」が押された時の処理
     */
    onChecklistEdit: function() {

        // 2020/01/10 ID:54 グループを指定する場合は最低１つ以上の選択を必須とする。@tosa
        if (this.grant === 'perGroup') {
            let selectedGroups = this.groups.filter(group => group.selected);
            if (selectedGroups.length == 0) {

                //alert("閲覧権限をグループ指定にした場合は、少なくとも1グループは選択してください。");
                this.$dialog.message("閲覧権限をグループ指定にした場合は、少なくとも1グループは選択してください。");
                Vue.ELearning.Util.hideOverlay(this.$store);
                return;
            }
        }

        if (this.id) {
            this.updateTask(
                function() {
                    // 関連ユーザー更新
                    this.updateGroupsByTask(
                        function() {
                            // タグ更新
                            this.updateTagsByTask(
                                function() {
                                    this.$router.push('/admin/checklist/' + this.id);
                                }.bind(this),
                            );
                        }.bind(this),
                    );
                }.bind(this),
            );
        } else {
            this.insertTask(
                function() {
                    // 関連ユーザー更新
                    this.updateGroupsByTask(
                        function() {
                            // タグ更新
                            this.updateTagsByTask(
                                function() {
                                    this.$router.push('/admin/checklist/' + this.id);
                                }.bind(this),
                            );
                        }.bind(this),
                    );
                }.bind(this),
            );
        }
    },

    assignedGroupSort(sort) {
        this.sortOrderGroup = sort;
    },

    fetchGroups() {
        return new Promise((resolve, reject) => {
            const handler = Vue.ELearning.Group.getAll(
                'group_name',
                this.sortOrderGroup,
            );

            handler
                .then(res => {
                    if (res.data.status_number != 200) throw new Error();
                    return res.data.content;
                })
                .then(data => {
                    this.groups = [];

                    data.map(group => {
                        this.groups.push({
                            groupId: group.group_id,
                            groupName: group.group_name,
                            selected: this.groupsByTask.includes(
                                group.group_id,
                            ),
                        });
                    });
                    this.initGroups = JSON.parse(JSON.stringify(this.groups));
                    resolve();
                })
                .catch(res => {
                    console.error(res);
                    reject();
                });
        });
    },

    onUserByGroupChange(user) {
        user.selected = !user.selected;
    },
};

logic.watch = {
    sortOrderGroup: {
        handler: 'fetchGroups',
    },
};

export default logic;