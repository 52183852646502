<template>
    <div class="box file_box">
      <div class="inner">
      <img v-if="!isVideo && videoSrc" :src="videoSrc" alt="">
      <video v-if="isVideo && videoSrc" :src="videoSrc" controls webkit-playsinline playsinline mute autoplay></video>
      <div class="upload_icon" v-if="!videoSrc"><i class="fas fa-photo-video"></i></div>
      <label :for="'file_upload' + index" class="file_label">
          {{ title }}
        <input type="file" :id="'file_upload' + index" @change="onChangeVideo($event)" accept=".mp4, .png, .jpg, .jpeg, .gif">
      </label>
      </div>
    </div>
</template>

<script>
export default {

  components: {
  },
  props: {
      video:Object,
      index:Number,
      title:{
        type:String,
        default: '動画・画像を選択'
      }
  },
  data: function () {
    return {
      selectedVideo:null,
      videoFile: null,
    };
  },
  mounted: function () {
  },
  computed: {
    isVideo: function(){
      if (!this.videoPath) {
            return false;
      }

      var isVideo = false;
      isVideo = isVideo || this.videoPath.endsWith('.mp4');
      isVideo = isVideo || this.videoPath.endsWith('.MP4');
      isVideo = isVideo || this.videoPath.endsWith('.mov');
      isVideo = isVideo || this.videoPath.endsWith('.MOV');

      return isVideo;
    },

    videoPath: function(){
      if(this.videoFile){
        return this.videoFile.name
      }
      if(this.video && this.video.video_path){
        return this.video.video_path
      }
      return null
    },
      videoSrc: function(){
      if(this.selectedVideo){
        return this.selectedVideo
      }
      if(this.video && this.video.video_path){
        return this.video.video_path
      }
      return null
    }
  },
  methods: {
      onChangeVideo: function(event) {
        //console.log(e.target.result);
        this.selectedVideo = window.URL.createObjectURL(event.target.files[0]);
        this.videoFile = event.target.files.length > 0 ? event.target.files[0] : [];
        this.$emit("change-video", this.videoFile)
      },
  },
};
</script>
<style lang="scss" scoped>
</style>
