<template>
<div>
                <div class="list_box">
                    <ul>
                        <li><span>キーワード</span>
                            <input type="text" name="keyword" placeholder="タップして入力" v-model="keyword"></li>
                    </ul>
                </div>
                <div class="list_box" v-show="isSearchTypeLesson">
                    <h3>レッスン</h3>
                    <ul>
                        <li class="checkbox">
                            <p class="name"><span>カテゴリ</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.parts">
                                    <label :key="index"><input type="checkbox" name="category" :value="item.value" v-model="categories"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li class="checkbox">
                            <p class="name"><span>ジャンル</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.genres">
                                    <label :key="index"><input type="checkbox" name="genre" :value="item.value" v-model="genre"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li class="checkbox">
                            <p class="name"><span>難易度</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.levels">
                                    <label :key="index"><input type="checkbox" name="level" :value="item.value" v-model="levels"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li>
                            <span>価格下限</span>
                            <select name="price_low" v-model="price_low">
                            <option value="500">500円以上</option>
                            <option value="1000">1,000円以上</option>
                            <option value="2000">2,000円以上</option>
                            <option value="3000">3,000円以上</option>
                            <option value="4000">4,000円以上</option>
                            <option value="5000">5,000円以上</option>
                        </select></li>
                        <li>
                            <span>価格上限</span>
                            <select name="price_up" v-model="price_up">
                                <option value="500">500円以下</option>
                                <option value="1000">1,000円以下</option>
                                <option value="2000">2,000円以下</option>
                                <option value="3000">3,000円以下</option>
                                <option value="4000">4,000円以下</option>
                                <option value="5000">5,000円以下</option>
                            </select></li>
                    </ul>
                </div>

                <div class="list_box">
                    <h3>講師</h3>
                    <ul>
                        <li class="checkbox">
                            <p class="name"><span>年齢</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.ages">
                                    <label :key="index"><input type="checkbox" name="ages" :value="item.value" v-model="ages"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li>
                            <span>性別</span>
                            <select name="sex" v-model="sex">
                                <template v-for="(item ,index) in $config.sex">
                                    <option :value="item.value" :key="index">{{ item.name }}</option>
                                </template>
                            </select>
                        </li>
                        <li class="checkbox">
                            <p class="name"><span>楽器</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.parts">
                                    <label :key="index"><input type="checkbox" name="category" :value="item.value" v-model="teacher_part"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li class="checkbox">
                            <p class="name"><span>得意ジャンル</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.genres">
                                    <label :key="index"><input type="checkbox" name="favorite_genre" :value="item.value" v-model="favorite_genre"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li>
                            <span>講師歴</span>
                            <select name="career" v-model="career">
                                <template v-for="(item ,index) in $config.career">
                                    <option :value="item.value" :key="index">{{ item.name }}</option>
                                </template>
                            </select>
                        </li>

                    </ul>
                </div>
                <div class="container pt0">
                    <button class="mt20 button md" @click="search">検索する</button>
                </div>
</div>
</template>

<script>
    export default {
        components :{
        },
        props: {
            isSearchTypeLesson: Boolean,
        },
        data: function () {
            return {
                categories:[],
                levels:[],
                price_low:null,
                price_up:null,
                ages:[],
                sex:0,
                genre:[],
                career:0,
                teacher_part:[],
                favorite_genre:[],
                keyword:null,

                queryParams: [
                    "keyword",
                    "categories",
                    "levels",
                    "price_low",
                    "price_up",
                    "ages",
                    "sex",
                    "genre",
                    "favorite_genre",
                    "teacher_part",
                    "career",
                ],
            }
        },
        mounted:function(){
                jQuery(".checkbox").on("click",".name",function() {
                        jQuery(this).toggleClass("active").next(".items").slideToggle(300);
                });

                //クエリからセットする
                var query = this.$libs.urlObj().query
                this.queryParams.forEach((key) =>{
                  if(query[key]){
                    var item = query[key];
                    if(Array.isArray(this[key])){
                        console.log("array:", key, item)
                        if(Number.isInteger(item)){
                            this[key].push(item)
                        }else{
                            this[key] = item.split(",")
                        }
                    }else{
                        this[key] = item
                    }
                  }
                })
        },
        methods:{
            search: function(){

                var query = {}
                this.queryParams.forEach((key) =>{
                    var item = this[key];
                  if(this[key]){
                    if(Array.isArray(item)){
                        if(item.length > 0){
                            query[key] = item.join(",")
                        }
                    }else{
                        query[key] = item
                    }
                  }
                })

                query["type"] = this.isSearchTypeLesson ? "lesson" : "teacher"
                this.$libs.pushUrl(['search','result'],{query:query})

            }
        }
    }
</script>
<style lang="scss" scoped>
</style>
