var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "レッスン情報を入力",
      "main-class": "lesson_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "post create_intro" }, [
              _c(
                "div",
                { staticClass: "container pt20 pb30 bg_yellow" },
                [
                  _c("h3", [_vm._v("レッスン紹介")]),
                  _vm._v(" "),
                  _c("lesson-video-input-component", {
                    attrs: { video: _vm.lesson.video, index: 1 },
                    on: {
                      "change-video": function($event) {
                        return _vm.onChangeVideo($event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" }, [
                    _c("h3", [_vm._v("タイトル")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lesson.title,
                          expression: "lesson.title"
                        }
                      ],
                      attrs: { type: "text", placeholder: "タップして入力" },
                      domProps: { value: _vm.lesson.title },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.lesson, "title", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.error("title")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("title")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" }, [
                    _c("h3", [_vm._v("レッスン内容")]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lesson.description,
                          expression: "lesson.description"
                        }
                      ],
                      attrs: { placeholder: "タップして入力" },
                      domProps: { value: _vm.lesson.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.lesson,
                            "description",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.error("decription")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("decription")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" }, [
                    _c("h3", [_vm._v("ゴール")]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lesson.goal,
                          expression: "lesson.goal"
                        }
                      ],
                      attrs: { placeholder: "タップして入力" },
                      domProps: { value: _vm.lesson.goal },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.lesson, "goal", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.error("goal")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("goal")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" }, [
                    _c("ul", [
                      _c("li", [
                        _c("span", [_vm._v("カテゴリ")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.lesson.part,
                                expression: "lesson.part"
                              }
                            ],
                            attrs: { name: "category" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.lesson,
                                  "part",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _vm._l(_vm.$config.parts, function(item, index) {
                              return [
                                _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: item.value }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("ジャンル")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.lesson.genre,
                                expression: "lesson.genre"
                              }
                            ],
                            attrs: { name: "genre" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.lesson,
                                  "genre",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _vm._l(_vm.$config.genres, function(item, index) {
                              return [
                                _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: item.value }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("span", [_vm._v("難易度")]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.lesson.level,
                                expression: "lesson.level"
                              }
                            ],
                            attrs: { name: "level" },
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.lesson,
                                  "level",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _vm._l(_vm.$config.levels, function(item, index) {
                              return [
                                _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: item.value }
                                  },
                                  [_vm._v(_vm._s(item.forSelectLabel))]
                                )
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.error("part")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("part")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.error("genre")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("genre")))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.error("level")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("level")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" }, [
                    _c("h3", [_vm._v("販売価格")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "price" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.lesson.price,
                            expression: "lesson.price"
                          }
                        ],
                        attrs: {
                          type: "number",
                          placeholder: "タップして入力"
                        },
                        domProps: { value: _vm.lesson.price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.lesson, "price", $event.target.value)
                          }
                        }
                      }),
                      _c("span", { staticClass: "bold" }, [_vm._v("円")])
                    ]),
                    _vm._v(" "),
                    _vm.error("price")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("price")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "box" }, [
                    _c("h3", [_vm._v("採点・質問（円/回）")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "price" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.lesson.advice_price,
                            expression: "lesson.advice_price"
                          }
                        ],
                        attrs: {
                          type: "number",
                          placeholder: "タップして入力"
                        },
                        domProps: { value: _vm.lesson.advice_price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.lesson,
                              "advice_price",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c("span", { staticClass: "bold" }, [_vm._v("円")])
                    ]),
                    _vm._v(" "),
                    _vm.error("advice_price")
                      ? _c("p", { staticClass: "error_description" }, [
                          _vm._v(_vm._s(_vm.error("advice_price")))
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.lesson.steps && _vm.lesson.steps.length > 0
                    ? [
                        _c("div", { staticClass: "box" }, [
                          _c("h3", [_vm._v("ステップ")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "step_box currentStep_box bg_white"
                            },
                            [
                              _c(
                                "ul",
                                [
                                  _vm._l(_vm.lesson.steps, function(
                                    item,
                                    index
                                  ) {
                                    return [
                                      _c("li", { key: index }, [
                                        _c("p", { staticClass: "step" }, [
                                          _vm._v("Step" + _vm._s(index + 1))
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [_vm._v(_vm._s(item.title))])
                                      ])
                                    ]
                                  })
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  on: { click: _vm.editSteps }
                                },
                                [_vm._v("ステップの編集")]
                              )
                            ]
                          )
                        ])
                      ]
                    : [
                        _c("div", { staticClass: "box" }, [
                          _c("h3", [_vm._v("ステップ")]),
                          _vm._v(" "),
                          _c("div", { staticClass: "step_box bg_white" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button",
                                on: { click: _vm.editSteps }
                              },
                              [_vm._v("ステップの登録")]
                            )
                          ])
                        ])
                      ],
                  _vm._v(" "),
                  _c("div", { staticClass: "buttons" }, [
                    _vm.lesson.display_flag != 1
                      ? _c(
                          "button",
                          {
                            staticClass: "button brown",
                            attrs: { disabled: !_vm.isNew && !_vm.lessonId },
                            on: {
                              click: function($event) {
                                _vm.showModalPublish = true
                              }
                            }
                          },
                          [_vm._v("公開する")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.lesson.display_flag == 1
                      ? _c(
                          "button",
                          {
                            staticClass: "button brown",
                            on: {
                              click: function($event) {
                                _vm.showModalPublish = true
                              }
                            }
                          },
                          [_vm._v("更新する")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.lesson || _vm.lesson.display_flag != 1
                      ? _c(
                          "button",
                          {
                            staticClass: "button white md",
                            attrs: { disabled: !_vm.isNew && !_vm.lessonId },
                            on: { click: _vm.saveDraft }
                          },
                          [_vm._v("下書きに保存する")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button gray md",
                        attrs: { disabled: !_vm.isNew && !_vm.lessonId },
                        on: {
                          click: function($event) {
                            _vm.showModalPrivate = true
                          }
                        }
                      },
                      [_vm._v("非公開にする")]
                    )
                  ])
                ],
                2
              )
            ]),
            _vm._v(" "),
            _vm.showModalPublish
              ? _c("modal-component", {
                  attrs: {
                    title: "このレッスンを公開しますか？",
                    "view-selectable": true
                  },
                  on: {
                    yes: _vm.savePublish,
                    no: function($event) {
                      _vm.showModalPublish = false
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showModalPrivate
              ? _c("modal-component", {
                  attrs: {
                    title: "このレッスンを非公開にしますか？",
                    "view-selectable": true
                  },
                  on: {
                    yes: _vm.savePrivate,
                    no: function($event) {
                      _vm.showModalPrivate = false
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }