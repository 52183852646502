var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "navbar",
      staticStyle: {
        "margin-right": "0",
        "padding-right": "0",
        position: "relative",
        right: "-20px",
        "z-index": "10000"
      }
    },
    [
      _c("div", { staticClass: "navbar-end" }, [
        _c(
          "div",
          {
            staticClass: "navbar-item",
            staticStyle: { "margin-right": "0", "padding-right": "0" }
          },
          [
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowUser,
                    expression: "isShowUser"
                  }
                ],
                staticClass: "button is-orange br100 margin",
                attrs: { id: "touser", to: "/user/categories" }
              },
              [_c("strong", [_vm._v("ユーザー画面トップへ")])]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowAdmin,
                    expression: "isShowAdmin"
                  }
                ],
                staticClass: "button is-blue br100 margin",
                attrs: { id: "totop", to: "/admin/menu" }
              },
              [_c("strong", [_vm._v("管理画面トップへ")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowLogout,
                    expression: "isShowLogout"
                  }
                ],
                staticClass: "button is-blue br100 margin",
                attrs: { id: "logout" },
                on: { click: _vm.doLogout }
              },
              [_c("strong", [_vm._v("ログアウト")])]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isShowNotice,
                    expression: "isShowNotice"
                  }
                ],
                staticClass: "ico-alert2",
                attrs: { id: "alert" }
              },
              [
                _c("img", {
                  attrs: { src: "/images/ico-alert2.png", alt: "" }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.noticeCount))])
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }