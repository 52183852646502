<template>
    <common-layout-component2
        show-back="true"
        show-admin="true"
        v-on:on-nav-back="back"
    >
        <template v-slot:nav-message>
            <p>ユーザー登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">ユーザー編集</h2>
                    <div class="column tr">
                        <button class="button basic is-gray" @click="importUsr">
                            インポート&nbsp;<img
                                src="/images/ico-import.png"
                                alt=""
                            />
                        </button>
                        <input id="input-file-csv" @change="selectedUploadCsv" type="file" name="file" class="d-none" />

                        <button class="button basic is-gray" @click="exportUsr">
                            エクスポート&nbsp;<img
                                src="/images/ico-export.png"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
                <!--　サブタイトル部 -->
                <div id="subtitle-area">ユーザー一覧</div>
                <!--表組み -->
                <div id="table-area" class="columns">
                    <div class="column">
                        <button
                            @click="gotoRegist"
                            class="button is-login"
                        >
                            新規登録&nbsp;<img
                                src="//images/ico-plus.png"
                                alt=""
                            />
                        </button>

                        <button
                            @click="gotoEdit"
                            v-bind:disabled="editButtonEnabled"
                            class="button is-login"
                        >
                            編集&nbsp;<img
                                src="//images/ico-pen.png"
                                alt=""
                            />
                        </button>

                        <button
                            @click="showModalDelete = true"
                            v-bind:disabled="deleteButtonEnabled"
                            data-remodal-target="modal"
                            class="button basic is-red"
                        >
                            削除&nbsp;<img
                                src="//images/ico-trash.png"
                                alt=""
                            />
                        </button>
                    </div>
                    <div class="column column-search">
                        <!--<search-input-component @search="onSearch"></search-input-component>-->
                        <p class="input-search tr">
                            <input
                                type="text"
                                placeholder="検索"
                                v-model="keyword"
                                @change="onSearch($event.target)"
                                style="padding-right:2.2em;"
                            /><img
                                src="//images/ico-search.png"
                                alt=""
                            />
                        </p>
                        <button
                            class="btn btn-clear btn-round"
                            @click="clearConditions"
                            :disabled="!keyword.length"
                        >
                            クリア
                        </button>
                    </div>
                </div>
                <!-- -->
                <div class="table-wrapper">
                    <table id="table000" class="basic scrollTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th
                                    @click="onSort('user_name_kana')"
                                    style="cursor:pointer;"
                                >
                                    ユーザー名&nbsp;&nbsp;
                                    <img
                                        v-if="axis == 'user_name_kana' && order == 'asc'"
                                        src="/images/ico-down.png"
                                        alt=""
                                    />
                                    <img
                                        v-if="axis == 'user_name_kana' && order == 'desc'"
                                        src="/images/ico-up.png"
                                        alt=""
                                    />
                                    <img
                                        v-if="axis == 'org_name'"
                                        src="/images/ico-down-thin.png"
                                        alt=""
                                    />
                                </th>
                                <th>メールアドレス</th>
                                <th
                                    @click="onSort('org_name')"
                                    style="cursor:pointer;"
                                >
                                    部署名&nbsp;&nbsp;
                                    <img
                                        v-if="axis == 'org_name' && order == 'asc'"
                                        src="/images/ico-down.png"
                                        alt=""
                                    />
                                    <img
                                        v-if="axis == 'org_name' && order == 'desc'"
                                        src="/images/ico-up.png"
                                        alt=""
                                    />
                                    <img
                                        v-if="axis != 'org_name'"
                                        src="/images/ico-down-thin.png"
                                        alt=""
                                    />
                                </th>
                                <th>権限</th>
                                <th>所属グループ</th>
                            </tr>
                        </thead>

                        <tr v-show="users.length == 0">
                            <td colspan="6">ユーザーが存在しません。</td>
                        </tr>
                        <template
                            v-for="user in users"
                            style="border:0;overflow-y:scroll;"
                        >
                            <tr :key="user.userId">
                                <td class="check">
                                    <!--<label>
                                        <input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                                        <span class="checkbox01-parts"></span>
                                    </label>-->
                                    <base-check-component
                                        v-on:check-state-change="
                                            onCheckStateChange(user)
                                        "
                                        v-bind:value="user.selected"
                                    ></base-check-component>
                                </td>
                                <td class="user">{{ user.userName }}</td>
                                <td class="email">{{ user.mailAddress }}</td>
                                <td class="area">{{ user.orgName }}</td>
                                <td class="edit">{{ user.grantName }}</td>
                                <td class="group"><div>
                                    <span v-if="user.groups.length == 1">{{
                                        user.groups[0].group_name
                                    }}</span>
                                    <span v-if="user.groups.length > 1"
                                        >{{
                                            user.groups[0].group_name
                                        }}...</span
                                    >
                                    <span v-else></span>

                                    <img
                                        v-if="
                                            user.groups.length > 1 &&
                                                !user.isGroupsOpen
                                        "
                                        class=""
                                        style="cursor:pointer;"
                                        src="//images/ico-plus2.png"
                                        alt=""
                                        @click="
                                            user.isGroupsOpen = !user.isGroupsOpen
                                        "
                                    />
                                    <img
                                        v-if="
                                            user.groups.length > 1 &&
                                                user.isGroupsOpen
                                        "
                                        class=""
                                        style="cursor:pointer;"
                                        src="//images/ico-minus.png"
                                        alt=""
                                        @click="
                                            user.isGroupsOpen = !user.isGroupsOpen
                                        "
                                    /></div>
                                </td>
                            </tr>
                            <tr :key="user.userId + '-2'" v-if="user.isGroupsOpen" class="grouplist">
                                <td colspan="6">
                                        <template v-for="group in user.groups">
                                            <a
                                                href=""
                                                style="text-decoration: none;"
                                                class="button basic is-tags"
                                                @click.stop.prevent
                                                >{{ group.group_name }}</a
                                            >
                                        </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>

                <!-- モーダルダイアログ -->
                <modal-component
                    v-bind:is-delete="true"
                    v-if="showModalDelete"
                    is-quest="true"
                    width="600"
                    @modalYes="execDelete"
                    @modalNo="showModalDelete = false"
                >
                    <h4 slot="header"><!--ユーザー削除の確認--></h4>
                    <h3 slot="body" style="text-align: center;">
                        <!--<h1 clss="tc"><img src="//images/ico-q.png" alt=""></h1>
                        <p>選択されたユーザーを削除しますか？</p>-->
                        <p class="tc pb2em" style="margin-top:0.5em;">
                            <strong>選択されたユーザーを削除しますか？</strong>
                        </p>
                    </h3>
                </modal-component>
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
import logic from './js/UserList.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import SearchInputComponent from '../../commons/SearchInputComponent.vue';

import ModalComponent from '../../commons/ModalComponent.vue';

import PlusMinus from '../../commons/PlusMinus.vue';

logic.components = {
    CommonLayoutComponent2,
    BaseCheckComponent,
    SearchInputComponent,
    PlusMinus,
    ModalComponent,
};

export default logic;
</script>

<style scoped lang="scss">
table.basic tr {
    th img {
        padding-top: 3px;
        vertical-align: top;
    }

    td{
        text-align: left;
        border-bottom: none;
        &:nth-of-type(1){
            text-align: center;
            width: 5%;
        }
        &:nth-of-type(2){
            width: 24%;
        }
        &:nth-of-type(3){
            width: 24%;
        }
        &:nth-of-type(4){
            width: 24%;
        }
        &:nth-of-type(5){
            text-align: center;
            width: 8%;
        }
        &:nth-of-type(6){
            width: 15%;
        }
        &.group div{
            display: flex;
            span{
                flex-grow: 1;
            }
            img{
                position: static;
                align-self: center;
                width: 18px;
                height: 18px;
            } 
        }                                        
    }
    &:last-of-type{
        td{
            border-bottom: solid 1px #ccc;
        }
    }
}
table.basic .grouplist{
    td{
        position: relative;
        background-color: #E8F8E2;
        text-align: left;
        border-top: none;
        padding: 5px 10px;

        .button.is-tags {
            background: none;
            margin: 5px 10px;
        }

        &::after{
            content: "";
            position: absolute;
            top: -22px;
            right: 7%;
            border: 12px solid transparent;
            border-bottom: 12px solid #E8F8E2;
        }
        &:nth-of-type(1){
            text-align: left;
        }
    }
}
</style>
