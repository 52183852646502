<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" title="みそシル教室" main-class="home">
        <template v-slot:body v-if="lessons">
            <section class="popular bg_yellow">
                <div class="container">
                    <div class="caption">
                        <h1>人気のレッスン</h1>
                    </div>
                    <div class="h_scroll_container">
                        <div class="flex_nw">
                            <template v-for="(item ,index) in $config.parts">
                                <button :key="index" @click="$libs.pushUrl(['search','result'],{query:{categories:item.value}})" class="cat_tab">{{ item.name }}</button>
                            </template>
                        </div>
                    </div>
                    <div class="lessons">
                        <template v-for="(lesson, index) in lessons">
                            <lesson-cassette-component :key="index" :lesson="lesson">
                            </lesson-cassette-component>
                        </template>
                    </div>
                    <button class="button" @click="$libs.pushUrl(['search'])">レッスンを探す</button>
                </div>
            </section>
            <section class="bg_white">
                <div class="container">
                    <div class="caption">
                        <h1 class="key_color">人気の講師</h1>
                    </div>
                    <div class="teachers">
                        <template v-for="(user, index) in teachers">
                            <user-cassette-component :key="index" :user="user">
                            </user-cassette-component>
                        </template>
                    </div>
                    <button class="button" @click="$libs.pushUrl(['search'],{query:{view:'teacher'}})">講師を探す</button>
                </div>
            </section>
        </template>    
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';
import LessonCassetteComponent from '../../miso/user/LessonCassetteComponent.vue';
import UserCassetteComponent from '../../miso/user/UserCassetteComponent.vue';

export default {
    components: {
            CommonLayoutComponent,
            ModalComponent,
            LessonCassetteComponent,
            UserCassetteComponent,
    },
  data: function () {
    return {
        teachers:null,
        lessons:null,
    };
  },
  created: function () {},
    computed: {
    },
  mounted: function () {

      // ユーザー情報取得
      var handler = Vue.ELearning.appInfo.summary();

      handler.then((response) => {
        var content = response.data.content
        if (content) {
            this.teachers = content.teachers;
            this.lessons = content.lessons;            
        } else {
            throw new Error("アプリ情報の取得に失敗しました", response);
        }

      }).catch((response) => {
          this.$dialog.message( "アプリ情報の取得に失敗しました。" )
        console.error(response);
      });
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
</style>
