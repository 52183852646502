<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" title="ご利用ガイド" class-name="guide_page">
        <template v-slot:body>
                <section class="u-bg-yellow">
                <div class="list c-list u-pt20">
                    <h3 class="c-list__title">まなぶ</h3>
                    <ul>
                        <li class="c-list__item">
                            <a href="#">レッスンをさがす<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>ホーム画面の「レッスンを探す」をタップします</li>
                                    <li>カテゴリやジャンル、講師情報などで検索してお好みのレッスンを探してください</li>
                                    <li>検索結果をタップすると、レッスンの詳細を確認できます</li>
                                </ul>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">講師をさがす<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>ホーム画面の「講師を探す」をタップします</li>
                                    <li>年齢層や楽器、得意ジャンルなどで検索してお好みの講師を探してください</li>
                                    <li>検索結果をタップすると、講師の情報と登録されているレッスンを確認できます</li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">レッスンを購入する<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>購入するレッスンを選択します</li>
                                    <li>レッスン料を確認し、「購入する」をタップします</li>
                                    <li>購入したレッスンの試聴期限は購入日から180日間です</li>
                                </ul>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">レッスンを受ける<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイレッスン画面で購入したレッスンをタップします</li>
                                    <li>レッスン情報の説明や概要動画をみます</li>
                                    <li>Step１をタップして、練習します<br> 講師にききたいことがあったら「トップに戻る」をタップして「Q&amp;A」タブから質問します（レッスンによって有償の場合があります）
                                    </li>
                                    <li>できるようになったら「次のステップへ」をタップします</li>
                                    <li>すべてのステップをクリアしたら、講師に採点をリクエストしましょう</li>
                                </ul>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">採点してもらう<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイレッスン画面で修了したレッスンをタップします</li>
                                    <li>「採点」タブを選択し、「採点してもらう」をタップします（レッスンによって有償の場合があります）</li>
                                    <li>レッスン内容を実演した動画を選択して、講師に伝えたいコメントを入力します</li>
                                    <li>採点結果を公開するか選択して投稿します 採点結果を公開すると、ユーザー名、実演動画、点数が公開され、ランキング対象になります</li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">講師に質問する<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイレッスン画面で購入したレッスンをタップします</li>
                                    <li>「Q&amp;A」タブを選択し、「質問する」をタップします（レッスンによって有償の場合があります） </li>
                                    <li>講師への質問を入力します 動画を添付することも可能です</li>
                                    <li>質問内容を公開するか選択して投稿します 質問内容を公開すると、ユーザー名、実演動画、質問が公開されます</li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">プロフィール編集・アカウント設定<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイページ画面の「個人情報設定」をタップします</li>
                                    <li>必要な情報を編集します</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="list c-list">
                    <h3 class="c-list__title">おしえる</h3>
                    <ul>
                        <li class="c-list__item"><a href="#">レッスンを作る<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイレッスン画面の「レッスンをつくる」をタップします</li>
                                    <li>レッスンをイメージできる動画や画像、タイトル、レッスン内容の説明、目指すゴールを入力します</li>
                                    <li>カテゴリ（楽器の種類）やジャンル、難易度を選びます</li>
                                    <li>レッスンの販売価格を設定します</li>
                                    <li>採点や質問の回答を有料にする場合は、1回あたりの価格を「採点・質問（円/回）」に入力します（期限内に回答しなかった場合は入金されません）</li>
                                    <li>「ステップの登録」をタップして、レッスンをステップ毎にわけて動画や説明を登録します（1ステップをなるべく短い動画にして、クリアしやすくしましょう） レッスンの入力が終わったら、「公開する」をタップして販売を開始しましょう
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">質問や採点リクエストを受け取ったら<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>画面右上の「やること」をタップします
                                    </li>
                                    <li>内容を確認し、返信します</li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">売上の管理<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイページ画面で「XXXXXX」をタップします</li>
                                    <li>売上確認、振込申請、入金履歴、返金？</li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">講師をやめる<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイページ画面で「XXXXXX」をタップします</li>
                                    <li>売上確認、振込申請、入金履歴、返金？</li>
                                    <li></li>
                                </ul>
                            </div>
                        </li>
                        <li class="c-list__item"><a href="#">プロフィール編集・アカウント設定<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li>マイページ画面の「個人情報設定」をタップします
                                    </li>
                                    <li>必要な情報を編集します</li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">売上の管理<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">振込先<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">入金<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">返金<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                        </li>
                        <li class="c-list__item"><a href="#">講師をやめる<i class="fas fa-angle-down"></i></a>
                            <div class="item_contents c-list__item__contents">
                                <ul>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>

                        </li>
                    </ul>
                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    export default {
        components :{
            CommonLayoutComponent
        },
        mounted:function(){
            $(".list li a").click(function() {
                $(this).toggleClass("is-active").next(".item_contents").slideToggle(300);
                return false;
            });
        },
    }
</script>
