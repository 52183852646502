<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="ステップの登録" main-class="lesson_page">
        <template v-slot:body>
            <section class="post create_steps bg_yellow">
                <div class="container pt20">
                    <template v-for="(step,index) in steps">
                    <div class="box step" :key="index">
                        <h2 class="">Step {{step.work_procedure_index}}</h2>

                        <div class="box">
                            <input type="text" placeholder="ステップを入力" v-model="step.title">

                        </div>
                        <lesson-video-input-component :video="step.video" :index="index" @change-video="onChangeVideo($event, index)" />
                        <div class="box">
                            <h3>説明（任意）</h3>
                            <textarea placeholder="タップして入力" v-model="step.description"></textarea>
                        </div>
                        <div class="box">
                            <h3>ポイント（任意）</h3>
                            <textarea class="notice" placeholder="タップして入力"  v-model="step.notice"></textarea>
                        </div>
                    </div>
                    </template>
                    <div v-if="isEnabledAddStep" class="add_step" @click="onAddStep">
                        <p><i class="fas fa-plus-circle"></i>ステップを追加する</p>
                    </div>
                    <div class="buttons">
                        <button class="button md" @click="post">保存する</button>
                    </div>

                </div>
            </section>

            <modal-component v-if="showModalSaved" title="保存しました" :view-plain="true" @ok="saved"/>
        </template>            
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import LessonVideoInputComponent from '../../miso/user/LessonVideoInputComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonVideoInputComponent, 
        },
        props: {
            lessonId: {
                type: Number,
            },
        },
        data: function () {
            return {
                steps:[],
                selectedVideo: null,
                showModalSaved: false,

                arrowLeave:false,
            }
        },
        computed: {
            isEnabledAddStep: function(){
                return this.steps.length < process.env.MIX_MAX_LESSON_STEP_NUM
            }
        },
        mounted:function(){
            this.getData()
        },
        methods:{

            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTask(this.lessonId);
                taskHandler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            const lesson = res.data.content;
                            this.steps = lesson.steps
                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },
            
            post: function(){
                Promise.resolve()
                .then(response => {
                    return Promise.all(
                        this.steps.map(step => {

                        //ビデオアップロード
                        if(step.uploadVideoFile){
                            let fd = new FormData(); //★②
                            fd.append('fileData', step.uploadVideoFile);
                            if(step.uploadThumbnailBlob){
                                fd.append('thumbnail', this.uploadThumbnailBlob);
                            }
                            var config = {
                                headers: { 'Content-Type': 'multipart/formdata' }
                            };
                            return Vue.ELearning.Assets.upload(fd, config)
                            .then(response => {
                                if(response){
                                    if (response.data.status_number == 200) {
                                        var content = response.data.content;
                                        console.log('asset upload completed:', content);
                                        if (content.hasFile == 'OK') {
                                            step.video_id = content.video.video_id;
                                
                                            step.video = content.video
                                            step.uploadVideoFile = null
                                            step.uploadThumbnailBlob = null
                                
                                        } else {
                                            // アップロード失敗
                                            this.currentFileErr = 'ファイルのアップロードに失敗しました。';
                                        }
                                    } else {
                                       throw new Error('ファイルのアップロードに失敗しました');
                                    }
                                }else{
                                }
                            })
                        }
                    }))
                })
                .then(response => {
                    console.log("save:",this.steps)
                    return Vue.ELearning.Checklist.save(this.steps);
                })
                .then(response => {
                    if (response.data.status_number == 200) {
                        var content = response.data.content;
                        if (content.result == 'NG') {
                            // バリデーションエラー
                            if (content.errors.title != undefined) {
                                this.$dialog.message(content.errors.title.join(" "));
                            }

                        } else {
                            this.allowLeave = true;
                            //this.back();
                             this.showModalSaved = true;
                        }

                    } else {
                        this.$dialog.message('ステップの保存に失敗しました');
                        throw new Error('ステップの保存に失敗しました');
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.$dialog.message(error.message);
                    Vue.ELearning.Util.hideOverlay(this.$store);
                })
            },

            onChangeVideo: function(file, index){
                console.log("index:", index)
                //this.steps[index].video = file
                this.steps[index].uploadVideoFile = file

                const self = this

                //videoの場合はサムネイルを作成
                this.$store.commit('overlay', true);
                this.$libs.createThumbnails(file,function(thumbnail){

                    if(thumbnail){
                        var url = (window.URL || window.webkitURL);
                        self.steps[index].uploadThumbnailBlob = thumbnail;
                    }else{
                        console.log("no video or thumnail create errer")
                    }
                    self.$store.commit('overlay', false);
                })


                console.log("video:", this.steps[index].uploadVideoFile)
            },

            onAddStep: function() {
                var step = {
                    task_id: this.lessonId,
                    work_procedure_index: this.steps.length + 1,
                    title: "",
                    description: "",
                    notice: "",
                    video_id: null,
                    uploadThumbnailBlob: null,
                };

                this.steps.push(step);
            },
            saved: function(){
                this.$libs.pushUrl(['lesson',this.lessonId, 'edit'])
            },
            
            onNaviBack: function(){
                this.$libs.pushUrl(['lesson',this.lessonId, 'edit'])
            },

    }
}
</script>
<style lang="scss" scoped>
</style>
