export default {
    install(Vue, options) {

        Vue.prototype.$libs = {

            createThumbnails(file, callback) {
                // reset data

                if (!file || !file.type.match('video/*')) {
                    callback(false)
                    return
                }
                // read file
                const reader = new FileReader()
                reader.onload = (evt) => {
                    this.src = evt.target.result
                    this.captureVideo(this.src, callback)
                }
                reader.readAsDataURL(file)
            },
            captureVideo(src, callback) {
                const video = document.createElement('video')
                const canvas = document.createElement('canvas')
                const context = canvas.getContext('2d')

                // set canvas
                video.onloadeddata = () => {
                    canvas.width = video.videoWidth
                    canvas.height = video.videoHeight
                    video.currentTime = 0.1
                }

                // capture thumbnail
                video.onseeked = () => {
                    const MAX_SIZE = 640;
                    var dstWidth, dstHeight;
                    if (video.videoWidth > video.videoHeight) {
                        dstWidth = MAX_SIZE;
                        dstHeight = video.videoHeight * MAX_SIZE / video.videoWidth;
                    } else {
                        dstHeight = MAX_SIZE;
                        dstWidth = video.videoWidth * MAX_SIZE / video.videoHeight;
                    }
                    canvas.width = dstWidth;
                    canvas.height = dstHeight;
                    context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight, 0, 0, dstWidth, dstHeight)
                    const base64 = canvas.toDataURL('image/png')
                    const thumbnail = this.base64toBlob(base64)
                    callback(thumbnail)
                }

                // set video source
                video.src = src
                video.load()
            },
            base64toBlob(base64) {
                var bin = atob(base64.replace(/^.*,/, ''));
                var buffer = new Uint8Array(bin.length);
                for (var i = 0; i < bin.length; i++) {
                    buffer[i] = bin.charCodeAt(i);
                }
                try {
                    var blob = new Blob([buffer.buffer], {
                        type: 'image/png'
                    });
                } catch (e) {
                    return false;
                }
                return blob;
            },

            /* get url */
            url(paths, option) {
                var path;
                if (paths instanceof Array) {
                    path = paths.join("/")
                } else {
                    path = paths
                }

                var query = ""
                if (option) {
                    var queries = []
                    if (option.query) {
                        Object.keys(option.query).forEach(function(key) {
                            queries.push(key + "=" + option.query[key])
                        })
                        if (queries.length > 0) {
                            query = "?" + queries.join("&")
                        }
                    }
                }
                return process.env.MIX_APP_URL + "/" + path + query
            },
            /* get push url */
            urlObj() {

                var url = decodeURI(location.href)
                    /*クエリパラメータを取得 */
                const regex = /[?&]([^=#]+)=([^&#]*)/g;
                const params = {};
                let match;
                while (match = regex.exec(url)) {
                    params[match[1]] = match[2];
                }

                return {
                    url: location.href,
                    query: params
                }
            },
            /* push url */
            pushUrl(paths, option) {

                location.href = this.url(paths, option)
            }
        };

        // Vue.$libs でも使えるようにする
        Vue.$libs = Vue.prototype.$libs;
    }
}