<template>
    <common-layout show-back="true" show-admin="true" @on-nav-back="back">
        <template v-slot:nav-message>
            <div class="col-md-1">
                <p>チェックリストプレビュー</p>
            </div>
        </template>
        <template v-slot:body>
            <keep-alive>
                <check-list
                    :procedures="procedures"
                    :target="target"
                    :content-height="contentHeight"
                />
            </keep-alive>
            <confirm-modal
                v-if="showConfirmModalLeave"
                :continue-func="leave"
                :close-func="closeConfirmModalLeave"
                yes-text="このページを離れる"
                no-text="キャンセル"
                title="確認"
                message="このページを離れますか？行った変更が保存されない可能性があります。保存する場合は編集画面に戻ってください。"
            />
        </template>
    </common-layout>
</template>

<script>
import CommonLayout from '../../commons/CommonLayoutComponent2.vue';
import CheckList from './components/CheckList.vue';
import ConfirmModal from '../common/components/ConfirmModal.vue';

export default {
    components: {
        CommonLayout,
        CheckList,
        ConfirmModal,
    },
    data() {
        return {
            bodyOffsetTop: 0,
            showConfirmModalLeave: false,

            allowLeave: false,
            nextPath: "",
        };
    },
    computed: {
        target() {
            return this.$store.state.currentProcedureForPreview;
        },
        procedures() {
            return this.$store.state.preview;
        },
        contentHeight() {
            const winHeight = this.$store.state.winHeight;
            const realHeight = winHeight - this.bodyOffsetTop - 12;

            this.layoutManager();

            return realHeight;
        },
    },
    mounted() {
        this.layoutManager();
        
        //ページ離脱アラート
        window.onbeforeunload = function(e) {
            e.returnValue = "このページを離れますか？"; // returnValueのメッセージは使用できなくなった（Chromeデフォルトメッセージが使用される）
        }
    },
    methods: {
        back() {
            const path = this.$store.state.previewOriginUrl;
            this.$router.push({ path });
        },
        layoutManager() {
            this.$nextTick(() => {
                // メインヘッダ・サブヘッダ・ウィンドウの高さ取得
                const windowHeight = jQuery(window).height();
                this.bodyOffsetTop = jQuery('.mainCol-2').offset().top - 30;
            });
        },
        leave() {
            this.allowLeave = true;
            this.$router.push({ path: this.nextPath });
        },
        closeConfirmModalLeave() {
            this.showConfirmModalLeave = false;
        },
    },

    beforeRouteLeave(to, from, next) {

        //preview・loginページに遷移/保存済み/遷移確認済ならOK
        if (to.path == this.$store.state.previewOriginUrl || to.path == "/login" || this.allowLeave) {
            window.onbeforeunload = null;
            next();
        }

        //アラートを表示
        this.nextPath = to.path;
        this.showConfirmModalLeave = true;
        next(false);
    },

};
</script>
