<template>
    <table class="basic scrollTable">
        <thead>
            <tr>
                <th></th>
                <th
                    class="select-user-table-sortheader"
                    @click="sort('user_name_kana')"
                >
                    <span>ユーザー名&nbsp;</span>
                    <img
                        v-show="sortAxis == 'user_name_kana' && sortOrder == 'asc'"
                        src="/images/ico-down.png"
                        alt=""
                    />
                    <img
                        v-show="sortAxis == 'user_name_kana' && sortOrder == 'desc'"
                        src="/images/ico-up.png"
                        alt=""
                    />
                    <img
                        v-show="sortAxis == 'org_name'"
                        src="/images/ico-down-thin.png"
                        alt=""
                    />
                </th>
                <th
                    class="select-user-table-sortheader"
                    @click="sort('org_name')"
                >
                    <span>部署名&nbsp;</span>
                    <img
                        v-show="sortAxis == 'org_name' && sortOrder == 'asc'"
                        src="/images/ico-down.png"
                        alt=""
                    />
                    <img
                        v-show="sortAxis == 'org_name' && sortOrder == 'desc'"
                        src="/images/ico-up.png"
                        alt=""
                    />
                    <img
                        v-show="sortAxis == 'user_name_kana'"
                        src="/images/ico-down-thin.png"
                        alt=""
                    />
                </th>
            </tr>
        </thead>
        <tr v-if="users.length == 0">
            <td colspan="3" class="select-user-table-nodata">
                グループに紐付け可能なユーザーが存在しません。
            </td>
        </tr>
        <template v-else v-for="user in users">
            <select-user-table-row :key="user.userId" :user="user" />
        </template>
    </table>
</template>

<script>
import SelectUserTableRow from './SelectUserTableRow.vue';

export default {
    components: {
        SelectUserTableRow,
    },
    props: {
        initialUsers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            users: [],
            sortAxis: 'user_name_kana',
            sortOrder: 'asc',
        };
    },
    mounted() {
        this.users = this.initialUsers;
    },
    methods: {
        sort(axis) {
            if (this.sortAxis == axis) {
                if (this.sortOrder == 'asc') {
                    this.sortOrder = 'desc';
                } else {
                    this.sortOrder = 'asc';
                }
            } else {
                this.sortAxis = axis;
                this.sortOrder = 'asc';
            }

            const groupId = this.$route.params.groupId;
            const handler = Vue.ELearning.Group.getAllUsersByGroup(
                groupId,
                this.sortAxis,
                this.sortOrder,
            );

            return handler
                .then(response => {
                    if (response.data.status_number == 200) {
                        const users = response.data.content || [];

                        this.users = users.map(user => ({
                            userId: user['user_id'],
                            userName: user['user_name'],
                            orgName: user['org_name'],
                            selected:
                                user['admin'] === 1
                                    ? true
                                    : user['selected'] === 1,
                            admin: user['admin'] === 1,
                        }));

                        Vue.ELearning.Util.hideOverlay(this.$store);
                    } else {
                        throw new Error(
                            '関連ユーザー情報を取得できませんでした',
                        );
                    }
                })
                .catch(response => {
                    console.error(response);
                    Vue.ELearning.Util.hideOverlay(this.$store);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.select-user-table {
    &-sortheader {
        cursor: pointer;
    }
    &-nodata {
        border: 1px solid gray;
    }
}
</style>
