<template>
<div>
    <header v-if="!$store.state.isLoggedIn">
        <div class="container pt10 pb10">
            <div class="flex_nw">
                <h1 class="logo">
                 <a :href="$libs.url('')">
                みそシル教室
                </a>
                </h1>
                <div class="logins flex_nw">
                    <a :href="$libs.url('login')">ログイン</a>
                    <a :href="$libs.url('register')" class="register">無料登録</a>
                </div>
            </div>
        </div>
    </header>
    <header v-if="$store.state.isLoggedIn">
        <div class="container pt0 pb0">
            <div class="flex_nw">
                <div class="left back"><a v-if="showBack" @click.stop.prevent="onBack"><i class="fas fa-angle-left"></i></a></div>
                <h1>{{ title }}</h1>
                <div class="right">
                    <a :href="$libs.url('todo')" class="icon">
                        <i class="fas fa-check-circle"></i>
                        <span>やること</span>
                        <span v-if="$store.state.appInfo && $store.state.appInfo.todoCount > 0" class="num">{{ $store.state.appInfo.todoCount }}</span>
                    </a>
                </div>
            </div>
        </div>
    </header>
    <main id="main" :class="[mainClass, {'is-loggedin': $store.state.isLoggedIn }]">
        <slot name="body"></slot>
    </main>
    <tabbar-component v-if="$store.state.isLoggedIn">
        <template v-slot:tabaction>
            <slot name="action"></slot>
        </template>
    </tabbar-component>
    <footer-component v-if="!$store.state.isLoggedIn">
    </footer-component>
</div>

</template>

<script>
import TabbarComponent from './TabbarComponent.vue';
import FooterComponent from './FooterComponent.vue';
export default {
    components: {
        TabbarComponent,
        FooterComponent
    },
    
    props: {
        showBack: Boolean,
        showNaviBar: Boolean,
        title: String,
        mainClass: String,
        requireUserInfo: Boolean,
    },

    data: function() {
        return {
        };
    },

    mounted: function() {
        if(this.requireUserInfo){

            // ログイン済（サーバー側でチェック済）かつユーザ情報が存在しない場合は（キャッシュ削除など？）ログイン画面へ
           if (!this.$store || !this.$store.state.userInfo) {
                this.$store.commit("logout", {});
                this.$libs.pushUrl("login")
           }
        }
    },
    methods: {
        onBack: function() {
            this.$emit('on-nav-back');
        },

    },

    computed: {
    },

    watch: {
    },

};
</script>

<style scoped lang="scss">
</style>
