var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "購入履歴",
      "main-class": "my_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "bg_yellow" }, [
              _c("div", { staticClass: "container pt20" }, [
                _c(
                  "div",
                  { staticClass: "lessons" },
                  [
                    _vm.purchases && _vm.purchases.length > 0
                      ? [
                          _vm._l(_vm.purchases, function(item, index) {
                            return [
                              _c("div", { key: index, staticClass: "lesson" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.$libs.url([
                                        "lesson",
                                        item.task_id
                                      ])
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "contents" }, [
                                      _c(
                                        "div",
                                        { staticClass: "flex_nw" },
                                        [
                                          _c(
                                            "lesson-video-thumbnail-component",
                                            {
                                              attrs: { video: item.task.video }
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "info" }, [
                                            _c("p", { staticClass: "date" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.purchasedDate(
                                                    item.created_at
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("h3", [
                                              _vm._v(_vm._s(item.task.title))
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "price" }, [
                                              _vm._v(
                                                _vm._s(_vm.strType(item.type))
                                              ),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.price.toLocaleString()
                                                  )
                                                )
                                              ]),
                                              _vm._v("円（税込）")
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          })
                        ]
                      : _vm.purchases
                      ? [
                          _c("p", { staticClass: "mt50" }, [
                            _vm._v("購入履歴はまだありません。")
                          ])
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }