var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [
            _c("span", { staticClass: "nav-msg-sub" }, [
              _vm._v(_vm._s(_vm.categoryName))
            ]),
            _vm._v("\n    " + _vm._s(_vm.taskName) + "\n  ")
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "columns is-gapless" }, [
              _c(
                "div",
                {
                  staticClass: "column is-one-quarter",
                  staticStyle: { "overflow-y": "auto" },
                  style: { "max-height": _vm.contentHeight + "px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { margin: "0", padding: "0" },
                      attrs: { id: "sidebar" }
                    },
                    [
                      _c("p", { staticClass: "side-title" }, [
                        _c("b", [_vm._v("作業手順")])
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.checklist, function(item, index) {
                        return [
                          _c("work-procedure", {
                            key: item.uuid,
                            attrs: { index: index, item: item },
                            on: { check: _vm.currentFlowOnChange }
                          })
                        ]
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "tc pt10" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button basic is-gray",
                        staticStyle: { color: "white" },
                        on: { click: _vm.preview }
                      },
                      [_vm._v("プレビュー")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "button basic is-login",
                        staticStyle: { color: "white" },
                        on: { click: _vm.save }
                      },
                      [_vm._v("保存")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("br")
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "column has-background-white",
                  staticStyle: { "overflow-y": "auto" },
                  style: { "max-height": _vm.contentHeight + "px" },
                  attrs: { id: "main-body-content-of-checklist" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "contents-inner" },
                    [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("input", {
                            staticClass: "radio02-input",
                            attrs: { type: "radio", id: "radio03-01" },
                            domProps: { checked: _vm.currentKind == "single" },
                            on: {
                              change: function($event) {
                                return _vm.currentKindChange("single")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "radio03-01" } }, [
                            _c("b", [_vm._v("一つの作業手順")])
                          ]),
                          _vm._v(" "),
                          !_vm.isChild
                            ? [
                                _c("input", {
                                  staticClass: "radio02-input",
                                  attrs: { type: "radio", id: "radio03-02" },
                                  domProps: {
                                    checked: _vm.currentKind == "multi"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.currentKindChange("multi")
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("label", { attrs: { for: "radio03-02" } }, [
                                  _c("b", [_vm._v("複数の作業手順")])
                                ])
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("input", {
                            staticClass: "radio02-input",
                            attrs: { type: "radio", id: "radio03-03" },
                            domProps: { checked: _vm.currentKind == "pdf" },
                            on: {
                              change: function($event) {
                                return _vm.currentKindChange("pdf")
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "radio03-03" } }, [
                            _c("b", [_vm._v("PDFアップロード")])
                          ]),
                          _vm._v(" "),
                          _vm.itemIndex === 0
                            ? [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button basic is-gray",
                                    on: { click: _vm.importTaskCheck }
                                  },
                                  [
                                    _vm._v("\n                  インポート "),
                                    _c("img", {
                                      attrs: {
                                        src: "/images/ico-import.png",
                                        alt: ""
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "d-none",
                                  attrs: {
                                    id: "input-file-csv",
                                    type: "file",
                                    name: "file"
                                  },
                                  on: { change: _vm.selectedUploadCsv }
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "button basic is-gray",
                                    on: { click: _vm.exportTaskCheck }
                                  },
                                  [
                                    _vm._v("\n                  エクスポート "),
                                    _c("img", {
                                      attrs: {
                                        src: "/images/ico-export.png",
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "field" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("作業手順")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model:balue",
                                value: _vm.currentWorkProcName,
                                expression: "currentWorkProcName",
                                arg: "balue"
                              }
                            ],
                            staticClass: "input",
                            attrs: { type: "text", placeholder: "" },
                            domProps: { value: _vm.currentWorkProcName },
                            on: {
                              change: function($event) {
                                return _vm.currentNameChange(
                                  $event.target.value
                                )
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.currentWorkProcName = $event.target.value
                              }
                            }
                          })
                        ])
                      ]),
                      _vm._v(" "),
                      !_vm.hideContent
                        ? [
                            _c("div", { staticClass: "field" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("動画/画像")
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "control columns" },
                                [
                                  _vm.isOverFileSizeSum
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "alert alert-warning",
                                            attrs: { role: "alert" }
                                          },
                                          [
                                            _c(
                                              "strong",
                                              { staticClass: "text" },
                                              [
                                                _vm._v(
                                                  "使用可能容量を超過しているので、これ以上アップロードできません。"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.isUploadDisabled,
                                                expression: "!isUploadDisabled"
                                              }
                                            ],
                                            staticClass: "column"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                attrs: { id: "droparea" },
                                                on: {
                                                  dragover: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.checkDrag(
                                                      $event,
                                                      "new",
                                                      true
                                                    )
                                                  },
                                                  drop: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.onDrop.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  { staticClass: "leads" },
                                                  [
                                                    _vm._v(
                                                      "\n                            ここにファイルをドラッグ\n                          "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("p", [_vm._v("または")]),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "button basic is-gray",
                                                      attrs: {
                                                        disabled:
                                                          _vm.isUploadDisabled
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                              ファイルを選択\n                              "
                                                      ),
                                                      _c("input", {
                                                        staticClass:
                                                          "drop__input",
                                                        staticStyle: {
                                                          display: "none"
                                                        },
                                                        attrs: {
                                                          type: "file",
                                                          id:
                                                            "corporation_file",
                                                          disabled:
                                                            _vm.isUploadDisabled
                                                        },
                                                        on: {
                                                          change: _vm.onDrop
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "caution" },
                                                  [
                                                    _vm._v(
                                                      "100MB以上の動画はアップロードできません。"
                                                    ),
                                                    _c("br"),
                                                    _vm._v(
                                                      "アップロードできるファイル形式は、動画：MP4、画像：PNG・JPEG、PDFです。"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.currentFileErr != "",
                                          expression: "currentFileErr != ''"
                                        }
                                      ],
                                      staticClass: "column"
                                    },
                                    [
                                      _c("label", { staticClass: "label" }, [
                                        _vm._v(_vm._s(_vm.currentFileErr))
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isUploadDisabled,
                                          expression: "isUploadDisabled"
                                        }
                                      ],
                                      staticClass: "column"
                                    },
                                    [
                                      _vm.isVideo
                                        ? _c(
                                            "div",
                                            [
                                              _c("video-component", {
                                                attrs: {
                                                  videoSrc: _vm.currentFilePath
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm.isPdf
                                        ? _c("div", [
                                            _c(
                                              "label",
                                              { staticClass: "label" },
                                              [_vm._v("PDF")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "object",
                                              {
                                                staticStyle: {
                                                  height: "1170px"
                                                },
                                                attrs: {
                                                  data: _vm.currentFilePath,
                                                  type: "application/pdf",
                                                  width: "80%"
                                                }
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.currentFilePath,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.currentFileName
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm.isImage
                                        ? _c("div", [
                                            _c("img", {
                                              staticClass: "img-responsive",
                                              attrs: {
                                                src: _vm.currentFilePath
                                              }
                                            })
                                          ])
                                        : _c("div", [
                                            _c(
                                              "label",
                                              { staticClass: "label" },
                                              [_vm._v("ファイル")]
                                            )
                                          ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "column is-one-quarter rel"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isUploadDisabled,
                                              expression: "isUploadDisabled"
                                            }
                                          ],
                                          staticClass: "button basic is-red",
                                          attrs: {
                                            id: "delete-file",
                                            disabled: !_vm.isUploadDisabled
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showConfirmModalDeleteFile = true
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                    ファイルを削除\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                2
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "field" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("操作説明文")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "control" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model:balue",
                                      value: _vm.currentWorkProcText,
                                      expression: "currentWorkProcText",
                                      arg: "balue"
                                    }
                                  ],
                                  staticClass: "textarea",
                                  domProps: { value: _vm.currentWorkProcText },
                                  on: {
                                    change: function($event) {
                                      return _vm.currentTextChange(
                                        $event.target.value
                                      )
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.currentWorkProcText =
                                        $event.target.value
                                    }
                                  }
                                })
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "field  pb2em" }, [
                              _c("label", { staticClass: "label" }, [
                                _vm._v("注意文")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model:balue",
                                      value: _vm.currentWorkProcNotice,
                                      expression: "currentWorkProcNotice",
                                      arg: "balue"
                                    }
                                  ],
                                  staticClass: "input",
                                  attrs: { type: "text", placeholder: "" },
                                  domProps: {
                                    value: _vm.currentWorkProcNotice
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.currentNoticeChange(
                                        $event.target.value
                                      )
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.currentWorkProcNotice =
                                        $event.target.value
                                    }
                                  }
                                })
                              ])
                            ])
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "contents-inner2 top-border" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.hasChild || _vm.isChild,
                            expression: "hasChild || isChild"
                          }
                        ],
                        staticClass: "columns"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "column",
                            staticStyle: { "text-align": "center" }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "button is-login",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.addChildProcedure.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v("次のサブ手順を追加 "),
                                _c("img", {
                                  attrs: {
                                    src: "/images/ico-plus-w.png",
                                    alt: ""
                                  }
                                })
                              ]
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isChild,
                                    expression: "isChild"
                                  }
                                ],
                                staticClass: "button basic is-red",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.showConfirmModalDeleteInner = true
                                  }
                                }
                              },
                              [
                                _vm._v("このサブ手順を削除 "),
                                _c("img", {
                                  attrs: {
                                    src: "/images/ico-minus-w.png",
                                    alt: ""
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "padding-left": "2em",
                          "padding-right": "2em"
                        }
                      },
                      [
                        _c("div", { staticClass: "columns" }, [
                          _c("p", { staticClass: "column" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-login",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.addPrev.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/ico-prev.png",
                                    alt: ""
                                  }
                                }),
                                _vm._v(" 前に作業手順を追加")
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "column tr" }, [
                            _c("p", { staticClass: "pb2em" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-login",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.addNext.apply(null, arguments)
                                    }
                                  }
                                },
                                [
                                  _vm._v("次の作業手順を追加 "),
                                  _c("img", {
                                    attrs: {
                                      src: "/images/ico-next.png",
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.showDeleteProc
                              ? _c("p", [
                                  !_vm.isChild
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "button basic is-red",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              $event.preventDefault()
                                              return _vm.showConfirmDelete.apply(
                                                null,
                                                arguments
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v("この作業手順を削除 "),
                                          _c("img", {
                                            attrs: {
                                              src: "/images/ico-trash.png",
                                              alt: ""
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _vm.showConfirmModal
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.deleteProc,
                    "close-func": _vm.closeConfirmModal,
                    title: "サブ手順削除の確認",
                    message: "サブ手順もすべて削除されますが、よろしいですか？"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showConfirmModalDeleteFile
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.onFileDelete,
                    "close-func": _vm.closeConfirmModalDeleteFile,
                    title: "確認",
                    message: "ファイルを削除してよろしいですか？"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showConfirmModalDeleteProc
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.deleteProc,
                    "close-func": _vm.closeConfirmModalDeleteProc,
                    title: "確認",
                    message: "この作業手順を削除してよろしいですか？"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showConfirmModalDeleteInner
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.deleteInner,
                    "close-func": _vm.closeConfirmModalDeleteInner,
                    title: "確認",
                    message: "このサブ手順を削除してよろしいですか？"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showConfirmDeleteStepChild
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.deleteProc,
                    "close-func": _vm.closeConfirmModalDeleteStepChild,
                    title: "確認",
                    message: "サブ手順も全て削除されますがよろしいですか？"
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.showConfirmModalLeave
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.leave,
                    "close-func": _vm.closeConfirmModalLeave,
                    "yes-text": "このページを離れる",
                    "no-text": "キャンセル",
                    title: "確認",
                    message:
                      "このページを離れますか？行った変更が保存されない可能性があります。"
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }