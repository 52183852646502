<template>
    <div>
        <input type="text" class="form-control" placeholder="検索" v-model:value="searchText" @keydown.enter="onSearch" style="background-color:#eaeaea;padding-right:2.1em;">
        <i class="fas fa-search search-icon" v-bind:class="{'search-icon-active': searchEnabled}" v-bind:style="{cursor:cursorType}"  @click="onSearch"></i>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
                searchText: ''
            }

        },
        computed: {
            searchEnabled: function(){
                return true;

                /*
                if(this.searchText == undefined) {
                    return false;
                }

                if(this.searchText.length > 0) {
                    return true;
                } else {
                    return false;
                }
                */
            },

            cursorType: function () {
                if(this.searchEnabled) {
                    return "pointer";
                } else {
                    return "normal";
                }
            }
        },

        methods: {
            onSearch: function(){
                if(!this.searchEnabled) {
                    return;
                }

                this.$emit("search", {
                    'searchText':this.searchText
                })
            },
        }
    }
</script>
<style scoped>
    .search-icon {
        font-size:1.5em;
        z-index:10000;
        position:absolute;
        top:0.14em;
        right:1em;
        color:#a0a0a0;

        /*cursor:pointer;*/
    }

    .search-icon-active {
        color:#4FBE2B;
    }
</style>