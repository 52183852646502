/**
 * E-Learningシステム関連API
 * 
 */

import $ from 'jquery';
import router from '../router';
import store from '../store';

const ELearningPlugin = {};
ELearningPlugin.install = function(Vue, options) {
    Vue.ELearning = {};

    /**
     * アプリ情報関連API
     * @type {{current: (function(): *)}}
     */
    Vue.ELearning.appInfo = {
        get: function() {
            return axios.get('/appInfo');
        },
        summary: function() {
            return axios.get('/summary');
        },
    }

    /**
     * 認証API
     * @type {{authenticate: authenticate}}
     */
    Vue.ELearning.Authenticatior = {
        authenticate: function(userId, password, remember) {
            return axios.post('/authenticate', {
                userId: userId,
                password: password,
                // 2019-10-28 kato PWA作業スケジュール No.27（実装そのものを追加）
                remember: remember
            });
        },
        authenticate2: function(userId, password, callback) {
            const handler = axios.post('/authenticate', {
                userId: userId,
                password: password,
            });
            handler
                .then(res => {
                    if (res.data.status_number == 200) {
                        callback(res, null);
                    } else {
                        throw new Error('認証に失敗しました');
                    }
                })
                .catch(res => {
                    callback(null, res);
                });
        },
        // 2019-10-29 kato PWA作業スケジュール No.27（実装そのものを追加）
        autoauthenticate: function(remember) {
            return axios.post('/autoauthenticate', remember);
        },

        verification: function(userId, password, remember, code) {
            return axios.post('/verification', {
                userId: userId,
                password: password,
                remember: remember,
                code: code
            });
        },

        //確認コードの再送信依頼
        resendVerification: function(params) {
            return axios.post('/resend_verification', {
                userId: params.userId,
            });
        },

        // F2 No.109 二重ログイン禁止
        clear: function() {
            return axios.post('/clear');
        }

    };

    /**
     * カテゴリ関連API
     * @type {{getAll: (function(): *)}}
     */
    Vue.ELearning.Category = {
        getAll: function() {
            return axios.get('/categories/all');
        },
        getAllWithSort: function(sortAxis, sortOrder) {
            return axios.get('/categories/all/' + sortAxis + '/' + sortOrder);
        },
        getOne: function(categoryId) {
            return axios.get('/categories/' + categoryId);
        },
        getValid: function() {
            return axios.get('/categories');
        },
        getValidWithTasks: function() {
            return axios.get('/categories/existstasks');
        },

        // カテゴリ登録編集
        insert: function(record) {
            return axios.post('/categories', {
                category_name: record.categoryName,
                category_text: record.categoryDesc,
                display_flag: record.publicity == 'public' ? 1 : 0,
            });
        },
        update: function(record) {
            return axios.put('/categories', {
                category_id: record.categoryId,
                category_name: record.categoryName,
                category_text: record.categoryDesc,
                display_flag: record.publicity == 'public' ? 1 : 0,
            });
        },
        delete: function(categoryIds) {
            return axios.post('/categories/delete', {
                categories: categoryIds,
            });
        },

        /**
         * カテゴリ複製
         * カテゴリおよびタスク、チェックリストを複製し独立したカテゴリとして運用できるようにする。
         * @param categoryId
         * @returns {*|void}
         */
        replicate: function(categoryId) {
            return axios.post('/categories/replicate/' + categoryId);
        },
    };

    /**
     * タグ関連API
     * @type {{getAll: (function(): *)}}
     */
    Vue.ELearning.Tag = {
        /**
         * 全タグを取得する
         * @returns {*}
         */
        getAll: function() {
            return axios.get('/tags');
        },

        getAllBySort: function(axis, order) {
            return axios.get('/tags/' + axis + '/' + order);
        },

        getOne: function(tagId) {
            return axios.get('/tags/' + tagId);
        },

        deleteTag: function(tags) {
            return axios.post('/tags/delete', {
                tags,
            });
        },

        /**
         * 有効タグを取得する
         * @returns {*}
         */
        getValid: function() {
            return axios.get('/tags/enabled');
        },

        /**
         * タスクに紐づくタグを取得する
         * @param taskId
         * @returns {*}
         */
        getTagsByTask: function(taskId) {
            return axios.get('/tags/task');
        },

        insertTag: function(tag) {
            return axios.post('/tags', {
                tag: tag,
            });
        },

        updateTag: function(tag) {
            return axios.put('/tags', {
                tag: tag,
            });
        },
    };

    /**
     * グループ関連API
     * @type {{getAll: (function(): *)}}
     */
    Vue.ELearning.Group = {
        getAll: function(axis, order) {
            if (axis != undefined && order != undefined) {
                return axios.get('/groups/' + axis + '/' + order);
            } else {
                return axios.get('/groups');
            }
        },

        getGroup: function(groupId) {
            return axios.get('/groups/' + groupId);
        },

        insertGroup: function(groupData, taskData, userData) {
            return axios.post('/groups', {
                group: groupData,
                tasks: taskData,
                users: userData,
            });
        },

        updateGroup: function(groupData, taskData, userData) {
            return axios.put('/groups', {
                group: groupData,
                tasks: taskData,
                users: userData,
            });
        },

        deleteGroup: function(groupId) {
            return axios.delete('/groups/' + groupId);
        },

        search: function(keywords, axis, order) {
            if (keywords == undefined || keywords == '') {
                keywords = '_none_';
            }
            if (axis == undefined || axis == '') {
                axis = 'group_name';
            }
            if (order == undefined || order == '') {
                order = 'asc';
            }

            return axios.get(
                '/groups/search/' + keywords + '/' + axis + '/' + order,
            );
        },

        getAllTasksByGroup: function(groupId, axis, order) {
            if (axis != undefined && order != undefined) {
                return axios.get(
                    '/groups/tasks/all/' + groupId + '/' + axis + '/' + order,
                );
            } else {
                return axios.get('/groups/tasks/all/' + groupId);
            }
        },

        getAllUsersByGroup: function(groupId, axis, order) {
            if (axis != undefined && order != undefined) {
                return axios.get(
                    '/groups/users/all/' + groupId + '/' + axis + '/' + order,
                );
            } else {
                return axios.get('/groups/users/all/' + groupId);
            }
        },

        getGroupCsv: function(keywords, axis, order) {
            return axios.get('/groups/csv', {
                responseType: 'arraybuffer',
                params: {
                    keywords: keywords,
                    axis: axis,
                    order: order,
                },
            });
        },

        postUserCsv(csv) {
            return axios.post('/groups/csv', csv, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
        },
    };

    /**
     * タスク関連API
     * @type {{getAll: (function(): *)}}
     */
    Vue.ELearning.Task = {
        getAllForAdmin: function() {
            return axios.get('/tasks/all');
        },

        getPurchased: function() {
            return axios.get('/tasks/purchased');
        },

        getTask: function(id) {
            return axios.get('/tasks/' + id);
        },

        getTaskWithPurchasedUsers: function(id) {
            return axios.get('/tasks/' + id + "/withpurchased");
        },

        getProvidedTask: function(categoryId, tagId, axis, order) {
            if (tagId == undefined) {
                tagId = 9999;
            }
            if (axis == undefined) {
                axis = 'update_datetime';
            }
            if (order == undefined) {
                order = 'desc';
            }

            return axios.get(
                '/tasks/provided/' +
                categoryId +
                '/' +
                tagId +
                '/' +
                axis +
                '/' +
                order,
            );
        },
        getProvidedMultiTask: function(categoryId, listTags, axis, order) {
            if (listTags == undefined) {
                return false;
            }
            if (axis == undefined) {
                axis = 'update_datetime';
            }
            if (order == undefined) {
                order = 'desc';
            }
            listTags = listTags.join(',');

            return axios.get(
                '/tasks/provided/tags_multi/' +
                categoryId +
                '/' +
                listTags +
                '/' +
                axis +
                '/' +
                order,
            );
        },

        getProvidedTags: function(categoryId) {
            //tasks/provided/tags/1/update_datetime/desc
            return axios.get(
                'tasks/provided/tags/' + categoryId + '/update_datetime/desc',
            );
        },

        insertTask: function(task) { return axios.post('/tasks', { task: task }); },
        updateTask: function(task) { return axios.put('/tasks', { task: task }); },
        deleteTasks: function(task) { return axios.post('/tasks/delete', { task: task }); },

        publishTasks: function(task) {
            return axios.post('/tasks/publish', {
                task: task,
            });
        },

        publishGetTasks: function(fileid) {
            return axios.get('/tasks/publish/' + fileid, {
                responseType: 'arraybuffer',
                headers: { Accept: 'application/zip' },
            });
        },

        getAllForAdminWithSort: function(tagId, sortAxis, sortOrder) {
            return axios.get(
                '/tasks/tag/' + sortAxis + '/' + sortOrder + '/' + tagId,
            );
        },

        searchMultiTagForAdmin: function(listTags, sortAxis, sortOrder) {
            if (listTags == undefined) {
                return false;
            }
            if (sortAxis == undefined) {
                sortAxis = "update_datetime";
            }
            if (sortOrder == undefined) {
                sortOrder = "desc";
            }
            listTags = listTags.join(',');

            return axios.get("/tasks/multi_tag/" + sortAxis + "/" + sortOrder + "/" + listTags);
        },

        getAll: function() {
            return axios.get('/tasks');
        },
        getAllForAdminWithTag: function(tagId) {
            return axios.get('/tasks/all/tag/' + tagId);
        },
        getAllWithTag: function(tagId) {
            return axios.get('/tasks/tag/' + tagId);
        },

        /**
         * タスク複製
         *
         * @param {number} task_id タスクの Primary Key
         * @returns {*|void}
         */
        replicate: function(task_id) {
            return axios.post('/tasks/replicate/' + task_id);
        },

        getCsv: function(keywords, axis, order) {
            return axios.get('/tasks/csv', {
                responseType: 'arraybuffer',
                params: {
                    keywords: keywords,
                    axis: axis,
                    order: order,
                },
            });
        },

        postCsv(csv) {
            return axios.post('/tasks/csv', csv, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
        },

        getSummary: function(task_ids) {
            return axios.post('/tasks/summary', { task_id: task_ids });
        },

        insertComment: function(comment) { return axios.post('/comment', { comment: comment }); },
        viewedComment: function(comment) { return axios.post('/comment/viewed', { comments: comment }); },

    };

    /**
     * 購入情報関連API
     * @type {{get: (function(): *)}}
     */
    Vue.ELearning.Purchase = {
        purchase: function(purchase) { return axios.post('/purchase', { purchase: purchase }); },
        purchases: function() { return axios.get('/purchase/purchases'); },
        mySales: function() { return axios.get('/purchase/mysales'); },
    };

    /**
     * 支払い情報関連API
     * @type {{get: (function(): *)}}
     */
    Vue.ELearning.Payment = {
        apply: function() { return axios.post('/payment/apply'); },
        myPayments: function() { return axios.get('/payment'); },
        myPaymentById: function(id) { return axios.get('/payment/' + id); },
        payments: function() { return axios.get('/payment/allpayments'); },
    };

    /**
     * 進捗情報関連API
     * @type {{get: (function(): *)}}
     */
    Vue.ELearning.Search = {
        lessons: function(params) { return axios.get('/search/lessons', { params: params }); },
        teachers: function(params) { return axios.get('/search/teachers', { params: params }); },
    };

    /**
     * チェックリスト関連API
     * @type {{get: (function(*, *): *)}}
     */
    Vue.ELearning.Checklist = {

        get: function() { return axios.get('/checklist'); },
        //getFileSizeSum: function() { return axios.get('/checklist/file_size/sum'); }, //2020/03/13 廃止
        getFileSize: function(work_procedure_id) { return axios.get('/checklist/' + work_procedure_id + '/file_size'); },

        /**
         * ユーザー向けのチェックリスト一覧 ( タスク詳細 ) を取得する
         *
         * @param {number} task_id
         * @return タスク詳細
         */
        getForUser: function(task_id) {
            return axios.get('/tasks/provided-a-task/' + task_id);
        },

        /**
         * 編集用のチェックリスト一覧 ( タスク詳細 ) を取得する F2 No.94 2020/2/14 新規追加　カテゴリ非公開のタスクも取得する用
         *
         * @param {number} task_id
         * @return タスク詳細
         */
        getForEdit: function(task_id) {
            return axios.get('/tasks/task-for-edit/' + task_id);
        },

        save: function(checklist) { return axios.put('/checklist', { checklist: checklist }); },

        getTaskCsv: function(taskId) { return axios.get('/tasks/csv/' + taskId, { responseType: 'arraybuffer' }); },
        postCsv: function(taskId, csv) { return axios.post('/tasks/csv/' + taskId, csv, { headers: { 'content-type': 'multipart/form-data' } }); },
    };

    /**
     * ユーザー情報関連API
     * @type {{current: (function(): *)}}
     */
    Vue.ELearning.UserInfo = {
        current: function() {
            return axios.get('/users/current');
        },

        all: function() {
            return axios.get('/users/all');
        },

        myStudents: function() {
            return axios.get('/users/mystudent');
        },

        allForLog: function() {
            return axios.get('/users/all-for-log');
        },

        getUser: function(userId) {
            return axios.get('/users/' + userId);
        },

        insert: function(user, groups) {
            console.log('insert', user);

            return axios.post('/register', {
                user: user,
            });
        },
        update: function(user) {
            return axios.put('/users', {
                user: user,
            });
        },

        updatePassword: function(user, password, newPassword, confirmPassword) {
            return axios.put('/users/password', {
                user: user,
                password: password,
                new_password: newPassword,
                confirm_password: confirmPassword
            });
        },

        delete: function(userId) {
            return axios.delete('/users/' + userId);
        },

        search: function(keywords, axis, order) {
            if (keywords == '' || keywords == undefined) {
                keywords = '_none_';
            }

            return axios.get(
                '/users/search/keyword/' + keywords + '/' + axis + '/' + order,
            );
        },

        getUserCsv: function(keywords, axis, order) {
            return axios.get('/users/csv', {
                responseType: 'arraybuffer',
                params: {
                    keywords: keywords,
                    axis: axis,
                    order: order,
                },
            });
        },

        postUserCsv(csv) {
            return axios.post('/users/csv', csv, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
        },
    };

    /**
     * 進捗情報関連API
     * @type {{get: (function(): *)}}
     */
    Vue.ELearning.Progress = {
        get() { return axios.get('/progress'); },
        store(progress, complete = false) { return axios.post('/progress', { complete, progress }); },
    };

    /**
     * アサイン関連API
     *
     */
    Vue.ELearning.Assignment = {
        assignGroupCategory: function(groupId, categoryId) {
            return axios.post('/assignment/group/category');
        },
        assignGroupTask: function(groupId, taskId) {
            return axios.post('/assignment/group/task');
        },
        assignGroupUser: function(groupId, userId) {
            return axios.post('/assignment/group/user');
        },

        /**
         *
         * @param {number} task_id タスク Primary Key
         * @param {number[]} tag_ids タグ ID の一覧
         */
        assignTaskTag: function(task_id, tag_ids) {
            return axios.post('/assignment/task/' + task_id + '/tag', { tagIds: tag_ids });
        },

        assignTaskUser: function(categoryId, taskId, userIds) {
            return axios.post(
                '/assignment/task/user/' + categoryId + '/' + taskId, {
                    userIds: userIds,
                },
            );
        },

        /**
         * タスクとグループを紐づける
         *
         * @param {number} task_id タスク Primary Key
         * @param {number[]} group_ids グループ ID の一覧
         */
        assignTaskGroup: function(task_id, group_ids) {
            return axios.post('/assignment/task/' + task_id + '/group', { groups: group_ids });
        },

        unassignGroupCategory: function(groupId, categoryId) {
            return axios.delete('/assignment/group/category');
        },
        unassignGroupUser: function(groupId, userId) {
            return axios.delete('/assignment/group/user');
        },
        unassignTaskTag: function(taskId, tagId) {
            return axios.delete('/assignment/task/tag');
        },
        unassignTaskUser: function(taskId, userId) {
            return axios.delete('/assignment/task/user');
        },

        getCategoriesByGroup: function(groupId) {
            return axios.get('/assignment/group/category');
        },
        getTasksByGroup: function(groupId) {
            return axios.get('/assignment/group/task');
        },
        getUsersByGroup: function(groupId) {
            return axios.get('/assignment/group/user');
        },
        getGroupsByUser: function(userId) {
            return axios.get('/assignment/user/group/' + userId);
        },

        getAllGroupsByUser: function(userId, axis, order) {
            if (axis != undefined && order != undefined) {
                return axios.get(
                    '/assignment/user/group/all/' +
                    userId +
                    '/' +
                    axis +
                    '/' +
                    order,
                );
            } else {
                return axios.get('/assignment/user/group/all/' + userId);
            }
        },

        getTasksByTag: function(tagId) {
            return axios.get('/assignment/tag/task');
        },
        getTagsByTask: function(taskId) {
            return axios.get('/assignment/task/tag');
        },

        /** 全タグの一覧と、各タグが指定した ID のタスクと紐づいているかを取得する */
        getAllTagsByTask: function(task_id) { return axios.get('/assignment/task/' + task_id + '/tag'); },

        getUsersByTask: function(categoryId, taskId, axis, order) {
            if (axis == undefined || order == undefined) {
                return axios.get(
                    '/assignment/user/task/' + categoryId + '/' + taskId,
                );
            } else {
                return axios.get(
                    '/assignment/user/task/' +
                    categoryId +
                    '/' +
                    taskId +
                    '/' +
                    axis +
                    '/' +
                    order,
                );
            }
        },

        getGroupUsersByTask: function(categoryId, taskId, axis, order) {
            return axios.get(
                '/assignment/group/user/task/' + categoryId + '/' + taskId,
            );
        },

        /**
         *
         *
         * @param {number} task_id タスクの Primary Key
         */
        getGroupsByTask(task_id) {
            return axios.get('/assignment/task/' + task_id + '/group');
        },
    };

    /**
     * お知らせ関連API
     * @type {{getAll: (function(): *)}}
     */
    Vue.ELearning.Notice = {
        getAll() {
            return axios.get('/notice');
        },

        readed(id) {

            const data = {
                notice_id: id
            };

            if (!Vue.Download.isOnline()) {
                return Vue.Download.storeLog("notice_read", data);
            }

            return axios.post('/notice_user', data);
        },
    };

    Vue.ELearning.Todo = {
        getAll() {
            return axios.get('/todo');
        },
    };

    Vue.ELearning.Assets = {
        upload: function(fileData, config) {
            return axios.post('/assets', fileData, config);
        },
    };

    Vue.ELearning.Bulk = {
        upload: function(fileData, config) {
            return axios.post('/imports', fileData, config);
        },
    };

    /**
     * 参照ログ関連API
     * @type {{getAll: (function(): *)}}
     */
    Vue.ELearning.Logs = {

        /**
         * 閲覧ログ機能のトップページのパスを返す
         */
        getTopPagePath: function() {
            if (store.state.userInfo.grant >= 3) {
                return "/admin/logs";
            }

            // 管理者以外の場合は自分の個別ユーザー画面にリンクする
            return "/admin/logs/users/" + store.state.userInfo.userId;
            // return "/admin/logs/work_procedures/" + this.$store.state.userInfo.userId;
        },

        /**
         * 現在のページが閲覧ログ機能のトップページかどうかを返す
         */
        isTopPage: function() {
            // console.log( "router.currentRoute :", router.currentRoute );

            return router.currentRoute.path == this.getTopPagePath();
        },

        /**
         * 共通初期化処理
         *
         * @todo Vue でやる
         */
        init: function() {
            // TAB-PANEL
            $(".tab-panel ul.tab li a").on('click', function() {
                $(this).addClass("selected").parent('li').siblings('li').children('a').removeClass('selected');
                $(this).closest('.tab-panel').children('.panel').children('.panel-content').hide();
                $($(this).attr("href")).fadeIn(500);
                return false;
            });

            /*
            // SHOW-MORE-TABLE
            $(".show-more-button a").on('click',function(){
                $(this).hide().closest('.show-more-table').find('table.partial-display').removeClass('partial-display');
                return false;
            });
            */
        },

        /**
         * API を呼び出してデータを取得する
         *
         * @param {Promise} promise Promise オブジェクト
         * @param {function} post_func 取得したデータを受け取る関数
         */
        getApiData(promise, post_func) {
            promise.then(
                response => {
                    if (response.status == 200) {
                        // API サーバー側でログアウトされている場合は、ログインページに遷移する
                        if (response.data.status_number == 402) {
                            router.push('/login');
                            return;
                        }

                        post_func(response.data.content);
                        // console.log( "data", response.data.content );
                    }
                }
            );
        },

        /**
         * API を呼び出して CSV ファイルをダウンロードする
         *
         * @param {Promise} Promise
         * @param {string} ファイル名
         */
        downloadCsv: function(promise, file_name) {
            promise.then(
                response => {
                    if (response.status != 200) {
                        return;
                    }

                    //console.log( response.data.content );

                    let blob = new Blob(['\ufeff' + response.data.content.payload], { type: 'text/csv' });

                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, file_name);
                        window.navigator.msSaveOrOpenBlob(blob, file_name);
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", file_name);
                        link.click();
                    }
                }
            );
        },

        getAuthorizedLogins: (period_begin, period_end) => {
            return axios.get('/logs/authorize', { params: { begin: period_begin, end: period_end } });
        },

        getAuthorizedUsers: (period_begin, period_end) => {
            return axios.get('/logs/authorize/user', { params: { begin: period_begin, end: period_end } });
        },

        getCompletedTasks: (period_begin, period_end) => {
            return axios.get('/logs/authorize/user', { params: { begin: period_begin, end: period_end } });
        },

        getTopAuthorizedDevices: (period_begin, period_end) => {
            return axios.get('/logs/top_authorized_devices', { params: { begin: period_begin, end: period_end } });
        },

        getTopWatchedUsers: (period_begin, period_end) => {
            return axios.get('/logs/top_watched_users', { params: { begin: period_begin, end: period_end } });
        },

        getTopWatchedUsersCsv: (period_begin, period_end) => {
            return axios.get('/logs/top_watched_users/csv', { params: { begin: period_begin, end: period_end } });
        },

        /** 閲覧数の多いタスクの一覧 */
        getTopViewedTasks: (params) => {
            return axios.get('/logs/top_viewed_tasks', { params: params });
        },
        getTopViewedTasksCsv: (params) => {
            return axios.get('/logs/top_viewed_tasks/csv', { params: params });
        },

        /** 閲覧したユーザー数の多いタスクの一覧 */
        getTopUsersViewedTasks: (params) => {
            return axios.get('/logs/top_users_viewed_tasks', { params: params });
        },
        getTopUsersViewedTasksCsv: (params) => {
            return axios.get('/logs/top_users_viewed_tasks/csv', { params: params });
        },

        getTopAuthorizedUsers: (period_begin, period_end) => {
            return axios.get('/logs/top_authorized_users', { params: { begin: period_begin, end: period_end } });
        },

        getTopAuthorizedUsersCsv: (period_begin, period_end) => {
            return axios.get('/logs/top_authorized_users/csv', { params: { begin: period_begin, end: period_end } });
        },

        getTopCompletedUsers: (period_begin, period_end) => {
            return axios.get('/logs/top_completed_users', { params: { begin: period_begin, end: period_end } });
        },

        getTopCompletedUsersCsv: (period_begin, period_end) => {
            return axios.get('/logs/top_completed_users/csv', { params: { begin: period_begin, end: period_end } });
        },

        /**
         * タスクを閲覧したことをログに記録する
         *
         * @param {Number} タスク Primary Key
         */
        logTaskView: (task_id) => {

            const data = {
                task_id: task_id
            };

            if (!Vue.Download.isOnline()) {
                return Vue.Download.storeLog("task_view", data);
            }

            return axios.post('/logs/task_view', data);
        },

        /**
         * 動画を再生したことをログに記録する
         *
         * @param {Number} 作業手順 Primary Key
         */
        logVideoPlay: (work_procedure_id) => {

            const data = {
                work_procedure_id: work_procedure_id
            };

            if (!Vue.Download.isOnline()) {
                return Vue.Download.storeLog("video_play", data);
            }

            return axios.post('/logs/video_play', data);
        },

        /**
         * タスクを完了したことをログに記録する
         *
         * @param {Number} タスク Primary Key
         */
        logTaskComplete: (task_id) => {

            const data = {
                task_id: task_id
            };

            if (!Vue.Download.isOnline()) {
                return Vue.Download.storeLog("task_complete", data);
            }

            return axios.post('/logs/task_complete', data);
        },

        /**
         * 作業項目を完了したことをログに記録する
         *
         * @param {Number} work_procedure_id 作業項目 ID
         * @param {String} work_procedure_name 作業項目名
         */
        logWorkProcedure: () => {

            var logs = [];
            store.state.logWorkProcedures.forEach(proc => {

                const type = (proc.status == 'completed') ? "work_procedure_complete" : "work_procedure_skip";

                const data = {
                    category_id: store.state.current_category_id,
                    category_name: store.state.currentCategoryName,
                    task_id: store.state.currentTask.id,
                    task_name: store.state.currentTaskName,
                    work_procedure_id: proc.id,
                    work_procedure_name: proc.name,
                };

                if (!Vue.Download.isOnline()) {
                    Vue.Download.storeLog(type, data);
                } else {

                    data.type = type;
                    logs.push(data);
                }
            });

            if (logs.length > 0) {
                axios.post('/logs/work_procedure', logs);
            }

            //ストアしたLogをクリアする
            store.commit("clearLogWorkProcedures");
        },

        /**
         * オフライン時にローカルに記録した全てのログをサーバーに送信する
         * 
         */
        logOffline: (logs) => {
            /*
            const logs = [
                { type: "user_authorize", create_datetime: "2000-01-01 00:00:00" },
                { type: "task_view", task_id: 1234, create_datetime: "1983-07-31 12:34:56" },
                { type: "video_play", work_procedure_id: 9999, create_datetime: "2019-08-10 12:34:56" },
                { type: "task_complete", task_id: 5555, create_datetime: "1999-01-13 12:34:56" },
                { type: "work_procedure_complete", create_datetime: "2002-02-02 02:02:02" },
                { type: "work_procedure_skip", create_datetime: "2003-03-03 03:03:03" },
            ];
            */

            return axios.post('/logs/offline', logs);
        },

        /** 全ユーザーのログイン回数を取得する */
        getUsersLoginCount: (params) => { return axios.get('/logs/authorize/total', { params: params }); },

        /** ユーザーのログイン回数を取得する */
        getUserLoginCount: (params) => { return axios.get('/logs/authorize/total/' + params.user_id, { params: params }); },

        /** 動画再生回数を取得する */
        getVideoPlayCount: (period_begin, period_end) => { return axios.get('/logs/video_play_count', { params: { begin: period_begin, end: period_end } }); },

        /// ユーザー個別

        /** 日別ログイン数の一覧を取得する */
        getDailyAuthorizedLogins: (params) => { return axios.get("/logs/authorize/" + params.user_id, { params: params }); },

        /** 日別動画再生数の一覧を取得する */
        getDailyVideoPlayCounts: (params) => { return axios.get("/logs/daily_video_play_counts/" + params.user_id, { params: params }); },

        /** 日別タスク完了数の一覧を取得する */
        getDailyCompletedTasks: (params) => { return axios.get("/logs/daily_completed_tasks/" + params.user_id, { params: params }); },

        /** 完了数の多い順にタスクの一覧を取得する */
        getTopCompletedTasks: (params) => { return axios.get("/logs/top_completed_tasks/" + params.user_id, { params: params }); },
        getTopCompletedTasksCsv: (params) => { return axios.get("/logs/top_completed_tasks/" + params.user_id + "/csv", { params: params }); },

        /** 動画再生数の多い順に作業手順の一覧を取得する */
        getTopPlayedWorkProcedures: (params) => { return axios.get("/logs/top_played_work_procedures", { params: params }); },
        getTopPlayedWorkProceduresCsv: (params) => { return axios.get("/logs/top_played_work_procedures/csv", { params: params }); },

        /** スキップされた回数の多い順に作業手順の一覧を取得する */
        getTopSkippedWorkProcedures: (params) => { return axios.get("/logs/top_skipped_work_procedures", { params: params }); },
        getTopSkippedWorkProceduresCsv: (params) => { return axios.get("/logs/top_skipped_work_procedures/csv", { params: params }); },

        /// タスク更新履歴

        /** タスクの更新履歴の一覧を取得する */
        getTaskUpdates: (params) => { return axios.get("/logs/task_updates", { params: params }); },
        getTaskUpdatesCsv: (params) => { return axios.get("/logs/task_updates/csv", { params: params }); },

        /// 作業履歴

        /** 作業履歴の一覧を取得する */
        getWorkProcedureCompletedLogs: (params) => { return axios.get("/logs/work_procedure_completed_logs", { params: params }); },
        getWorkProcedureCompletedLogsCsv: (params) => { return axios.get("/logs/work_procedure_completed_logs/csv", { params: params }); },
    };

    /**
     * アサイン関連API
     *
     */
    Vue.ELearning.Ping = {

        ping: function() {
            return axios.get('/ping');
        },
    };

    /**
     * ユーティリティ
     * @type {{generateUuid: (function(): string)}}
     */
    Vue.ELearning.Util = {
        /**
         * ランダムUUIDを生成する
         * @returns {string}
         */
        generateUuid: function() {
            // https://github.com/GoogleChrome/chrome-platform-analytics/blob/master/src/internal/identifier.js
            // const FORMAT: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
            //console.log("generateUUID");
            let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('');
            for (let i = 0, len = chars.length; i < len; i++) {
                switch (chars[i]) {
                    case 'x':
                        chars[i] = Math.floor(Math.random() * 16).toString(16);
                        break;
                    case 'y':
                        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(
                            16,
                        );
                        break;
                }
            }
            return chars.join('');
        },

        /**
         * 処理中オーバーレイを表示
         * @param store
         */
        showOverlay(store) {
            if (store) {
                // Vue管理外Bodyのスタイル変更
                jQuery('body').css({ 'overflow-y': 'hidden' });
                jQuery('html').css({ 'overflow-y': 'hidden' });

                store.commit('overlay', true);
            }
        },

        /**
         * 処理中オーバーレイを非表示
         * @param store
         */
        hideOverlay(store) {
            if (store) {
                // Vue管理外Bodyのスタイル変更
                jQuery('body').css({ 'overflow-y': 'auto' });
                jQuery('html').css({ 'overflow-y': 'auto' });

                store.commit('overlay', false);
            }
        },

        bodyScrollOff() {
            jQuery('body').css({ 'overflow-y': 'hidden' });
            jQuery('html').css({ 'overflow-y': 'hidden' });
            //console.log("scroll off");
        },
        bodyScrollOn() {
            jQuery('body').css({ 'overflow-y': 'auto' });
            jQuery('html').css({ 'overflow-y': 'auto' });
            //console.log("scroll on");
        },

        /**
         * 引数に指定されたオブジェクトをディープコピーし返却する
         * @param obj
         * @returns {any}
         */
        cloneObject(obj) {
            var json = JSON.stringify(obj);

            return JSON.parse(json);
        },

        /**
         * ファイル名が動画のファイルかどうかを返す
         * 
         * @param {string}} file_name ファイル名
         * @return {boolean} ファイル名が動画のファイルかどうかを返す
         */
        isVideoFileName(file_name) {
            return file_name.toLowerCase().endsWith('.mp4');
        },

        /**
         * ファイル名が画像のファイルかどうかを返す
         * 
         * @param {string}} file_name ファイル名
         * @return {boolean} ファイル名が動画のファイルかどうかを返す
         */
        isImageFileName(file_name) {
            file_name = file_name.toLowerCase();

            return file_name.endsWith('.jpg') ||
                file_name.endsWith('.jpeg') ||
                file_name.endsWith('.png') ||
                file_name.endsWith('.svg') ||
                file_name.endsWith('.gif');
        },

        /**
         * ファイル名が PDF のファイルかどうかを返す
         * 
         * @param {string}} file_name ファイル名
         * @return {boolean} ファイル名が動画のファイルかどうかを返す
         */
        isPdfFileName(file_name) {
            return file_name.toLowerCase().endsWith('.pdf');
        },

        /**
         * ファイル名から MIME タイプを返す
         * 
         * とりあえず、Blob に MIME タイプを設定しないと PDF が正しく表示できないため PDF のみ対応している
         * @todo PDF 以外も正しく MIME タイプを返す
         * 
         * @param {string} file_name ファイル名
         * @return {string} MIME タイプ
         */
        getMimeTypeByFileName(file_name) {
            if (this.isVideoFileName(file_name)) {
                return ""; // 特に問題が起きないため、 ""
            }
            if (this.isImageFileName(file_name)) {
                return ""; // 特に問題が起きないため、 ""
            }
            if (this.isPdfFileName(file_name)) {
                return "application/pdf";
            }

            return "";
        }
    };
};

export default {
    ELearningPlugin,
};