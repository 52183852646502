var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "a-task each-task column is-one-third videoBlock_item",
      on: {
        click: function($event) {
          $event.preventDefault()
          $event.stopPropagation()
          return _vm.gotoChecklist.apply(null, arguments)
        }
      }
    },
    [
      _c("div", { staticClass: "each-task-inner" }, [
        _c(
          "div",
          { staticClass: "task-images" },
          [
            _vm.useVideoThumbnail
              ? _c("video-thumbnail", {
                  attrs: {
                    "is-new": _vm.computedIsNew,
                    "is-update": _vm.computedIsUpdate,
                    path: _vm.thumbnailPath
                  }
                })
              : _vm.useImageThumbnail
              ? _c("image-thumbnail", {
                  attrs: {
                    "is-new": _vm.computedIsNew,
                    "is-update": _vm.computedIsUpdate,
                    path: _vm.thumbnailPath
                  }
                })
              : _vm.usePdfThumbnail
              ? _c("pdf-thumbnail", {
                  attrs: {
                    "is-new": _vm.computedIsNew,
                    "is-update": _vm.computedIsUpdate,
                    path: _vm.thumbnailPath,
                    "pdf-name": _vm.task.task_name
                  }
                })
              : _vm.task.task_size > 0 &&
                _vm.task.thumbnail_url.video_path != undefined
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "jumbotron" }, [
                      _c("div", { staticClass: "status-box" }, [
                        _vm.computedIsNew
                          ? _c("div", { staticClass: "status-new" }, [
                              _vm._v("NEW")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.computedIsUpdate
                          ? _c("div", { staticClass: "status" }, [
                              _vm._v("UPDATE")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("サムネイルを表示できません")])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.task.task_size == 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "jumbotron" }, [
                      _c("div", { staticClass: "status-box" }, [
                        _vm.computedIsNew
                          ? _c("div", { staticClass: "status-new" }, [
                              _vm._v("NEW")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.computedIsUpdate
                          ? _c("div", { staticClass: "status" }, [
                              _vm._v("UPDATE")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("h3", [_vm._v("作業手順が登録されておりません")])
                    ])
                  ])
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "task-info" }, [
          _vm.isTaskStored
            ? _c("div", { staticClass: "task-badge" }, [
                _vm._v("ダウンロード済")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "task-title videoBlock_text",
              class: { "icon-download": _vm.isTaskStored && _vm.isSmallView }
            },
            [_vm._v(_vm._s(_vm.task.task_name))]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "task-table-height",
              class: { "sp-hidden": _vm.isSmallView }
            },
            [
              _c("table", { staticClass: "basic task-table" }, [
                _c("tr", [
                  _c("td", { staticClass: "dates" }, [
                    _vm._m(0),
                    _vm._v(" " + _vm._s(_vm.task.create_datetime_for_show)),
                    _c("br"),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(
                      " " +
                        _vm._s(_vm.task.update_datetime_for_show) +
                        "\n                    "
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "dates" }, [
                    _vm._m(2),
                    _vm._v(" " + _vm._s(_vm.task.creator)),
                    _c("br"),
                    _vm._v(" "),
                    _vm._m(3),
                    _vm._v(
                      " " + _vm._s(_vm.task.updator) + "\n                    "
                    )
                  ])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "task-text",
              class: { "sp-hidden": _vm.isSmallView }
            },
            [_vm._v(_vm._s(_vm.task.task_text))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              {
                staticClass: "column p-relative has-tags",
                class: { "sp-hidden": _vm.isSmallView }
              },
              [
                _vm._l(_vm.task.tags, function(tag, index) {
                  return [
                    _c("span", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowTag(index),
                          expression: "isShowTag(index)"
                        }
                      ],
                      key: tag.tag_id,
                      staticClass: "button basic is-tags",
                      domProps: { textContent: _vm._s(tag.tag_name) }
                    })
                  ]
                }),
                _vm._v(" "),
                _vm.task.tags.length > 5 && !_vm.tagOpen
                  ? _c("img", {
                      staticClass: "tag-open",
                      attrs: {
                        src:
                          _vm.$store.state.documentRoot +
                          "/images/ico-plus2.png",
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          _vm.tagOpen = !_vm.tagOpen
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.task.tags.length > 0 && _vm.tagOpen
                  ? _c("img", {
                      staticClass: "tag-close",
                      attrs: {
                        src:
                          _vm.$store.state.documentRoot +
                          "/images/ico-minus.png",
                        alt: ""
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          _vm.tagOpen = !_vm.tagOpen
                        }
                      }
                    })
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "column is-one-third" }, [
              _vm.task.task_size > 0
                ? _c(
                    "div",
                    { staticClass: "col-md-3" },
                    [
                      _c("circle-graph-component", {
                        attrs: { value: _vm.progress, size: _vm.progressSize }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("circle-graph-component", {
                        attrs: { value: -1, size: _vm.progressSize }
                      })
                    ],
                    1
                  )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.task.task_size > 0 && _vm.progress > 0
        ? _c(
            "div",
            { staticClass: "sp-progress pc-hidden" },
            [
              _c("circle-graph-component", {
                attrs: { value: _vm.progress, size: "small" }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "green" }, [
      _c("i", { staticClass: "far fa-edit" }),
      _vm._v("作成日")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "green" }, [
      _c("i", { staticClass: "far fa-clock" }),
      _vm._v("更新日")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "green" }, [
      _c("i", { staticClass: "fas fa-user" }),
      _vm._v("作成者")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "green" }, [
      _c("i", { staticClass: "fas fa-user" }),
      _vm._v("更新者")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }