<template>
    <common-layout-component2
        show-back="true"
        show-admin="true"
        v-on:on-nav-back="back"
    >
        <template v-slot:nav-message>
            <p>グループ登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">グループ編集</h2>
                    <div class="column tr">
                        <button class="button basic is-gray" @click="importGrp">
                            インポート&nbsp;<img
                                src="/images/ico-import.png"
                                alt=""
                            />
                        </button>
                        <input id="input-file-csv" @change="selectedUploadCsv" type="file" name="file" class="d-none" />
                        <button class="button basic is-gray" @click="exportGrp">
                            エクスポート&nbsp;<img
                                src="/images/ico-export.png"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
                <!--　サブタイトル部 -->
                <div id="subtitle-area">グループ一覧</div>
                <!--表組み -->
                <div id="table-area" class="columns">
                    <div class="column">
                        <button
                            class="button is-login"
                            @click="gotoEdit('new')"
                        >
                            新規登録&nbsp;<img
                                src="/images/ico-plus.png"
                                alt=""
                            />
                        </button>
                        <button
                            v-bind:disabled="editButtonEnabled"
                            class="button is-login"
                            @click="gotoEdit('edit')"
                        >
                            編集&nbsp;<img
                                src="/images/ico-pen.png"
                                alt=""
                            />
                        </button>
                        <button
                            v-bind:disabled="deleteButtonEnabled"
                            class="button basic is-red"
                            @click="showModalDelete = true"
                        >
                            削除&nbsp;<img
                                src="/images/ico-trash.png"
                                alt=""
                            />
                        </button>
                    </div>
                    <div class="column column-search">
                        <!--<p class="input-search tr"><input type="text" placeholder="検索"><img src="/images/ico-search.png" alt=""></p>-->
                        <p class="input-search tr">
                            <input
                                type="text"
                                placeholder="検索"
                                v-model="keyword"
                                @change="onSearch($event.target.value)"
                                style="padding-right:2.2em;"
                            /><img
                                src="/images/ico-search.png"
                                alt=""
                            />
                        </p>
                        <button
                            class="btn btn-clear btn-round"
                            @click="clearConditions"
                            :disabled="!keyword.length"
                        >
                            クリア
                        </button>
                    </div>
                </div>
                <!-- -->
                <div class="table-wrapper">
                    <table class="basic scrollTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th
                                    @click="onSort('group_name')"
                                    style="cursor:pointer;"
                                >
                                    グループ名&nbsp;<arrow-up-down-alt
                                        v-if="axis == 'group_name'"
                                        v-bind:order="order"
                                    ></arrow-up-down-alt
                                    ><!--<img src="/images/ico-down.png" alt="">-->
                                </th>
                                <th>グループ説明文</th>
                                <th>
                                    閲覧可能なタスク<!--&nbsp;<img src="/images/ico-down.png" alt="">-->
                                </th>
                            </tr>
                        </thead>

                        <tr v-show="groups.length == 0">
                            <td colspan="4">グループが存在しません。</td>
                        </tr>

                        <template v-for="group in groups">
                            <tr :key="group.groupId">
                                <td class="check">
                                    <label>
                                        <!--<input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                                        <span class="checkbox01-parts"></span>-->
                                        <base-check-component
                                            v-on:check-state-change="
                                                onCheckStateChange(group)
                                            "
                                            v-bind:value="group.selected"
                                        ></base-check-component>
                                    </label>
                                </td>
                                <td class="group-name">
                                    {{ group.groupName }}
                                </td>
                                <td class="group-body">
                                    {{ group.groupText }}
                                </td>
                                <td class="task">
                                    <button
                                        class="button basic is-gray2 rs"
                                        @click="onOpenGroupByTask(group)"
                                    >
                                        タスクリスト<img
                                            class=""
                                            src="/images/ico-add-gray.png"
                                            alt=""
                                        />
                                    </button>
                                </td>
                            </tr>
                        </template>

                        <!--<tr>
                            <td class="check">
                                <label>
                                    <input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                                    <span class="checkbox01-parts"></span>
                                </label>
                            </td>
                            <td class="group-name">グループ名が入ります</td>
                            <td class="group-body">グループ説明文が入ります。グループ説明文が入ります。グルー…</td>
                            <td class="task"><a class="button basic is-gray2 rs" href="#modal2">タスクリスト <img class="" src="/images/ico-add-gray.png" alt=""></a>
                            </td>
                        </tr>-->
                    </table>
                </div>

                <!-- モーダルコンポーネント -->
                <modal-component
                    v-if="showModalDelete"
                    v-bind:is-delete="true"
                    is-quest="true"
                    width="600"
                    @modalYes="execDelete"
                    @modalNo="showModalDelete = false"
                >
                    <h4 slot="header"><!--グループ削除の確認--></h4>
                    <h3 slot="body" style="text-align:center;">
                        <!--<h1 clss="tc"><img src="/images/ico-q.png" alt=""></h1>-->
                        <!--<p>選択されたグループを削除しますか？</p>-->
                        <p class="tc pb2em" style="margin-top:1em;">
                            <strong>選択されたグループを削除しますか？</strong>
                        </p>
                    </h3>
                </modal-component>

                <modal-component
                    v-if="isOpenGroupByTask"
                    v-on:modalYes="isOpenGroupByTask = !isOpenGroupByTask"
                    v-on:modalNo="isOpenGroupByTask = !isOpenGroupByTask"
                    is-quest="true"
                    width="1200"
                >
                    <div slot="header">
                        <h3>閲覧可能なタスク</h3>
                    </div>
                    <div slot="body">
                        <!--<task-selection-component2 v-bind:current-group-tasks="currentGroupTasks" v-bind:is-selection-mode="false"></task-selection-component2>-->

                        <multi-selection-component
                            v-bind:current-parents="currentGroupTasks"
                            parent-item-name="categoryName"
                            child-item-name="taskName"
                            child-column-name="tasks"
                            v-bind:is-selection-mode="false"
                        ></multi-selection-component>
                    </div>
                    <div slot="footer">
                        <div class="row">
                            <div class="col-md-12" style="text-align:center;">
                                <button
                                    class="button basic is-login"
                                    @click="isOpenGroupByTask = false"
                                >
                                    閉じる
                                </button>
                            </div>
                        </div>
                    </div>
                </modal-component>
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
import logic from './js/GroupList.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import SearchInputComponent from '../../commons/SearchInputComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

import PlusMinus from '../../commons/PlusMinus.vue';

import TaskSelectionComponent from '../../commons/TaskSelectionComponent.vue';
import TaskSelectionComponent2 from './components/TaskSelectionComponent2.vue';
import MultiSelectionComponent from './components/MultiSelectionComponent.vue';

logic.components = {
    CommonLayoutComponent2,
    BaseCheckComponent,
    SearchInputComponent,
    ModalComponent,
    PlusMinus,
    TaskSelectionComponent,
    TaskSelectionComponent2,
    MultiSelectionComponent,
};
export default logic;
</script>
