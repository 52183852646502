<template>
  <div>
    <transition name="modal" @afterLeave="onPasswordLeave">
      <modal-component
        v-if="showModalChangePassword"
        :is-save="!forceChange"
        :is-change="forceChange"
        width="600"
        @modalYes="doChangePassword"
        @modalNo="cancelChangePassword"
      >
        <div slot="body" class="password">
          <h3 v-if="forceChange"><strong>パスワードを変更してください。</strong></h3>
          <div class="field columns">
            <label
              class="label column"
              v-bind:class="{error:hasErrPassword(['old_password','auth'])}"
            >旧パスワード</label>
            <div class="control column">
              <input
                class="input"
                v-bind:class="{'error-ctrl':hasErrPassword(['old_password','auth'])}"
                type="password"
                v-model="currentPassword"
                autocomplete="off"
              />
              <p class="error">{{ errPasswordMessage('old_password') }}</p>
              <p class="error">{{ errPasswordMessage('auth') }}</p>
            </div>
          </div>
          <div class="field columns">
            <label
              class="label column"
              v-bind:class="{error:hasErrPassword(['new_password'])}"
            >新パスワード</label>
            <div class="control column">
              <input
                class="input"
                v-bind:class="{'error-ctrl':hasErrPassword(['new_password'])}"
                type="password"
                v-model="newPassword"
                autocomplete="off"
              />
              <p class="error">{{ errPasswordMessage('new_password') }}</p>
            </div>
          </div>
          <div class="field columns">
            <label class="label column" autocomplete="off">
              新パスワード
              <span>(確認)</span>
            </label>
            <div class="control column">
              <input class="input" type="password" v-model="confirmPassword" />
            </div>
          </div>
        </div>
      </modal-component>
    </transition>

    <transition name="modal" @afterLeave="onSavedLeave">
      <modal-component
        v-if="showModalChangeComplete"
        width="600"
        @modalOk="showModalChangeComplete=false"
      >
        <h3 slot="body" style="text-align: center;">
          <p class="tc pb2em" style="margin-top:0.5em;">
            <strong>パスワードを変更しました</strong>
          </p>
        </h3>
      </modal-component>
    </transition>
  </div>
</template>

<script>
import ModalComponent from "./ModalComponent";

export default {
  components: {
    ModalComponent
  },
  props: {
    forceChange:Boolean
  },
  data: function() {
    return {
      showModalChangePassword: true,
      showModalChangeComplete: false,
      cancelled: false,

      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errChangePassword: {}
    };
  },

  methods: {
    hasErrPassword(types) {
      var hasError = false;
      types.forEach(type => {
        if (this.errChangePassword[type]) {
          hasError = true;
        }
      });
      return hasError;
    },

    errPasswordMessage(type) {
      if (this.errChangePassword[type]) {
        return this.errChangePassword[type].join(" ");
      }
      return "";
    },

    doChangePassword: function() {
      Vue.ELearning.Util.showOverlay(this.$store);

      Vue.ELearning.UserInfo.current()

        .then(response => {
          if (response.data.status_number == 200) {
            var user = response.data.content;
            return Vue.ELearning.UserInfo.updatePassword(
              user,
              this.currentPassword,
              this.newPassword,
              this.confirmPassword
            );
          } else if (response.data.status_number == 401) {
            //ログインタイムアウト
            this.$router.push({ path: "/login" });
            throw new Error("タイムアウトしました");
          } else {
            //不明なエラー
            throw new Error("不明なエラーが発生しました");
          }
        })
        .then(response => {
          if (response && response.data.status_number == 200) {
            console.log(response);

            const content = response.data.content;

            if (content.result == "NG") {
              this.errChangePassword = content.errors;
            } else {
              //OK
              this.errChangePassword = {};

              this.showModalChangePassword = false;
              this.showModalChangeComplete = true;
            }
          } else {
            //エラー
            throw new Error("不明なエラーが発生しました");
          }

          Vue.ELearning.Util.hideOverlay(this.$store);
        })
        .catch(error => {
          //エラー
          console.error(error);
          this.$dialog.message(error.message);
          Vue.ELearning.Util.hideOverlay(this.$store);
        });
    },

    cancelChangePassword: function() {

      this.currentPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;

      this.cancelled = true;
      this.showModalChangePassword = false;
    },
    onPasswordLeave: function() {

      this.currentPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;

      if(this.cancelled){
        this.$emit("cancel");
      }
    },    
    onSavedLeave: function() {

      this.currentPassword = null;
      this.newPassword = null;
      this.confirmPassword = null;

      //userInfoを更新
      this.$store.commit("updateUserInfo", {
           'initialPassword': 0,
       });

      this.$emit("saved");
    }
  }
};
</script>

<style scoped lang="scss">
.password {
  padding: 0 24px;
  h3{
      text-align: center;
      margin-bottom: 30px;
  }

  .columns{
    margin: 0 0 15px 0;

    &:last-child{
      margin: 0;
    }
  }

  label {
    margin-top: 10px;
    flex-basis: 30%;
    flex-grow: 0;
    font-weight: normal;
    text-align: left;

    span {
      font-size: 0.8em;
    }
  }

  .column{
    padding: 0;
  }
  .error {
    margin-top: 5px;
    color: rgba(255, 0, 0, 0.6) !important;
  }
  .error-ctrl {
    background-color: rgba(255, 0, 0, 0.16) !important;
  }
}
@media screen and (max-width: 767px) {
  .password {
    padding: 0 0 24px 0;
    h3{
      font-size: 1.5rem;
    }
    label{
      font-size: 1.2rem;
    }
    input{
      height: 35px;
    }
    .error {
      padding-bottom: 0;
  }

  }
}
</style>
