import { render, staticRenderFns } from "./NotificationComponent.vue?vue&type=template&id=07d81990&scoped=true&"
import script from "./NotificationComponent.vue?vue&type=script&lang=js&"
export * from "./NotificationComponent.vue?vue&type=script&lang=js&"
import style0 from "./NotificationComponent.vue?vue&type=style&index=0&id=07d81990&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07d81990",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m1292/Documents/develop/laravel/misoshiru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('07d81990')) {
      api.createRecord('07d81990', component.options)
    } else {
      api.reload('07d81990', component.options)
    }
    module.hot.accept("./NotificationComponent.vue?vue&type=template&id=07d81990&scoped=true&", function () {
      api.rerender('07d81990', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/commons/NotificationComponent.vue"
export default component.exports