<template>
    <common-layout-component2
        show-back="false"
        show-admin="true"
    >

        <template v-slot:nav-message>
            <p>設定/登録</p>
        </template>
        <template v-slot:body>
            <div class="container">
                <div class="columns is-multiline cat-wrapper">
                    <menu-item
                        :allow-grant="3"
                        icon-file-name="ico-usr.png"
                        link-path="/admin/users"
                        link-text="ユーザー登録/編集"
                    />
                    <menu-item
                        :allow-grant="3"
                        icon-file-name="ico-group.png"
                        link-path="/admin/groups"
                        link-text="グループ登録/編集"
                    />
                    <menu-item
                        :allow-grant="3"
                        icon-file-name="ico-cat.png"
                        link-path="/admin/categories"
                        link-text="カテゴリ登録/編集"
                    />
                    <menu-item
                        :allow-grant="2"
                        icon-file-name="ico-task.png"
                        link-path="/admin/tasks"
                        link-text="タスク登録/編集"
                    />
                    <menu-item
                        :allow-grant="2"
                        icon-file-name="ico-tag.png"
                        link-path="/admin/tags"
                        link-text="タグ登録/編集"
                    />
                    <!-- <menu-item
                        :allow-grant="1"
                        icon-file-name="ico-log.png"
                        :link-path="log_link_path"
                        link-text="閲覧ログ確認"
                    /> -->
                     <!-- <menu-item
                        :allow-grant="2"
                        icon-file-name="ico-publish.png"
                        link-path="/admin/publishes"
                        link-text="書き出し"
                    /> -->
               </div>
            </div>
        </template>
    </common-layout-component2>
</template>

<script>
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import MenuItem from './components/MenuItem.vue';

export default {
    components: {
        CommonLayoutComponent2,
        MenuItem,
    },

    computed: {
        /**
         * 閲覧ログのリンク先パス
         */
        log_link_path: function ()
        {
            // 管理者の場合はダッシュボードにリンクする
            if ( this.$store.state.userInfo.grant >= 3 )
            {
                return "/admin/logs";
            }

            // 管理者以外の場合は自分の個別ユーザー画面にリンクする
            return "/admin/logs/users/" + this.$store.state.userInfo.userId;
        }
    }
};
</script>
