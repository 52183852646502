var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": false,
      title: "ログイン",
      "main-class": "login_page"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "bg_yellow" }, [
              _c("div", { staticClass: "container pt50" }, [
                _c("div", { staticClass: "caption" }, [
                  _c("h1", [_vm._v("ログイン")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("このサービスをご利用になるには"),
                    _c("br"),
                    _vm._v(" ログインしてください。\n                    ")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "box bg_white" }, [
                  _c("div", { staticClass: "item" }, [
                    _c("p", [_vm._v("メールアドレス")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      attrs: { type: "text", name: "email", placeholder: "" },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("p", [_vm._v("パスワード")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      attrs: {
                        type: "password",
                        name: "password",
                        placeholder: ""
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "item" }, [
                    _c("input", {
                      attrs: {
                        id: "remember",
                        type: "checkbox",
                        name: "remember"
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "remember" } }, [
                      _vm._v("ログイン状態を保存する")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "mt20 button md",
                      on: { click: _vm.doLogin }
                    },
                    [_vm._v("ログイン")]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "other_buttons" }, [
                  _c("p", { staticClass: "icon" }, [
                    _c("a", { attrs: { href: _vm.$libs.url("") } }, [
                      _vm._v("パスワードをお忘れですか？"),
                      _c("i", { staticClass: "fas fa-angle-right" })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "inner" }, [
                    _c(
                      "button",
                      {
                        staticClass: "bg_accent button md",
                        on: {
                          click: function($event) {
                            return _vm.$libs.pushUrl("register")
                          }
                        }
                      },
                      [_vm._v("無料新規登録はこちら")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "icon",
                        attrs: { href: _vm.$libs.url("") }
                      },
                      [
                        _vm._v("みそしる教室 トップへ"),
                        _c("i", { staticClass: "fas fa-angle-right" })
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.showModal
              ? _c(
                  "modal-component",
                  {
                    attrs: { "is-quest": "false" },
                    on: {
                      modalOk: function($event) {
                        _vm.showModal = false
                      }
                    }
                  },
                  [
                    _c("h4", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v("パスワードの確認")
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { slot: "body" },
                        slot: "body"
                      },
                      [_vm._v("管理者にお問い合わせください。")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showResendVerificationModal
              ? _c(
                  "modal-component",
                  {
                    attrs: { "is-quest": "false" },
                    on: {
                      modalOk: function($event) {
                        _vm.showResendVerificationModal = false
                      }
                    }
                  },
                  [
                    _c("h4", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v("確認コードの再送信")
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { slot: "body" },
                        slot: "body"
                      },
                      [_vm._v("確認コードをメールアドレスに送信しました")]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showSendVerificationErrorModal
              ? _c(
                  "modal-component",
                  {
                    attrs: { "is-quest": "false" },
                    on: {
                      modalOk: function($event) {
                        _vm.showSendVerificationErrorModal = false
                      }
                    }
                  },
                  [
                    _c("h4", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v("エラー")
                    ]),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { slot: "body" },
                        slot: "body"
                      },
                      [_vm._v("しばらく経ってから再度お試しください")]
                    )
                  ]
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }