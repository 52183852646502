var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flow",
      class: {
        "bg-green": _vm.item.selected
      }
    },
    [
      _c("label", { staticClass: "procedure-check" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.item.selected,
              expression: "item.selected"
            }
          ],
          staticClass: "checkbox01-input",
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.item.selected)
              ? _vm._i(_vm.item.selected, null) > -1
              : _vm.item.selected
          },
          on: {
            change: [
              function($event) {
                var $$a = _vm.item.selected,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(_vm.item, "selected", $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.item,
                        "selected",
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.item, "selected", $$c)
                }
              },
              function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.change.apply(null, arguments)
              }
            ]
          }
        }),
        _vm._v(" "),
        _c("span", {
          staticClass: "checkbox01-parts",
          domProps: { textContent: _vm._s(_vm.name) }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }