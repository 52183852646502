<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" :require-user-info="true" title="マイページ" main-class="my_page">
        <template v-slot:body>
            <section class="user_header bg_yellow">
                <div class="container pt30 pb30">
                    <div class="icon">
                        <p :style="iconImage"></p>
                    </div>
                    <div class="name">
                        <h1>{{ displayName }}</h1>
                    </div>
                </div>
            </section>
            <section class="bg_yellow">
                <div class="list_box">
                    <ul>
                        <li>
                            <a :href="$libs.url(['mypage','edit'])">
                                個人情報設定<i class="fas fa-angle-right"></i>
                            </a>
                        </li>
                        <li v-if="$store.state.userInfo.grant == 1">
                            <a :href="$libs.url(['mypage','purchased-history'])">
                                購入履歴<i class="fas fa-angle-right"></i>
                            </a>
                        </li>
                        <li v-if="$store.state.userInfo.grant == 2">
                            <a :href="$libs.url(['mypage','sales'])">
                                支払い管理<i class="fas fa-angle-right"></i>
                            </a>
                        </li>
                        <li v-if="$store.state.userInfo.grant == 2">
                            <a :href="$libs.url(['mypage','my-students'])">
                                生徒<i class="fas fa-angle-right"></i>
                            </a>
                        </li>
                        <!--
                        <li>
                        <a :href="$libs.url(['mypage','edit'])">
                            <a>お問い合わせ<i class="fas fa-angle-right"></i></a>
                        </a>
                        </li>
                        -->
                        <li><a href="mailto:contact@misoshiru.net?subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&amp;body=%E3%81%8A%E5%90%8D%E5%89%8D%3A%0D%0A%0D%0A%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%3A%0D%0A%0D%0A%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%3A%0D%0A">お問い合わせ<i class="fas fa-angle-right"></i></a></li>

                        <li>
                            <a :href="$libs.url(['guide'])">ご利用ガイド<i class="fas fa-angle-right"></i></a>
                        </li>
                        <li>
                            <a :href="$libs.url(['faq'])">よくある質問<i class="fas fa-angle-right"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="list_box">
                    <ul>
                        <li>
                            <a href="/terms">利用規約<i class="fas fa-angle-right"></i></a>
                        </li>
                        <li>
                            <a href="/privacy">プライバシーポリシー<i class="fas fa-angle-right"></i></a>
                        </li>
                        <li>
                            <a href="/company">運営会社<i class="fas fa-angle-right"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="list_box">
                    <ul>
                        <li><a @click.stop.prevent="showModalLogout = true">ログアウト<i class="fas fa-angle-right"></i></a></li>
                    </ul>
                </div>
                <div class="container pt20">

                </div>
            </section>

            <modal-component v-if="showModalLogout" title="ログアウトしますか？" :view-selectable="true" @yes="logout" @no="showModalLogout = false"/>
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../miso/commons/ModalComponent.vue';

export default {
    components: {
    CommonLayoutComponent,
    ModalComponent
    },
  data: function () {
    return {
        userIcon:null,
      displayName: "",
      showModalLogout: false,
    };
  },
  created: function () {},

  mounted: function () {
      this.refleshData();
  },
  computed: {
      iconImage: function() {
          if(this.userIcon){
              return 'background: url(' + this.userIcon + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      }
  },
  methods: {
    refleshData: function() {

            // ユーザー情報取得
            var handler = Vue.ELearning.UserInfo.current();

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    var user = response.data.content;

                    this.userIcon = user["image"];
                    this.displayName = user["display_name"];

                } else {
                    throw new Error("ユーザー情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },

    logout: function(){
        Vue.ELearning.Authenticatior.clear();

        // ログアウト処理
        this.$store.commit("logout", {});
        this.$libs.pushUrl("login")
    },
    back: function() {
        this.$libs.pushUrl("")
    },
  },
}

</script>
<style lang="scss" scoped>
</style>
