var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.currentGroupTasks, function(categoryTask) {
        return [
          _c(
            "div",
            {
              staticClass: "row category",
              staticStyle: {
                border: "1px solid #d3d3d3",
                "background-color": "#EEEEEE"
              },
              style: { color: _vm.categoryColor(categoryTask) }
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-md-8",
                  staticStyle: { "line-height": "1.5em" }
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "1.5em",
                        "padding-top": "0.5em",
                        "vertical-align": "middle"
                      }
                    },
                    [_vm._v(_vm._s(categoryTask.categoryName))]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "col-md-4",
                  staticStyle: { "text-align": "right" }
                },
                [
                  _c("plus-minus", {
                    staticStyle: { "font-size": "1.5em", cursor: "pointer" },
                    attrs: { indicator: _vm.groupTaskIndicator(categoryTask) },
                    on: {
                      "indicator-click": function($event) {
                        categoryTask.isOpen = !categoryTask.isOpen
                      }
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          categoryTask.isOpen
            ? _c(
                "div",
                {
                  staticClass: "row",
                  staticStyle: { "border-collapse": "collapse" }
                },
                [
                  _vm._l(categoryTask.tasks, function(task, idx) {
                    return [
                      _c(
                        "div",
                        {
                          key: categoryTask.categoryId + "-" + task.taskId,
                          staticClass: "col-md-6",
                          class: {
                            "bottom-task-left": _vm.isBottomTaskLeft(
                              categoryTask.tasks,
                              idx
                            ),
                            "bottom-task-right": _vm.isBottomTaskRight(
                              categoryTask.tasks,
                              idx
                            )
                          },
                          staticStyle: {
                            "line-height": "2.5em",
                            border: "1px solid #d3d3d3",
                            "border-top": "0"
                          },
                          style: {
                            "border-right": _vm.categoryTaskBorderRight(
                              idx,
                              categoryTask.tasks.length
                            ),
                            color: _vm.taskColor(task),
                            "font-weight": _vm.taskWeight
                          }
                        },
                        [
                          _vm.isSelectionMode
                            ? _c("base-check-component", {
                                attrs: { value: task.selected },
                                on: {
                                  "check-state-change": function($event) {
                                    return _vm.onCheckStateChange(task)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSelectionMode
                            ? _c("span", [_vm._v("  ")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSelectionMode
                            ? _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onCheckStateChange(task)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(task.taskName))]
                              )
                            : _c("span", [_vm._v(_vm._s(task.taskName))])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      categoryTask.tasks.length - 1 == idx &&
                      categoryTask.tasks.length % 2 == 1
                        ? _c("div", {
                            staticClass: "col-md-6 bottom-task-right",
                            staticStyle: {
                              "line-height": "2.5em",
                              border: "1px solid #d3d3d3",
                              "border-top": "0"
                            }
                          })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "row",
            staticStyle: { "margin-bottom": "1em", height: "1px" }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }