//console.log("Initialize App");
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
//import router from './router';
import App from './components/App.vue';
import Cookies from 'js-cookie';

import util from './plugins/util';
import db_plugin from './plugins/db';
import download_plugin from './plugins/download';
import filters from './plugins/filters';
import dialog from './plugins/dialog';
import config from './plugins/config';
import libs from './plugins/libs';

import store from './store'

import Login from './components/miso/commons/Login.vue';
import Register from './components/miso/user/Register.vue';
import Mypage from './components/miso/user/Mypage.vue';
import Mypage_edit from './components/miso/user/MypageEdit.vue';
import Purchased_history from './components/miso/user/PurchasedHistory.vue';
import Sales from './components/miso/user/Sales.vue';
import Sales_history from './components/miso/user/SalesHistory.vue';
import My_students from './components/miso/user/MyStudents.vue';
import Search from './components/miso/user/Search.vue';
import Search_result from './components/miso/user/SearchResult.vue';
import Notice from './components/miso/user/Notice.vue';
import Todo from './components/miso/user/Todo.vue';
import Mylesson from './components/miso/user/MyLesson.vue';
import Lesson from './components/miso/user/Lesson.vue';
import Lesson_intro from './components/miso/user/LessonIntro.vue';
import Lesson_step from './components/miso/user/LessonStep.vue';
import Lesson_post_advice from './components/miso/user/LessonPostAdvice.vue';
import Lesson_edit from './components/miso/user/LessonEdit.vue';
import Lesson_purchased_users from './components/miso/user/LessonPurchasedUsers.vue';
import Step_edit from './components/miso/user/StepEdit.vue';
import User from './components/miso/user/User.vue';
import Top from './components/miso/commons/Top.vue';
import Guide from './components/miso/commons/Guide.vue';
import Faq from './components/miso/commons/Faq.vue';
import Terms from './components/miso/commons/Terms.vue';
import Privacy from './components/miso/commons/Privacy.vue';
import Notfound from './components/miso/commons/NotFound.vue';

require('./bootstrap');
window.Cookies = Cookies;

Vue.use(util.ELearningPlugin);
Vue.use(db_plugin);
Vue.use(download_plugin)
Vue.use(filters);
Vue.use(dialog);
Vue.use(config);
Vue.use(libs);

/**
 * ドキュメントルートの設定
 * @type {string}
 */
var docRoot = process.env.MIX_VUEROOTER_BASE;

axios.defaults.baseURL = docRoot + '/apis/';
axios.defaults.headers.common = {
    'X-CSRF-TOKEN': window.Laravel.csrfToken,
    'X-Requested-with': 'XMLHttpRequest',
};
axios.defaults.withCredentials = true;

// 2019-10-05 ando : サーバー上でログインしているかどうかで API を使えるかどうかを判断できるはずだが、
// 元の実装が、それとは別にトークン el-auth-token を使用していたので、そのまま生かす。が、サーバー上でちゃんとトークンをチェックしているか、確認が必要
// 2019-10-28 kato PWA作業スケジュール No.37 にて ↑
// 見当たらず、@package App\Http\Middleware class AddMetadata でチェック実装。
axios.interceptors.request.use(function(config) {
    console.log("config:", config)
    var isShowOverlay = true
    isShowOverlay = isShowOverlay && !config.url.endsWith("appInfo")
    isShowOverlay = isShowOverlay && !config.url.endsWith("ping")
    if (isShowOverlay) {
        store.commit('overlay', true);
    }

    var authToken = Cookies.get('el-auth-token');
    if (authToken) {
        config.headers['el-auth-token'] = authToken;
    } else {
        config.headers['el-auth-token'] = 'unauthorized';
    }

    return config;
});
axios.interceptors.response.use(function(response) {
    store.commit('overlay', false);

    if (response.data.status_number == 401) {
        console.log("response.data.status_number == 401");
        store.commit("logout", {});
        if (location.pathname != "/login") {
            Vue.$libs.pushUrl("login");
        }
    }

    return response
});
Vue.prototype.$http = axios;


// アイコンクラス
Vue.component('arrow-up', {
    template: `
        <i class="fas fa-arrow-up "></i>
    `,
});
Vue.component('arrow-down', {
    template: `
        <i class="fas fa-arrow-down "></i>
    `,
});

// アイコンクラス
Vue.component('arrow-up-alt', {
    template: `
        <i class="far fa-arrow-alt-circle-up arrow-alt"></i>
    `,
});
Vue.component('arrow-down-alt', {
    template: `
        <i class="far fa-arrow-alt-circle-down arrow-alt"></i>
    `,
});

// 共通コンポーネント
import upDown from './components/commons/ArrowUpDown.vue';
Vue.component('arrow-up-down', upDown);

import upDownAlt from './components/commons/ArrowAltUpDown.vue';
Vue.component('arrow-up-down-alt', upDownAlt);

import overlay from './components/miso/commons/OverlayComponent.vue';
Vue.component('overlay', overlay);

import globalControl from './components/commons/GlobalControl.vue';
Vue.component('global-control', globalControl);

window.Vue = Vue;

// 要素に v-focus を指定すると、その要素にフォーカスするようにする
Vue.directive('focus', {
    inserted: function(el) {
        el.focus();
    }
});

/*
 * メインコンポーネント
 */
/*
const app = new Vue({
    el: '#app',
    //router,
    components: { App },
    template: '<App />',
    util,
    store,
});
*/
const app_blade = new Vue({
    el: '#app',
    components: {
        Login,
        Register,
        Mypage,
        Mypage_edit,
        Purchased_history,
        Sales,
        Sales_history,
        My_students,
        Search,
        Search_result,
        Notice,
        Todo,
        Mylesson,
        Lesson,
        Lesson_intro,
        Lesson_step,
        Lesson_post_advice,
        Lesson_edit,
        Lesson_purchased_users,
        Step_edit,
        User,
        Top,
        Guide,
        Faq,
        Terms,
        Privacy,
        Notfound,
    },
    util,
    store,
});


jQuery(document).ready(function() {
    jQuery(window).resize(function() {
        //console.log("win resize", store);

        store.commit('windowResize');
    });

    store.commit('windowResize');
});

/**
 * Service Worker を起動する
 */
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {

        store.commit('overlay', true); //Service Workerがactivateになるまで操作させない

        navigator.serviceWorker.register("/service-worker.js").then(function(registration) {
            console.log('ServieWorker registration OK : ', registration.scope);
            //console.log(navigator.serviceWorker);

            var serviceWorker;
            if (registration.installing) {
                serviceWorker = registration.installing;

            } else if (registration.waiting) {
                serviceWorker = registration.waiting;

            } else if (registration.active) {
                //Service Worker稼働中　
                store.commit('overlay', false);
            }

            if (serviceWorker) {
                serviceWorker.addEventListener('statechange', function(e) {

                    console.log("service worker statechange.", e.target.state);
                    if (e.target.state == "activated") {
                        //Service Worker ステータス activate　
                        store.commit('overlay', false);
                    }
                });
            }

        }, function(error) {
            console.log('ServieWorker registration NG : ', error, error.message);
            store.commit('overlay', false);
        });
    });
}