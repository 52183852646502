<template>
  <common-layout-component2
    show-back="true"
    show-admin="true"
    v-on:on-nav-back="back"
  >

    <template v-slot:nav-message>
      <p>カテゴリ登録/編集</p>
    </template>
    <template v-slot:body>
      <div class="container mainContainer">
        <!-- ヘッダー部 -->
        <div
          id="header-area"
          class="columns"
        >
          <h2
            id="title"
            class="column"
            v-text="title"
          />
          <div class="column tr">
          </div>
        </div>
        <!--表組み -->
        <section class="subWindow">
          <div class="field columns">
            <label
              class="label column is-one-fifth"
              v-bind:class="{error:errCategoryName}"
            >カテゴリ名</label>
            <div class="control column">
              <input
                class="input"
                v-bind:class="{'error-ctrl':errCategoryName}"
                type="text"
                v-model="categoryName"
                placeholder="カテゴリ名が入ります"
                v-focus
              >
            </div>
          </div>
          <div
            v-show="errCategoryName"
            class="field columns"
            v-bind:class="{error:errCategoryName}"
          >
            <label class="label column is-one-fifth"></label>
            <div class="control column">
              <div v-bind:class="{error:errCategoryName}">
                <p
                  v-for="(err, idx) in categoryNameErrors"
                  :key="idx"
                  v-text="err"
                />
              </div>
            </div>
          </div>

          <!--<div class="field columns">
                        <label class="label column is-one-fifth">説明文</label>
                        <div class="control column">
					<textarea class="input" rows="4" name="" v-model="categoryDesc" style="height:auto;">ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。
					</textarea>
                        </div>
                    </div>-->

          <div class="field columns">
            <label class="label column is-one-fifth">公開/非公開</label>
            <div class="control column">

              <input
                type="radio"
                id="radio02-01"
                class="radio02-input"
                value="public"
                v-model="publicity"
              >
              <label for="radio02-01"><b>公開</b></label>

              <input
                type="radio"
                id="radio02-02"
                class="radio02-input"
                value="negative"
                v-model="publicity"
              >
              <label for="radio02-02"><b>非公開</b></label>
            </div>
          </div>
          <div class="field">
            <div class="control tc">
              <a
                class="button basic is-cancel"
                @click="onCancel"
              >キャンセル</a>　
              <a
                class="button basic is-login"
                style="color:white;"
                @click="onUpdate"
                v-text="submitLabel"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </common-layout-component2>
</template>
<script>
import logic from './js/CategoryEdit.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import TableComponent from '../../commons/TableComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

logic.components = {
  CommonLayoutComponent2,
  BaseCheckComponent,
  TableComponent,
  ModalComponent,
};

export default logic;
</script>
<style scoped>
.error {
  color: rgba(255, 0, 0, 0.6) !important;
}
.error-ctrl {
  background-color: rgba(255, 0, 0, 0.16) !important;
}
</style>
