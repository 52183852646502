var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "work-procedure-container", staticStyle: {} },
    [
      _c(
        "li",
        {
          staticClass: "list-group-item work-procedure",
          style: { background: _vm.getRootBgColor }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-1" },
              [
                _c("check-component", {
                  attrs: { "check-box-mode": _vm.getRootStatus }
                })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.showButton
              ? _c("div", { staticClass: "col-md-9" }, [
                  _c(
                    "span",
                    {
                      staticClass: "work-procedure-name",
                      staticStyle: { "word-wrap": "break-spaces" },
                      style: {
                        color: _vm.getRootTextColor,
                        "font-size": _vm.getFontSize(_vm.procedureItem)
                      },
                      on: {
                        click: function($event) {
                          return _vm.showItem(_vm.procedureItem)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.getItem.index) +
                          ". " +
                          _vm._s(_vm.getItem.name)
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.showButton
              ? _c("div", { staticClass: "col-md-1" }, [
                  _vm.procedureItem.child.length > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "child-open-indicator work-procedure-name",
                          on: {
                            click: function($event) {
                              _vm.openChild = !_vm.openChild
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.childOpenIndicatorLabel))]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showButton
              ? _c("div", { staticClass: "col-md-7" }, [
                  _c(
                    "span",
                    {
                      staticClass: "work-procedure-name",
                      staticStyle: { "word-wrap": "break-spaces" },
                      style: {
                        color: _vm.getRootTextColor,
                        "font-size": _vm.getFontSize(_vm.procedureItem)
                      },
                      on: {
                        click: function($event) {
                          return _vm.showItem(_vm.procedureItem)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.getItem.index) +
                          ". " +
                          _vm._s(_vm.getItem.name)
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showButton
              ? _c("div", { staticClass: "col-md-1" }, [
                  _vm.procedureItem.child.length > 0
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "child-open-indicator work-procedure-name",
                          on: {
                            click: function($event) {
                              _vm.openChild = !_vm.openChild
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.childOpenIndicatorLabel))]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showButton && !_vm.isPreview
              ? _c("div", { staticClass: "col-md-3" }, [
                  _vm.procedureItem.child.length === 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-info",
                          staticStyle: { color: "white" },
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.skipByCurrentProc(_vm.procedureItem)
                            }
                          }
                        },
                        [_vm._v("スキップ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.procedureItem.child.length === 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.completeByCurrentProc(
                                _vm.procedureItem
                              )
                            }
                          }
                        },
                        [_vm._v("完了")]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.procedureItem.child, function(item0) {
        return _vm.openChild
          ? _c(
              "li",
              {
                key: item0.uuid,
                staticClass: "list-group-item work-procedure-sub",
                style: { background: _vm.getBgColor(item0) }
              },
              [
                _vm.openChild
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-1" }, [_vm._v(" ")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-1" },
                        [
                          _c("check-component", {
                            attrs: { "check-box-mode": item0.status }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.showButton
                        ? _c("div", { staticClass: "col-md-9" }, [
                            _c(
                              "span",
                              {
                                staticClass: "work-procedure-name",
                                staticStyle: { "word-wrap": "break-spaces" },
                                style: {
                                  color: _vm.getTextColor(item0),
                                  "font-size": _vm.getFontSize(item0)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showItem(item0)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(item0.index) +
                                    ". " +
                                    _vm._s(item0.name)
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showButton
                        ? _c("div", { staticClass: "col-md-7" }, [
                            _c(
                              "span",
                              {
                                staticClass: "work-procedure-name",
                                staticStyle: { "word-wrap": "break-spaces" },
                                style: {
                                  color: _vm.getTextColor(item0),
                                  "font-size": _vm.getFontSize(item0)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showItem(item0)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(item0.index) +
                                    ". " +
                                    _vm._s(item0.name)
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showButton && !_vm.isPreview
                        ? _c("div", { staticClass: "col-md-3" }, [
                            _c("div", { staticClass: "pull-right" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info ",
                                  staticStyle: { color: "white" },
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.skipByCurrentProc(item0)
                                    }
                                  }
                                },
                                [_vm._v("スキップ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success pull-right",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      return _vm.completeByCurrentProc(item0)
                                    }
                                  }
                                },
                                [_vm._v("完了")]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }