<template>
  <div class="columns is-gapless" style="">
    <!-- left menu -->
    <div class="column is-one-quarter" style="overflow-y:auto;" v-bind:style="{ 'max-height': contentHeight + 'px' }">
      <div id="sidebar">
        <p class="side-title"><b>作業手順</b></p>

        <template v-for="(p, idx) in props">
          <work-procedure
            :key="p.work_procedure_id"
            :item="p"
            :is-last="isLastProcedure(idx)"
            action-name="updateCurrentProcedure"
          ></work-procedure>
        </template>

        <p class="tc pt10">
          <a class="button basic is-login" style="color:white;" @click="openSaveDialog"
            ><img src="/images/ico-report.png" alt="" /> &nbsp;{{
              isCompleted ? 'タスク完了' : '保存して中断'
            }}
          </a>
        </p>
      </div>
    </div>

    <!-- right contents -->
    <check-list-detail
      :content-height="contentHeight"
      :index="prop.index"
      :name="prop.name"
      :notice="prop.notice"
      :description="prop.description"
      :base-path="prop.basepath"
      :video-path="prop.videopath"
      :hide-content="hideContent"
      @skip="modalSkip"
      @completed="modalCompleted"
      @toggle="toggle"
      v-on:video-play="$emit('video-play')"
    />

    <confirm-modal
      v-if="showModalCompleted"
      :continue-func="completeByCurrentProc"
      :close-func="closeModalCompleted"
      title="タスク完了の確認"
      message="タスクを完了しますか？"
    />

    <confirm-modal
      v-if="showModalSkip"
      :continue-func="skipByCurrentProc"
      :close-func="closeModalSkip"
      title="タスクスキップの確認"
      message="タスクをスキップしますか？"
    />

    <confirm-modal
      v-if="showModalTaskCompleted"
      :continue-func="completeByTask"
      :close-func="closeModalTaskCompleted"
      title="タスク完了の確認"
      message="タスクを完了しますか？"
    />

    <modal-component
      v-if="showModalSaveHistory"
      is-quest="true"
      @modalYes="saveHistory"
      @modalNo="showModalSaveHistory = false"
    >
      <h4 slot="header">確認</h4>
      <h3 slot="body" style="text-align: center;">
        <p class="tc pb2em" style="padding-bottom:0;">
          <strong>タスクを保存して中断しますか？</strong>
        </p>
      </h3>
    </modal-component>

    <task-download-modal
      v-if="showModalDownload"
      :downloaded="taskDownloaded"
      @close-modal-download="closeModalDownload" 
    />
  </div>
</template>
<script>
import ModalComponent from '../../../commons/ModalComponent.vue';
import VideoComponent from '../../../commons/VideoComponent.vue';
import CheckListDetail from './CheckListDetail.vue';
import WorkProcedure from '../../common/components/WorkProcedure.vue';
import ConfirmModal from '../../common/components/ConfirmModal.vue';
import TaskDownloadModal from '../../common/components/TaskDownloadModal.vue';

export default {
  components: {
    WorkProcedure,
    ModalComponent,
    VideoComponent,
    CheckListDetail,
    ConfirmModal,
    TaskDownloadModal
  },
  props: {
    sideBarWidth: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    prop: {},
    props: {
      type: Array,
      default: function() {
        return [];
      }
    },
    contentHeight: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    sidebarWidth: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    showModalDownload:false,
    taskDownloaded:false,
  },
  data() {
    return {
      showModalCompleted: false,
      showModalSkip: false,
      showModalTaskCompleted: false,
      showModalSaveHistory: false
    };
  },
  computed: {
    hideContent() {
      //console.log("this.currentKind:",this.currentKind);
      //return this.currentKind === 'multi'; //← undefined?になる？
      return (this.prop.branch_id == 0 && this.prop.child.length > 0);
    },
    isCompleted() {
      const recursive = procs => {
        return procs.filter(proc => proc.status === 'incomplete');
      };

      return !Boolean(
        this.props.filter(proc => {
          if (proc.child && proc.child.length) {
            if (recursive(proc.child).length !== 0) {
              return true;
            }
          }

          return proc.status === 'incomplete';
        }).length
      );
    },

    hasChildProp(){
    },
  },
  methods: {
    isLastProcedure(index) {
      return index === this.props.length - 1;
    },
    modalSkip() {
      this.showModalSkip = true;
    },
    modalCompleted() {
      this.showModalCompleted = true;
    },
    closeModalSkip() {
      this.showModalSkip = false;
    },
    closeModalCompleted() {
      this.showModalCompleted = false;
    },
    closeModalTaskCompleted() {
      this.showModalTaskCompleted = false;
    },
    toggle: function() {
      this.$emit('toggle', 'normal');
    },
    skip: function() {
      this.$emit('skip', 'skip');
    },
    completed: function() {
      this.$emit('complete', 'complete');
    },
    outputReport: function() {
      this.$emit('output-report', 'report');
    },

    skipByCurrentProc: function() {
      this.showModalSkip = false;

      this.prop.status = 'partial';

      this.$store.commit('nextProc', this.prop);

      // 作業手順をスキップした事をログに記録する →　F2 No.111 記録するタイミングをタスク完了/中断時に変更
      //Vue.ELearning.Logs.logWorkProcedureSkip( this.prop.id, this.prop.name );
      this.$store.commit('storeWorkProcedureForLog', this.prop);
    },

    completeByCurrentProc: function() {
      this.showModalCompleted = false;

      this.prop.status = 'completed';
      this.$store.commit('nextProc', this.prop);

      // 作業手順を完了した事をログに記録する →　F2 No.111 記録するタイミングをタスク完了/中断時に変更
      //Vue.ELearning.Logs.logWorkProcedureComplete( this.prop.id, this.prop.name );
      this.$store.commit('storeWorkProcedureForLog', this.prop);
    },

    completeByTask() {

      // 作業手順を完了した事をログに記録する
      Vue.ELearning.Logs.logWorkProcedure();

      this.$store.dispatch('saveProgress', {
        complete: true,
        callback: () => {
          this.showModalTaskCompleted = false;
          this.$router.push( { path: `/user/tasks/${this.$store.state.current_category_id}` } );
        }
      });
    },
    saveHistory() {

      // 作業手順を完了した事をログに記録する
      Vue.ELearning.Logs.logWorkProcedure();

      this.$store.dispatch('saveProgress', {
        callback: () => {
          this.showModalSaveHistory = false;
          this.$router.push( { path: `/user/tasks/${this.$store.state.current_category_id}` } );
        }
      });
    },

    openSaveDialog() {
      const type = this.isCompleted ? 'showModalTaskCompleted' : 'showModalSaveHistory';
      this[type] = true;
    },

    closeModalDownload() {
      this.$emit('close-modal-download');
    }
  }
};
</script>
