var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": false,
      title: "みそシル教室",
      "main-class": "notfound_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("div", { staticClass: "container" }, [
              _c("p", [_vm._v("お探しのページは見つかりませんでした。")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button md",
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "top" })
                    }
                  }
                },
                [_vm._v("トップページに戻る")]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }