<template>
    <div class="column">
    <div class="has-background-white checklist-item sp-hidden" :style="itemStyle">
        <div class="contents-inner">
            <div class="contents-body">
                <!-- open　icon -->
                <a id="cont-open" @click="toggle">
                    <img src="/images/ico-right-arrow.png" alt="" />
                </a>
                <h2 class="title-list" v-text="itemName" />
                <template v-if="!hideContent">
                    <div class="video">
                        <div v-if="isVideo" style="width:90%">
                            <video-component :videoSrc="videoSrc" v-on:video-play="$emit('video-play')"></video-component>
                        </div>

                        <div v-else-if="isPdf" style="width:80%">
                            <iframe v-if="isOnline" :src="pdfSrc" width="100%" style="height:450px" ></iframe>
                            <object v-else :data="embedPdfSrc" type="application/pdf" width="100%" style="height:450px" />
                        </div>
                        <div v-else-if="isImage" style="width:90%">
                            <img :src="videoSrc" class="img-responsive" />
                        </div>
                        <div v-else>
                            <label></label>
                        </div>
                    </div>
                    <p class="category pb20" style="white-space:pre-wrap;" v-html="descriptionWithLink" />
                    <div v-show="hasNotice" class="attention" v-text="notice" />
                </template>
                <div class="field" v-if="!hideContent">
                    <div class="control tc">
                        <a class="button basic is-gray" @click="skip"
                            >スキップ</a
                        >&nbsp;&nbsp;
                        <a class="button basic is-login" @click="completed"
                            >完了</a
                        >
                    </div>
                </div>
            </div>
            <!-- contents-body-->
        </div>
    </div>
    <!-- column -->

    <!-- >>SP -->
      <div class="blockToggle_inner pc-hidden" v-if="!hideContent">
        <div class="blockToggle_detail">
          <div class="video_contents">
            <div class="video_contents_item">
                <div v-if="isVideo">
                    <video-component :videoSrc="videoSrc" v-on:video-play="$emit('video-play')"></video-component>
                </div>

                <div v-else-if="isPdf">
                    <iframe class="pdf" :src="pdfSrc" width="100%"></iframe>
                </div>

                <div v-else-if="isImage">
                    <img :src="videoSrc" class="img-responsive" />
                </div>
                      
            </div>
            <p v-if="descriptionWithLink && descriptionWithLink.length > 0" class="video_contents_text" v-html="descriptionWithLink" />
            <div class="cautionArea" v-if="notice && notice.length > 0">
              <p class="cautionArea_text">{{ notice }}</p>
            </div>
            <p class="btnArea-bottom">
              <button href="#" @click="skip" class="button rs is-skip">スキップ</button>
              <button href="#" @click="completed" class="button rs is-check">完了</button>
            </p>
          </div>
        </div>
      </div>

    <!-- <<SP -->    
    </div>
</template>

<script>
import VideoComponent from '../../../commons/VideoComponent.vue';
import pdf from 'vue-pdf'

export default {
    components: {
        VideoComponent,
        pdf
    },
    props: {
        contentHeight: {
            type: Number,
            required: true,
        },
        index: {
            type: String | Number,
            default: null,
        },
        name: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        notice: {
            type: String,
            default: '',
        },
        videoId: { //SP用
            type: Number,
            default: null,
        },        
        basePath: {
            type: String,
            default: '',
        },
        videoPath: {
            type: String,
            default: '',
        },
        hideContent: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            videoSrc: '', // blob:xxx になる場合もあり、その場合、拡張子はなくなる
            originalVideoSrc: '',
        }
    },

    mounted: function(){

        this.videoSrc = this.basePath + this.videoPath; //SP用初回
        this.originalVideoSrc = this.videoSrc;  //SP用初回
        
    },
    computed: {
        itemName() {
            return `${this.index == undefined ? '' : this.index + '.'} ${
                this.name
            }`;
        },
        itemStyle() {
            return {
                maxHeight: `${this.contentHeight}px`,
            };
        },
        fileName() {
            if ( ! Vue.Download.isOnline() )
            {
                return this.$store.state.currentProcedure.name + '.pdf';
            }

            return this.videoSrc.split('/').slice(-1)[0];
        },
        hasNotice() {
            return this.notice && this.notice.replace(' ', '').length > 0;
        },
        validSrc() {
            return this.videoSrc && this.videoSrc.length > 0;
        },
        
        isVideo() { return this.validSrc && Vue.ELearning.Util.isVideoFileName( this.originalVideoSrc ); },
        isPdf() { return this.validSrc && Vue.ELearning.Util.isPdfFileName( this.originalVideoSrc ); },
        isImage() { return this.validSrc && Vue.ELearning.Util.isImageFileName( this.originalVideoSrc ); },

        fileId() {
            if(this.videoId){
                return this.videoId; //SP用はthis.videoIdがセットされている　SPはcurrentProcedureを使用しない
            }

            return this.$store.state.currentProcedure.video_id;
        },

        pdfSrc(){
            return "/pdfviewer/web/viewer.html?file=" + this.videoSrc;
        },

        embedPdfSrc(){
            return this.videoSrc + "#toolbar=0";
        },

        descriptionWithLink() {

            //リンクURLがあれば、aタグに変換する
            if(this.description){

                const urls = this.description.match(/\[\[.*\]\]/g);
                var str = this.escapeHTML(this.description);
                
                if(urls){
                    urls.forEach(strUrl => {
                        const escapeUrl = this.escapeHTML(strUrl); //置換用
                        
                        var url = strUrl.replace('[[', '');
                            url = url.replace(']]', '');            

                        const link = this.enabledLink(url);
                        if(link){
                            const aBegin = '<a href="' + link + '" target="_blank">';
                            const aEnd = '</a>';            
                            var repUrl = strUrl.replace('[[', aBegin);
                            repUrl = repUrl.replace(']]', aEnd);            

                            str = str.replace(escapeUrl, repUrl);
                        }
                    });
                }
                return str;

            }else{
                return this.description;
            }
        },

        isOnline() {
            return Vue.Download.isOnline();
        },

    },
    methods: {
        toggle() {
            this.$emit('toggle');
        },
        skip() {
            this.$emit('skip');
        },
        completed() {
            this.$emit('completed');
        },

        async updateVideoSrc()
        {
            if ( ! Vue.Download.isOnline() )
            {
                const url = await Vue.Download.getFileURLByFileId( this.fileId );

                if ( url )
                {
                    this.videoSrc = url;
                }
            }
        },

        escapeHTML(str) {
            str = str.replace(/&/g, '&amp;');
            str = str.replace(/</g, '&lt;');
            str = str.replace(/>/g, '&gt;');
            str = str.replace(/"/g, '&quot;');
            str = str.replace(/'/g, '&#39;');
            return str;
        },

        enabledLink(str) {
            if( str.match( /^http?:\/\// ) || str.match( /^https?:\/\// )){
                str = str.replace(/</g, '&lt;');
                str = str.replace(/>/g, '&gt;');
                str = str.replace(/"/g, '&quot;');
                str = str.replace(/'/g, '&#39;');
                return str;
            }
            return null;
        }        
    },

    watch: {
        basePath: function ( value ) {
            this.videoSrc = `${value}${this.videoPath}`;
            this.originalVideoSrc = this.videoSrc;
        },
        videoPath: function ( value ) {
            this.videoSrc = `${this.basePath}${value}`;
            this.originalVideoSrc = this.videoSrc;
        },

        /*
        fileId: function ( value ) {
            this.updateVideoSrc();
        },
        */

        originalVideoSrc: async function ( value ) {
            this.updateVideoSrc();
        }
    }
};
</script>

<style scoped lang="scss">
.checklist-item {
    overflow-y: auto;

    .contents-inner {
        @media screen and (max-height: 800px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .button {
            color: #fff;
        }
    }
}
</style>
