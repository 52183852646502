var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("ユーザー登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c(
                      "h2",
                      { staticClass: "column", attrs: { id: "title" } },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column tr" })
                  ]
                ),
                _vm._v(" "),
                _c("section", { staticClass: "subWindow" }, [
                  _vm.loginFailed >= _vm.$store.state.appInfo.loginFailedLock
                    ? _c("div", { staticClass: "field columns" }, [
                        _c("p", { staticClass: "attention" }, [
                          _vm._v(
                            "このユーザーは使用停止中です。使用再開するにはパスワードを更新してください。"
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errUserName }
                      },
                      [_vm._v("ユーザー名")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userName,
                            expression: "userName"
                          },
                          { name: "focus", rawName: "v-focus" }
                        ],
                        staticClass: "input",
                        class: { "error-ctrl": _vm.errUserName },
                        attrs: {
                          type: "text",
                          placeholder: "ユーザー名が入ります"
                        },
                        domProps: { value: _vm.userName },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.userName = $event.target.value
                            },
                            _vm.update_furigana
                          ]
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errUserName,
                          expression: "errUserName"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errUserName }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errUserName } },
                          _vm._l(_vm.userNameErrors, function(err) {
                            return _c("p", [_vm._v(_vm._s(err))])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("ユーザー名(カナ)")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userNameKana,
                            expression: "userNameKana"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "ユーザー名(カナ)が入ります"
                        },
                        domProps: { value: _vm.userNameKana },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.userNameKana = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("部署名")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.orgName,
                            expression: "orgName"
                          }
                        ],
                        staticClass: "input",
                        attrs: {
                          type: "text",
                          placeholder: "部署名が入ります"
                        },
                        domProps: { value: _vm.orgName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.orgName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errMailAddress }
                      },
                      [_vm._v("メールアドレス")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newMailAddress,
                            expression: "newMailAddress"
                          }
                        ],
                        staticClass: "input",
                        class: { "error-ctrl": _vm.errMailAddress },
                        attrs: {
                          type: "text",
                          placeholder: "メールアドレスが入ります",
                          autocomplete: "new-password"
                        },
                        domProps: { value: _vm.newMailAddress },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newMailAddress = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errMailAddress,
                          expression: "errMailAddress"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errMailAddress }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errMailAddress } },
                          _vm._l(_vm.mailAddressErrors, function(err) {
                            return _c("p", [_vm._v(_vm._s(err))])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errPassword }
                      },
                      [_vm._v("パスワード")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password"
                          }
                        ],
                        staticClass: "input",
                        class: { "error-ctrl": _vm.errPassword },
                        attrs: {
                          type: "password",
                          placeholder: "パスワードが入ります",
                          autocomplete: "new-password"
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.password = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-clear btn-round",
                          on: {
                            click: function($event) {
                              _vm.password = ""
                            }
                          }
                        },
                        [_vm._v("クリア")]
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errPassword,
                          expression: "errPassword"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errPassword }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errPassword } },
                          _vm._l(_vm.passwordErrors, function(err) {
                            return _c("p", [_vm._v(_vm._s(err))])
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("権限")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.grant,
                            expression: "grant"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio02-01",
                          value: "editor"
                        },
                        domProps: { checked: _vm._q(_vm.grant, "editor") },
                        on: {
                          change: [
                            function($event) {
                              _vm.grant = "editor"
                            },
                            _vm.changeGrant
                          ],
                          click: _vm.changeSelectGrant
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio02-01" } }, [
                        _c("b", [_vm._v("編集・閲覧")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.grant,
                            expression: "grant"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio02-02",
                          value: "viewer"
                        },
                        domProps: { checked: _vm._q(_vm.grant, "viewer") },
                        on: {
                          change: [
                            function($event) {
                              _vm.grant = "viewer"
                            },
                            _vm.changeGrant
                          ],
                          click: _vm.changeSelectGrant
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio02-02" } }, [
                        _c("b", [_vm._v("閲覧のみ")])
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.grant,
                            expression: "grant"
                          }
                        ],
                        staticClass: "radio02-input",
                        attrs: {
                          type: "radio",
                          id: "radio02-03",
                          value: "admin"
                        },
                        domProps: { checked: _vm._q(_vm.grant, "admin") },
                        on: {
                          change: [
                            function($event) {
                              _vm.grant = "admin"
                            },
                            _vm.changeGrant
                          ],
                          click: _vm.changeSelectGrant
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "radio02-03" } }, [
                        _c("b", [_vm._v("管理者")])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns pb2em" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("所属グループ")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      !_vm.hasSelectedGroup || _vm.notSelect
                        ? _c(
                            "button",
                            {
                              staticClass: "button basic is-tags rs selection",
                              class: { notselect: _vm.notSelect },
                              on: { click: _vm.groupSelectorOpener }
                            },
                            [_vm._v("選択して下さい")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSelectedGroup && !_vm.notSelect
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button basic is-tags rs selection selected",
                              on: { click: _vm.groupSelectorOpener }
                            },
                            [_vm._v("選択中")]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control tc" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-cancel",
                          on: { click: _vm.back }
                        },
                        [_vm._v("キャンセル")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-login",
                          on: { click: _vm.saveUser }
                        },
                        [_vm._v("保存")]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.groupSelectorOpen
                  ? _c(
                      "modal-component",
                      {
                        attrs: { "is-quest": "true", width: "1200" },
                        on: {
                          modalYes: function($event) {},
                          modalNo: function($event) {
                            _vm.groupSelectorOpen = false
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("h3", [_vm._v("所属グループ選択")])]
                        ),
                        _vm._v(" "),
                        _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                          _c(
                            "table",
                            { staticClass: "basic scrollTable" },
                            [
                              _c("thead", [
                                _c("tr", [
                                  _c("th"),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: { cursor: "pointer" },
                                      on: { click: _vm.assignedGroupSort }
                                    },
                                    [
                                      _c("span", [_vm._v("グループ名 ")]),
                                      _vm._v(" "),
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.sortOrderGroup == "asc",
                                            expression:
                                              "sortOrderGroup == 'asc'"
                                          }
                                        ],
                                        attrs: {
                                          src: "/images/ico-down.png",
                                          alt: ""
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.sortOrderGroup == "desc",
                                            expression:
                                              "sortOrderGroup == 'desc'"
                                          }
                                        ],
                                        attrs: {
                                          src: "/images/ico-up.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _vm.groups.length == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          border: "1px solid gray"
                                        },
                                        attrs: { colspan: "2" }
                                      },
                                      [
                                        _vm._v(
                                          "ユーザーに紐付け可能なグループが存在しません。"
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.groups, function(group) {
                                return _c("tr", [
                                  _c(
                                    "td",
                                    { staticClass: "check" },
                                    [
                                      _c("base-check-component", {
                                        on: {
                                          "check-state-change": function(
                                            $event
                                          ) {
                                            return _vm.onUserByGroupChange(
                                              group
                                            )
                                          }
                                        },
                                        model: {
                                          value: group.selected,
                                          callback: function($$v) {
                                            _vm.$set(group, "selected", $$v)
                                          },
                                          expression: "group.selected"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "user",
                                      on: {
                                        click: function($event) {
                                          return _vm.onUserByGroupChange(group)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(group.groupName))]
                                  )
                                ])
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12",
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-cancel",
                                      on: {
                                        click: function($event) {
                                          _vm.groupSelectorOpen = false
                                        }
                                      }
                                    },
                                    [_vm._v("キャンセル")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-login",
                                      on: {
                                        click: function($event) {
                                          _vm.groupSelectorOpen = false
                                        }
                                      }
                                    },
                                    [_vm._v("選択完了")]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }