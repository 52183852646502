var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tags-resist-body" }, [
    _c(
      "div",
      { staticClass: "columns is-multiline" },
      [
        _vm._l(_vm.tags, function(tag) {
          return [
            _c("tag-select-item", { key: tag.tagId, attrs: { tag: tag } })
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("p", { staticClass: "tr" }, [
      _c(
        "button",
        { staticClass: "button basic is-gray", on: { click: _vm.openModal } },
        [_vm._v("\n            タグ新規作成\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }