<template>
    <modal-component @modalNo="close" is-quest="true" width="1200">
        <div slot="header">
            <h3>ユーザー選択</h3>
        </div>
        <div slot="body">
            <select-user-table :initial-users="users" />
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-md-12 button-wrap">
                    <button class="button basic is-cancel" @click="cancel">
                        キャンセル</button
                    >&nbsp;&nbsp;
                    <button class="button basic is-login" @click="close">
                        選択完了
                    </button>
                </div>
            </div>
        </div>
    </modal-component>
</template>

<script>
import ModalComponent from '../../../commons/ModalComponent.vue';
import SelectUserTable from './SelectUserTable.vue';

export default {
    components: {
        ModalComponent,
        SelectUserTable,
    },
    props: {
        users: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        close() {
            this.$emit('close-modal');
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>

<style lang="scss" scoped>
.button-wrap {
    text-align: center;
}
</style>
