var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("グループ登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c("h2", {
                      staticClass: "column",
                      attrs: { id: "title" },
                      domProps: { textContent: _vm._s(_vm.title) }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "column tr" })
                  ]
                ),
                _vm._v(" "),
                _c("section", { staticClass: "subWindow" }, [
                  _c("div", { staticClass: "field columns" }, [
                    _c(
                      "label",
                      {
                        staticClass: "label column is-one-fifth",
                        class: { error: _vm.errGroupName }
                      },
                      [_vm._v("グループ名")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.groupName,
                            expression: "groupName"
                          },
                          { name: "focus", rawName: "v-focus" }
                        ],
                        staticClass: "input",
                        class: { "error-ctrl": _vm.errGroupName },
                        attrs: {
                          type: "text",
                          placeholder: "グループ名が入ります"
                        },
                        domProps: { value: _vm.groupName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.groupName = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errGroupName,
                          expression: "errGroupName"
                        }
                      ],
                      staticClass: "field columns",
                      class: { error: _vm.errGroupName }
                    },
                    [
                      _c("label", { staticClass: "label column is-one-fifth" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "control column" }, [
                        _c(
                          "div",
                          { class: { error: _vm.errGroupName } },
                          _vm._l(_vm.groupNameErrors, function(err, idx) {
                            return _c("p", {
                              key: idx,
                              domProps: { textContent: _vm._s(err) }
                            })
                          }),
                          0
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("グループ説明文")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.groupText,
                            expression: "groupText"
                          }
                        ],
                        staticClass: "input",
                        attrs: { type: "text", placeholder: "" },
                        domProps: { value: _vm.groupText },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.groupText = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("閲覧タスク選択")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      !_vm.hasSelectedTasks
                        ? _c(
                            "button",
                            {
                              staticClass: "button basic is-tags rs selection",
                              on: { click: _vm.taskSelectorOpener }
                            },
                            [
                              _vm._v(
                                "\n                            選択して下さい\n                        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSelectedTasks
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button basic is-tags rs selection selected",
                              on: { click: _vm.taskSelectorOpener }
                            },
                            [
                              _vm._v(
                                "\n                            選択中\n                        "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field columns pb2em" }, [
                    _c("label", { staticClass: "label column is-one-fifth" }, [
                      _vm._v("ユーザー選択")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control column" }, [
                      !_vm.hasSelectedUsers
                        ? _c(
                            "button",
                            {
                              staticClass: "button basic is-tags rs selection",
                              on: { click: _vm.userSelectorOpener }
                            },
                            [
                              _vm._v(
                                "\n                            選択して下さい\n                        "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasSelectedUsers
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "button basic is-tags rs selection selected",
                              on: { click: _vm.userSelectorOpener }
                            },
                            [
                              _vm._v(
                                "\n                            選択中\n                        "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control tc" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-cancel",
                          on: { click: _vm.back }
                        },
                        [
                          _vm._v(
                            "\n                            キャンセル\n                        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "button basic is-login",
                        domProps: { textContent: _vm._s(_vm.submitLabel) },
                        on: { click: _vm.save }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm.userSelectorOpen
                  ? _c("select-user-modal", {
                      attrs: { users: _vm.users },
                      on: {
                        "close-modal": _vm.closeSelectUser,
                        cancel: _vm.cancelSelectUser
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.taskSelectorOpen
                  ? _c("select-task-modal", {
                      attrs: { "category-tasks": _vm.categoryTasks },
                      on: {
                        "close-modal": _vm.closeSelectTask,
                        cancel: _vm.cancelSelectTask
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }