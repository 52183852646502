<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" title="検索結果" main-class="search_page">
        <template v-slot:body v-if="result">
            <section class="result" :class="{show_filter: showFilter, bg_white:!isSearchTypeLesson}">
                <div class="container pt20 pb20">
                    <div class="page_header result_header">
                        <div>
                            <p class="result_num"><span>{{ count }}</span>件</p>
                        </div>
                        <button class="plane filter" @click="showFilter=true"><i class="fas fa-filter"></i>絞り込み</button>
                    </div>
                    <div class="lessons" v-if="isSearchTypeLesson">
                        <template v-for="(item, index) in result">
                            <lesson-cassette-component :key="index" :lesson="item" :showPrice="true">
                            </lesson-cassette-component>
                        </template>
                    </div>
                    <div class="teachers" v-if="!isSearchTypeLesson">
                        <template v-for="(item, index) in result">
                            <user-cassette-component :key="index" :user="item">
                            </user-cassette-component>
                        </template>
                    </div>
                </div>

                <div class="filter_box">
                    <transition name="show_filter_bg">
                        <div v-if="showFilter" class="bg" @click.stop.prevent="showFilter=false"></div>
                    </transition>
                    <transition name="show_filter_inner">
                        <div class="inner pt20" v-if="showFilter">
                            <search-form-component :is-search-type-lesson="isSearchTypeLesson"/>
                        </div>
                    </transition>

                </div>
            </section>            
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import SearchFormComponent from '../../miso/user/SearchFormComponent.vue';
    import LessonCassetteComponent from '../../miso/user/LessonCassetteComponent.vue';
    import UserCassetteComponent from '../../miso/user/UserCassetteComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            SearchFormComponent,
            LessonCassetteComponent,
            UserCassetteComponent,
        },
        beforeRouteUpdate (to, from, next) {
            this.showFilter = false
            this.getData(to.query)
            next()
        },
        data: function () {
            return {
                showFilter: false,
                result: null,
                isSearchTypeLesson: true,
                count:null,
            }
        },
        mounted:function(){

            var query = this.$libs.urlObj().query
            console.log("query",this.$libs.urlObj());
            this.getData(query)
        },
        methods:{
            getData: function(query){

                if(!query.type || query.type == "lesson"){
                    this.isSearchTypeLesson = true
                }else{
                    this.isSearchTypeLesson = false
                }

                var handler
                if(this.isSearchTypeLesson){
                    handler = Vue.ELearning.Search.lessons(query)
                }else{
                    handler = Vue.ELearning.Search.teachers(query)
                }
                handler.then(
                    function(res) {
                        console.log("task", res);
                        var content = res.data.content;
                        this.result = content.result;
                        this.count = content.count;
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },
            onNaviBack: function(){

                this.$libs.pushUrl("search")
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>
