<template>
    <common-layout-component2 show-back="true" show-admin="true" v-on:on-nav-back="back">

        <template v-slot:nav-message>
            <p>ユーザー登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">{{title}}</h2>
                    <div class="column tr">
                    </div>
                </div>
                <!--表組み -->
                <section class="subWindow">
                    <div v-if="loginFailed >= $store.state.appInfo.loginFailedLock" class="field columns">
                        <p class="attention">このユーザーは使用停止中です。使用再開するにはパスワードを更新してください。</p>
                    </div>
                    <div class="field columns">
                        <label class="label column is-one-fifth" v-bind:class="{error:errUserName}">ユーザー名</label>
                        <div class="control column">
                            <input class="input" v-bind:class="{'error-ctrl':errUserName}" type="text" v-model="userName" placeholder="ユーザー名が入ります" v-focus v-on:input="update_furigana">
                        </div>
                    </div>
                    <div v-show="errUserName" class="field columns" v-bind:class="{error:errUserName}">
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{error:errUserName}">
                                <p v-for="err in userNameErrors">{{err}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth">ユーザー名(カナ)</label>
                        <div class="control column">
                            <input class="input" type="text" v-model="userNameKana" placeholder="ユーザー名(カナ)が入ります">
                        </div>
                    </div>
                    <div class="field columns">
                        <label class="label column is-one-fifth">部署名</label>
                        <div class="control column">
                            <input class="input" type="text" v-model="orgName" placeholder="部署名が入ります">
                        </div>
                    </div>
                    <div class="field columns">
                        <label class="label column is-one-fifth" v-bind:class="{error:errMailAddress}">メールアドレス</label>
                        <div class="control column">
                            <input class="input" v-bind:class="{'error-ctrl':errMailAddress}" type="text" v-model="newMailAddress" placeholder="メールアドレスが入ります" autocomplete="new-password">
                        </div>
                    </div>
                    <div v-show="errMailAddress" class="field columns" v-bind:class="{error:errMailAddress}">
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{error:errMailAddress}">
                                <p v-for="err in mailAddressErrors">{{err}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth" v-bind:class="{error:errPassword}">パスワード</label>
                        <div class="control column">
                            <input class="input" v-bind:class="{'error-ctrl':errPassword}" type="password" v-model="password" placeholder="パスワードが入ります" autocomplete="new-password">
                        </div>
                        <div class="control column">
                            <button class="btn btn-clear btn-round" @click="password = ''">クリア</button>
                        </div>
                    </div>
                    <div v-show="errPassword" class="field columns" v-bind:class="{error:errPassword}">
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{error:errPassword}">
                                <p v-for="err in passwordErrors">{{err}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth">権限</label>
                        <div class="control column">

                            <input type="radio" id="radio02-01" class="radio02-input" v-model="grant" v-on:change="changeGrant" v-on:click="changeSelectGrant" value="editor">
                            <label for="radio02-01"><b>編集・閲覧</b></label>

                            <input type="radio" id="radio02-02" class="radio02-input" v-model="grant" v-on:change="changeGrant" v-on:click="changeSelectGrant" value="viewer">
                            <label for="radio02-02"><b>閲覧のみ</b></label>

                            <input type="radio" id="radio02-03" class="radio02-input" v-model="grant" v-on:change="changeGrant" v-on:click="changeSelectGrant" value="admin">
                            <label for="radio02-03"><b>管理者</b></label>
                        </div>
                    </div>

                    <div class="field columns pb2em">
                        <label class="label column is-one-fifth">所属グループ</label>
                        <div class="control column">
                            <button v-if="!hasSelectedGroup || notSelect" v-bind:class="{notselect: notSelect}" class="button basic is-tags rs selection" @click="groupSelectorOpener">選択して下さい</button>
                            <button v-if="hasSelectedGroup && !notSelect"  class="button basic is-tags rs selection selected" @click="groupSelectorOpener">選択中</button>                            
                        </div>
                    </div>
                    <div class="field">
                        <div class="control tc">
                            <button class="button basic is-cancel" @click="back">キャンセル</button>
                            <button class="button basic is-login" @click="saveUser">保存</button>
                        </div>
                    </div>
                </section>

                <!-- 所属グループ選択画面 -->
                <modal-component v-if="groupSelectorOpen" v-on:modalYes="" v-on:modalNo="groupSelectorOpen = false" is-quest="true" width="1200">
                    <div slot="header">
                        <h3>所属グループ選択</h3>
                    </div>
                    <div slot="body">
                        <!--<table class="table">
                            <thead>
                            <tr class="table-header">
                                <th class="table-header-area-left">&nbsp;</th>

                                <th class="table-header-area-right">グループ名</th>
                            </tr>
                            </thead>
                            <tbody style="height:400px;max-height:400px;overflow-y:scroll;">
                            <tr v-for="group in groups" class="data-row">
                                <td style="border:1px solid gray;vertical-align:middle;text-align:center;">
                                    <base-check-component v-on:check-state-change="onUserByGroupChange(group)"
                                                          v-model:check-state="group.selected"></base-check-component>
                                </td>
                                <td style="border:1px solid gray;text-align:center;vertical-align:middle;cursor:pointer;"
                                    v-on:click="onUserByGroupChange(group)">{{ group.groupName }}
                                </td>
                            </tr>
                            </tbody>
                        </table>-->


                        <table class="basic scrollTable">
                            <thead>
                            <tr>
                                <th></th>
                                <th style="cursor:pointer" @click="assignedGroupSort">
                                    <span>グループ名&nbsp;</span>
                                    <img v-show="sortOrderGroup == 'asc'" src="/images/ico-down.png" alt="">
                                    <img v-show="sortOrderGroup == 'desc'" src="/images/ico-up.png" alt="">
                                </th>

                                <!--<th style="cursor:pointer" @click="userTaskSort('org_name')">
                                    <span>部署名&nbsp;</span>
                                    <img v-show="sortAxisUser == 'org_name' && sortOrderUser == 'asc'" src="/images/ico-down.png" alt="">
                                    <img v-show="sortAxisUser == 'org_name' && sortOrderUser == 'desc'" src="/images/ico-up.png" alt="">
                                </th>-->
                            </tr>
                            </thead>

                            <tr v-if="groups.length == 0">
                                <td colspan="2" style="border:1px solid gray;">ユーザーに紐付け可能なグループが存在しません。</td>
                            </tr>
                            <tr v-for="group in groups">
                                <td class="check">
                                    <!--<label>
                                        <input type="checkbox" checked="checked" name="checkbox01[]"
                                               class="checkbox01-input">
                                        <span class="checkbox01-parts"></span>
                                    </label>-->
                                    <base-check-component v-on:check-state-change="onUserByGroupChange(group)" v-model:check-state="group.selected"></base-check-component>
                                </td>
                                <td class="user" v-on:click="onUserByGroupChange(group)">{{ group.groupName }}</td>

                            </tr>
                        </table>
                    </div>
                    <div slot="footer">
                        <div class="row">
                            <div class="col-md-12" style="text-align:center;">
                                <button class="button basic is-cancel" @click="groupSelectorOpen = false">キャンセル</button>
                                <button class="button basic is-login" @click="groupSelectorOpen = false">選択完了</button>

                            </div>
                        </div>
                    </div>
                </modal-component>
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
    import logic from './js/UserEdit.js';
    import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
    import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
    import SearchInputComponent from '../../commons/SearchInputComponent.vue';

    import ModalComponent from '../../commons/ModalComponent.vue';

    import PlusMinus from '../../commons/PlusMinus.vue';


    logic.components = {
        CommonLayoutComponent2,
        BaseCheckComponent,
        SearchInputComponent,
        PlusMinus,
        ModalComponent
    }

    export default logic;
</script>
<style lang="scss" scoped>
    .error {
        color: rgba(255, 0, 0, 0.6) !important;
    }
    .error-ctrl {
        background-color: rgba(255, 0, 0, 0.16) !important;
    }
    button.notselect {
      cursor: default;
      pointer-events: none;
      border-color: #dedede;
      color: #dedede;
      background: url("~Image/ico-add-lightgray.png") no-repeat right 1.5em center;
      img {
        opacity: .1;
      }
    }

    .attention {
        width: 100%;
        color: #d83030;
    }
</style>
