<template>
    <div>
        <template v-for="groupUser in currentGroupUsers">

            <div v-if="groupUser.users.length > 0" class="modal-cat" v-bind:style="{color:groupColor(groupUser)}">
                <span>{{ groupUser.groupName }}&nbsp;&nbsp;</span>

                <!--<plus-minus v-bind:indicator="groupTaskIndicator(categoryTask)" @indicator-click="categoryTask.isOpen = !categoryTask.isOpen" style="font-size:1.5em;cursor:pointer;"></plus-minus>-->

                <img v-if="!groupUser.isOpen" src="/images/ico-plus-gray.png" alt="" @click="groupUser.isOpen = !groupUser.isOpen" style="cursor:pointer;">
                <img v-else src="/images/ico-minus-gray.png" alt="" @click="groupUser.isOpen = !groupUser.isOpen" style="cursor:pointer;">

            </div>

            <div v-if="groupUser.isOpen" class="tasks">
                <template v-for="(user, idx) in groupUser.users">
                    <div v-if="isSelectionMode" class="task-body"
                        v-bind:class="{'last-l': isBottomUserLeft(groupUser.users, idx), 'last-r': isBottomUserRight(groupUser.users, idx)}"
                        v-bind:style="{color:userColor(user), 'font-weight':userWeight}"
                    >
                        <label>
                            <input type="checkbox" v-model="user.selected" class="checkbox01-input" disabled>
                            <span class="checkbox01-parts" @click="onCheckStateChange(user)">{{ user.userName }}</span>
                        </label>
                    </div>

                    <div  v-if="!isSelectionMode" class="task-body"
                        v-bind:class="{'last-l': isBottomUserLeft(groupUser.user, idx), 'last-r': isBottomUserRight(groupUser.user, idx)}"
                        v-bind:style="{color:userColor(user), 'font-weight':userWeight}"
                    >
                        <!--<label>
                            <span class="task-body">{{ task.taskName }}</span>
                        </label>-->
                        {{ user.userName }}
                    </div>

                    <div v-if="!isSelectionMode && groupUser.users.length - 1 == idx && (groupUser.users.length % 2) == 1" class="task-body last-r">&nbsp;</div>
                    <div v-if="isSelectionMode && groupUser.users.length - 1 == idx && (groupUser.users.length % 2) == 1" class="task-body last-r"><label>&nbsp;</label></div>
                </template>




                <!--
                <div class="task-body"><label>
                    <input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                    <span class="checkbox01-parts">タスク名2</span>
                </label></div>
                <div class="task-body last-l"><label>
                    <input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                    <span class="checkbox01-parts">タスク名3</span>
                </label></div>
                <div class="task-body last-r"><label>
                    <input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                    <span class="checkbox01-parts">タスク名4</span>
                </label></div>
                -->
            </div>
        </template>
    </div>
</template>
<script>
    import BaseCheckComponent from '../../../commons/BaseCheckComponent.vue';
    import PlusMinus from '../../../commons/PlusMinus.vue';

    export default {
        methods: {
            isBottomUserLeft: function(users, index) {
                var userSize = users.length;

                if(userSize % 2 == 0) {
                    if(userSize - 2 == index) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if(userSize - 1 == index) {
                        return true;
                    } else {
                        return false;
                    }
                    /*return false;*/
                }
            },
            isBottomUserRight: function(users, index) {
                var userSize = users.length;

                if(userSize % 2 == 0) {
                    if(userSize - 1 == index) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    /*if(taskSize - 1 == index) {
                        return true;
                    } else {
                        return false;
                    }*/
                    return false;
                }
            },

            groupTaskIndicator: function(groupTask) {
                return groupTask.isOpen ? 'close' : 'open';
            },

            onGroupTaskIndicatorClick: function(groupTask) {
                groupTask.isOpen = !groupTask.isOpen;
            },

            groupUserBorderRight: function(index, size){
                //console.log(index, size);

                if((index + 1) % 2 == 0) {
                    return "1px solid #d3d3d3";
                } else {
                    return 0;
                }
            },

            userSelected: function(user){
                user.selected = !user.selected;
            },

            groupColor: function(groupUser){
                var userSize = groupUser.users.length;

                var selectedItem = 0;

                for(var key in groupUser.users) {
                    var item = groupUser.users[key];
                    if(item.selected) {
                        selectedItem++;
                    }
                }

                if(selectedItem == 0) {
                    return "#d3d3d3";
                } else {
                    return "black";
                }
            },

            userColor: function(user){
                if(user.selected) {
                    return "#4FBE2B";
                } else {
                    return "#d3d3d3";
                }
            },

            userWeight: function(user){
                if(user.selected) {
                    return "bold";
                } else {
                    return "normal";
                }
            },

            onCheckStateChange(user) {
                user.selected = !user.selected;
            },
        },
        props: {
            currentGroupUsers:Array,
            isSelectionMode:Boolean,
        },

        components: {
            BaseCheckComponent,
            PlusMinus
        }
    }
</script>
