<template>
    <common-layout-component2 :show-back="is_show_back" show-admin="true" @on-nav-back="$router.push( top_page_path )">
    <template v-slot:nav-message>
      <p>閲覧ログ - ユーザー</p>
    </template>

    <template v-slot:body>
      <div class="columns is-gapless logs">
        <log-side-bar />

        <div class="column main-column">
          <div class="main-column-inner">
            <div class="row">
              <div class="col-md-6"><router-link :to="{ name: 'log-work-procedures-user', params: { user_id: user.user_id } }" v-if="user.user_id" class="log-user-button procedure-button">ユーザー作業履歴</router-link></div>
              <div class="col-md-6">
                <log-period-input :period_begin.sync="period_begin" :period_end.sync="period_end" v-on:period-updated="on_period_updated" />
              </div>
            </div>
            <div class="row board-container board-container01">
              <div class="col-md-6">
                <div class="board border-none">
                  <div class="user-basic-data">
                    <div class="user-icon"><img src="/images/icon_user.png" alt=""></div>
                    <div class="data1">
                      <p class="user-name">{{ user.user_name }}</p>
                      <p class="user-kana">{{ user.user_name_kana }}</p>
                    </div>
                    <div class="data2">
                      <p class="user-department">{{ user.org_name }}</p>
                      <p class="user-email">{{ user.mail_address }}</p>
                    </div>
                  </div>
                </div><!-- /.board -->
              </div><!-- /.col-md-6 -->
              
              <div class="col-md-6">
                <div class="board board-content-sm">
                  <dl>
                    <dt class="board-title icon-user title-lg">ログイン回数</dt>
                    <dd class="board-body">
                      <span class="count log-in-count">{{ total_login_count }}</span><span class="unit">回</span>
                    </dd>
                  </dl>
                </div><!-- /.board -->
              </div><!-- /.col-md-6 -->					
              
            </div><!-- /.board-container01 -->

            <div class="row board-container board-container02">
              <div class="col-md-6">
                <div class="board tab-panel">
                  <ul class="tab">
                    <li><a href="#tab1-1" class="tabcolor02 selected">ログイン回数</a></li>
                    <li><a href="#tab1-2" class="tabcolor02">動画再生回数</a></li>
                  </ul> 
                  <div class="panel">
                    <div id="tab1-1" class="panel-content"><canvas id="daily_authorized_logins_chart"></canvas></div>
                    <div id="tab1-2" class="panel-content"><canvas id="daily_video_play_counts_chart"></canvas></div>

                  </div><!-- /.panel -->
                </div><!-- /.board -->
              </div><!-- /.col-md-6 -->
              
              <div class="col-md-6">
                <div class="board">
                  <dl>
                    <dt class="board-title align-right">完了タスク数</dt>
                    <dd><canvas id="daily_completed_tasks_chart"></canvas></dd>
                  </dl>				
                </div><!-- /.board -->
              </div><!-- /.col-md-6 -->
            </div><!-- /.board-container02 -->
            
            
            <div class="row board-container board-container03">
              <div class="col-12">
              <div class="board tab-panel">
                <ul class="tab">
                  <li><a href="#tab2-1" class="tabcolor01 selected"><span class="icon-task">完了したタスク</span></a></li>
                  <li><a href="#tab2-2" class="tabcolor03"><span class="icon-video">よく動画再生した作業</span></a></li>
                </ul>
                <div class="panel">
                  <div id="tab2-1" class="panel-content">
                    <div class="download-button"><a href="#" v-on:click.prevent.stop.prevent="download_top_completed_tasks_csv">CSVダウンロード</a></div>

                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color03 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_completed_tasks_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>タスク</th>
                              <th>カテゴリ</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( task, n ) in top_completed_tasks" :key="task.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td><router-link :to="{ name: 'log-work-procedures-task', params: { task__id: task.id } }">{{ task.task_name }}</router-link></td>
                              <td>{{ task.category_name }}</td>
                              <td>{{ task.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <more-button v-model="is_top_completed_tasks_more" :list="top_completed_tasks" :limit="3" color="color03"></more-button>
                    </div>
                  </div><!-- /.panel-content -->


                  <div id="tab2-2" class="panel-content">
                    <div class="download-button"><a href="#" v-on:click.prevent.stop.prevent="download_top_played_work_procedures_csv">CSVダウンロード</a></div>
                    <div class="show-more-table">
                      <div class="table-responsive">
                        <table class="table basic2 color03 initial-rows3" v-bind:class="{ 'partial-display': ! is_top_played_work_procedures_more }">
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>作業手順</th>
                              <th>タスク</th>
                              <th>カテゴリ</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="( work_procedure, n ) in top_played_work_procedures" :key="work_procedure.id">
                              <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
                              <td>{{ work_procedure.work_procedure_name }}</td>
                              <td>{{ work_procedure.task_name }}</td>
                              <td>{{ work_procedure.category_name }}</td>
                              <td>{{ work_procedure.count }}回</td>
                            </tr>
                          </tbody>
                        </table>
                      </div><!-- /.table-responsive -->
                      <more-button v-model="is_top_played_work_procedures_more" :list="top_played_work_procedures" :limit="3" color="color03"></more-button>
                    </div><!-- /.show-more-table -->
                  </div><!-- /.panel-content -->
                </div><!-- /.panel -->
              </div><!-- /.tab-panel -->
              </div>
            </div><!-- /.board-container03 -->
          </div><!-- /.main-column -->
        </div><!-- /.column -->
      </div>
    </template>
  </common-layout-component2>
</template>
<script>

import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import LogSideBar from './components/LogSideBar';
import LogPeriodInput from './components/LogPeriodInput';
import MoreButton from './components/MoreButton';

import Chart from 'chart.js';
import moment from 'moment';

export default {
  components: {
      CommonLayoutComponent2,
      LogSideBar,
      LogPeriodInput,
      MoreButton,
  },
  
  data: () => { return {
    period_begin: "",                   // 期間 ( 開始 )
    period_end: "",                     // 期間 ( 終了 )

    user: {},                           // 表示対象のユーザー情報
    total_login_count: 0,               // ログイン回数

    daily_authorized_logins: [],        // 日別ログイン回数
    daily_video_play_counts: [],        // 日別動画再生回数数
    daily_completed_tasks: [],          // 日別完了タスク数

    // Chart Object
    daily_authorized_logins_chart: null,
    daily_video_play_counts_chart: null,
    daily_completed_tasks_chart: null,

    top_completed_tasks: [],            // 完了数の多いタスクの一覧
    top_played_work_procedures: [],     // 動画再生回数の多い作業手順の一覧

    // More
    is_top_completed_tasks_more: false,
    is_top_played_work_procedures_more: false,

  } },

  computed: {
    // 表示対象のユーザーのユーザー ID
    user_id: function () { return this.$route.params.user_id },

    // API の共通パラメータ
    api_params: function () { return { user_id: this.user_id, begin: this.period_begin, end: this.period_end }; },

    is_show_back: () => ! Vue.ELearning.Logs.isTopPage(),
    top_page_path: () => Vue.ELearning.Logs.getTopPagePath(),
  },

  mounted () {
    Vue.ELearning.Logs.init();

    // ユーザー情報取得
    Vue.ELearning.Logs.getApiData( Vue.ELearning.UserInfo.getUser( this.user_id ), ( data ) => {
      this.user = data;
    } );
  },

  methods: {
    /**
     * 期間が変更された時の処理
     */
    on_period_updated: function ( begin, end ) {
      // 
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getUserLoginCount( this.api_params ), ( data ) => { this.total_login_count = data; } )

      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getDailyAuthorizedLogins( this.api_params ), ( data ) => { this.daily_authorized_logins = data; this.setup_daily_authorized_logins_chart(); } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getDailyVideoPlayCounts( this.api_params ), ( data ) => { this.daily_video_play_counts = data; this.setup_daily_video_play_counts_chart(); } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getDailyCompletedTasks( this.api_params ), ( data ) => { this.daily_completed_tasks = data; this.setup_daily_completed_tasks_chart(); } );

      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopCompletedTasks( this.api_params ), ( data ) => { this.top_completed_tasks = data; } );
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTopPlayedWorkProcedures( this.api_params ), ( data) => { this.top_played_work_procedures = data; } );
    },

    /**
     * 日別ログイン回数のチャートを準備する
     */
    setup_daily_authorized_logins_chart: function () {

      if ( this.daily_authorized_logins_chart )
      {
        this.daily_authorized_logins_chart.destroy();
      }

      this.daily_authorized_logins_chart = new Chart( "daily_authorized_logins_chart", {
        type: 'line',
        data: {
          labels: this.daily_authorized_logins.map( ( row ) => moment( row.date ).format( "M/D" ) ),
          datasets: [{
            data: this.daily_authorized_logins.map( ( row ) => row.count ),
            borderColor: "#00BDDD",
            lineTension: 0.0,
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: "#FFFFFF",
            pointHoverRadius: 5,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function ( value ) { if ( value % 1 === 0 ) { return value; } }
              }
            }]
          },
        }
      });
    },

    /**
     * 日別動画再生回数のチャートを準備する
     */
    setup_daily_video_play_counts_chart: function () {

      if ( this.daily_video_play_counts_chart )
      {
        this.daily_video_play_counts_chart.destroy();
      }

      this.daily_video_play_counts_chart = new Chart( "daily_video_play_counts_chart", {
        type: 'line',
        data: {
          labels: this.daily_video_play_counts.map( ( row ) => moment( row.date ).format( "M/D" ) ),
          datasets: [{
            data: this.daily_video_play_counts.map( ( row ) => row.count ),
            borderColor: "#00BDDD",
            lineTension: 0.0,
            fill: false,
            pointRadius: 5,
            pointBackgroundColor: "#FFFFFF",
            pointHoverRadius: 5,
          }]
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [{
              ticks: {
                callback: function ( value ) { if ( value % 1 === 0 ) { return value; } }
              }
            }]
          },
        }
      });
    },

    /**
     * 日別完了タスク数のチャートを準備する
     */
    setup_daily_completed_tasks_chart: function () {
      
      if ( this.daily_completed_tasks_chart )
      {
        this.daily_completed_tasks_chart.destroy();
      }

      this.daily_completed_tasks_chart = new Chart( "daily_completed_tasks_chart", {
        type: 'bar',
        data: {
          labels: this.daily_completed_tasks.map( ( row ) => moment( row.date ).format( "M/D" ) ),
          datasets: [{
            data: this.daily_completed_tasks.map( ( row ) => row.count ),
            backgroundColor: "rgba(79, 190, 43, 0.5)",
          }]
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                categoryPercentage: 0.5,
                barPercentage: 0.5,
              }
            ],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function ( value ) { if ( value % 1 === 0 ) { return value; } }
              }
            }]
          }
        }
      });
    },

    download_top_completed_tasks_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopCompletedTasksCsv( this.api_params ), "top_completed_tasks.csv" );
    },

    download_top_played_work_procedures_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTopPlayedWorkProceduresCsv( this.api_params ), "top_played_work_procedures.csv" );
    }
  },
};

</script>