<template>
    <common-layout-component2
        show-back="true"
        show-admin="true"
        v-on:on-nav-back="back"
    >
        <template v-slot:nav-message>
            <p>タスク登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">タスク編集</h2>
                    <div class="column tr server-volume-area">
                        <div class="server-volume">
                            <div class="volume-text">サーバー容量</div>
                            <div class="volume-size">
                                <div>{{ currentFileSizeSum }} / {{ maxDiskSize() }}<span>GB</span></div>
                                <div>使用量 / 契約容量</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--　サブタイトル部 -->
                <div id="subtitle-area">タスク一覧</div>
                <!--表組み -->
                <div id="table-area" class="columns">
                    <div class="column">
                        <button class="button is-login" @click="onClickNew">
                            新規登録&nbsp;<img
                                src="/images/ico-plus.png"
                                alt=""
                            />
                        </button>
                        <button
                            class="button is-login"
                            v-bind:disabled="editButtonEnabled"
                            @click="onClickEdit"
                        >
                            編集&nbsp;<img
                                src="/images/ico-pen.png"
                                alt=""
                            />
                        </button>
                        <button
                            class="button basic is-gray"
                            v-bind:disabled="replicateButtonEnabled"
                            @click="onClickReplicate"
                        >
                            複製&nbsp;<img
                                src="/images/ico-copy.png"
                                alt=""
                            />
                        </button>
                        <button
                            class="button basic is-red"
                            v-bind:disabled="deleteButtonEnabled"
                            @click="showModalDelete = true"
                        >
                            削除&nbsp;<img
                                src="/images/ico-trash.png"
                                alt=""
                            />
                        </button>
                    </div>
                    <div class="column">
                        <p class="input-search tr">
                            <button
                                class="button basic is-gray2"
                                @click="sortUpdateDatetime"
                            >
                                更新日順&nbsp;<arrow-up-down-alt
                                    v-if="sortAxis == 'update_datetime'"
                                    v-bind:order="sortOrder"
                                ></arrow-up-down-alt
                                ><!--<img src="/images/ico-down2.png" alt="">-->
                            </button>
                            <button
                                class="button basic is-gray2"
                                @click="sortDictionary"
                            >
                                名前順&nbsp;<arrow-up-down-alt
                                    v-if="sortAxis == 'dictionary'"
                                    v-bind:order="sortOrder"
                                ></arrow-up-down-alt
                                ><!--<img src="/images/ico-down2.png" alt="">-->
                            </button>
                            <button
                                class="button basic is-gray2 rs"
                                @click="onAllTagOpen"
                            >
                                タグ検索&nbsp;&nbsp;{{ allTagIndicator }}
                            </button>
                        </p>

                        <div class="row all-tag-container">
                            <div class="col-md-1"></div>
                            <div class="col-md-10" style="">
                                <div
                                    v-if="allTagOpen"
                                    class="all-tag-indicator"
                                >
                                    ▲
                                </div>
                                <div
                                    v-if="allTagOpen"
                                    class="all-tag-area"
                                    style="padding-top:5px;padding-bottom:5px;"
                                >
                                    <ul>
                                        <template v-for="tag in allTag">
                                            <li
                                                :key="tag.tagId"
                                                v-bind:class="{
                                                    'tag-selected':
                                                        tag.selected,
                                                }"
                                                class="button basic is-tags"
                                                style="margin-left:5px;margin-right:5px;"
                                                @click="onTagSelect(tag)"
                                            >
                                                <span v-text="tag.tagName" />
                                            </li>
                                        </template>
                                        <li style="display:inline-block"></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- -->
                <div class="table-wrapper">
                    <table id="table001" class="basic scrollTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th style="min-width:10em;">作成/更新日</th>
                                <th>タスク名</th>
                                <th>カテゴリ名</th>
                                <th>閲覧権限</th>
                                <th style="min-width:8em;">公開/非公開</th>
                                <th>タグ</th>
                            </tr>
                        </thead>

                        <tr v-show="taskInfo.length == 0">
                            <td colspan="7">タスクが存在しません。</td>
                        </tr>
                        <template v-for="(task,index) in taskInfo">
                            <tr :key="index">
                                <td class="check">
                                    <label>
                                        <!--<input type="checkbox" checked="checked" name="checkbox01[]" class="checkbox01-input">
                                        <span class="checkbox01-parts"></span>-->
                                        <base-check-component
                                            v-on:check-state-change="
                                                onCheckStateChange(task)
                                            "
                                            v-model:check-state="task.selected"
                                        ></base-check-component>
                                    </label>
                                </td>
                                <td class="dates">
                                    <span class="green"
                                        ><img
                                            src="/images/ico-date.png"
                                            alt=""
                                        />作成日</span
                                    >&nbsp;{{ task.postDate }}<br />
                                    <span class="green"
                                        ><img
                                            src="/images/ico-date2.png"
                                            alt=""
                                        />更新日</span
                                    >&nbsp;{{ task.updateDate }}
                                </td>
                                <td class="tasks">{{ task.taskName }}</td>
                                <td class="cats">{{ task.categoryName }}</td>
                                <td class="edit">
                                    <button
                                        v-if="task.references == 2"
                                        class="button basic is-gray2 rs"
                                        @click="onOpenGroupByTask(task)"
                                    >
                                        グループリスト
                                        <img
                                            class=""
                                            src="/images/ico-add-gray.png"
                                            alt=""
                                        />
                                    </button>
                                    <span v-if="task.references == 1"
                                        >全てのユーザー</span
                                    >
                                </td>
                                <td class="open">
                                    <span>{{ task.publicity }}</span>

                                    <span
                                        v-if="task.category_del_flag == 1"
                                        style="color:red;"
                                        >(カテゴリ削除済み)</span
                                    >
                                    <span
                                        v-else-if="task.publicity_category == 0"
                                        style="color:gray;"
                                        >(カテゴリ非公開)</span
                                    >
                                </td>
                                <td class="group">
                                    <button
                                        v-if="
                                            task.tags.length > 0 &&
                                                !taskTagOpened(task)
                                        "
                                        class="button basic is-gray2 rs pl0"
                                        @click="tagAreaChange(task)"
                                    >
                                        タグ一覧
                                        <img
                                            class=""
                                            src="/images/ico-plus3.png"
                                            alt=""
                                        />
                                    </button>
                                    <button
                                        v-if="
                                            task.tags.length > 0 &&
                                                taskTagOpened(task)
                                        "
                                        class="button basic is-gray2 rs pl0"
                                        @click="tagAreaChange(task)"
                                    >
                                        タグ一覧
                                        <img
                                            class=""
                                            src="/images/ico-minus3.png"
                                            alt=""
                                        />
                                    </button>
                                </td>
                            </tr>
                            <tr :key="index + '-2'" v-if="taskTagOpened(task)">
                                <td colspan="7" class="table-tags task-tags has-tags">
                                        <template v-for="tag in task.tags">
                                            <span
                                                :key="tag.tagId"
                                                class="button basic is-tags"
                                                v-text="tag.tagName"
                                            />
                                        </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>

                <!-- モーダルコンポーネント -->
                <modal-component
                    v-if="isOpenUserByTask"
                    v-on:modalYes="isOpenUserByTask = !isOpenUserByTask"
                    v-on:modalNo="isOpenUserByTask = !isOpenUserByTask"
                    is-quest="true"
                    width="1200"
                >
                    <div slot="header">
                        <h3>グループリスト</h3>
                    </div>
                    <div slot="body">
                        <table class="basic scrollTable">
                            <thead>
                                <tr>
                                    <th>グループ名</th>
                                </tr>
                            </thead>

                            <tr v-if="groups.length == 0">
                                <td colspan="2" style="border:1px solid gray;">
                                    ユーザーに紐付け可能なグループが存在しません。
                                </td>
                            </tr>

                            <tr v-for="(name, key) in groups" :key="key">
                                <td class="user">
                                    {{ name }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div slot="footer">
                        <div class="row">
                            <div class="col-md-12" style="text-align:center;">
                                <button
                                    class="button basic is-cancel"
                                    @click="isOpenUserByTask = false"
                                >
                                    閉じる</button
                                >&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </modal-component>

                <!-- 元レイアウト(remodal使用のためVueでは使えず・・・独自モーダルに差し替え) -->
                <div
                    class="remodal"
                    v-if="false"
                    data-remodal-id="modal2"
                    data-remodal-options="hashTracking:false"
                >
                    <button
                        data-remodal-action="close"
                        class="remodal-close"
                    ></button>
                    <!-- ヘッダー部 -->
                    <div id="header-area-user" class="columns">
                        <h2 id="title-user">ユーザー選択</h2>
                    </div>
                    <div class="table-wrapper pb2em">
                        <table class="basic scrollTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        ユーザー名&nbsp;<img
                                            src="img/ico-down.png"
                                            alt=""
                                        />
                                    </th>
                                    <th>
                                        部署名&nbsp;<img
                                            src="img/ico-down.png"
                                            alt=""
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td class="check">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked="checked"
                                            name="checkbox01[]"
                                            class="checkbox01-input"
                                        />
                                        <span class="checkbox01-parts"></span>
                                    </label>
                                </td>
                                <td class="user">ユーザー名が入ります</td>
                                <td class="area">部署名が入ります</td>
                            </tr>
                            <tr>
                                <td class="check">
                                    <label>
                                        <input
                                            type="checkbox"
                                            name="checkbox01[]"
                                            class="checkbox01-input"
                                        />
                                        <span class="checkbox01-parts"></span>
                                    </label>
                                </td>
                                <td class="user">ユーザー名が入ります</td>
                                <td class="area">部署名が入ります</td>
                            </tr>
                        </table>
                    </div>
                    <p class="tc">
                        <a
                            data-remodal-action="cancel"
                            class="button basic is-cancel"
                            @click="isOpenUserByTask = false"
                            >キャンセル</a
                        >
                        <a
                            data-remodal-action="confirm"
                            class="button basic is-login"
                            @click="isOpenUserByTask = false"
                            >選択完了</a
                        >
                    </p>
                </div>

                <modal-component
                    v-if="showModalDelete"
                    v-bind:is-delete="true"
                    is-quest="true"
                    width="600"
                    @modalYes="onClickDelete"
                    @modalNo="showModalDelete = false"
                >
                    <h4 slot="header"><!--タスク削除の確認--></h4>

                    <h3 slot="body" style="text-align: center;">
                        <!--<h1 clss="tc"><img src="/images/ico-q.png" alt=""></h1>
                        <p>選択されたタスクを削除しますか？</p>-->
                        <p class="tc pb2em" style="margin-top:0.5em;">
                            <strong>選択されたタスクを削除しますか？</strong>
                        </p>
                    </h3>
                </modal-component>
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
import logic from './js/TaskList.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import TableComponent from '../../commons/TableComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

logic.components = {
    CommonLayoutComponent2,
    BaseCheckComponent,
    TableComponent,
    ModalComponent,
};

export default logic;
</script>
<style scoped lang="scss">
.column {
  white-space: nowrap;
}
@media (max-width: 991px){
  .columns:not(.is-desktop) {
    display: block!important;
  }
  .column {
    white-space: normal;
  }
}
@media (min-width: 992px){
  .columns:not(.is-desktop) {
    display: flex;
  }
}
.has-tags {
    .button {
        margin-left: 0.3rem;
        cursor: inherit;
        text-decoration: none;

        &:hover {
            opacity: 1;
        }
    }
}

.tag-selected {
    background-color: #5ac23a;

    /*border:2px solid #3f8124;*/

    color: white;
}

/* tag-indicator */

.all-tag-indicator {
    color: #f5f5f5;
    position: absolute;
    right: 1em;
    top: -1em;
    font-size: 1.3em;
}
.all-tag-area {
    background-color: #f5f5f5;

    top: 0;
}

.tag-item {
    display: inline-block;
    margin: 5px;
    border-radius: 1em;
    border: 2px solid #5ac23a;
    padding: 5px;
    color: #5ac23a;
    cursor: pointer;
}

.tag-item:hover {
    background-color: rgba(18, 194, 74, 0.5);
    color: rgba(0, 0, 0, 0.6);
}

.tag-selected {
    background-color: #5ac23a !important;

    /*border:2px solid #3f8124;*/

    color: white !important;
}

.all-tag-container {
    border-radius: 1em;
    margin: 0;
    padding: 1em;
    margin-top: 1em;
    position: absolute;
    top: 168px;
    right: -50px;
    width: 110%;
    z-index: 10000;

    /*box-shadow: 2px 2px 8px #ccc;*/
}

.server-volume-area {
    padding: 0;
    .server-volume {
        display: inline-block;
        border: solid 1px #dddddd;
        text-align: left;
        .volume-text {
            font-size: 14px;
            line-height: 28px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 35px 0 20px;
            &::before {
                content: "";
                display: inline-block;
                background: url(/images/ico-server-foot.png) 0 0 no-repeat;
                background-size: contain;
                width: 25px;
                height: 30px;
                vertical-align: top;
                margin-right: 7px;
                margin-top: 0;
           }
        }
        .volume-size {
           display: inline-block;
           vertical-align: middle;
           width: 170px;
           margin: 10px 0;
           div:first-child {
                font-size: 26px;
                line-height: 1;
                font-weight: bold;
                span {
                    font-size: 19px;
                    margin-left: 10px;
                }
            }
            div:last-child {
                font-size: 10px;
                font-weight: bold;
                color: #999999;
                margin: 2px 0 0;
            }
        }
    }
}

#table-area {
  position: relative;
  margin-bottom: 0;
}
#table-area >div:first-child {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#table-area >div:last-child {
  width: 100%;
}
#table-area >div:last-child .all-tag-container{
  position: static;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
}
#table-area >div:last-child .all-tag-container >div:last-child {
  flex: auto;
  max-width: unset;
  white-space: normal;
  padding: 0;
  margin: 1.25rem 0 0;
}

table.basic tr{
    td{
        border-bottom: none;
    }
    &:last-of-type{
        td{
            border-bottom: solid 1px #ccc;
        }
    }
}
table.basic .table-tags{
        position: relative;
        background-color: #E8F8E2;
        text-align: left;
        border-top: none;
        top: auto;
        padding: 5px 10px;

        .button.is-tags {
            background: none;
            margin: 5px 10px;
        }

        &::after{
            content: "";
            position: absolute;
            top: -22px;
            right: 61px;
            border: 12px solid transparent;
            border-bottom: 12px solid #E8F8E2;
        }
}
</style>
