window._ = require('lodash');

import store from './store'

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

//let token = document.head.querySelector('meta[name="csrf-token"]');
let token = window.Laravel.csrfToken;

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });


// 2019-10-28 kato PWA作業スケジュール No.37
// セッションに不具合が生じた場合はログイン画面へ遷移する
// 2019-10-28 kato PWA作業スケジュール No.27（実装そのものを追加）
// cookie にトークンが残っていた場合は、ログイン保持かどうか問い合わせて自動ログインする
window.emTransit = function(to, from, next, store, m) {
    var flg = false;
    console.log('window.emTransit >>> ' + m);
    console.log([to, from, next, store]);
    do {
        var authToken = Cookies.get('el-auth-token');
        Cookies.remove("el-auth-token");
        if (!authToken) {
            console.log('auth token なし');
            break;
        }
        if (!store) {
            console.log('store なし');
            break;
        }
        var res = store.dispatch("autoauthenticate", { 'remember': authToken });
        res.then(function(res) {
            var content = res.data;
            if (content.status_number == "200") {
                try {
                    // 自動ログイン
                    console.log(content);
                    store.commit("login", {
                        'userId': content.content.user_id,
                        'userName': content.content.user_name,
                        'grant': content.content.user_grant,
                        'authToken': authToken,
                        'remember': 'on'
                    });
                    // 出来ればリフレッシュで済ませたい
                    //next({path: to.path});
                    //this.$router.go({path: this.$router.currentRoute.path, force: true});
                    next({ path: '/' });
                } catch (e) {
                    console.log('window.emTransit : データ不正');
                    if (to && to.path != '/login') {
                        next({ path: '/login' });
                    }
                }
            } else {
                console.log('window.emTransit : エラーが返された');
                if (content.content.errors && content.content.errors == "login_destroyed") {

                    store.commit('loginDestroyed', true);
                    //this.$dialog.message(content.content.errors.login_destroyed);
                }
                if (to && to.path != '/login') {
                    next({ path: '/login' });
                }
            }
        });
        flg = true;
    } while (false);

    if (flg) {
        // store任せ
        console.log('window.emTransit : run store');
    } else if (to && to.path === '/login') {
        // ログイン画面なら無視
        console.log('window.emTransit : 無視');
    } else {
        console.log('window.emTransit : ログイン表示を発行して終了');
        next({ path: '/login' });
    }
}