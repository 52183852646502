var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "振り込み履歴",
      "main-class": "my_page sales"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "bg_yellow" }, [
              _c(
                "div",
                { staticClass: "container pt20" },
                [
                  _vm.payments && _vm.payments.length > 0 ? void 0 : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "proceeds_history" },
                    [
                      _vm._l(_vm.payments, function(item, index) {
                        return [
                          _c(
                            "a",
                            {
                              key: index,
                              attrs: {
                                href: _vm.$libs.url([
                                  "mypage",
                                  "sales",
                                  item.id
                                ])
                              }
                            },
                            [
                              _c("div", { staticClass: "transfar" }, [
                                _c("div", { staticClass: "head flex_nw" }, [
                                  _c("p", [
                                    _vm._v(
                                      "申請日：" +
                                        _vm._s(_vm.applyDate(item.created_at))
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "prceed" }, [
                                    _vm._v(
                                      _vm._s(
                                        Number(item.price_sum).toLocaleString()
                                      ) + "円"
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "flex_nw" }, [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.payedDate(item)))
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "status" }, [
                                    _vm._v(_vm._s(_vm.status(item)))
                                  ])
                                ])
                              ])
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                2
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }