<template>
    <common-layout-component2 show-back="true" show-admin="true" v-on:on-nav-back="back">

        <template v-slot:nav-message>
            <p>タグ登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">タグ編集</h2>
                    <div class="column tr">
                        <a href="#" type="button" id="download" class="btn btn-success btn-export" style="display:none;"></a>
                    </div>
                </div>
                <!--　サブタイトル部 -->
                <div id="subtitle-area">タグ一覧</div>
                <!--表組み -->
                <div id="table-area" class="columns">
                    <div class="column">
                        <button class="button is-login" @click="onClickNew">新規登録&nbsp;<img src="/images/ico-plus.png" alt=""></button>
                        <button v-bind:disabled="editButtonEnabled" @click="onClickEdit" class="button is-login">編集&nbsp;<img src="/images/ico-pen.png" alt=""></button>
                        <!--<button v-bind:disabled="replicateButtonEnabled" @click="onClickReplicate" class="button basic is-gray">複製&nbsp;<img src="/images/ico-copy.png" alt=""></button>-->
                        <button v-bind:disabled="deleteButtonEnabled" @click="showModalDelete = true" data-remodal-target="modal" class="button basic is-red">削除&nbsp;<img src="/images/ico-trash.png" alt=""></button>
                    </div>

                    <div class="column">
                        <p class="input-search tr">
                            <button href="#" class="button basic is-gray2" @click="sortUpdateDatetime">更新日順&nbsp;<arrow-up-down-alt v-if="sortAxis == 'update_datetime'" v-bind:order="sortOrder"></arrow-up-down-alt><!--<img src="/images/ico-down2.png" alt="">--></button>
                            <button href="#" class="button basic is-gray2" @click="sortDictionary">名前順&nbsp;<arrow-up-down-alt v-if="sortAxis == 'dictionary'" v-bind:order="sortOrder"></arrow-up-down-alt><!--<img src="/images/ico-down2.png" alt="">--></button>
                        </p>
                    </div>
                </div>
                <!-- -->
                <div class="table-wrapper">
                    <table class="basic scrollTable">
                        <thead>
                        <tr>
                            <th style="max-width:2em !important;"></th>
                            <th>タグ名</th>
                            <!--<th>説明文</th>-->

                        </tr>
                        </thead>

                        <tr v-show="tags.length == 0">
                            <td colspan="2">タグが存在しません。</td>
                        </tr>

                        <template v-for="tag in tags">
                            <tr>
                                <td class="check">
                                    <label>
                                        <!--<input checked="checked" type="checkbox" name="checkbox01[]" class="checkbox01-input">
                                        <span class="checkbox01-parts"></span>-->
                                        <base-check-component v-on:check-state-change="onCheckStateChange(tag)" v-model:check-state="tag.selected"></base-check-component>
                                    </label>
                                </td>
                                <td class="group-name">{{ tag.tagName }}</td>

                                <!--<td class="group-body">{{ category.categoryDesc }}</td>-->
                                <!--<td class="open">{{ category.publicity }}</td>-->
                            </tr>
                        </template>

                    </table>
                </div>

                <!-- モーダルダイアログ -->
                <modal-component v-if="showModalDelete" v-bind:is-delete="true" is-quest="true" width="600" @modalYes="onClickDelete" @modalNo="showModalDelete = false">
                    <h4 slot="header"><!--タグ削除の確認--></h4>
                    <h3 slot="body" style="text-align: center;">
                        <!--<h1 clss="tc"><img src="/images/ico-q.png" alt=""></h1>
                        <p>選択されたタグを削除しますか？</p>-->
                        <p class="tc pb2em" style="margin-top:0.5em;"><strong>選択されたタグを削除しますか？</strong></p>
                    </h3>
                </modal-component>
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
    import logic from './js/TagList.js';
    import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

    import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
    import TableComponent from '../../commons/TableComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';

    logic.components = {
        CommonLayoutComponent2,
        BaseCheckComponent,
        TableComponent,
        ModalComponent
    }

    export default logic;
</script>
