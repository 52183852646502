<template>
    <div class="level">
        <template v-for="(i, index) in [0,1,2,3,4]">
            <i v-if="i < level" class="fas fa-star" :key="index"></i>
            <i v-else class="far fa-star" :key="index"></i>
        </template>
    </div>
</template>

<script>
export default {
    components: {
    },
    
    props: {
        level:Number,
    },

    data: function() {
        return {
        };
    },

    mounted: function() {
    },
    methods: {
    },

    computed: {
    },

    watch: {
    },

};
</script>

<style scoped lang="scss">
</style>
