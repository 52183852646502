<template>
  <common-layout-component2
    show-back="true"
    show-admin="true"
    v-on:on-nav-back="back"
  >

    <template v-slot:nav-message>
      <p>タグ登録/編集</p>
    </template>
    <template v-slot:body>
      <div class="container mainContainer">
        <!-- ヘッダー部 -->
        <div
          id="header-area"
          class="columns"
        >
          <h2
            id="title"
            class="column"
            v-text="title"
          />
          <div class="column tr">
          </div>
        </div>
        <!--表組み -->
        <section class="subWindow">
          <div class="field columns">
            <label class="label column is-one-fifth">タグ名</label>
            <div class="control column">
              <input
                class="input"
                type="text"
                v-model="tagName"
                placeholder="タグ名が入ります"
                v-focus
              >
            </div>
          </div>

          <div class="field">
            <div class="control tc">
              <a
                class="button basic is-cancel"
                @click="onCancel"
              >キャンセル</a>　
              <a
                class="button basic is-login"
                style="color:white;"
                @click="onUpdate"
                v-text="submitLabel"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </common-layout-component2>
</template>
<script>
import logic from './js/TagEdit.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import TableComponent from '../../commons/TableComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

logic.components = {
  CommonLayoutComponent2,
  BaseCheckComponent,
  TableComponent,
  ModalComponent,
};

export default logic;
</script>
