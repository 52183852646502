var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "thumbnail" }, [
    !_vm.isVideo || _vm.hasThumbnail
      ? _c("p", { style: _vm.thumbnailImage() })
      : _vm._e(),
    _vm._v(" "),
    _vm.isVideo && !_vm.hasThumbnail
      ? _c("div", { staticClass: "video" }, [
          _c("video", {
            ref: "videoElement",
            staticStyle: { display: "none" },
            attrs: {
              src: _vm.video.video_path,
              mute: "",
              "webkit-playsinline": "",
              playsinline: "",
              autoplay: ""
            }
          }),
          _vm._v(" "),
          _c("img", { attrs: { src: _vm.videoThumbnail } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }