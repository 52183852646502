<template>
    <div class="embed-responsive">
        <div v-if="isNew">
            <div class="status-new">NEW</div>
            <div v-if="isUpdate" class="status" style="left:4em;">UPDATE</div>
        </div>
        <div v-else-if="isUpdate" class="status">UPDATE</div>
        <div class="pdf-thumbnail">
            <pdf :src="path" ></pdf>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
    components: {
        pdf
    },
    props: {
        path: {
            type: String,
            required: true,
        },
        pdfName: {
            type: String,
            required: true,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        }
    },
};
</script>

<style lang="scss" scoped>
.pdf-thumbnail {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>
