<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true"  title="やることリスト" main-class="notice_page">
        <template v-slot:body>
            <section class="bg_white">
                <div class="list_box">
                    <ul>
                        <template v-if="todos && todos.length > 0">
                        <template v-for="(item,index) in todos">
                        <li :key="index">
                            <a :href="link(item)" class="flex_nw">
                                <div class="icon">
                                    <p :style="iconImage(item.notice.user.image)"></p>
                                </div>
                                <div class="content">
                                    <p>{{item.notice.name}}</p>
                                    <p class="date">{{ date(item.created_at) }}</p>
                                </div>
                                <i class="fas fa-angle-right"></i>
                            </a>
                        </li>
                        </template>
                        </template>
                        <template v-else>
                            <div class="container pt50">
                                <p>やることリストはありません</p>
                            </div>
                        </template>
                    </ul>
                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';
import moment from 'moment';

export default {
    components: {
    CommonLayoutComponent,
    ModalComponent
    },
  data: function () {
    return {
        todos:[],
    };
  },
  created: function () {},

  mounted: function () {
      this.getData();
  },
  computed: {
  },
  methods: {
       getData: function(){
            Vue.ELearning.Todo.getAll()
            .then(
                function(res) {
                    if (res.data.status_number == 200) {
                        console.log("todo", res.data.content);
                        this.todos = res.data.content.data;
                    }
                }.bind(this),
            )
            .catch(function(res) {
                throw new Error("やることリストの取得に失敗しました", res);
            }.bind(this));
      },

     　iconImage: function(path) {
          if(path){
              return 'background: url(' + path + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      },

      link: function(item){
          if((item.notice.type >= 20 && item.notice.type < 30) ||
             (item.notice.type >= 50 && item.notice.type < 60)){
               return this.$libs.url(['lesson',item.task_id, 'intro'])
          }

          if(item.notice.type >= 30 && item.notice.type < 40){
               return this.$libs.url(['lesson',item.task_id, 'intro'],{query:{view:'advice'}})
          }
          if(item.notice.type >= 40 && item.notice.type < 50){
               return this.$libs.url(['lesson',item.task_id, 'intro'],{query:{view:'qa'}})    
          }

          this.$libs.url(['lesson',item.task_id, 'intro'],{query:{view:'advice'}})  
      },

      date: function(stringDate){
          var date = moment(stringDate);
          return date.format("MM月DD日"); 
      },
      onNaviBack: function(){
            history.back()
      },
  },
}

</script>
<style lang="scss" scoped>
</style>
