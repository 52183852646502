var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "個人情報設定",
      "main-class": "my_page edit"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "user_header bg_yellow" }, [
              _c("div", { staticClass: "container pt30 pb30" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("p", { style: _vm.iconImage })
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "label",
                    {
                      staticClass: "file_label_s",
                      attrs: { for: "file_upload" }
                    },
                    [
                      _vm._v(
                        "\n                        編集\n                    "
                      ),
                      _c("input", {
                        attrs: {
                          type: "file",
                          id: "file_upload",
                          accept: ".png, .jpg, .jpeg, .gif"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeImage($event)
                          }
                        }
                      })
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "bg_yellow" }, [
              _c("div", { staticClass: "list_box input_box" }, [
                _c("h2", [_vm._v("ニックネーム")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.displayName,
                          expression: "displayName"
                        }
                      ],
                      class: { error: _vm.errDisplayName },
                      attrs: {
                        type: "text",
                        name: "displayname",
                        placeholder: ""
                      },
                      domProps: { value: _vm.displayName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.displayName = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.displayName
                      ? _c("span", [_vm._v("タップして編集")])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.errDisplayName
                  ? _c("p", { staticClass: "error_description" }, [
                      _vm._v(_vm._s(_vm.displayNameErrors.join(" ")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box input_box" }, [
                _c("h2", [_vm._v("名前")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userName,
                          expression: "userName"
                        }
                      ],
                      class: { error: _vm.errUserName },
                      attrs: { type: "text", name: "name", placeholder: "" },
                      domProps: { value: _vm.userName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.userName = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.userName
                      ? _c("span", [_vm._v("タップして編集")])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.errUserName
                  ? _c("p", { staticClass: "error_description" }, [
                      _vm._v(_vm._s(_vm.userNameErrors.join(" ")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box input_box" }, [
                _c("h2", [_vm._v("メールアドレス")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newEmail,
                          expression: "newEmail"
                        }
                      ],
                      class: { error: _vm.errMailAddress },
                      attrs: { type: "text", name: "email", placeholder: "" },
                      domProps: { value: _vm.newEmail },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newEmail = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.email
                      ? _c("span", [_vm._v("タップして編集")])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.errMailAddress
                  ? _c("p", { staticClass: "error_description" }, [
                      _vm._v(_vm._s(_vm.mailAddressErrors.join(" ")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box input_box" }, [
                _c("h2", [_vm._v("ログインパスワード")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password,
                          expression: "password"
                        }
                      ],
                      class: { error: _vm.errPassword },
                      attrs: {
                        type: "password",
                        name: "password",
                        placeholder: "",
                        autocomplete: "off"
                      },
                      domProps: { value: _vm.password },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.password = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.password
                      ? _c("span", [_vm._v("タップして編集")])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.errPassword
                  ? _c("p", { staticClass: "error_description" }, [
                      _vm._v(_vm._s(_vm.passwordErrors.join(" ")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box input_box" }, [
                _c("h2", [_vm._v("電話番号")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone"
                        }
                      ],
                      class: { error: _vm.errPhone },
                      attrs: { type: "text", name: "phone", placeholder: "" },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.phone = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    !_vm.phone
                      ? _c("span", [_vm._v("タップして編集")])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _vm.errPhone
                  ? _c("p", { staticClass: "error_description" }, [
                      _vm._v(_vm._s(_vm.phoneErrors.join(" ")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box input_box select_box" }, [
                _vm.grantId == 2 ? _c("h2", [_vm._v("講師情報")]) : _vm._e(),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("span", [_vm._v("年齢")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.age,
                            expression: "age"
                          }
                        ],
                        attrs: { name: "age" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.age = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _vm._l(_vm.$config.ages, function(item, index) {
                          return [
                            _c(
                              "option",
                              { key: index, domProps: { value: item.value } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v("性別")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sex,
                            expression: "sex"
                          }
                        ],
                        attrs: { name: "sex" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.sex = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _vm._l(_vm.$config.sex, function(item, index) {
                          return [
                            _c(
                              "option",
                              { key: index, domProps: { value: item.value } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "checkbox" }, [
                    _c("p", { staticClass: "name" }, [
                      _vm.grantId == 1
                        ? _c("span", [_vm._v("楽器")])
                        : _vm._e(),
                      _vm.grantId == 2
                        ? _c("span", [_vm._v("担当楽器")])
                        : _vm._e(),
                      _c("span", { staticClass: "selsected" }, [
                        _vm._v(
                          _vm._s(_vm.$config.names("parts", _vm.part).join(","))
                        )
                      ]),
                      _c("i", { staticClass: "fas fa-chevron-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "items" },
                      [
                        _vm._l(_vm.$config.parts, function(item, index) {
                          return [
                            _c("label", { key: index }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.part,
                                    expression: "part"
                                  }
                                ],
                                attrs: { type: "checkbox", name: "part" },
                                domProps: {
                                  value: item.value,
                                  checked: Array.isArray(_vm.part)
                                    ? _vm._i(_vm.part, item.value) > -1
                                    : _vm.part
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.part,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.value,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.part = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.part = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.part = $$c
                                    }
                                  }
                                }
                              }),
                              _c("p", [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _c("i", { staticClass: "fas fa-check" })
                              ])
                            ])
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "checkbox" }, [
                    _c("p", { staticClass: "name" }, [
                      _vm.grantId == 1
                        ? _c("span", [_vm._v("習得したいジャンル")])
                        : _vm._e(),
                      _vm.grantId == 2
                        ? _c("span", [_vm._v("得意ジャンル")])
                        : _vm._e(),
                      _c("span", { staticClass: "selsected" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$config.names("genres", _vm.genre).join(",")
                          )
                        )
                      ]),
                      _c("i", { staticClass: "fas fa-chevron-down" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "items" },
                      [
                        _vm._l(_vm.$config.genres, function(item, index) {
                          return [
                            _c("label", { key: index }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.genre,
                                    expression: "genre"
                                  }
                                ],
                                attrs: { type: "checkbox", name: "genre" },
                                domProps: {
                                  value: item.value,
                                  checked: Array.isArray(_vm.genre)
                                    ? _vm._i(_vm.genre, item.value) > -1
                                    : _vm.genre
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.genre,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = item.value,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.genre = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.genre = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.genre = $$c
                                    }
                                  }
                                }
                              }),
                              _c("p", [
                                _c("span", [_vm._v(_vm._s(item.name))]),
                                _c("i", { staticClass: "fas fa-check" })
                              ])
                            ])
                          ]
                        })
                      ],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _vm.grantId == 1
                      ? _c("span", [_vm._v("音楽歴")])
                      : _vm._e(),
                    _vm.grantId == 2
                      ? _c("span", [_vm._v("講師歴")])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.career,
                            expression: "career"
                          }
                        ],
                        attrs: { name: "career" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.career = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _vm._l(_vm.$config.career, function(item, index) {
                          return [
                            _c(
                              "option",
                              { key: index, domProps: { value: item.value } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box input_box" }, [
                _c("h2", [_vm._v("プロフィール")]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.profile,
                          expression: "profile"
                        }
                      ],
                      class: { error: _vm.errProfile },
                      attrs: { placeholder: "" },
                      domProps: { value: _vm.profile },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.profile = $event.target.value
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _vm.errProfile
                  ? _c("p", { staticClass: "error_description" }, [
                      _vm._v(_vm._s(_vm.profileErrors.join(" ")))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "container pt20" }, [
                _c(
                  "button",
                  { staticClass: "button md", on: { click: _vm.save } },
                  [_vm._v("保存する")]
                )
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }