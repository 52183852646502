var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column" }, [
    _c(
      "div",
      {
        staticClass: "has-background-white checklist-item sp-hidden",
        style: _vm.itemStyle
      },
      [
        _c("div", { staticClass: "contents-inner" }, [
          _c(
            "div",
            { staticClass: "contents-body" },
            [
              _c(
                "a",
                { attrs: { id: "cont-open" }, on: { click: _vm.toggle } },
                [
                  _c("img", {
                    attrs: { src: "/images/ico-right-arrow.png", alt: "" }
                  })
                ]
              ),
              _vm._v(" "),
              _c("h2", {
                staticClass: "title-list",
                domProps: { textContent: _vm._s(_vm.itemName) }
              }),
              _vm._v(" "),
              !_vm.hideContent
                ? [
                    _c("div", { staticClass: "video" }, [
                      _vm.isVideo
                        ? _c(
                            "div",
                            { staticStyle: { width: "90%" } },
                            [
                              _c("video-component", {
                                attrs: { videoSrc: _vm.videoSrc },
                                on: {
                                  "video-play": function($event) {
                                    return _vm.$emit("video-play")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        : _vm.isPdf
                        ? _c("div", { staticStyle: { width: "80%" } }, [
                            _vm.isOnline
                              ? _c("iframe", {
                                  staticStyle: { height: "450px" },
                                  attrs: { src: _vm.pdfSrc, width: "100%" }
                                })
                              : _c("object", {
                                  staticStyle: { height: "450px" },
                                  attrs: {
                                    data: _vm.embedPdfSrc,
                                    type: "application/pdf",
                                    width: "100%"
                                  }
                                })
                          ])
                        : _vm.isImage
                        ? _c("div", { staticStyle: { width: "90%" } }, [
                            _c("img", {
                              staticClass: "img-responsive",
                              attrs: { src: _vm.videoSrc }
                            })
                          ])
                        : _c("div", [_c("label")])
                    ]),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "category pb20",
                      staticStyle: { "white-space": "pre-wrap" },
                      domProps: { innerHTML: _vm._s(_vm.descriptionWithLink) }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.hasNotice,
                          expression: "hasNotice"
                        }
                      ],
                      staticClass: "attention",
                      domProps: { textContent: _vm._s(_vm.notice) }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              !_vm.hideContent
                ? _c("div", { staticClass: "field" }, [
                    _c("div", { staticClass: "control tc" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button basic is-gray",
                          on: { click: _vm.skip }
                        },
                        [_vm._v("スキップ")]
                      ),
                      _vm._v("  \n                    "),
                      _c(
                        "a",
                        {
                          staticClass: "button basic is-login",
                          on: { click: _vm.completed }
                        },
                        [_vm._v("完了")]
                      )
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ]
    ),
    _vm._v(" "),
    !_vm.hideContent
      ? _c("div", { staticClass: "blockToggle_inner pc-hidden" }, [
          _c("div", { staticClass: "blockToggle_detail" }, [
            _c("div", { staticClass: "video_contents" }, [
              _c("div", { staticClass: "video_contents_item" }, [
                _vm.isVideo
                  ? _c(
                      "div",
                      [
                        _c("video-component", {
                          attrs: { videoSrc: _vm.videoSrc },
                          on: {
                            "video-play": function($event) {
                              return _vm.$emit("video-play")
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm.isPdf
                  ? _c("div", [
                      _c("iframe", {
                        staticClass: "pdf",
                        attrs: { src: _vm.pdfSrc, width: "100%" }
                      })
                    ])
                  : _vm.isImage
                  ? _c("div", [
                      _c("img", {
                        staticClass: "img-responsive",
                        attrs: { src: _vm.videoSrc }
                      })
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.descriptionWithLink && _vm.descriptionWithLink.length > 0
                ? _c("p", {
                    staticClass: "video_contents_text",
                    domProps: { innerHTML: _vm._s(_vm.descriptionWithLink) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.notice && _vm.notice.length > 0
                ? _c("div", { staticClass: "cautionArea" }, [
                    _c("p", { staticClass: "cautionArea_text" }, [
                      _vm._v(_vm._s(_vm.notice))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "btnArea-bottom" }, [
                _c(
                  "button",
                  {
                    staticClass: "button rs is-skip",
                    attrs: { href: "#" },
                    on: { click: _vm.skip }
                  },
                  [_vm._v("スキップ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button rs is-check",
                    attrs: { href: "#" },
                    on: { click: _vm.completed }
                  },
                  [_vm._v("完了")]
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }