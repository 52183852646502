<template>
    <div class="video">
        <img v-if="!isVideo" :src="video" alt="">
        <video v-if="isVideo" :src="srcVideo" controls :poster="thumbnail" webkit-playsinline playsinline></video>
    </div>
</template>

<script>
export default {

  components: {
  },
  props: {
      video:String,
      thumbnail:String,
  },
  data: function () {
    return {
    };
  },
  mounted: function () {
  },
  computed: {
    isVideo: function(){
        var isVideo = false;

      if(this.video){
        isVideo = isVideo || this.video.endsWith('.mp4');
        isVideo = isVideo || this.video.endsWith('.MP4');
        isVideo = isVideo || this.video.endsWith('.mov');
        isVideo = isVideo || this.video.endsWith('.MOV');
      }
      return isVideo;
    },
    srcVideo: function(){
      return this.video + "#t=0.001"
    },
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
</style>
