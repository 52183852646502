<template>
    <div class="column is-half cat-item top">
        <a
            href="javascript:void(0)"
            class="menu-item"
            :class="itemClass"
            @click="navTo"
        >
            <h3>
                <img
                    :src="iconSrc"
                    alt=""
                >
                <span v-text="linkText" />
            </h3>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        iconFileName: {
            type: String,
            required: true,
        },
        linkPath: {
            type: String,
            required: true,
        },
        linkText: {
            type: String,
            required: true,
        },
        allowGrant: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        basePath() {
            return process.env.MIX_VUEROOTER_BASE;
        },
        allowed() {
            return this.allowGrant <= this.currentGrant;
        },
        iconSrc() {
            return `${this.basePath}/images/${this.iconFileName}`;
        },
        currentGrant() {
            return this.$store.state.userInfo.grant;
        },
        itemClass() {
            const classes = [];
            if (!this.allowed) {
                classes.push('disabled');
            }
            return classes;
        },
    },
    methods: {
        navTo() {
            if (!this.allowed) {
                return;
            }
            this.$router.push({ path: this.linkPath });
        },
    },
};
</script>

<style scoped lang="scss">
.menu-item {
    text-decoration: none;

    &.disabled {
        color: #dedede;
        cursor: default;

        &:hover {
            background-color: white !important;
            color: #dedede !important;
            text-decoration: none;
            cursor: default;

            border-radius: 20px !important;
            box-shadow: 2px 2px 8px #ccc !important;
            padding: 15px !important;
        }
    }
}
</style>
