<template>
    <transition name="modal" @after-leave="$emit('afterLeave')">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container" v-bind:id="currentContainerId" v-bind:style="modalContainerStyle">

                    <!--<span class="close-cursor" @click="$emit('modalNo')">✖</span>-->

                    <div v-show="!isDelete && !isPublish && !isSave" class="modal-header" v-bind:id="currentHeaderId" style="border-bottom:0;">
                        <slot name="header"></slot>
                    </div>

                    <div class="modal-body" v-bind:id="currentBodyId"   v-bind:style="bodyStyle">
                        <h1 v-show="isDelete" clss="tc" style="text-align: center"><img src="/images/ico-q.png" alt=""></h1>
                        <h1 v-show="isPublish" clss="tc" style="text-align: center"><img src="/images/ico-q-green.png" alt=""></h1>

                        <slot name="body">
                            default body
                        </slot>
                    </div>

                    <div class="modal-footer" v-bind:id="currentFooterId" style="border-top:0;">
                        <div class="row" style="width:100%;">
                            <div class="col-md-12" style="margin-left:0;padding-left:0;">
                                <slot name="footer">
                                    <!--<slot v-if="isQuestionMode" name="footer">

                                        <button class="button basic is-cancel" @click="$emit('modalNo')">いいえ</button>
                                        <button class="button basic is-login" @click="$emit('modalYes')">はい</button>
                                    </slot>

                                    <slot v-if="!isQuestionMode" name="footer">
                                        <button class="btn btn-success" @click="$emit('modalOk')">OK</button>
                                    </slot>-->

                                    <div v-if="isQuestionMode && !isDelete && !isPublish" name="footer" style="text-align:center;">

                                        <button class="button basic is-cancel" @click="$emit('modalNo')">{{　noText　}}</button>&nbsp;&nbsp;
                                        <button class="button basic is-login" @click="$emit('modalYes')">{{　yesText　}}</button>
                                    </div>
                                    <div v-if="isDelete" name="footer" style="text-align:center;">

                                        <button class="button basic is-cancel" @click="$emit('modalNo')">キャンセル</button>&nbsp;&nbsp;
                                        <button class="button basic is-red" @click="$emit('modalYes')">削除</button>
                                    </div>
                                    <div v-if="isPublish" name="footer" style="text-align:center;">

                                        <button class="button basic is-cancel" @click="$emit('modalNo')">キャンセル</button>&nbsp;&nbsp;
                                        <button class="button basic is-red" @click="$emit('modalYes')">書き出し</button>
                                    </div>

                                    <div v-if="isSave" name="footer" style="text-align:center;">

                                        <button class="button basic is-cancel" @click="$emit('modalNo')">キャンセル</button>&nbsp;&nbsp;
                                        <button class="button basic is-login save" @click="$emit('modalYes')">保存</button>
                                    </div>

                                    <div v-if="isChange" name="footer" style="text-align:center;">
                                        <button class="button basic is-login save" @click="$emit('modalYes')">変更</button>
                                    </div>

                                    <div v-if="!isQuestionMode && !isDelete && !isPublish && !isSave && !isChange" name="footer" style="text-align:center;">
                                        <button class="button is-login" @click="$emit('modalOk')">OK</button>
                                    </div>

                                </slot>
                            </div><br />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        mounted: function(){
            // コンテナ高・ヘッダ高・フッタ高からBodyHeightを算出する
            var ch = jQuery("#" + this.currentContainerId).height();
            var hh = jQuery("#" + this.currentHeaderId).height();
            var fh = jQuery("#" + this.currentFooterId).height();

            if(this.realHeight > 0){
                ch = this.realHeight - 40;
                var bodyHeight = ch - hh - fh - 120;

                //console.log(ch);

                //jQuery("#" + this.currentBodyId).css('height', bodyHeight + 'px');
                jQuery("#" + this.currentBodyId).css('maxHeight', bodyHeight + 'px');
            }
        },

        computed: {
            bodyStyle: function() {
                var style = {};
                if(!this.isDelete) {
                    style["overflow-y"] = "auto";
                } else {
                    style["padding"] = "";
                }
                return style;
            },
            modalContainerStyle: function() {
                var style = {};
                style["width"] = this.realWidth + 'px';

                //style["maxHeight"] = this.realHeight + 'px';
                if(this.realHeight == 0){
                    style["maxHeight"] = "calc(100vh - 60px)";
                    style["overflow"] = "scroll";
                }else{
                    style["maxHeight"] = this.realHeight + 'px';
                }
                
                if(this.isDelete) {
                    //style["padding-top"] = 0;
                    //style["padding-bottom"] = 0;
                }
                return style;
            },

            currentContainerId: function() {
                var id = Vue.ELearning.Util.generateUuid();
                //console.log(id);
                return id;
            },
            currentHeaderId: function() {
                var id = Vue.ELearning.Util.generateUuid();
                //console.log(id);
                return id;
            },
            currentBodyId: function() {
                var id = Vue.ELearning.Util.generateUuid();
                //console.log(id);
                return id;
            },

            currentFooterId: function() {
                var id = Vue.ELearning.Util.generateUuid();
                //console.log(id);
                return id;
            },

            isQuestionMode: function () {
                return this.isQuest === "true";
            },

            realWidth: function() {
                var width = 0;
                if(this.width) {
                    //console.log("width", this.width);
                    width = Number(this.width)
                } else {
                    width = 500;
                }

                if(this.isDelete) {
                    width = 700;
                }

                //console.log(width);
                return width;
            },
            realHeight: function() {
                var width = 0;
                if(this.height) {
                    //console.log("width", this.width);
                    width = Number(this.height)
                } else {
                    width = 0;
                }

                if(this.isDelete && !this.height) {
                    width = 286.5;
                }

                //console.log(width);
                return width;
            }
        },

        props: {
            isQuest: String,
            width: String,
            height:String,
            /*
            height: {
                type:String,
                default: function() {
                    return "700";
                }
            },*/
            isDelete: {
                type:Boolean,
                default: function () {
                    return false;
                }
            },
            isPublish: {
                type:Boolean,
                default: function () {
                    return false;
                }
            },
            yesText: {
                type:String,
                default: function(){
                    return "はい";
                }
            },
            noText: {
                type:String,
                default: function(){
                    return "いいえ";
                }
            },
            isSave: {
                type:Boolean,
                default: function () {
                    return false;
                }
            },            
            isChange: {
                type:Boolean,
                default: function () {
                    return false;
                }
            },            
        }
    }
</script>
<style scoped>
@media screen and (min-width: 768px) {
    .modal-mask {
        position: fixed;
        z-index: 99998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: rgba(0, 0, 0, .5);*/
        /*background-color: #40424C;*/
        background-color: rgba(64, 66, 76, .95);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
        border:1px solid black;
    }

    .modal-container {
        width: 500px;
        max-width: calc(100vw - 60px);
        box-sizing: border-box;
        margin: 0px auto;
        padding: 30px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-container::-webkit-scrollbar {
        display: none;
    }

    .modal-header {
        font-size:32px;
        font-weight:bold;
    }

    .modal-header h3 {
        margin-top: 0;
        color: #42b983;
    }

    .modal-body {
        margin: 20px 0;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .close-cursor {
        position:relative;
        top:0;
        left:0;

        font-size:1em;

        font-weight: bold;
        cursor:pointer;

        color:gray;
    }

    .close-cursor:hover {
        color:black;
    }

    .button.save {
        min-width: 130px;
    }
}
</style>
