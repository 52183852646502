<template>
    <div>
        <div class="embed-responsive embed-responsive-16by9" style="max-width: 845px">
            <video id="video_player" controlsList="nodownload" class="embed-responsive-item" style="cursor:pointer" v-bind:src="video_src" v-video="play" v-on:play="on_video_play" controls @contextmenu.prevent muted playsinline preload="metadata"></video>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                'play':false
            };
        },

        directives: {
            video: function(el, binding){
                binding.value ? el.play() : el.pause();
            }
        },

        props: {
            /** ビデオのソースパス */
            videoSrc: '',
        },

        computed: {
            video_src: function() {

                const ua = navigator.userAgent;
                const isIOS = ua.indexOf("iPhone") >= 0 || ua.indexOf("iPad") >= 0  || navigator.userAgent.indexOf("iPod") >= 0
                if(isIOS){
                    return this.videoSrc + "#t=0.1";
                }
                return this.videoSrc;
            }
        },

        methods: {
            on_video_play: function () {
                this.$emit( "video-play" );
            }
        },
        mounted: function(){
            this.$forceUpdate()
        }
    }
</script>
