var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "column has-background-white checklist-item",
      style: _vm.itemStyle
    },
    [
      _c("div", { staticClass: "contents-inner" }, [
        _c(
          "div",
          { staticClass: "contents-body" },
          [
            _c("a", { attrs: { id: "cont-open" }, on: { click: _vm.toggle } }, [
              _c("img", {
                attrs: { src: "/images/ico-right-arrow.png", alt: "" }
              })
            ]),
            _vm._v(" "),
            _c("h2", {
              staticClass: "title-list",
              domProps: { textContent: _vm._s(_vm.itemName) }
            }),
            _vm._v(" "),
            !_vm.hideContent
              ? [
                  _c("div", { staticClass: "video" }, [
                    _vm.isVideo
                      ? _c(
                          "div",
                          { staticStyle: { width: "90%" } },
                          [
                            _c("video-component", {
                              attrs: { videoSrc: _vm.basePath + _vm.videoPath }
                            })
                          ],
                          1
                        )
                      : _vm.isPdf
                      ? _c("div", { staticStyle: { width: "80%" } }, [
                          _c("a", {
                            attrs: { href: _vm.videoSrc, target: "_blank" },
                            domProps: { textContent: _vm._s(_vm.fileName) }
                          }),
                          _c("br"),
                          _vm._v(" "),
                          _c("object", {
                            staticStyle: { height: "450px" },
                            attrs: {
                              data: _vm.videoSrc,
                              type: "application/pdf",
                              width: "80%"
                            }
                          })
                        ])
                      : _vm.isImage
                      ? _c("div", { staticStyle: { width: "90%" } }, [
                          _c("img", {
                            staticClass: "img-responsive",
                            attrs: { src: _vm.videoSrc }
                          })
                        ])
                      : _c("div", [_c("label")])
                  ]),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "category pb20",
                    staticStyle: { "white-space": "pre-wrap" },
                    domProps: { textContent: _vm._s(_vm.description) }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasNotice,
                        expression: "hasNotice"
                      }
                    ],
                    staticClass: "attention",
                    domProps: { textContent: _vm._s(_vm.notice) }
                  })
                ]
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }