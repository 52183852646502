var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": false,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "マイページ",
      "main-class": "my_page"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "user_header bg_yellow" }, [
              _c("div", { staticClass: "container pt30 pb30" }, [
                _c("div", { staticClass: "icon" }, [
                  _c("p", { style: _vm.iconImage })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.displayName))])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("section", { staticClass: "bg_yellow" }, [
              _c("div", { staticClass: "list_box" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      { attrs: { href: _vm.$libs.url(["mypage", "edit"]) } },
                      [
                        _vm._v("\n                            個人情報設定"),
                        _c("i", { staticClass: "fas fa-angle-right" })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.$store.state.userInfo.grant == 1
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$libs.url([
                                "mypage",
                                "purchased-history"
                              ])
                            }
                          },
                          [
                            _vm._v("\n                            購入履歴"),
                            _c("i", { staticClass: "fas fa-angle-right" })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.userInfo.grant == 2
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: _vm.$libs.url(["mypage", "sales"]) }
                          },
                          [
                            _vm._v("\n                            支払い管理"),
                            _c("i", { staticClass: "fas fa-angle-right" })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.userInfo.grant == 2
                    ? _c("li", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$libs.url(["mypage", "my-students"])
                            }
                          },
                          [
                            _vm._v("\n                            生徒"),
                            _c("i", { staticClass: "fas fa-angle-right" })
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "mailto:contact@misoshiru.net?subject=%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B&body=%E3%81%8A%E5%90%8D%E5%89%8D%3A%0D%0A%0D%0A%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7%3A%0D%0A%0D%0A%E3%81%8A%E5%95%8F%E3%81%84%E5%90%88%E3%82%8F%E3%81%9B%E5%86%85%E5%AE%B9%3A%0D%0A"
                        }
                      },
                      [
                        _vm._v("お問い合わせ"),
                        _c("i", { staticClass: "fas fa-angle-right" })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: _vm.$libs.url(["guide"]) } }, [
                      _vm._v("ご利用ガイド"),
                      _c("i", { staticClass: "fas fa-angle-right" })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: _vm.$libs.url(["faq"]) } }, [
                      _vm._v("よくある質問"),
                      _c("i", { staticClass: "fas fa-angle-right" })
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box" }, [
                _c("ul", [
                  _c("li", [
                    _c("a", { attrs: { href: "/terms" } }, [
                      _vm._v("利用規約"),
                      _c("i", { staticClass: "fas fa-angle-right" })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: "/privacy" } }, [
                      _vm._v("プライバシーポリシー"),
                      _c("i", { staticClass: "fas fa-angle-right" })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("a", { attrs: { href: "/company" } }, [
                      _vm._v("運営会社"),
                      _c("i", { staticClass: "fas fa-angle-right" })
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "list_box" }, [
                _c("ul", [
                  _c("li", [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showModalLogout = true
                          }
                        }
                      },
                      [
                        _vm._v("ログアウト"),
                        _c("i", { staticClass: "fas fa-angle-right" })
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "container pt20" })
            ]),
            _vm._v(" "),
            _vm.showModalLogout
              ? _c("modal-component", {
                  attrs: {
                    title: "ログアウトしますか？",
                    "view-selectable": true
                  },
                  on: {
                    yes: _vm.logout,
                    no: function($event) {
                      _vm.showModalLogout = false
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }