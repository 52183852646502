import { render, staticRenderFns } from "./CommonLayoutComponent.vue?vue&type=template&id=56754c35&scoped=true&"
import script from "./CommonLayoutComponent.vue?vue&type=script&lang=js&"
export * from "./CommonLayoutComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56754c35",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m1292/Documents/develop/laravel/misoshiru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56754c35')) {
      api.createRecord('56754c35', component.options)
    } else {
      api.reload('56754c35', component.options)
    }
    module.hot.accept("./CommonLayoutComponent.vue?vue&type=template&id=56754c35&scoped=true&", function () {
      api.rerender('56754c35', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/miso/commons/CommonLayoutComponent.vue"
export default component.exports