<template>
    <common-layout-component2 show-back="true" show-user="true" v-on:on-nav-back="back" >

        <template v-slot:nav-message>
            <p>ダウンロードしたタスク一覧</p>
        </template>

        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">ダウンロードしたタスク一覧</h2>
                </div>
                <!--　サブタイトル部 -->
                <!--表組み -->
                <div id="table-area" class="columns">
                    <div class="column">
                        <div id="subtitle-area">タスク一覧</div>
                        <button class="button basic is-red" v-bind:disabled="! deleteButtonEnabled" @click="showModalDelete = true">
                            削除&nbsp;<img src="/images/ico-trash.png" alt="" />
                        </button>
                    </div>
                    <div class="column">
                        <p class="input-search tr">
                            <button class="button basic is-gray2" @click="sortDatetime">
                                ダウンロード日順&nbsp;<arrow-up-down v-bind:order="sortOrder"></arrow-up-down>
                            </button>
                        </p>
                    </div>
                </div>

                <div class="table-wrapper">
                    <table id="table001" class="basic scrollTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th style="min-width:10em;">ダウンロード日</th>
                                <th>タスク名</th>
                                <th>カテゴリ名</th>
                                <th>タスク容量</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="task in tasks" v-bind:key="task.id">
                                <td class="check">
                                    <label>
                                        <base-check-component v-on:check-state-change="onCheckStateChange(task)" v-model="task.selected"></base-check-component>
                                    </label>
                                </td>
                                <td class="dates">{{ task.download_datetime_for_show }}</td>
                                <td class="tasks">{{ task.task_name }}</td>
                                <td class="cats">{{ task.category_name }}</td>
                                <td>{{ ( task.file_size / 1024 / 1024 ).toFixed( 2 ) }} MB</td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- SP >> -->
                    <table class="basic scrollTable sp-download-table pc-hidden">
                        <tbody>
                            <tr v-for="task in tasks" v-bind:key="task.id">

                                <td class="check">
                                    <input type="checkbox" @change="onSpCheckStateChange()" v-model="task.selected">
                                </td>
                                <td class="name">
                                    <span>{{ task.download_datetime_for_show + " " + task.category_name}}</span>
                                    <span>{{ task.task_name }}</span>
                                </td>
                                <td class="dates">{{ ( task.file_size / 1024 / 1024 ).toFixed( 2 ) }}<span>MB</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- << SP -->
                </div>

                <modal-component v-if="showModalDelete" v-bind:is-delete="true" is-quest="true" width="600" @modalYes="onClickDelete" @modalNo="showModalDelete = false">
                    <h4 slot="header"><!--タスク削除の確認--></h4>
                    <h3 slot="body" style="text-align: center;">
                        <!--<h1 clss="tc"><img src="/images/ico-q.png" alt=""></h1>
                        <p>選択されたタスクを削除しますか？</p>-->
                        <p class="tc pb2em" style="margin-top:0.5em;">
                            <strong>選択されたタスクを削除しますか？</strong>
                        </p>
                    </h3>
                </modal-component>
            </div>

        </template>

    </common-layout-component2>
</template>
<script>
    import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
    import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';

    export default {
        mounted: async function() {
            var res = Vue.ELearning.Category.getValid();

            res.then(function(res){
                var content = res.data;

                console.log(content);
                this.categories = content.content;
            }.bind(this)).catch(function(err){
                console.log(err);
            });

            this.tasks = await Vue.Download.getStoredTaksAll();
            this.tasks = _.map( this.tasks, ( task ) => { task.selected = false; return task; } );
            this.sortTasks();
        },
        data: function() {
            return {
                tasks: [],
                sortOrder: "desc",
                showModalDelete: false,
                categories: []
            };
        },
        computed: {
            deleteButtonEnabled: function ()
            {
                return _.some( this.tasks, ( task ) => task.selected );
            }
        },

        methods: {
            sortDatetime: function ()
            {
                if ( this.sortOrder == "asc" )
                {
                    this.sortOrder = "desc";
                }
                else
                {
                    this.sortOrder = "asc";
                }

                this.sortTasks();
            },

            /**
             * タスクをソートする
             */
            sortTasks: function ()
            {
                // console.log( "this.sortOrder :", this.sortOrder );

                this.tasks = _.sortBy( this.tasks, 'download_datetime' );

                if ( this.sortOrder == "desc" )
                {
                    this.tasks = _.reverse( this.tasks );
                }

                // this.tasks.splice();
            },

            onCheckStateChange: function ( task )
            {
                task.selected = ! task.selected;
                this.tasks.splice();
            },

            onSpCheckStateChange: function ()
            {
                this.tasks.splice();
            },

            /**
             * 選択されているタスクを削除する
             */
            onClickDelete: function ()
            {
                this.showModalDelete = false;

                for ( const task of this.tasks )
                {
                    if ( task.selected )
                    {
                        Vue.Download.deleteStoredTask( task.id );
                    }
                }

                Vue.Download.deleteUnusedStoredFiles();

                this.tasks = _.filter( this.tasks, ( task ) => ! task.selected );
            },

            /**
             * 戻るボタン押下時処理
             */
            back: function()
            {
                this.$router.push( { path: '/user/categories' } );
            },
        },

        components: {
            CommonLayoutComponent2,
            BaseCheckComponent,
            ModalComponent,
        },
    }
</script>
<style scoped lang="scss">

#subtitle-area{
  display: inline-block;
  margin-right: 30px;
}
</style>
