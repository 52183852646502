<template>
  <!-- 採点　-->
  <div class="mb40">
    <template v-if="myScores && myScores.length > 0">
      <template v-for="(item, index) in myScores">
        <div class="feedback box_wh" :key="index">
          <div class="box">
            <div class="user flex_nw">
              <div class="icon">
                <p :style="iconImage(item.user.image)"></p>
              </div>
              <p class="name">{{ item.user.display_name }}</p>
              <p class="date">{{ date(item.created_at) }}</p>
            </div>
            <div class="contents">
              <p>{{ item.message }}</p>
              <lesson-video-component
                v-if="item.video"
                :video="item.video.video_path"
              />
            </div>
          </div>
          <template v-for="(re_item, index) in item.replies">
            <div class="box reply" :key="index">
              <p class="relpay_icon"><i class="fas fa-reply"></i>先生より</p>
              <div class="user flex_nw">
                <div class="icon">
                  <p :style="iconImage(re_item.user.image)"></p>
                </div>
                <p class="name">{{ re_item.user.display_name }}</p>
                <p class="date">{{ date(re_item.created_at) }}</p>
              </div>
              <div class="evaluation" :class="{first:rank(re_item.score) == 1,second:rank(re_item.score) == 2,third:rank(re_item.score) == 3}">
                  <p class="score"><span>{{ re_item.score }}</span>点</p>
                  <p class="description">{{ re_item.title }}</p>
              </div>
              <div class="contents">
                <p>{{ re_item.message }}</p>
                <lesson-video-component
                  v-if="re_item.video"
                  :video="re_item.video.video_path"
                />
              </div>
            </div>
          </template>
          <!--  投稿者で返信していない場合　-->
          <template v-if="$store.state.userInfo.id == lesson.user_id">
            <div class="box reply reply_post">
              <p class="notice"><i class="fas fa-exclamation-circle"></i>採点してください<br>期限:{{ replayLimitDate(item.created_at) }}</p>
              <button
                class="button round"
                @click="$libs.pushUrl(['lesson',lesson.id,'advice',item.id,'reply' ])">
                採点する
              </button>
            </div>
          </template>
        </div>
      </template>
    </template>

    <!-- みんなの得点 -->
    <h2 class="mt30">みんなの得点</h2>
    <div class="feedback box_wh">
      <template v-if="scores && scores.length > 0">
        <template v-for="(item, index) in scores">
          <div :key="index" class="ranking_item" :class="{first:rank(item.replies[0].score) == 1,second:rank(item.replies[0].score) == 2,third:rank(item.replies[0].score) == 3}">
            <div class="box head flex_nw">
                <p v-if="index < 3" class="rank"></p>
                <p v-if="index >= 3" class="rank">{{ index + 1 }}</p>
                <div class="user flex_nw">
                    <div class="icon">
                        <p :style="iconImage(item.user.image)"></p>
                    </div>
                    <div>
                        <p class="name">{{ item.user.display_name }}</p>
                        <p class="date">{{ date(item.created_at) }}</p>
                    </div>
                </div>
                <p class="score">{{ item.bestScore }}</p>
                <p><i class="fas fa-chevron-down"></i></p>
            </div>
            <div class="inner">
            <div class="box">
              <div class="user flex_nw">
                <div class="icon">
                  <p :style="iconImage(item.user.image)"></p>
                  　
                </div>
                <p class="name">{{ item.user.display_name }}</p>
                <p class="date">{{ date(item.created_at) }}</p>
              </div>
              <div class="contents">
                <p>{{ item.message }}</p>
                <lesson-video-component
                  v-if="item.video"
                  :video="item.video.video_path"
                />
              </div>
            </div>
            <template v-for="(re_item, index) in item.replies">
              <div class="box reply" :key="index">
                <p class="relpay_icon"><i class="fas fa-reply"></i>先生より</p>
                <div class="user flex_nw">
                  <div class="icon">
                    <p :style="iconImage(re_item.user.image)"></p>
                  </div>
                  <p class="name">{{ re_item.user.display_name }}</p>
                  <p class="date">{{ date(re_item.created_at) }}</p>
                </div>
                <div class="evaluation" :class="{first:rank(re_item.score) == 1,second:rank(re_item.score) == 2,third:rank(re_item.score) == 3}">
                  <p class="score"><span>{{ re_item.score }}</span>点</p>
                  <p class="description">{{ re_item.title }}</p>
                </div>
                <div class="contents">
                  <p>{{ re_item.message }}</p>
                  <lesson-video-component
                    v-if="re_item.video"
                    :video="re_item.video.video_path"
                  />
                </div>
              </div>
            </template>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <p class="pt50 pb50 text_center">みんなの得点はまだありません</p>
      </template>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import LessonVideoComponent from '../../miso/user/LessonVideoComponent.vue';

export default {
  components: {
    LessonVideoComponent,
  },
  props: {
        lesson:Object,
        myScores: Array,
        scores: Array,
  },
  data: function () {
    return {
    };
  },
  watch: {
  },
  mounted: function () {
    jQuery(".ranking_item .head").click(function() {
        $(this).toggleClass("active").next(".inner").slideToggle(300);
    });
  },
  computed: {
  },

  methods: {

    iconImage: function (path) {
      if (path) {
        return "background: url(" + path + ") center center / cover";
      } else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },

    date: function (stringDate) {
      var now = moment();
      var date = moment(stringDate);

      if (now.diff(date, "days") > 90) {
        return date.format("YYYY年MM月DD日");
      }else {
        return date.format("MM月DD日 HH:mm");
      }
    },
    replayLimitDate: function (stringDate) {
      var now = moment();
      var date = moment(stringDate).add(7, 'd')

      return date.format("MM月DD日 HH:mm");
    },

    rank: function(score){

      console.log("score",score)
      //スコアの配列を作成
      const upper = this.scores.filter(function(item){
        return item.replies[0].score > score  
      })
      console.log("length",upper)
      return upper.length + 1

    },

  },
};
</script>
<style lang="scss" scoped>
</style>
