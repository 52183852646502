<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="レッスン情報を入力" main-class="lesson_page">
        <template v-slot:body>
            <section class="post create_intro">
                <div class="container pt20 pb30 bg_yellow">
                    <h3>レッスン紹介</h3>
                    <lesson-video-input-component :video="lesson.video" :index="1" @change-video="onChangeVideo($event)"/>
                    <div class="box">
                        <h3>タイトル</h3>
                        <input type="text" placeholder="タップして入力" v-model="lesson.title">
                        <p class="error_description" v-if="error('title')">{{ error('title') }}</p>
                    </div>

                    <div class="box">
                        <h3>レッスン内容</h3>
                        <textarea placeholder="タップして入力" v-model="lesson.description"></textarea>
                        <p class="error_description" v-if="error('decription')">{{ error('decription') }}</p>
                    </div>
                    <div class="box">
                        <h3>ゴール</h3>
                        <textarea placeholder="タップして入力" v-model="lesson.goal"></textarea>
                        <p class="error_description" v-if="error('goal')">{{ error('goal') }}</p>
                    </div>
                    <div class="box">
                        <ul>
                            <li>
                                <span>カテゴリ</span>
                                <select name="category" v-model="lesson.part">
                                    <template v-for="(item ,index) in $config.parts">
                                        <option :value="item.value" :key="index">{{ item.name }}</option>
                                    </template>
                            </select></li>
                            <li>
                                <span>ジャンル</span>
                                <select name="genre" v-model="lesson.genre">
                                    <template v-for="(item ,index) in $config.genres">
                                        <option :value="item.value" :key="index">{{ item.name }}</option>
                                    </template>
                            </select></li>

                            <li>
                                <span>難易度</span>
                                <select name="level" v-model="lesson.level">
                                    <template v-for="(item ,index) in $config.levels">
                                        <option :value="item.value" :key="index">{{ item.forSelectLabel }}</option>
                                    </template>
                            </select></li>
                        </ul>
                        <p class="error_description" v-if="error('part')">{{ error('part') }}</p>
                        <p class="error_description" v-if="error('genre')">{{ error('genre') }}</p>
                        <p class="error_description" v-if="error('level')">{{ error('level') }}</p>
                    </div>
                    <div class="box">
                        <h3>販売価格</h3>
                        <div class="price">
                            <input type="number" placeholder="タップして入力" v-model="lesson.price"><span class="bold">円</span>
                        </div>
                        <p class="error_description" v-if="error('price')">{{ error('price') }}</p>
                    </div>
                    <div class="box">
                        <h3>採点・質問（円/回）</h3>
                        <div class="price">
                            <input type="number" placeholder="タップして入力" v-model="lesson.advice_price"><span class="bold">円</span>
                        </div>
                        <p class="error_description" v-if="error('advice_price')">{{ error('advice_price') }}</p>
                    </div>
                    <template v-if="lesson.steps && lesson.steps.length > 0">
                        <div class="box">
                            <h3>ステップ</h3>
                            <div class="step_box currentStep_box bg_white">
                                <ul>
                                    <template v-for="(item, index) in lesson.steps">
                                        <li :key="index">
                                            <p class="step">Step{{ index + 1 }}</p>
                                            <p>{{ item.title }}</p>
                                        </li>
                                    </template>
                                </ul>
                                <button class="button" @click="editSteps">ステップの編集</button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="box">
                            <h3>ステップ</h3>
                            <div class="step_box bg_white">
                                <button class="button" @click="editSteps">ステップの登録</button>
                            </div>
                        </div>
                    </template>

                    <div class="buttons">
                        <button class="button brown" v-if="lesson.display_flag != 1" @click="showModalPublish = true" :disabled="!isNew && !lessonId">公開する</button>
                        <button class="button brown" v-if="lesson.display_flag == 1" @click="showModalPublish = true">更新する</button>
                        <button class="button white md" v-if="!lesson || lesson.display_flag != 1" @click="saveDraft" :disabled="!isNew && !lessonId" >下書きに保存する</button>
                        <button class="button gray md" @click="showModalPrivate = true" :disabled="!isNew && !lessonId">非公開にする</button>
                    </div>

                </div>
            </section>

            <modal-component v-if="showModalPublish" title="このレッスンを公開しますか？" :view-selectable="true" @yes="savePublish" @no="showModalPublish = false"/>
            <modal-component v-if="showModalPrivate" title="このレッスンを非公開にしますか？" :view-selectable="true" @yes="savePrivate" @no="showModalPrivate = false"/>
        </template>            
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import LessonVideoInputComponent from '../../miso/user/LessonVideoInputComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonVideoInputComponent,

        },
        props: {
            lessonId: {
                type: Number,
            },
            isNew: {
                type: Boolean,
            },            
        },
        data: function () {
            return {
                showModal:false,
                selectedVideo: null,
                showModalPublish: false,
                showModalPrivate: false,                

                lesson:{
                    user_id: this.$store.state.userInfo.id,
                    title: "",
                    goal: "",
                    description: "",
                    goal: "",
                    part: 0,
                    genre: 0,
                    level: 1,
                    price: 0,
                    advicePrice: 0,
                    dispay_flag: 0,
                    draft: 0,
                    video:null,
                },
                uploadVideoFile:null,
                uploadThumbnailBlob:null,
                errors:null,
            }
        },
        computed: {
            thumbnail:function(){
                if(this.uploadThumbnailBlob){
                    var url = (window.URL || window.webkitURL);
                    return url.createObjectURL(this.uploadThumbnailBlob);
                }else{
                    return null
                }
            }
        },
        mounted:function(){

            if(this.isNew){
                //新規登録
            }else{  
                this.getData()
            }
        },
        methods:{
            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTask(this.lessonId);

                taskHandler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            this.lesson = res.data.content;
                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },
            saveDraft: function(){
                    var params = this.postParams()
                    params.display_flag = 0
                    params.draft = 1
                    this.post(params, function(error, lesson){
                        if(error){
                            this.$dialog.message("下書き保存に失敗しました");
                        }else{
                            this.$dialog.message( "下書きに保存しました" )
                        }
                }.bind(this))
            },

            savePublish: function(){
                this.showModalPublish = false

                var params = this.postParams()
                params.display_flag = 1
                params.draft = 0

                if(!this.lesson || !this.lesson.steps || this.lesson.steps.length == 0){
                    this.$dialog.message( "ステップを登録してください" )
                    return
                }
                this.post(params, function(error, lesson){
                    if(error){
                        this.$dialog.message("エラーが発生しました");
                    }else{
                        this.$dialog.message( "レッスンを公開しました" )
                    }
                }.bind(this))
            },

            savePrivate: function(){
                this.showModalPrivate = false

                var params = this.postParams()
                params.display_flag = 0
                params.draft = 0
                this.post(params, function(error, lesson){
                    if(error){
                        this.$dialog.message("エラーが発生しました");
                    }else{
                        this.$dialog.message( "レッスンを非公開にしました" ) 
                    }                   
                }.bind(this))
            },

            editSteps: function(){
                var params = this.postParams()
                params.display_flag = 0
                params.draft = 1

                this.errors = null

                var self = this
                this.post(params, function(error, lesson){
                    if(error){
                        this.$dialog.message("レッスン情報にエラーがあります");
                    }else{
                        this.$libs.pushUrl(["lesson", this.lessonId, "step", "edit"])
                    }                        
                }.bind(this))
            },

            post: function(params, callback){

                this.errors = null

                Promise.resolve()
                .then(() => {
                    //ビデオアップロード
                    if(this.uploadVideoFile){
                        let fd = new FormData(); //★②
                        fd.append('fileData', this.uploadVideoFile);

                        if(this.uploadThumbnailBlob){
                            fd.append('thumbnail', this.uploadThumbnailBlob);
                        }
                        var config = {
                            headers: { 'Content-Type': 'multipart/formdata' }
                        };

                        return Vue.ELearning.Assets.upload(fd, config);

                    //レッスンアップロード
                    }else{
                        return null
                    }

                }).then((response) => {
                    if(response){
                        if (response.data.status_number == 200) {
                            var content = response.data.content;
                            console.log('asset upload completed:', content);
                            if (content.hasFile == 'OK') {
                                params.video_id = content.video.video_id;
                                
                                this.lesson.video = content.video
                                this.uploadVideoFile = null
                                this.uploadThumbnailBlob =null

                                //レッスンアップロード
                                if(!this.lessonId){
                                    return Vue.ELearning.Task.insertTask(params);
                                }else{
                                    return Vue.ELearning.Task.updateTask(params);
                                }
                            } else {
                                // アップロード失敗
                                this.currentFileErr = 'ファイルのアップロードに失敗しました。';
                            }
                        } else {
                            throw new Error('ファイルのアップロードに失敗しました');
                        }

                    }else{
                        if(!this.lessonId){
                            return Vue.ELearning.Task.insertTask(params);
                        }else{
                            return Vue.ELearning.Task.updateTask(params);
                        }
                    }

                }).then((response) => {

                    console.log('タスク登録', response.data.content);

                    var content = response.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        console.error(content);
                        this.errors = content.errors
                        //this.errorHandler(content);
                        //Vue.ELearning.Util.hideOverlay(this.$store);
                        callback(content, null);
                        return;
                    }

                    if(!this.lessonId){
                        this.lessonId = content.newId;
                    }else{
                    }
                    callback(null, content);

                }).catch(function(response) {
                    console.error(response);
                    callback(response, null);
                });
            },

            iconImage: function (path) {
                if (path) {
                    return "background: url(" + path + ") center center / cover";
                } else {
                    return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
                }
            },
            onChangeVideo: function(file){
                //this.lesson.video = file
                this.uploadVideoFile = file
                //console.log("video:", this.lesson.video)

                const self = this

                //videoの場合はサムネイルを作成
                this.$store.commit('overlay', true);
                this.$libs.createThumbnails(file,function(thumbnail){

                    if(thumbnail){
                        var url = (window.URL || window.webkitURL);
                        self.uploadThumbnailBlob = thumbnail;
                    }else{
                        console.log("no video or thumnail create errer")
                    }
                    self.$store.commit('overlay', false);
                })

            },
            postParams: function(){
                return this.lesson
            },

            error: function(key){

                if(this.errors){
                    if(this.errors[key]){
                        return this.errors[key].join(" ")
                    }else{
                        return null
                    }
                }
                return null
                //this.$dialog.message( "ステップを登録してください" )
            },
            onNaviBack: function(){

                if(this.lessonId){
                    this.$libs.pushUrl(['lesson',this.lessonId, "intro"])
                }else{
                    history.back()
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>
