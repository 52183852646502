var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alert-container" }, [
    _c(
      "div",
      {
        staticClass: "ico-alert2 sp-hidden",
        attrs: { id: "alert" },
        on: { click: _vm.toggle }
      },
      [
        _c("img", { attrs: { src: "/images/ico-alert2.svg", width: "22px" } }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.count > 0,
                expression: "count > 0"
              }
            ]
          },
          [_vm._v(_vm._s(_vm.count))]
        )
      ]
    ),
    _vm._v(" "),
    _vm.isOpen
      ? _c("div", { staticClass: "notifications sp-hidden" }, [
          _c("div", {
            staticClass: "notifications-wrap container",
            on: { click: _vm.toggle }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "notifications-inner" }, [
            _c("div", { staticClass: "hd" }, [
              _vm._v("\n                お知らせ\n                "),
              _c(
                "span",
                { staticClass: "close-cursor", on: { click: _vm.toggle } },
                [_c("img", { attrs: { src: "/images/icon_time_circle.png" } })]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "notifications-table-wrapper overflow-auto" },
              [
                _c(
                  "table",
                  { staticClass: "table mb-0" },
                  [
                    _vm.notifications.length > 0
                      ? _vm._l(_vm.notifications, function(row, key) {
                          return _c("tr", { key: key }, [
                            _c("td", { staticClass: "notifications-date" }, [
                              row.unread
                                ? _c("span", { staticClass: "unread" })
                                : _vm._e(),
                              _vm._v(" "),
                              !row.unread
                                ? _c("span", { staticClass: "readed" })
                                : _vm._e(),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm._f("moment")(row.create_datetime)
                                  ) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(row.name))])
                          ])
                        })
                      : [_vm._m(0)]
                  ],
                  2
                )
              ]
            )
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pc-hidden" },
      [
        _vm.notifications.length > 0
          ? _vm._l(_vm.notifications, function(row, key) {
              return _c("div", { key: key + "_02", staticClass: "notice" }, [
                row.unread ? _c("span", { staticClass: "unread" }) : _vm._e(),
                _vm._v(" "),
                !row.unread ? _c("span", { staticClass: "readed" }) : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm._f("moment")(row.create_datetime))
                  )
                ]),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(row.name))])
              ])
            })
          : [_vm._m(1)]
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [_c("td", [_vm._v("お知らせはありません。")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notice" }, [
      _c("p", [_vm._v("お知らせはありません。")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }