/**
 * なるほどーがタスクダウンロード機能
 * 
 */

import store from '../store';
import moment from 'moment';
import * as errors from '../errors';
import router from '../router';

export default {
    install(Vue, options) {

        Vue.Download = {
            http: axios.create({ baseURL: null }),

            /** ファイル ID => オブジェクト URL のキャッシュ */
            objectURLCache: {},

            persist: async function() {
                if (window.navigator.storage) {
                    const persisted = await window.navigator.storage.persist();
                    console.log("persisted :", persisted);
                }
            },

            /**
             * 画面遷移した時に行う処理
             * 
             * @param {object} route ルートオブジェクト
             */
            onPageTransition: async function(route) {
                await this.checkOnline();

                if (!this.isOnline()) {
                    // オフラインモードで非オフライン対応ページにアクセスした場合は、カテゴリ一覧画面に遷移する
                    const page_is_offline_compliant = route.matched.some(r => r.meta.isOfflineCompliant === true);

                    if (!page_is_offline_compliant) {
                        router.push({ path: '/' });
                        return;
                    }
                }

                this.checkOfflineActionInterval();

                // ディスク容量の見積を更新する
                store.dispatch("updateStorageEstimate");

                if (this.isOnline() && !route.path.startsWith("/login") && !route.path.startsWith("/admin/")) {
                    console.log("route.path :", route.path);

                    // ダウンロード済みタスクの進捗アップロードの必要性をチェックする
                    await this.checkPostProgress();

                    // ダウンロード済みタスクの更新をチェックする
                    //this.checkTaskUpdate(); 停止中 2020/11/09 
                }
            },

            /**
             * 現在オンライン状態かどうかをチェックする
             * 
             * @return {boolean} 現在オンライン状態であれば true を、オフライン状態であれば false を返す
             */
            checkOnline: async function() {
                let is_online = false;

                try {
                    const response = await Vue.ELearning.Ping.ping();

                    if (response.status == 200) {
                        is_online = true;
                    }
                } catch (e) {

                }

                console.log("is_online :", is_online);

                // store.commit( "updateIsOnline", navigator.onLine );
                store.commit("updateIsOnline", is_online);
            },

            /**
             * 現在オンライン状態かどうかを返す
             * 
             * @return {boolean} 現在オンライン状態であれば true を、オフライン状態であれば false を返す
             */
            isOnline: function() {
                // return false;
                return store.state.isOnline;
                // return navigator.onLine;
            },

            /**
             * 前回最後にユーザーが操作してからの時間をチェックし、一定時間以上空いていれば、再ログインした事にする ( オフラインの場合 )
             */
            checkOfflineActionInterval: function() {
                if (this.isOnline()) {
                    store.commit("updateLastOfflineActionDateTime", null);
                    return;
                }

                const current_action_datetime = new moment();

                if (store.state.lastOfflineActionDateTime) {
                    const last_action_datetime = new moment(store.state.lastOfflineActionDateTime);

                    console.log("last_action_datetime :", last_action_datetime.format("YYYY-MM-DD hh:mm:ss"));
                    console.log("current_action_datetime :", current_action_datetime.format("YYYY-MM-DD hh:mm:ss"));

                    if (last_action_datetime.add(process.env.MIX_OFFLINE_RE_LOGIN_INTERVAL, "s").isBefore(current_action_datetime)) {
                        this.storeLog("user_authorize", {});
                    }
                }

                store.commit("updateLastOfflineActionDateTime", current_action_datetime.format("YYYY-MM-DD hh:mm:ss"));
            },

            /**
             * ダウンロード済みタスクのオフライン時進捗をサーバーに送信する必要があるかどうかを調べ、必要があれば送信する
             * 
             */
            checkPostProgress: async function() {
                const tasks = await Vue.DB.getTasksAll();
                const offline_progressed_tasks = _.filter(tasks, { is_offline_progressed: true });

                for (const task of offline_progressed_tasks) {
                    this.postProgress(task);

                    task.is_offline_progressed = false;

                    await Vue.DB.putTask(task);
                }
            },

            /**
             * タスクのオフライン時進捗をサーバーに送信する
             * 
             * @param {object} task タスク
             */
            postProgress: async function(task) {
                let ps = _.map(task.procedure, (p) => { return { work_procedure_id: p.id, status: Number(p.status) } })

                console.log("ps :", ps);

                await Vue.ELearning.Progress.store(ps);
            },

            /**
             * タスクをダウンロードする
             * 
             * @param {number} task_id タスク ID
             */
            downloadTask: async function(task_id) {
                const response = await Vue.ELearning.Checklist.getForUser(task_id);

                if (response.data.status_number != 200) {
                    throw new Error("downloadTask() failed.");
                }

                var task = response.data.content;

                if (store.state.storageEstimate && task.file_size > (store.state.storageEstimate.quota - store.state.storageEstimate.usage)) {
                    throw new errors.TaskSizeOverError();
                }

                const stored_task = await Vue.DB.getTask(task_id);

                const file_download_promises = [];

                for (let work_procedure of task.procedure) {
                    // ローカル DB に保存されている作業項目から更新されていた場合は is_updated = true とマークする
                    if (stored_task) {
                        const stored_work_procedure = _.find(stored_task.procedure, { id: work_procedure.id });

                        // 新規に追加された作業項目も is_updated = true とマークする
                        if (stored_work_procedure === undefined) {
                            work_procedure.is_updated = true;
                        }
                        // 作業項目の「内容」が変わった事を調べる ( 作業項目のタスク内での表示順が変わっただけの時も update_datetime は変わるため、更新の検出に update_datetime は使わない )
                        else if (
                            stored_work_procedure.work_procedure_name != work_procedure.work_procedure_name ||
                            stored_work_procedure.work_procedure_text != work_procedure.work_procedure_text ||
                            stored_work_procedure.work_procedure_notice != work_procedure.work_procedure_notice ||
                            stored_work_procedure.video_id != work_procedure.video_id
                        ) {
                            work_procedure.is_updated = true;
                        }
                    }

                    if (work_procedure.video_path == null) {
                        continue;
                    }

                    // ローカルにファイルを保存済みかつ、更新日時が変わっていなければ、ダウンロードしない ( 差分ダウンロード機能 )
                    const file = await Vue.DB.getFile(work_procedure.video_id);

                    if (file) {
                        const local_file_updated = new moment(file.update_datetime);
                        const server_file_updated = new moment(work_procedure.video_update_datetime);

                        if (local_file_updated.isSame(server_file_updated)) {
                            console.log("skip download : ", work_procedure.video_id, work_procedure.video_path);
                            continue;
                        }
                    }

                    file_download_promises.push(this.downloadFile(work_procedure.video_id, work_procedure.video_base_path + work_procedure.video_path, work_procedure.video_update_datetime));
                }

                await Promise.all(file_download_promises);

                console.log(task.procedure);

                await Vue.DB.putTask(task);

                await this.deleteUnusedStoredFiles();

                return task;
            },

            /**
             * タスクがダウンロードされてローカルに保存されているかどうかを調べる
             * 
             * @param {number} task_id タスク ID
             */
            isTaskStored: async function(task_id) {
                return await Vue.DB.isTaskStored(task_id);
            },

            /**
             * ファイルをダウンロードする
             * 
             * @param {number} video_id 動画 ID
             * @param {string} file_path ファイルパス
             * @param {string} update_datetime ファイルの最終更新日時
             */
            downloadFile: async function(video_id, file_path, update_datetime) {
                const props = {
                    user_id: store.state.userInfo.userId,
                    update_datetime: update_datetime,
                    type: Vue.ELearning.Util.getMimeTypeByFileName(file_path),
                };

                const filePath = file_path + "?type=download"; //2020/02/28 AWS S3の画像ファイルがCORSで弾かれる。キャッシュのせい？ ダウンロード時はクエリをつけることにした
                const response = await this.http.get(filePath, { responseType: 'arraybuffer' });

                await Vue.DB.putFile(video_id, response.data, props); // .catch( ( e ) => { console.error( "ando" ); } )
            },

            /**
             * ローカルに保存されている全てのタスクを取得する
             */
            getStoredTaksAll: async function() {
                return await Vue.DB.getTasksAll();
            },

            /**
             * ローカルに保存されているタスクを取得する
             */
            getStoredTask: async function(task_id) {
                console.log("getStoredTask(" + task_id + ")");
                const task = await Vue.DB.getTask(task_id);

                // task.is_stored = true;

                return task;
            },

            /**
             * タスクをローカル DB またはネットワークから取得する
             * 
             * @param {number} task_id タスク ID
             */
            getTask: async function(task_id) {
                console.log("in Vue.Download.getTask(), online :", this.isOnline());

                if (this.isOnline()) {
                    console.log("getForUser(" + task_id + ") from ONLINE");
                    let response = await Vue.ELearning.Checklist.getForUser(task_id);

                    if (response.data.status_number == 200) {
                        const task = await this.formatTaskFromNetwork(response.data.content);

                        return task;
                    }

                    // throw 'Vue.ELearning.Checklist.getForUser() failed. response.data.status_number : ' + response.data.status_number;
                } else {
                    return await this.getStoredTask(task_id);
                }
            },

            /**
             * ネットワークから取得したタスク情報を整形する
             * 
             * @param {Object} task タスク情報
             */
            formatTaskFromNetwork: async function(task) {
                const stored_task = await Vue.DB.getTask(task.id);

                if (!stored_task) {
                    return task;
                }

                // DB の作業項目の更新フラグをコピーする
                for (let work_procedure of task.procedure) {
                    const stored_work_procedure = _.find(stored_task.procedure, { id: work_procedure.id });

                    if (!stored_work_procedure) {
                        continue;
                    }

                    work_procedure.is_updated = stored_work_procedure.is_updated;
                }

                return task;
            },

            /**
             * ローカルに保存されているタスクを削除する
             * 
             * @param {Number} task_id タスク ID
             */
            deleteStoredTask: async function(task_id) {
                return await Vue.DB.deleteTask(task_id);
            },

            /**
             * ロカールに保存されているファイルのうち、どのタスクからも参照されていないファイルを削除する
             */
            deleteUnusedStoredFiles: async function() {
                const file_ids = await Vue.DB.getFileKeysAll();
                const tasks = await Vue.DB.getTasksAll();

                const used_file_ids = _.uniq(tasks.map(task => task.procedure.map(procedure => procedure.video_id)).flat());
                const unused_file_ids = _.difference(file_ids, used_file_ids);

                // console.log( "file ids :", file_ids );
                // console.log( "used file ids :", used_file_ids );
                // console.log( "unused file ids :", unused_file_ids );

                for (const file_id of unused_file_ids) {
                    await Vue.DB.deleteFile(file_id);
                }
            },

            /**
             * ファイルの URL を取得する
             * 
             * @param {Object} file File オブジェクト
             * @return {string} ファイルの URL
             */
            getFileURLByFile(file) {
                console.log("getFileURLByFile() file_id : ", file.id);

                if (this.objectURLCache[file.id] !== undefined) {
                    console.log("getFileURL() returns cache for file_id : ", file.id);

                    return this.objectURLCache[file.id];
                }

                console.log("getFileURL() created blob for file_id : ", file.id);

                const blob = new Blob([file.blob], { type: file.type });
                const url = window.URL.createObjectURL(blob);

                this.objectURLCache[file.id] = url;

                return url;
            },

            /**
             * ファイルの URL を取得する
             * 
             * @param {number} file_id ファイル ID
             * @return {string} ファイルの URL
             */
            async getFileURLByFileId(file_id) {
                console.log("getFileURLByFileId() file_id : ", file_id);

                if (!file_id) {
                    return false;
                }

                const file = await Vue.DB.getFile(file_id);

                if (!file) {
                    return false;
                }

                return Vue.Download.getFileURLByFile(file);
            },

            /**
             * タスクが更新されているか調べる
             */
            async checkTaskUpdate() {
                const tasks = await Vue.DB.getTasksAll();
                const task_ids = _.map(tasks, "id");

                console.log("task_ids : ", task_ids);

                if (!task_ids) {
                    return;
                }

                const response = await Vue.ELearning.Task.getSummary(task_ids);

                if (response.data.status_number != 200) {
                    throw new Error("Vue.ELearning.Task.getSummary() failed.");
                }

                var task_sammaries = response.data.content;

                console.log("task_sammaries : ", task_sammaries);

                const deleted_task_ids = [];
                const revoked_task_ids = [];
                const updated_task_ids = [];

                for (const task of tasks) {
                    const task_summary = _.find(task_sammaries, (t) => t.id == task.id);

                    console.log()

                    if (task_summary === undefined) {
                        deleted_task_ids.push(task.id);
                        continue;
                    }

                    if (task_summary.is_deleted) {
                        deleted_task_ids.push(task.id);
                        continue;
                    }

                    if (task_summary.is_revoked) {
                        revoked_task_ids.push(task.id);
                        continue;
                    }

                    // タスクの更新をチェックする
                    const local_task_updated = new moment(task.update_datetime);
                    const server_task_updated = new moment(task_summary.update_datetime);

                    console.log("local_task_updated :", local_task_updated);
                    console.log("server_task_updated :", server_task_updated);

                    if (!local_task_updated.isSame(server_task_updated)) {
                        updated_task_ids.push(task.id);
                        continue;
                    }

                    // 作業手順が削除された事を、作業手順の数を比較して検出する
                    if (task.procedure.length != task_summary.work_procedure_count) {
                        console.log("task.procedure.length :", task.procedure.length);
                        console.log("task_summary.work_procedure_count :", task_summary.work_procedure_count);

                        updated_task_ids.push(task.id);
                        continue;
                    }

                    // 作業手順の更新をチェックする
                    const local_work_procedure_updated = task.procedure.map(p => new moment(p.update_datetime)).sort((a, b) => {
                        if (a.isSame(b)) {
                            return 0;
                        }
                        if (a.isBefore(b)) {
                            return -1;
                        }
                    }).pop();

                    const server_work_procedure_updated = new moment(task_summary.work_procedure_update_datetime);

                    console.log("local_work_procedure_updated :", local_work_procedure_updated);
                    console.log("server_work_procedure_updated :", server_work_procedure_updated);

                    if (!local_work_procedure_updated.isSame(server_work_procedure_updated)) {
                        updated_task_ids.push(task.id);
                        continue;
                    }
                }

                console.log("deleted_task_ids :", deleted_task_ids);
                console.log("revoked_task_ids :", revoked_task_ids);
                console.log("updated_task_ids :", updated_task_ids);

                if (deleted_task_ids.length > 0) {
                    for (const id of deleted_task_ids) {
                        await this.deleteStoredTask(id);
                    }

                    this.showMessage("deleted", deleted_task_ids.length + "件のタスクがサーバーで削除されたため、ダウンロード済みタスクを削除しました。");
                }

                if (revoked_task_ids.length > 0) {
                    for (const id of revoked_task_ids) {
                        await this.deleteStoredTask(id);
                    }

                    this.showMessage("grantChanged", revoked_task_ids.length + "件のタスクがサーバーで権限変更されたため、ダウンロード済みタスクを削除しました。");
                }

                if (updated_task_ids.length > 0) {
                    for (const id of updated_task_ids) {
                        const task = await this.downloadTask(id);

                        if (id == store.state.currentTask.id) {
                            store.commit('storeTask', task);
                        }
                    }

                    this.showMessage("updated", updated_task_ids.length + "件のタスクがサーバーで更新されたため、ダウンロード済みタスクを再ダウンロードしました。");
                }
            },

            showMessage(type, message) {

                var now = new Date();

                if (store.state.showedDownloadedMessage[type]) {

                    var showedDate = new Date(store.state.showedDownloadedMessage[type]);
                    var termMin = (now - showedDate) / (60 * 1000);

                    console.log("message-diff:", termMin, "type:", type);

                    if (termMin < 3) {
                        return;
                    }
                }

                store.commit('showedDownloadedMessage', { "type": type, "date": now });
                Vue.$dialog.message(message);

            },

            /**
             * 閲覧ログ・ログインログをローカルストレージに保存する
             * 
             * @param {string} type ログの種類
             * @param {ojbect} data ログの内容
             */
            async storeLog(type, data) {
                console.log("storeLog(", type, ",", data, ")");

                const now = new moment();

                data.type = type;
                data.user_id = store.state.userInfo.userId;
                data.create_datetime = now.format("YYYY-MM-DD hh:mm:ss");

                Vue.DB.putLog(data);
            },

            /**
             * ローカルストレージに保存したログをサーバーに送信する
             * 
             * @param {string} type ログの種類
             * @param {ojbect} data ログの内容
             */
            async postOfflineLog() {
                if (!this.isOnline()) {
                    return;
                }

                const db_logs = await Vue.DB.getLogsAll();

                if (db_logs.length == 0) {
                    return;
                }

                let logs = _.cloneDeep(db_logs);
                logs = _.map(logs, log => { _.unset(log, "id"); return log });
                logs = _.map(logs, log => { _.unset(log, "user_id"); return log });

                console.log("logs :", logs);

                const response = await Vue.ELearning.Logs.logOffline(logs);

                if (response.data.status_number == 200) {
                    for (const log of db_logs) {
                        Vue.DB.deleteLog(log.id);
                    }
                }
            },

            /**
             * ローカルストレージに保存されている、現在ログイン中のユーザーが既読したお知らせの ID を返す ( 非同期 )
             * 
             * @return {number|undefined}
             */
            getStoredReadNoticeId() {
                return new Promise(async(resolve, reject) => {
                    let logs = await Vue.DB.getLogsAll();
                    logs = _.filter(logs, { type: "notice_read" });

                    const notice_ids = _.map(logs, "notice_id");
                    const max_notice_id = _.max(notice_ids);

                    // console.log( "max_notice_id :", max_notice_id );

                    resolve(max_notice_id);
                });
            }
        }

        // console.log( 'baseURL :', Vue.Download.http.defaults.baseURL );
        // Vue.DB.http.defaults.baseURL = '/';
    }
}