<template>
    <common-layout-component2
        show-back="true"
        show-admin="true"
        v-on:on-nav-back="back"
    >
        <template v-slot:nav-message>
            <!--<p>タスク登録/編集</p>-->
            <span class="nav-msg-sub">{{ currentCategoryName }}</span>
            {{ currentTaskName }}
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column" v-text="title" />
                    <div class="column tr"></div>
                </div>
                <!--表組み -->
                <section class="subWindow">
                    <div class="field columns">
                        <label class="label column is-one-fifth"
                            v-bind:class="{ error: errCategoryId }"
                            >カテゴリ名</label
                        >
                        <div class="control column">
                            <!--<select class="input" >
                                <option value="">選択して下さい</option>
                            </select>-->

                            <select
                                class="input minimal"
                                v-model="selectedCategoryId"
                                @change="onCategoryChanged"
                                v-focus
                            >
                                <!--<option disabled value="">カテゴリを選択してください</option>-->
                                <option
                                    v-for="category in categories"
                                    v-bind:value="category.value"
                                    >{{ category.text }}</option
                                >
                            </select>
                        </div>
                    </div>
                    <div
                        v-show="errCategoryId"
                        class="field columns"
                        v-bind:class="{ error: errCategoryId }"
                    >
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{ error: errCategoryId }">
                                <p v-for="err in categoryErrors">{{ err }}</p>
                            </div>
                        </div>
                    </div>                    
                    <div class="field columns">
                        <label
                            class="label column is-one-fifth"
                            v-bind:class="{ error: errTaskName }"
                            >タスク名</label
                        >
                        <div class="control column">
                            <input
                                class="input"
                                v-bind:class="{ 'error-ctrl': errTaskName }"
                                type="text"
                                v-model="taskName"
                                placeholder="タスク名が入ります"
                            />
                        </div>
                    </div>
                    <div
                        v-show="errTaskName"
                        class="field columns"
                        v-bind:class="{ error: errTaskName }"
                    >
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{ error: errTaskName }">
                                <p v-for="err in taskNameErrors">{{ err }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="field columns">
                        <label
                            class="label column is-one-fifth"
                            v-bind:class="{ error: errTaskText }"
                            >説明文</label
                        >
                        <div class="control column">
                            <textarea
                                class="input"
                                rows="2"
                                v-bind:class="{ 'error-ctrl': errTaskText }"
                                name=""
                                v-model="taskDesc"
                                style="height:auto;"
                            >
ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。ダミー説明文が入ります。
                            </textarea>
                        </div>
                    </div>
                    <div
                        v-show="errTaskText"
                        class="field columns"
                        v-bind:class="{ error: errTaskText }"
                    >
                        <label class="label column is-one-fifth"></label>
                        <div class="control column">
                            <div v-bind:class="{ error: errTaskText }">
                                <p v-for="err in taskTextErrors">{{ err }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth"
                            >閲覧権限</label
                        >
                        <div class="control column">
                            <input
                                type="radio"
                                id="radio02-01"
                                class="radio02-input"
                                value="all"
                                v-model="grant"
                            />
                            <label for="radio02-01"><b>全て</b></label>

                            <input
                                type="radio"
                                id="radio02-02"
                                class="radio02-input"
                                value="perGroup"
                                v-model="grant"
                            />
                            <label for="radio02-02"><b>グループ指定</b></label>

                            <button
                                v-if="!groupSelectedCompleteEnabled || !groupSelectionEnabled"                            
                                class="button basic is-tags rs selection"
                                v-bind:disabled="!groupSelectionEnabled"
                                @click="isOpenUserByTask = !isOpenUserByTask"
                            >
                                選択して下さい
                            </button>
                            <button
                                v-if="groupSelectedCompleteEnabled && groupSelectionEnabled"
                                class="button basic is-tags rs selection selected"
                                v-bind:disabled="!groupSelectionEnabled"
                                @click="isOpenUserByTask = !isOpenUserByTask"
                            >
                                選択中
                            </button>                            
                        </div>
                    </div>

                    <div class="field columns">
                        <label class="label column is-one-fifth"
                            >公開/非公開</label
                        >
                        <div class="control column">
                            <input
                                type="radio"
                                id="radio03-01"
                                class="radio02-input"
                                value="public"
                                v-model="publicity"
                            />
                            <label for="radio03-01"><b>公開</b></label>

                            <input
                                type="radio"
                                id="radio03-02"
                                class="radio02-input"
                                value="negative"
                                v-model="publicity"
                            />
                            <label for="radio03-02"><b>非公開</b></label>
                        </div>
                    </div>

                    <div class="field columns pb2em">
                        <label class="label column is-one-fifth"
                            >タグ登録</label
                        >
                        <div class="control column">
                            <button
                                v-if="!hasSelectedTags"
                                class="button basic is-tags rs selection"
                                @click="
                                    isTagSelectorOpened = !isTagSelectorOpened
                                "
                            >
                                選択して下さい
                            </button>
                            <button
                                v-if="hasSelectedTags"
                                class="button basic is-tags rs selection selected"
                                @click="
                                    isTagSelectorOpened = !isTagSelectorOpened
                                "
                            >
                                選択中
                            </button>                            
                            <transition name="fade">
                                <tag-select
                                    v-if="isTagSelectorOpened"
                                    :tags="tags"
                                    @open-tag-modal="openTagModal"
                                />
                            </transition>
                        </div>
                    </div>

                    <div class="field">
                        <div class="control tc">
                            <div class="columns is-multiline">
                                <div class="column is-one-third"></div>
                                <div class="column is-one-third">
                                    <button
                                        class="button basic is-cancel"
                                        @click="onCancel"
                                    >
                                        キャンセル
                                    </button>
                                    <button
                                        class="button basic is-login"
                                        style="color:white;"
                                        @click="onUpdate"
                                        v-text="submitLabel"
                                    />
                                </div>
                                <div class="column is-one-third tr">
                                    <button
                                        class="button basic is-login"
                                        style="color:white;"
                                        @click="onChecklistEdit"
                                    >
                                        保存＆チェックリスト編集&nbsp;<img
                                            src="/images/ico-pen.png"
                                            alt=""
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <!-- モーダルコントロール -->
                <modal-component
                    v-if="isOpenUserByTask"
                    v-on:modalYes="isOpenUserByTask = !isOpenUserByTask"
                    v-on:modalNo="isOpenUserByTask = !isOpenUserByTask"
                    is-quest="true"
                    width="1200"
                >
                    <div slot="header">
                        <h3>グループ選択</h3>
                    </div>
                    <div slot="body">
                        <table class="basic scrollTable">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th
                                        style="cursor:pointer"
                                        @click="assignedGroupSort"
                                    >
                                        <span>グループ名&nbsp;</span>
                                        <img
                                            v-show="sortOrderGroup == 'asc'"
                                            src="/images/ico-down.png"
                                            alt=""
                                        />
                                        <img
                                            v-show="sortOrderGroup == 'desc'"
                                            src="/images/ico-up.png"
                                            alt=""
                                        />
                                    </th>
                                </tr>
                            </thead>

                            <tr v-if="groups.length == 0">
                                <td colspan="2" style="border:1px solid gray;">
                                    ユーザーに紐付け可能なグループが存在しません。
                                </td>
                            </tr>

                            <tr v-for="(group, key) in groups" :key="key">
                                <td class="check">
                                    <base-check-component
                                        v-on:check-state-change="
                                            onUserByGroupChange(group)
                                        "
                                        v-model:check-state="group.selected"
                                    ></base-check-component>
                                </td>
                                <td
                                    class="user"
                                    v-on:click="onUserByGroupChange(group)"
                                >
                                    {{ group.groupName }}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div slot="footer">
                        <div class="row">
                            <div class="col-md-12" style="text-align:center;">
                                <button
                                    class="button basic is-cancel"
                                    @click="cancelModalGroups()"
                                >
                                    キャンセル</button
                                >&nbsp;&nbsp;
                                <button
                                    class="button basic is-login"
                                    @click="
                                        userTaskDialogCompleted()
                                    "
                                    v-bind:disabled="!groupSelectedCompleteEnabled"
                                >
                                    選択完了
                                </button>
                            </div>
                        </div>
                    </div>
                </modal-component>

                <tag-select-modal
                    v-if="isOpenCreateTag"
                    @cancel="closeTagModal"
                    @created="reloadTags"
                    @toggle="toggleTagModal"
                />
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
import logic from './js/TaskEdit.js';
import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';
import MultiSelectionComponent from './components/MultiSelectionComponent.vue';

import TagSelect from './components/TagSelect.vue';
import TagSelectModal from './components/TagSelectModal.vue';

logic.components = {
    CommonLayoutComponent2,
    BaseCheckComponent,
    ModalComponent,
    MultiSelectionComponent,
    TagSelect,
    TagSelectModal,
};

export default logic;
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.error {
    color: rgba(255, 0, 0, 0.6) !important;
}
.error-ctrl {
    background-color: rgba(255, 0, 0, 0.16) !important;
}
</style>
