<template>
  <common-layout-component2 show-back="true" show-admin="true" @on-nav-back="$router.push( { name: 'log-index' } )">
    <template v-slot:nav-message>
      <p>閲覧ログ - タスク更新履歴</p>
    </template>

    <template v-slot:body>
      <div class="columns is-gapless logs">
        <log-side-bar />

        <div class="column main-column">
          <div class="main-column-inner">
            

            <log-period-input :period_begin.sync="period_begin" :period_end.sync="period_end" v-on:period-updated="on_period_updated">
              <div class="download-button"><a href="#" @click.prevent.stop="download_task_updates_csv">CSVダウンロード</a></div>
            </log-period-input>

            <div class="row board-container board-container01">
              <div class="col-12">
                <div class="board">
                  <div class="show-more-table">
                    <div class="table-responsive">
                      <sortable-table ref="table" :headers="headers" :list.sync="task_updates" :limit="6" :is_more="is_task_updates_more" :sort_by.sync="sort_by" :is_sort_asc.sync="is_sort_asc"></sortable-table>
                    </div><!-- /.table-responsive -->
                    <more-button v-model="is_task_updates_more" :list="task_updates" :limit="6" color="color01"></more-button>
                  </div><!-- /.show-more-table -->
                </div><!-- /.board -->
              </div>
            </div><!-- /.board-container01 -->
          </div><!-- /.main-column -->
        </div><!-- /.column -->
      </div>
    </template>
  </common-layout-component2>
</template>
<script>

import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';
import LogSideBar from './components/LogSideBar';
import LogPeriodInput from './components/LogPeriodInput';
import SortableTable from './components/SortableTable';
import MoreButton from './components/MoreButton';

import moment from 'moment';

export default {
  components: {
      CommonLayoutComponent2,
      LogSideBar,
      LogPeriodInput,
      SortableTable,
      MoreButton,
  },
  
  data: () => { return {
    period_begin: "",                   // 期間 ( 開始 )
    period_end: "",                     // 期間 ( 終了 )
    
    task_updates: [],                   // タスク更新履歴の一覧
    headers: [
      { name: "category_name", title: "カテゴリ", },
      { name: "task_name", title: "タスク", },
      { name: "work_procedure_name", title: "作業手順", },
      { name: "user_name", title: "更新者", sortName: "user_name_kana"},
      { name: "date", title: "更新日時", filter: ( value ) => moment(value).format("YYYY/MM/DD HH:mm") }
    ],

    sort_by: "date",                    // ソート
    is_sort_asc: true,
    
    // More
    is_task_updates_more: false,
  } },

  computed: {
    // API の共通パラメータ
    api_params: function () { return { begin: this.period_begin, end: this.period_end }; }
  },

  watch: {
    '$route' (to, from) {
      this.update_list();
    }
  },

  mounted () {
    Vue.ELearning.Logs.init();
  },

  methods: {
    /**
     * 期間が変更された時の処理
     */
    on_period_updated: function ( begin, end ) {
      this.update_list();
    },

    /** リストを更新する */
    update_list: function () {
      // 
      Vue.ELearning.Logs.getApiData( Vue.ELearning.Logs.getTaskUpdates( this.api_params ), async function ( data ) {
        this.task_updates = data;

        // console.log( "task_updates a :", this.task_updates );

        this.sort_by = "date";
        this.is_sort_asc = true;

        await Vue.nextTick();

        if(this.$refs.table){
          this.$refs.table.sort_list();
        }

        // console.log( "task_updates b :", this.task_updates );
      }.bind( this ) );
    },

    /** タスク更新履歴の一覧 CSV ダウンロード */
    download_task_updates_csv: function () {
      Vue.ELearning.Logs.downloadCsv( Vue.ELearning.Logs.getTaskUpdatesCsv( this.api_params ), "task_updates.csv" );
    }
  },
};

</script>