import { render, staticRenderFns } from "./ExclamationComponent.vue?vue&type=template&id=9208c17c&"
import script from "./ExclamationComponent.vue?vue&type=script&lang=js&"
export * from "./ExclamationComponent.vue?vue&type=script&lang=js&"
import style0 from "./ExclamationComponent.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/m1292/Documents/develop/laravel/misoshiru/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9208c17c')) {
      api.createRecord('9208c17c', component.options)
    } else {
      api.reload('9208c17c', component.options)
    }
    module.hot.accept("./ExclamationComponent.vue?vue&type=template&id=9208c17c&", function () {
      api.rerender('9208c17c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/commons/ExclamationComponent.vue"
export default component.exports