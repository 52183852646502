var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("タスク登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c(
                      "h2",
                      { staticClass: "column", attrs: { id: "title" } },
                      [_vm._v("タスク編集")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column tr server-volume-area" }, [
                      _c("div", { staticClass: "server-volume" }, [
                        _c("div", { staticClass: "volume-text" }, [
                          _vm._v("サーバー容量")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "volume-size" }, [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.currentFileSizeSum) +
                                " / " +
                                _vm._s(_vm.maxDiskSize())
                            ),
                            _c("span", [_vm._v("GB")])
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("使用量 / 契約容量")])
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { attrs: { id: "subtitle-area" } }, [
                  _vm._v("タスク一覧")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "table-area" } },
                  [
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          on: { click: _vm.onClickNew }
                        },
                        [
                          _vm._v("\n                        新規登録 "),
                          _c("img", {
                            attrs: { src: "/images/ico-plus.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          attrs: { disabled: _vm.editButtonEnabled },
                          on: { click: _vm.onClickEdit }
                        },
                        [
                          _vm._v("\n                        編集 "),
                          _c("img", {
                            attrs: { src: "/images/ico-pen.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-gray",
                          attrs: { disabled: _vm.replicateButtonEnabled },
                          on: { click: _vm.onClickReplicate }
                        },
                        [
                          _vm._v("\n                        複製 "),
                          _c("img", {
                            attrs: { src: "/images/ico-copy.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-red",
                          attrs: { disabled: _vm.deleteButtonEnabled },
                          on: {
                            click: function($event) {
                              _vm.showModalDelete = true
                            }
                          }
                        },
                        [
                          _vm._v("\n                        削除 "),
                          _c("img", {
                            attrs: { src: "/images/ico-trash.png", alt: "" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column" }, [
                      _c("p", { staticClass: "input-search tr" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button basic is-gray2",
                            on: { click: _vm.sortUpdateDatetime }
                          },
                          [
                            _vm._v("\n                            更新日順 "),
                            _vm.sortAxis == "update_datetime"
                              ? _c("arrow-up-down-alt", {
                                  attrs: { order: _vm.sortOrder }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button basic is-gray2",
                            on: { click: _vm.sortDictionary }
                          },
                          [
                            _vm._v("\n                            名前順 "),
                            _vm.sortAxis == "dictionary"
                              ? _c("arrow-up-down-alt", {
                                  attrs: { order: _vm.sortOrder }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "button basic is-gray2 rs",
                            on: { click: _vm.onAllTagOpen }
                          },
                          [
                            _vm._v(
                              "\n                            タグ検索  " +
                                _vm._s(_vm.allTagIndicator) +
                                "\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row all-tag-container" }, [
                        _c("div", { staticClass: "col-md-1" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-10" }, [
                          _vm.allTagOpen
                            ? _c("div", { staticClass: "all-tag-indicator" }, [
                                _vm._v(
                                  "\n                                ▲\n                            "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.allTagOpen
                            ? _c(
                                "div",
                                {
                                  staticClass: "all-tag-area",
                                  staticStyle: {
                                    "padding-top": "5px",
                                    "padding-bottom": "5px"
                                  }
                                },
                                [
                                  _c(
                                    "ul",
                                    [
                                      _vm._l(_vm.allTag, function(tag) {
                                        return [
                                          _c(
                                            "li",
                                            {
                                              key: tag.tagId,
                                              staticClass:
                                                "button basic is-tags",
                                              class: {
                                                "tag-selected": tag.selected
                                              },
                                              staticStyle: {
                                                "margin-left": "5px",
                                                "margin-right": "5px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onTagSelect(tag)
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    tag.tagName
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      }),
                                      _vm._v(" "),
                                      _c("li", {
                                        staticStyle: { display: "inline-block" }
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-wrapper" }, [
                  _c(
                    "table",
                    {
                      staticClass: "basic scrollTable",
                      attrs: { id: "table001" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c("th", { staticStyle: { "min-width": "10em" } }, [
                            _vm._v("作成/更新日")
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v("タスク名")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("カテゴリ名")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("閲覧権限")]),
                          _vm._v(" "),
                          _c("th", { staticStyle: { "min-width": "8em" } }, [
                            _vm._v("公開/非公開")
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v("タグ")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.taskInfo.length == 0,
                              expression: "taskInfo.length == 0"
                            }
                          ]
                        },
                        [
                          _c("td", { attrs: { colspan: "7" } }, [
                            _vm._v("タスクが存在しません。")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.taskInfo, function(task, index) {
                        return [
                          _c("tr", { key: index }, [
                            _c("td", { staticClass: "check" }, [
                              _c(
                                "label",
                                [
                                  _c("base-check-component", {
                                    on: {
                                      "check-state-change": function($event) {
                                        return _vm.onCheckStateChange(task)
                                      }
                                    },
                                    model: {
                                      value: task.selected,
                                      callback: function($$v) {
                                        _vm.$set(task, "selected", $$v)
                                      },
                                      expression: "task.selected"
                                    }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "dates" }, [
                              _c("span", { staticClass: "green" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/ico-date.png",
                                    alt: ""
                                  }
                                }),
                                _vm._v("作成日")
                              ]),
                              _vm._v(" " + _vm._s(task.postDate)),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "green" }, [
                                _c("img", {
                                  attrs: {
                                    src: "/images/ico-date2.png",
                                    alt: ""
                                  }
                                }),
                                _vm._v("更新日")
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(task.updateDate) +
                                  "\n                            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "tasks" }, [
                              _vm._v(_vm._s(task.taskName))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "cats" }, [
                              _vm._v(_vm._s(task.categoryName))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "edit" }, [
                              task.references == 2
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-gray2 rs",
                                      on: {
                                        click: function($event) {
                                          return _vm.onOpenGroupByTask(task)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    グループリスト\n                                    "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: "/images/ico-add-gray.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              task.references == 1
                                ? _c("span", [_vm._v("全てのユーザー")])
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "open" }, [
                              _c("span", [_vm._v(_vm._s(task.publicity))]),
                              _vm._v(" "),
                              task.category_del_flag == 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("(カテゴリ削除済み)")]
                                  )
                                : task.publicity_category == 0
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "gray" } },
                                    [_vm._v("(カテゴリ非公開)")]
                                  )
                                : _vm._e()
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "group" }, [
                              task.tags.length > 0 && !_vm.taskTagOpened(task)
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button basic is-gray2 rs pl0",
                                      on: {
                                        click: function($event) {
                                          return _vm.tagAreaChange(task)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    タグ一覧\n                                    "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: "/images/ico-plus3.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              task.tags.length > 0 && _vm.taskTagOpened(task)
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "button basic is-gray2 rs pl0",
                                      on: {
                                        click: function($event) {
                                          return _vm.tagAreaChange(task)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    タグ一覧\n                                    "
                                      ),
                                      _c("img", {
                                        attrs: {
                                          src: "/images/ico-minus3.png",
                                          alt: ""
                                        }
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _vm.taskTagOpened(task)
                            ? _c("tr", { key: index + "-2" }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "table-tags task-tags has-tags",
                                    attrs: { colspan: "7" }
                                  },
                                  [
                                    _vm._l(task.tags, function(tag) {
                                      return [
                                        _c("span", {
                                          key: tag.tagId,
                                          staticClass: "button basic is-tags",
                                          domProps: {
                                            textContent: _vm._s(tag.tagName)
                                          }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _vm.isOpenUserByTask
                  ? _c(
                      "modal-component",
                      {
                        attrs: { "is-quest": "true", width: "1200" },
                        on: {
                          modalYes: function($event) {
                            _vm.isOpenUserByTask = !_vm.isOpenUserByTask
                          },
                          modalNo: function($event) {
                            _vm.isOpenUserByTask = !_vm.isOpenUserByTask
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_c("h3", [_vm._v("グループリスト")])]
                        ),
                        _vm._v(" "),
                        _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                          _c(
                            "table",
                            { staticClass: "basic scrollTable" },
                            [
                              _c("thead", [
                                _c("tr", [_c("th", [_vm._v("グループ名")])])
                              ]),
                              _vm._v(" "),
                              _vm.groups.length == 0
                                ? _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          border: "1px solid gray"
                                        },
                                        attrs: { colspan: "2" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                ユーザーに紐付け可能なグループが存在しません。\n                            "
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.groups, function(name, key) {
                                return _c("tr", { key: key }, [
                                  _c("td", { staticClass: "user" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(name) +
                                        "\n                            "
                                    )
                                  ])
                                ])
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { slot: "footer" }, slot: "footer" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12",
                                  staticStyle: { "text-align": "center" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "button basic is-cancel",
                                      on: {
                                        click: function($event) {
                                          _vm.isOpenUserByTask = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                閉じる"
                                      )
                                    ]
                                  ),
                                  _vm._v("  \n                        ")
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                false
                  ? _c(
                      "div",
                      {
                        staticClass: "remodal",
                        attrs: {
                          "data-remodal-id": "modal2",
                          "data-remodal-options": "hashTracking:false"
                        }
                      },
                      [
                        _c("button", {
                          staticClass: "remodal-close",
                          attrs: { "data-remodal-action": "close" }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "columns",
                            attrs: { id: "header-area-user" }
                          },
                          [
                            _c("h2", { attrs: { id: "title-user" } }, [
                              _vm._v("ユーザー選択")
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "table-wrapper pb2em" }, [
                          _c("table", { staticClass: "basic scrollTable" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th"),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    "\n                                    ユーザー名 "
                                  ),
                                  _c("img", {
                                    attrs: { src: "img/ico-down.png", alt: "" }
                                  })
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(
                                    "\n                                    部署名 "
                                  ),
                                  _c("img", {
                                    attrs: { src: "img/ico-down.png", alt: "" }
                                  })
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "check" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "checkbox01-input",
                                    attrs: {
                                      type: "checkbox",
                                      checked: "checked",
                                      name: "checkbox01[]"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "checkbox01-parts"
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "user" }, [
                                _vm._v("ユーザー名が入ります")
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "area" }, [
                                _vm._v("部署名が入ります")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("td", { staticClass: "check" }, [
                                _c("label", [
                                  _c("input", {
                                    staticClass: "checkbox01-input",
                                    attrs: {
                                      type: "checkbox",
                                      name: "checkbox01[]"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    staticClass: "checkbox01-parts"
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "user" }, [
                                _vm._v("ユーザー名が入ります")
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "area" }, [
                                _vm._v("部署名が入ります")
                              ])
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "tc" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button basic is-cancel",
                              attrs: { "data-remodal-action": "cancel" },
                              on: {
                                click: function($event) {
                                  _vm.isOpenUserByTask = false
                                }
                              }
                            },
                            [_vm._v("キャンセル")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "button basic is-login",
                              attrs: { "data-remodal-action": "confirm" },
                              on: {
                                click: function($event) {
                                  _vm.isOpenUserByTask = false
                                }
                              }
                            },
                            [_vm._v("選択完了")]
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showModalDelete
                  ? _c(
                      "modal-component",
                      {
                        attrs: {
                          "is-delete": true,
                          "is-quest": "true",
                          width: "600"
                        },
                        on: {
                          modalYes: _vm.onClickDelete,
                          modalNo: function($event) {
                            _vm.showModalDelete = false
                          }
                        }
                      },
                      [
                        _c("h4", { attrs: { slot: "header" }, slot: "header" }),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { slot: "body" },
                            slot: "body"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "tc pb2em",
                                staticStyle: { "margin-top": "0.5em" }
                              },
                              [
                                _c("strong", [
                                  _vm._v("選択されたタスクを削除しますか？")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }