var logic = {};

logic.created = function() {
    console.log('Initialize Admin/Groups');
};

logic.mounted = function() {
    this.refleshData();
};

logic.data = function() {
    return {
        showModalDelete: false,
        isOpenGroupByTask: false,
        order: 'asc',
        axis: 'group_name',
        groups: [],
        keyword: '',
        currentGroupTasks: [
            /*
                            {
                                categoryId:1,
                                categoryName:'カテゴリ０１',

                                isOpen:false,

                                tasks:[
                                    {
                                        taskId:1,
                                        taskName:'タスク００１',
                                        selected:true,
                                    },
                                    {
                                        taskId:2,
                                        taskName:'タスク００２',
                                        selected:true,
                                    },
                                    {
                                        taskId:3,
                                        taskName:'タスク００３',
                                        selected:false,
                                    },
                                ]
                            },
            */
        ],
    };
};

logic.methods = {
    refleshData: function() {
        Vue.ELearning.Util.showOverlay(this.$store);

        var handler = Vue.ELearning.Group.search(
            this.keyword,
            this.axis,
            this.order,
        );

        handler
            .then(response => {
                if (response.data.status_number == 200) {
                    this.groups = [];
                    var groups = response.data.content;
                    for (var key in groups) {
                        var group = groups[key];

                        this.groups.push({
                            groupId: group['group_id'],
                            groupName: group['group_name'],
                            groupText: group['group_text'],

                            selected: false,
                            enabled: true,

                            tasks: [],
                        });
                    }

                    Vue.ELearning.Util.hideOverlay(this.$store);
                } else {
                    throw new Error('グループ情報の取得に失敗しました');
                }
            })
            .catch(response => {
                console.error(response);
                Vue.ELearning.Util.hideOverlay(this.$store);
            });
    },

    gotoEdit: function(mode) {
        var groupId = 0;

        if (mode == 'new') {
            this.$router.push({
                path: '/admin/group/0',
            });
            return;
        }

        for (var key in this.groups) {
            var item = this.groups[key];

            if (item.selected) {
                groupId = item.groupId;
            }
        }

        this.$router.push({
            path: '/admin/group/' + groupId,
        });
    },

    back: function() {
        this.$router.push({
            path: '/admin/menu/',
        });
    },

    onSort: function(axis) {
        if (this.order == 'asc') {
            this.order = 'desc';
        } else {
            this.order = 'asc';
        }

        this.refleshData();
    },

    onSearch: function(keyword) {
        this.keyword = keyword /*.searchText*/ ;

        this.refleshData();
        //Vue.ELearning.Group.search();
    },

    execDelete: function() {
        console.log('グループ削除');

        Vue.ELearning.Util.showOverlay(this.$store);

        var groups = [];
        for (var key in this.groups) {
            var item = this.groups[key];
            if (item.selected) {
                var h = Vue.ELearning.Group.deleteGroup(item.groupId);
                groups.push(h);
            }
        }

        Promise.all(groups)
            .then(res => {
                //if(res.data.status_number == 200) {
                console.log(res);
                Vue.ELearning.Util.hideOverlay(this.$store);

                this.showModalDelete = false;

                this.refleshData();
                //} else {
                //    throw new Error("グループ情報の削除に失敗しました");
                //}
            })
            .catch(res => {
                console.error(res);

                Vue.ELearning.Util.hideOverlay(this.$store);

                this.showModalDelete = false;

                this.refleshData();
            });
    },

    importGrp: function() {
        console.log("********** import Group");
        this.$el.querySelector('#input-file-csv').click();
    },

    selectedUploadCsv(e) {
        const files = e.target.files;
        const csv = files[0];

        let formData = new FormData();
        formData.append('csv', csv);

        const handler = Vue.ELearning.Group.postUserCsv(formData)

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then((res) => {
                const content = res.data.content;
                if (content.result == "NG") {
                    //エラー
                    const key = Object.keys(content.errors)[0];
                    var message = "インポートに失敗しました";
                    message = message + "。" + content.errors[key].join(" ");
                    this.$dialog.message(message);
                }

                Vue.ELearning.Util.hideOverlay(this.$store)
                this.refleshData()

                e.target.value = "";
            })
            .catch(err => {
                console.log("error", err);
                this.$dialog.message('インポートに失敗しました');
                //alert('インポートに失敗しました');
                Vue.ELearning.Util.hideOverlay(this.$store);

                e.target.value = "";
            })
    },

    exportGrp: function() {
        console.log("********** export Group");
        var handler = Vue.ELearning.Group.getGroupCsv();
        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(response => {
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'grouplst.csv');
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                Vue.ELearning.Util.hideOverlay(this.$store)
            })
            .catch(response => {
                this.$dialog.message('エクスポートに失敗しました');
                //alert('エクスポートに失敗しました');
                Vue.ELearning.Util.hideOverlay(this.$store)
            });
    },


    onOpenGroupByTask(group) {
        var groupId = group.groupId;

        var handler = Vue.ELearning.Group.getAllTasksByGroup(groupId);

        handler
            .then(response => {
                if (response.data.status_number == 200) {
                    var categories = response.data.content;

                    this.currentGroupTasks = [];

                    for (var key in categories) {
                        var item = categories[key];

                        this.currentGroupTasks.push({
                            categoryId: item['categoryId'],
                            categoryName: item['categoryName'],
                            isOpen: false,
                            tasks: item['tasks'],
                        });
                    }

                    console.log(this.currentGroupTasks);

                    this.isOpenGroupByTask = true;
                } else {
                    throw new Error('関連タスク情報を取得できませんでした');
                }
            })
            .catch(response => {
                console.error(response);
            });
    },

    onCheckStateChange(group) {
        group.selected = !group.selected;
    },

    clearConditions() {
        this.keyword = '';

        this.refleshData();
    },
};

logic.computed = {
    editButtonEnabled() {
        var selectCount = 0;

        for (var item in this.groups) {
            if (this.groups[item].selected && this.groups[item].enabled) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if (selectCount == 0) {
            return true;
        }
        if (selectCount == 1) {
            return false;
        }
        return true;
    },

    deleteButtonEnabled() {
        var selectCount = 0;

        for (var item in this.groups) {
            if (this.groups[item].selected && this.groups[item].enabled) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if (selectCount == 0) {
            return true;
        }
        if (selectCount >= 1) {
            return false;
        }
    },
};

export default logic;