<template>
    <nav
        class="navbar"
        style="margin-right:0;padding-right:0;position:relative;right:-20px;z-index:10000;"
    >
        <div
            class="navbar-end"
            style=""
        >
            <div
                class="navbar-item"
                style="margin-right:0;padding-right:0;"
            >
                <router-link
                    v-show="isShowUser"
                    id="touser"
                    to="/user/categories"
                    class="button is-orange br100 margin"
                >
                    <strong>ユーザー画面トップへ</strong>
                </router-link>
                <router-link
                    v-show="isShowAdmin"
                    id="totop"
                    to="/admin/menu"
                    class="button is-blue br100 margin"
                >
                    <strong>管理画面トップへ</strong>
                </router-link>
                <a
                    v-show="isShowLogout"
                    id="logout"
                    @click="doLogout"
                    class="button is-blue br100 margin"
                >
                    <strong>ログアウト</strong>
                </a>
                <a
                    id="alert"
                    v-show="isShowNotice"
                    class="ico-alert2"
                >
                    <img
                        src="/images/ico-alert2.png"
                        alt=""
                    >
                    <span>{{noticeCount}}</span>
                </a>
            </div>
        </div>
    </nav>
</template>
<script>
export default {
    computed: {
        currentGrant: function() {
            //console.log("grant", this.$store.state.userInfo);
            return this.$store.state.userInfo.grant;
        },
        isShowAdmin: function() {
            //console.log("showAdmin", this.showAdmin);
            if (this.showAdmin == undefined) {
                return true;
            } else {
                if (this.showAdmin == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowUser: function() {
            if (this.showUser == undefined) {
                return true;
            } else {
                if (this.showUser == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowLogout: function() {
            if (this.showLogout == undefined) {
                return true;
            } else {
                if (this.showLogout == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowNotice: function() {
            if (this.showNotice == undefined) {
                return true;
            } else {
                if (this.showNotice == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },

    methods: {
        doLogout: function() {
            // ログアウト処理

            this.$router.push({ path: '/login' });
        },
    },

    props: {
        showAdmin: String,
        showUser: String,
        showLogout: String,
        showNotice: String,
    },
};
</script>
