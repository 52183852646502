<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="振り込み履歴" main-class="my_page sales">
        <template v-slot:body>
            <section class="bg_yellow">
                <div class="container pt20">
                     <template v-if="payments && payments.length > 0">
                     </template>
                    <div class="proceeds_history">
                        <template v-for="item,index in payments">
                        <a :href="$libs.url(['mypage','sales',item.id])" :key="index">
                            <div class="transfar">
                                <div class="head flex_nw">
                                    <p>申請日：{{ applyDate(item.created_at) }}</p>
                                    <p class="prceed">{{ Number(item.price_sum).toLocaleString() }}円</p>
                                </div>
                                <div class="flex_nw">
                                    <p>{{ payedDate(item) }}</p>
                                    <p class="status">{{ status(item) }}</p>
                                </div>
                            </div>
                        </a>
                        </template>
                    </div>
                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import moment from 'moment';
export default {
    components: {
    CommonLayoutComponent,
    },
  data: function () {
    return {
      total:0,
      payments: null,
    };
  },
  created: function () {},

  mounted: function () {
      this.refleshData();
  },
  computed: {
  },
  methods: {
    refleshData: function() {

            // ユーザー情報取得
            var handler = Vue.ELearning.Payment.myPayments();

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    this.payments = response.data.content.result;
                } else {
                    throw new Error("振り込み情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },
    status: function(item){
        return (item.payed_datetime)? "振り込み済み" : "受付済み"
    },
    applyDate: function(strDate){
        const date = moment(strDate)
        return date.format("YYYY/MM/DD HH:mm")
    },    
    payedDate: function(item){
        const strDate = (item.payed_datetime)? item.payed_datetime : item.created_at
        const date = moment(strDate)
        return date.format("YYYY/MM/DD")
    },    
    onNaviBack: function() {
        this.$libs.pushUrl(["mypage","sales"])
    },
  },
}

</script>
<style lang="scss" scoped>
</style>
