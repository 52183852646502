var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column sidebar" }, [
    _c("nav", { staticClass: "side-navigation" }, [
      _vm.is_admin
        ? _c("ul", { staticClass: "menu" }, [
            _c(
              "li",
              { staticClass: "dashboard" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: "/admin/logs", "exact-active-class": "active" }
                  },
                  [_vm._v("ダッシュボード")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "user" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: "/admin/logs/users", "active-class": "active" }
                  },
                  [_vm._v("ユーザー")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "contents" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/admin/logs/contents",
                      "active-class": "active"
                    }
                  },
                  [_vm._v("コンテンツ")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "task" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/admin/logs/task_updates",
                      "active-class": "active"
                    }
                  },
                  [_vm._v("タスク更新履歴")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "user-work" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/admin/logs/work_procedures",
                      "active-class": "active"
                    }
                  },
                  [_vm._v("ユーザー作業履歴")]
                ),
                _vm._v(" "),
                _vm.isShowUserworkUserlist && _vm.userworkUsers.length > 0
                  ? _c("div", { staticClass: "user-work-list" }, [
                      _c("div", { staticClass: "user-work-list-header" }, [
                        _c("p", [_vm._v("ユーザー名")]),
                        _vm._v(" "),
                        _c("a", {
                          class: { asc: _vm.userworkUsersSortAsc },
                          on: {
                            click: function($event) {
                              return _vm.$emit("sortUsers")
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(_vm.userworkUsers, function(user, index) {
                          return _c(
                            "li",
                            { key: index },
                            [
                              _c(
                                "router-link",
                                {
                                  class: _vm.userNameClass(user.user_name),
                                  attrs: {
                                    to: {
                                      name: "log-work-procedures-user",
                                      params: { user_id: user.user_id }
                                    },
                                    "active-class": "active"
                                  }
                                },
                                [_vm._v(_vm._s(user.user_name))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.is_admin
        ? _c("ul", { staticClass: "menu" }, [
            _c(
              "li",
              { staticClass: "user" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "log-user",
                        params: { user_id: _vm.user_id }
                      },
                      "active-class": "active"
                    }
                  },
                  [_vm._v("ユーザー")]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "user-work" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "log-work-procedures-user",
                        params: { user_id: _vm.user_id }
                      },
                      "active-class": "active"
                    }
                  },
                  [_vm._v("ユーザー作業履歴")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }