var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [
            _c("div", { staticClass: "col-md-1" }, [
              _c("p", [_vm._v("チェックリストプレビュー")])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "keep-alive",
              [
                _c("check-list", {
                  attrs: {
                    procedures: _vm.procedures,
                    target: _vm.target,
                    "content-height": _vm.contentHeight
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.showConfirmModalLeave
              ? _c("confirm-modal", {
                  attrs: {
                    "continue-func": _vm.leave,
                    "close-func": _vm.closeConfirmModalLeave,
                    "yes-text": "このページを離れる",
                    "no-text": "キャンセル",
                    title: "確認",
                    message:
                      "このページを離れますか？行った変更が保存されない可能性があります。保存する場合は編集画面に戻ってください。"
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }