<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <slot name="control"></slot>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="table">
                    <thead>
                        <slot name="header"></slot>
                    </thead>
                    <tbody>
                        <slot name="data"></slot>
                    </tbody>
                    <tfoot>
                        <slot name="footer"></slot>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        
    }
</script>