<template>
    <modal-component
        @modalYes="toggle"
        @modalNo="toggle"
        is-quest="true"
        width="600"
    >
        <div slot="header">
            <h3>タグ作成</h3>
        </div>
        <div slot="body">
            <div class="row form-group">
                <div class="col-md-2"></div>
                <div class="col-md-2">
                    <label class="form-check-label">タグ名</label>
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" v-model="name" />
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-md-12 button-wrap">
                    <button class="button basic is-cancel" @click="cancel">
                        キャンセル</button
                    >&nbsp;&nbsp;
                    <button class="button basic is-login" @click="create">
                        作成
                    </button>
                </div>
            </div>
        </div>
    </modal-component>
</template>

<script>
import ModalComponent from '../../../commons/ModalComponent.vue';

export default {
    components: {
        ModalComponent,
    },
    data() {
        return {
            name: '',
        };
    },
    methods: {
        toggle() {
            this.$emit('toggle');
        },
        cancel() {
            this.$emit('cancel');
        },
        create() {
            return Vue.ELearning.Tag.insertTag({
                tag_name: this.name,
            })
                .then(res => {
                    if (
                        typeof res.data.content.errors !== 'undefined' &&
                        res.data.content.errors != ''
                    ) {
                        this.$dialog.message('タグの作成に失敗しました。');
                        console.log("タグ作成エラー:", res.data.content.errors);
                    } else {
                        this.$emit('created');
                    }
                })
                .catch(() => this.$dialog.message('タグの作成に失敗しました。'));
        },
    },
};
</script>

<style lang="scss" scoped>
.button-wrap {
    text-align: center;
}
</style>
