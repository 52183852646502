import * as errors from '../../../../errors';

export default {
    data() {
        return {
            currentComponent: 'normal-checklist',

            contentHeight: 0,
            sidebarWidth: 0,

            showModalCompleted: false,
            showModalSkip: false,
            showModalTaskCompleted: false,

            showModalDownload: false,
            taskDownloaded: false,

            leftSize: 8,
            leftTweenedSize: 8,

            rightSize: 4,
            rightTweenedSize: 4,

            show: true,

            checkMode: 'incomplete',

            offCanvasState: false,

            /** 動画再生ログ記録済みフラグ */
            isVideoPlayLogged: false,

            isSpDetailAllOpened: false
        };
    },

    async mounted() {
        this.layoutManager();

        Vue.ELearning.Util.showOverlay(this.$store);

        this.$store.state.currentProcedure = {
            name: '',
        };

        let task_id = this.$route.params.task_id;
        try {
            let task = await Vue.Download.getTask(task_id);
            console.log("result of await Vue.Download.getTask( task_id ) : ", task);

            if (!task) {
                Vue.ELearning.Util.hideOverlay(this.$store);
                this.$router.push({ path: '/user/categories' });
                return;
            }

            this.$store.commit('storeTask', task);

            // タスクを閲覧した事をログに記録する
            Vue.ELearning.Logs.logTaskView(task.id).then();

            //作業手順Log初期化
            this.$store.commit("clearLogWorkProcedures");

            Vue.ELearning.Util.hideOverlay(this.$store);


        } catch (error) {

            Vue.ELearning.Util.hideOverlay(this.$store);
            console.log("get task Error > ", error);
        }

    },

    computed: {
        realContentHeight: function() {
            var winHeight = this.$store.state.winHeight;
            var realHeight = winHeight - this.contentHeight - 62;

            //console.log("contentHeight", realHeight);
            this.layoutManager();

            return realHeight;
        },

        noticeEmpty: function() {
            var notice = this.proc.notice;
            // proc.notice != undefined && proc.notice != '' && proc.notice.replace(' ', '') != ''
            if (notice == null) {
                return true;
            }
            if (notice == undefined) {
                return true;
            }
            if (notice == '') {
                return true;
            }
            if (notice.replace(' ', '') == '') {
                return true;
            }

            //console.log("notice", notice, typeof notice);
            return false;
        },

        taskName: function() {
            return this.$store.state.currentTaskName;
        },
        categoryName: function() {
            return this.$store.state.currentCategoryName;
        },
        taskSize: function() {
            if (this.$store.state.currentTask.file_size === undefined) {
                return "-";
            }

            return (this.$store.state.currentTask.file_size / 1024 / 1024).toFixed(2);
        },

        isVideo: function() {
            var path = this.proc.basepath + this.proc.videopath + '';
            if (typeof path !== 'string') {
                return false;
            } else {
                var isVideo = false;
                isVideo = isVideo || path.endsWith('.mp4');
                isVideo = isVideo || path.endsWith('.MP4');
                return isVideo;
            }
        },
        isPdf: function() {
            var path = this.proc.basepath + this.proc.videopath + '';
            if (typeof path !== 'string') {
                return false;
            } else {
                var isPdf = false;
                isPdf = isPdf || path.endsWith('.pdf');
                isPdf = isPdf || path.endsWith('.PDF');
                return isPdf;
            }
        },
        isImage: function() {
            var path = this.proc.basepath + this.proc.videopath + '';
            if (path == undefined || path == null) {
                return false;
            }

            // !name.endsWith(".jpg") || !name.endsWith(".jpeg") || !name.endsWith(".png") || !name.endsWith(".svg") || !name.endsWith(".gif")
            var isImage = false;
            isImage = isImage || path.endsWith('.jpg');
            isImage = isImage || path.endsWith('.jpeg');
            isImage = isImage || path.endsWith('.png');
            isImage = isImage || path.endsWith('.svg');
            isImage = isImage || path.endsWith('.gif');
            isImage = isImage || path.endsWith('.JPG');
            isImage = isImage || path.endsWith('.JPEG');
            isImage = isImage || path.endsWith('.PNG');
            isImage = isImage || path.endsWith('.SVG');
            isImage = isImage || path.endsWith('.GIF');

            return isImage;
        },

        proc: function() {
            //console.log('proc');

            // 現在の作業手順が変更されるタイミングで、動画を「未再生」とする
            this.$data.isVideoPlayLogged = false;

            return this.$store.state.currentProcedure;
        },
        procs: function() {
            return this.$store.state.procedures;
        },

        procSize: function() {
            return this.$store.state.procedures.length;
        },

        progress: function() {
            var itemSize = 0;
            var comSize = 0;

            for (var key in this.$store.state.procedures) {
                var item = this.$store.state.procedures[key];

                if (item.child.length > 0) {
                    var ch = item.child;

                    for (var item0 in ch) {
                        var item00 = ch[item0];
                        itemSize++;

                        if (
                            item00.status === 'partial' ||
                            item00.status === 'completed'
                        ) {
                            comSize++;
                        }
                    }
                } else {
                    itemSize++;

                    if (
                        item.status === 'partial' ||
                        item.status === 'completed'
                    ) {
                        comSize++;
                    }
                }
            }

            return Math.floor((comSize / itemSize) * 100);
        },

        leftAnimatedSize: function() {
            return this.leftTweenedSize.toFixed(0);
        },
        rightAnimatedSize: function() {
            return this.rightTweenedSize.toFixed(0);
        },
        leftAnimatedClass: function() {
            return 'col-md-' + this.leftTweenedSize.toFixed(0);
        },
        rightAnimatedClass: function() {
            return 'col-md-' + this.rightTweenedSize.toFixed(0);
        },

        leftAnimatedClassObj: function() {
            var key = 'col-md-' + this.leftTweenedSize.toFixed(0);
            var cls = {};
            cls[key] = true;

            //console.log(cls);

            return cls;
        },
        rightAnimatedClassObj: function() {
            var key = 'col-md-' + this.rightTweenedSize.toFixed(0);

            var cls = {};
            cls[key] = true;
            //console.log("right", key);
            return cls;
        },

        leftClassObject: function() {},
        rightClassObject: function() {},

        isOnline() {
            return Vue.Download.isOnline();
        }
    },

    methods: {
        on_video_play: function() {

            // すでにログ記録済みなら記録しない
            if (this.$data.isVideoPlayLogged) {
                return;
            }

            this.$data.isVideoPlayLogged = true;

            Vue.ELearning.Logs.logVideoPlay(this.$store.state.currentProcedure.id).then(
                response => {
                    if (response.status != 200) {
                        this.$data.isVideoPlayLogged = false;
                    }
                }
            ).catch(response => {
                this.$data.isVideoPlayLogged = false;
            });
        },

        toggleComponent: function(mode) {
            //console.log(mode);

            if (this.currentComponent == 'normal-checklist') {
                this.currentComponent = 'left-ful-checklist';
            } else {
                this.currentComponent = 'normal-checklist';
            }
        },
        layoutManager: function() {
            this.$nextTick(() => {
                // メインヘッダ・サブヘッダ・ウィンドウの高さ取得
                var windowHeight = jQuery(window).height(); /* - 42*/

                //var bodyOffsetTop = jQuery("#sidebar").offset().top;
                var bodyOffsetTop = jQuery('.mainCol').offset().top - 30;
                console.log(
                    'H:',
                    windowHeight,
                    bodyOffsetTop,
                    windowHeight - bodyOffsetTop,
                );
                this.contentHeight = bodyOffsetTop;

                var windowWidth = jQuery(window).width();
                var sideBarWidth = jQuery('#sidebar').width();
                this.sidebarWidth = sideBarWidth;
                //console.log("W:", windowWidth, sideBarWidth, windowWidth - sideBarWidth);
            });
        },

        contentFullPath: function(proc) {
            var path = proc.basepath + proc.videopath;
            if (path == undefined) {
                return '';
            } else {
                return path;
            }
        },

        contentFullPathEndsWith: function(proc, target) {
            var path = proc.basepath + proc.videopath;
            //console.log("path", path);

            if (typeof path !== String) {
                return false;
            }

            if (path == undefined) {
                return false;
            } else {
                return path.endsWith(target);
            }
        },

        nextProc: function() {
            //this.$store.commit('nextProc', this.proc);
            this.$store.commit('nextProc', this.proc);
        },

        initByCurrentProc: function() {
            this.proc.status = 'incomplete';
        },

        skipByCurrentProc: function() {
            this.showModalSkip = false;

            this.proc.status = 'partial';

            this.$store.commit('nextProc', this.proc);
        },

        completeByCurrentProc: function() {
            this.showModalCompleted = false;

            this.proc.status = 'completed';

            this.$store.commit('nextProc', this.proc);
        },

        showItem: function(event, item) {},

        onChange: function(event) {},

        changeOffCanvas: function(event) {
            if (!this.offCanvasState) {
                this.leftSize = 0;
                this.rightSize = 12;
            } else {
                this.leftSize = 8;
                this.rightSize = 4;
            }

            this.offCanvasState = !this.offCanvasState;
        },

        back() {
            this.$router.push({ path: '/user/tasks/' + this.$store.state.current_category_id });
        },

        async downloadTask() {
            try {
                this.taskDownloaded = false;
                this.showModalDownload = true;

                const task = await Vue.Download.downloadTask(this.$store.state.currentTask.id);

                //F2 No.67 モーダル表示に変更 
                //this.$dialog.message("タスクをダウンロードしました。");
                this.taskDownloaded = true;

                this.$store.commit('storeTask', task);

                console.log('do');
                this.$store.dispatch("updateStorageEstimate");
                console.log('done');
            } catch (e) {
                console.error(e);
                this.closeModalDownload();

                if (e instanceof errors.TaskSizeOverError) {
                    this.$dialog.message("容量不足のためタスクをダウンロードできません。");
                } else {
                    this.$dialog.message("タスクのダウンロードに失敗しました。");
                }
            }
        },
        closeModalDownload() {
            this.showModalDownload = false;
        },

        spDetailAllOpen() {
            this.$refs.SpCheckList.detailShowAllToggle();
        }
    },

    watch: {
        leftSize: function(newValue) {
            TweenLite.to(this.$data, 1, { leftTweenedSize: newValue });
        },
        rightSize: function(newValue) {
            TweenLite.to(this.$data, 1, { rightTweenedSize: newValue });
        },

        showModalCompleted: function(val) {
            if (val) {
                Vue.ELearning.Util.bodyScrollOff();
            } else {
                Vue.ELearning.Util.bodyScrollOn();
            }
        },
        showModalSkip: function(val) {
            if (val) {
                Vue.ELearning.Util.bodyScrollOff();
            } else {
                Vue.ELearning.Util.bodyScrollOn();
            }
        },
        showModalTaskCompleted: function(val) {
            if (val) {
                Vue.ELearning.Util.bodyScrollOff();
            } else {
                Vue.ELearning.Util.bodyScrollOn();
            }
        },
    },
};