var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "やることリスト",
      "main-class": "notice_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("section", { staticClass: "bg_white" }, [
              _c("div", { staticClass: "list_box" }, [
                _c(
                  "ul",
                  [
                    _vm.todos && _vm.todos.length > 0
                      ? [
                          _vm._l(_vm.todos, function(item, index) {
                            return [
                              _c("li", { key: index }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "flex_nw",
                                    attrs: { href: _vm.link(item) }
                                  },
                                  [
                                    _c("div", { staticClass: "icon" }, [
                                      _c("p", {
                                        style: _vm.iconImage(
                                          item.notice.user.image
                                        )
                                      })
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "content" }, [
                                      _c("p", [
                                        _vm._v(_vm._s(item.notice.name))
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "date" }, [
                                        _vm._v(
                                          _vm._s(_vm.date(item.created_at))
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fas fa-angle-right"
                                    })
                                  ]
                                )
                              ])
                            ]
                          })
                        ]
                      : [
                          _c("div", { staticClass: "container pt50" }, [
                            _c("p", [_vm._v("やることリストはありません")])
                          ])
                        ]
                  ],
                  2
                )
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }