var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flow-container" }, [
    _c(
      "div",
      { staticClass: "sp-hidden" },
      [
        _c(
          "div",
          {
            staticClass: "flow",
            class: {
              "last-flow": _vm.isLast && !_vm.openChild,
              "bg-gray": _vm.isNotIncomplete,
              opened: _vm.isOpened,
              skipped: _vm.isOpened && !_vm.hasChild
            },
            style: { backgroundColor: _vm.backgroundColor },
            on: { click: _vm.showItem }
          },
          [
            _c("label", { staticStyle: { display: "block" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.isNotIncomplete,
                    expression: "isNotIncomplete"
                  }
                ],
                staticClass: "checkbox01-input",
                attrs: { type: "checkbox", disabled: "" },
                domProps: {
                  checked: Array.isArray(_vm.isNotIncomplete)
                    ? _vm._i(_vm.isNotIncomplete, null) > -1
                    : _vm.isNotIncomplete
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.isNotIncomplete,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.isNotIncomplete = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.isNotIncomplete = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.isNotIncomplete = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "checkbox01-parts" },
                [
                  _c("span", {
                    style: {
                      color: _vm.textColor,
                      fontSize: _vm.fontSize
                    },
                    domProps: { textContent: _vm._s(_vm.name) }
                  }),
                  _vm._v(" "),
                  _vm.hasChild
                    ? [
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.openChild,
                              expression: "!openChild"
                            }
                          ],
                          staticClass: "expand-icon",
                          attrs: { src: "/images/ico-plus-gray.png", alt: "" },
                          on: { click: _vm.expand }
                        }),
                        _vm._v(" "),
                        _c("img", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.openChild,
                              expression: "openChild"
                            }
                          ],
                          staticClass: "expand-icon",
                          attrs: { src: "/images/ico-minus-gray.png", alt: "" },
                          on: { click: _vm.expand }
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              _vm.isUpdated
                ? _c("img", {
                    staticClass: "ico-task-attention",
                    attrs: { src: "/images/ico-task-attention.png", alt: "" }
                  })
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showCtrl && !_vm.hideContent,
                expression: "showCtrl && !hideContent"
              }
            ],
            staticClass: "buttons2"
          },
          [
            _c(
              "a",
              {
                staticClass: "button basic is-gray action-skip",
                on: {
                  click: function($event) {
                    return _vm.onSkip(_vm.item)
                  }
                }
              },
              [_vm._v("スキップ")]
            ),
            _vm._v(" "),
            _c("span", [_vm._v("  ")]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "button basic is-login action-complete",
                on: {
                  click: function($event) {
                    return _vm.onComplete(_vm.item)
                  }
                }
              },
              [_vm._v("完了")]
            )
          ]
        ),
        _vm._v(" "),
        _vm._l(_vm.item.child, function(child) {
          return [
            _c(
              "transition",
              { key: child.branch_id, attrs: { name: "slide-fade" } },
              [
                _vm.openChild
                  ? _c("work-procedure-child", {
                      attrs: {
                        "action-name": _vm.actionName,
                        isLast: _vm.isLast,
                        item: child,
                        "show-ctrl": _vm.showCtrl,
                        "on-skip": _vm.onSkip,
                        "on-complete": _vm.onComplete
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        })
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pc-hidden" }, [
      _c(
        "div",
        {
          staticClass: "blockToggle_head",
          class: {
            child: _vm.item.branch_id > 0,
            "is-check": _vm.isNotIncomplete,
            "is-skip": _vm.isOpened && !_vm.hasChild,
            "is-active": _vm.isActive || _vm.isOpenDetail
          },
          on: {
            click: function($event) {
              return _vm.$emit("active")
            }
          }
        },
        [
          _c("div", { staticClass: "blockToggle_title" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isNotIncomplete,
                  expression: "isNotIncomplete"
                }
              ],
              class: {
                "is-skip": _vm.isOpened && !_vm.hasChild,
                "is-check": _vm.isNotIncomplete
              },
              attrs: { type: "checkbox", disabled: "" },
              domProps: {
                checked: Array.isArray(_vm.isNotIncomplete)
                  ? _vm._i(_vm.isNotIncomplete, null) > -1
                  : _vm.isNotIncomplete
              },
              on: {
                change: function($event) {
                  var $$a = _vm.isNotIncomplete,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isNotIncomplete = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isNotIncomplete = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isNotIncomplete = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("p", { staticClass: "blockToggle_text" }, [
              _vm._v(_vm._s(_vm.name))
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "iconWrap" }, [
              _vm.isUpdated ? _c("span", { staticClass: "icon_ex" }) : _vm._e(),
              _vm._v(" "),
              !_vm.hasChild
                ? _c("span", {
                    staticClass: "blockToggle_btn",
                    class: { "is-active": _vm.isOpenDetail },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.$emit("open-detail-toggle")
                      }
                    }
                  })
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "btnarea" } }, [
            _vm.isActive && !_vm.hasChild && !_vm.isOpenDetail
              ? _c("div", { staticClass: "btnArea-top" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button rs is-skip",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onSkip(_vm.item)
                        }
                      }
                    },
                    [_vm._v("スキップ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button rs is-check",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onComplete(_vm.item)
                        }
                      }
                    },
                    [_vm._v("完了")]
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }