<template>
  <table class="table basic4 color01 has-sorticon" :class="classess">
    <thead>
      <tr>
        <th>&nbsp;</th>
        <th v-for="( header, n ) in headers" :key="n" @click.prevent.stop="header.sortName? sort( header.sortName ) : sort( header.name )">{{ header.title }}<a :class="header.sortName? sort_class( header.sortName ) : sort_class( header.name )" href="#"></a></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="( item, n ) in list" :key="n">
        <td>{{ ( n + 1 ) | zero_pad( 2 ) }}</td>
        <td v-for="( header, m ) in headers" :key="m">{{ header.filter ? header.filter( item[ header.name ] ) : item[ header.name ] }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>

export default {
  data: () => {
    return {
    }
  },

  props: {
    headers: Array,                     // ヘッダの一覧
    list: Array,                        // 対象の一覧
    limit: Number,                      // 初期表示数

    is_more: Boolean,                   // 
    
    sort_by: String,
    is_sort_asc: Boolean,
  },

  watch: {
    sort_by: function ( value ) { this.sort_list(); },
    is_sort_asc: function ( value ) { this.sort_list(); },
  },

  computed: {
    classess: function () {
      let result = { 'partial-display': ! this.is_more };

      if ( this.limit )
      {
        result[ "initial-rows" + this.limit ] = true;
      }
      
      return result;
    }
  },

  methods: {
    sort: function ( by )  {
      if ( by == this.sort_by )
      {
        this.$emit( "update:is_sort_asc", ! this.is_sort_asc );
      }
      else
      {
        this.$emit( "update:sort_by", by );
        this.$emit( "update:is_sort_asc", true );
      }
    },

    sort_class: function ( by )
    {
      return {
        'sort-icon': this.sort_by == by,
        'asc': this.is_sort_asc,
      }
    },

    /**
     * 一覧をソートする
     */
    sort_list: function ()
    {
      // alert( this.sort_by + ":" + this.is_sort_asc );

      this.list.sort( ( a, b ) => {

        const value_a = a[ this.sort_by ]? a[ this.sort_by ] : "";
        const value_b = b[ this.sort_by ]? b[ this.sort_by ] : "";          

        if ( this.is_sort_asc )
        {
          return value_a < value_b ? -1 : 1;
        }
        else
        {
          return value_a < value_b ? 1 : -1;
        }
      } );

      this.$emit( "update:list", this.list );
    },
  },

  mounted: function ()
  {

  }
};

</script>
<style scoped lang="scss">
</style>
