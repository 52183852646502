<template>
    <common-layout-component :show-back="true" :show-navi-bar="true"  @on-nav-back="onNaviBack" :require-user-info="true" :title="title" main-class="lesson_page">
        <template v-slot:body v-if="lesson">
            <section class="post post_video bg_yellow">
                <div class="container pt20">
                    <lesson-video-input-component :index="1" title="自分の動画を選択（任意）" @change-video="onChangeVideo($event)"/>

                    <div v-if="viewType == 1 && $store.state.userInfo.grant == 2" class="box">
                        <h3>点数</h3>
                        <input type="number" placeholder="95" v-model="comment.score" min="0" max="100">
                    </div>

                    <div v-if="viewType == 2 && $store.state.userInfo.grant == 1" class="box">
                        <h3>件名</h3>
                        <input type="text" placeholder="" v-model="comment.title" maxlength="50">
                    </div>

                    <div v-if="viewType == 1 && $store.state.userInfo.grant == 2" class="box">
                        <h3>評価</h3>
                        <input type="text" placeholder="よくできました！" v-model="comment.title" maxlength="50">
                    </div>

                    <div class="box">
                        <h3 v-if="viewType == 1">コメント</h3>
                        <h3 v-if="viewType == 2 && $store.state.userInfo.grant == 1">質問内容</h3>
                        <h3 v-if="viewType == 2 && $store.state.userInfo.grant >= 2">回答</h3>
                        <textarea placeholder="" v-model="comment.message"></textarea>
                    </div>
                    <div class="box" v-if="$store.state.userInfo.grant == 1">
                        <h3>公開設定</h3>
                        <dl class="flex" @click="comment.publish = 1 - comment.publish">
                            <dt>みんなに公開する</dt>
                            <dd><i v-if="comment.publish == 1" class="fas fa-toggle-on"></i>
                                <i v-if="comment.publish != 1" class="fas fa-toggle-off"></i></dd>
                        </dl>
                        <p>講師にだけ公開する場合はOFF
                        </p>
                    </div>
                    <button class="mt20 button md" @click="post">投稿する</button>
                </div>
            </section>
            <modal-component v-if="showModalSaved" title="投稿しました" :view-plain="true" @ok="posted"/>

        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import LessonVideoInputComponent from '../../miso/user/LessonVideoInputComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonVideoInputComponent,
        },
        props: {
            lessonId: {
                type: Number,
            },
            advice: {
                type: Number,
            },
            question: {
                type: Number,
            },
            type: {
                type: String,
            },                        
        },
        data: function () {
            return {
                lesson:null,
                isPublic: true,
                isCommentType: 1, //1:
                uploadVideoFile: null,
                comment:{
                    task_id :null,
                    user_id: this.$store.state.userInfo.id,
                    type: this.viewType,
                    video_id:null,
                    title: '',
                    message: '',
                    score: null,
                    publish: 1,
                },
                showModalSaved: false,
                uploadThumbnailBlob: null,
            }
        },
        computed: {
            viewType:function(){
                return (this.type == "advice")? 1 : 2 //1:アドバイス 2:質問
            },
            title: function(){
                if(this.viewType == 1 && this.$store.state.userInfo.grant == 1){
                    return "採点してもらう"
                }
                if(this.viewType == 1 && this.$store.state.userInfo.grant == 2){
                    return "採点する"
                }
                if(this.viewType == 2 && this.$store.state.userInfo.grant == 1){
                    return "質問する"
                }
                if(this.viewType == 2 && this.$store.state.userInfo.grant == 2){
                    return "回答する"
                }                                                
            }
        },
        mounted:function(){

            if(this.$store.state.userInfo.grant == 3 && !this.advice){
                this.$libs.pushUrl("")
            }

            this.comment.type = this.viewType
            this.getData()
        },
        methods:{
            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTask(this.lessonId);
                taskHandler
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("task", res.data.content);
                            this.lesson = res.data.content;

                            //生徒で未購入の場合はイントロへリダイレクト
                            if(this.$store.state.userInfo.grant == 1 && this.lesson.purchases.length == 0){
                                this.$libs.pushUrl(['lesson', this.lessonId])
                                return
                            }
                            //講師で自分のレッスンでない場合は紹介TOPへリダイレクト
                            if(this.$store.state.userInfo.grant == 2 && this.lesson.user_id != this.$store.state.userInfo.id){
                                this.$libs.pushUrl(["lesson",this.lessonId])
                                return
                            }

                            //講師からの返信のとき
                            if(this.advice){
                               this.comment.reply_id = this.advice
                            }
                            //講師からの返信のとき
                            if(this.question){
                               this.comment.reply_id = this.question
                            }
                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },
            errorHandler :function(error){

            },
            post: function(){

                this.comment.task_id = this.lesson.id
                Promise.resolve()
                .then(() => {
                    //ビデオアップロード
                    if(this.uploadVideoFile){
                        let fd = new FormData(); //★②
                        fd.append('fileData', this.uploadVideoFile);

                        if(this.uploadThumbnailBlob){
                            fd.append('thumbnail', this.uploadThumbnailBlob);
                        }

                        var config = {
                            headers: { 'Content-Type': 'multipart/formdata' }
                        };

                        return Vue.ELearning.Assets.upload(fd, config);

                    //レッスンアップロード
                    }else{
                        return null
                    }

                }).then((response) => {
                    if(response){
                        if (response.data.status_number == 200) {
                            var content = response.data.content;
                            console.log('asset upload completed:', content);
                            if (content.hasFile == 'OK') {
                                this.comment.video_id = content.video.video_id;                                
                                this.uploadVideoFile = null
                                this.uploadThumbnailBlob =null
                                
                                return Vue.ELearning.Task.insertComment(this.comment);
                            } else {
                                // アップロード失敗
                                this.$dialog.message("ファイルのアップロードに失敗しました。");
                            }
                        } else {
                            throw new Error('ファイルのアップロードに失敗しました');
                        }

                    }else{
                        return Vue.ELearning.Task.insertComment(this.comment);
                    }

                }).then((response) => {

                    console.log('アドバイス登録', response.data.content);

                    var content = response.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        console.error(content);
                        this.errorHandler(content);
                        //Vue.ELearning.Util.hideOverlay(this.$store);
                        return;
                    }
                    this.showModalSaved = true

                }).catch((response) => {
                    console.error(response);
                    this.$dialog.message("エラーが発生しました");
                });

            },
            onChangeVideo: function(file){
                this.uploadVideoFile = file

                const self = this

                //videoの場合はサムネイルを作成
                this.$store.commit('overlay', true);
                this.$libs.createThumbnails(file,function(thumbnail){

                    if(thumbnail){
                        var url = (window.URL || window.webkitURL);
                        self.uploadThumbnailBlob = thumbnail;
                    }else{
                        console.log("no video or thumnail create errer")
                    }
                    self.$store.commit('overlay', false);
                })


            },
            posted: function(){
                this.$libs.pushUrl(['lesson',this.lessonId, 'intro'])
            },

            onNaviBack: function(){

                if(this.lesson){
                    this.$libs.pushUrl(['lesson',this.lessonId, 'intro'])
                }else{
                    history.back()
                }
            },

        }
    }
</script>
<style lang="scss" scoped>
</style>
