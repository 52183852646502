<template>
<div id="wrapper">
    <main id="main" :class="[className]">
        <transition name="view">
        </transition>

        <overlay v-if="showOverlay"></overlay>
    </main>
</div>
</template>
<script>
    export default {

        computed: {
            showOverlay: function() {
                return this.$store.state.showOverlay;
            },
            className: function(){
            }
        }
    }
</script>
<style>
    .view-enter-active, view-leave-active {
        transition: opacity 0.5s
    }
    .view-leave-active {
        position:absolute;
    }
    .view-enter, .view-leave-to {
        opacity:0;
    }
</style>