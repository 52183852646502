var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "justify-content-center" }, [
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          version: "1.1",
          viewBox: _vm.viewBox,
          width: _vm.rad2,
          height: _vm.rad2
        }
      },
      [
        _c("circle", {
          attrs: { cx: _vm.rad, cy: _vm.rad, r: _vm.rad, fill: "#D3D5D7" }
        }),
        _vm._v(" "),
        _c("path", {
          attrs: { d: _vm.pathParam, fill: _vm.circleFill, stroke: "#fff" }
        }),
        _vm._v(" "),
        _c("circle", {
          attrs: { cx: _vm.rad, cy: _vm.rad, r: _vm.radInner, fill: "#FFFFFF" }
        }),
        _vm._v(" "),
        _vm.size != "small"
          ? _c(
              "text",
              {
                staticStyle: { "font-weight": "bold", "font-size": "1.2em" },
                attrs: {
                  x: _vm.percentLabelX,
                  y: _vm.rad * 1.44,
                  fill: _vm.textFill
                }
              },
              [_vm._v(_vm._s(_vm.percentLabel))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showCompletedLabel && _vm.size != "small"
          ? _c(
              "text",
              {
                staticStyle: { "font-weight": "bold", "font-size": "2.5em" },
                attrs: { x: _vm.percentX + 10, y: _vm.rad, fill: _vm.textFill }
              },
              [_vm._v("✔")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showUnCompletedLabel && _vm.size != "small"
          ? _c(
              "text",
              {
                staticStyle: { "font-weight": "bold", "font-size": "2.5em" },
                attrs: { x: _vm.percentX, y: _vm.rad, fill: _vm.textFill }
              },
              [_vm._v(_vm._s(_vm.unCompTextContent))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showPercentLabel && _vm.size != "small"
          ? _c(
              "text",
              {
                staticStyle: { "font-weight": "bold", "font-size": "1.5em" },
                attrs: {
                  x: _vm.percentX,
                  y: _vm.rad * 0.88,
                  fill: _vm.textFill
                }
              },
              [_vm._v(_vm._s(_vm.percentText))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.size == "small" && _vm.percent > 0
          ? _c(
              "text",
              {
                staticStyle: { "font-weight": "bold", "font-size": "0.8em" },
                attrs: {
                  x: _vm.percentLabelX,
                  y: _vm.rad + 9,
                  fill: _vm.textFill
                }
              },
              [_vm._v(_vm._s(_vm.percentLabel))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showPercentLabel && _vm.size == "small" && _vm.percent > 0
          ? _c(
              "text",
              {
                staticStyle: { "font-weight": "bold", "font-size": "1.1em" },
                attrs: { x: _vm.percentX, y: _vm.rad - 1, fill: _vm.textFill }
              },
              [_vm._v(_vm._s(_vm.percentText))]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }