<template>
    <modal-component @modalNo="close" is-quest="true" width="1200">
        <div slot="header">
            <h3>閲覧タスク選択</h3>
        </div>
        <div slot="body">
            <multi-selection-component
                :current-parents="categoryTasks"
                :is-selection-mode="true"
                :use-parent-check="true"
                parent-item-name="categoryName"
                child-item-name="taskName"
                child-column-name="tasks"
            ></multi-selection-component>
        </div>
        <div slot="footer">
            <div class="row">
                <div class="col-md-12" style="text-align:center;">
                    <button class="button basic is-cancel" @click="cancel">
                        キャンセル</button
                    >&nbsp;&nbsp;
                    <button class="button basic is-login" @click="close">
                        選択完了
                    </button>
                </div>
            </div>
        </div>
    </modal-component>
</template>

<script>
import ModalComponent from '../../../../components/commons/ModalComponent.vue';
import MultiSelectionComponent from './MultiSelectionComponent.vue';

export default {
    props: {
        categoryTasks: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ModalComponent,
        MultiSelectionComponent,
    },
    methods: {
        close() {
            this.$emit('close-modal');
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>

<style lang="scss" scoped></style>
