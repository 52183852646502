<template>
  <div id="loading">
    <div class="inner">
      <div class="loadingio-spinner-rolling-demwobq4wwf">
        <div class="ldio-m1b4ic6pq8s">
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {},
  props: {},
};
</script>
<style scoped lang="scss">
#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.0);
  z-index: 1000;
}

.inner {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
}

@keyframes ldio-m1b4ic6pq8s {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-m1b4ic6pq8s div {
  position: absolute;
  width: 60px;
  height: 60px;
  border: 10px solid #f79e04;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-m1b4ic6pq8s div {
  animation: ldio-m1b4ic6pq8s 1s linear infinite;
  top: 50px;
  left: 50px;
}

.loadingio-spinner-rolling-demwobq4wwf {
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-m1b4ic6pq8s {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-m1b4ic6pq8s div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
</style>