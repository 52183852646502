var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: "レッスン",
      "main-class": "lesson_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.lesson
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "lesson_header bg_yellow" }, [
                    _c(
                      "div",
                      { staticClass: "container pt0 pb0" },
                      [
                        _c("lesson-video-component", {
                          attrs: {
                            video: _vm.videoPath,
                            thumbnail: _vm.videoThumbnail
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("section", { staticClass: "bg_yellow" }, [
                    _c("div", { staticClass: "container pt20 pb10" }, [
                      _c("div", { staticClass: "user_teacher flex" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$libs.url([
                                "teacher",
                                _vm.lesson.user.id
                              ])
                            }
                          },
                          [
                            _c("div", { staticClass: "icon" }, [
                              _c("p", {
                                style: _vm.iconImage(_vm.lesson.user.image)
                              })
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "name" }, [
                          _c("p", [_vm._v("講師")]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$libs.url([
                                  "teacher",
                                  _vm.lesson.user.id
                                ])
                              }
                            },
                            [_vm._v(_vm._s(_vm.lesson.user.display_name))]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _c(
                          "div",
                          { staticClass: "categories flex" },
                          [
                            _c("div", { staticClass: "cat no_border" }, [
                              _vm._v(_vm._s(_vm.partName))
                            ]),
                            _vm._v(" "),
                            _c("level-component", {
                              attrs: { level: _vm.lesson.level }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("h1", [_vm._v(_vm._s(_vm.lesson.title))])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.lesson.user_id == _vm.$store.state.userInfo.id &&
                    _vm.$store.state.userInfo.grant != 1
                      ? _c("div", { staticClass: "current_students" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$libs.url([
                                  "lesson",
                                  _vm.lesson.id,
                                  "purchased"
                                ])
                              }
                            },
                            [
                              _c("span", [_vm._v("現在の受講者")]),
                              _vm._v(" "),
                              _c("span", { staticClass: "num" }, [
                                _vm._v(_vm._s(_vm.lesson.purchases_count))
                              ])
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "container pt0" }, [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "types flex_nw mt20" }, [
                            _c(
                              "a",
                              {
                                class: { current: _vm.viewType == 1 },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.viewType = 1
                                  }
                                }
                              },
                              [_vm._v("レッスン")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                class: { current: _vm.viewType == 2 },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.viewType = 2
                                  }
                                }
                              },
                              [_vm._v("採点")]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                class: { current: _vm.viewType == 3 },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    _vm.viewType = 3
                                  }
                                }
                              },
                              [_vm._v("Q & A")]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.viewType == 1
                            ? _c("div", { staticClass: "box_wh steps" }, [
                                _c(
                                  "ul",
                                  [
                                    _vm._l(_vm.lesson.steps, function(
                                      item,
                                      index
                                    ) {
                                      return [
                                        _c("li", { key: index }, [
                                          _c(
                                            "a",
                                            {
                                              class: {
                                                complete:
                                                  item.progress.length > 0
                                              },
                                              attrs: {
                                                href: _vm.$libs.url([
                                                  "lesson",
                                                  _vm.lesson.id,
                                                  index
                                                ])
                                              }
                                            },
                                            [
                                              _c("p", { staticClass: "step" }, [
                                                item.progress.length > 0 &&
                                                item.progress[0].status == 1
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fas fa-check"
                                                    })
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                                        Step" +
                                                    _vm._s(index + 1) +
                                                    "\n                                    "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(_vm._s(item.title))
                                              ]),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-angle-right"
                                              })
                                            ]
                                          )
                                        ])
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.viewType == 2
                            ? [
                                _c("lesson-scores-component", {
                                  attrs: {
                                    lesson: _vm.lesson,
                                    "my-scores": _vm.myScores,
                                    scores: _vm.scores
                                  }
                                })
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.viewType == 3
                            ? [
                                _c("lesson-qa-component", {
                                  attrs: {
                                    lesson: _vm.lesson,
                                    "my-qa": _vm.myQa,
                                    qa: _vm.qa
                                  }
                                })
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h2", [_vm._v("レッスン情報")]),
                          _vm._v(" "),
                          _c("lesson-description-component", {
                            attrs: {
                              lesson: _vm.lesson,
                              "show-toggle-button": true
                            }
                          })
                        ],
                        2
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.showModalComment
                    ? _c("modal-component", {
                        attrs: {
                          title: _vm.commentModalTitle,
                          "view-selectable": true
                        },
                        on: {
                          yes: _vm.commentPurchase,
                          no: function($event) {
                            _vm.showModalComment = false
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showModalCommentPurchased
                    ? _c("modal-component", {
                        attrs: {
                          title: "ご購入ありがとうございます！",
                          "view-plain": true,
                          ok: _vm.commentModalOkTitle
                        },
                        on: { ok: _vm.onCommentPurchased }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "action",
          fn: function() {
            return [
              _c(
                "div",
                [
                  _vm.viewType == 2 && _vm.$store.state.userInfo.grant == 1
                    ? [
                        _c("div", { staticClass: "container pt10 pb10" }, [
                          _c(
                            "div",
                            { staticClass: "action" },
                            [
                              _vm.lesson.del_flag == 0 &&
                              _vm.lesson.draft == 0 &&
                              _vm.lesson.display_flag == 1
                                ? [
                                    _vm.lesson.advice_price > 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "round",
                                            on: {
                                              click: function($event) {
                                                _vm.showModalComment = true
                                              }
                                            }
                                          },
                                          [_vm._v("採点してもらう")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.lesson.advice_price == 0
                                      ? _c(
                                          "button",
                                          {
                                            staticClass: "round",
                                            on: {
                                              click: function($event) {
                                                return _vm.commentPurchase()
                                              }
                                            }
                                          },
                                          [_vm._v("採点してもらう")]
                                        )
                                      : _vm._e()
                                  ]
                                : [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "round",
                                        attrs: { disabled: "" }
                                      },
                                      [_vm._v("採点依頼はできません")]
                                    )
                                  ]
                            ],
                            2
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.viewType == 3 && _vm.$store.state.userInfo.grant == 1
                    ? [
                        _c("div", { staticClass: "container pt10 pb10" }, [
                          _c(
                            "div",
                            { staticClass: "action" },
                            [
                              _vm.lesson.del_flag == 0 &&
                              _vm.lesson.draft == 0 &&
                              _vm.lesson.display_flag == 1
                                ? [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "round",
                                        on: {
                                          click: function($event) {
                                            return _vm.commentPurchase()
                                          }
                                        }
                                      },
                                      [_vm._v("質問する")]
                                    )
                                  ]
                                : [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "round",
                                        attrs: { disabled: "" }
                                      },
                                      [_vm._v("質問できません")]
                                    )
                                  ]
                            ],
                            2
                          )
                        ])
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.lesson &&
                  _vm.lesson.user_id == _vm.$store.state.userInfo.id &&
                  _vm.$store.state.userInfo.grant == 2
                    ? [
                        _c("div", { staticClass: "create" }, [
                          _c("div", { staticClass: "flex_nw" }, [
                            _c(
                              "button",
                              {
                                staticClass: "round",
                                on: {
                                  click: function($event) {
                                    return _vm.$libs.pushUrl([
                                      "lesson",
                                      _vm.lessonId,
                                      "edit"
                                    ])
                                  }
                                }
                              },
                              [_vm._v("編集")]
                            )
                          ])
                        ])
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }