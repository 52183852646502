var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.currentGroupUsers, function(groupUser) {
        return [
          groupUser.users.length > 0
            ? _c(
                "div",
                {
                  staticClass: "modal-cat",
                  style: { color: _vm.groupColor(groupUser) }
                },
                [
                  _c("span", [_vm._v(_vm._s(groupUser.groupName) + "  ")]),
                  _vm._v(" "),
                  !groupUser.isOpen
                    ? _c("img", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { src: "/images/ico-plus-gray.png", alt: "" },
                        on: {
                          click: function($event) {
                            groupUser.isOpen = !groupUser.isOpen
                          }
                        }
                      })
                    : _c("img", {
                        staticStyle: { cursor: "pointer" },
                        attrs: { src: "/images/ico-minus-gray.png", alt: "" },
                        on: {
                          click: function($event) {
                            groupUser.isOpen = !groupUser.isOpen
                          }
                        }
                      })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          groupUser.isOpen
            ? _c(
                "div",
                { staticClass: "tasks" },
                [
                  _vm._l(groupUser.users, function(user, idx) {
                    return [
                      _vm.isSelectionMode
                        ? _c(
                            "div",
                            {
                              staticClass: "task-body",
                              class: {
                                "last-l": _vm.isBottomUserLeft(
                                  groupUser.users,
                                  idx
                                ),
                                "last-r": _vm.isBottomUserRight(
                                  groupUser.users,
                                  idx
                                )
                              },
                              style: {
                                color: _vm.userColor(user),
                                "font-weight": _vm.userWeight
                              }
                            },
                            [
                              _c("label", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: user.selected,
                                      expression: "user.selected"
                                    }
                                  ],
                                  staticClass: "checkbox01-input",
                                  attrs: { type: "checkbox", disabled: "" },
                                  domProps: {
                                    checked: Array.isArray(user.selected)
                                      ? _vm._i(user.selected, null) > -1
                                      : user.selected
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = user.selected,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              user,
                                              "selected",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              user,
                                              "selected",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(user, "selected", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "checkbox01-parts",
                                    on: {
                                      click: function($event) {
                                        return _vm.onCheckStateChange(user)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(user.userName))]
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isSelectionMode
                        ? _c(
                            "div",
                            {
                              staticClass: "task-body",
                              class: {
                                "last-l": _vm.isBottomUserLeft(
                                  groupUser.user,
                                  idx
                                ),
                                "last-r": _vm.isBottomUserRight(
                                  groupUser.user,
                                  idx
                                )
                              },
                              style: {
                                color: _vm.userColor(user),
                                "font-weight": _vm.userWeight
                              }
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(user.userName) +
                                  "\n                "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isSelectionMode &&
                      groupUser.users.length - 1 == idx &&
                      groupUser.users.length % 2 == 1
                        ? _c("div", { staticClass: "task-body last-r" }, [
                            _vm._v(" ")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isSelectionMode &&
                      groupUser.users.length - 1 == idx &&
                      groupUser.users.length % 2 == 1
                        ? _c("div", { staticClass: "task-body last-r" }, [
                            _c("label", [_vm._v(" ")])
                          ])
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }