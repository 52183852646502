var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "level" },
    [
      _vm._l([0, 1, 2, 3, 4], function(i, index) {
        return [
          i < _vm.level
            ? _c("i", { key: index, staticClass: "fas fa-star" })
            : _c("i", { key: index, staticClass: "far fa-star" })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }