import historykana from 'historykana';
import japanese from 'japanese';

var logic = {};

logic.created = function() {
    //console.log("Initialize Admin/UserEdit");
    var userId = this.$route.params.userId;
    if (userId > 0) {
        this.title = 'ユーザー編集';
    } else {
        this.title = 'ユーザー登録';
    }
};

logic.mounted = function() {
    this.refleshData();
};

logic.methods = {
    refleshData: function() {
        var userId = this.$route.params.userId;

        if (userId > 0) {
            // サーバからデータを取得する。

            // ユーザー情報取得
            var handler = Vue.ELearning.UserInfo.getUser(userId);

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    var user = response.data.content;

                    //console.log("user:", user);

                    this.userId = user["user_id"];
                    this.userName = user["user_name"];
                    this.userNameKana = user["user_name_kana"];
                    this.mailAddress = user["mail_address"];
                    this.newMailAddress = user["mail_address"];
                    this.orgName = user["org_name"];
                    this.password = user["password"];
                    this.loginFailed = user["login_failed"];

                    const getGrantName = (grantId) => {
                        if (grantId == 1) {
                            return "viewer";
                        } else if (grantId == 2) {
                            return "editor";
                        } else if (grantId >= 3) {
                            return "admin";
                        } else {
                            return "viewer";
                        }
                    };

                    this.grant = getGrantName(user["grant_id"]);

                    if (this.grant === "admin") {
                        this.notSelect = true;
                    }

                    // console.log("grant", this.grant);

                    // ユーザーに紐づくグループ情報を未選択状態のものも含めて取得する
                    var handlerGroup = Vue.ELearning.Assignment.getAllGroupsByUser(userId, 'group_name', this.sortOrderGroup);

                    return handlerGroup;
                } else {
                    throw new Error("ユーザー情報の取得に失敗しました", response);
                }

            }).then((response) => {
                // グループ情報反映
                //console.log("グループ問い合わせ", response);
                if (response.data.status_number == 200) {
                    this.groups = [];
                    var groups = response.data.content;

                    //console.log("groups:", groups);

                    for (var key in groups) {
                        var group = groups[key];

                        //console.log(group);
                        if (this.grant === "admin") {
                            this.groups.push({
                                groupId: group["group_id"],
                                groupName: group["group_name"],
                                selected: true
                            });
                        } else {
                            this.groups.push({
                                groupId: group["group_id"],
                                groupName: group["group_name"],
                                selected: group["selected"] == 0 ? false : true
                            });
                        }
                    }
                    //console.log("groups:", this.groups);
                } else {
                    throw new Error("グループ情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
            // 新規登録
        } else {

            var handler = Vue.ELearning.Group.getAll('group_name', this.sortOrderGroup);
            handler.then((res) => {
                if (res.data.status_number == 200) {
                    var content = res.data.content;
                    //console.log(content);
                    this.groups = [];

                    for (var key in content) {
                        var item = content[key];

                        this.groups.push({
                            groupId: item["group_id"],
                            groupName: item["group_name"],
                            selected: false
                        });
                    }

                }
            }).catch((res) => {
                console.error(res);
            });

            this.grant = "viewer";
        }
    },

    errorHandler: function(content) {
        if (content.errors == undefined) {
            return;
        }


        if (content.errors.user_name != undefined) {
            //console.log("err-user-name");
            this.errUserName = true;

            this.userNameErrors = [];
            for (var key in content.errors.user_name) {
                var item = content.errors.user_name[key];
                this.userNameErrors.push(item);
            }
        }
        if (content.errors.mail_address != undefined) {
            //console.log("err-mail_address");
            this.errMailAddress = true;

            this.mailAddressErrors = [];
            for (var key in content.errors.mail_address) {
                var item = content.errors.mail_address[key];
                this.mailAddressErrors.push(item);
            }
        }
        if (content.errors.password != undefined) {
            //console.log("err-password");
            this.errPassword = true;

            this.passwordErrors = [];
            for (var key in content.errors.password) {
                var item = content.errors.password[key];
                this.passwordErrors.push(item);
            }
        }
    },

    saveUser: function() {
        var userId = this.$route.params.userId;

        var handler = null;

        const getGrantId = () => {
            if (this.grant == 'viewer') {
                return 1;
            } else if (this.grant == 'editor') {
                return 2;
            } else if (this.grant == 'admin') {
                return 3;
            } else {
                return 1;
            }
        };

        if (userId > 0) {
            this.errUserName = false;
            this.errMailAddress = false;
            this.errPassword = false;
            this.userNameErrors = [];
            this.mailAddressErrors = [];
            this.passwordErrors = [];

            Vue.ELearning.UserInfo.update({
                'user_id': this.userId,
                'user_name': this.userName,
                'user_name_kana': this.userNameKana,
                'org_name': this.orgName,
                'old_mail_address': this.mailAddress,
                'mail_address': this.newMailAddress,
                'password': this.password,
                'grant_id': getGrantId(),
            }, this.groups).then((response) => {
                if (response.data.status_number == 200) {
                    var content = response.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        //console.error(content);

                        this.errorHandler(content);

                        return;
                    }

                    this.$router.push("/admin/users");
                } else {
                    throw new Error("ユーザー情報の更新に失敗しました。");
                }
            }).catch((response) => {
                console.error(response);
            });
        } else {
            this.errUserName = false;
            this.errMailAddress = false;
            this.errPassword = false;
            this.userNameErrors = [];
            this.mailAddressErrors = [];
            this.passwordErrors = [];

            Vue.ELearning.UserInfo.insert({
                'user_name': this.userName,
                'user_name_kana': this.userNameKana,
                'org_name': this.orgName,
                'old_mail_address': this.mailAddress,
                'mail_address': this.newMailAddress,
                'password': this.password,
                'grant_id': getGrantId(),
            }, this.groups).then((response) => {
                if (response.data.status_number == 200) {
                    var content = response.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        //console.error(content);

                        this.errorHandler(content);

                        return;
                    }

                    this.$router.push("/admin/users");
                } else {
                    throw new Error("ユーザー情報の更新に失敗しました。");
                }
            }).catch((response) => {
                console.error(response);
            });
        }

        /*console.log("save", handler);

        handler.then((response) => {
            if(response.data.status_number == 200) {
                this.$router.push("/admin/users");
            } else {
                throw new Error("ユーザー情報の更新に失敗しました。");
            }
        }).catch((response) => {
            console.error(response);
        });*/
    },

    onUserByGroupChange: function(user) {
        user.selected = !user.selected;
    },

    groupSelectorOpener: function() {
        this.storeGroup();

        this.groupSelectorOpen = true;
    },
    userSelectCancel: function() {
        //this.users = this.oldUsers;

        this.revertGroup();

        this.groupSelectorOpen = false;
    },

    changeGrant(event) {
        if (event.target.value === "admin") {
            this.notSelect = true;
        } else {
            this.notSelect = false;
        }
    },

    changeSelectGrant: function(event) {

        this.selectGrant = event.target.value;

        if (this.selectGrant !== "admin") {
            this.groups.forEach((val, i) => {
                val.selected = false;
            });
        } else {
            this.groups.forEach((val, i) => {
                val.selected = true;
            });
        }

    },

    /**
     * グループユーザ状態を保存
     */
    storeGroup: function() {
        this.oldGroups = [];

        var clone = Vue.ELearning.Util.cloneObject(this.groups);

        for (var key in clone) {
            this.oldGroups.push(clone[key]);
        }
    },

    /**
     * グループユーザー情報を、ダイアログ開前(store以前)の状態に巻き戻す
     */
    revertGroup: function() {
        this.groups = [];

        for (var key in this.oldGroups) {
            this.groups.push(this.oldGroups[key]);
        }
    },

    back: function() {
        this.$router.push("/admin/users");
    },

    assignedGroupSort: function() {
        console.log("sort");
        if (this.sortOrderGroup == 'asc') {
            this.sortOrderGroup = 'desc'
        } else {
            this.sortOrderGroup = 'asc'
        }

        var userId = this.$route.params.userId;
        if (userId > 0) {
            var handlerGroup = Vue.ELearning.Assignment.getAllGroupsByUser(userId, 'group_name', this.sortOrderGroup);
            handlerGroup.then((response) => {
                if (response.data.status_number == 200) {
                    this.groups = [];
                    var groups = response.data.content;

                    //console.log("groups:", groups);

                    for (var key in groups) {
                        var group = groups[key];

                        this.groups.push({
                            groupId: group["group_id"],
                            groupName: group["group_name"],
                            selected: group["selected"] == 0 ? false : true
                        });
                    }
                    //console.log("groups:", this.groups);
                } else {
                    throw new Error("グループ情報の取得に失敗しました", response);
                }
            }).catch((res) => {
                console.error(res);
            });
        } else {
            var handler = Vue.ELearning.Group.getAll('group_name', this.sortOrderGroup);
            handler.then((res) => {
                if (res.data.status_number == 200) {
                    var content = res.data.content;
                    //console.log(content);
                    this.groups = [];
                    for (var key in content) {
                        var item = content[key];

                        this.groups.push({
                            groupId: item["group_id"],
                            groupName: item["group_name"],
                            selected: false
                        });
                    }

                }
            }).catch((res) => {
                console.error(res);
            });
        }


    },

    update_furigana(input) {
        this.history.push(input.target.value);
        this.userNameKana = japanese.katakanize(historykana(this.history));
    }

};

logic.computed = {
    hasSelectedGroup() {
        return !this.groups.every(group => !group.selected);
    }

};

logic.data = function() {
    return {
        'groupSelectorOpen': false,

        userId: '',
        userName: '',
        userNameKana: '',
        orgName: '',
        newMailAddress: '',
        mailAddress: '',
        password: '',
        grant: 'viewer',
        loginFailed: 0,

        errUserName: false,
        errMailAddress: false,
        errPassword: false,

        userNameErrors: [],
        mailAddressErrors: [],
        passwordErrors: [],

        groups: [],
        oldGroups: [],


        sortOrderGroup: 'asc',
        title: 'ユーザー登録',

        notSelect: false,

        history: [],

    };
};

export default logic;