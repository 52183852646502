var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _vm.order == "desc" ? _c("span", [_c("arrow-up")], 1) : _vm._e(),
    _vm._v(" "),
    _vm.order == "asc" ? _c("span", [_c("arrow-down")], 1) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }