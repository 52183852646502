var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.$store.state.isLoggedIn
        ? _c("header", [
            _c("div", { staticClass: "container pt10 pb10" }, [
              _c("div", { staticClass: "flex_nw" }, [
                _c("h1", { staticClass: "logo" }, [
                  _c("a", { attrs: { href: _vm.$libs.url("") } }, [
                    _vm._v("\n                みそシル教室\n                ")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "logins flex_nw" }, [
                  _c("a", { attrs: { href: _vm.$libs.url("login") } }, [
                    _vm._v("ログイン")
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "register",
                      attrs: { href: _vm.$libs.url("register") }
                    },
                    [_vm._v("無料登録")]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$store.state.isLoggedIn
        ? _c("header", [
            _c("div", { staticClass: "container pt0 pb0" }, [
              _c("div", { staticClass: "flex_nw" }, [
                _c("div", { staticClass: "left back" }, [
                  _vm.showBack
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.onBack.apply(null, arguments)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-angle-left" })]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("h1", [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "icon",
                      attrs: { href: _vm.$libs.url("todo") }
                    },
                    [
                      _c("i", { staticClass: "fas fa-check-circle" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("やること")]),
                      _vm._v(" "),
                      _vm.$store.state.appInfo &&
                      _vm.$store.state.appInfo.todoCount > 0
                        ? _c("span", { staticClass: "num" }, [
                            _vm._v(_vm._s(_vm.$store.state.appInfo.todoCount))
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "main",
        {
          class: [
            _vm.mainClass,
            { "is-loggedin": _vm.$store.state.isLoggedIn }
          ],
          attrs: { id: "main" }
        },
        [_vm._t("body")],
        2
      ),
      _vm._v(" "),
      _vm.$store.state.isLoggedIn
        ? _c("tabbar-component", {
            scopedSlots: _vm._u(
              [
                {
                  key: "tabaction",
                  fn: function() {
                    return [_vm._t("action")]
                  },
                  proxy: true
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.$store.state.isLoggedIn ? _c("footer-component") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }