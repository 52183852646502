<template>
    <div class="multi-modal">
        <template v-for="(currentParent, idx) in currentParents">
            <div
                v-if="getChildren(currentParent).length > 0"
                :key="idx"
                class="modal-cat"
                v-bind:style="{ color: parentColor(currentParent) }"
            >
                <label v-if="useParentCheck">
                    <input
                        type="checkbox"
                        v-model="currentParent.selected"
                        class="checkbox01-input"
                        disabled
                    />
                    <span
                        class="checkbox01-parts"
                        v-bind:class="{ 'has-selected': hasSelectedChilds(currentParent) }"
                        @click="onCheckParentStateChange(currentParent)"
                        v-text="getParentValue(currentParent)"
                    />
                </label>
                <span v-else v-text="getParentValue(currentParent)" />

                <img
                    v-if="!currentParent.isOpen"
                    src="/images/ico-plus-gray.png"
                    alt=""
                    class="icon-toggle"
                    @click="currentParent.isOpen = !currentParent.isOpen"
                />
                <img
                    v-else
                    src="/images/ico-minus-gray.png"
                    alt=""
                    class="icon-toggle"
                    @click="currentParent.isOpen = !currentParent.isOpen"
                />
            </div>

            <div
                v-if="getChildren(currentParent).length > 0"
                v-show="currentParent.isOpen"
                :key="getChildrenIndex(idx)"
                class="tasks"
            >
                <template v-for="(child, cIdx) in getChildren(currentParent)">
                    <template v-if="isSelectionMode">
                        <div
                            :key="cIdx"
                            class="task-body"
                            v-bind:class="{
                                'last-l': isBottomChildLeft(
                                    currentParent,
                                    cIdx,
                                ),
                                'last-r': isBottomChildRight(
                                    currentParent,
                                    cIdx,
                                ),
                            }"
                            v-bind:style="{
                                color: childColor(child),
                                'font-weight': childWeight(child),
                            }"
                        >
                            <label>
                                <input
                                    type="checkbox"
                                    v-model="child.selected"
                                    class="checkbox01-input"
                                    disabled
                                />
                                <span
                                    class="checkbox01-parts"
                                    @click="
                                        onCheckStateChange(child, currentParent)
                                    "
                                    >{{ getChildValue(child) }}</span
                                >
                            </label>
                        </div>
                        <div
                            v-if="isOddLast(currentParent, cIdx)"
                            :key="getLastIndex(cIdx)"
                            class="task-body last-r"
                        >
                            &nbsp;
                        </div>
                    </template>
                    <template v-else>
                        <div
                            :key="cIdx"
                            class="task-body"
                            v-bind:class="{
                                'last-l': isBottomChildLeft(
                                    currentParent,
                                    cIdx,
                                ),
                                'last-r': isBottomChildRight(
                                    currentParent,
                                    cIdx,
                                ),
                            }"
                            v-bind:style="{
                                color: childColor(child),
                                'font-weight': childWeight(child),
                            }"
                        >
                            {{ getChildValue(child) }}
                        </div>
                        <div
                            v-if="isOddLast(currentParent, cIdx)"
                            :key="getLastIndex(cIdx)"
                            class="task-body last-r"
                        >
                            &nbsp;
                        </div>
                    </template>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        currentParents: {
            type: Array,
            default: () => [],
        },
        isSelectionMode: {
            type: Boolean,
            default: true,
        },
        parentItemName: {
            type: String,
            required: true,
        },
        childColumnName: {
            type: String,
            required: true,
        },
        childItemName: {
            type: String,
            required: true,
        },
        useParentCheck: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        // 取得したデータの子がすべて選択状態なら親も選択状態にする
        this.currentParents.forEach(parent => {
            parent.selected = this.getChildren(parent).every(c => c.selected);
        });
    },
    methods: {
        isOddLast(parent, idx) {
            const size = this.getChildSize(parent);
            return size - 1 == idx && size % 2 == 1;
        },
        getParentValue(parent) {
            return parent[this.parentItemName];
        },
        getChildValue(child) {
            return child[this.childItemName];
        },
        getChildren(parent) {
            return parent[this.childColumnName];
        },
        getChildSize(parent) {
            return this.getChildren(parent).length;
        },
        isBottomChildLeft(parent, index) {
            const size = this.getChildSize(parent);

            if (size % 2 == 0) {
                return size - 2 == index;
            }
            return size - 1 == index;
        },
        isBottomChildRight(parent, index) {
            const size = this.getChildSize(parent);

            if (size % 2 == 0) {
                return size - 1 == index;
            }
            return false;
        },
        parentColor(parentItem) {
            return 'black';
        },
        childColor(child) {
            return child.selected ? '#4FBE2B' : 'black';
        },
        childWeight(child) {
            return 'normal';
        },
        getChildrenIndex(idx) {
            return `childrens-${idx}`;
        },
        getLastIndex(idx) {
            return `last-${idx}`;
        },

        onCheckParentStateChange(parent) {

            const children = this.getChildren(parent);

            //すべての子の閲覧権限が「全てのユーザー」ならば、変更不可
            if( children.every( c => c.references == 1) ){
                this.$dialog.message("すべてのユーザーに公開されているタスクです。");
                return;
            }

            parent.selected = !parent.selected;

            children.forEach(c => {
                if(c.references != 1){ //閲覧権限がグループ指定されていないものは変更不可とする
                    c.selected = parent.selected;
                }
            });

            //parent.selected = this.getChildren(parent).every(c => c.selected);

            // 親が選択状態にされた場合は子を開く →　常に開く　に変更
            //if (parent.selected) {
                parent.isOpen = true;
            //}
        },
        onCheckStateChange(child, parent) {

            //閲覧権限がグループ指定されていないものは変更不可とする
            if(child.references == 1){
                this.$dialog.message("すべてのユーザーに公開されているタスクです。");
                return; 
            }

            child.selected = !child.selected;

            // 親もチェック可能な場合、子の選択状態が解除された時点で親の選択状態も解除する
            if (this.useParentCheck && parent.selected !== child.selected) {
                // 子の選択状態が解除された場合は親の選択状態も解除
                if (!child.selected) {
                    parent.selected = false;
                } else {
                    // 子が選択された場合、子全体が選択されているかどうかをチェックし、選択されていれば親も選択状態にする
                    parent.selected = this.getChildren(parent).every(
                        c => c.selected,
                    );
                }
            }
        },

        hasSelectedChilds(parent){
            //親が選択済であればfalse（子がすべて選択されている）
            if(parent.selected){
                return false;
            }else{
                //子がすべて未選択済であればfalse　１つでも選択済みがあればtrue
                return !this.getChildren(parent).every(c => !c.selected); 
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.icon-toggle {
    cursor: pointer;
}
</style>
