var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "modal" },
      on: {
        "after-leave": function($event) {
          return _vm.$emit("afterLeave")
        }
      }
    },
    [
      _c("div", { staticClass: "modal-mask" }, [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "modal-container",
              style: _vm.modalContainerStyle,
              attrs: { id: _vm.currentContainerId }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isDelete && !_vm.isPublish && !_vm.isSave,
                      expression: "!isDelete && !isPublish && !isSave"
                    }
                  ],
                  staticClass: "modal-header",
                  staticStyle: { "border-bottom": "0" },
                  attrs: { id: _vm.currentHeaderId }
                },
                [_vm._t("header")],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  style: _vm.bodyStyle,
                  attrs: { id: _vm.currentBodyId }
                },
                [
                  _c(
                    "h1",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isDelete,
                          expression: "isDelete"
                        }
                      ],
                      staticStyle: { "text-align": "center" },
                      attrs: { clss: "tc" }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/images/ico-q.png", alt: "" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h1",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPublish,
                          expression: "isPublish"
                        }
                      ],
                      staticStyle: { "text-align": "center" },
                      attrs: { clss: "tc" }
                    },
                    [
                      _c("img", {
                        attrs: { src: "/images/ico-q-green.png", alt: "" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("body", function() {
                    return [
                      _vm._v(
                        "\n                        default body\n                    "
                      )
                    ]
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal-footer",
                  staticStyle: { "border-top": "0" },
                  attrs: { id: _vm.currentFooterId }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row", staticStyle: { width: "100%" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "col-md-12",
                          staticStyle: {
                            "margin-left": "0",
                            "padding-left": "0"
                          }
                        },
                        [
                          _vm._t("footer", function() {
                            return [
                              _vm.isQuestionMode &&
                              !_vm.isDelete &&
                              !_vm.isPublish
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { name: "footer" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-cancel",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalNo")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.noText))]
                                      ),
                                      _vm._v(
                                        "  \n                                    "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-login",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalYes")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.yesText))]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isDelete
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { name: "footer" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-cancel",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalNo")
                                            }
                                          }
                                        },
                                        [_vm._v("キャンセル")]
                                      ),
                                      _vm._v(
                                        "  \n                                    "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-red",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalYes")
                                            }
                                          }
                                        },
                                        [_vm._v("削除")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isPublish
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { name: "footer" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-cancel",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalNo")
                                            }
                                          }
                                        },
                                        [_vm._v("キャンセル")]
                                      ),
                                      _vm._v(
                                        "  \n                                    "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-red",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalYes")
                                            }
                                          }
                                        },
                                        [_vm._v("書き出し")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isSave
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { name: "footer" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button basic is-cancel",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalNo")
                                            }
                                          }
                                        },
                                        [_vm._v("キャンセル")]
                                      ),
                                      _vm._v(
                                        "  \n                                    "
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button basic is-login save",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalYes")
                                            }
                                          }
                                        },
                                        [_vm._v("保存")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isChange
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { name: "footer" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "button basic is-login save",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalYes")
                                            }
                                          }
                                        },
                                        [_vm._v("変更")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isQuestionMode &&
                              !_vm.isDelete &&
                              !_vm.isPublish &&
                              !_vm.isSave &&
                              !_vm.isChange
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "text-align": "center" },
                                      attrs: { name: "footer" }
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "button is-login",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit("modalOk")
                                            }
                                          }
                                        },
                                        [_vm._v("OK")]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      ),
                      _c("br")
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }