var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "embed-responsive embed-responsive-16by9",
        staticStyle: { "max-width": "845px" }
      },
      [
        _c("video", {
          directives: [
            {
              name: "video",
              rawName: "v-video",
              value: _vm.play,
              expression: "play"
            }
          ],
          staticClass: "embed-responsive-item",
          staticStyle: { cursor: "pointer" },
          attrs: {
            id: "video_player",
            controlsList: "nodownload",
            src: _vm.video_src,
            controls: "",
            muted: "",
            playsinline: "",
            preload: "metadata"
          },
          domProps: { muted: true },
          on: {
            play: _vm.on_video_play,
            contextmenu: function($event) {
              $event.preventDefault()
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }