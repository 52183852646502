<template>
    <modal-component v-if="isShow" :title="title" :view-plain="true" @ok="onOk">
        <div slot="body">
            <p>{{ message }}</p>
        </div>
    </modal-component>
</template>
<script>
    import ModalComponent from './ModalComponent';

    export default {
        components: {
            ModalComponent,
        },

        data: function() {
            return {
                title: "",
                message: "",
                isShow: true,
            };
        },

        methods: {
            show: function ()
            {
                this.$mount();
                document.body.appendChild( this.$el );
            },

            destroy: function ()
            {
                document.body.removeChild( this.$el );
                this.$destroy();
            },

            onOk: function ()
            {
                this.isShow = false;
            },

            onAfterLeave: function ()
            {
                this.destroy();
            }
        }
    }
</script>
