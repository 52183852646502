    <!--
    【西鉄エムテック様　対応版デザイン】
    レイアウト用コンポーネント
-->
<template>

<div>

    <div class="w-content">

        <!-- ヘッダーコントロール -->
        <header style="padding-top:0;padding-bottom:0">
            <nav aria-label="main navigation" class="navbar" role="navigation" style="margin-right:0;padding-right:0;">
                <div class="navbar-brand">
                    <router-link class="navbar-item" style="text-decoration: none;" to="/user/categories" v-if="!noImageNav">
                        <img alt="" class="navbar-logo" v-bind:src="
                                    $store.state.documentRoot +
                                        '/images/logo-new.png'
                                " />&nbsp;
                        <span class="logo"></span>
                    </router-link>
                    <span class="navbar-item" v-if="noImageNav">
                        <img alt="" class="navbar-logo" v-bind:src="
                                    $store.state.documentRoot +
                                        '/images/logo-new.png'
                                " />&nbsp;
                        <span class="logo" style="color:white;" v-if="noImageNav"></span>
                    </span>
                </div>

                <div class="navbar-menu" id="navbarBasic">
                    <div class="navbar-end">
                        <div class="navbar-item">
                            <!-- ナビゲーションボタン -->
                            <div v-if="isOnline" class="buttons">
                                <router-link class="link-header link-home" id="" style="" to="/user/categories" v-show="isShowHome">
                                    ホーム
                                </router-link>

                                <router-link class="link-header link-log" id="" style="" to="/admin/menu" v-show="isShowAdmin && currentGrant>1">
                                    設定/登録
                                </router-link>

                                <router-link class="link-header link-log-history" id="" style="" :to="{ path: log_top_page_path }" v-show="isShowLog">
                                    履歴
                                </router-link>

                                <div class="link-header login-username" v-show="isShowLogout">
                                    <a href="#" v-on:click.prevent="toggleMenu">{{ loginUserName }}</a>
                                    <div class="usermenu" v-if="isOpenMenu">
                                        <div class="usermenu-wrap container" @click="toggleMenu"></div>
                                        <div class="usermenu-inner">
                                            <div class="usermenu-list-wrapper overflow-auto">
                                                <ul>
                                                    <li><router-link to="/user/download">ダウンロードしたタスク一覧</router-link></li>
                                                    <li><a href="#" @click.prevent="showModalConfirmLogout=true; isOpenMenu=false" class="link-logout" id="logout" style="" v-show="isShowLogout">ログアウト</a></li>
                                                    <li><a href="#" @click.prevent="showModalChangePassword=true; isOpenMenu=false" class="link-logout" id="password" style="" v-show="isShowLogout">パスワード変更</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="network_status" v-show="isShowHome">
                                    <img src="/images/ico_online.svg" width="34px" height="auto">
                                </div>

                                <Notification v-if="showNotice" />
                            </div>
                            <div v-else class="buttons">
                                <router-link class="link-header link-home" id="" style="" to="/user/categories">
                                   ホーム
                                </router-link>

                                <div class="link-header login-username" v-show="isShowLogout">
                                   <a href="#" v-on:click.self="toggleMenu">{{ loginUserName }}</a>
                                    <div class="usermenu" v-if="isOpenMenu">
                                        <div class="usermenu-wrap container" @click="toggleMenu"></div>
                                        <div class="usermenu-inner">
                                            <div class="usermenu-list-wrapper overflow-auto">
                                                <ul>
                                                    <li><router-link to="/user/download">ダウンロードしたタスク一覧</router-link></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="network_status">
                                    <a @click.prevent="reload"><img src="/images/ico_offline.svg" width="34px" height="auto"></a>
                                </div>

                                <Notification v-if="showNotice" />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>

        <!-- ナビバー -->
        <div class="bread">
            <nav aria-label="main navigation" class="navbar" role="navigation">
                <div class="navbar-brand">
                    <div v-bind:class="{
                                'nav-back': !isChecklistInCurrent,
                                'nav-back-checklist': isChecklistInCurrent,
                            }" v-bind:style="{ navBackStyle }" v-show="isShowBack">
                        <a @click.prevent="onBack" href style="text-decoration: none;">
                            <img alt v-bind:src="
                                        $store.state.documentRoot +
                                            '/images/ico-lw.png'
                                    " width="10" />&nbsp;Back
                        </a>
                    </div>
                    <h1 class="nav-msg">
                        <!-- 画面タイトル -->
                        <slot name="nav-message"></slot>
                    </h1>
                </div>
                <div class="navbar-menu"></div>

                <div class="navbar-end">
                    <slot name="nav-ctrl"></slot>
                </div>

            <div class="network_status pc-hidden" v-show="isOnline">
                <img src="/images/sp/ico_online.svg" width="34px" height="auto">
            </div>

            <div class="network_status pc-hidden" v-show="!isOnline">
                <a @click.prevent="reload"><img src="/images/sp/ico_offline.svg" width="34px" height="auto"></a>
            </div>

            </nav>
            <div id="nav-var-fill" v-bind:style="{ height: fillHeight + 'px' }" v-show="showFill"></div>

        </div>

        <section style :class="wrapperClasses" v-bind:style="mainColStyle" v-if="!simpleLayout">
            <!-- メインコンテンツ -->
            <slot name="body"></slot>
        </section>

        <section class="mainWindow" v-if="simpleLayout">
            <!-- メインコンテンツ -->
            <slot name="body"></slot>
        </section>

    </div>

    <footer>
        <div class="container">
        <div>
        <div class="cache-volume" v-show="isShowUser">
          <p class="cache-volume-text">ダウンロード容量の目安</p>
          <p class="cache-volume-volume">{{ localDiskUsage }} / {{ localDiskTotal }}<span>GB</span></p>
          <!--
            <div class="bar-base">
                <div class="bar"
                    v-bind:style="{width: currentFileSizeSumRatio + '%'}"
                ></div>
            </div>
            <div>使用中：{{ currentFileSizeSum }}GB/{{ maxDiskSize() }}GB</div>
            -->
        </div>
        <div class="copyright">&copy;ITP</div>
        </div>
        </div>

        <!-- SP >> -->
        <SpFooterComponent :show-home="isShowHome" :show-logout="isShowLogout" :show-notice="showNotice" :login-user-name="loginUserName" :local-disk-usage="localDiskUsage" :local-disk-total="localDiskTotal"
        @logout="showModalConfirmLogout=true" @change-password="showModalChangePassword=true"/>
        <!-- << SP -->
    </footer>

    <modal-component v-if="showModalConfirmLogout" is-quest="true" width="600" @modalYes="doLogout" @modalNo="showModalConfirmLogout = false">
        <h3 slot="body" style="text-align: center;">
            <p class="tc pb2em" style="margin-top:0.5em;">
                <strong>ログアウトしますか？</strong>
            </p>
        </h3>
    </modal-component>

     <!--パスワード変更モーダル-->
    <ChangePasswordComponent v-if="showModalChangePassword || passwordChangeRequired" @saved="showModalChangePassword=false" @cancel="showModalChangePassword=false" :force-change="passwordChangeRequired">
    </ChangePasswordComponent>
</div>

</template>

<script>
import Notification from 'Components/commons/NotificationComponent.vue';
import ModalComponent from 'Components/commons/ModalComponent.vue';
import ChangePasswordComponent from 'Components/commons/ChangePasswordModalComponent.vue';
import SpFooterComponent from 'Components/commons/SpFooterComponent.vue';

export default {
    components: {
        Notification,
        ModalComponent,
        ChangePasswordComponent,
        SpFooterComponent
    },

    mounted: function() {
        var route = this.$route;

        if (route.path.startsWith('/user/checklist/')) {
            this.isTopMarginNone = true;
        } else {
            this.isTopMarginNone = false;
        }

        if (this.simpleLayout || route.path.startsWith('/user/categories')) {
            this.$nextTick(function() {
                jQuery('body').css('backgroundColor', 'white');
            });
        } else {
            this.$nextTick(function() {
                jQuery('body').css('backgroundColor', '#EEEEEE');
            });
        }
    },
    methods: {
        onBack: function() {
            this.$emit('on-nav-back');
        },

        doLogout: function() {

            Vue.ELearning.Authenticatior.clear();

            // ログアウト処理
            this.$store.commit("logout", {});
            this.$router.push({
                path: '/login'
            });
        },

        maxNumUser() {
            return this.$store.state.appInfo.maxNumUser; //process.env.MIX_MAX_NUM_USER;
        },

        maxDiskSize() {
            return this.$store.state.appInfo.maxDiskSize;// process.env.MIX_MAX_DISK_SIZE;
        },

        toggleMenu() {
            this.isOpenMenu = !this.isOpenMenu
        },

        reload: function () {
            this.$router.go({path: this.$router.currentRoute.path, force: true});
        }
    },

    data: function() {
        return {
            isOpenMenu: false,
            isTopMarginNone: false,
            showModalConfirmLogout: false,
            showModalChangePassword: false,
        };
    },

    computed: {
        isChecklist() {
            return (
                this.$route.path.startsWith('/admin/checklist/') ||
                this.$route.path.startsWith('/admin/preview') ||
                this.$route.path.startsWith('/design/checklist-edit/')
            );
        },

        /**
         * 現在のページが閲覧ログページかどうかを返す
         *
         * @return {boolean} 現在のページが閲覧ログページであれば true を、そうでなければ false を返す
         */
        isLog() {
            return (
                this.$route.path == '/admin/logs' ||
                this.$route.path.startsWith('/admin/logs/')
            );
        },

        mainColStyle: function() {
            var style = {};
            if (this.isTopMarginNone) {
                style['padding-top'] = '0 !important';
                style['padding-bottom'] = '0 !important';
            }

            return style;
        },
        isChecklistInCurrent: function() {
            var route = this.$route;
            var isCheck =
                route.path.startsWith('/user/checklist/') ||
                route.path.startsWith('/admin/checklist/');
            return isCheck;
        },
        navBackStyle: function() {
            var route = this.$route;
            var isChecklist =
                route.path.startsWith('/user/checklist/') ||
                route.path.startsWith('/admin/checklist/');

            var style = {};
            if (!isChecklist) {
                style['padding'] = '15px 10px 0 20px !important';
            } else {
                style['padding'] = '15px 10px 0 20px !important';
            }
            // console.log(style);
            return style;
        },
        currentGrant: function() {
            //console.log("grant", this.$store.state.userInfo);
            return this.$store.state.userInfo.grant;
        },
        isShowHome: function() {
            if (this.showHome == undefined) {
                return true;
            } else {
                if (this.showHome == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowAdmin: function() {
            //console.log("showAdmin", this.showAdmin);
            if (this.showAdmin == undefined) {
                return true;
            } else {
                if (this.showAdmin == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowLog: function() {
            if (this.showLog == undefined) {
                return true;
            } else {
                if (this.showLog == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowUser: function() {
            if (this.showUser == undefined) {
                return true;
            } else {
                if (this.showUser == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isShowLogout: function() {
            if (this.showLogout == undefined) {
                return true;
            } else {
                if (this.showLogout == 'true') {
                    return true;
                } else {
                    return false;
                }
            }
        },

        isShowBack: function() {
            if (this.showBack == undefined) {
                return true;
            } else {
                if (this.showBack == 'true' || this.showBack === true) {
                    return true;
                } else {
                    return false;
                }
            }
        },

        /**
         * コンポーネントが生成する <section> に付加する class
         *
         * mainCol      : 通常ページ用
         * mainCol-2    : チェックリスト・閲覧ログページ用 ( サイドナビ有 )
         *
         * @todo もっと綺麗な方法で実装する
         *
         * @return {string[]} class の配列
         */
        wrapperClasses() {
            if ( this.isChecklist || this.isLog ) {
                return [ 'mainCol-2' ]
            }

            return [ 'mainCol' ];
        },

        currentNumUser() {
            return this.$store.state.appInfo.userSum;
        },

        loginUserName() {
            const userName = this.$store.state.userInfo.userName;
            return userName &&　userName.length > 12? userName.slice(0,12)+"…" : userName;
        },

        currentFileSizeSum() {
            return this.$store.state.fileSizeSum;
        },

        currentFileSizeSumRatio() {
            var currentFileSizeSumRatio = Math.round(this.$store.state.fileSizeSum / this.maxDiskSize() * 100);
            return currentFileSizeSumRatio;
        },

        isOnline() {
            return Vue.Download.isOnline();
        },

        localDiskTotal() {
            return this.$store.state.storageEstimate ? ( this.$store.state.storageEstimate.quota / 1024 / 1024 / 1024 ).toFixed( 2 ) : "-";
        },

        localDiskUsage() {
            return this.$store.state.storageEstimate ? ( this.$store.state.storageEstimate.usage / 1024 / 1024 / 1024 ).toFixed( 2 ) : "-";
        },

        passwordChangeRequired() {
            if(this.$store.state.userInfo && this.$store.state.userInfo.initialPassword){
                return (this.$store.state.userInfo.initialPassword == 1);
            }
            return false;
        },
        /**
         * 閲覧ログのリンク先パス
         */
        log_top_page_path: () => Vue.ELearning.Logs.getTopPagePath(),
    },

    watch: {
        // currentNumUser(newNum, oldNum) {
        //     // console.log('newNum', newNum);
        // },
        //
        // currentFileSizeSum(newSize, oldSize) {
        //
        // },
    },

    props: {
        showBack: String|Boolean,
        showHome: String,
        showAdmin: String,
        showLog: String,
        showUser: String,
        showLogout: String,

        showNotice: {
            type: Boolean,
            default: true,
        },

        showFill: {
            type: Boolean,
            default: function() {
                return false;
            },
        },
        fillHeight: {
            type: Number,
            default: function() {
                return 0;
            },
        },
        noImageNav: {
            type: Boolean,
            default: function() {
                return false;
            },
        },
        simpleLayout: {
            type: Boolean,
            default: function() {
                return false;
            },
        },
    },
};
</script>

<style scoped lang="scss">
.buttons {
    .link-header {
        margin-right: 20px;
        color: #333;
        font-size: 14px;
        padding-left: 18px;
        line-height: 1.0em;
        img {
            vertical-align: baseline;
        }
        a{
            color: #333;
        }
    }

    .link-home{
        padding-left: 15px;        
        background: url(~Image/icon_home_small.svg) no-repeat left center/12px auto;
    }
    .link-log{
        background: url(~Image/icon_log_small.svg) no-repeat left center/14px auto;
    }
    .link-log-history{
        background: url(~Image/icon_log_history_small.svg) no-repeat left center/14px auto;
    }

    .login-username {
        position: relative;
        margin-right: 20px;
        color: #333;
        font-size: 14px;
        padding-left: 15px;        
        background: url(~Image/icon_user.svg) no-repeat left center/11px auto;   
        &:hover {
            cursor: pointer;
        }
    }
    .usermenu {
        &-inner {
            position :absolute;
            top: 43px;
            left: -230px;
            width: 280px;
            margin: 0 70px 0 auto;
            max-height: 600px;
            background: #fff;
            z-index: 1040;
            filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.2));
            &:before {
                content: "";
                position: absolute;
                top: -36px;
                right: 27px;
                border: 18px solid transparent;
                border-bottom: 18px solid #fff;
            }
        }

        &-list-wrapper {
            height:calc(100% - 40px);
            ul {
                li{
                    border-bottom: 1px solid #ccc;
                    a{
                        display: block;
                        padding: 1.0em 1.25em;
                        color: #333;
                        font-size: 15px;
                        text-decoration: none;
                        &:hover{
                            background: #E8F8E2;
                        }
                    }
                    &:last-of-type{
                        border-bottom: none;
                    }
                }
            }
        }

        &-wrap {
            background: transparent;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1030;
            @media screen and (min-width: 1088px){
                max-width: 100%;
            }
            @media (min-width: 992px){
                max-width: 100%;
            }
        }
    }
    .network_status{
        margin-right: 15px;
        position: relative;
        img{
            margin-top: -3px;
        }
        a:hover{
            opacity: 0.6;
        }
    }
}
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    background: #ddd;

    .container{
        &>div{
            position: relative;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #ccc;
            color: #666;
        }
    }
    .cache-volume {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 10px;
        left: 0px;
        .cache-volume-text {
            font-size: 16px;
            line-height: 25px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 28px;
            &::before {
                content: "";
                display: inline-block;
                /*background: url(/images/ico-cache.png) 0 0 no-repeat;
                background-size: contain;
                background-position: center;
                width: 30px;
                height: 28px;
                vertical-align: top;
                margin-right: 7px;
                margin-top: 0;*/
            }
        }
        .cache-volume-volume {
            font-size: 22px;
            font-weight: bold;
            display: inline-block;
            vertical-align: middle;
            span {
                font-size: 15px;
                margin-left: 10px;
            }
        }
        .bar-base {
            width: 180px;
            height: 6px;
            margin-right: 15px;
            border-radius: 1px;
            background: #fff;
            .bar {
                height: 6px;
                background: #4fbe2b;
            }
        }
    }
    .copyright {
        font-size: 14px;
        text-align: center;
        padding-top: 3px;
    }
}
</style>
