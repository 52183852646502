<template>
    <div
        class="flow"
        v-bind:class="{
            'bg-green': item.selected,
        }"
    >
        <label class="procedure-check">
            <input
                type="checkbox"
                class="checkbox01-input"
                v-model="item.selected"
                @change.stop.prevent="change"
            />
            <span class="checkbox01-parts" v-text="name" />
        </label>
    </div>
</template>

<script>
export default {
    props: {
        parentIndex: {
            type: Number,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        number() {
            return `${this.parentIndex + 1}-${this.index + 1}`;
        },
        name() {
            return `${this.number}. ${this.item.workProcedureName}`;
        },
    },
    methods: {
        change($event) {
            this.$emit('check', this.item.uuid, $event.target.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.procedure-check {
    padding-left: 1.5em;
    cursor: pointer;
}
</style>
