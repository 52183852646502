<template>
<div>
  <div class="show-more-button" :class="color" v-if="! value"><a href="#" v-on:click.stop.prevent="on_more_click"><span class="icon-more">More</span></a></div>
  <div class="show-more-button back" :class="color" v-if="is_more_executed"><a href="#" v-on:click.stop.prevent="on_back_click"><span class="icon-more">Back</span></a></div>
</div>
</template>

<script>

export default {
  data: () => {
    return {
      is_more_executed: false,          // More ボタンが 1 度押されたかどうか
    }
  },

  props: {
    value: Boolean,                     // 全て表示させるかどうか
    list: Array,                        // 対象の一覧
    limit: Number,                      // 一部だけ表示させる場合の数
    color: String,                      // 色指定用クラス名
  },

  watch: {

    list: function ( value )
    {
      if ( ! this.is_more_executed )
      {
        this.$emit( "input", value.length <= this.limit );
      }
      
      // alert( value.length + ":" + this.limit );
    }
  },

  computed: {
    showBack: function(){
      return list.length >= this.limit
    }
  },

  methods: {
    on_more_click: function ()
    {
      this.$emit( "input", true );
      this.is_more_executed = true;
    },
    on_back_click: function ()
    {
      this.$emit( "input", false );
      this.is_more_executed = false;
    }
  }
};

</script>
<style scoped lang="scss">
</style>
