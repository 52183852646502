<template>
      <div class="thumbnail">
          <p v-if="!isVideo || hasThumbnail" :style="thumbnailImage()"></p>
          <div v-if="isVideo && !hasThumbnail" class="video">
              <video ref="videoElement" :src="video.video_path" mute style="display:none" webkit-playsinline playsinline autoplay></video>
              <img :src="videoThumbnail" />
          </div>
      </div>
</template>

<script>
export default {

  components: {
  },
  props: {
        video: Object,
  },
  data: function () {
    return {
      videoThumbnail:null,
    };
  },
  mounted: function () {
    if(this.isVideo || !this.hasThumbnail){
        this.create()
    }
  },

  computed: {
   isVideo: function(){
      var isVideo = false;

      if(this.video &&　this.video.video_path){
        isVideo = isVideo || this.video.video_path.endsWith('.mp4');
        isVideo = isVideo || this.video.video_path.endsWith('.MP4');
        isVideo = isVideo || this.video.video_path.endsWith('.mov');
        isVideo = isVideo || this.video.video_path.endsWith('.MOV');
      }
      return isVideo;
    },
    hasThumbnail: function(){
      if(this.video &&　this.video.thumbnail_path){
        return true
      }
      return false
    },
  },
  methods: {
    create: function(){
      var video = this.$refs.videoElement;
      if(video){
        var self = this

        //video.currentTime = 0.5;
        video.onseeked = function(){
          var canvas = document.createElement('canvas');
        
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          console.log("video", video.videoWidth);
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        self.videoThumbnail = canvas.toDataURL('image/png')
        video.pause();
      };
      video.onloadedmetadata = function(){
        console.log("onloadedmetadata", video.videoWidth);
        //video.play();
        video.currentTime = 0.5;
      };

      video.oncanplay = function(){
        console.log("oncanplay", video.videoWidth);
        //video.play();
        video.currentTime = 0.5;
        video.oncanplay = null;
      };

      }

  },
    thumbnailImage: function () {
      if(this.video &&　this.video.thumbnail_path){
        return "background: url(" + this.video.thumbnail_path + ") center center / cover";
      }
      else if(this.video &&　this.video.video_path){
        return "background: url(" + this.video.video_path + ") center center / cover";
      }
      else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.thumbnail{
  height: 80px;
  overflow: hidden;
  text-align: center;
}
.video{
  transform: translateX(50%);
  img{
    height: 80px;
    width: auto;
    transform: translateX(-50%);
  }
}
</style>
