<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" title="レッスン" main-class="lesson_page">
        <template v-slot:body v-if="lesson">
            <section class="lesson_header bg_yellow">
                <div class="container pt0 pb0">
                    <lesson-video-component :video="videoPath" />
                </div>
            </section>
            <section class="bg_yellow">
                <div class="container pt20">
                    <div class="user_teacher flex">
                        <a :href="$libs.url(['teacher',lesson.user_id])">
                           <div class="icon">
                                <p :style="iconImage(lesson.user.image)"></p>
                            </div>
                        </a>
                        <div class="name">
                            <p>講師</p>
                            <a :href="$libs.url(['teacher',lesson.user_id])">
                                {{ lesson.user.display_name }}
                            </a>
                        </div>
                    </div>
                    <div class="description">
                        <div class="categories flex">
                            <div class="cat no_border">{{ partName }}</div>
                            <level-component :level="lesson.level"></level-component>
                        </div>
                        <h1>{{ lesson.title }}</h1>
                        <!--<p>{{ lesson.description }}</p>-->
                    </div>
                    <lesson-description-component :lesson="lesson" :show-toggle-button="false">
                    </lesson-description-component>
                </div>
            </section>
            <section class="purchase" v-if="!$store.state.isLoggedIn || $store.state.userInfo.grant < 2">
                <div class="container pt10 pb10">
                    <div class="flex_nw">
                        <div class="price">
                            <span>レッスン料</span> {{ lesson.price.toLocaleString() }}円
                        </div>
                        <button class="round" @click="showModalPurchase = true">購入する</button>

                    </div>
                </div>
            </section>
            <modal-component v-if="showModalPurchase" title="このレッスンを購入しますか？" :view-selectable="true" @yes="purchase" @no="showModalPurchase = false"/>
            <modal-component v-if="showModalPurchased" title="ご購入ありがとうございます！" :view-plain="true" ok="レッスンへ" @ok="$libs.pushUrl(['lesson',lessonId,'intro'])" />
        </template>

    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../miso/commons/ModalComponent.vue';
    import LessonDescriptionComponent from '../../miso/user/LessonDescriptionComponent.vue';
    import LessonVideoComponent from '../../miso/user/LessonVideoComponent.vue';
    import LevelComponent from '../../miso/commons/LevelComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent,
            LessonDescriptionComponent,
            LessonVideoComponent,            
            LevelComponent,
        },
        props: {
            lessonId: {
                type: Number,
            },
        },
        data: function () {
            return {
                lesson:null,
                showModalPurchase: false,
                showModalPurchased: false,
                deleted:false,
            }
        },
        mounted:function(){
            console.log("lessonId",this.lessonId)
            this.getData()
            /*
            if(this.lesson.purchased){
                this.$libs.pushUrl(['lesson',this.lessonId, 'intro'])
            }
            */
        },

        computed: {
            partName: function(){
                return this.$config.name("parts",this.lesson.part)
            },
            genreName: function(){
                return this.$config.name("genres",this.lesson.genre)
            },
            videoPath: function(){
                if(this.lesson.video && this.lesson.video.video_path){
                    return this.lesson.video.video_path
                }
                return null
            }
        },
        methods:{
            getData: function(){
               // タスク情報取得
                var taskHandler = Vue.ELearning.Task.getTask(this.lessonId);
                taskHandler
                .then(
                    function(res) {
                        var content = res.data.content
                        if (content) {
                            this.lesson = content;

                            //講師で自分のレッスンの場合はイントロへリダイレクト
                            if(this.$store.state.userInfo.grant != 1 && this.lesson.user_id == this.$store.state.userInfo.id){
                                this.$libs.pushUrl(['lesson',this.lessonId,'intro'])
                            }
                            //生徒で購入済みの場合はイントロへリダイレクト
                            if(this.$store.state.userInfo.grant == 1 && this.lesson.purchases.length > 0){
                                this.$libs.pushUrl(['lesson',this.lessonId,'intro'])
                            }
                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },
            search: function(){
            },
            iconImage: function (path) {
                if (path) {
                    return "background: url(" + path + ") center center / cover";
                } else {
                    return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
                }
            },

            purchase: function(){

                Vue.ELearning.Purchase.purchase({task_id: this.lesson.id, type: 1})
                .then(
                    function(res) {
                        if (res.data.status_number == 200) {
                            console.log("purchase", res.data.content);

                            if(res.data.content.result == "NG"){
                                this.$dialog.message("エラーが発生しました");
                            }else{
                                this.showModalPurchase = false;
                                this.showModalPurchased = true;
                            }
                        }else if(res.data.status_number == 401){
                            this.$libs.pushUrl('login')
                        }
                    }.bind(this),
                )
                .catch(function(res) {}.bind(this));
            },
            
            onNaviBack: function(){
                history.back()
            },
        }
    }
</script>
<style lang="scss" scoped>
</style>
