<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true"  title="個人情報設定" main-class="my_page edit">
        <template v-slot:body>
            <section class="user_header bg_yellow">
                <div class="container pt30 pb30">
                    <div class="icon">
                        <p :style="iconImage"></p>
                    </div>
                    <div class="">
                        <label for="file_upload" class="file_label_s">
                            編集
                        <input type="file" id="file_upload" @change="onChangeImage($event)" accept=".png, .jpg, .jpeg, .gif">
                        </label>
                    </div>
                </div>
            </section>

            <section class="bg_yellow">
                <div class="list_box input_box">
                    <h2>ニックネーム</h2>
                    <ul>
                        <li><input type="text" name="displayname" placeholder="" v-model="displayName" :class="{error:errDisplayName}">
                            <span v-if="!displayName">タップして編集</span>
                        </li>
                    </ul>
                    <p v-if="errDisplayName" class="error_description">{{ displayNameErrors.join(" ") }}</p>
                </div>
                <div class="list_box input_box">
                    <h2>名前</h2>
                    <ul>
                        <li><input type="text" name="name" placeholder="" v-model="userName" :class="{error:errUserName}">
                            <span v-if="!userName">タップして編集</span>
                        </li>
                    </ul>
                    <p v-if="errUserName" class="error_description">{{ userNameErrors.join(" ") }}</p>
                </div>
                <div class="list_box input_box">
                    <h2>メールアドレス</h2>
                    <ul>
                        <li><input type="text" name="email" placeholder="" v-model="newEmail" :class="{error:errMailAddress}">
                            <span v-if="!email">タップして編集</span>
                        </li>
                    </ul>
                    <p v-if="errMailAddress" class="error_description">{{ mailAddressErrors.join(" ") }}</p>
                </div>
                <div class="list_box input_box">
                    <h2>ログインパスワード</h2>
                    <ul>
                        <li><input type="password" name="password" placeholder="" v-model="password" :class="{error:errPassword}" autocomplete="off">
                            <span v-if="!password">タップして編集</span>
                        </li>
                    </ul>
                    <p v-if="errPassword" class="error_description">{{ passwordErrors.join(" ") }}</p>
                </div>
                <div class="list_box input_box">
                    <h2>電話番号</h2>
                    <ul>
                        <li><input type="text" name="phone" placeholder="" v-model="phone" :class="{error:errPhone}">
                            <span v-if="!phone">タップして編集</span>
                        </li>
                    </ul>
                    <p v-if="errPhone" class="error_description">{{ phoneErrors.join(" ") }}</p>
                </div>
                <!-- 生徒/講師情報 -->
                <div class="list_box input_box select_box">
                    <h2 v-if="grantId == 2">講師情報</h2>
                    <ul>
                        <li>
                            <span>年齢</span>
                            <select name="age" v-model="age">
                                <template v-for="(item ,index) in $config.ages">
                                    <option :value="item.value" :key="index">{{ item.name }}</option>
                                </template>
                            </select></li>
                        <li>
                            <span>性別</span>
                            <select name="sex" v-model="sex">
                                <template v-for="(item ,index) in $config.sex">
                                    <option :value="item.value" :key="index">{{ item.name }}</option>
                                </template>
                            </select>
                        </li>
                        <li class="checkbox">
                            <p class="name"><span v-if="grantId == 1">楽器</span><span v-if="grantId == 2">担当楽器</span><span class="selsected">{{ $config.names("parts", part ).join(",") }}</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.parts">
                                    <label :key="index"><input type="checkbox" name="part" :value="item.value" v-model="part"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li class="checkbox">
                            <p class="name"><span v-if="grantId == 1">習得したいジャンル</span><span v-if="grantId == 2">得意ジャンル</span><span class="selsected">{{ $config.names("genres", genre ).join(",") }}</span><i class="fas fa-chevron-down"></i></p>
                            <div class="items">
                                <template v-for="(item ,index) in $config.genres">
                                    <label :key="index"><input type="checkbox" name="genre" :value="item.value" v-model="genre"><p><span>{{ item.name }}</span><i class="fas fa-check"></i></p></label>
                                </template>
                            </div>
                        </li>
                        <li>
                            <span v-if="grantId == 1">音楽歴</span><span v-if="grantId == 2">講師歴</span>
                            <select name="career" v-model="career">
                                <template v-for="(item ,index) in $config.career">
                                    <option :value="item.value" :key="index">{{ item.name }}</option>
                                </template>
                            </select></li>
                    </ul>
                </div>

                <div class="list_box input_box">
                    <h2>プロフィール</h2>
                    <ul>
                        <li><textarea placeholder="" v-model="profile" :class="{error:errProfile}"></textarea>
                        </li>
                    </ul>
                    <p v-if="errProfile" class="error_description">{{ profileErrors.join(" ") }}</p>
                </div>
                <div class="container pt20">
                    <button class="button md" @click="save">保存する</button>
                </div>
            </section>

            <!--<modal-component v-if="showModalLogout" title="保存しました" viewPlain="true" @ok="showModalSaved = false"/>-->

        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../commons/ModalComponent.vue';

export default {
    components: {
    CommonLayoutComponent,
    ModalComponent
    },
  data: function () {
    return {
        userIcon:null,
        selectedUserIcon:null,
      userId: null,
      displayName: null,
      userName: null,
      email: null,
      newEmail: null,
      phone: null,
      password: null,
      profile: null,
      terms:false,
      grantId:null,

      sex:null,
      age:null,
      genre:[],
      part:[],
      career:null,

      errDisplayName: false,
      errUserName: false,
      errMailAddress: false,
      errPassword: false,
      errPhone: false,
      errProfile: false,

      displayNameErrors: [],
      userNameErrors: [],
      mailAddressErrors: [],
      passwordErrors: [],
      phoneErrors: [],
      profileErrors: [],

      imageFile: null,

      //showModalSaved: false,
    };
  },
  created: function () {},

  mounted: function () {
      this.refleshData();
      jQuery(".checkbox").on("click",".name",function() {
            jQuery(this).toggleClass("active").next(".items").slideToggle(300);
      });
  },
  computed: {
      iconImage: function() {
          if(this.selectedUserIcon){
              return 'background: url(' + this.selectedUserIcon + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      }
  },
  methods: {
    refleshData: function() {

            // ユーザー情報取得
            var handler = Vue.ELearning.UserInfo.current();

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    var user = response.data.content;
                    //console.log("user:", user);
                    this.userId = user["id"];
                    this.userIcon = user["image"];
                    this.selectedUserIcon = user["image"];
                    this.userName = user["name"];
                    this.displayName = user["display_name"];
                    this.email = user["mail_address"];
                    this.newEmail = user["mail_address"];
                    this.phone = user["phone"];
                    if(user["meta"]){
                        var part = user["meta"]["part"]? user["meta"]["part"].split(',') : [];
                        this.part = part.filter(function(item){ return item.length > 0})
                        var genre = user["meta"]["genre"]? user["meta"]["genre"].split(',') : [];
                        this.genre = genre.filter(function(item){ return item.length > 0})
                        this.age = user["meta"]["age"];
                        this.sex = user["meta"]["sex"];
                        this.career = user["meta"]["career"];
                        this.profile = user["meta"]["profile"];
                    }
                    //this.password = user["password"];

                    this.grantId = user["grant_id"];

                } else {
                    throw new Error("ユーザー情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },

    errorHandler: function(content) {
        if (content.errors == undefined) {
            return;
        }

        if (content.errors.user_name != undefined) {
            //console.log("err-user-name");
            this.errUserName = true;

            this.userNameErrors = [];
            for (var key in content.errors.user_name) {
                var item = content.errors.user_name[key];
                this.userNameErrors.push(item);
            }
        }
        if (content.errors.display_name != undefined) {
            //console.log("err-user-name");
            this.errDisplayName = true;

            this.displayNameErrors = [];
            for (var key in content.errors.display_name) {
                var item = content.errors.display_name[key];
                this.displayNameErrors.push(item);
            }
        }        
        if (content.errors.mail_address != undefined) {
            //console.log("err-mail_address");
            this.errMailAddress = true;

            this.mailAddressErrors = [];
            for (var key in content.errors.mail_address) {
                var item = content.errors.mail_address[key];
                this.mailAddressErrors.push(item);
            }
        }
        if (content.errors.new_password != undefined) {
            //console.log("err-password");
            this.errPassword = true;

            this.passwordErrors = [];
            for (var key in content.errors.new_password) {
                var item = content.errors.new_password[key];
                this.passwordErrors.push(item);
            }
        }
        if (content.errors.phone != undefined) {
            //console.log("err-password");
            this.errPhone = true;

            this.phoneErrors = [];
            for (var key in content.errors.phone) {
                var item = content.errors.phone[key];
                this.phoneErrors.push(item);
            }
        }        
    },

    save: function() {
            this.errDisplayName = false;
            this.errUserName = false;
            this.errMailAddress = false;
            this.errPassword = false;
            this.errPhone = false,
            this.errProfile = false,

            this.displayNameErrors = [];
            this.userNameErrors = [];
            this.mailAddressErrors = [];
            this.passwordErrors = [];
            this.phoneErrors = [];
            this.profileErrors = [];


        Promise.resolve()
        .then(() => {
            if(this.imageFile){
                let fd = new FormData(); //★②
                fd.append('fileData', this.imageFile);
                var config = {
                    headers: { 'Content-Type': 'multipart/formdata' }
                };
                return Vue.ELearning.Assets.upload(fd, config);
            }else{
                return false;
            }

        }).then((response) => {
            if(response){
                if (response.data.status_number == 200) {
                var content = response.data.content;
                    console.log('upload completed:', content);
                if (content.hasFile == 'OK') {
                    this.userIcon = content.video.video_path;

                    } else {
                        // アップロード失敗
                        this.currentFileErr = 'ファイルのアップロードに失敗しました。';
                        return false;
                    }
                } else {
                    throw new Error('ファイルのアップロードに失敗しました');
                    return false;
                }
            }

            var part = this.part.length > 0 ? "," + this.part.join(",") + "," : ""
            var genre = this.genre.length > 0 ? "," + this.genre.join(",") + "," : ""            
            var params = {
                'id': this.userId,
                'name': this.userName,
                'display_name': this.displayName,
                'old_mail_address': this.email,
                'mail_address': this.newEmail,
                'phone': this.phone,
                'image': this.userIcon,
                'age':this.age,
                'sex':this.sex,
                'part':part,
                'genre':genre,
                'career':this.career,
                'profile': this.profile,
            }
            if(this.password && this.password.length > 0){
                params["new_password"] = this.password
            }
            return Vue.ELearning.UserInfo.update(params)

        }).then((response) => {
                if (response && response.data.status_number == 200) {
                    var content = response.data.content;
                    if (content.result == 'NG') {
                        // バリデーションエラー
                        //console.error(content);

                        this.errorHandler(content);
                        return;
                    }

                    //this.showModalSaved = true;
                    this.$dialog.message( "保存しました。" )
                } else {
                    throw new Error("ユーザー情報の更新に失敗しました。");
                    this.$dialog.message( "保存に失敗しました。" )
                }
            }).catch((response) => {
                console.error(response);
                this.$dialog.message( "保存に失敗しました。" )
        });     
    },
    onChangeImage: function(event) {
        //console.log(e.target.result);
        this.selectedUserIcon = window.URL.createObjectURL(event.target.files[0]);

        this.imageFile = event.target.files.length > 0 ? event.target.files[0] : [];
    },
    onNaviBack: function(){

        if(this.lesson){
            this.$libs.pushUrl("mypage")
        }else{
            history.back()
        }
    },

  },
}

</script>
<style lang="scss" scoped>
</style>
