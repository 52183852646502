<template>
  <!-- Q and A　-->
  <div class="mb40">
    <template v-if="myQa && myQa.length > 0">
      <template v-for="(item, index) in myQa">
        <div class="feedback box_wh" :key="index">
          <div class="box">
            <div class="user flex_nw">
              <div class="icon">
                <p :style="iconImage(item.user.image)"></p>
              </div>
              <p class="name">{{ item.user.display_name }}</p>
              <p class="date">{{ date(item.created_at) }}</p>
            </div>
            <div class="contents">
              <h3>{{ item.title }}</h3>
              <p>{{ item.message }}</p>
              <lesson-video-component
                v-if="item.video"
                :video="item.video.video_path"
              />
            </div>
          </div>
          <template v-for="(re_item, index) in item.replies">
            <div class="box reply" :key="index">
              <p class="relpay_icon"><i class="fas fa-reply"></i>回答</p>
              <div class="user flex_nw">
                <div class="icon">
                  <p :style="iconImage(re_item.user.image)"></p>
                </div>
                <p class="name">{{ re_item.user.display_name }}</p>
                <p class="date">{{ date(re_item.created_at) }}</p>
              </div>
              <div class="contents">
                <p>{{ re_item.message }}</p>
                <lesson-video-component
                  v-if="re_item.video"
                  :video="re_item.video.video_path"
                />
              </div>
            </div>
          </template>
          <!--  投稿者で返信していない場合　-->
          <template v-if="$store.state.userInfo.id == lesson.user_id">
            <div class="box reply reply_post">
              <p class="notice"><i class="fas fa-exclamation-circle"></i>回答してください<br>期限:{{ replayLimitDate(item.created_at) }}</p>
              <button
                class="button round"
                @click="$libs.pushUrl(['lesson',lesson.id,'question',item.id,'reply' ])">
                回答する
              </button>
            </div>
          </template>
        </div>
      </template>
    </template>

    <!-- みんなの質問 -->
    <h2 class="mt30">みんなの質問</h2>
    <div class="feedback box_wh">
      <template v-if="qa && qa.length > 0">
        <template v-for="(item, index) in qa">
          <div class="qa_item" :key="index">
            <div class="box head flex_nw">
              <div class="left">
                <h3><span v-if="isNew(item.created_at)" class="new">NEW</span>{{ item.title }}</h3>
                  <div class="flex_nw">
                    <p class="name">{{ item.user.display_name }}</p>
                    <p class="date">{{ date(item.created_at) }}</p>
                  </div>
                </div>
                <p><i class="fas fa-chevron-down"></i></p>
              </div>
            <div class="inner">
            <div class="box">
              <div class="user flex_nw">
                <div class="icon">
                  <p :style="iconImage(item.user.image)"></p>
                  　
                </div>
                <p class="name">{{ item.user.display_name }}</p>
                <p class="date">{{ date(item.created_at) }}</p>
              </div>
              <div class="contents">
                <p>{{ item.message }}</p>
                <lesson-video-component
                  v-if="item.video"
                  :video="item.video.video_path"
                />
              </div>
            </div>
            <template v-for="(re_item, index) in item.reply">
              <div class="box reply" :key="index">
                <p class="relpay_icon"><i class="fas fa-reply"></i>回答</p>
                <div class="user flex_nw">
                  <div class="icon">
                    <p :style="iconImage(re_item.user.image)"></p>
                  </div>
                  <p class="name">{{ re_item.user.display_name }}</p>
                  <p class="date">{{ date(re_item.created_at) }}</p>
                </div>
                <div class="contents">
                  <p>{{ re_item.message }}</p>
                  <lesson-video-component
                    v-if="re_item.video"
                    :video="re_item.video.video_path"
                  />
                </div>
              </div>
            </template>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <p class="pt50 pb50 text_center">質問はまだありません</p>
      </template>

    </div>
  </div>
</template>

<script>
import moment from "moment";
import LessonVideoComponent from '../../miso/user/LessonVideoComponent.vue';

export default {
  components: {
    LessonVideoComponent,
  },
  props: {
    lesson:Object,
    qa: Array,
    myQa: Array,
  },
  data: function () {
    return {
    };
  },
  watch: {
  },
  mounted: function () {
      jQuery(".qa_item .head").click(function() {
          $(this).toggleClass("active").next(".inner").slideToggle(300);
      });
  },
  computed: {
  },

  methods: {

    iconImage: function (path) {
      if (path) {
        return "background: url(" + path + ") center center / cover";
      } else {
        return "background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover";
      }
    },

    date: function (stringDate) {
      var now = moment();
      var date = moment(stringDate);

      if (now.diff(date, "days") > 90) {
        return date.format("YYYY年MM月DD日");
      } else {
        return date.format("MM月DD日 HH:mm");
      }
    },
    isNew:function(stringDate){
      var now = moment();
      var date = moment(stringDate);

      if (now.diff(date, "days") <= 7) {
        return true
      }

      return false
    },
    replayLimitDate: function (stringDate) {
      var now = moment();
      var date = moment(stringDate).add(7, 'd')

      return date.format("MM月DD日 HH:mm");
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
