var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table basic4 color01 has-sorticon", class: _vm.classess },
    [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th", [_vm._v(" ")]),
            _vm._v(" "),
            _vm._l(_vm.headers, function(header, n) {
              return _c(
                "th",
                {
                  key: n,
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      header.sortName
                        ? _vm.sort(header.sortName)
                        : _vm.sort(header.name)
                    }
                  }
                },
                [
                  _vm._v(_vm._s(header.title)),
                  _c("a", {
                    class: header.sortName
                      ? _vm.sort_class(header.sortName)
                      : _vm.sort_class(header.name),
                    attrs: { href: "#" }
                  })
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.list, function(item, n) {
          return _c(
            "tr",
            { key: n },
            [
              _c("td", [_vm._v(_vm._s(_vm._f("zero_pad")(n + 1, 2)))]),
              _vm._v(" "),
              _vm._l(_vm.headers, function(header, m) {
                return _c("td", { key: m }, [
                  _vm._v(
                    _vm._s(
                      header.filter
                        ? header.filter(item[header.name])
                        : item[header.name]
                    )
                  )
                ])
              })
            ],
            2
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }