<template>
    <common-layout-component :show-back="false" :show-navi-bar="true" title="プライバシーポリシー" main-class="privacy_page">
        <template v-slot:body>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    export default {
        components :{
            CommonLayoutComponent
        },
        mounted:function(){
        },
    }
</script>
