var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component2", {
    attrs: { "show-back": "true", "show-admin": "true" },
    on: { "on-nav-back": _vm.back },
    scopedSlots: _vm._u([
      {
        key: "nav-message",
        fn: function() {
          return [_c("p", [_vm._v("ユーザー登録/編集")])]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "container mainContainer" },
              [
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "header-area" } },
                  [
                    _c(
                      "h2",
                      { staticClass: "column", attrs: { id: "title" } },
                      [_vm._v("ユーザー編集")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "column tr" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-gray",
                          on: { click: _vm.importUsr }
                        },
                        [
                          _vm._v("\n                        インポート "),
                          _c("img", {
                            attrs: { src: "/images/ico-import.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "d-none",
                        attrs: {
                          id: "input-file-csv",
                          type: "file",
                          name: "file"
                        },
                        on: { change: _vm.selectedUploadCsv }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-gray",
                          on: { click: _vm.exportUsr }
                        },
                        [
                          _vm._v("\n                        エクスポート "),
                          _c("img", {
                            attrs: { src: "/images/ico-export.png", alt: "" }
                          })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { attrs: { id: "subtitle-area" } }, [
                  _vm._v("ユーザー一覧")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "columns", attrs: { id: "table-area" } },
                  [
                    _c("div", { staticClass: "column" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          on: { click: _vm.gotoRegist }
                        },
                        [
                          _vm._v("\n                        新規登録 "),
                          _c("img", {
                            attrs: { src: "//images/ico-plus.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-login",
                          attrs: { disabled: _vm.editButtonEnabled },
                          on: { click: _vm.gotoEdit }
                        },
                        [
                          _vm._v("\n                        編集 "),
                          _c("img", {
                            attrs: { src: "//images/ico-pen.png", alt: "" }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button basic is-red",
                          attrs: {
                            disabled: _vm.deleteButtonEnabled,
                            "data-remodal-target": "modal"
                          },
                          on: {
                            click: function($event) {
                              _vm.showModalDelete = true
                            }
                          }
                        },
                        [
                          _vm._v("\n                        削除 "),
                          _c("img", {
                            attrs: { src: "//images/ico-trash.png", alt: "" }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column column-search" }, [
                      _c("p", { staticClass: "input-search tr" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.keyword,
                              expression: "keyword"
                            }
                          ],
                          staticStyle: { "padding-right": "2.2em" },
                          attrs: { type: "text", placeholder: "検索" },
                          domProps: { value: _vm.keyword },
                          on: {
                            change: function($event) {
                              return _vm.onSearch($event.target)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.keyword = $event.target.value
                            }
                          }
                        }),
                        _c("img", {
                          attrs: { src: "//images/ico-search.png", alt: "" }
                        })
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-clear btn-round",
                          attrs: { disabled: !_vm.keyword.length },
                          on: { click: _vm.clearConditions }
                        },
                        [
                          _vm._v(
                            "\n                        クリア\n                    "
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "table-wrapper" }, [
                  _c(
                    "table",
                    {
                      staticClass: "basic scrollTable",
                      attrs: { id: "table000" }
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.onSort("user_name_kana")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                ユーザー名  \n                                "
                              ),
                              _vm.axis == "user_name_kana" && _vm.order == "asc"
                                ? _c("img", {
                                    attrs: {
                                      src: "/images/ico-down.png",
                                      alt: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.axis == "user_name_kana" &&
                              _vm.order == "desc"
                                ? _c("img", {
                                    attrs: {
                                      src: "/images/ico-up.png",
                                      alt: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.axis == "org_name"
                                ? _c("img", {
                                    attrs: {
                                      src: "/images/ico-down-thin.png",
                                      alt: ""
                                    }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("th", [_vm._v("メールアドレス")]),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.onSort("org_name")
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                                部署名  \n                                "
                              ),
                              _vm.axis == "org_name" && _vm.order == "asc"
                                ? _c("img", {
                                    attrs: {
                                      src: "/images/ico-down.png",
                                      alt: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.axis == "org_name" && _vm.order == "desc"
                                ? _c("img", {
                                    attrs: {
                                      src: "/images/ico-up.png",
                                      alt: ""
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.axis != "org_name"
                                ? _c("img", {
                                    attrs: {
                                      src: "/images/ico-down-thin.png",
                                      alt: ""
                                    }
                                  })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          _c("th", [_vm._v("権限")]),
                          _vm._v(" "),
                          _c("th", [_vm._v("所属グループ")])
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.users.length == 0,
                              expression: "users.length == 0"
                            }
                          ]
                        },
                        [
                          _c("td", { attrs: { colspan: "6" } }, [
                            _vm._v("ユーザーが存在しません。")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.users, function(user) {
                        return [
                          _c("tr", { key: user.userId }, [
                            _c(
                              "td",
                              { staticClass: "check" },
                              [
                                _c("base-check-component", {
                                  attrs: { value: user.selected },
                                  on: {
                                    "check-state-change": function($event) {
                                      return _vm.onCheckStateChange(user)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "user" }, [
                              _vm._v(_vm._s(user.userName))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "email" }, [
                              _vm._v(_vm._s(user.mailAddress))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "area" }, [
                              _vm._v(_vm._s(user.orgName))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "edit" }, [
                              _vm._v(_vm._s(user.grantName))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "group" }, [
                              _c("div", [
                                user.groups.length == 1
                                  ? _c("span", [
                                      _vm._v(_vm._s(user.groups[0].group_name))
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                user.groups.length > 1
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(user.groups[0].group_name) +
                                          "..."
                                      )
                                    ])
                                  : _c("span"),
                                _vm._v(" "),
                                user.groups.length > 1 && !user.isGroupsOpen
                                  ? _c("img", {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        src: "//images/ico-plus2.png",
                                        alt: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          user.isGroupsOpen = !user.isGroupsOpen
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                user.groups.length > 1 && user.isGroupsOpen
                                  ? _c("img", {
                                      staticStyle: { cursor: "pointer" },
                                      attrs: {
                                        src: "//images/ico-minus.png",
                                        alt: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          user.isGroupsOpen = !user.isGroupsOpen
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ])
                            ])
                          ]),
                          _vm._v(" "),
                          user.isGroupsOpen
                            ? _c(
                                "tr",
                                {
                                  key: user.userId + "-2",
                                  staticClass: "grouplist"
                                },
                                [
                                  _c(
                                    "td",
                                    { attrs: { colspan: "6" } },
                                    [
                                      _vm._l(user.groups, function(group) {
                                        return [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "button basic is-tags",
                                              staticStyle: {
                                                "text-decoration": "none"
                                              },
                                              attrs: { href: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(group.group_name))]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _vm.showModalDelete
                  ? _c(
                      "modal-component",
                      {
                        attrs: {
                          "is-delete": true,
                          "is-quest": "true",
                          width: "600"
                        },
                        on: {
                          modalYes: _vm.execDelete,
                          modalNo: function($event) {
                            _vm.showModalDelete = false
                          }
                        }
                      },
                      [
                        _c("h4", { attrs: { slot: "header" }, slot: "header" }),
                        _vm._v(" "),
                        _c(
                          "h3",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { slot: "body" },
                            slot: "body"
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "tc pb2em",
                                staticStyle: { "margin-top": "0.5em" }
                              },
                              [
                                _c("strong", [
                                  _vm._v("選択されたユーザーを削除しますか？")
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }