var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticStyle: { cursor: "pointer" }, style: _vm.currentStyle },
    [
      _vm.currentSelected
        ? _c("input", {
            staticClass: "checkbox01-input",
            attrs: { type: "checkbox", checked: "", readonly: "" },
            on: {
              click: _vm.checkStateChange,
              change: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
              }
            }
          })
        : _c("input", {
            staticClass: "checkbox01-input",
            attrs: { type: "checkbox", readonly: "" },
            on: {
              click: _vm.checkStateChange,
              change: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
              }
            }
          }),
      _vm._v(" "),
      _c("span", { staticClass: "checkbox01-parts" }, [
        _vm._v(_vm._s(_vm.msgLabel))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }