var logic = {};

logic.created = function() {
    console.log("Initialize Admin/Tags");

    // 最新情報を取得
    this.refleshData();
};

logic.data = function() {
    return {
        showModalDelete:false,

        radius:32,
        tagOpen: false,
        checkBoxState:false,

        sortOrder:'asc',
        sortAxis:'update_datetime'/* dictionary */,

        tags: [],
    }
};

logic.methods = {
    sortUpdateDatetime: function(){
        Vue.ELearning.Util.showOverlay(this.$store);

        var axisChanged = false;

        if(this.sortAxis != "update_datetime") {
            axisChanged = true;
        }

        this.sortAxis = "update_datetime";

        if(axisChanged) {
            this.sortOrder = "asc";
        } else {
            if(this.sortOrder == "asc") {
                this.sortOrder = "desc";
            } else {
                this.sortOrder = "asc";
            }
        }



        //Vue.ELearning.Util.hideOverlay(this.$store);
        this.refleshData(this.sortAxis, this.sortOrder);
    },

    sortDictionary: function(){
        Vue.ELearning.Util.showOverlay(this.$store);
        var axisChanged = false;

        if(this.sortAxis != "dictionary") {
            axisChanged = true;
        }

        this.sortAxis = "dictionary";

        if(axisChanged) {
            this.sortOrder = "asc";
        } else {
            if(this.sortOrder == "asc") {
                this.sortOrder = "desc";
            } else {
                this.sortOrder = "asc";
            }
        }

        //Vue.ELearning.Util.hideOverlay(this.$store);
        this.refleshData(this.sortAxis, this.sortOrder);
    },


    navigateEdit: function(tagId) {
        this.$router.push({
            path: '/admin/tag/' + tagId
        });
    },

    onCheckStateChange: function(tag){
        tag.selected = !tag.selected;
    },

    back: function() {

        this.$router.push({
            path: '/admin/menu/'
        });

    },
    onClickNew: function() {
        this.navigateEdit(0);
    },

    onClickEdit: function() {
        var len = this.tags.length;
        for(var item in this.tags) {
            if (this.tags[item].selected) {
                this.navigateEdit(this.tags[item].tagId);
                return;
            }
        }
    },

    onClickDelete: function() {
        var deleteTarget = [];
        for(var item in this.tags) {
            if(this.tags[item].selected) {
                // ToDo: 指定IDからカテゴリを削除するAPIをKick
                //this.categoryInfo[item].enabled = false;
                deleteTarget.push(this.tags[item].tagId);
            }
        }

        // ToDo:タグ削除

        var res = Vue.ELearning.Tag.deleteTag(deleteTarget);
        res.then(function(res){
            console.log(res.data.content);
            this.showModalDelete = false;
            // 最新情報を取得
            this.refleshData(this.sortAxis, this.sortOrder);

        }.bind(this)).catch(function(res){
            this.showModalDelete = false;
            console.error(res);
        }.bind(this));
    },

    refleshData: function(axis, order){

        Vue.ELearning.Util.showOverlay(this.$store);

        if(axis == undefined) {
            this.sortAxis = "update_datetime";
        } else {
            this.sortAxis = axis;
        }
        if(order == undefined) {
            this.sortOrder = "desc";
        } else {
            this.sortOrder = order;
        }




        var res = Vue.ELearning.Tag.getAllBySort(this.sortAxis, this.sortOrder);

        res.then(function(res){
            var content = res.data;

            this.tags = [];
            for(var k in content.content) {
                var item = content.content[k];

                this.tags.push({
                    'tagId': item.tag_id,
                    'tagName': item.tag_name,
                    //'categoryDesc': item.category_text,
                    'selected': false,
                    //'enabled':true,
                    //'publicity': (item.display_flag == 1) ? '公開' : '非公開',
                });
            }

            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this)).catch(function(err){
            //console.log(err);
            Vue.ELearning.Util.hideOverlay(this.$store);
        }.bind(this));
    },


    /**
     * CSVダウンロード
     */
    downloadCsv: function() {

        alert("仕様確認中");
        return;


        var res = Vue.ELearning.Category.getAll();

        res.then(function(res){
            var content = res.data;

            var csv = 'categories\n';
            csv += 'カテゴリID,カテゴリ名,カテゴリ説明,公開/非公開\n';
            var line = '';

            //this.categoryInfo = [];
            for(var k in content.content) {
                var item = content.content[k];
                console.log(item);
                line = [item.category_id, item.category_name, item.category_text, item.display_flag].join(",");
                csv += line + '\n';

            }

            var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);

            let blob = new Blob([bom, csv], {type: 'text/csv'});

            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, "test.csv");

                // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
                window.navigator.msSaveOrOpenBlob(blob, "test.csv");
            } else {
                document.getElementById("download").href = window.URL.createObjectURL(blob);
                document.getElementById("download").download = "categories.csv";
                document.getElementById("download").click();
                document.getElementById("download").href = "#";
            }

        }.bind(this)).catch(function(err){
            //console.log(err);
        });
    },


    importCsv: function () {
        alert("仕様確認中");
    }
};

logic.computed = {
    sortOrderIndicatorUpdateDatetime: function() {
        if(this.sortAxis !== "update_datetime") {
            return "";
        } else {
            if(this.sortOrder === "asc") {
                return "↓";
            } else {
                return "↑";
            }
        }
    },
    sortOrderIndicatorDictionary: function() {
        if(this.sortAxis !== "dictionary") {
            return "";
        } else {
            if(this.sortOrder === "asc") {
                return "↓";
            } else {
                return "↑";
            }
        }
    },

    tagList: function() {
        return this.tags;
    },

    editButtonEnabled() {
        var selectCount = 0;

        for(var item in this.tags) {

            if(this.tags[item].selected) {
                selectCount++;
            }
        }

        //console.log(selectCount);

        if(selectCount == 0) {
            return true;
        }
        if(selectCount == 1) {
            return false;
        }
        return true;
    },

    deleteButtonEnabled() {
        var selectCount = 0;

        for(var item in this.tags) {

            if(this.tags[item].selected) {
                selectCount++;
            }
        }

        if(selectCount == 0) {
            return true;
        }
        if(selectCount >= 1) {
            return false;
        }

    },
};

export default logic;