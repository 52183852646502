var logic = {};

logic.mounted = function() {
    //console.log("Initialize Admin/Tasks");
    var w = jQuery('#table001').width();
    //console.log(w);

    this.tableWidth = w;

    this.refleshTaskData();
    this.refleshTagData();
};

logic.methods = {
    /**
     * タスク編集画面に遷移する
     * @param 
     */

    /**
     * 
     * @param {Number} id タスクの Primary Key
     */
    navigateEdit: function(id) {
        this.$router.push({ path: '/admin/task/' + id });
    },

    /**
     * タスク編集画面(新規作成モード)に遷移する。
     */
    onClickNew: function() {
        this.navigateEdit(0);
    },

    /**
     * 編集ボタン押下時処理
     */
    onClickEdit: function() {
        for (var item in this.taskInfo) {
            if (this.taskInfo[item].selected) {
                this.navigateEdit(this.taskInfo[item].id);
                return;
            }
        }
    },

    /**
     * 複製ボタン押下時処理
     * 
     * @todo PK 対応 2019-10-06 ando
     */
    onClickReplicate: function() {
        var len = this.taskInfo.length;
        for (var item in this.taskInfo) {
            if (this.taskInfo[item].selected) {
                var res = Vue.ELearning.Task.replicate(this.taskInfo[item].id);
                res.then(
                    function(res) {
                        var content = res.data.content;
                        if (content.result == "OK") {
                            var originId = this.taskInfo[item].id;
                            if (content.target && content.target[originId]) {
                                this.$router.push({ path: '/admin/task/' + content.target[originId] });
                            } else {
                                //不正なレスポンス
                                console.error(res);
                                this.$dialog.message('タスクの複製に失敗しました');
                                throw new Error('タスクの複製に失敗しました');
                            }

                        } else {
                            //NG
                            console.error(res);
                            this.$dialog.message('タスクの複製に失敗しました');
                            throw new Error('タスクの複製に失敗しました');
                        }
                        /*
                        // 最新情報を取得
                        //this.refleshData();
                        for (var key in this.allTag) {
                            var item = this.allTag[key];
                            if (item.selected) {
                                this.refleshTaskData(
                                    item.tagId,
                                    this.sortAxis,
                                    this.sortOrder,
                                );
                            }
                        }
                        */
                    }.bind(this),
                ).catch(
                    function(res) {
                        console.error(res);
                    }.bind(this),
                );

                return;
            }
        }
    },

    /**
     * 削除ボタン押下時処理
     * 
     * @todo PK 対応 2019-10-06 ando
     */
    onClickDelete: function() {
        this.showModalDelete = false;
        //alert("仕様調整中");
        //return;

        var deleteTarget = [];

        for (var item in this.taskInfo) {
            if (this.taskInfo[item].selected) {
                deleteTarget.push(this.taskInfo[item]);
            }
        }

        console.log(deleteTarget);

        var handler = Vue.ELearning.Task.deleteTasks(deleteTarget);

        Vue.ELearning.Util.showOverlay(this.$store);

        handler
            .then(res => {
                if (res.data.status_number == 200) {
                    console.log(res.data.content);
                    Vue.ELearning.Util.hideOverlay(this.$store);

                    // 最新情報を取得
                    //this.refleshData();
                    for (var key in this.allTag) {
                        var item = this.allTag[key];
                        if (item.selected) {
                            this.refleshTaskData(
                                item.tagId,
                                this.sortAxis,
                                this.sortOrder,
                            );
                        }
                    }
                } else {
                    throw new Error('タスクの削除に失敗しました');
                }
            })
            .catch(res => {
                console.error(res);
                Vue.ELearning.Util.hideOverlay(this.$store);
            });
    },

    /**
     * タグ一覧ボタン押下時処理(タグ一覧アコーディオン開閉)
     * @param task
     */
    tagAreaChange: function(task) {
        task.tagOpen = !task.tagOpen;

        if (task.tagOpen) {
            task.tagIndicatorState['fa-plus-circle'] = false;
            task.tagIndicatorState['fa-minus-circle'] = true;
        } else {
            task.tagIndicatorState['fa-plus-circle'] = true;
            task.tagIndicatorState['fa-minus-circle'] = false;
        }
    },

    /**
     * タグ一覧インジケータ("+", "-")変更
     * @param task
     * @returns {string}
     */
    tagIndicator: function(task) {
        if (task.tagOpen) {
            return '－';
        } else {
            return '＋';
        }
    },

    classObject: function(task) {
        var res = {};
        if (!task.tagOpen) {
            res['fas'] = true;
            res['fa-plus-circle'] = true;
        } else {
            res['fas'] = true;
            res['fa-minus-circle'] = true;
        }

        return res;
    },

    /**
     * タグ検索ボタン押下時処理
     */
    onAllTagOpen: function() {
        this.allTagOpen = !this.allTagOpen;
    },

    /**
     * タスク選択チェックボックス選択時処理
     * @param task
     */
    onCheckStateChange: function(task) {
        task.selected = !task.selected;
    },

    /**
     * タグインジケータ取得
     * @param task
     * @returns {string}
     */
    tagIndicator: function(task) {
        if (task.tagOpen) {
            return '－';
        } else {
            return '＋';
        }
    },

    /**
     * タスク．関連タグ情報開時処理
     * @param task
     * @returns {*}
     */
    taskTagOpened(task) {
        return task.tagOpen;
    },
    taskTagOpenedForIndicator(task) {
        return task.tagOpen ? 'close' : 'open';
    },

    /**
     * 戻るボタン押下時処理
     */
    back: function() {
        this.$router.push({
            path: '/admin/menu/',
        });
    },

    /**
     * タスクに紐付けされているユーザ情報変更時処理
     * @param record
     */
    onUserByTaskChange: function(record) {
        console.log(record);

        record.selected = !record.selected;
    },

    /**
     * 関連ユーザダイアログ
     * @param task
     */
    onOpenGroupByTask: function(task) {
        //console.log(task);
        if (task != undefined) {
            // 行のボタン押下時
            this.currentTask = task;
        } else {
            // モーダル内でソートされた場合
            task = this.currentTask;
        } /* org_name */

        this.fetchGroupsByTask(task.id).then(() => { this.isOpenUserByTask = true; });
    },

    onTagSelect: function(tag) {
        var flag = false;
        var flagTagAll = true;
        for (var key in this.allTag) {
            var item = this.allTag[key];
            if (tag.tagId == 9999 && item.tagId != 9999) {
                item.selected = false;
            } else if (tag.tagId != 9999 && item.tagId == 9999) {
                item.selected = false;
            } else {
                if (item.tagId == tag.tagId) {
                    if (tag.selected == true) {
                        tag.selected = false;
                    } else {
                        tag.selected = true;
                    }
                }
            }
            if (tag.tagId != 9999 && item.selected == true) {
                flagTagAll = false;
            }
        }
        var listTagSelected = [];
        var checkFlagAll = false;
        for (var key in this.allTag) {
            var item = this.allTag[key];
            if (flagTagAll == true && item.tagId == 9999) {
                item.selected = true;
                checkFlagAll = true;
            }
            if (item.selected == true) {
                listTagSelected.push(item.tagId);
            }
        }

        if (listTagSelected.length <= 0) {
            listTagSelected.push(0);
        }

        if (checkFlagAll == true) {
            this.refleshTaskData(9999, this.sortAxis, this.sortOrder);
        } else {
            this.searchMultiTags(listTagSelected, this.sortAxis, this.sortOrder);
        }
    },

    searchMultiTags: function(listTags, axis, order) {
        Vue.ELearning.Util.showOverlay(this.$store);
        var tasks = [];
        if (axis !== undefined && order !== undefined) {
            tasks = Vue.ELearning.Task.searchMultiTagForAdmin(
                listTags,
                this.sortAxis,
                this.sortOrder,
            );
        } else {
            tasks = Vue.ELearning.Task.searchMultiTagForAdmin(listTags);
        }

        tasks
            .then(
                function(res) {
                    if (res.data.status_number == 200) {
                        var records = res.data.content;

                        this.taskInfo = [];

                        for (var key in records) {
                            var task = records[key];

                            var tags = [];

                            for (var tagkKey in task.allTag) {
                                var tag = task.allTag[tagkKey];

                                tags.push({
                                    tagId: tag.tag_id,
                                    tagName: tag.tag_name,
                                });
                            }

                            this.taskInfo.push({
                                id: task.id,
                                categoryId: task.category_id,
                                taskId: task.task_id,
                                taskName: task.task_name,
                                taskDescription: task.task_text,
                                categoryName: task.category_name,
                                publicity: task.display_flag == 1 ? '公開' : '非公開',

                                publicity_name_category: task.category_display_flag == 1 ?
                                    '公開' : '非公開',
                                publicity_category: task.category_display_flag,
                                category_del_flag: task.category_del_flag,

                                postDate: task.create_datetime,
                                updateDate: task.update_datetime,

                                progress: task.progress,

                                references: task.references,

                                selected: false,
                                tagOpen: false,
                                enabled: true,

                                tagIndicatorState: {
                                    fas: true,
                                    'fa-plus-circle': true,
                                    'fa-minus-circle': false,
                                },
                                tags: tags,
                            });
                        }
                    }

                    Vue.ELearning.Util.hideOverlay(this.$store);
                }.bind(this),
            )
            .catch(
                function(res) {
                    //alert('タスク情報の取得に失敗しました');
                    this.$dialog.message("タスク情報の取得に失敗しました");
                    console.error(res);

                    Vue.ELearning.Util.hideOverlay(this.$store);
                }.bind(this),
            );
    },
    refleshTaskData: function(tagId, axis, order) {
        // タスク一覧取得

        Vue.ELearning.Util.showOverlay(this.$store);

        var tasks = [];

        if (tagId !== undefined) {
            if (axis !== undefined && order !== undefined) {
                // APIコール
                tasks = Vue.ELearning.Task.getAllForAdminWithSort(
                    tagId,
                    this.sortAxis,
                    this.sortOrder,
                );
            } else {
                tasks = Vue.ELearning.Task.getAllForAdminWithTag(tagId);
            }
        } else {
            if (axis !== undefined && order !== undefined) {
                tasks = Vue.ELearning.Task.getAllForAdminWithSort(
                    9999,
                    'update_datetime',
                    'asc',
                );
            } else {
                tasks = Vue.ELearning.Task.getAllForAdmin();
            }
        }

        tasks
            .then(
                function(res) {
                    if (res.data.status_number == 200) {
                        var records = res.data.content;

                        this.taskInfo = [];

                        for (var key in records) {
                            var task = records[key];

                            var tags = [];

                            for (var tagkKey in task.allTag) {
                                var tag = task.allTag[tagkKey];

                                tags.push({
                                    tagId: tag.tag_id,
                                    tagName: tag.tag_name,
                                });
                            }

                            this.taskInfo.push({
                                id: task.id,
                                categoryId: task.category_id,
                                taskId: task.task_id,
                                taskName: task.task_name,
                                taskDescription: task.task_text,
                                categoryName: task.category_name,
                                publicity: task.display_flag == 1 ? '公開' : '非公開',

                                publicity_name_category: task.category_display_flag == 1 ?
                                    '公開' : '非公開',
                                publicity_category: task.category_display_flag,
                                category_del_flag: task.category_del_flag,

                                postDate: task.create_datetime,
                                updateDate: task.update_datetime,

                                progress: task.progress,

                                references: task.references,

                                selected: false,
                                tagOpen: false,
                                enabled: true,

                                tagIndicatorState: {
                                    fas: true,
                                    'fa-plus-circle': true,
                                    'fa-minus-circle': false,
                                },
                                tags: tags,
                            });
                        }
                    }

                    Vue.ELearning.Util.hideOverlay(this.$store);
                }.bind(this),
            )
            .catch(
                function(res) {
                    //alert('タスク情報の取得に失敗しました');
                    this.$dialog.message("タスク情報の取得に失敗しました");
                    console.error(res);

                    Vue.ELearning.Util.hideOverlay(this.$store);
                }.bind(this),
            );
    },

    refleshTagData: function() {
        // タグ一覧取得
        var tags = Vue.ELearning.Tag.getAll();

        tags.then(
            function(res) {
                if (res.data.status_number == 200) {
                    var records = res.data.content;

                    this.allTag = [];
                    for (var key in records) {
                        var item = records[key];

                        this.allTag.push({
                            tagId: item.tag_id,
                            tagName: item.tag_name,
                            selected: false,
                        });
                    }

                    this.allTag.push({
                        tagId: 9999,
                        tagName: '全て',
                        selected: true,
                    });
                }
            }.bind(this),
        ).catch(
            function(res) {
                //alert('タグ情報の取得に失敗しました');
                this.$dialog.message("タグ情報の取得に失敗しました");
                console.error(res);
            }.bind(this),
        );
    },

    // ソート関連
    sortUpdateDatetime: function() {
        var axisChanged = false;

        if (this.sortAxis != 'update_datetime') {
            axisChanged = true;
        }

        this.sortAxis = 'update_datetime';

        if (axisChanged) {
            this.sortOrder = 'asc';
        } else {
            if (this.sortOrder == 'asc') {
                this.sortOrder = 'desc';
            } else {
                this.sortOrder = 'asc';
            }
        }

        for (var key in this.allTag) {
            var item = this.allTag[key];
            if (item.selected) {
                this.refleshTaskData(item.tagId, this.sortAxis, this.sortOrder);
            }
        }
    },
    sortDictionary: function() {
        var axisChanged = false;

        if (this.sortAxis != 'dictionary') {
            axisChanged = true;
        }

        this.sortAxis = 'dictionary';

        if (axisChanged) {
            this.sortOrder = 'asc';
        } else {
            if (this.sortOrder == 'asc') {
                this.sortOrder = 'desc';
            } else {
                this.sortOrder = 'asc';
            }
        }

        for (var key in this.allTag) {
            var item = this.allTag[key];
            if (item.selected) {
                this.refleshTaskData(item.tagId, this.sortAxis, this.sortOrder);
            }
        }
    },

    exportTasks: function() {
        var handler = Vue.ELearning.Task.getCsv(
            // this.keyword,
            // this.axis,
            // this.order
        );

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(response => {
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'tasks.csv');
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                Vue.ELearning.Util.hideOverlay(this.$store)
            })
            .catch(response => {
                //alert('エクスポートに失敗しました');
                this.$dialog.message("エクスポートに失敗しました");
                Vue.ELearning.Util.hideOverlay(this.$store)
            });
    },

    importTasks: function() {
        this.$el.querySelector('#input-file-csv').click();
    },

    selectedUploadCsv(e) {
        const files = e.target.files;
        const csv = files[0];

        let formData = new FormData();
        formData.append('csv', csv);

        const handler = Vue.ELearning.Task.postCsv(formData)

        Vue.ELearning.Util.showOverlay(this.$store);
        handler
            .then(() => {
                Vue.ELearning.Util.hideOverlay(this.$store)
                this.refleshTaskData()
                this.refleshTagData()
            })
            .catch(err => {
                //alert('インポートに失敗しました');
                this.$dialog.message("インポートに失敗しました");
                Vue.ELearning.Util.hideOverlay(this.$store)
            })
    },

    userTaskSort: function(axis) {
        if (axis == this.sortAxisUser) {
            if (this.sortOrderUser == 'asc') {
                this.sortOrderUser = 'desc';
            } else {
                this.sortOrderUser = 'asc';
            }
        } else {
            this.sortOrderUser = 'asc';
            this.sortAxisUser = axis;
        }

        // 問い合わせ
        this.onOpenUserByTask();
    },

    fetchGroupsByTask(task_id) {
        return new Promise((resolve, reject) => {
            const res = Vue.ELearning.Assignment.getGroupsByTask(task_id);
            res.then(res => {
                    if (res.data.status_number != 200) throw new Error();
                    return res.data.content;
                })
                .then(data => {
                    this.groups = [];
                    data.map(row => this.groups.push(row.group.group_name));

                    resolve();
                })
                .catch(err => {
                    console.error('グループタスクアサイン情報取得失敗');
                    reject();
                });
        });
    },

    maxDiskSize() {
        return this.$store.state.appInfo.maxDiskSize; //process.env.MIX_MAX_DISK_SIZE;
    },
};

logic.watch = {
    isOpenUserByTask: function(val) {
        console.log('openUserByTask', val);

        if (val) {
            Vue.ELearning.Util.bodyScrollOff();
        } else {
            Vue.ELearning.Util.bodyScrollOn();
        }
    },

    showModalDelete: function(val) {
        console.log('showModalDelete', val);

        if (val) {
            Vue.ELearning.Util.bodyScrollOff();
        } else {
            Vue.ELearning.Util.bodyScrollOn();
        }
    },
    allTagOpen: function(val) {
        if (val == true) {
            this.$nextTick(
                function() {
                    var h = jQuery('.all-tag-container').outerHeight();
                    //console.log("open", h);

                    this.tagAreaHeight = h;
                }.bind(this),
            );
        } else {
            this.$nextTick(
                function() {
                    var h = jQuery('.all-tag-container').outerHeight();
                    //console.log("close", h);

                    this.tagAreaHeight = h;
                }.bind(this),
            );
        }
    },

    sortOrderGroup: {
        handler: 'fetchGroups',
    },
};

logic.data = function() {
    return {
        tagAreaHeight: 0,
        tableWidth: 0,
        radius: 32,
        allTagOpen: false,
        tagOpen: false,
        checkBoxState: false,

        showModalDelete: false,

        taskInfo: [
            /*
            {
                taskId: 1,
                taskName: 'タスク名タスク名タスク名タスク名タスク名タスク名タスク名',
                taskDescription: 'タスク説明XxxxxxxxxX タスク説明XxxxxxxxxXタスク説明XxxxxxxxxXタスク説明XxxxxxxxxXタスク説明XxxxxxxxxX',
                categoryName: 'カテゴリ名００１',
                publicity: '公開',
                postDate: '2019/01/01',
                postUser: '投稿者００１',
                updateDate: '2019/02/01',
                updateUser: '更新者００１',
                progress:72,
                selected:false,
                tagOpen:false,

                enabled:true,

                tagIndicatorState: {
                    'fas':true,
                    'fa-plus-circle':true,
                    'fa-minus-circle':false,
                },

                tags: [
                    {
                        tagId:"",
                        tagName:"タグ００１"
                    },
                ]
            },
            */
        ],

        isOpenUserByTask: false,

        allTag: [],

        // ---------------------------------------------------------------------------------------------------------
        sortOrder: 'desc',
        sortAxis: 'update_datetime' /* dictionary */ ,

        currentTask: {},
        sortOrderUser: 'asc',
        sortAxisUser: 'user_name_kana' /* org_name */ ,

        groups: [],
    };
};
logic.computed = {
    realTagAreaHeight: function() {
        return this.tagAreaHeight - 20;
    },

    openClose() {
        return this.allTagOpen ? 'close' : 'open';
    },

    allTags: function() {
        return this.$store.state.allTags;
    },

    allTagIndicator: function() {
        return this.allTagOpen ? '▼' : '▲';
    },

    editButtonEnabled() {
        var selectCount = 0;

        for (var item in this.taskInfo) {
            if (this.taskInfo[item].selected && this.taskInfo[item].enabled) {
                selectCount++;
            }
        }

        if (selectCount == 0) {
            return true;
        }
        if (selectCount == 1) {
            return false;
        }
        return true;
    },
    replicateButtonEnabled() {
        var selectCount = 0;

        for (var item in this.taskInfo) {
            if (this.taskInfo[item].selected && this.taskInfo[item].enabled) {
                selectCount++;
            }
        }

        if (selectCount == 0) {
            return true;
        }
        if (selectCount == 1) {
            return false;
        }
        return true;
    },
    deleteButtonEnabled() {
        var selectCount = 0;

        for (var item in this.taskInfo) {
            if (this.taskInfo[item].selected && this.taskInfo[item].enabled) {
                selectCount++;
            }
        }

        if (selectCount == 0) {
            return true;
        }
        if (selectCount >= 1) {
            return false;
        }
    },

    tagsForShow() {
        if (this.tagOpen) {
            return this.taskInfo.tags;
        } else {
            var result = [];
            var max = 0;
            var min = 1;

            for (var tag in this.taskInfo.tags) {
                if (min <= max) {
                    result.push(this.taskInfo.tags[tag]);
                } else {
                    break;
                }
                min++;
            }

            return result;
        }
    },

    sortOrderIndicatorUpdateDatetime: function() {
        if (this.sortAxis !== 'update_datetime') {
            //return "↓";
            return '';
        } else {
            if (this.sortOrder === 'asc') {
                return '↓';
            } else {
                return '↑';
            }
        }
    },
    sortOrderIndicatorDictionary: function() {
        if (this.sortAxis !== 'dictionary') {
            //return "↓";
            return '';
        } else {
            if (this.sortOrder === 'asc') {
                return '↓';
            } else {
                return '↑';
            }
        }
    },

    currentFileSizeSum() {
        return this.$store.state.fileSizeSum;
    },
};

export default logic;