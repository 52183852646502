var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "plus-minus" }, [
    _vm.indicator == "open"
      ? _c("span", [
          _c("i", {
            staticClass: "fas fa-plus-circle",
            on: {
              click: function($event) {
                return _vm.onClick(false)
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.indicator == "close"
      ? _c("span", [
          _c("i", {
            staticClass: "fas fa-minus-circle",
            on: {
              click: function($event) {
                return _vm.onClick(true)
              }
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }