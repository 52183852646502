<template>
  <div class="modal-download">

    <!-- ダウンロード中 -->
    <div class="modal-mask" v-if="!downloaded">
      <div class="modal-wrapper">
        <div class="modal-container checklist-download">
          <div class="modal-body">
            <span>ダウンロード中...</span>
            <figure><img src="/images/checklist-download-timer.png" alt=""></figure>
          </div>
        </div>
   	  </div>
    </div>
    <!-- ダウンロード完了 -->
    <transition name="modaldownload" @afterLeave="onAfterLeave">
    <div class="modal-mask" v-if="downloaded && !isClose">
      <div class="modal-wrapper">
        <div class="modal-container checklist-download">
          <div class="modal-body">
            <p>ダウンロードが完了しました</p>
          </div>
          <div class="modal-footer">
            <div class="row">
              <div class="col-md-12">
                <div name="footer">
                  <button class="button" @click.stop="onCloseClicked">閉じる</button>
                </div>
             </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  </div>
</template>

<script>
export default {
  props: {
    downloaded:false,
  },
  data() {
    return {
        isClose: false,
    };
  },
  mounted: function(){
      this.isClose = false;
  },
  computed: {
  },
  methods: {
    onCloseClicked() {
        this.isClose = true;
    },
    onAfterLeave() {
        this.$emit('close-modal-download');
    }    
  }
};
</script>

<style scoped lang="scss">
.modal-download{

.modal-mask {
  position: fixed;
  z-index: 99998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(64, 66, 76, .95);
  display: table;
  transition: opacity .3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  border: 1px solid black;
}

@media screen and (min-width: 768px) {

.checklist-download {
  padding: 50px 30px;
  text-align: center;
}
.checklist-download .modal-body span {
  display: block;
  font-size: 15.5px;
  line-height: 1.6;
  margin: 0 0 18px;
}
.checklist-download .modal-body figure {
  /*background-image: url(../../images/dummy.png);*/
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.checklist-download .modal-body figure::before {
  content: '';
  position: absolute;
  top: 10%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 40%;
  background: #53bc36;
  animation: checklist-download-timer-before 2s ease-in-out 0s infinite normal forwards;
}
@keyframes checklist-download-timer-before {
 0% {top: 20%;height: 25%;}
 30% {top: 20%;height: 30%;}
 70% {top: 65%;height: 0%;}
 100% {top: 65%;height: 0%;}
}
.checklist-download .modal-body figure::after {
  content: '';
  position: absolute;
  top: 0%;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 40%;
  background: #53bc36;
  animation: checklist-download-timer-after 2s ease-in-out 0s infinite normal forwards;
}
@keyframes checklist-download-timer-after {
 0% {top: 20%;left: 45%;width: 10%;height: 0%}
 50% {left: 30%;width: 40%;}
 60% {top: 60%;left: 20%;width: 60%;height: 40%}
 70% {top: 60%;left: 0%;width: 100%;}
 100% {top: 60%;left: 0%;width: 100%;}
}
.checklist-download .modal-body figure img {
  position: relative;
  z-index: 2;
}
.checklist-download .modal-body p {
  font-size: 16px;
  line-height: 1.6;
  font-weight: bold;
}

.checklist-download .modal-footer {
  border: none;
  text-align: center;
}
.checklist-download .modal-footer .button {
  height: auto;
  padding: 10px 42px;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}
.modal-body {
  margin: 20px 0;
  overflow-y: auto;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border: none;
}
.modal-footer .row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
}
.modal-footer .col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: 0px;
  padding-left: 0px;
}
.modal-footer .button {
  background-color: #4FBE2B;
  border-color: #4FBE2B;
  border-width: 1px;
  color: #fff;
  cursor: pointer;
  justify-content: center;
  padding: calc(.375em - 1px) 1.5em;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  border-radius: 50px;
  font-size: 16px;
}

/* Vue transition */
.modaldownload-leave-active {
    opacity: 0;
}

.modaldownload-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

}
}
</style>
