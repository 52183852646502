<template>
    <common-layout-component :show-back="true" :show-navi-bar="true" @on-nav-back="onNaviBack" :require-user-info="true" title="購入履歴" main-class="my_page">
        <template v-slot:body>
            <section class="bg_yellow">
                <div class="container pt20">
                    <div class="lessons">
                        <template v-if="purchases && purchases.length > 0">
                            <template v-for="item,index in purchases">
                                <div class="lesson" :key="index">
                                    <a :href="$libs.url(['lesson',item.task_id])">
                                        <div class="contents">
                                            <div class="flex_nw">
                                                <lesson-video-thumbnail-component :video="item.task.video" />
                                                <div class="info">
                                                    <p class="date">{{ purchasedDate(item.created_at) }}</p>
                                                    <h3>{{ item.task.title }}</h3>
                                                    <p class="price">{{ strType(item.type) }}<span>{{ item.price.toLocaleString() }}</span>円（税込）</p>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </template>
                        </template>
                        <template v-else-if="purchases">
                            <p class="mt50">購入履歴はまだありません。</p>
                        </template>
                    </div>

                </div>
            </section>
        </template>
    </common-layout-component>
</template>

<script>
import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
import ModalComponent from '../../miso/commons/ModalComponent.vue';
import LessonVideoThumbnailComponent from '../../miso/user/LessonVideoThumbnailComponent.vue';
import moment from 'moment';
export default {
    components: {
    CommonLayoutComponent,
    ModalComponent,
    LessonVideoThumbnailComponent,
    },
  data: function () {
    return {
        userIcon:null,
      displayName: "",
      showModalLogout: false,
      purchases: null,
    };
  },
  created: function () {},

  mounted: function () {
      this.refleshData();
  },
  computed: {
      iconImage: function() {
          if(this.userIcon){
              return 'background: url(' + this.userIcon + ') center center / cover';
          }else{
              return 'background: url(/images/miso/icon_user_teacher_default.jpg) center center / cover';
          }
      }
  },
  methods: {
    refleshData: function() {

            // ユーザー情報取得
            var handler = Vue.ELearning.Purchase.purchases();

            handler.then((response) => {
                if (response.data.status_number == 200) {
                    this.purchases = response.data.content.result;

                } else {
                    throw new Error("購入情報の取得に失敗しました", response);
                }
            }).catch((response) => {
                console.error(response);
            });
    },
    strType: function(type){
        if(type == 1){
            return "レッスン料"
        }
        if(type == 2){
            return "採点チケット"
        }
        if(type == 3){
            return "質問チケット"
        }
        return "レッスン料"
    },
    purchasedDate: function(strDate){
        var date = moment(strDate)
        return date.format("YYYY/MM/DD HH:mm")
    },    
    onNaviBack: function() {
        this.$libs.pushUrl("mypage")
    },
  },
}

</script>
<style lang="scss" scoped>
</style>
