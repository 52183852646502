<template>
  <modal-component is-quest="true" :yes-text="yesText" :no-text="noText" @modalYes="continueFunc" @modalNo="closeFunc">
    <h4 slot="header" v-text="title" />
    <h3 slot="body" class="modal-body">
      <p class="tc pb2em modal-body-message">
        <strong v-text="message" />
      </p>
    </h3>
  </modal-component>
</template>

<script>
import ModalComponent from '../../../commons/ModalComponent.vue';

export default {
  components: {
    ModalComponent
  },
  props: {
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    continueFunc: {
      type: Function,
      default: () => null
    },
    closeFunc: {
      type: Function,
      default: () => null
    },
    yesText: {
      type:String,
      default: () => "はい"
    },
    noText: {
      type:String,
      default: () => "いいえ"
    },            
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  text-align: center;

  &-message {
    padding-bottom: 0;
  }
}
</style>
