<template>
    <common-layout-component2 show-back="true" show-admin="true" v-on:on-nav-back="back">

        <template v-slot:nav-message>
            <p>カテゴリ登録/編集</p>
        </template>
        <template v-slot:body>
            <div class="container mainContainer">
                <!-- ヘッダー部 -->
                <div id="header-area" class="columns">
                    <h2 id="title" class="column">カテゴリ編集</h2>
                </div>
                <!--　サブタイトル部 -->
                <div id="subtitle-area">カテゴリ一覧</div>
                <!--表組み -->
                <div id="table-area" class="columns">
                    <div class="column">
                        <button class="button is-login" @click="onClickNew">新規登録&nbsp;<img src="/images/ico-plus.png" alt=""></button>
                        <button v-bind:disabled="editButtonEnabled" @click="onClickEdit" class="button is-login">編集&nbsp;<img src="/images/ico-pen.png" alt=""></button>
                        <button v-bind:disabled="replicateButtonEnabled" @click="onClickReplicate" class="button basic is-gray">複製&nbsp;<img src="/images/ico-copy.png" alt=""></button>
                        <button v-bind:disabled="deleteButtonEnabled" @click="showModalDelete = true" data-remodal-target="modal" class="button basic is-red">削除&nbsp;<img src="/images/ico-trash.png" alt=""></button>
                    </div>
                    <div class="column">
                        <p class="input-search tr">
                            <button href="#" class="button basic is-gray2" @click="sortUpdateDatetime">更新日順&nbsp;<arrow-up-down-alt v-if="sortAxis == 'update_datetime'" v-bind:order="sortOrder"></arrow-up-down-alt><!--<img src="/images/ico-down2.png" alt="">--></button>
                            <button href="#" class="button basic is-gray2" @click="sortDictionary">名前順&nbsp;<arrow-up-down-alt v-if="sortAxis == 'dictionary'" v-bind:order="sortOrder"></arrow-up-down-alt><!--<img src="/images/ico-down2.png" alt="">--></button>




                        </p>
                    </div>
                </div>
                <!-- -->
                <div class="table-wrapper">
                    <table class="basic scrollTable">
                        <thead>
                        <tr>
                            <th></th>
                            <th>カテゴリ名</th>
                            <!--<th>説明文</th>-->
                            <th>公開/非公開</th>
                        </tr>
                        </thead>

                        <template v-for="category in categoryList">
                            <tr>
                                <td class="check">
                                    <label>
                                        <!--<input checked="checked" type="checkbox" name="checkbox01[]" class="checkbox01-input">
                                        <span class="checkbox01-parts"></span>-->
                                        <base-check-component v-on:check-state-change="onCheckStateChange(category)" v-model:check-state="category.selected"></base-check-component>
                                    </label>
                                </td>
                                <td class="group-name">{{ category.categoryName }}</td>
                                <!--<td class="group-body">{{ category.categoryDesc }}</td>-->
                                <td class="open">{{ category.publicity }}</td>
                            </tr>
                        </template>


                        <!--<tr>
                            <td class="check">
                                <label>
                                    <input type="checkbox" name="checkbox01[]" class="checkbox01-input">
                                    <span class="checkbox01-parts"></span>
                                </label>
                            </td>
                            <td class="group-name">カテゴリ名が入ります</td>
                            <td class="group-body">ダミーテキストが入ります。ダミーテキストが入ります。ダミーテキス…</td>
                            <td class="open">非公開
                            </td>
                        </tr>-->
                    </table>
                </div>

                <!-- モーダルダイアログ -->
                <modal-component v-if="showModalDelete" v-bind:is-delete="true" is-quest="true" width="600" height="331" @modalYes="onClickDelete" @modalNo="showModalDelete = false">
                    <h4 slot="header"></h4>
                    <h3 slot="body" style="text-align: center;">
                        <p class="tc pb2em" style="margin-top:0.5em;">
                            <strong style="font-size: 1.4rem;line-height: 2;font-weight: normal;">選択されたカテゴリを削除しますか？</strong>
                            <span style="display: block;">※タスクも削除されます</span>
                        </p>
                    </h3>
                </modal-component>
            </div>
        </template>
    </common-layout-component2>
</template>
<script>
    import logic from './js/CategoryList.js';
    import CommonLayoutComponent2 from '../../commons/CommonLayoutComponent2.vue';

    import BaseCheckComponent from '../../commons/BaseCheckComponent.vue';
    import TableComponent from '../../commons/TableComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';

    logic.components = {
        CommonLayoutComponent2,
        BaseCheckComponent,
        TableComponent,
        ModalComponent
    }

    export default logic;
</script>
<style scoped lang="scss">
.column {
  white-space: nowrap;
}
@media (max-width: 991px){
  .columns:not(.is-desktop) {
    display: block!important;
  }
  .column {
    white-space: normal;
  }
}
@media (min-width: 992px){
  .columns:not(.is-desktop) {
    display: flex;
  }
}
</style>
