<template>
    <common-layout-component :show-back="true" :show-navi-bar="false"  @on-nav-back="onNaviBack" title="みそシル教室" main-class="notfound_page">
        <template v-slot:body>
                <div class="container">
                    <p>お探しのページは見つかりませんでした。</p>
                    <button class="button md" @click="$router.push({ name: 'top' })">トップページに戻る</button>
                </div>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    import ModalComponent from '../../commons/ModalComponent.vue';

    export default {
        components :{
            CommonLayoutComponent,
            ModalComponent
        },
        mounted:function(){

        },
        methods: {
            onNaviBack: function(){
                history.back()
            },
        },
    }
</script>
<style scoped lang="scss">
</style>
