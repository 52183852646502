var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      title: _vm.title,
      "main-class": "user_page user_teacher"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.user
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "user_header bg_keycolor" }, [
                    _c("div", { staticClass: "container pt30 pb30" }, [
                      _c("div", { staticClass: "icon" }, [
                        _c("p", { style: _vm.iconImage(_vm.user.image) })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "name" }, [
                        _vm.user.grant_id > 1
                          ? _c("p", [_vm._v("講師")])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h1", [_vm._v(_vm._s(_vm.user.display_name))])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.user.meta
                    ? _c("section", { staticClass: "profile bg_white" }, [
                        _c(
                          "div",
                          { staticClass: "container pt20 pb20" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.user.meta.profile) +
                                  "\n                "
                              )
                            ]),
                            _vm._v(" "),
                            _vm.user.grant_id > 1
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "categories flex" },
                                    [
                                      _vm._l(_vm.partNames, function(
                                        item,
                                        index
                                      ) {
                                        return [
                                          _c(
                                            "div",
                                            { key: index, staticClass: "cat" },
                                            [_vm._v(_vm._s(item))]
                                          )
                                        ]
                                      })
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "career" }, [
                                    _vm._v("講師歴: " + _vm._s(_vm.careerNames))
                                  ])
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "section",
                    { staticClass: "popular bg_yellow" },
                    [
                      _vm.lessons && _vm.lessons.length > 0
                        ? [
                            _c(
                              "div",
                              { staticClass: "container pt20" },
                              [
                                _vm.user.grant_id == 1
                                  ? [_c("h2", [_vm._v("受講したレッスン")])]
                                  : [
                                      _c("div", [
                                        _c("p", { staticClass: "count" }, [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.lessons.length))
                                          ]),
                                          _vm._v("レッスン")
                                        ])
                                      ])
                                    ],
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "lessons" },
                                  [
                                    _vm._l(_vm.lessons, function(item, index) {
                                      return [
                                        _c("lesson-cassette-component", {
                                          key: index,
                                          attrs: {
                                            lesson: item,
                                            showPrice: _vm.user.grant_id > 1
                                          }
                                        })
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ],
                              2
                            )
                          ]
                        : [
                            _c("div", { staticClass: "container" }, [
                              _c("p", [_vm._v("レッスンはまだありません。")])
                            ])
                          ]
                    ],
                    2
                  )
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }