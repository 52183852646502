var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [_vm._t("control")], 2)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("table", { staticClass: "table" }, [
          _c("thead", [_vm._t("header")], 2),
          _vm._v(" "),
          _c("tbody", [_vm._t("data")], 2),
          _vm._v(" "),
          _c("tfoot", [_vm._t("footer")], 2)
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }