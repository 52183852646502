var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "modal-component",
        {
          attrs: { title: _vm.title, "view-plain": true },
          on: { ok: _vm.onOk }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("p", [_vm._v(_vm._s(_vm.message))])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }