var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("common-layout-component", {
    attrs: {
      "show-back": true,
      "show-navi-bar": true,
      "require-user-info": true,
      title: _vm.title,
      "main-class": "lesson_page"
    },
    on: { "on-nav-back": _vm.onNaviBack },
    scopedSlots: _vm._u(
      [
        _vm.lesson
          ? {
              key: "body",
              fn: function() {
                return [
                  _c("section", { staticClass: "post post_video bg_yellow" }, [
                    _c(
                      "div",
                      { staticClass: "container pt20" },
                      [
                        _c("lesson-video-input-component", {
                          attrs: {
                            index: 1,
                            title: "自分の動画を選択（任意）"
                          },
                          on: {
                            "change-video": function($event) {
                              return _vm.onChangeVideo($event)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.viewType == 1 &&
                        _vm.$store.state.userInfo.grant == 2
                          ? _c("div", { staticClass: "box" }, [
                              _c("h3", [_vm._v("点数")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.comment.score,
                                    expression: "comment.score"
                                  }
                                ],
                                attrs: {
                                  type: "number",
                                  placeholder: "95",
                                  min: "0",
                                  max: "100"
                                },
                                domProps: { value: _vm.comment.score },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.comment,
                                      "score",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.viewType == 2 &&
                        _vm.$store.state.userInfo.grant == 1
                          ? _c("div", { staticClass: "box" }, [
                              _c("h3", [_vm._v("件名")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.comment.title,
                                    expression: "comment.title"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "",
                                  maxlength: "50"
                                },
                                domProps: { value: _vm.comment.title },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.comment,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.viewType == 1 &&
                        _vm.$store.state.userInfo.grant == 2
                          ? _c("div", { staticClass: "box" }, [
                              _c("h3", [_vm._v("評価")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.comment.title,
                                    expression: "comment.title"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  placeholder: "よくできました！",
                                  maxlength: "50"
                                },
                                domProps: { value: _vm.comment.title },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.comment,
                                      "title",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "box" }, [
                          _vm.viewType == 1
                            ? _c("h3", [_vm._v("コメント")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.viewType == 2 &&
                          _vm.$store.state.userInfo.grant == 1
                            ? _c("h3", [_vm._v("質問内容")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.viewType == 2 &&
                          _vm.$store.state.userInfo.grant >= 2
                            ? _c("h3", [_vm._v("回答")])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.comment.message,
                                expression: "comment.message"
                              }
                            ],
                            attrs: { placeholder: "" },
                            domProps: { value: _vm.comment.message },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.comment,
                                  "message",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _vm.$store.state.userInfo.grant == 1
                          ? _c("div", { staticClass: "box" }, [
                              _c("h3", [_vm._v("公開設定")]),
                              _vm._v(" "),
                              _c(
                                "dl",
                                {
                                  staticClass: "flex",
                                  on: {
                                    click: function($event) {
                                      _vm.comment.publish =
                                        1 - _vm.comment.publish
                                    }
                                  }
                                },
                                [
                                  _c("dt", [_vm._v("みんなに公開する")]),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _vm.comment.publish == 1
                                      ? _c("i", {
                                          staticClass: "fas fa-toggle-on"
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.comment.publish != 1
                                      ? _c("i", {
                                          staticClass: "fas fa-toggle-off"
                                        })
                                      : _vm._e()
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "講師にだけ公開する場合はOFF\n                    "
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "mt20 button md",
                            on: { click: _vm.post }
                          },
                          [_vm._v("投稿する")]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showModalSaved
                    ? _c("modal-component", {
                        attrs: { title: "投稿しました", "view-plain": true },
                        on: { ok: _vm.posted }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          : null
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }