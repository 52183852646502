<template>
    <span>
    <span  v-if="order=='desc'">
        <arrow-up-alt></arrow-up-alt>
    </span>
    <span  v-if="order=='asc'">
        <arrow-down-alt></arrow-down-alt>
    </span>
    </span>
</template>
<script>
    export default {
        props: {
            order: String,
        }
    }
</script>