<template>
    <div class="embed-responsive embed-responsive-16by9">
        <div v-if="isNew">
            <div class="status-new">NEW</div>
            <div v-if="isUpdate" class="status" style="left:4em;">UPDATE</div>
        </div>
        <div v-else-if="isUpdate" class="status">UPDATE</div>
        <img
            class="embed-responsive-item image-thumbnail-source"
            v-bind:src="path"
        />
    </div>
</template>

<script>
export default {
    props: {
        path: {
            type: String,
            required: true,
        },
        isUpdate: {
            type: Boolean,
            default: false,
        },
        isNew: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.image-thumbnail-source {
    border-radius: 1.1em;
}
</style>
