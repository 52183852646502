<template>
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="64px"
	 height="64px" viewBox="0 0 480 480" enable-background="new 0 0 480 480" xml:space="preserve">
        <g id="レイヤー_1">
            <g>
                <path fill="#FFFF00" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015
                    l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
                <path fill="none" stroke="#FCAB6E" stroke-width="2" stroke-miterlimit="10" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37
                    L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
            </g>
            <g>
                <g>
                    <path fill="#FCAB6E" d="M255.298,65.719c-8.25-14.289-21.75-14.289-30,0L28.063,407.342c-8.25,14.289-1.5,25.98,15,25.98h394.473
                        c16.5,0,23.25-11.691,15-25.98L255.298,65.719z M224.633,110.042c8.25-14.29,21.75-14.29,30,0l159.803,276.789
                        c8.25,14.289,1.5,25.98-15,25.98H79.829c-16.5,0-23.25-11.691-15-25.98L224.633,110.042z"/>
                </g>
                <g>
                    <path fill="#F7F3EB" d="M244.633,92.721c-2.75-4.763-7.25-4.763-10,0L54.829,404.246c-2.75,4.762-0.5,8.754,5,8.754h359.607
                        c5.5,0,7.75-3.992,5-8.754L244.633,92.721z"/>
                </g>
            </g>
            <g>
                <path fill="#FCAB6E" d="M258.702,376.141c0,10.036-8.364,18.402-18.405,18.402c-10.034,0-18.399-8.366-18.399-18.402
                    c0-10.039,8.365-18.403,18.399-18.403C250.896,357.737,258.702,366.66,258.702,376.141z"/>
                <path fill="#FCAB6E" d="M262.672,162.178c-4.221-15.885-12.803-20.994-23.369-20.573c-9.608,0.384-17.676,6.543-21.401,20.573
                    c-6.54,24.629-0.993,73.671,3.825,102.926c3.982,24.163,9.522,56.029,11.907,67.586c0.946,4.581,3.055,10.273,5.974,10.273
                    c4.582,0,6.462-4.447,7.663-10.271c2.389-11.553,7.702-43.421,11.687-67.588C263.776,235.849,269.207,186.807,262.672,162.178z"/>
            </g>
        </g>

        <!--
        <g id="レイヤー_1のコピー" display="none">
            <g display="inline">
                <path fill="#F9804B" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015
                    l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
                <path fill="none" stroke="#FCAB6E" stroke-width="2" stroke-miterlimit="10" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37
                    L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
            </g>
            <g display="inline">
                <g>
                    <path fill="#333333" d="M255.298,65.719c-8.25-14.289-21.75-14.289-30,0L28.063,407.342c-8.25,14.289-1.5,25.98,15,25.98h394.473
                        c16.5,0,23.25-11.691,15-25.98L255.298,65.719z M224.633,110.042c8.25-14.29,21.75-14.29,30,0l159.803,276.789
                        c8.25,14.289,1.5,25.98-15,25.98H79.829c-16.5,0-23.25-11.691-15-25.98L224.633,110.042z"/>
                </g>
                <g>
                    <path fill="#F9804B" d="M244.633,92.721c-2.75-4.763-7.25-4.763-10,0L54.829,404.246c-2.75,4.762-0.5,8.754,5,8.754h359.607
                        c5.5,0,7.75-3.992,5-8.754L244.633,92.721z"/>
                </g>
            </g>
            <g display="inline">
                <path fill="#333333" d="M258.702,376.141c0,10.036-8.364,18.402-18.405,18.402c-10.034,0-18.399-8.366-18.399-18.402
                    c0-10.039,8.365-18.403,18.399-18.403C250.896,357.737,258.702,366.66,258.702,376.141z"/>
                <path fill="#333333" d="M262.672,162.178c-4.221-15.885-12.803-20.994-23.369-20.573c-9.608,0.384-17.676,6.543-21.401,20.573
                    c-6.54,24.629-0.993,73.671,3.825,102.926c3.982,24.163,9.522,56.029,11.907,67.586c0.946,4.581,3.055,10.273,5.974,10.273
                    c4.582,0,6.462-4.447,7.663-10.271c2.389-11.553,7.702-43.421,11.687-67.588C263.776,235.849,269.207,186.807,262.672,162.178z"/>
            </g>
        </g>
        <g id="レイヤー_1のコピー_2" display="none">
            <g display="inline">
                <path fill="#FFFFFF" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015
                    l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
                <path fill="none" stroke="#FCAB6E" stroke-width="2" stroke-miterlimit="10" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37
                    L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
            </g>
            <g display="inline">
                <g>
                    <path d="M255.298,65.719c-8.25-14.289-21.75-14.289-30,0L28.063,407.342c-8.25,14.289-1.5,25.98,15,25.98h394.473
                        c16.5,0,23.25-11.691,15-25.98L255.298,65.719z M224.633,110.042c8.25-14.29,21.75-14.29,30,0l159.803,276.789
                        c8.25,14.289,1.5,25.98-15,25.98H79.829c-16.5,0-23.25-11.691-15-25.98L224.633,110.042z"/>
                </g>
                <g>
                    <path fill="#FFFFFF" d="M244.633,92.721c-2.75-4.763-7.25-4.763-10,0L54.829,404.246c-2.75,4.762-0.5,8.754,5,8.754h359.607
                        c5.5,0,7.75-3.992,5-8.754L244.633,92.721z"/>
                </g>
            </g>
            <g display="inline">
                <path d="M258.702,376.141c0,10.036-8.364,18.402-18.405,18.402c-10.034,0-18.399-8.366-18.399-18.402
                    c0-10.039,8.365-18.403,18.399-18.403C250.896,357.737,258.702,366.66,258.702,376.141z"/>
                <path d="M262.672,162.178c-4.221-15.885-12.803-20.994-23.369-20.573c-9.608,0.384-17.676,6.543-21.401,20.573
                    c-6.54,24.629-0.993,73.671,3.825,102.926c3.982,24.163,9.522,56.029,11.907,67.586c0.946,4.581,3.055,10.273,5.974,10.273
                    c4.582,0,6.462-4.447,7.663-10.271c2.389-11.553,7.702-43.421,11.687-67.588C263.776,235.849,269.207,186.807,262.672,162.178z"/>
            </g>
        </g>
        <g id="レイヤー_1のコピー_3" display="none">
            <g display="inline">
                <path fill="#CCCCCC" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015
                    l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
                <path fill="none" stroke="#FCAB6E" stroke-width="2" stroke-miterlimit="10" d="M40,438c-19.25,0-27.125-13.699-17.5-30.37
                    L222.705,60.834c9.625-16.671,25.375-16.687,35-0.015l200.206,346.818c9.625,16.671,1.75,30.362-17.5,30.362H40z"/>
            </g>
            <g display="inline">
                <g>
                    <path d="M255.298,65.719c-8.25-14.289-21.75-14.289-30,0L28.063,407.342c-8.25,14.289-1.5,25.98,15,25.98h394.473
                        c16.5,0,23.25-11.691,15-25.98L255.298,65.719z M224.633,110.042c8.25-14.29,21.75-14.29,30,0l159.803,276.789
                        c8.25,14.289,1.5,25.98-15,25.98H79.829c-16.5,0-23.25-11.691-15-25.98L224.633,110.042z"/>
                </g>
                <g>
                    <path fill="#CCCCCC" d="M244.633,92.721c-2.75-4.763-7.25-4.763-10,0L54.829,404.246c-2.75,4.762-0.5,8.754,5,8.754h359.607
                        c5.5,0,7.75-3.992,5-8.754L244.633,92.721z"/>
                </g>
            </g>
            <g display="inline">
                <path d="M258.702,376.141c0,10.036-8.364,18.402-18.405,18.402c-10.034,0-18.399-8.366-18.399-18.402
                    c0-10.039,8.365-18.403,18.399-18.403C250.896,357.737,258.702,366.66,258.702,376.141z"/>
                <path d="M262.672,162.178c-4.221-15.885-12.803-20.994-23.369-20.573c-9.608,0.384-17.676,6.543-21.401,20.573
                    c-6.54,24.629-0.993,73.671,3.825,102.926c3.982,24.163,9.522,56.029,11.907,67.586c0.946,4.581,3.055,10.273,5.974,10.273
                    c4.582,0,6.462-4.447,7.663-10.271c2.389-11.553,7.702-43.421,11.687-67.588C263.776,235.849,269.207,186.807,262.672,162.178z"/>
            </g>
        </g>
        -->
</svg>

</template>
<script>
    export default {

        computed: {

            /**
             * 完了
             */
            mode1 () {

            },

            /**
             * 未完了
             */
            mode2 () {

            },

            /**
             * 一部未完了
             * @returns {boolean}
             */
            mode3 () {

            }
        },

        props: {
            checkBoxMode: '',

        }
    }
</script>
<style type="text/css">
    .st0 {
        fill: #4B4B4B;
    }
</style>