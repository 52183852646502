var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "description" }, [
    _vm.showToggleButton && !_vm.showDesc
      ? _c("div", { staticClass: "description" }, [
          _c("p", { staticClass: "short" }, [
            _vm._v(_vm._s(_vm.shortDescription))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showToggleButton && !_vm.showDesc
      ? _c("p", { staticClass: "align_center" }, [
          _c(
            "a",
            {
              staticClass: "toggle open",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  _vm.showDesc = true
                }
              }
            },
            [
              _vm._v("レッスン情報をもっと見る"),
              _c("i", { staticClass: "fas fa-angle-down" })
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.showToggleButton || _vm.showDesc
      ? _c("div", [
          _c("div", { staticClass: "description" }, [
            _c("p", [_vm._v(_vm._s(_vm.lesson.description))])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box_wh contents" }, [
            _vm.lesson.goal
              ? _c("div", [
                  _c("h3", [_vm._v("ゴール")]),
                  _vm._v(" "),
                  _c("p", [_vm._v(_vm._s(_vm.lesson.goal) + "\n            ")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("h3", [_vm._v("レッスン内容")]),
              _vm._v(" "),
              _c(
                "ul",
                [
                  _vm._l(_vm.lesson.steps, function(item, index) {
                    return [
                      _c("li", { key: index }, [_vm._v(_vm._s(item.title))])
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h3", [_vm._v("この講師について")]),
              _vm._v(" "),
              _c("div", { staticClass: "about_teacher flex" }, [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.$libs.url(["teacher", _vm.lesson.user_id])
                    }
                  },
                  [
                    _c("div", { staticClass: "icon" }, [
                      _c("p", { style: _vm.iconImage(_vm.lesson.user.image) })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$libs.url(["teacher", _vm.lesson.user_id])
                      }
                    },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.lesson.user.display_name) +
                          "\n                    "
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.lesson.user.meta.profile))])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "box_wh lesson_info" }, [
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("レッスンステップ数")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.lesson.steps.length) + "ステップ")])
            ]),
            _vm._v(" "),
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("カテゴリー")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.partName))])
            ]),
            _vm._v(" "),
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("難易度")]),
              _vm._v(" "),
              _c(
                "dd",
                [_c("level-component", { attrs: { level: _vm.lesson.level } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("販売価格")]),
              _vm._v(" "),
              _c("dd", [
                _vm._v(_vm._s(_vm.lesson.price.toLocaleString()) + "円")
              ])
            ]),
            _vm._v(" "),
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("採点・質問（/回）")]),
              _vm._v(" "),
              _c("dd", [
                _vm._v(_vm._s(_vm.lesson.advice_price.toLocaleString()) + "円")
              ])
            ]),
            _vm._v(" "),
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("公開日")]),
              _vm._v(" "),
              _c("dd", [_vm._v(_vm._s(_vm.publishedDate))])
            ]),
            _vm._v(" "),
            _c("dl", { staticClass: "flex_nw" }, [
              _c("dt", [_vm._v("視聴期限")]),
              _vm._v(" "),
              _vm.isPurchased
                ? _c("dd", [_vm._v(_vm._s(_vm.expirationDate))])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isPurchased
                ? _c("dd", [
                    _vm._v("購入日から" + _vm._s(_vm.lesson.valid_days) + "日")
                  ])
                : _vm._e()
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showToggleButton
      ? _c("p", { staticClass: "align_center" }, [
          _vm.showDesc
            ? _c(
                "a",
                {
                  staticClass: "toggle close",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.showDesc = false
                    }
                  }
                },
                [
                  _vm._v("レッスン情報をとじる"),
                  _c("i", { staticClass: "fas fa-angle-up" })
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }