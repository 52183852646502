var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "wrapper columns is-multiline" },
        [
          _vm._l(_vm.props, function(item, index) {
            return [
              _c(
                "div",
                { key: item.id },
                [
                  _c(
                    "div",
                    { staticClass: "blockToggle" },
                    [
                      _c("work-procedure", {
                        key: item.work_procedure_id,
                        attrs: {
                          item: item,
                          "is-last": _vm.isLastProcedure(index),
                          "is-active": _vm.isActiveProc(item),
                          "is-open-detail": _vm.detailShowed(item),
                          "on-skip": _vm.skipProc,
                          "on-complete": _vm.completeProc,
                          "action-name": "updateCurrentProcedure"
                        },
                        on: {
                          active: function($event) {
                            return _vm.activeProc([item])
                          },
                          "open-detail-toggle": function($event) {
                            return _vm.detailShowToggle(item)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "checklist-detail" } },
                        [
                          _vm.detailShowed(item)
                            ? [
                                _c("check-list-detail", {
                                  key: item.id,
                                  attrs: {
                                    "content-height": 100,
                                    index: 0,
                                    name: item.name,
                                    notice: item.notice,
                                    description: item.description,
                                    "video-id": item.video_id,
                                    "base-path": item.basepath,
                                    "video-path": item.videopath,
                                    "hide-content": _vm.hideContent
                                  },
                                  on: {
                                    skip: function($event) {
                                      return _vm.modalSkip(item)
                                    },
                                    completed: function($event) {
                                      return _vm.modalCompleted(item)
                                    },
                                    "video-play": function($event) {
                                      return _vm.$emit("video-play")
                                    }
                                  }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(item.child, function(child, index) {
                    return [
                      _c(
                        "div",
                        { key: child.id, staticClass: "blockToggle" },
                        [
                          _c("work-procedure", {
                            attrs: {
                              item: child,
                              "is-last": _vm.isLastProcedure(index),
                              "is-active": _vm.isActiveProc(child),
                              "is-open-detail": _vm.detailShowed(child),
                              "on-skip": _vm.skipProc,
                              "on-complete": _vm.completeProc,
                              "action-name": "updateCurrentProcedure"
                            },
                            on: {
                              "open-detail-toggle": function($event) {
                                return _vm.detailShowToggle(child)
                              },
                              active: function($event) {
                                return _vm.activeProc([child, item])
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "checklist-detail" } },
                            [
                              _vm.detailShowed(child)
                                ? [
                                    _c("check-list-detail", {
                                      attrs: {
                                        "content-height": 100,
                                        index: 0,
                                        name: child.name,
                                        notice: child.notice,
                                        description: child.description,
                                        "base-path": child.basepath,
                                        "video-id": child.video_id,
                                        "video-path": child.videopath,
                                        "hide-content": _vm.hideContent
                                      },
                                      on: {
                                        skip: function($event) {
                                          return _vm.modalSkip(item)
                                        },
                                        completed: function($event) {
                                          return _vm.modalCompleted(item)
                                        },
                                        "video-play": function($event) {
                                          return _vm.$emit("video-play")
                                        }
                                      }
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isCompleted
        ? _c(
            "a",
            {
              staticClass: "floatArea floatArea-save",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openSaveDialog.apply(null, arguments)
                }
              }
            },
            [_c("p", [_vm._v("保存して中断")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isCompleted
        ? _c(
            "a",
            {
              staticClass: "floatArea floatArea-comp",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.openSaveDialog.apply(null, arguments)
                }
              }
            },
            [_c("p", [_vm._v("完了")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalCompleted
        ? _c("confirm-modal", {
            attrs: {
              "continue-func": _vm.completeByCurrentProc,
              "close-func": _vm.closeModalCompleted,
              title: "タスク完了の確認",
              message: "タスクを完了しますか？"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalSkip
        ? _c("confirm-modal", {
            attrs: {
              "continue-func": _vm.skipByCurrentProc,
              "close-func": _vm.closeModalSkip,
              title: "タスクスキップの確認",
              message: "タスクをスキップしますか？"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalTaskCompleted
        ? _c("confirm-modal", {
            attrs: {
              "continue-func": _vm.completeByTask,
              "close-func": _vm.closeModalTaskCompleted,
              title: "タスク完了の確認",
              message: "タスクを完了しますか？"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalSaveHistory
        ? _c(
            "modal-component",
            {
              attrs: { "is-quest": "true" },
              on: {
                modalYes: _vm.saveHistory,
                modalNo: function($event) {
                  _vm.showModalSaveHistory = false
                }
              }
            },
            [
              _c("h4", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("確認")
              ]),
              _vm._v(" "),
              _c(
                "h3",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { slot: "body" },
                  slot: "body"
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "tc pb2em",
                      staticStyle: { "padding-bottom": "0" }
                    },
                    [_c("strong", [_vm._v("タスクを保存して中断しますか？")])]
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showModalDownload
        ? _c("task-download-modal", {
            attrs: { downloaded: _vm.taskDownloaded },
            on: { "close-modal-download": _vm.closeModalDownload }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }