var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-component",
    {
      attrs: {
        "is-quest": "true",
        "yes-text": _vm.yesText,
        "no-text": _vm.noText
      },
      on: { modalYes: _vm.continueFunc, modalNo: _vm.closeFunc }
    },
    [
      _c("h4", {
        attrs: { slot: "header" },
        domProps: { textContent: _vm._s(_vm.title) },
        slot: "header"
      }),
      _vm._v(" "),
      _c(
        "h3",
        { staticClass: "modal-body", attrs: { slot: "body" }, slot: "body" },
        [
          _c("p", { staticClass: "tc pb2em modal-body-message" }, [
            _c("strong", { domProps: { textContent: _vm._s(_vm.message) } })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }