<template>
    <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="width: 32px; height: 32px; opacity: 1;" xml:space="preserve">

		<g v-if="mode1">
			<!-- 完了 -->

			<polygon class="st0" points="335.644,414.285 53.466,414.285 53.466,132.107 291.098,132.107 344.564,78.64 0,78.64 0,467.751
				389.106,467.751 389.106,441.018 389.106,323.746 335.644,377.213 	" style="fill: rgb(75, 75, 75);fill:#B6B6B6;"></polygon>

			<polygon class="st0" points="158.903,163.312 103.914,218.311 193.434,307.822 248.423,362.82 303.412,307.822 512,99.247
				457.002,44.249 248.431,252.823 	" style="fill: rgb(75, 75, 75);fill:#49BC23;"></polygon>

		</g>

		<g v-if="mode2">
			<!-- 未完了 -->

			<polygon class="st0" points="335.644,414.285 53.466,414.285 53.466,132.107 344.564,132.107 344.564,78.64 0,78.64 0,467.751
				389.106,467.751 389.106,441.018 389.106,78.64 344.564,78.64 335.644,377.213 	" style="fill: rgb(75, 75, 75);fill:#B6B6B6;"></polygon>
		</g>

		<g v-if="mode3">
			<!-- 親業手順(一部未完了) -->

			<polygon class="st0" points="335.644,414.285 53.466,414.285 53.466,132.107 291.098,132.107 344.564,78.64 0,78.64 0,467.751
				389.106,467.751 389.106,441.018 389.106,323.746 335.644,377.213 	" style="fill: rgb(75, 75, 75);fill:#B6B6B6;"></polygon>

			<polygon class="st0" points="158.903,163.312 103.914,218.311 193.434,307.822 248.423,362.82 303.412,307.822 512,99.247
				457.002,44.249 248.431,252.823 	" style="fill: rgb(75, 75, 75);fill:#B6B6B6;"></polygon>

		</g>
	</svg>

</template>
<script>
    export default {

        computed: {

            /**
			 * 完了
			 */
            mode1 () {
				//console.log(this.checkBoxMode);
				return this.checkBoxMode === 'completed';
            },

			/**
			 * 未完了
			 */
            mode2 () {

                return this.checkBoxMode === 'incomplete';
            },

            /**
			 * 一部未完了
             * @returns {boolean}
             */
            mode3 () {

                return this.checkBoxMode === 'partial';
            }
        },

        props: {
            checkBoxMode: '',

        }
	}
</script>
<style type="text/css">
	.st0 {
		fill: #4B4B4B;
	}
</style>