<template>
    <common-layout-component :show-back="false" :show-navi-bar="true"  title="利用規約" main-class="terms_page">
        <template v-slot:body>
        </template>
    </common-layout-component>
</template>

<script>
    import CommonLayoutComponent from '../../miso/commons/CommonLayoutComponent.vue';
    export default {
        components :{
            CommonLayoutComponent
        },
        mounted:function(){
        },
    }
</script>
